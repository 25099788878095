const locale = {
  pricing: 'Preisgestaltung',
  saveUpTo: 'Sparen Sie bis zu',
  monthly: 'monatlich',
  monthlyUppercase: 'Monatlich',
  annually: 'jährlich',
  annuallyUppercase: 'Jährlich',
  organization: 'Organisation',
  month: 'Monat',
  billedMonthly: 'Monatlich abgerechnet',
  billedAnnually: 'Jährlich abgerechnet',
  users: 'Benutzer',
  startTrialForFree: 'Kostenlose Testversion starten',
  buy: 'KAUFEN',
  contactUs: 'Kontaktieren Sie uns',
  starterPlan: 'Starter-Plan',
  enterprisePlan: 'Enterprise-Plan',
  mostPopular: 'Am beliebtesten',
  buyPackage: 'Paket kaufen',
  buyPackageDescription:
    'Dieses Paket namens {{name}} beinhaltet ein Speicherlimit von {{storageLimit}} GB und ein Benutzerlimit von {{userLimit}}.',
  dontHaveBillingInfo: 'Sie haben keine Rechnungsinformationen zur Verwendung.',
  dontHaveCard: 'Sie haben keine Karte zur Verwendung.',
  addBillingInfo: 'Rechnungsinformationen hinzufügen',
  addCard: 'Karte hinzufügen',
  manageBillingInfo: 'Rechnungsinformationen verwalten',
  manageCards: 'Karten verwalten',
  summary: 'Zusammenfassung',
  iAcceptThe: 'Ich akzeptiere die',
  preliminaryInformation: 'Vorabinformationen',
  distanceSalesAgreement: 'Fernabsatzvertrag',
  close: 'Schließen',
  cancel: 'Abbrechen',
  subscribeSuccess1: 'Ihr Abonnement wurde erfolgreich gestartet.',
  subscribeSuccess2: 'Willkommen an Bord.',
  continue: 'Fortfahren',
  letUsCall: 'Lassen Sie uns anrufen',
  billingInfo: 'Rechnungsinformationen',
  selectedCard: 'Ausgewählte Karte',
  currentPlan: 'Aktueller Plan',
  renewAt: 'Verlängern am',
  active: 'Aktiv',
  usageLimits: 'Nutzungslimits',
  storageUsage: 'Speichernutzung',
  activeCard: 'Aktive Karte',
  trialPackage: 'Testpaket',
  trialPackageDescription:
    'Dieses Paket bietet eine Nutzungsdauer von 7 Tagen mit einem Speicherlimit von {{storageLimit}} GB und einem Benutzerlimit von {{userLimit}}.',
  confirmTrial: 'Sind Sie sicher, dass Sie die Testversion beginnen möchten?',
  beginTrial: 'Testversion beginnen',
  contactFormSuccess: 'Ihre Anfrage wurde erfolgreich übermittelt. Wir werden Sie in Kürze kontaktieren.',

  packageDescription:
    'Dieses Paket bietet eine Nutzungsdauer von 7 Tagen mit einem Speicherlimit von {{storageLimit}} GB und einem Benutzerlimit von {{userLimit}}.',
  beginTrialSuccess: 'Testversion erfolgreich gestartet.',
  purchaseSuccess: 'Erfolgreich gekauft.',
  title: 'Preisgestaltung',
  unknownError: 'Ein unbekannter Fehler ist aufgetreten.',
  contactMessage: 'Telefon: {{phone}}, Organisationsname: {{orgName}}',
  detailTitle: 'Detail',
  trialPackageTitle: 'Testpaket',
  buyPackageTitle: 'Paket kaufen',
  contactUsTitle: 'Kontaktieren Sie uns',
  preliminaryInfoTitle: 'Vorabinformationen',
  distanceSalesAgreementTitle: 'Fernabsatzvertrag',
  welcomeTrial1: 'Ihr Testpaket wurde erfolgreich gestartet. Willkommen an Bord.',
  welcomeTrial2: 'Willkommen an Bord.',
  noBillingInfo: 'Sie haben keine Rechnungsinformationen zur Verwendung.',
  billingInfoLabel: 'Rechnungsinformationen*',
  noCardInfo: 'Sie haben keine Karte zur Verwendung.',
  selectedCardLabel: 'Ausgewählte Karte*',
  acceptPreliminaryInfo: 'Ich akzeptiere die Vorabinformationen',
  acceptDistanceSalesAgreement: 'Ich akzeptiere den Fernabsatzvertrag',
  preliminaryInfoDescription:
    'Dieses Paket bietet eine Nutzungsdauer von 7 Tagen mit einem Speicherlimit und Benutzerberechtigungen wie in der Vereinbarung beschrieben.',
  subscriptionSuccess: 'Ihr Abonnement wurde erfolgreich gestartet. Willkommen an Bord.',
  selectedPeriodLabel: '/{{selectedPeriod}}',
  distanceSalesAgreementDescription:
    'Dieses Paket bietet eine Nutzungsdauer von 7 Tagen mit einem Speicherlimit und Benutzerberechtigungen wie im Fernabsatzvertrag beschrieben.',
  goCRM: 'Zum CRM',
  tryForFree: 'Kostenlos testen',
  onePaymentOf: 'Eine Zahlung von',

  cancelled: 'Storniert',
  expired: 'Abgelaufen',
  completed: 'Abgeschlossen',
  failed: 'Fehlgeschlagen',
  ongoingWithCancelled: 'Laufend mit Stornierung',
  ongoingWithFailure: 'Laufend mit Fehler',

  payment: 'Zahlung',
  upgrade: 'UPGRADE',
  paymentSuccess: 'Zahlung erfolgreich abgeschlossen.',
  paymentFailure: 'Zahlungsprozess abgeschlossen',
};

export default locale; 
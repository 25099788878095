const locale = {
  demo: "Demo",
  title: "Was ist <highlight>CoCRM</highlight> Marketing?",
  description:
    "Bei CoCRM machen wir nicht nur die Verwaltung Ihrer bestehenden Kundenbeziehungen einfacher — wir helfen Ihnen auch, Ihre Marketingprozesse zu optimieren, um Ihre Geschäftsziele zu erreichen. Mit CoCRM Marketing können Sie Ihre Zielgruppe effektiver erreichen und Ihre Marketingkampagnen effizienter gestalten.",
  featuresTitle: "Mit CoCRM Marketing können Sie:",
  features1:
    "Ihre Marketingkampagnen nach Land, Stadt und Branche für ein strategischeres Management anpassen,",
  features2:
    "Personalisierte Kampagnen erstellen, die auf die spezifischen Märkte zugeschnitten sind, in denen Sie die Markenbekanntheit steigern möchten,",
  features3:
    "Die Customer Journey effizienter verfolgen, um Ihre Vertriebs- und Marketingziele zu unterstützen,",
  features4:
    "Ergebnisorientierte Strategien durch Integration mit digitalen Marketing-Tools entwickeln.",
  buttonTitle: "Angebot anfordern",
  formDescription:
    "Füllen Sie das Formular aus und wir melden uns so schnell wie möglich bei Ihnen.",
  sendSuccess: "Vielen Dank! Wir werden uns so schnell wie möglich bei Ihnen melden.",
  formTitle: "Angebot anfordern",
  formButtonTitle: "Absenden",
  fullName: "Vollständiger Name",
  email: "E-Mail",
  phone: "Telefon",
  orgName: "Organisationsname",
};

export default locale; 
const locale = {
  projects: 'Projekte',
  project: 'Projekt',
  projectConversions: 'Projektumwandlungen',
  title: 'Projekte',
  create: 'Projekt erstellen',
  update: 'Projekt aktualisieren',
  delete: 'Projekt löschen',
  createSuccess: 'Projekt erfolgreich erstellt.',
  updateSuccess: 'Projekt erfolgreich aktualisiert.',
  deleteSuccess: 'Projekt erfolgreich gelöscht.',
  deleteConfirm: 'Sind Sie sicher, dass Sie dieses Projekt löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  emptyState1: 'Noch keine Projekte gestartet.',
  emptyState2:
    'Erstellen und verwalten Sie hier Ihre Projekte, um den Fortschritt zu verfolgen, Aufgaben zuzuweisen und mit Ihrem Team zusammenzuarbeiten.',
  progressCalculationType: 'Fortschrittsberechnung',
  progressPercentage: 'Fortschritt %',
  isBillable: 'Abrechenbar',
  billingType: 'Abrechnungsart',
  billingRatePerHour: 'Stundensatz',
  members: 'Mitglieder',
  startDate: 'Startdatum',
  deadline: 'Frist',
  estimatedHours: 'Geschätzte Stunden',
  // labels
  welcome: 'Willkommen',
  description: "Ihr Projektfortschritt in dieser Woche ist erstaunlich. Machen wir weiter.",

  // detail
  changeStatus: 'Status ändern',
  generalInfoTitle: 'Allgemeine Informationen',
  customerInfoTitle: 'Kundeninformationen',
  contactPhoneNumber: 'Kontakt-Telefonnummer',
  membersTitle: 'Mitglieder',
  milestone: 'Meilenstein',
  timeSheet: 'Zeiterfassung',
  projectInfoTitle: 'Projektinformationen',
  billingInfoTitle: 'Abrechnungsinformationen',
  statusChangeSuccess: 'Projektstatus erfolgreich geändert.',
  detail: 'Projektdetail',
  noMember: 'Noch kein Mitglied.',

  // timeSheet
  noAssigneeToCreateTimeSheet: 'Bitte weisen Sie Benutzer dem Projekt zu, um Zeiterfassungen zu erstellen',
  emptyStateTimeSheetList1: 'Es gibt noch keine Zeiterfassung.',
  addTimeSheet: 'Zeiterfassung hinzufügen',
  timeSheetCreate: 'Zeiterfassung erstellen',
  timeSheetUpdate: 'Zeiterfassung aktualisieren',
  member: 'Mitglied',
  startTime: 'Startzeit',
  endTime: 'Endzeit',
  duration: 'Dauer',
  timeSheetCreateSuccess: 'Zeiterfassung erfolgreich erstellt.',
  timeSheetUpdateSuccess: 'Zeiterfassung erfolgreich aktualisiert.',
  timeSheetDeleteSuccess: 'Zeiterfassung erfolgreich gelöscht.',

  // milestone
  emptyStateMilestoneList1: 'Es gibt noch keinen Meilenstein.',
  addMilestone: 'Meilenstein hinzufügen',
  milestoneCreate: 'Meilenstein erstellen',
  milestoneUpdate: 'Meilenstein aktualisieren',
  milestoneCreateSuccess: 'Meilenstein erfolgreich erstellt.',
  milestoneUpdateSuccess: 'Meilenstein erfolgreich aktualisiert.',
  milestoneDeleteSuccess: 'Meilenstein erfolgreich gelöscht.',
  addTask: 'Aufgabe hinzufügen',
};

export default locale; 
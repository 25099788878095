const locale = {
  reports: "Rapporti",
  report: "Rapporto",
  reportConversions: "Conversioni dei Rapporti",
  title: "Rapporti",
  addReport: "Aggiungi Rapporto",
  emptyStateDesc1: "Nessun rapporto disponibile al momento.",
  emptyStateDesc2: "Genera e visualizza qui approfondimenti dettagliati per monitorare le tue attività CRM e prendere decisioni basate sui dati."
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { useInvoicePaymentDetailQuery } from '../../context/payment.api';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  IInvoicePayment,
  IInvoicePaymentDetailResult,
  IInvoicePaymentListItemResult,
} from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { PaymentDetailDrawerLeftPanelComponent } from './PaymentDetailDrawer-leftPanel.component';
import { PaymentDetailDrawerRightPanelComponent } from './PaymentDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

export interface IInvoicePaymentDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedPayment: IInvoicePaymentDetailResult & IHasId<number>;
  setSelectedPayment: Dispatch<
    SetStateAction<TListGridSelectedEntity<IInvoicePaymentListItemResult>>
  >;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const PaymentDetailDrawer = memo((props: IInvoicePaymentDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: paymentDetailData, error: paymentDetailError } = useInvoicePaymentDetailQuery(
    props?.selectedPayment?._id
      ? {
          input: {
            invoiceId: props.selectedPayment.invoice?._id ?? '',
            paymentId: props.selectedPayment._id,
          },
        }
      : skipToken,
    {
      skip: !props?.selectedPayment?._id,
    },
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(paymentDetailError as IBaseError, currentLanguage);
  }, [paymentDetailError]);

  return (
    <DefaultDetailDrawer<IInvoicePaymentDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedPayment}
      widthResponsive={{ xs: '100%', sm: '100%', md: 800, lg: 800 }}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.sale.payment.title'),
            }
      }
    >
      <PaymentDetailDrawerLeftPanelComponent
        paymentDetailData={paymentDetailData}
        setOpen={props.setOpen}
      />
      <PaymentDetailDrawerRightPanelComponent paymentDetailData={paymentDetailData} />
    </DefaultDetailDrawer>
  );
});

export default PaymentDetailDrawer;

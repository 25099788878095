import dashboardLocale from '../domains/01-dashboard/locales/de';
import productAndServiceLocale from '../domains/08-productAndService/locales/de';
import organizationalChartLocale from '../domains/02-organizationalChart/locales/de';
import leadLocale from '../domains/03-lead/locales/de';
import customerLocale from '../domains/04-customer/locales/de';
import preSaleLocale from '../domains/05-preSale/locales/de';
import saleLocale from '../domains/06-sale/locales/de';
import pricingLocale from '../domains/16-pricing/locales/de';
import calendarLocale from '../domains/12-calendar/locales/de';
import contractLocale from '../domains/07-contract/locales/de';
import supportLocale from '../domains/15-support/locales/de';
import reportLocale from '../domains/13-report/locales/de';
import taskLocale from '../domains/11-task/locales/de';
import projectLocale from '../domains/10-project/locales/de';
import expenseLocale from '../domains/09-expense/locales/de';
import noteLocale from '../domains/14-note/locales/de';

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  productAndService: {
    ...productAndServiceLocale,
  },
  customer: {
    ...customerLocale,
  },
  preSale: {
    ...preSaleLocale,
  },
  sale: {
    ...saleLocale,
  },
  pricing: {
    ...pricingLocale,
  },
  calendar: {
    ...calendarLocale,
  },
  contract: {
    ...contractLocale,
  },
  support: {
    ...supportLocale,
  },
  report: {
    ...reportLocale,
  },
  task: {
    ...taskLocale,
  },
  project: {
    ...projectLocale,
  },
  expense: {
    ...expenseLocale,
  },
  note: {
    ...noteLocale,
  },

  ////////////////////////////////////////////////////////////////
  // common used names
  ////////////////////////////////////////////////////////////////
  preSaleLabel: 'Vorverkauf',
  preSales: 'Vorverkäufe',
  contactDetails: 'Details',
  localization: 'Lokalisierung',
  info: 'Info',
  about: 'Über',
  contact: 'Kontakt',
  record: 'Datensatz',
  records: 'Datensätze',
  tracking: 'Verfolgung',
  pricingLabel: 'Preisgestaltung',
  dashboardLabel: 'Dashboard',
  marketing: 'Marketing',
  campaigns: 'Kampagnen',
  emails: 'E-Mails',
  sms: 'SMS',
  socialMedia: 'Soziale Medien',
  salesLabel: 'Verkauf',
  businessIntelligence: 'Business Intelligence',
  calendarLabel: 'Kalender',
  supportLabel: 'Ticket',
  accountMenu: 'Kontomenü',
  profile: 'Profil',
  myAccount: 'Mein Konto',
  logout: 'Abmelden',
  searchPlaceholder: 'Versuchen Sie, nach "Einblicke" zu suchen',
  searchAnIcon: 'Symbol suchen',

  // --------------------
  // settings related
  // --------------------
  settings: 'Einstellungen',
  english: 'Englisch',
  turkish: 'Türkisch',
  french: 'Französisch',
  german: 'Deutsch',
  italian: 'Italienisch',
  portuguese: 'Portugiesisch',
  russian: 'Russisch',
  spanish: 'Spanisch',
  dutch: 'Niederländisch',
  darkMode: 'Dunkelmodus',
  lightMode: 'Hellmodus',
  languageSelectLabel: 'Sprache auswählen',

  ////////////////////////////////////////////////////////////////
  // common action texts
  ////////////////////////////////////////////////////////////////
  actions: 'Aktionen',
  yes: 'Ja',
  no: 'Nein',
  importExport: 'Import/Export',
  view: 'Ansicht',
  list: 'Liste',
  create: 'Erstellen',
  update: 'Aktualisieren',
  delete: 'Löschen',
  edit: 'Bearbeiten',
  cancel: 'Abbrechen',
  downloadSuccessful: 'Erfolgreich heruntergeladen',
  export: 'Exportieren',
  import: 'Importieren',
  exportData: 'Daten exportieren',
  importData: 'Daten importieren',
  showMore: 'Mehr anzeigen',
  selectColor: 'Farbe auswählen',
  justForMe: 'Nur für mich',

  ////////////////////////////////////////////////////////////////
  // common situational texts
  ////////////////////////////////////////////////////////////////
  total: 'Gesamt',
  weeklyTotal: 'Wöchentliche Summe',
  hasCreatedAt: 'wurde erstellt am ',
  createdBy: 'Erstellt von ',
  updatedBy: 'Aktualisiert von ',
  uploadLogo: 'Logo hochladen',
  selectIconTitle: 'Symbol auswählen',
  searchIcons: 'Symbole suchen',
  uploadPictureText: 'Bild hochladen',
  searchForAnIcon: 'Nach einem Symbol suchen',
  selectedIcon: 'Ausgewähltes Symbol:',
  signedUser: 'Angemeldeter Benutzer',

  // --------------------
  // delete dialog
  // --------------------
  deletedEntitySuccessfully: 'Erfolgreich gelöscht',
  deleteEntityContent: 'Sind Sie sicher, dass Sie löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  deleteEntityTitle: 'Löschen',

  // --------------------
  // organization
  // --------------------
  mustCreateOrganizationTitle: 'Organisation erstellen',
  mustCreateOrganizationContent: 'Sie müssen eine Organisation erstellen, um das Panel zu nutzen.',
  mustCreateOrganizationSuccess: 'Organisation erfolgreich erstellt.',

  // --------------------
  // import export
  // --------------------
  importedError: 'Importfehler',
  importedSuccessfully: 'Erfolgreich importiert',
  downloadExample: 'Beispiel herunterladen',
  downloadFormError: 'Fehler beim Herunterladen des Formulars',
  downloadFormSuccessfully: 'Formular erfolgreich heruntergeladen',
  downloadExampleFormSuccessfully: 'Beispielformular erfolgreich heruntergeladen',
  downloadExampleFormError: 'Fehler beim Herunterladen des Beispielformulars',

  // --------------------
  // estimate
  // --------------------
  addEstimate: 'Schätzung hinzufügen',
  noEstimate: 'Es gibt keine Schätzungen',
  noEstimateContent1: 'Schätzungen nicht gefunden',
  additionalInfo: 'Zusätzliche Informationen',

  // --------------------
  // proposal
  // --------------------
  addProposal: 'Angebot hinzufügen',
  noProposal: 'Es gibt keine Angebote',
  lastProposal: 'Letztes Angebot',
  proposalSend: 'Angebot gesendet',
  noProposalSend: 'Es wurden keine Angebote gesendet',
  noProposalContent1: 'Angebote nicht gefunden',
  sendEmailOnExpiration: 'E-Mail bei Ablauf senden',
  testimonialCaseStudies: 'Testimonial-Fallstudien',
  projectTimeline: 'Projektzeitplan',
  executiveSummary: 'Zusammenfassung',
  costBreakdown: 'Kostenaufschlüsselung',
  conclusion: 'Fazit',
  projectorServiceDesc: 'Detaillierte Projekt-/Dienstleistungsbeschreibung',
  noSalentAgents: 'Es gibt keine Vertriebsmitarbeiter',

  // --------------------
  // document
  // --------------------
  addDocument: 'Dokument hinzufügen',
  noDocument: 'Es gibt keine Dokumente',
  noDocumentContent1: 'Dokumente nicht gefunden',

  // --------------------
  // task
  // --------------------
  noTask: 'Es gibt keine Aufgaben',
  noTaskContent1: 'Aufgaben nicht gefunden',

  // --------------------
  // activity
  // --------------------
  noActivity: 'Es gibt keine Aktivitäten',
  addActivity: 'Aktivität hinzufügen',
  noActivityContent1: 'Aktivitäten nicht gefunden',
  noActivityContent2: 'Es gibt keine Aktivitäten für diese Entität',

  // --------------------
  // note
  // --------------------
  addNote: 'Notiz hinzufügen',
  noNote: 'Es gibt keine Notizen',
  emptyStateNoteList1: 'Notizen nicht gefunden',
  noteDate: 'Notizdatum',
  noteUsers: 'Notizbenutzer',
  noteTitle: 'Titel',

  // --------------------
  // reminder
  // --------------------
  addReminder: 'Erinnerung hinzufügen',
  noReminder: 'Es gibt keine Erinnerungen',
  emptyStateReminderList1: 'Erinnerungen nicht gefunden',
  remindDate: 'Erinnerungsdatum',
  remindUsers: 'Benutzer erinnern',
  remindTitle: 'Titel',

  // --------------------
  // appointment
  // --------------------
  noAppointments: 'Es gibt keine Termine',
  makeAnAppointment: 'Termin vereinbaren',

  // --------------------
  // assignee
  // --------------------
  assignees: 'Zugewiesene',
  noAssignees: 'Keine Zugewiesenen',
  addAssignee: 'Zugewiesenen hinzufügen',

  // --------------------
  // followers
  // --------------------
  followers: 'Follower',
  noFollowers: 'Keine Follower',

  // --------------------
  // product and services
  // --------------------
  addProduct: 'Produkt hinzufügen',
  addProductOrService: 'Produkt oder Dienstleistung hinzufügen',
  editProdutctOrService: 'Produkt oder Dienstleistung bearbeiten',
  productType: 'Produkttyp',
  editProduct: 'Produkt bearbeiten',
  product: 'Produkt',

  // --------------------
  // ticket
  // --------------------
  subject: 'Betreff',
  contactName: 'Kontaktname',
  tickets: 'Tickets',

  ////////////////////////////////////////////////////////////////
  // common used words
  ////////////////////////////////////////////////////////////////
  of: 'von',

  ////////////////////////////////////////////////////////////////
  // common used entity names
  ////////////////////////////////////////////////////////////////
  leadLabel: 'Lead',
  leads: 'Leads',
  leadForms: 'Lead-Formulare',
  leadStatuses: 'Lead-Status',
  leadSources: 'Lead-Quellen',
  customerLabel: 'Kunde',
  customers: 'Kunden',
  estimateLabel: 'Schätzung',
  estimates: 'Schätzungen',
  proposalLabel: 'Angebot',
  proposals: 'Angebote',
  invoiceLabel: 'Rechnung',
  invoices: 'Rechnungen',
  productsServices: 'Produkte & Dienstleistungen',
  activityLabel: 'Aktivität',
  activities: 'Aktivitäten',
  noteLabel: 'Notiz',
  notes: 'Notizen',
  documentLabel: 'Dokument',
  documents: 'Dokumente',
  appointmentLabel: 'Termin',
  appointments: 'Termine',
  reminderLabel: 'Erinnerungen',
  reminders: 'Erinnerungen',
  departmentLabel: 'Abteilung',
  departments: 'Abteilungen',
  contractLabel: 'Vertrag',
  contractTemplate: 'Vertragsvorlage',
  contracts: 'Verträge',
  paymentLabel: 'Zahlung',
  payments: 'Zahlungen',
  organizationalChartLabel: 'Organigramm',
  expenseLabel: 'Ausgabe',
  expenses: 'Ausgaben',
  productAndServiceLabel: 'Produkte & Dienstleistungen',
  projectLabel: 'Projekt',
  projects: 'Projekte',
  taskLabel: 'Aufgabe',
  tasks: 'Aufgaben',
  reportLabel: 'Bericht',
  reports: 'Berichte',
  billingInformation: 'Rechnungsinformationen',
  billingInfo: 'Rechnungsinfo',
  otherBillingInformation: 'Andere Rechnungsinformationen',
  shippingInfo: 'Versandinfo',
  contactInformation: 'Kontaktinformationen',
  otherContactInformation: 'Andere Kontaktinformationen',
  warningBillingInfo: 'Sobald ein Feld ausgefüllt ist, müssen alle Felder ausgefüllt werden.',
  addRole: 'Rolle hinzufügen',

  ////////////////////////////////////////////////////////////////
  // common fields
  ////////////////////////////////////////////////////////////////
  color: 'Farbe',
  icon: 'Symbol',
  index: 'Index',
  title: 'Titel',
  fullName: 'Vollständiger Name',
  firstName: 'Vorname',
  lastName: 'Nachname',
  surname: 'Nachname',
  convert: 'Konvertieren',
  country: 'Land',
  state: 'Bundesland',
  city: 'Stadt',
  industry: 'Branche',
  address: 'Adresse',
  website: 'Website',
  emailAddress: 'E-Mail-Adresse',
  name: 'Name',
  companyName: 'Firmenname',
  companyEmail: 'Firmen-E-Mail',
  companyPhone: 'Firmentelefon',
  timezone: 'Zeitzone',
  companyLogo: 'Firmenlogo',
  language: 'Sprache',
  department: 'Abteilung',
  email: 'E-Mail',
  phoneNumber: 'Telefonnummer',
  type: 'Typ',
  createdAt: 'Erstellt am',
  updatedAt: 'Aktualisiert am',
  prefix: 'Präfix',
  tags: 'Tags',
  tagsPlaceholder: 'Tags',
  addTagsPlaceholder: 'Tags hinzufügen',
  addNewOption: 'Neue Option hinzufügen',
  enterText: 'Text eingeben...',
  company: 'Unternehmen',
  sector: 'Sektor',
  phone: 'Telefon',
  content: 'Inhalt',
  priority: 'Priorität',
  position: 'Position',
  status: 'Status',
  source: 'Quelle',
  date: 'Datum',
  expireDate: 'Ablaufdatum',
  totalPrice: 'Gesamtpreis',
  currency: 'Währung',
  category: 'Kategorie',
  parentCategory: 'Übergeordnete Kategorie',
  vat: 'Umsatzsteuer-ID',
  zip: 'PLZ',
  street: 'Straße',
  taxOffice: 'Finanzamt',
  target: 'Ziel',
  targetType: 'Zieltyp',
  description: 'Beschreibung',
  birthDate: 'Geburtsdatum',
  gender: 'Geschlecht',
  isPrimary: 'Ist primär',
  prospectName: 'Interessentenname',
  prospectAddress: 'Interessentenadresse',
  prospectContactName: 'Interessentenkontaktname',
  prospectType: 'Interessententyp',
  leadValue: 'Lead-Wert',
  estimateId: 'Schätzungs-ID',
  proposalId: 'Angebots-ID',
  invoiceId: 'Rechnungs-ID',
  paymentId: 'Zahlungs-ID',
  returnId: 'Rückgabe-ID',
  expenseId: 'Ausgaben-ID',
  customerName: 'Kundenname',
  customerEmail: 'Kunden-E-Mail',
  customerType: 'Kundentyp',
  finalPrice: 'Endpreis',
  paymentMethod: 'Zahlungsmethode',
  confirmationNumber: 'Bestätigungsnummer',
  amoundPaid: 'Gezahlter Betrag',
  refundAmount: 'Rückerstattungsbetrag',
  isOpen: 'Ist offen',
  resolveReason: 'Lösungsgrund',
  disapproveReason: 'Ablehnungsgrund',
  sku: 'Artikelnummer',
  priceType: 'Preistyp',
  price: 'Preis',
  discountedPrice: 'Rabattierter Preis',
  discount: 'Rabatt',
  unit: 'Einheit',
  unitPrice: 'Stückpreis',
  natTaxRate: 'Nationale Steuerrate',
  intTaxRate: 'Internationale Steuerrate',
  selectRoles: 'Rollen auswählen',
  timeZone: 'Zeitzone',
  prospect: 'Interessent',
  prospectTargetEntityRef: 'Interessentenziel-Entitätsreferenz',
  prospectTarget: 'Interessentenziel',
  prospectVAT: 'Interessenten-USt-IdNr.',
  prospectPhoneNumber: 'Interessenten-Telefonnummer',
  prospectZIP: 'Interessenten-PLZ',
  contactFirstName: 'Kontakt Vorname',
  contactLastName: 'Kontakt Nachname',
  contactEmail: 'Kontakt E-Mail',
  expiration: 'Ablauf',
  detailedInformation: 'Detaillierte Informationen',
  projectOrServiceDesc: 'Projekt-/Dienstleistungsbeschreibung',
  scopeOfWork: 'Arbeitsumfang',
  assumptionsAndExclusions: 'Annahmen und Ausschlüsse',
  paymentTerms: 'Zahlungsbedingungen',
  products: 'Produkte',
  services: 'Dienstleistungen',
  productName: 'Produktname',
  productDescription: 'Produktbeschreibung',
  productUnit: 'Produkteinheit',
  quantity: 'Menge',
  tax: 'Steuer',
  extraInformation: 'Zusätzliche Informationen',
  offers: 'Angebote',
  reference: 'Referenz',
  allowComments: 'Kommentare erlauben',
  totally: 'Insgesamt',
  subTotal: 'Zwischensumme',
  taxTotal: 'Steuersumme',
  extraDiscount: 'Zusätzlicher Rabatt',
  shippingTotal: 'Gesamte Versandkosten',
  salesAgents: 'Vertriebsmitarbeiter',
  legal: 'Rechtliches',
  useLegalNDA: 'Rechtliche Geheimhaltungsvereinbarung verwenden',
  useLegalTermAndCond: 'Rechtliche Geschäftsbedingungen verwenden',
  signature: 'Unterschrift',
  signerUser: 'Unterzeichnender Benutzer',
  signedDate: 'Unterzeichnungsdatum',
  generalInfo: 'Allgemeine Informationen',
  startDate: 'Startdatum',
  dueDate: 'Fälligkeitsdatum',
  endDate: 'Enddatum',
  expirationEmailSentDate: 'Datum der gesendeten Ablauf-E-Mail',
  allowedPaymentMethods: 'Erlaubte Zahlungsmethoden',
  sendEmail: 'E-Mail senden',
  identityNumber: 'Identitätsnummer',
  recurringCount: 'Wiederholungsanzahl',
  isRecurring: 'Ist wiederkehrend',
  recurringFrequency: 'Wiederholungsfrequenz',
  recurringDueDate: 'Wiederkehrendes Fälligkeitsdatum',
  paymentID: 'Zahlungs-ID',
  paymentPrefix: 'Zahlungspräfix',
  returnPrefix: 'Rückgabepräfix',
  paymentDate: 'Zahlungsdatum',
  amountPaid: 'Gezahlter Betrag',
  invoiceID: 'Rechnungs-ID',
  targetDetail: 'Zieldetail',
  productsAndServices: 'Produkte & Dienstleistungen',
  popularSearch: 'Beliebte Suche',
  returnReason: 'Rückgabegrund',
  partialReturn: 'Teilrückgabe',
  amount: 'Betrag',
  relationship: 'Beziehung',
  relationshipType: 'Beziehungstyp',
  relatedEntity: 'Zugehörige Entität',
  relatedEstimate: 'Zugehörige Schätzung',
  relatedProposal: 'Zugehöriges Angebot',
  relatedTask: 'Zugehörige Aufgabe',
  relatedProject: 'Zugehöriges Projekt',
  relatedLead: 'Zugehöriger Lead',
  relatedCustomer: 'Zugehöriger Kunde',
  relatedInvoice: 'Zugehörige Rechnung',
  relatedPayment: 'Zugehörige Zahlung',
  relatedUser: 'Zugehöriger Benutzer',
  relatedExpense: 'Zugehörige Ausgabe',
  relatedContract: 'Zugehöriger Vertrag',
  order: 'Bestellung',

  ////////////////////////////////////////////////////////////////
  // SEARCH
  ////////////////////////////////////////////////////////////////
  leadCreate: 'Lead erstellen',
  leadList: 'Lead-Liste',
  leadImport: 'Leads importieren',
  customerCreate: 'Kunde erstellen',
  customerList: 'Kundenliste',
  customerImport: 'Kunden importieren',
  estimateCreate: 'Schätzung erstellen',
  estimateList: 'Schätzungsliste',
  proposalCreate: 'Angebot erstellen',
  proposalList: 'Angebotsliste',
  invoiceCreate: 'Rechnung erstellen',
  invoiceList: 'Rechnungsliste',
  paymentCreate: 'Zahlung erstellen',
  paymentList: 'Zahlungsliste',
  returnCreate: 'Rückgabe erstellen',
  returnList: 'Rückgabeliste',
  contractCreate: 'Vertrag erstellen',
  contractList: 'Vertragsliste',
  productCreate: 'Produkt erstellen',
  productList: 'Produktliste',
  serviceList: 'Dienstleistungsliste',
  serviceCreate: 'Dienstleistung erstellen',
  expenseCreate: 'Ausgabe erstellen',
  expenseList: 'Ausgabenliste',
  projectCreate: 'Projekt erstellen',
  projectList: 'Projektliste',
  taskCreate: 'Aufgabe erstellen',
  taskList: 'Aufgabenliste',
  reminderList: 'Erinnerungsliste',
  reminderCreate: 'Erinnerung erstellen',
  appointmentList: 'Terminliste',
  appointmentCreate: 'Termin erstellen',
  noteCreate: 'Notiz erstellen',
  noteList: 'Notizliste',
  ticketList: 'Ticketliste',
  ticketCreate: 'Ticket erstellen',
  noResultsFound: 'Keine Ergebnisse gefunden',
  trySearching: 'Versuchen Sie, nach',
  hideButton: 'Schaltfläche ausblenden',
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from 'react';
import { Box, Button, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material';
import {
  IInvoicePaymentDetailResult,
  InvoicePaymentStatus,
  PermissionSubject,
} from 'corede-common-cocrm';
import { PaymentDetailDrawerLeftPanelHeaderTagsComponent } from './PaymentDetailDrawer-leftPanel.header.tags.component';
import { PaymentDetailDrawerLeftPanelActionsComponent } from './PaymentDetailDrawer-leftPanel-header-actions.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MoreVert, Refresh } from '@mui/icons-material';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import { PermissionAction } from 'corede-common';

export interface IPaymentDetailDrawerLeftPanelHeaderComponentProps {
  paymentDetailData: IInvoicePaymentDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const DrawerMenu = (props: IPaymentDetailDrawerLeftPanelHeaderComponentProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const [openConvertForm, setOpenConvertForm] = React.useState(false);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <PermissionWrapper
        check={{
          subject: PermissionSubject.invoicePayment,
          action: PermissionAction.update,
        }}
      >
        <Button
          id="payment-detail-button"
          aria-controls={open ? 'payment-detail-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          variant="outlined"
          color="secondary"
          sx={{
            position: downMd ? 'relative' : 'absolute',
            top: 0,
            right: 0,
            height: 24,
            width: 24,
            minWidth: 0,
            borderRadius: '50%',
            padding: 0,
          }}
        >
          <MoreVert />
        </Button>
        <Menu
          id="payment-detail-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'payment-detail-button',
          }}
        >
          <MenuItem onClick={() => setOpenConvertForm(true)}>
            <Refresh sx={{ mr: 1 }} />
            <Typography fontSize={'small'} fontWeight={'bold'}>
              {t('crm.sale.payment.paymentRenew')}
            </Typography>
          </MenuItem>
        </Menu>
      </PermissionWrapper>
    </Stack>
  );
};

/**
 * Container for views in left panel header
 */
export const PaymentDetailDrawerLeftPanelHeaderComponent = (
  props: IPaymentDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      justifyContent={{ xs: 'space-between', lg: 'flex-start' }}
      gap={1}
      sx={{ width: '100%' }}
    >
      {/* Payment Base Info Section */}
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: 'primary.main' }}>
        {props.paymentDetailData?.paymentPrefix ? props.paymentDetailData?.paymentPrefix + '-' : ''}
        {props.paymentDetailData?.paymentId ?? ''}
      </Typography>

      {/* {!downMd && (
        <PaymentDetailDrawerLeftPanelActionsComponent
          paymentDetailData={props.paymentDetailData}
          setOpen={props.setOpen}
        />
      )}

      {downMd && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap:2 }}>
          <PaymentDetailDrawerLeftPanelActionsComponent
            paymentDetailData={props.paymentDetailData}
            setOpen={props.setOpen}
          />
          <DrawerMenu paymentDetailData={props.paymentDetailData} setOpen={props.setOpen} />
        </Box>
      )}*/}
    </Stack>
  );
};

const locale = {
  appointments: 'Appuntamenti',
  appointment: 'Appuntamento',
  appointmentConversions: 'Conversioni di appuntamenti',
  title: 'Appuntamenti',
  create: 'Crea appuntamento',
  update: 'Aggiorna appuntamento',
  delete: 'Elimina appuntamento',
  details: 'Dettagli appuntamento',
  createSuccess: 'Appuntamento creato con successo.',
  updateSuccess: 'Appuntamento aggiornato con successo.',
  emptyState1: 'Nessun appuntamento programmato ancora.',
  emptyState2:
    'Configura e gestisci i tuoi appuntamenti qui per rimanere organizzato e connetterti con i tuoi clienti senza problemi.',
  statusChangeSuccess: 'Stato dell\'appuntamento modificato con successo.',
  deleteConfirm: 'Sei sicuro di voler eliminare questo appuntamento?',
  deletedSuccessfully: 'Appuntamento eliminato con successo.',

  //fields
  startTime: 'Ora di inizio',
  endTime: 'Ora di fine',
  timezone: 'Fuso orario',
  location: 'Luogo',
  organizer: 'Organizzatore',
  targetType: 'Tipo di obiettivo',
  attendees: 'Partecipanti',
  category: 'Categoria',
  meetingLink: 'Link alla riunione',
  duration: 'Durata',

  //detail drawer
  changeStatus: 'Cambia stato',
  generalInfoTitle: 'Informazioni generali',
  organizerInfoTitle: 'Informazioni sull\'organizzatore',
  appointmentInfoTitle: 'Informazioni sull\'appuntamento',
};

export default locale; 
const locale = {
  leadForms: 'Formulaires de Prospects',
  title: 'Formulaires de Prospects',
  newForm: 'Nouveau Formulaire',
  emptyStateDesc1:
    'Vos formulaires de prospects apparaîtront ici au fur et à mesure que vous les créez pour capturer des informations précieuses sur les clients.',
  emptyStateDesc2:
    'Gérez et personnalisez facilement vos formulaires pour vous assurer que vous collectez les bonnes informations pour chaque interaction.',

  create: 'Créer un Formulaire de Prospect',
  update: 'Mettre à jour le Formulaire de Prospect',
  updateSuccess: 'Formulaire de prospect mis à jour avec succès',
  createSuccess: 'Formulaire de prospect créé avec succès',
  deleteSuccess: 'Formulaire de prospect supprimé avec succès',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce formulaire de prospect ?',
  deletedSuccessfully: 'Formulaire de prospect supprimé avec succès',

  // tabs
  detailedInfo: 'Informations Détaillées',
  branding: 'Image de Marque',
  formFields: 'Champs du Formulaire',
  submission: 'Soumission*',
  notification: 'Notification',

  //fields
  addField: 'Ajouter un Champ',
  updateField: 'Mettre à jour le Champ',
  label: 'Étiquette',
  formName: 'Nom du Formulaire',
  defaultLanguage: 'Langue par Défaut',
  defaultPrefix: 'Préfixe par Défaut',
  defaultStatus: 'Statut par Défaut',
  defaultSource: 'Source par Défaut',
  defaultAssigned: 'Attribution par Défaut',
  defaultBusinessType: 'Type d\'Entreprise par Défaut',
  defaultType: 'Type par Défaut',
  defaultQualification: 'Qualification par Défaut',
  defaultTags: 'Tags par Défaut',
  submitButtonText: 'Texte du Bouton de Soumission',
  header: 'En-tête',
  isRequired: 'Obligatoire',
  type: 'Type',
  defaultIsPublic: 'Public par Défaut',
  followUpRedirectUrl: 'URL de Redirection de Suivi',
  followUpMessage: 'Message de Suivi',
  specificUsersToNotify: 'Utilisateurs Spécifiques à Notifier',
  willNotify: 'Notifiera',
  willNotifyResponsibleUsers: 'Notifiera les Utilisateurs Responsables',
  leadField: 'Champ de Prospect',
  placeholder: 'Placeholder',
  fullNamePlaceholder: 'Entrez votre nom complet',
  emailPlaceholder: 'Entrez votre email',
  formTitle: 'Formulaire',
};

export default locale; 
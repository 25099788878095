const locale = {
  reports: "Berichte",
  report: "Bericht",
  reportConversions: "Berichtskonvertierungen",
  title: "Berichte",
  addReport: "Bericht hinzufügen",
  emptyStateDesc1: "Noch keine Berichte verfügbar.",
  emptyStateDesc2: "Erstellen und betrachten Sie hier detaillierte Einblicke, um Ihre CRM-Aktivitäten zu verfolgen und datengestützte Entscheidungen zu treffen."
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskCreateMutation } from '../../context/task.api';
import { enqueueSnackbar } from 'notistack';
import { IGraphqlVariables, unknownError } from 'corede-common';
import { getCurrentLanguage } from 'localization';
import { ITaskCreateInput, TaskPriority, TaskStatus, TaskTargetType } from 'corede-common-cocrm';
import { useForm } from 'react-hook-form';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import { Stack, Typography, Tooltip, IconButton } from '@mui/material';
import { Iconify } from 'components';
import { UpsertTaskComponent } from '../form/UpsertTaskComponent';
import ActionDialog, { OverlayType } from 'components/dialog/ActionDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateCreateTasksInput } from '../../validations/create.validation';

type ITaskCreateOverlay = {
  open: boolean;
  setOpen: (open: boolean) => void;
  targetId: string;
  targetEntityRef: TaskTargetType | undefined;
  overlayType: OverlayType;
  selectedStatus?: TaskStatus;
};

const TaskCreateOverlay = (props: ITaskCreateOverlay) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const uploadDocumentRef = useRef<{ handleUpload: () => void }>(null);

  //mutation
  const [
    taskCreate,
    { data: taskCreateData, isLoading: taskCreateIsLoading, error: taskCreateError },
  ] = useTaskCreateMutation();

  //form

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, touchedFields, isValid },
  } = useForm<IGraphqlVariables<ITaskCreateInput>>({
    values: {
      input: {
        relatedEntityId: props.targetId ?? undefined,
        relatedEntityRef: props.targetEntityRef ?? undefined,
        subject: '',
        description: '',
        startDate: undefined,
        dueDate: undefined,
        assigneeIds: [],
        followerIds: [],
        checklist: [],
        departmentId: undefined,
        priority: TaskPriority.medium,
        tags: [],
        hourlyRate: undefined,
        hourlyRateCurrency: undefined,
        isBillable: false,
        isRecurrent: false,
        repeatDays: undefined,
        totalCycle: undefined,
        documents: [],
        status: props.selectedStatus ?? undefined,
      },
    },
    resolver: yupResolver(validateCreateTasksInput),
    mode: 'onChange',
  });

  const onSubmit = async (values: IGraphqlVariables<ITaskCreateInput>) => {
    setLoadingUpload(true);
    const newDocs = await uploadDocumentRef.current?.handleUpload();
    // Assign them to the task input
    if (newDocs) setValue('input.documents', newDocs);

    const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
      input: values,
    });
    await taskCreate(transformedValues as IGraphqlVariables<ITaskCreateInput>);
    setLoadingUpload(false);
  };

  //effects

  useEffect(() => {
    if (taskCreateData) {
      enqueueSnackbar(t('crm.task.task.createTaskSuccess'), { variant: 'success' });
      reset();
      props.setOpen(false);
    }
  }, [taskCreateData]);

  useEffect(() => {
    if (taskCreateError) {
      enqueueSnackbar(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (taskCreateError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [taskCreateError]);

  useEffect(() => {
    if (props.targetEntityRef) {
      setValue('input.relatedEntityRef', props.targetEntityRef);
    }
  }, [props.targetEntityRef]);

  useEffect(() => {
    if (props.targetId) {
      setValue('input.relatedEntityId', props.targetId);
    }
  }, [props.targetId]);

  const commonContent = (
      <UpsertTaskComponent
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        control={control}
        errors={errors}
        touchedFields={touchedFields}
        type="create"
        
        targetId={props.targetId}
        targetEntityRef={props.targetEntityRef}
        uploadDocumentRef={uploadDocumentRef}
        open={props.open}
      />
  );

  switch (props.overlayType) {
    case OverlayType.drawer:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          loading={taskCreateIsLoading || loadingUpload}
          buttonTitle={t('create')}
          backgroundColor={'background.default'}
          title={
            <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                {t('crm.task.task.create')}
              </Typography>

              <Tooltip title={t('webNotification.close')}>
                <IconButton sx={{ cursor: 'pointer' }} onClick={() => props.setOpen(false)}>
                  <Iconify icon={'material-symbols:close'} />
                </IconButton>
              </Tooltip>
            </Stack>
          }
          handleSubmit={handleSubmit(onSubmit)}
          disabled={!isValid || taskCreateIsLoading || loadingUpload}
        >
          {commonContent}
        </ActionDialog>
      );
    case OverlayType.dialog:
      return (
        <ActionDialog
          open={props.open}
          onClose={() => {
            props.setOpen(false);
            reset();
          }}
          title={t('crm.task.task.create')}
          loading={taskCreateIsLoading || loadingUpload}
          buttonTitle={t('create')}
          handleClick={handleSubmit(onSubmit)}
          disabled={!isValid || taskCreateIsLoading || loadingUpload}
        >
          {commonContent}
        </ActionDialog>
      );
    default:
      return null;
  }
};

export default TaskCreateOverlay;

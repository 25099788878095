const locale = {
  pricing: 'Preços',
  saveUpTo: 'Economize até',
  monthly: 'mensal',
  monthlyUppercase: 'Mensal',
  annually: 'anual',
  annuallyUppercase: 'Anual',
  organization: 'Organização',
  month: 'Mês',
  billedMonthly: 'Cobrado Mensalmente',
  billedAnnually: 'Cobrado Anualmente',
  users: 'Usuários',
  startTrialForFree: 'Iniciar Teste Gratuito',
  buy: 'COMPRAR',
  contactUs: 'Contate-nos',
  starterPlan: 'Plano Inicial',
  enterprisePlan: 'Plano Empresarial',
  mostPopular: 'Mais Popular',
  buyPackage: 'Comprar Pacote',
  buyPackageDescription:
    'Este pacote chamado {{name}} inclui um limite de armazenamento de {{storageLimit}} GB e um limite de usuários de {{userLimit}}.',
  dontHaveBillingInfo: 'Você não tem informações de faturamento para usar.',
  dontHaveCard: 'Você não tem nenhum cartão para usar.',
  addBillingInfo: 'Adicionar Informações de Faturamento',
  addCard: 'Adicionar Cartão',
  manageBillingInfo: 'Gerenciar Informações de Faturamento',
  manageCards: 'Gerenciar Cartões',
  summary: 'Resumo',
  iAcceptThe: 'Eu aceito as',
  preliminaryInformation: 'Informações Preliminares',
  distanceSalesAgreement: 'Acordo de Venda à Distância',
  close: 'Fechar',
  cancel: 'Cancelar',
  subscribeSuccess1: 'Sua assinatura foi iniciada com sucesso.',
  subscribeSuccess2: 'Bem-vindo a bordo.',
  continue: 'Continuar',
  letUsCall: 'Deixe-nos Ligar',
  billingInfo: 'Informações de Faturamento',
  selectedCard: 'Cartão Selecionado',
  currentPlan: 'Plano Atual',
  renewAt: 'Renovar em',
  active: 'Ativo',
  usageLimits: 'Limites de Uso',
  storageUsage: 'Uso de armazenamento',
  activeCard: 'Cartão Ativo',
  trialPackage: 'Pacote de Teste',
  trialPackageDescription:
    'Este pacote oferece um período de uso de 7 dias, com um limite de armazenamento de {{storageLimit}} GB e um limite de usuários de {{userLimit}}.',
  confirmTrial: 'Tem certeza de que deseja iniciar o teste?',
  beginTrial: 'Iniciar Teste',
  contactFormSuccess: 'Sua solicitação foi enviada com sucesso. Entraremos em contato em breve.',

  packageDescription:
    'Este pacote oferece um período de uso de 7 dias, com um limite de armazenamento de {{storageLimit}} GB e um limite de usuários de {{userLimit}}.',
  beginTrialSuccess: 'Teste iniciado com sucesso.',
  purchaseSuccess: 'Comprado com sucesso.',
  title: 'Preços',
  unknownError: 'Ocorreu um erro desconhecido.',
  contactMessage: 'Telefone: {{phone}}, Nome da Organização: {{orgName}}',
  detailTitle: 'Detalhe',
  trialPackageTitle: 'Pacote de Teste',
  buyPackageTitle: 'Comprar Pacote',
  contactUsTitle: 'Contate-nos',
  preliminaryInfoTitle: 'Informações Preliminares',
  distanceSalesAgreementTitle: 'Acordo de Venda à Distância',
  welcomeTrial1: 'Seu pacote de teste foi iniciado com sucesso. Bem-vindo a bordo.',
  welcomeTrial2: 'Bem-vindo a bordo.',
  noBillingInfo: 'Você não tem informações de faturamento para usar.',
  billingInfoLabel: 'Informações de Faturamento*',
  noCardInfo: 'Você não tem nenhum cartão para usar.',
  selectedCardLabel: 'Cartão Selecionado*',
  acceptPreliminaryInfo: 'Eu aceito as Informações Preliminares',
  acceptDistanceSalesAgreement: 'Eu aceito o Acordo de Venda à Distância',
  preliminaryInfoDescription:
    'Este pacote oferece um período de uso de 7 dias, com um limite de armazenamento e permissões de usuário conforme descrito no acordo.',
  subscriptionSuccess: 'Sua assinatura foi iniciada com sucesso. Bem-vindo a bordo.',
  selectedPeriodLabel: '/{{selectedPeriod}}',
  distanceSalesAgreementDescription:
    'Este pacote oferece um período de uso de 7 dias, com um limite de armazenamento e permissões de usuário conforme descrito no acordo de venda à distância.',
  goCRM: 'Ir para o CRM',
  tryForFree: 'Experimente Gratuitamente',
  onePaymentOf: 'Um pagamento de',

  cancelled: 'Cancelado',
  expired: 'Expirado',
  completed: 'Concluído',
  failed: 'Falhou',
  ongoingWithCancelled: 'Em andamento com cancelamento',
  ongoingWithFailure: 'Em andamento com falha',

  payment: 'Pagamento',
  upgrade: 'ATUALIZAR',
  paymentSuccess: 'Pagamento concluído com sucesso.',
  paymentFailure: 'Processo de pagamento concluído',
};

export default locale; 
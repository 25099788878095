/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, useMediaQuery } from '@mui/material';
import { IInvoiceReturnDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { ReturnDetailDrawerLeftPanelHeaderComponent } from './ReturnDetailDrawer-leftPanel-header.component';
import { ReturnDetailDrawerLeftPanelContactDetailComponent } from './ReturnDetailDrawer-leftPanel-contactDetail.component';
import { ReturnDetailDrawerLeftPanelAssignedsComponent } from './ReturnDetailDrawer-leftPanel-assigned.component';

export interface IReturnDetailDrawerLeftPanelComponentProps {
  returnDetailData: IInvoiceReturnDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReturnDetailDrawerLeftPanelComponent = (
  props: IReturnDetailDrawerLeftPanelComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <DefaultDetailDrawerLeftPanelComponent<IInvoiceReturnDetailResult>
      entityData={props.returnDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <ReturnDetailDrawerLeftPanelHeaderComponent
        returnDetailData={props.returnDetailData}
        setOpen={props.setOpen}
      />

      {/* General Details */}
      <ReturnDetailDrawerLeftPanelContactDetailComponent
        returnDetailData={props.returnDetailData}
        setOpen={props.setOpen}
      />

      <Divider />
    </DefaultDetailDrawerLeftPanelComponent>
  );
};

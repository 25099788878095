/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Box, Stack, Grid, Typography } from '@mui/material';
import { IHasId } from 'corede-common';
import {
  IProjectDetailResult,
  ITask,
  ITaskChecklistItem,
  ITaskDetailResult,
  ITaskListItemResult,
  TaskStatus,
  TaskTargetType,
} from 'corede-common-cocrm';
import ColumnContainer from './MilestoneKanban-columnContainer.component';
import TaskCard from './MilestoneKanban-taskCard.component';
import { getTranslatedEnumValue } from 'localization';
import {
  useProjectMilestoneKanbanUpdateMutation,
  useProjectDetailQuery,
  useProjectMilestoneTaskAssignMutation,
  useProjectMilestoneDeleteMutation,
} from '../../context/project.api';
import TaskDetailDrawer from 'apps/crm/domains/11-task/subdomains/task/pages/detailDrawer/TaskDetailDrawer';
import { TaskDeleteDialog } from 'apps/crm/domains/11-task/subdomains/task/pages/TaskDeleteDialog';
import TaskUpdateDrawerWrapper from 'apps/crm/domains/11-task/subdomains/task/pages/update/TaskUpdateOverlay';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { OverlayType } from 'components/dialog/ActionDialog';
import {
  DndContext,
  DragOverlay,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
  useSensors,
  useSensor,
  PointerSensor,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import {
  useTaskKanbanUpdateMutation,
  useTaskListQuery,
} from 'apps/crm/domains/11-task/subdomains/task';
import MilestoneUpdateOverlay from './update/MilestoneUpdateOverlay';
import { IProjectMilestone } from 'corede-common-cocrm';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ActionDialog from 'components/dialog/ActionDialog';

export type Id = string | number;

export type Column = {
  id: Id;
  title: string;
};

export type Task = {
  id: Id;
  milestone: Id;
  subject: string;
  description: string;
  checklistTotal: number;
  checklistCompleted: number;
  documentCount: number;
  assignees: any[]; //TODO
  dueDate: Date | undefined;
};

export type MilestoneTasks = {
  [key: string]: Task[];
};

export interface IMilestoneKanbanComponent {
  // task

  projectDetailData: IProjectDetailResult | undefined;
  setOpenTaskCreateDrawer?: (value: boolean) => void | undefined;
}

// Milestone Delete Dialog Component
const MilestoneDeleteDialog = memo(
  (props: {
    open: boolean;
    projectId: string;
    milestoneId: string;
    onClose: () => void;
    onDelete: (milestoneId: string) => void;
    isLoading: boolean;
  }) => {
    const { t } = useTranslation();

    return (
      <ActionDialog
        open={props.open}
        onClose={props.onClose}
        title={t('crm.deleteEntityTitle')}
        buttonTitle={t('crm.delete')}
        handleClick={() => props.onDelete(props.milestoneId)}
        disabled={props.isLoading}
        loading={props.isLoading}
        buttonColor="error"
        width={500}
      >
        <Grid item xs={12}>
          <Typography>{t('crm.deleteEntityContent')}</Typography>
        </Grid>
      </ActionDialog>
    );
  }
);

MilestoneDeleteDialog.displayName = 'MilestoneDeleteDialog';

const MilestoneKanbanComponent = memo((props: IMilestoneKanbanComponent) => {
  // init
  const { t } = useTranslation();

  // states
  const [selectedTask, setSelectedTask] = useState<TListGridSelectedEntity<ITask>>();
  const [selectedMilestone, setSelectedMilestone] = useState<IProjectMilestone>();
  const [openTaskDetailDrawer, setOpenTaskDetailDrawer] = useState(false);
  const [openTaskDeleteDialog, setOpenTaskDeleteDialog] = useState(false);
  const [openTaskUpdateDrawer, setOpenTaskUpdateDrawer] = useState(false);
  const [openMilestoneUpdateDialog, setOpenMilestoneUpdateDialog] = useState(false);
  const [openMilestoneDeleteDialog, setOpenMilestoneDeleteDialog] = useState(false);
  const [milestoneDeleteLoading, setMilestoneDeleteLoading] = useState(false);

  // queries
  const { data: projectDetailData } = useProjectDetailQuery({
    input: {
      _id: props.projectDetailData?._id ?? '',
    },
  });
  const { data: taskListData } = useTaskListQuery({
    input: {
      filter: {
        relatedEntityRef: TaskTargetType.Project,
        relatedEntityId: props.projectDetailData?._id ?? '',
      },
    },
  });

  const milestones = projectDetailData?.milestones ?? [];
  const allTasks = taskListData?.data ?? [];

  // mutations
  const [taskKanbanUpdate] = useTaskKanbanUpdateMutation();
  const [milestoneTaskAssign] = useProjectMilestoneTaskAssignMutation();
  const [milestoneDelete] = useProjectMilestoneDeleteMutation();

  // kanban

  const UNCATEGORIZED_COLUMN = {
    id: 'uncategorized',
    title: 'Uncategorized',
  };

  const [columns, setColumns] = useState<Column[]>([]);
  const [activeColumn, setActiveColumn] = useState<Column | null>(null);
  const [activeTask, setActiveTask] = useState<Task | null>(null);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskCounts, setTaskCounts] = useState<{ [key: string]: number }>({});
  const [milestoneTasks, setMilestoneTasks] = useState<MilestoneTasks>({});
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 3 },
    }),
  );

  const updateTask = (id: Id, content: string) => {
    const updatedTasks = tasks.map((task) => (task.id === id ? { ...task, content } : task));
    setTasks(updatedTasks);
  };

  const onDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === 'Column') {
      setActiveColumn(event.active.data.current.column);
    } else if (event.active.data.current?.type === 'Task') {
      setActiveTask(event.active.data.current.task);
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    setActiveColumn(null);
    setActiveTask(null);

    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (!activeId || !overId) return;

    if (active.id === over.id) return;

    const isTask = active.data.current?.type === 'Task';
    const isOverColumn = over.data.current?.type === 'Column';
    const isOverTask = over.data.current?.type === 'Task';

    // Bir kartı bir sütunun üzerine sürükleme
    if (isTask && isOverColumn) {
      const activeTaskId = active.data.current?.task?.id;
      const overColumnId = over.data.current?.column?.id;
      const sourceColumnId = active.data.current?.task?.milestone;

      if (!activeTaskId || !overColumnId || !sourceColumnId) return;

      if (sourceColumnId !== overColumnId) {
        setMilestoneTasks((prevMilestoneTasks) => {
          const sourceTasks = prevMilestoneTasks[sourceColumnId] || [];
          const targetTasks = prevMilestoneTasks[overColumnId] || [];

          const updatedSourceTasks = sourceTasks.filter((task) => task.id !== activeTaskId);
          const updatedTargetTasks = [
            ...targetTasks,
            { ...active.data?.current?.task, milestone: overColumnId },
          ];

          return {
            ...prevMilestoneTasks,
            [sourceColumnId]: updatedSourceTasks,
            [overColumnId]: updatedTargetTasks,
          };
        });

        milestoneTaskAssign({
          filter: {
            projectId: projectDetailData?._id ?? '',
            taskId: activeTaskId,
          },
          input: {
            milestoneId: overColumnId,
          },
        }).catch((error) => {
          console.error('Error updating task:', error);
        });
      }
    }
    // Bir kartı başka bir kartın üzerine sürükleme
    else if (isTask && isOverTask) {
      const activeTaskId = active.data.current?.task?.id;
      const overTaskId = over.id;
      const sourceColumnId = active.data.current?.task?.milestone;
      const targetColumnId = over.data.current?.task?.milestone;

      if (!activeTaskId || !overTaskId || !sourceColumnId || !targetColumnId) return;

      // Eğer aynı sütun içinde sürükleme yapılıyorsa, sadece sıralamayı değiştir
      if (sourceColumnId === targetColumnId) {
        setMilestoneTasks((prevMilestoneTasks) => {
          const columnTasks = [...(prevMilestoneTasks[sourceColumnId] || [])];
          const activeIndex = columnTasks.findIndex((task) => task.id === activeTaskId);
          const overIndex = columnTasks.findIndex((task) => task.id === overTaskId);

          if (activeIndex !== -1 && overIndex !== -1) {
            const result = arrayMove(columnTasks, activeIndex, overIndex);
            return {
              ...prevMilestoneTasks,
              [sourceColumnId]: result,
            };
          }
          return prevMilestoneTasks;
        });
      } 
      // Farklı sütunlar arasında sürükleme yapılıyorsa
      else {
        setMilestoneTasks((prevMilestoneTasks) => {
          const sourceTasks = prevMilestoneTasks[sourceColumnId] || [];
          const targetTasks = prevMilestoneTasks[targetColumnId] || [];
          
          const taskToMove = sourceTasks.find((task) => task.id === activeTaskId);
          if (!taskToMove) return prevMilestoneTasks;
          
          const updatedSourceTasks = sourceTasks.filter((task) => task.id !== activeTaskId);
          const overIndex = targetTasks.findIndex((task) => task.id === overTaskId);
          
          // Hedef kartın üzerine taşınan kartı ekle
          const updatedTargetTasks = [...targetTasks];
          updatedTargetTasks.splice(overIndex, 0, { ...taskToMove, milestone: targetColumnId });
          
          return {
            ...prevMilestoneTasks,
            [sourceColumnId]: updatedSourceTasks,
            [targetColumnId]: updatedTargetTasks,
          };
        });

        milestoneTaskAssign({
          filter: {
            projectId: projectDetailData?._id ?? '',
            taskId: activeTaskId,
          },
          input: {
            milestoneId: targetColumnId,
          },
        }).catch((error) => {
          console.error('Error updating task:', error);
        });
      }
    }
  };

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) return;

    if (active.id === over.id) return;

    const overId = over.id;

    const isTask = active.data.current?.type === 'Task';
    const isOverTask = over.data.current?.type === 'Task';
    const isOverColumn = over.data.current?.type === 'Column';

    // Bir kartı başka bir kartın üzerine sürükleme
    if (isTask && isOverTask) {
      const sourceColumnId = active.data.current?.task?.milestone;
      const targetColumnId = over.data.current?.task?.milestone;

      if (!sourceColumnId || !targetColumnId) return;

      // Farklı sütunlar arasında sürükleme yapılıyorsa
      if (sourceColumnId !== targetColumnId) {
        setActiveTask((prevTask) => {
          if (prevTask) {
            return { ...prevTask, milestone: targetColumnId };
          }
          return prevTask;
        });
      }
    } 
    // Bir kartı bir sütunun üzerine sürükleme
    else if (isTask && isOverColumn) {
      const targetColumnId = overId;

      setActiveTask((prevTask) => {
        if (prevTask) {
          return { ...prevTask, milestone: targetColumnId };
        }
        return prevTask;
      });
    }
  };

  const handleLoadMore = () => {
    // Implement load more logic here
  };

  const handleEditMilestone = (milestoneId: Id) => {
    const milestone = milestones.find((m) => m._id === milestoneId);
    if (milestone) {
      setSelectedMilestone(milestone);
      setOpenMilestoneUpdateDialog(true);
    }
  };

  const handleDeleteMilestone = (milestoneId: Id) => {
    const milestone = milestones.find((m) => m._id === milestoneId);
    if (milestone) {
      setSelectedMilestone(milestone);
      setOpenMilestoneDeleteDialog(true);
    }
  };

  const confirmDeleteMilestone = (milestoneId: string) => {
    setMilestoneDeleteLoading(true);
    
    milestoneDelete({
      input: {
        projectId: props.projectDetailData?._id ?? '',
        projectMilestoneId: milestoneId,
      }
    })
      .then(() => {
        enqueueSnackbar(t('crm.project.project.milestoneDeleteSuccess'), {
          variant: 'success',
        });
        setOpenMilestoneDeleteDialog(false);
        setMilestoneDeleteLoading(false);
      })
      .catch((error) => {
        console.error('Error deleting milestone:', error);
        enqueueSnackbar(t('crm.error'), { variant: 'error' });
        setMilestoneDeleteLoading(false);
      });
  };

  // effects

  useEffect(() => {
    const categorizedTasks: MilestoneTasks = milestones.reduce(
      (acc, milestone) => {
        acc[milestone._id] = [];
        return acc;
      },
      { uncategorized: [] } as MilestoneTasks,
    );

    allTasks?.forEach((task) => {
      const milestoneId = projectDetailData?.taskList?.find((t) => t.task?._id === task._id)
        ?.milestone?._id;
      if (milestoneId && categorizedTasks[milestoneId]) {
        categorizedTasks[milestoneId].push({
          id: task._id,
          milestone: milestoneId,
          subject: task.subject,
          description: task.description,
          checklistTotal: task.checklist?.length ?? 0,
          checklistCompleted:
            task.checklist?.filter((item: ITaskChecklistItem) => item.isChecked).length ?? 0,
          documentCount: task.documents?.length ?? 0,
          assignees: task.assignees ?? [],
          dueDate: task.dueDate ?? undefined,
        });
      } else {
        categorizedTasks['uncategorized'].push({
          id: task._id,
          milestone: 'uncategorized',
          subject: task.subject,
          description: task.description,
          checklistTotal: task.checklist?.length ?? 0,
          checklistCompleted:
            task.checklist?.filter((item: ITaskChecklistItem) => item.isChecked).length ?? 0,
          documentCount: task.documents?.length ?? 0,
          assignees: task.assignees ?? [],
          dueDate: task.dueDate ?? undefined,
        });
      }
    });

    setMilestoneTasks(categorizedTasks);
  }, [allTasks, milestones, projectDetailData]);

  useEffect(() => {
    if (milestoneTasks['uncategorized']?.length > 0) {
      setColumns([
        UNCATEGORIZED_COLUMN,
        ...milestones.map((milestone) => ({
          id: milestone._id,
          title: milestone.name,
        })),
      ]);
    } else {
      setColumns([
        ...milestones.map((milestone) => ({
          id: milestone._id,
          title: milestone.name,
        })),
      ]);
    }
  }, [milestoneTasks, milestones]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
      }}
    >
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <Stack direction="row" spacing={2} alignItems="flex-start">
          <SortableContext items={columnsId}>
            {columns.map((col) => (
              <ColumnContainer
                key={col.id}
                column={col}
                tasks={milestoneTasks[col.id] || []}
                taskCount={milestoneTasks[col.id]?.length || 0}
                updateTask={updateTask}
                setSelectedTask={setSelectedTask}
                setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
                handleLoadMore={handleLoadMore}
                onEditMilestone={handleEditMilestone}
                onDeleteMilestone={handleDeleteMilestone}
              />
            ))}
          </SortableContext>
        </Stack>

        {createPortal(
          <DragOverlay>
            {activeColumn && (
              <ColumnContainer
                column={activeColumn}
                tasks={tasks.filter((task) => task.milestone === activeColumn.id)}
                taskCount={taskCounts[activeColumn.id]}
                updateTask={updateTask}
                setSelectedTask={setSelectedTask}
                setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
                handleLoadMore={handleLoadMore}
              />
            )}
            {activeTask && (
              <TaskCard
                task={activeTask}
                updateTask={updateTask}
                setSelectedTask={setSelectedTask}
                setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
              />
            )}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>

      <Box sx={{ width: '100%', height: '10px' }} />

      <TaskDetailDrawer
        setSelectedTask={setSelectedTask}
        open={openTaskDetailDrawer}
        setOpen={setOpenTaskDetailDrawer}
        selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
      />

      <TaskUpdateDrawerWrapper
        open={openTaskUpdateDrawer}
        setOpen={setOpenTaskUpdateDrawer}
        targetId={selectedTask?.relatedEntity?._id ?? ''}
        targetEntityRef={selectedTask?.relatedEntityRef}
        item={selectedTask}
        overlayType={OverlayType.drawer}
      />

      <TaskDeleteDialog
        open={openTaskDeleteDialog}
        selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
        setItem={setSelectedTask}
        key={selectedTask?._id}
        onClose={() => {
          setSelectedTask(undefined);
          setOpenTaskDeleteDialog(false);
        }}
      />

      {selectedMilestone && (
        <MilestoneUpdateOverlay
          open={openMilestoneUpdateDialog}
          setOpen={setOpenMilestoneUpdateDialog}
          selectedMilestone={selectedMilestone}
          projectId={props.projectDetailData?._id ?? ''}
          overlayType={OverlayType.dialog}
        />
      )}

      {selectedMilestone && (
        <MilestoneDeleteDialog
          open={openMilestoneDeleteDialog}
          projectId={props.projectDetailData?._id ?? ''}
          milestoneId={selectedMilestone._id}
          onClose={() => {
            setSelectedMilestone(undefined);
            setOpenMilestoneDeleteDialog(false);
          }}
          onDelete={confirmDeleteMilestone}
          isLoading={milestoneDeleteLoading}
        />
      )}
    </Box>
  );
});

MilestoneKanbanComponent.displayName = 'MilestoneKanbanComponent';

export default MilestoneKanbanComponent;

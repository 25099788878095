import profileLocale from "../domains/profile/locales/es";

const locale = {
  profile: {
    ...profileLocale,
  },
  profileo: "Solicitud de presupuesto"
};

export default locale;

const locale = {
  demo: 'Demo',
  adjustment: 'Anpassung',
  currency: 'Währung',
  exchangeRates: 'Wechselkurse',
  fromCurrency: 'Von Währung',
  toCurrency: 'Zu Währung',
  rate: 'Kurs',
  updatedAt: 'Aktualisiert am',
  language: 'Sprache',
  locale: 'Gebietsschema',
  updateSettings: 'Einstellungen aktualisieren',
  NDA: 'Geheimhaltungsvereinbarung',
  followUpMailTemplate: 'Nachfass-E-Mail-Vorlage',
  emailType: 'E-Mail-Typ',
  subject: 'Betreff',
  content: 'Inhalt',
  variables: 'Variablen',
  prefix: 'Präfix',
  paymentPrefix: 'Zahlungspräfix',
  returnPrefix: 'Rückgabepräfix',
  termsAndConditions: 'Allgemeine Geschäftsbedingungen',
  paymentMethods: 'Zahlungsmethoden',
  companyBackground: 'Unternehmenshintergrund',
  companyReferences: 'Unternehmensreferenzen',
  timezone: 'Zeitzone',
  updateSuccess: 'Einstellungen erfolgreich aktualisiert',
  name: 'Name',
  surname: 'Nachname',
  email: 'E-Mail',
  phone: 'Telefon',
  identityNumber: 'Ausweisnummer',
  vat: 'Umsatzsteuer-ID',
  country: 'Land',
  state: 'Bundesland',
  city: 'Stadt',
  street: 'Straße',
  address: 'Adresse',
  taxOffice: 'Finanzamt',
  zip: 'PLZ',
  fullName: 'Vollständiger Name',
  update: 'Aktualisieren',
  create: 'Erstellen',
  updateBillingInfo: 'Rechnungsinformationen aktualisieren',
  updateBillingInfoSuccess: 'Rechnungsinformationen erfolgreich aktualisiert',
  commonSettingsUpdateSuccess: 'Einstellungen erfolgreich aktualisiert',
  save: 'Speichern',
  organizationSettings: 'Organisation',
  monthly: 'monatlich',
  trial: 'Testversion',
  free: 'Kostenlos',
  trialEnds: 'Testversion endet',
  browsePackages: 'Pakete durchsuchen',
  cancelSubscription: 'Abonnement kündigen',
  updateOrganizationLogoSuccess: 'Organisationslogo erfolgreich aktualisiert',
  organizationLogo: 'Organisationslogo',
  summary: 'Zusammenfassung',
  website: 'Webseite',
  legalName: 'Rechtlicher Name',
  dateFormat: 'Datumsformat',
  timeFormat: 'Zeitformat',
  vatNumber: 'Umsatzsteuer-ID',
  taxRate: 'Steuersatz',
  exchangeRateMode: 'Wechselkursmodus',
  general: 'Allgemein',
  localizations: 'Lokalisierungen',
  finance: 'Finanzen',
  generalInfo: 'Allgemeine Informationen',
  socialMedia: 'Soziale Medien',
  billingInfo: 'Rechnungsinformationen',
  cards: 'Karten',
  currentPlan: 'Aktueller Plan',
  invoices: 'Rechnungen',
  cardListSecure: 'Ihre Karten werden in der iyzico-Datenbank gespeichert.',
  noInvoiceContent1: "Sie haben noch keine Rechnung.",
  noInvoiceContent2:
    'Verfolgen und verwalten Sie hier Zahlungen für Ihre Rechnungen, um Ihre Finanzen organisiert und aktuell zu halten.',
  noLanguageContent1: "Sie haben noch keine Sprache.",
  noLanguageContent2:
    'Erstellen und verwalten Sie hier Sprachen, um Ihre Website für ein globales Publikum zugänglich zu machen.',
  noLeadContent1: 'Lead-Einstellungen sind derzeit nicht verfügbar.',
  noLeadContent2:
    'Passen Sie an, wie Leads erfasst, zugewiesen und verfolgt werden, um Ihren CRM-Workflow zu optimieren.',
  noCustomerContent1: 'Noch keine Kundeneinstellungen konfiguriert.',
  noCustomerContent2:
    'Richten Sie Präferenzen ein, um Kundeninformationen und Interaktionen effektiv in Ihrem CRM zu verwalten.',
  noEstimateContent1: 'Noch keine Rechnungseinstellungen konfiguriert.',
  noEstimateContent2:
    'Passen Sie hier Ihre Rechnungspräferenzen an, um die Abrechnung zu optimieren und genaue Aufzeichnungen zu gewährleisten.',
  noProposalContent1: 'Angebotseinstellungen sind derzeit nicht verfügbar.',
  noProposalContent2:
    'Passen Sie hier Ihre Angebotspräferenzen an, um die Kundenkommunikation zu optimieren und den Verkauf zu verbessern.',
  noInvoiceContent3: 'Noch keine Rechnungseinstellungen konfiguriert.',
  noInvoiceContent4:
    'Passen Sie hier Ihre Rechnungspräferenzen an, um die Abrechnung zu optimieren und genaue Aufzeichnungen zu gewährleisten.',
  noProjectContent1: 'Projekteinstellungen wurden noch nicht aufgerufen.',
  noProjectContent2:
    'Passen Sie hier Ihre Projektpräferenzen an, um das Aufgabenmanagement und den Workflow in Ihrem CRM zu optimieren.',
  noTaskContent1: 'Aufgabeneinstellungen wurden noch nicht aufgerufen.',
  noTaskContent2:
    'Konfigurieren Sie hier Ihre Aufgabenpräferenzen, um das Aufgabenmanagement zu optimieren und die Teamproduktivität zu verbessern.',
  noTicketContent1: 'Ticket/Support-Einstellungen wurden noch nicht aufgerufen.',
  noTicketContent2:
    'Richten Sie hier Ihre Präferenzen ein, um Kundenanfragen zu verwalten und zeitnahe Lösungen zu gewährleisten.',
  noFileManagementContent1: 'Dateiverwaltungseinstellungen wurden noch nicht aufgerufen.',
  noFileManagementContent2:
    'Konfigurieren Sie hier Ihre Präferenzen, um Dokumente effizient in Ihrem CRM zu organisieren und zu verwalten.',
  noCalendarContent1: 'Kalendereinstellungen wurden noch nicht aufgerufen.',
  noCalendarContent2:
    'Richten Sie hier Ihre Präferenzen ein, um Ihren Kalender anzupassen und die Terminplanung in Ihrem CRM zu optimieren.',
  noNotificationContent1: 'Benachrichtigungseinstellungen wurden noch nicht aufgerufen.',
  noNotificationContent2:
    'Passen Sie hier Ihre Benachrichtigungspräferenzen an, um über wichtige Aktivitäten in Ihrem CRM informiert zu bleiben.',
  noLeadPlusContent1: 'Kein aktives Lead+ Produkt oder Plan.',
  noLeadPlusContent2:
    'Kaufen oder autorisieren Sie einen Plan, um vollen Zugriff zu erhalten und Ihre Lead+ Einstellungen anzupassen.',
  noMarketingContent1: 'Kein aktives Marketing-Produkt oder -Plan.',
  noMarketingContent2:
    'Kaufen oder autorisieren Sie einen Plan, um vollen Zugriff zu erhalten und Ihre Marketing-Einstellungen anzupassen.',
  noWindowContent1: 'Kein aktives Window-Produkt oder -Plan.',
  noWindowContent2:
    'Kaufen oder autorisieren Sie einen Plan, um vollen Zugriff zu erhalten und Ihre Window-Einstellungen anzupassen.',
  noCommunityContent1: 'Kein aktives Community-Produkt oder -Plan.',
  noCommunityContent2:
    'Kaufen oder autorisieren Sie einen Plan, um vollen Zugriff zu erhalten und Ihre Community-Einstellungen anzupassen.',
  noIntegrationsContent1: 'Noch keine Integrationen eingerichtet.',
  noIntegrationsContent2:
    'Konfigurieren Sie hier Integrationen, um die Fähigkeiten von CoCRM zu erweitern und Workflows mit autorisierten Verbindungen zu optimieren.',
  noMenuContent1: 'Menüeinstellungen sind derzeit nicht verfügbar.',
  noMenuContent2:
    'Autorisieren Sie den Zugriff, um Ihr Menülayout anzupassen und die Navigation in Ihrem CRM zu optimieren.',
  noLegalAgreementsContent1: 'Einstellungen für rechtliche Vereinbarungen sind derzeit nicht verfügbar. ',
  noLegalAgreementsContent2:
    'Autorisieren Sie den Zugriff, um rechtliche Vereinbarungen in Ihrem CRM zu verwalten und anzupassen.',
  noCrmContent1: 'Kein aktives CRM-Produkt oder -Plan.',
  noCrmContent2:
    'Kaufen oder autorisieren Sie einen Plan, um vollen Zugriff zu erhalten und Ihre CRM-Einstellungen anzupassen.',
  noUserListContent1: 'Kein aktives Benutzerlisten-Produkt oder -Plan.',
  noUserListContent2:
    'Kaufen oder autorisieren Sie einen Plan, um vollen Zugriff zu erhalten und Ihre Benutzerlisten-Einstellungen anzupassen.',
  noPermissionsContent1: 'Kein aktives Berechtigungs-Produkt oder -Plan.',
  noPermissionsContent2:
    'Kaufen oder autorisieren Sie einen Plan, um vollen Zugriff zu erhalten und Ihre Berechtigungseinstellungen anzupassen.',
  lead: 'Lead',
  crm: 'CRM',
  menu: 'Menü',
  customer: 'Kunde',
  estimate: 'Angebot',
  proposal: 'Vorschlag',
  invoice: 'Rechnung',
  project: 'Projekt',
  task: 'Aufgabe',
  ticket: 'Ticket',
  fileManagement: 'Dateiverwaltung',
  calendar: 'Kalender',
  notification: 'Benachrichtigung',
  leadPlus: 'Lead+',
  marketing: 'Marketing',
  window: 'Window',
  community: 'Community',
  integrations: 'Integrationen',
  userList: 'Benutzerliste',
  permissions: 'Berechtigungen',
  userType: 'Benutzertyp',
  companyName: 'Firmenname',
  allowableFileFormats: 'Zulässige Dateiformate',

  // currentPlan
  yourCurrentPlan: 'Ihr aktueller Plan ist ',
  yourCurrentPlanDesc: 'Ein einfacher Start für jeden',
  activeUntil: 'Aktiv bis',
  activeUntilDesc: 'Wir senden Ihnen eine Benachrichtigung bei Ablauf des Abonnements',
  paymentDesc: 'Die Zahlung wird Ihrem Konto belastet',
  paymentForTrialDesc: 'Sie müssen ein Abonnement erwerben, um die Nutzung fortzusetzen',
  perMonth: 'Pro Monat',
  prtYear: 'Pro Jahr',
  perMonthDesc: 'Sie können Ihr Abonnement jederzeit kündigen',
  upgradePlan: 'Plan upgraden',
  attentionTitle: 'Wir benötigen Ihre Aufmerksamkeit!',
  attentionDesc: 'Ihr Plan erfordert Updates',
  days: 'Tage',
  of: 'von',
  remainingDesc: 'verbleibende Tage, bis Ihr Plan ein Update erfordert',
  usageOfStorage: 'Speichernutzung',
  users: 'Benutzer',

  //legalAgreements
  legalAgreements: 'Rechtliche Vereinbarungen',
  createLegalAgreement: 'Rechtliche Vereinbarung erstellen',
  updateLegalAgreement: 'Rechtliche Vereinbarung aktualisieren',
  deleteLegalAgreement: 'Rechtliche Vereinbarung löschen',
  createLegalAgreementSuccess: 'Rechtliche Vereinbarung erfolgreich erstellt',
  updateLegalAgreementSuccess: 'Rechtliche Vereinbarung erfolgreich aktualisiert',
  deleteLegalAgreementSuccess: 'Rechtliche Vereinbarung erfolgreich gelöscht',
  deleteLegalAgreementDesc: 'Sind Sie sicher, dass Sie diese rechtliche Vereinbarung löschen möchten?',

  // cards
  deleteCard: 'Karte löschen',
  addCard: 'Neue Karte hinzufügen',
  createCardSuccess: 'Karte erfolgreich erstellt',
  addCardSuccess: 'Karte erfolgreich hinzugefügt',
  deleteCardSuccess: 'Karte erfolgreich gelöscht',
  deleteCardDesc: 'Sind Sie sicher, dass Sie diese Karte löschen möchten?',
  requiredCard: 'Kartennummer ist erforderlich',
  requiredCardHolder: 'Name des Karteninhabers ist erforderlich',
  requiredExpireMonth: 'Ablaufmonat ist erforderlich',
  requiredExpireYear: 'Ablaufjahr ist erforderlich',
  requiredCvc: 'CVC ist erforderlich',
};

export default locale; 
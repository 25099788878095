const locale = {
  invoice: "Factura",
  invoices: "Facturas",
  create: "Crear Factura",
  update: "Actualizar Factura",
  delete: "Eliminar",
  view: "Ver Factura",
  list: "Listar Facturas",
  createdSuccessfully: "Factura creada con éxito",
  updatedSuccessfully: "Factura actualizada con éxito",
  deleteConfirm:
    "¿Está seguro de que desea eliminar? Esta acción no se puede deshacer.",
  deletedSuccessfully: "Factura eliminada con éxito",
  createInfo: "Puede crear nuevas facturas aquí.",
  prefix: "Prefijo",
  invoiceId: "Factura",
  timeZone: "Zona Horaria",
  customerId: "Cliente",
  customerName: "Nombre del Cliente",
  customerType: "Tipo de Cliente",
  customerCity: "Ciudad del Cliente",
  customerCountry: "País del Cliente",
  customerContactEmail: "Correo del Cliente",
  customerContactLastName: "Apellido del Cliente",
  customerContactFirstName: "Nombre del Cliente",
  billingInfo: "Información de Facturación",
  ["billingInfo.fullName"]: "Nombre Completo",
  ["billingInfo.phone"]: "Teléfono",
  ["billingInfo.address"]: "Dirección",
  ["billingInfo.country"]: "País",
  ["billingInfo.state"]: "Estado/Provincia",
  ["billingInfo.city"]: "Ciudad",
  ["billingInfo.street"]: "Calle",
  ["billingInfo.zip"]: "Código Postal",
  ["billingInfo.vat"]: "NIF/CIF",
  ["billingInfo.taxOffice"]: "Oficina Tributaria",
  shippingInfo: "Información de Envío",
  ["shippingInfo.fullName"]: "Nombre Completo",
  ["shippingInfo.phone"]: "Teléfono",
  ["shippingInfo.address"]: "Dirección",
  ["shippingInfo.country"]: "País",
  ["shippingInfo.state"]: "Estado/Provincia",
  ["shippingInfo.city"]: "Ciudad",
  ["shippingInfo.street"]: "Calle",
  ["shippingInfo.zip"]: "Código Postal",
  generalInformation: "Información General*",
  prospect: "Prospecto*",
  detailedInformation: "Información Detallada",
  billingInformation: "Información de Facturación*",
  shippingInformation: "Información de Envío",
  productsAndServices: "Productos y Servicios*",
  salesAgents: "Agentes de Ventas",
  legal: "Legal",
  signatureTab: "Firma*",
  isRecurring: "Es Recurrente",
  recurringFrequency: "Frecuencia de Recurrencia",
  recurringDueDate: "Fecha de Vencimiento Recurrente",
  recurringCount: "Número de Recurrencias",
  language: "Idioma",
  date: "Fecha",
  expireDate: "Fecha de Vencimiento",
  sendEmailOnExpiration: "Enviar Correo al Vencimiento",
  expirationEmailSentDate: "Fecha de Envío del Correo de Vencimiento",
  allowedPaymentMethods: "Métodos de Pago Permitidos",
  paymentTerms: "Condiciones de Pago",
  currency: "Moneda",
  products: "Productos",
  addProducts: "Añadir Productos",
  offers: "Ofertas",
  reference: "Referencia",
  salesAgentIds: "Agentes de Ventas",
  allowComments: "Permitir Comentarios",
  useLegalNDA: "Usar Acuerdo de Confidencialidad",
  legalNDAId: "Acuerdo de Confidencialidad",
  useLegalTermAndCond: "Usar Términos y Condiciones",
  legalTermAndCondId: "Términos y Condiciones",
  signerUserId: "Usuario Firmante",
  listInfo: "Lista de todas las facturas.",
  importedError: "Error de importación",
  importedSuccessfully: "Importado con éxito",
  downloadFormError: "Error al descargar el formulario",
  downloadFormSuccessfully: "Formulario descargado con éxito",
  downloadExampleFormSuccessfully: "Formulario de ejemplo descargado con éxito",
  downloadExampleFormError: "Error al descargar el formulario de ejemplo",
  title: "Facturas",
  emptyState1: "No se encontraron facturas aquí.",
  emptyState2: "Puede crear o importar facturas inmediatamente.",
  createInvoice: "Crear Factura",
  importInvoice: "Importar Factura",
  importTitle: "Importar Facturas",
  downloadExample: "Descargar Ejemplo",
  importText1: "Puede importar facturas desde esta página.",
  importText2: "Puede utilizar el formulario de ejemplo a continuación para publicar sus facturas.",
  importExport: "Importar/Exportar",
  exportData: "Exportar Datos",
  importData: "Importar Datos",
  b2b: "B2B",
  b2c: "B2C",
  viewInvoice: "Ver",
  edit: "Editar",
  junk: "Basura",
  unJunk: "Recuperar",
  addNewRole: "Añadir Nuevo Rol",
  actions: "Acciones",
  allowedPaymentMethodsPlaceholder: "Añadir Método de Pago",
  timezone: "Zona Horaria",
  followUp: "Seguimiento",
  lastSent: "Último Envío",
  dueDate: "Fecha de Vencimiento",
  sendEmail: "Enviar Correo",
  customer: "Cliente",
  country: "País",
  city: "Ciudad",
  contactFirstName: "Nombre de Contacto",
  contactLastName: "Apellido de Contacto",
  contactEmail: "Correo de Contacto",
  services: "Servicios",
  businessName: "Nombre de la Empresa",
  governmentAgency: "Agencia Gubernamental",
  fullName: "Nombre Completo",
  VAT: "NIF/CIF",
  phoneNumber: "Número de Teléfono",
  state: "Estado/Provincia",
  ZIP: "Código Postal",
  address: "Dirección",
  sentDate: "Fecha de Envío",
  name: "Nombre",
  description: "Descripción",
  productUnit: "Unidad",
  quantity: "Cantidad",
  price: "Precio",
  priceType: "Tipo de Precio",
  discountedPrice: "Precio con Descuento",
  finalPrice: "Precio Final",
  tax: "Impuesto",
  service: "Servicio",
  subTotal: "Subtotal",
  taxTotal: "Total de Impuestos",
  discount: "Descuento",
  product: "Producto",
  references: "Referencias",
  add: "Añadir",
  salesAgent: "Agente de Ventas",
  executiveSummary: "Resumen Ejecutivo",
  companyBackground: "Antecedentes de la Empresa",
  projectorServiceDesc: "Descripción Detallada del Proyecto/Servicio",
  costBreakdown: "Desglose de Costos",
  projectTimeline: "Cronograma del Proyecto",
  testimonialCaseStudies: "Testimonios y Casos de Estudio",
  conclusion: "Conclusión",

  sentSuccessfully: 'Factura enviada con éxito',
  send: 'Enviar Factura',
  resend: 'Reenviar Factura',
  sendSureTitle: 'Enviar Factura',
  sendSureDesc: '¿Está seguro de que desea enviar esta factura?',
  changeStatus: 'Cambiar Estado',
  statusUpdatedSuccessfully: 'Estado actualizado con éxito',

  // generalInfo
  generalInfoTitle: 'Información General',
  status: 'Estado',
  createdAt: 'Creado el',
  createdBy: 'Creado por',
  updatedAt: 'Actualizado el',
  notAvailable: 'No Disponible',
  updateStatus: 'Actualizar Estado',

  // customerInfo
  customerInfoTitle: 'Información del Cliente',
  contactPhoneNumber: 'Número de Teléfono',

  //productInfo
  productInfoTitle: 'Detalles del Producto',
  productNameUnknown: 'Producto Desconocido',
  noProducts: 'No hay productos disponibles.',

  //legalInfo
  legalInfoTitle: 'Información Legal',
  ndaTitle: 'Acuerdo de Confidencialidad (NDA)',
  termsTitle: 'Términos y Condiciones',
  documentName: 'Nombre del Documento',
  documentDescription: 'Descripción',
  documentContent: 'Contenido',

  //financialInfo
  financialInfoTitle: 'Información Financiera',
  shippingTotal: 'Total de Envío',
  total: 'Precio Total',
  noFinancialData: 'No hay datos financieros disponibles.',

  // documentInfo
  documentsTitle: 'Documentos',
  documentNameUnknown: 'Documento Desconocido',
  mimeType: 'Tipo de Archivo',
  download: 'Descargar',
  noDocuments: 'No hay documentos disponibles.',

  // salesAgents
  salesAgentsTitle: 'Agentes de Ventas',
  unknownAgent: 'Agente Desconocido',
  noSalesAgents: 'No hay agentes de ventas disponibles.',

  //signature
  signatureTitle: 'Información de Firma',
  additionalInfoTitle: 'Información Adicional',
  signature: 'Firma',
  signerUser: 'Usuario Firmante',

  //additionalInfo
  yes: 'Sí',
  no: 'No',
};

export default locale; 
const locale = {
  title: "Départements",
  delete: "Supprimer",
  deleteConfirm: "Êtes-vous sûr de vouloir supprimer ce département? Cette action ne peut pas être annulée.",
  deletedSuccessfully: "Département supprimé avec succès",
  create: "Créer un Département",
  departmentNameLabel: "Nom du Département",
  rolesLabel: "Rôles",
  addRole: "Ajouter un Rôle",
  createDepartmentButton: "Créer un Département",
  createSuccess: "Département créé avec succès",
  columnId: "#",
  columnName: "Nom",
  columnRoles: "Rôles",
  columnActions: "Actions",
  headerTitle: "Départements",
  createDepartment: "Créer un Département",
  rolesButton: "Rôles",
  emptyState1: "Aucun département trouvé",
  emptyState2: "Veuillez créer un nouveau département",
  addNewRole: "Ajouter un Nouveau Rôle",
  updateDepartmentTitle: "Mettre à jour le Département",
  updateDepartmentButton: "Mettre à jour",
  updateSuccess: "Département mis à jour avec succès",
};

export default locale; 
const locale = {
  leadForms: 'Формы Лидов',
  title: 'Формы Лидов',
  newForm: 'Новая Форма',
  emptyStateDesc1:
    'Ваши формы лидов будут отображаться здесь по мере их создания для сбора ценной информации о клиентах.',
  emptyStateDesc2:
    'Легко управляйте и настраивайте свои формы, чтобы убедиться, что вы собираете правильные данные для каждого взаимодействия.',

  create: 'Создать Форму Лида',
  update: 'Обновить Форму Лида',
  updateSuccess: 'Форма лида успешно обновлена',
  createSuccess: 'Форма лида успешно создана',
  deleteSuccess: 'Форма лида успешно удалена',
  deleteConfirm: 'Вы уверены, что хотите удалить эту форму лида?',
  deletedSuccessfully: 'Форма лида успешно удалена',

  // tabs
  detailedInfo: 'Подробная Информация',
  branding: 'Брендинг',
  formFields: 'Поля Формы',
  submission: 'Отправка*',
  notification: 'Уведомление',

  //fields
  addField: 'Добавить Поле',
  updateField: 'Обновить Поле',
  label: 'Метка',
  formName: 'Название Формы',
  defaultLanguage: 'Язык по Умолчанию',
  defaultPrefix: 'Префикс по Умолчанию',
  defaultStatus: 'Статус по Умолчанию',
  defaultSource: 'Источник по Умолчанию',
  defaultAssigned: 'Назначено по Умолчанию',
  defaultBusinessType: 'Тип Бизнеса по Умолчанию',
  defaultType: 'Тип по Умолчанию',
  defaultQualification: 'Квалификация по Умолчанию',
  defaultTags: 'Теги по Умолчанию',
  submitButtonText: 'Текст Кнопки Отправки',
  header: 'Заголовок',
  isRequired: 'Обязательно',
  type: 'Тип',
  defaultIsPublic: 'Публично по Умолчанию',
  followUpRedirectUrl: 'URL Перенаправления для Последующих Действий',
  followUpMessage: 'Сообщение для Последующих Действий',
  specificUsersToNotify: 'Конкретные Пользователи для Уведомления',
  willNotify: 'Будет Уведомлять',
  willNotifyResponsibleUsers: 'Будет Уведомлять Ответственных Пользователей',
  leadField: 'Поле Лида',
  placeholder: 'Заполнитель',
  fullNamePlaceholder: 'Введите ваше полное имя',
  emailPlaceholder: 'Введите вашу электронную почту',
  formTitle: 'Форма',
};

export default locale; 
const locale = {
  organization: 'Organization',
  organizations: 'Organizations',
  organizationChart: 'Organizational Chart',
  update: 'Update',
  delete: 'Delete',
  view: 'View',
  createdSuccessfully: 'User created successfully',
  updatedSuccessfully: 'User updated successfully',
  deleteConfirm: 'Are you sure you want to remove the user account "',
  deletedSuccessfully: 'User deleted successfully',
  info: 'You can add users to your team from this section.',
  name: 'Name',
  email: 'Email',
  surname: 'Surname',
  viewProfile: 'View Profile',
  roles: 'Roles',
  editChart: 'Edit Chart',
  save: 'Save',
  editOrganizationChart: 'Edit Organizational Chart',
  editOrganizationDetail: 'Drag and drop the users to the desired location.',
  createRole: 'Create Role',
  userList: 'User List',
  rolesInfo: 'You can create roles and assign permissions to them.',
  createUserSuccess: 'User created successfully',
  chart: 'Chart View',
  list: 'List View',
  users: 'Users',
  user: 'User',
  activeUsers: 'Active Users',
  activeUser: 'Active User',
  create: 'Create',
  new: 'New',
  roleName: 'Role Name',
  addRole: 'Add',
  updateRole: 'Update',
  deleteRole: 'Delete',
  roleCreatedSuccess: 'Role created successfully',
  roleUpdatedSuccess: 'Role updated successfully',
  roleDeletedSuccess: 'Role deleted successfully',
  errorFetchingPermissions: 'Error fetching permissions',
  errorFetchingRoles: 'Error fetching roles',
  errorCreatingRole: 'Error creating role',
  errorUpdatingRole: 'Error updating role',
  errorDeletingRole: 'Error deleting role',
  permissionUpdateSuccess: 'User permissions are updated successfully',
  subject: 'Subject',
  capabilities: 'Capabilities',
  allPermissions: 'All Permissions',
  all: 'All',
  advancedOptions: 'Advanced Options',
  id: 'ID',
  emailAddress: 'Email Address',
  status: 'Status',
  emailVerified: 'Email Verified',
  phoneNumber: 'Phone Number',
  country: 'Country',
  city: 'City',
  phone: 'Phone Number',
  lastLoginDate: 'Last Login Date',
  createdAt: 'Created At',
  actions: 'Actions',
  addNewRole: 'Add New Role',
  yes: 'Yes',
  no: 'No',
  details: 'Details',
  jobTitle: 'Job Title',
  deleteMessage: 'Are you sure you want to delete this user?',
  cancel: 'Cancel',
  userDeleteSuccess: 'User deleted successfully',
  userDeleteError: 'User delete failed',
  updateChartSuccessfully: 'Organization chart updated successfully',
  address: 'Address',
  birthDate: 'Birth Date',
  gender: 'Gender',
  language: 'Language',
  description: 'Description',
  rolesAndPermissions: 'Roles & Permissions',
  ownPermissionsAlert: "You can't change your own permissions.",
  zoom: 'Zoom',
  tree: 'Tree View',
  createSuccess: 'Organization chart created successfully',
  updateSuccess: 'Organization chart updated successfully',
  updateError: 'Error updating organization chart',
  fetchError: 'Error fetching organization chart',
  fetchSuccess: 'Organization chart fetched successfully',
  undefinedUser: 'Undefined User',
  chartUpdateSuccess: 'Chart updated successfully!',
  listView: 'List View',
  action: 'Action',
  actionScope: 'Action Scope',
  userDetail: 'User Detail',
  admin: 'Admin',
  department: 'Department',
  addUser: 'Add User',
  createDepartment: 'Create Department',
  updateUser: 'Update User',
  notDeleteOwnConfirm: "You can't delete yourself.",
  updateUserSuccess: 'User updated successfully',
  removeAccount: 'Remove Account',
  profile: 'Profile',
  permissions: 'Permissions',
  associatedLeads: 'Associated Leads',
  associatedCustomers: 'Associated Customers',
  associatedEstimates: 'Associated Estimates',
  associatedProposals: 'Associated Proposals',
  associatedInvoices: 'Associated Invoices',
  associatedTasks: 'Associated Tasks',
  about: 'About',
  detail: 'User Detail',
  users_: 'users',
  resendConfirmation: 'Resend Confirmation',
  resendSuccess: 'Confirmation sent successfully',
};

export default locale;

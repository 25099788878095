import settingsLocale from "../domains/settings/locales/es";

const locale = {
  settings: {
    ...settingsLocale,
  },
  demoo: "Solicitud de Presupuesto",
  settingsLabel: "Configuración",
  paymentSettings: "Pago",
  menuSettings: "Configuración del Menú",
  crmSettings: "CRM",
  leadPlusSettings: "Lead Plus",
  marketingSettings: "Marketing",
  windowSettings: "Ventana",
  communitySettings: "Comunidad",
  notificationSettings: "Notificación",
  organizationSettings: "Organización",
  myOrganization: "Mi Organización",
  generalSettings: "General",
  moduleSettings: "Configuración de Módulos",
  userSettings: "Configuración de Usuario",
  integrations: "Integraciones",
  legalAgreements: "Acuerdos Legales",
};

export default locale; 
 
import { Avatar, Icon, Stack, Typography } from '@mui/material';
import { Iconify } from 'components';
import AvatarGroupBase from 'components/avatar/AvatarGroupBase';
import { TCellRenderFunc } from 'components/baseDataGrid/infra/types';
import { IUserProfile as ICommonUserProfile, IEntity, IUser } from 'corede-common';

/**
 * @note if `image` not present `icon` will be showed if present
 */
export interface IEntityProfile extends IEntity {
  fullName: string;
  secondaryInfo?: string;
  image?: string;
  icon?: string;
}

export interface IUserProfile extends Partial<ICommonUserProfile> {}

/**
 * The entity may have following fields:
 * - fullName: string
 * - email: string
 * - image: string
 */
export function EntityProfileColumnCellComponent(): TCellRenderFunc<any, IEntityProfile> {
  return (cellParams) => {
    return !cellParams.value ? (
      <div>{`-`}</div>
    ) : (
      <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={1}>
        {!cellParams.value?.image && cellParams.value?.icon ? (
          <Iconify icon={cellParams.value?.icon} width={30} />
        ) : (
          <Avatar
            alt={cellParams.value?.fullName}
            src={cellParams.value?.image}
            sx={{ width: 35, height: 35 }}
          />
        )}

        <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} gap={0.5}>
          {cellParams.value?.fullName && (
            <Typography fontSize={'12px'} fontWeight={500} color={'text.primary'}>
              {cellParams.value?.fullName}
            </Typography>
          )}

          {cellParams.value?.secondaryInfo && (
            <Typography fontSize={'10px'} fontWeight={500} color={'text.secondary'}>
              {cellParams.value?.secondaryInfo}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  };
}

export function UserProfileColumnCellComponent(): TCellRenderFunc<any, IUserProfile> {
  return (cellParams) => {
    return !cellParams.value ? (
      <div>{`-`}</div>
    ) : (
      <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={1}>
        <Avatar
          alt={cellParams.value?.name}
          src={cellParams.value?.profileImage?.thumbnailPublicUrl}
          sx={{ width: 35, height: 35 }}
        />
        <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} gap={0.5}>
          {cellParams.value?.name && (
            <Typography fontSize={'12px'} fontWeight={500} color={'text.primary'}>
              {cellParams.value?.name}
            </Typography>
          )}

          {cellParams.value?.surname && (
            <Typography fontSize={'12px'} fontWeight={500} color={'text.primary'}>
              {cellParams.value?.surname}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  };
}

export function MultiUserColumnCellComponent(): TCellRenderFunc<any, any> {
  return (cellParams) => {
    console.log(cellParams?.value);
    // return <div>{`-`}</div>;
    return !cellParams.value || cellParams.value?.length === 0 ? (
      <div>{`-`}</div>
    ) : (
      <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={1}>
        <AvatarGroupBase userList={cellParams.value} width={30} />
      </Stack>
    );
  };
}

const locale = {
  // actions
  create: 'Crear Lead',
  update: 'Actualizar Lead',
  detail: 'Detalle del Lead',
  view: 'Ver Lead',
  list: 'Listar Leads',
  addNewRole: 'Añadir Nuevo Rol',
  tagsPlaceholder: 'Añadir etiquetas',
  listInfo: 'Lista de todos los leads.',
  weeklyReport: 'Informe Semanal',

  // situational messages
  createdSuccessfully: 'Lead creado con éxito',
  updatedSuccessfully: 'Lead actualizado con éxito',
  deleteConfirm: '¿Está seguro de que desea eliminar? Esta acción no se puede deshacer.',
  deletedSuccessfully: 'Lead eliminado con éxito',
  createInfo: 'Puede crear nuevos leads aquí.',
  updateLeadSuccess: 'Lead actualizado con éxito',
  createLeadSuccess: 'Lead creado con éxito',
  emptyState1: 'No se encontraron leads aquí.',
  emptyState2: 'Puede crear o importar leads inmediatamente.',

  // import
  importLead: 'Importar Lead',
  importTitle: 'Importar Leads',
  importText1: 'Puede importar leads desde esta página.',
  importText2: 'Puede utilizar el formulario de ejemplo a continuación para publicar sus leads.',

  // fields and names
  lead: 'Lead',
  leads: 'Leads',
  title: 'Listar Leads',
  assigneds: 'Asignados',
  qualification: 'Calificación',
  justOwn: 'Solo Propios',
  isJunk: 'Es Basura',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Basura',
  unJunk: 'No Basura',
  prospectName: 'Nombre del Prospecto: ',
  businessType: 'Tipo de Negocio',
  leadType: 'Tipo de Lead',
  leadCount: 'leads',
  leadValue: 'Valor del Lead',
  tracking: 'Seguimiento',
  image: 'Imagen del Lead',
  
  // contact
  addContact: 'Añadir Contacto',
  contactedDate: 'Fecha de Contacto',
  emptyStateContactContent1: 'No se encontraron contactos aquí.',
  contactDetail: 'Detalle de Contacto',
  contactHistory: 'Historial de Contacto',
  lastContacted: 'Último Contacto',
  lastContact: 'Último Contacto',
  contacted: 'Contactado',

  // statistics
  leadConversions: 'Conversiones de Leads',

  // lead to customer
  convertToCustomer: 'Convertir a Cliente',
  customerType: 'Tipo de Cliente',
  leadConvertedSuccessfully: 'Lead convertido con éxito',

  onSiteEstimateWith: 'Estimación en sitio con',

  // labels
  welcome: 'Bienvenido',
  description: 'Tu progreso de leads esta semana es increíble. Continuemos.',
};

export default locale; 
import projectLocale from "../subdomains/project/locales/pt";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  project: {
    ...projectLocale,
  },
};

export default locale; 
const locale = {
  title: 'Modelo de Contrato',
  emptyState1: 'Ainda não há modelos de contrato.',
  emptyState2:
    'Armazene e gerencie todos os detalhes do contrato aqui para agilizar seu faturamento e manter acordos claros com seus clientes.',
  create: 'Criar Modelo de Contrato',
  update: 'Atualizar Modelo de Contrato',
  delete: 'Excluir Modelo de Contrato',
  createSuccess: 'Modelo de contrato criado com sucesso.',
  updateSuccess: 'Modelo de contrato atualizado com sucesso.',
  deleteSuccess: 'Modelo de contrato excluído com sucesso.',
  deleteConfirm: 'Tem certeza de que deseja excluir este modelo de contrato? Esta ação não pode ser desfeita.',
};

export default locale; 
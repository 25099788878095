import { IFilter, Language } from "corede-common";
import { t } from "i18next";
import { handleDownloadList } from "apps/crm/util/handleDownload.action";

export const handleDownloadExpenseList = (params: {
  filter?: IFilter;
  currentLanguage: Language;
}) => {
  handleDownloadList({
    filePrefix: "expense",
    downloadUrl: "expense/export",
    currentLanguage: params.currentLanguage,
    filter: params.filter,
    successMessage: t("crm.expense.expense.downloadFormSuccessfully"),
  });
};

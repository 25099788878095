const locale = {
  readAllSuccess: 'Toutes les notifications ont été lues avec succès !',
  title: 'Notifications',
  markAllAsRead: 'Marquer tout comme lu.',
  close: 'Fermer',
  noNotifications1: "Aucune notification",
  noNotifications2: "Vous n'avez pas encore reçu de notifications.",
  refresh: 'Actualiser',
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { IInvoicePaymentDetailResult } from 'corede-common-cocrm';
import { PaymentDetailDrawerHeaderStatusComponent } from './PaymentDetailDrawer-rightPanel-header-status.component';
// import { PaymentDetailDrawerBaseInfoComponent } from './PaymentDetailDrawer-rightPanel-header-baseInfo.component';

export interface IPaymentDetailDrawerRightPanelHeaderComponentProps {
  paymentDetailData: IInvoicePaymentDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const PaymentDetailDrawerRightPanelHeaderComponent = (
  props: IPaymentDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      <PaymentDetailDrawerHeaderStatusComponent paymentDetailData={props.paymentDetailData} />
      {/* <PaymentDetailDrawerBaseInfoComponent paymentDetailData={props.paymentDetailData} /> */}
    </>
  );
};

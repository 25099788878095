const locale = {
  estimate: 'Estimate',
  title: 'Estimates',
  create: 'Create Estimate',
  update: 'Update Estimate',
  delete: 'Delete',
  view: 'View Estimate',
  list: 'List Estimates',
  detail: 'Estimate Detail',
  createdSuccessfully: 'Estimate created successfully',
  updatedSuccessfully: 'Estimate updated successfully',
  deleteConfirm: 'Are you sure you want to delete? This action cannot be undone.',
  deletedSuccessfully: 'Estimate deleted successfully',
  createInfo: 'You can create new estimates here.',
  prefix: 'Prefix',
  timeZone: 'TimeZone',
  prospectTargetEntityId: 'ProspectTarget',
  prospectTargetEntityRef: 'ProspectTargetRef',
  prospectType: 'Prospect Type',
  prospectName: 'Prospect Name',
  prospectVAT: 'Prospect VAT',
  prospectPhoneNumber: 'Prospect PhoneNumber',
  prospectCountry: 'Prospect Country',
  prospectState: 'Prospect State',
  prospectCity: 'Prospect City',
  prospectZIP: 'Prospect ZIP',
  prospectAddress: 'Prospect Address',
  prospectContactFirstName: 'Prospect Contact First Name',
  prospectContactLastName: 'Prospect Contact Last Name',
  prospectContactEmail: 'Prospect Contact Email',
  language: 'Language',
  date: 'Date',
  expireDate: 'Expire Date',
  sendEmailOnExpiration: 'Send Email',
  expirationEmailSentDate: 'Expiration Email Sent Date',
  projectOrServiceDesc: 'Project or Service Desc',
  paymentTerms: 'Payment Terms',
  products: 'Products',
  addProducts: 'Add Products',
  offers: 'Offers',
  salesAgentIds: 'Sales Agents',
  allowComments: 'Allow Comments',
  legalNDAId: 'NDA',
  legalTermAndCondId: 'Term And Cond',
  signerUserId: 'Signer User',
  listInfo: 'List of all estimates.',
  importedError: 'Imported error',
  importedSuccessfully: 'Imported successfully',
  downloadFormError: 'Download form error',
  downloadFormSuccessfully: 'Download form successfully',
  downloadExampleFormSuccessfully: 'Download example form successfully',
  downloadExampleFormError: 'Download example form error',
  emptyState1: 'No estimates found here.',
  emptyState2: 'You can create or import estimates immediately.',
  createEstimate: 'Create Estimate',
  importEstimate: 'Import Estimate',
  importTitle: 'Import Estimates',
  downloadExample: 'Download Example',
  importText1: 'You can import estimates from this page.',
  importText2: 'You can use the sample form below to publish your estimates.',
  importExport: 'Import/Export',
  exportData: 'Export Data',
  importData: 'Import Data',
  b2b: 'B2B',
  b2c: 'B2C',
  viewEstimate: 'View',
  edit: 'Edit',
  junk: 'Junk',
  unJunk: 'Un-Junk',
  addNewRole: 'Add New Role',
  actions: 'Actions',
  legalAgreements: 'Legal Agreements',
  businessName: 'Business Name',
  governmentAgency: 'Government Agency',
  fullName: 'Full Name',
  VAT: 'VAT',
  phoneNumber: 'Phone Number',
  country: 'Country',
  state: 'State',
  city: 'City',
  ZIP: 'ZIP',
  sentDate: 'Sent Date',
  name: 'Name',
  description: 'Description',
  productUnit: 'Unit',
  priceType: 'Price Type',
  service: 'Service',
  product: 'Product',
  services: 'Services',
  references: 'References',
  add: 'Add',
  salesAgent: 'Sales Agent',
  estimateCreateSuccess: 'Estimate created successfully',

  addEstimate: 'Add Estimate',
  noEstimateContent1: 'Estimates not found',
  noEstimate: 'There are no estimates',
  generalInfo: 'General Info',
  sentSuccessfully: 'Estimate sent successfully',
  send: 'Send Estimate',
  resend: 'Resend Estimate',
  sendSureTitle: 'Send Estimate',
  sendSureDesc: 'Are you sure you want to send this estimate?',
  changeStatus: 'Change Status',
  statusUpdatedSuccessfully: 'Status updated successfully',

  // generalInfo
  generalInfoTitle: 'General Information',
  estimateId: 'Estimate',
  status: 'Status',
  createdAt: 'Created At',
  reference: 'Reference',
  createdBy: 'Created By',
  updatedAt: 'Updated At',
  notAvailable: 'Not Available',
  updateStatus: 'Update Status',

  // customerInfo
  customerInfoTitle: 'Customer Information',
  contactFirstName: 'First Name',
  contactLastName: 'Last Name',
  contactEmail: 'Email',
  contactPhoneNumber: 'Phone Number',
  address: 'Address',

  //productInfo
  price: 'Price',
  productInfoTitle: 'Product Details',
  productNameUnknown: 'Unknown Product',
  discountedPrice: 'Discounted Price',
  quantity: 'Quantity',
  finalPrice: 'Final Price',
  tax: 'Tax Rate',
  noProducts: 'No products available.',

  //legalInfo
  legalInfoTitle: 'Legal Information',
  ndaTitle: 'Non-Disclosure Agreement (NDA)',
  termsTitle: 'Terms and Conditions',
  documentName: 'Document Name',
  documentDescription: 'Description',
  documentContent: 'Content',

  //financialInfo
  financialInfoTitle: 'Financial Information',
  currency: 'Currency',
  subTotal: 'Sub Total',
  discount: 'Discount',
  taxTotal: 'Tax Total',
  shippingTotal: 'Shipping Total',
  total: 'Total Price',
  noFinancialData: 'No financial data available.',

  // documentInfo
  documentsTitle: 'Documents',
  documentNameUnknown: 'Unknown Document',
  mimeType: 'File Type',
  download: 'Download',
  noDocuments: 'No documents available.',

  // salesAgents
  salesAgentsTitle: 'Sales Agents',
  unknownAgent: 'Unknown Agent',
  noSalesAgents: 'No sales agents available.',

  //signature
  signatureTitle: 'Signature Information',
  additionalInfoTitle: 'Additional Information',
  signature: 'Signature',
  signerUser: 'Signer User',

  //additionalInfo
  scopeOfWork: 'Scope of Work',
  assumptionsAndExclusions: 'Assumptions and Exclusions',
  useLegalNDA: 'Use Legal NDA',
  useLegalTermAndCond: 'Use Terms and Cond.',
  yes: 'Yes',
  no: 'No',

  //tabs
  generalInformation: 'General Info*',
  prospect: 'Prospect*',
  detailedInformation: 'Detailed Information',
  productsAndServices: 'Products and Services*',
  salesAgents: 'Sales Agents',
  legal: 'Legal',
  signatureTab: 'Signature*',
};

export default locale;


import profileLocale from "../subdomains/profile/locales/de";

const locale = {

  profile: {
    ...profileLocale,
  },
};

export default locale;

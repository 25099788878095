import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { IInvoicePaymentDetailResult, ProjectStatus } from 'corede-common-cocrm';
import React from 'react';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';
import { useTranslation } from 'react-i18next';

export interface IPaymentDetailDrawerLeftPanelProps {
  paymentDetailData: IInvoicePaymentDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentDetailDrawerLeftPanelContactDetailComponent = (
  props: IPaymentDetailDrawerLeftPanelProps,
) => {
  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack>
      {/* Genel Bilgiler */}
      <Stack direction={'column'} width={'100%'} gap={2} mt={2}>
        {!downLg && (
          <Stack direction={'column'} width={'100%'} gap={0.5}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                color: 'text.primary',
              }}
            >
              {t('crm.sale.payment.generalInfoTitle')}
            </Typography>
            <Divider />
          </Stack>
        )}

        {/* Payment Name */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.prefix')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.paymentDetailData?.paymentPrefix || '-'}
          </Typography>
        </Stack>

        {/* Payment Id */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.paymentId')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.paymentDetailData?.paymentId || '-'}
          </Typography>
        </Stack>

        {/* Created At */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.createdAt')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.paymentDetailData?.createdAt
              ? moment(props.paymentDetailData?.createdAt).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        </Stack>

        {/* Updated At */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.updatedAt')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.paymentDetailData?.updatedAt
              ? moment(props.paymentDetailData?.updatedAt).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

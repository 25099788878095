
import { Grid, Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, Container, Card, TextField } from '@mui/material';
import { FilledButton, MyTab, OutlinedButton } from 'components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache, useQuery } from "@apollo/client";
import { estimateDetailProspectQuery } from 'corede-common-cocrm';
import { BASE_URL } from 'utils';
import { CancelOutlined, CheckCircle, DownloadOutlined } from '@mui/icons-material';
import Logo from 'assets/images/logo.png'
import { useState } from 'react';

// http://localhost:3000/estimate/671c1d7cda3ec73e18a76778?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRpdHkiOnsiX2lkIjoiNjcxYzFkN2NkYTNlYzczZTE4YTc2Nzc4In0sImlhdCI6MTcyOTg5NTkxMH0.lLgBNRlp3GscDDEDFOYx3HPRWHWEevxp1szXCf1kwnA
// http://localhost:3000/estimate/671c98cdda3ec73e18a76795?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRpdHkiOnsiX2lkIjoiNjcxYzk4Y2RkYTNlYzczZTE4YTc2Nzk1In0sImlhdCI6MTcyOTkyNzM3N30.naDJUSx4M725gsvqZxmafpx1zGVNycvhAlvV-j2tX3g
const ProposalPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    if (!id) {
        navigate("/");
    }

    const client = new ApolloClient({
        uri: `${BASE_URL}/graphql`,
        cache: new InMemoryCache(),
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            accept: "application/json",
        },
    });

    return (
        <ApolloProvider client={client}>
            <ProposalContent />
        </ApolloProvider>
    );
};



const ProposalContent = () => {

    const [selectedTab, setSelectedTab] = useState("");
    const { data: estimateDetail, loading: estimateLoading, error: estimateError } = useQuery(estimateDetailProspectQuery)

    return <Container maxWidth="lg" sx={{ bgcolor: "background.alternative" }}>
        {/* Header */}
        <Grid container justifyContent="space-between" alignItems="flex-end" spacing={3} p={3}>
            <Grid item>
                <Box sx={{ mb: 2, }}>
                    <img width={"150px"} src={Logo} alt="logo" />
                </Box>
                <Typography variant="subtitle1" color="textSecondary">Web Design Estimate</Typography>
            </Grid>
            <Grid item>
                <Grid container justifyContent={"flex-end"} spacing={1}>
                    <Grid item>
                        <FilledButton
                            color='secondary'
                            leftIcon={<CheckCircle sx={{ mr: 1 }} />}
                            title='Accept'
                        />
                    </Grid>
                    <Grid item>
                        <OutlinedButton
                            color='error'
                            leftIcon={<CancelOutlined sx={{ mr: 1 }} />}
                            title='Decline'
                        />

                    </Grid>
                    <Grid item>
                        <OutlinedButton
                            color='primary'
                            leftIcon={<DownloadOutlined sx={{ mr: 1 }} />}
                            title='Download'
                        />

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="flex-start" spacing={3} mb={4} px={3}>

            <Grid item xs={12} md={8} mt={2}>
                {/* Proposal Details */}
                <Card sx={{ padding: 3, mb: 4 }}>
                    <Typography variant="h6"># PRO-000002</Typography>
                    <Divider sx={{ my: 2 }} />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>Rate</TableCell>
                                    <TableCell>Tax</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>1</TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" fontWeight="bold">Web Design</Typography>
                                        <Typography variant="body2" color="textSecondary">Enim amet quas ducimus commodi reprehenderit et. Et non numquam aut optio.</Typography>
                                    </TableCell>
                                    <TableCell>1</TableCell>
                                    <TableCell>$5,400.00</TableCell>
                                    <TableCell>0%</TableCell>
                                    <TableCell>$5,400.00</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>2</TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" fontWeight="bold">Consultant Services</Typography>
                                        <Typography variant="body2" color="textSecondary">Dolor laboriosam suscipit eius consequat. Aliquid unde sit dolorem velit magnam quos et.</Typography>
                                    </TableCell>
                                    <TableCell>1</TableCell>
                                    <TableCell>$500.00</TableCell>
                                    <TableCell>0%</TableCell>
                                    <TableCell>$500.00</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={5} align="right">Sub Total</TableCell>
                                    <TableCell>$5,900.00</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={5} align="right">Total</TableCell>
                                    <TableCell>$5,900.00</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>

                {/* Company Info */}
                <Card sx={{ padding: 3, mb: 4 }}>
                    <Typography variant="h6">Company Info</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body2" color="textSecondary">
                        Caterpillar, just as well. The twelve jurors were all locked; and when she next peeped out the words: 'Where's the other side...'
                    </Typography>
                </Card>

                {/* Proposal Information */}
                <Card sx={{ padding: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6">Proposal Information</Typography>
                            <Typography variant="body2" color="textSecondary">
                                Perfex INC<br />
                                172 Ivy Club Gottliebfurt<br />
                                New Heaven, Canada [CA] 2364
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6">Total $5,900.00</Typography>
                            <Typography variant="body2" color="textSecondary">Status: Accepted</Typography>
                            <Typography variant="body2" color="textSecondary">Date: 25/10/2024</Typography>
                            <Typography variant="body2" color="textSecondary">Open Till: 01/11/2024</Typography>
                        </Grid>
                    </Grid>
                </Card>

                {/* Signature */}
                <Card sx={{ padding: 3, mt: 4 }}>
                    <Typography variant="h6">Signature</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body2" color="textSecondary">
                        Signer Name: Jose Zulauf<br />
                        Signed Date: 25/10/2024<br />
                        IP Address: 102.22.251.41
                    </Typography>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box>
                    <MyTab
                        labels={["Summary", "Discussion"]}
                        setSelectedTab={setSelectedTab}
                    />
                    <Divider sx={{mb: 3}} />
                    {selectedTab === "Summary" ? <Summary /> : <Discussion />}
                </Box>
            </Grid>
        </Grid>
    </Container >;
};

const Summary = () => (
    <Box>
        <Typography variant="subtitle1" fontWeight="bold">EmployIF INC</Typography>
        <Typography variant="body2" color="textSecondary">
            172 Ivy Club Gottliebfurt<br />
            New Heaven<br />
            Canada [CA] 2364
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle1" fontWeight="bold">Estimate Information</Typography>
        <Typography variant="body2" color="textSecondary">
            Schaden-Watsica<br />
            46756 Hermiston Square Apt. 314<br />
            New Ezequielville Wisconsin US 26250<br />
            <a href="mailto:pullrich@example.net" style={{ color: 'blue', textDecoration: 'underline' }}>pullrich@example.net</a>
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" fontWeight="bold">Total $5,900.00</Typography>
        <Typography variant="body2" color="textSecondary">Status: Accepted</Typography>
        <Typography variant="body2" color="textSecondary">Date: 25/10/2024</Typography>
        <Typography variant="body2" color="textSecondary">Open Till: 01/11/2024</Typography>
    </Box>
);

const Discussion = () => (
    <Box>
        <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Add a comment..."
            sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary">Add Comment</Button>
    </Box>
);

export default ProposalPage;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState, useRef, memo } from 'react';
import { getCurrentLanguage } from 'localization';
import { IGraphqlVariables, unknownError } from 'corede-common';
import { ICreateLeadStatusInput } from 'corede-common-cocrm';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLeadStatusCreateMutation } from '../context/leadStatus.api';
import { validateCreateLeadStatusInput } from '../validations/create.validation';
import {
  AccessAlarm,
  AccessTime,
  Accessibility,
  AccountBalance,
  AccountCircle,
  Add,
  AddCircle,
  AddShoppingCart,
  Alarm,
  AllInclusive,
  Android,
  Announcement,
  Apps,
  Archive,
  ArrowBack,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ArrowForward,
  ArrowUpward,
  AspectRatio,
  Assessment,
  Assignment,
  AssignmentTurnedIn,
  Assistant,
  AttachFile,
  Audiotrack,
  Autorenew,
  Backup,
  BatteryFull,
  BeachAccess,
  Beenhere,
  Block,
  Bluetooth,
  Book,
  Bookmark,
  BorderAll,
  BrightnessAuto,
  Brush,
  Build,
  Business,
  Cached,
  Cake,
  Call,
  CameraAlt,
  Cancel,
  CardGiftcard,
  Cast,
  Chat,
  Check,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  ChildCare,
  Clear,
  Close,
  Cloud,
  CloudDownload,
  Code,
  Collections,
  ColorLens,
  Comment,
  Computer,
  ContactMail,
  ContentCopy,
  CreditCard,
  Crop,
  Dashboard,
  DataUsage,
  DateRange,
  Delete,
  Description,
  DesktopMac,
  DeveloperMode,
  Devices,
  Directions,
  DirectionsCar,
  DirectionsRun,
  DiscFull,
  Dns,
  Dock,
  Done,
  Drafts,
  Edit,
  Email,
  Error,
  ErrorOutline,
  Event,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Explore,
  Extension,
  Face,
  Favorite,
  FavoriteBorder,
  FileDownload,
  FilterList,
  Fingerprint,
  Flag,
  Flight,
  Folder,
  FormatAlignCenter,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatQuote,
  Forum,
  Forward,
  Fullscreen,
  Games,
  Gavel,
  GetApp,
  GpsFixed,
  Grade,
  Group,
  Headset,
  Healing,
  Hearing,
  Help,
  HighlightOff,
  History,
  Home,
  Hotel,
  HourglassFull,
  Https,
  Image,
  Info,
  Input,
  InsertChart,
  InsertPhoto,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Label,
  Language,
  Laptop,
  Launch,
  Layers,
  Link,
  List,
  LiveHelp,
  LocalOffer,
  LocalShipping,
  LocationOn,
  Lock,
  LockOpen,
  Mail,
  Map,
  Markunread,
  Memory,
  Menu,
  Message,
  Mic,
  MicOff,
  MoneyOff,
  Mood,
  MoreVert,
  Movie,
  MusicNote,
  MyLocation,
  Navigation,
  NearMe,
  NetworkWifi,
  NewReleases,
  Notifications,
  NotificationsActive,
  OfflinePin,
  OndemandVideo,
  Opacity,
  OpenInNew,
  Palette,
  PanTool,
  Payment,
  People,
  PermIdentity,
  Person,
  Phone,
  PhotoCamera,
  PictureAsPdf,
  PlayArrow,
  PlayCircleFilled,
  Poll,
  Power,
  Print,
  Public,
  QueryBuilder,
  Radio,
  Receipt,
  Redo,
  Refresh,
  Remove,
  RemoveRedEye,
  Reply,
  Report,
  Restaurant,
  RssFeed,
  Save,
  Schedule,
  School,
  ScreenShare,
  Search,
  Security,
  Send,
  Settings,
  Share,
  Shop,
  ShoppingCart,
  ShortText,
  ShowChart,
  Shuffle,
  SignalWifi4Bar,
  SimCard,
  Smartphone,
  Sms,
  Snooze,
  Speaker,
  Speed,
  Star,
  StarBorder,
  StarHalf,
  Storage,
  Store,
  Subtitles,
  SupervisorAccount,
  Support,
  SwapHoriz,
  Sync,
  SystemUpdate,
  Tab,
  Tablet,
  Tag,
  Terrain,
  TextFields,
  ThumbUp,
  Timelapse,
  Timer,
  Today,
  Toll,
  TouchApp,
  Toys,
  Traffic,
  Train,
  Translate,
  TrendingUp,
  Tune,
  Tv,
  Undo,
  UnfoldMore,
  Update,
  Usb,
  VerifiedUser,
  Videocam,
  ViewAgenda,
  ViewColumn,
  Visibility,
  VisibilityOff,
  VoiceChat,
  VolumeUp,
  VpnKey,
  Wallpaper,
  Warning,
  WatchLater,
  WbSunny,
  Web,
  Weekend,
  Whatshot,
  Widgets,
  Wifi,
  Work,
  YouTube,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import ActionDialog from 'components/dialog/ActionDialog';

interface ILeadStatusCreateDialog {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const iconComponents: Record<string, React.ComponentType> = {
  AccessAlarm,
  AccessTime,
  Accessibility,
  AccountBalance,
  AccountCircle,
  Add,
  AddCircle,
  AddShoppingCart,
  Alarm,
  AllInclusive,
  Android,
  Announcement,
  Apps,
  Archive,
  ArrowBack,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ArrowForward,
  ArrowUpward,
  AspectRatio,
  Assessment,
  Assignment,
  AssignmentTurnedIn,
  Assistant,
  AttachFile,
  Audiotrack,
  Autorenew,
  Backup,
  BatteryFull,
  BeachAccess,
  Beenhere,
  Block,
  Bluetooth,
  Book,
  Bookmark,
  BorderAll,
  BrightnessAuto,
  Brush,
  Build,
  Business,
  Cached,
  Cake,
  Call,
  CameraAlt,
  Cancel,
  CardGiftcard,
  Cast,
  Chat,
  Check,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  ChildCare,
  Clear,
  Close,
  Cloud,
  CloudDownload,
  Code,
  Collections,
  ColorLens,
  Comment,
  Computer,
  ContactMail,
  ContentCopy,
  CreditCard,
  Crop,
  Dashboard,
  DataUsage,
  DateRange,
  Delete,
  Description,
  DesktopMac,
  DeveloperMode,
  Devices,
  Directions,
  DirectionsCar,
  DirectionsRun,
  DiscFull,
  Dns,
  Dock,
  Done,
  Drafts,
  Edit,
  Email,
  Error,
  ErrorOutline,
  Event,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Explore,
  Extension,
  Face,
  Favorite,
  FavoriteBorder,
  FileDownload,
  FilterList,
  Fingerprint,
  Flag,
  Flight,
  Folder,
  FormatAlignCenter,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatQuote,
  Forum,
  Forward,
  Fullscreen,
  Games,
  Gavel,
  GetApp,
  GpsFixed,
  Grade,
  Group,
  Headset,
  Healing,
  Hearing,
  Help,
  HighlightOff,
  History,
  Home,
  Hotel,
  HourglassFull,
  Https,
  Image,
  Info,
  Input,
  InsertChart,
  InsertPhoto,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Label,
  Language,
  Laptop,
  Launch,
  Layers,
  Link,
  List,
  LiveHelp,
  LocalOffer,
  LocalShipping,
  LocationOn,
  Lock,
  LockOpen,
  Mail,
  Map,
  Markunread,
  Memory,
  Menu,
  Message,
  Mic,
  MicOff,
  MoneyOff,
  Mood,
  MoreVert,
  Movie,
  MusicNote,
  MyLocation,
  Navigation,
  NearMe,
  NetworkWifi,
  NewReleases,
  Notifications,
  NotificationsActive,
  OfflinePin,
  OndemandVideo,
  Opacity,
  OpenInNew,
  Palette,
  PanTool,
  Payment,
  People,
  PermIdentity,
  Person,
  Phone,
  PhotoCamera,
  PictureAsPdf,
  PlayArrow,
  PlayCircleFilled,
  Poll,
  Power,
  Print,
  Public,
  QueryBuilder,
  Radio,
  Receipt,
  Redo,
  Refresh,
  Remove,
  RemoveRedEye,
  Reply,
  Report,
  Restaurant,
  RssFeed,
  Save,
  Schedule,
  School,
  ScreenShare,
  Search,
  Security,
  Send,
  Settings,
  Share,
  Shop,
  ShoppingCart,
  ShortText,
  ShowChart,
  Shuffle,
  SignalWifi4Bar,
  SimCard,
  Smartphone,
  Sms,
  Snooze,
  Speaker,
  Speed,
  Star,
  StarBorder,
  StarHalf,
  Storage,
  Store,
  Subtitles,
  SupervisorAccount,
  Support,
  SwapHoriz,
  Sync,
  SystemUpdate,
  Tab,
  Tablet,
  Tag,
  Terrain,
  TextFields,
  ThumbUp,
  Timelapse,
  Timer,
  Today,
  Toll,
  TouchApp,
  Toys,
  Traffic,
  Train,
  Translate,
  TrendingUp,
  Tune,
  Tv,
  Undo,
  UnfoldMore,
  Update,
  Usb,
  VerifiedUser,
  Videocam,
  ViewAgenda,
  ViewColumn,
  Visibility,
  VisibilityOff,
  VoiceChat,
  VolumeUp,
  VpnKey,
  Wallpaper,
  Warning,
  WatchLater,
  WbSunny,
  Web,
  Weekend,
  Whatshot,
  Widgets,
  Wifi,
  Work,
  YouTube,
  ZoomIn,
  ZoomOut,
};

const LeadStatusCreateDialog = memo((props: ILeadStatusCreateDialog) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [visibleIcons, setVisibleIcons] = useState<string[]>([]);
  const iconsContainerRef = useRef<HTMLDivElement>(null);
  const [showSelectBox, setShowSelectBox] = useState('icon');
  const [
    leadStatusCreate,
    {
      data: leadStatusCreateData,
      isLoading: leadStatusCreateLoading,
      error: leadStatusCreateError,
    },
  ] = useLeadStatusCreateMutation();

  const filteredIcons = Object.keys(iconComponents).filter((iconName) =>
    iconName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    setVisibleIcons(filteredIcons.slice(0, 50));
  }, [searchQuery, filteredIcons]);

  const handleShowMoreIcons = () => {
    setVisibleIcons((prevVisibleIcons) => {
      const currentLength = prevVisibleIcons?.length;
      const nextIcons = filteredIcons.slice(0, currentLength + 50);
      return nextIcons;
    });
  };

  const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
    useFormik({
      initialValues: {
        input: {
          index: 0,
          name: '',
          color: 'primary.lighter',
          icon: 'CircleOutlined',
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateLeadStatusInput,
      onSubmit: async (values: IGraphqlVariables<ICreateLeadStatusInput>) => {
        setLoading(true);
        leadStatusCreate({ input: values.input });
        setLoading(false);
      },
    });

  const handleIconSelect = (iconName: string) => {
    setFieldValue('input.icon', iconName);
  };

  const handleColorSelect = (color: string) => {
    setFieldValue('input.color', color);
  };

  useEffect(() => {
    if (leadStatusCreateData) {
      props.setOpen(false);
      enqueueSnackbar(t('crm.lead.leadStatus.createSuccess'), { variant: 'success' });
      resetForm();
    }
  }, [leadStatusCreateData, resetForm]);

  useEffect(() => {
    if (leadStatusCreateError) {
      enqueueSnackbar(
        (leadStatusCreateError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [leadStatusCreateError]);

  return (
    <ActionDialog
      open={props.open}
      setOpen={props.setOpen}
      size="small"
      title={t('crm.lead.leadStatus.create')}
      handleClick={handleSubmit}
      disabled={loading || !isValid || leadStatusCreateLoading || !(values as any).input?.name}
      loading={loading || leadStatusCreateLoading}
      buttonTitle={t('crm.lead.leadStatus.create')}
    >
      <Grid container spacing={2} my={2}>
        <Grid item xs={3}>
          <InputLabel htmlFor="input.name-status" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
            {t('crm.index')}*
          </InputLabel>
          <TextField
            name="input.index"
            id="input.index-status"
            fullWidth
            value={(values as any).input.index}
            type="number"
            onChange={(e) => setFieldValue('input.index', Number(e.target.value))}
            onBlur={handleBlur}
            error={!!((errors as any)?.input?.index && (touched as any)?.input?.index)}
            helperText={(touched as any)?.input?.index && (errors as any)?.input?.index}
          />
        </Grid>
        <Grid item xs={9}>
          <InputLabel htmlFor="input.name-status" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
            {t('crm.name')}*
          </InputLabel>
          <TextField
            fullWidth
            name="input.name"
            id="input.name-status"
            value={(values as any).input.name}
            onChange={(e) =>
              setFieldValue(
                'input.name',
                e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
              )
            }
            onBlur={handleBlur}
            error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
            helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ ml: 1, mb: 0.5, fontSize: 'small', color: 'grey.600' }}>
            {t('crm.icon')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '80px',
              border: '1px solid',
              borderColor: 'grey.400',
              borderRadius: '16px',
              cursor: 'pointer',
            }}
            onClick={() => setShowSelectBox('icon')}
          >
            <SvgIcon
              component={iconComponents[values.input?.icon ?? 'CircleOutlined']}
              sx={{ color: 'primary.main', fontSize: '64px', opacity: 0.7 }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ ml: 1, mb: 0.5, fontSize: 'small', color: 'grey.600' }}>
            {t('crm.color')}*
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '80px',
              border: '1px solid',
              borderColor: 'grey.400',
              borderRadius: '16px',
              cursor: 'pointer',
            }}
            onClick={() => setShowSelectBox('color')}
          >
            <Box
              sx={{
                width: 64,
                height: 64,
                borderRadius: '50%',
                backgroundColor: (values as any).input.color,
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          mt={2}
          sx={{ ml: '16px', border: '1px solid', borderColor: 'grey.400', borderRadius: '16px' }}
        >
          {showSelectBox === 'icon' && (
            <Box
              sx={{
                height: 300,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
              ref={iconsContainerRef}
            >
              <Typography fontSize={'14px'} fontWeight={'bold'} textAlign={'center'} mt={1}>
                {t('crm.selectIconTitle')}
              </Typography>
              <TextField
                placeholder={t('crm.searchForAnIcon')}
                name="search-status"
                variant="outlined"
                sx={{
                  width: '70%',
                  '& .MuiOutlinedInput-root': {
                    height: 30,
                    fontSize: '12px',
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: '12px',
                  },
                }}
                margin="normal"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Box
                sx={{
                  maxHeight: 180,
                  ml: 1,
                  overflow: 'auto',
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Grid container spacing={1}>
                  {visibleIcons.map((iconName) => {
                    const IconComponent = iconComponents[iconName];
                    return IconComponent ? (
                      <Grid item xs={2} key={iconName}>
                        <IconButton onClick={() => handleIconSelect(iconName)}>
                          <IconComponent />
                        </IconButton>
                      </Grid>
                    ) : null;
                  })}
                </Grid>
                {visibleIcons?.length < filteredIcons?.length && (
                  <Button onClick={handleShowMoreIcons} sx={{ mt: 2, alignSelf: 'center' }}>
                    {t('crm.showMore')}
                  </Button>
                )}
              </Box>
            </Box>
          )}
          {showSelectBox === 'color' && (
            <Box
              sx={{
                height: 300,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Typography fontSize={'14px'} fontWeight={'bold'} textAlign={'center'} mt={1}>
                {t('crm.selectColor')}
              </Typography>
              <Box
                sx={{
                  maxHeight: 240,
                  ml: 1,
                  overflow: 'auto',
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Grid container>
                  {colors.map((color) => (
                    <Grid item xs={2} key={color}>
                      <IconButton onClick={() => handleColorSelect(color)}>
                        <Box
                          sx={{
                            width: 32,
                            height: 32,
                            borderRadius: '50%',
                            backgroundColor: color,
                          }}
                        />
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </ActionDialog>
  );
});

export const colors = [
  'primary.light',
  'secondary.light',
  'success.light',
  'error.light',
  'warning.light',
  'info.light',
  'primary.main',
  'secondary.main',
  'success.main',
  'error.main',
  'warning.main',
  'info.main',
  'primary.dark',
  'secondary.dark',
  'success.dark',
  'error.dark',
  'warning.dark',
  'info.dark',
];

export default LeadStatusCreateDialog;

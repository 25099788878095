/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, memo, SetStateAction, useEffect, useMemo, useState } from 'react';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import {
  ITicketCreateInput,
  TicketPriority,
  TicketTargetType,
} from 'corede-common-cocrm';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import { enqueueSnackbar } from 'notistack';
import 'react-quill/dist/quill.snow.css';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useTicketCreateMutation } from '../../context/support.api';
import { validateCreateSupportInput } from '../../validations/create.validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpsertTicketComponent } from '../UpsertTicket.component';
import ActionDialog, { OverlayType } from 'components/dialog/ActionDialog';
import ActionDrawer from 'components/drawer/ActionDrawer';

export interface ITicketCreateOverlay {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  targetId?: string;
  targetType?: TicketTargetType;
  overlayType: OverlayType;
}

const TicketCreateOverlay = memo((props: ITicketCreateOverlay) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);

  // mutations
  const [
    ticketCreate,
    { data: ticketCreateData, isLoading: ticketCreateLoading, error: ticketCreateError },
  ] = useTicketCreateMutation();

  // constants
  const initialValues = useMemo<IGraphqlVariables<ITicketCreateInput>>(
    () => ({
      input: {
        targetType: props.targetType,
        targetId: props.targetId,
        subject: '',
        ticketBody: undefined,
        priority: TicketPriority.medium,
        contactName: '',
        contactEmail: '',
        departmentId: undefined,
        assigneeIds: undefined,
        category: undefined,
        tags: undefined,
      },
    }),
    [],
  );

  // form setup
  const ticketCreateUseForm = useForm<IGraphqlVariables<ITicketCreateInput>>({
    values: initialValues,
    resolver: yupResolver(validateCreateSupportInput),
    mode: 'onChange',
  });

  const onSubmit = async (values: IGraphqlVariables<ITicketCreateInput>) => {
    setLoading(true);
    const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
      input: values,
    });
    await ticketCreate(transformedValues as IGraphqlVariables<ITicketCreateInput>);
    setLoading(false);
  };

  // useEffects.success

  useEffect(() => {
    if (ticketCreateData) {
      enqueueSnackbar(t('crm.support.support.createSuccess'), {
        variant: 'success',
      });
      ticketCreateUseForm.reset();
      props.setOpen(false);
    }
  }, [ticketCreateData, ticketCreateUseForm.reset]);

  useEffect(() => {
    if (!props.open) {
      ticketCreateUseForm.reset({
        input: {
          targetType: props.targetType,
          targetId: props.targetId,
          subject: '',
          ticketBody: '',
          priority: TicketPriority.medium,
          contactName: '',
          contactEmail: '',
          departmentId: undefined,
          assigneeIds: undefined,
          category: undefined,
          tags: undefined,
        },
      });
    }
  }, [props.open]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(ticketCreateError as IBaseError, currentLanguage);
  }, [ticketCreateError]);

  const commonContent = (
    <UpsertTicketComponent
      open={props.open}
      setOpen={props.setOpen}
      loading={ticketCreateLoading || loading}
      useForm={ticketCreateUseForm}
      targetId={props.targetId}
      targetType={props.targetType}
      type="create"
    />
  );

  switch (props.overlayType) {
    case OverlayType.drawer:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          size="medium"
          backgroundColor={'background.default'}
          title={t('crm.support.support.create')}
          handleSubmit={ticketCreateUseForm.handleSubmit(onSubmit)}
          disabled={
            ticketCreateLoading ||
            loading ||
            !ticketCreateUseForm.formState.isValid ||
            (ticketCreateUseForm.watch('input.targetType')
              ? !ticketCreateUseForm.watch('input.targetId')
              : false)
          }
          loading={ticketCreateLoading || loading}
          buttonTitle={t('crm.support.support.create')}
        >
          {commonContent}
        </ActionDialog>
      );

    case OverlayType.dialog:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          title={t('crm.support.support.create')}
          handleClick={ticketCreateUseForm.handleSubmit(onSubmit)}
          disabled={ticketCreateLoading || loading || !ticketCreateUseForm.formState.isValid}
          loading={ticketCreateLoading || loading}
          buttonTitle={t('crm.support.support.create')}
          width={500}
        >
          {commonContent}
        </ActionDialog>
      );

    default:
      return null;
  }
});

export default TicketCreateOverlay;

const locale = {
  productCategories: 'Categorías',
  title: 'Categorías de Productos/Servicios',
  create: 'Crear Categoría',
  delete: 'Eliminar Categoría',
  deleteConfirm: '¿Está seguro de que desea eliminar esta categoría: ',
  deletedSuccessfully: 'Categoría eliminada con éxito',
  createCategory: 'Crear Categoría',
  categoryCreateSuccess: 'Categoría creada con éxito',
  name: 'Nombre de Categoría',
  icon: 'Icono de Categoría',
  showMore: 'Mostrar Más',
  update: 'Actualizar',
  updateCategory: 'Actualizar Categoría',
  categoryUpdateSuccess: 'Categoría actualizada con éxito',
  en: 'Inglés',
  tr: 'Turco',
  createSuccess: 'Categoría creada con éxito',
  updateSuccess: 'Categoría de producto actualizada con éxito',
  actions: 'Acciones',
  createdAt: 'Creado el',
  emptyState1: 'No se encontraron categorías',
  emptyState2: 'Por favor, cree una nueva categoría',
  createProductCategory: 'Crear Categoría de Producto',
};

export default locale; 
const locale = {
  myAccount: "Minha conta",
  profilePicture: "Foto de perfil",
  profile: "Perfil",
  name: "Nome",
  surname: "Sobrenome",
  birthDate: "Data de nascimento",
  gender: "Gênero",
  update: "Atualizar",
  changeImage: "Alterar imagem",
  change: "Alterar",
  updateProfileSuccess: "Perfil atualizado com sucesso",
  updateProfilePictureSuccess: "Foto de perfil atualizada com sucesso",
  country: "País",
  city: "Cidade",
  signatureImage: "Imagem de assinatura",
  twoFactorAuthEmail: "E-mail de autenticação de dois fatores",
};

export default locale; 
import invoiceLocale from "../subdomains/invoice/locales/de";
import returnLocale from "../subdomains/return/locales/de";
import paymentLocale from "../subdomains/payment/locales/de";

const locale = {
  invoice: {
    ...invoiceLocale,
  },
  payment: {
    ...paymentLocale,
  },
  return: {
    ...returnLocale,
  },
};

export default locale; 
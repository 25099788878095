const locale = {
  contracts: 'Контракты',
  contract: 'Контракт',
  contractConversions: 'Конверсии контрактов',
  title: 'Контракты',
  create: 'Создать контракт',
  update: 'Обновить контракт',
  delete: 'Удалить контракт',
  createSuccess: 'Контракт успешно создан.',
  updateSuccess: 'Контракт успешно обновлен.',
  deleteSuccess: 'Контракт успешно удален.',
  deleteConfirm: 'Вы уверены, что хотите удалить этот контракт? Это действие нельзя отменить.',
  emptyState1: 'Контракты еще не добавлены.',
  emptyState2:
    'Храните и управляйте всеми деталями контрактов здесь, чтобы упростить выставление счетов и поддерживать четкие соглашения с клиентами.',

  // labels
  contractTemplateLabel: 'Шаблон контракта',
  contractValue: 'Стоимость контракта',
  contractType: 'Тип контракта',
  signedDate: 'Дата подписания',
  signedUser: 'Подписавший пользователь',
  signStatus: 'Статус подписания',

  //detail drawer
  changeStatus: 'Изменить статус',
  statusChangeSuccess: 'Статус успешно изменен.',
  generalInfoTitle: 'Общая информация',
  customerInfoTitle: 'Информация о клиенте',
  projectInfoTitle: 'Информация о проекте',
  contractRenew: 'Продлить контракт',
  renew: 'Продлить контракт',
  renewSuccess: 'Контракт успешно продлен.',
  emptyStateRenew: 'Нет контрактов для продления.',
  renewHistory: 'История продлений',
};

export default locale; 
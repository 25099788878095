const locale = {
  demo: "Демо",
  title: "Что такое <highlight>CoCRM</highlight> Маркетинг?",
  description:
    "В CoCRM мы не только упрощаем управление существующими отношениями с клиентами — мы также помогаем оптимизировать ваши маркетинговые процессы для достижения бизнес-целей. С CoCRM Маркетинг вы можете более эффективно достигать вашей целевой аудитории и делать ваши маркетинговые кампании более результативными.",
  featuresTitle: "С CoCRM Маркетинг вы можете:",
  features1:
    "Настраивать ваши маркетинговые кампании по странам, городам и отраслям для более стратегического управления,",
  features2:
    "Создавать персонализированные кампании, адаптированные к конкретным рынкам, где вы хотите повысить узнаваемость бренда,",
  features3:
    "Более эффективно отслеживать путь клиента для поддержки ваших целей в продажах и маркетинге,",
  features4:
    "Разрабатывать ориентированные на результат стратегии путем интеграции с инструментами цифрового маркетинга.",
  buttonTitle: "Запросить коммерческое предложение",
  formDescription:
    "Заполните форму, и мы свяжемся с вами в ближайшее время.",
  sendSuccess: "Спасибо! Мы свяжемся с вами в ближайшее время.",
  formTitle: "Запросить коммерческое предложение",
  formButtonTitle: "Отправить",
  fullName: "Полное имя",
  email: "Электронная почта",
  phone: "Телефон",
  orgName: "Название организации",
};

export default locale; 
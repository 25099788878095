const locale = {
  title: 'Dashboard',
  description:
    'Aktivieren Sie Ihr Abonnement Wechseln Sie zu einem geeigneten Abonnementplan, um alle Funktionen des CRM-Systems zu nutzen und Ihr Unternehmen zu erweitern',
  featuresTitle: 'Mit Abonnement:',
  features1: 'Unbegrenzter Zugriff auf alle Ihre Daten',
  features2: 'Benutzerdefinierte Berichtswerkzeuge',
  features3: 'Mehr Speicherplatz',
  features4: '24/7 technischer Support',
  buttonTitle: 'Abonnementpläne erkunden',
  trialExpired: 'Testplan ist abgelaufen. Bitte wählen Sie einen anderen Plan.',
  mainFailed: 'Ihr Plan ist nicht aktiviert. Bitte überprüfen Sie Ihre Zahlungsinformationen.',
  mainCompleted: 'Ihr Plan ist abgeschlossen. Bitte wählen Sie einen anderen Plan.',
  welcome: 'Willkommen ',
  platformValue: 'Plattformwert',
  dealsAmount: 'Geschäftsbetrag',
  deals: 'Geschäfte',
  byRefererCategory: 'nach Verweiserkategorie',
  revenue: 'Umsatz',
  leads: 'Leads',
  wl: 'G/V',
  winLoss: 'Gewinn/Verlust',
  averageMonthly: 'Monatlicher Durchschnitt',
  monthly: 'Monatlich',
  bestDeal: 'Bestes Geschäft',
  sales: 'Verkäufe',
  topSales: 'Top-Verkäufe',
  salesStreak: 'Verkaufsserie',
  topReview: 'Top-Bewertung',
  workWithPlatforms: 'Arbeit mit Plattformen',
  salesDynamics: 'Verkaufsdynamik',
  timeframe: 'Zeitrahmen',
  bestDetal: 'Bestes Geschäft',
  winRate: 'Gewinnrate',
  prevMonth: 'vorheriger Monat',
  vsPrevMonth: 'vs. vorheriger Monat',
  details: 'Details',
  filters: 'Filter',
  statistics: 'Statistiken',

  //LEAD
  totalLead: 'Gesamte Leads',
  totalLeadSubtitle: 'Gesamte Leads mit täglichen historischen Daten',
  today: 'Heute',
  yesterday: 'Gestern',
  leadConversion: 'Lead-Konversion',
  leadConversionSubtitle: 'Lead-Konversion mit täglichen historischen Daten',
  weeklyReport: 'Wochenbericht',

  //CUSTOMER
  totalCustomer: 'Gesamtkunden',
  totalCustomerSubtitle: 'Gesamtkunden mit täglichen historischen Daten',
  customerFilterType: 'Kundenfilter nach Typ',
  customerFilterCategory: 'Kundenfilter nach Kategorie',

  //ESTIMATE
  totalEstimate: 'Gesamtschätzungen',
  totalEstimateSubtitle: 'Gesamtschätzungen mit täglichen historischen Daten',
  estimateTotalPrice: 'Geschätzter Gesamtpreis',
  estimateTotalPriceSubtitle: 'Geschätzter Gesamtpreis mit täglichen historischen Daten',
  estimatesByStatus: 'Schätzungen nach Status',
  estimatesByTypes: 'Schätzungen nach Typen',
  estimatesByTarget: 'Schätzungen nach Ziel',

  //PROPOSAL
  totalProposal: 'Gesamtvorschläge',
  totalProposalSubtitle: 'Gesamtvorschläge mit täglichen historischen Daten',
  proposalTotalPrice: 'Vorschlag Gesamtpreis',
  proposalTotalPriceSubtitle: 'Vorschlag Gesamtpreis mit täglichen historischen Daten',
  proposalsByStatus: 'Vorschläge nach Status',
  proposalsByTypes: 'Vorschläge nach Typen',
  proposalsByTarget: 'Vorschläge nach Ziel',

  //TASK
  totalTask: 'Gesamtaufgaben',
  totalTaskSubtitle: 'Gesamtaufgaben mit täglichen historischen Daten',
  taskFilterPriority: 'Aufgabenfilter nach Priorität',
  taskFilterStatus: 'Aufgabenfilter nach Status',
};

export default locale; 
import React from 'react';
import { CalendarDomainLayout } from '../layouts';
import { AppointmentsRouter } from '../subdomains/appointment/routes/AppointmentsRouter';
import { RemindersRouter } from '../subdomains/reminder/routes/RemindersRouter';
import { calendarDomainRoutes } from './calendar.base.route';
import { Route, Routes } from 'react-router-dom';
import { CalendarRouter } from '../subdomains/calendar/routes/CalendarRouter';

export const CalendarDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CalendarDomainLayout />}>
        <Route index element={<RemindersRouter />} />
        <Route
          path={`${calendarDomainRoutes.subdomains.reminder.base}/*`}
          element={<RemindersRouter />}
        />
        <Route
          path={`${calendarDomainRoutes.subdomains.appointment.base}/*`}
          element={<AppointmentsRouter />}
        />
        <Route
          path={`${calendarDomainRoutes.subdomains.calendar.base}/*`}
          element={<CalendarRouter />}
        />
      </Route>
    </Routes>
  );
};

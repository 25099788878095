const locale = {
  leadStatuses: "Estados de Lead",
  title: "Estados de Lead",
  create: "Crear Estado de Lead",
  delete: "Eliminar Estado de Lead",
  deleteConfirm: "¿Está seguro de que desea eliminar este estado: ",
  deletedSuccessfully: "Estado de lead eliminado con éxito",
  createSuccess: "Estado de lead creado con éxito",
  update: "Actualizar Estado de Lead",
  updateSuccess: "Estado de lead actualizado con éxito",
  selectColor: "Seleccionar Color",
  selectIcon: "Seleccionar Icono",
};

export default locale; 
const locale = {
  demo: 'Demo',
  title: 'O que é a <highlight>CoCRM</highlight> Community?',
  description:
    'Na CoCRM, além de fornecer uma gestão eficaz dos seus processos de negócio e relacionamento com clientes, também oferecemos um espaço de colaboração projetado para expandir sua rede e oportunidades de negócio.',
  featuresTitle: 'Com a Comunidade CoCRM, você pode:',
  features1: 'Obter novas oportunidades de negócio através de indicações.',
  features2: 'Conectar-se com fornecedores confiáveis.',
  features3: 'Encontrar parceiros de negócios de confiança.',
  features4: 'Expandir sua rede para oferecer serviços 360° aos seus clientes.',
  buttonTitle: 'Junte-se à Comunidade',
  formTitle: 'Entrar na Lista de Espera',
  formDescription:
    'Por favor, preencha o formulário abaixo e entraremos em contato com você o mais breve possível.',
  sendSuccess: 'Obrigado! Entraremos em contato com você o mais breve possível.',
  formButtonTitle: 'Inscrever-se',
  fullName: 'Nome Completo',
  email: 'Email',
  phone: 'Telefone',
  orgName: 'Nome da Organização',
};

export default locale;

const locale = {
  pricing: 'Tarification',
  saveUpTo: 'Économisez jusqu\'à',
  monthly: 'mensuel',
  monthlyUppercase: 'Mensuel',
  annually: 'annuel',
  annuallyUppercase: 'Annuel',
  organization: 'Organisation',
  month: 'Mois',
  billedMonthly: 'Facturation Mensuelle',
  billedAnnually: 'Facturation Annuelle',
  users: 'Utilisateurs',
  startTrialForFree: 'Commencer l\'essai gratuit',
  buy: 'ACHETER',
  contactUs: 'Contactez-nous',
  starterPlan: 'Plan Débutant',
  enterprisePlan: 'Plan Entreprise',
  mostPopular: 'Le plus populaire',
  buyPackage: 'Acheter le forfait',
  buyPackageDescription:
    'Ce forfait appelé {{name}} comprend une limite de stockage de {{storageLimit}} Go et une limite d\'utilisateurs de {{userLimit}}.',
  dontHaveBillingInfo: 'Vous n\'avez pas d\'informations de facturation à utiliser.',
  dontHaveCard: 'Vous n\'avez pas de carte à utiliser.',
  addBillingInfo: 'Ajouter des informations de facturation',
  addCard: 'Ajouter une carte',
  manageBillingInfo: 'Gérer les informations de facturation',
  manageCards: 'Gérer les cartes',
  summary: 'Résumé',
  iAcceptThe: 'J\'accepte les',
  preliminaryInformation: 'Informations préliminaires',
  distanceSalesAgreement: 'Contrat de vente à distance',
  close: 'Fermer',
  cancel: 'Annuler',
  subscribeSuccess1: 'Votre abonnement a été démarré avec succès.',
  subscribeSuccess2: 'Bienvenue à bord.',
  continue: 'Continuer',
  letUsCall: 'Laissez-nous vous appeler',
  billingInfo: 'Informations de facturation',
  selectedCard: 'Carte sélectionnée',
  currentPlan: 'Plan actuel',
  renewAt: 'Renouveler le',
  active: 'Actif',
  usageLimits: 'Limites d\'utilisation',
  storageUsage: 'Utilisation du stockage',
  activeCard: 'Carte active',
  trialPackage: 'Forfait d\'essai',
  trialPackageDescription:
    'Ce forfait offre une période d\'utilisation de 7 jours, avec une limite de stockage de {{storageLimit}} Go et une limite d\'utilisateurs de {{userLimit}}.',
  confirmTrial: 'Êtes-vous sûr de vouloir commencer l\'essai?',
  beginTrial: 'Commencer l\'essai',
  contactFormSuccess: 'Votre demande a été soumise avec succès. Nous vous contacterons bientôt.',

  packageDescription:
    'Ce forfait offre une période d\'utilisation de 7 jours, avec une limite de stockage de {{storageLimit}} Go et une limite d\'utilisateurs de {{userLimit}}.',
  beginTrialSuccess: 'Essai démarré avec succès.',
  purchaseSuccess: 'Achat réussi.',
  title: 'Tarification',
  unknownError: 'Une erreur inconnue s\'est produite.',
  contactMessage: 'Téléphone: {{phone}}, Nom de l\'organisation: {{orgName}}',
  detailTitle: 'Détail',
  trialPackageTitle: 'Forfait d\'essai',
  buyPackageTitle: 'Acheter le forfait',
  contactUsTitle: 'Contactez-nous',
  preliminaryInfoTitle: 'Informations préliminaires',
  distanceSalesAgreementTitle: 'Contrat de vente à distance',
  welcomeTrial1: 'Votre forfait d\'essai a été démarré avec succès. Bienvenue à bord.',
  welcomeTrial2: 'Bienvenue à bord.',
  noBillingInfo: 'Vous n\'avez pas d\'informations de facturation à utiliser.',
  billingInfoLabel: 'Informations de facturation*',
  noCardInfo: 'Vous n\'avez pas de carte à utiliser.',
  selectedCardLabel: 'Carte sélectionnée*',
  acceptPreliminaryInfo: 'J\'accepte les informations préliminaires',
  acceptDistanceSalesAgreement: 'J\'accepte le contrat de vente à distance',
  preliminaryInfoDescription:
    'Ce forfait offre une période d\'utilisation de 7 jours, avec une limite de stockage et des autorisations d\'utilisateur comme indiqué dans l\'accord.',
  subscriptionSuccess: 'Votre abonnement a été démarré avec succès. Bienvenue à bord.',
  selectedPeriodLabel: '/{{selectedPeriod}}',
  distanceSalesAgreementDescription:
    'Ce forfait offre une période d\'utilisation de 7 jours, avec une limite de stockage et des autorisations d\'utilisateur comme indiqué dans le contrat de vente à distance.',
  goCRM: 'Aller au CRM',
  tryForFree: 'Essayer gratuitement',
  onePaymentOf: 'Un paiement de',

  cancelled: 'Annulé',
  expired: 'Expiré',
  completed: 'Terminé',
  failed: 'Échoué',
  ongoingWithCancelled: 'En cours avec annulation',
  ongoingWithFailure: 'En cours avec échec',

  payment: 'Paiement',
  upgrade: 'METTRE À NIVEAU',
  paymentSuccess: 'Paiement effectué avec succès.',
  paymentFailure: 'Processus de paiement terminé',
};

export default locale; 
import React from 'react';
import { Grid, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { Add, FileUpload } from '@mui/icons-material';
import { useLeadListCountQuery } from '../../context/lead.api';
import { LeadListKanbanComponent } from './LeadList-kanban.component';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import LeadListComponent from './LeadList-grid.component';
import { importLeadRoute } from '../../../../routes/lead.base.route';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeadListStatisticsView } from './LeadList-statistics.component';
import LeadCreateDrawer from '../create/LeadCreateDrawer';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { PermissionSubject } from 'corede-common-cocrm';
import { PermissionAction } from 'corede-common';
import { PermissionWrapper } from 'permission/PermissionWrapper';

const LeadList = memo(() => {
  // general
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openLeadCreateDrawer, setOpenLeadCreateDrawer] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const location = useLocation();
  const leadListType = 'list';

  // queries
  const { data: leadListCountData, isLoading: leadListCountLoading } = useLeadListCountQuery({});
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});

  const totalLeadCount = leadListCountData?.count ?? 0;
  const isEmpty = totalLeadCount === 0;

  // useEffects.init
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash === '#create') {
        setOpenLeadCreateDrawer(true);
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant={downMd ? 'body1' : 'body1'}>
              {t(`crm.lead.lead.welcome`)},
            </Typography>
            <Typography variant={downMd ? 'body1' : 'h4'} sx={{ fontWeight: '500' }}>
              {userDetailOwnData?.name} 👋🏻
            </Typography>
          </Stack>
        }
        description={
          <Typography
            variant={downMd ? 'caption' : 'body1'}
            visibility={isEmpty ? 'hidden' : 'visible'}
          >
            {t(`crm.lead.lead.description`)}
          </Typography>
        }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.lead.lead.create`),
          onClick: () => setOpenLeadCreateDrawer(true),
          permission: {
            subject: PermissionSubject.lead,
            action: PermissionAction.create,
          },
        }}
        rightButton2={{
          visibility: !isEmpty,
          icon: <FileUpload sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          onClick: () => navigate(importLeadRoute()),
          title: t(`crm.lead.lead.importLead`),
          permission: {
            subject: PermissionSubject.lead,
            action: PermissionAction.import,
          },
        }}
      />

      <LeadListStatisticsView count={totalLeadCount} leadListCountLoading={leadListCountLoading} />

      {leadListType === 'list' ? (
        <LeadListComponent setOpenLeadCreateDrawer={setOpenLeadCreateDrawer} />
      ) : (
        <LeadListKanbanComponent
          statuses={[]}
          leads={[]}
          setOpenLeadStatusesDrawer={() => null}
          setSelectedLead={null}
          setOpenUserDrawer={null}
        />
      )}

      <PermissionWrapper
        check={{
          subject: PermissionSubject.lead,
          action: PermissionAction.create,
        }}
      >
        <LeadCreateDrawer open={openLeadCreateDrawer} setOpen={setOpenLeadCreateDrawer} />
      </PermissionWrapper>
    </Grid>
  );
});

LeadList.displayName = 'LeadList';
export default LeadList;

/* eslint-disable react-hooks/exhaustive-deps */
import { IInvoicePaymentDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { PaymentDetailDrawerRightPanelHeaderComponent } from './PaymentDetailDrawer-rightPanel-header.component';
import { PaymentDetailDrawerRightPanelTabsComponent } from './PaymentDetailDrawer-rightPanel.tabs.component';

export interface IPaymentDetailDrawerRightPanelComponentProps {
  paymentDetailData: IInvoicePaymentDetailResult | undefined;
}

export const PaymentDetailDrawerRightPanelComponent = (
  props: IPaymentDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <PaymentDetailDrawerRightPanelHeaderComponent paymentDetailData={props.paymentDetailData} />

      {/* Tabs Section */}
      <PaymentDetailDrawerRightPanelTabsComponent paymentDetailData={props.paymentDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};

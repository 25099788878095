import noteLocale from "../subdomains/note/locales/de";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  note: {
    ...noteLocale
  }
};

export default locale; 
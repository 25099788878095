const locale = {
  leadSources: "Fonti di Lead",
  title: "Fonti di Lead",
  create: "Crea Fonte di Lead",
  delete: "Elimina Fonte di Lead",
  deleteConfirm: "Sei sicuro di voler eliminare questa fonte: ",
  deletedSuccessfully: "Fonte di lead eliminata con successo",
  createSuccess: "Fonte di lead creata con successo",
  updateSuccess: "Fonte di lead aggiornata con successo",
  update: "Aggiorna Fonte di Lead",
  updateLeadSourceSuccess: "Fonte di lead aggiornata con successo",
  selectColor: "Seleziona Colore",
  selectIcon: "Seleziona Icona",
};

export default locale; 
const locale = {
  service: 'Dienstleistungen',
  title: 'Dienstleistungen',
  create: 'Dienstleistung erstellen',
  update: 'Dienstleistung aktualisieren',
  delete: 'Dienstleistung löschen',
  deleteConfirm: 'Sind Sie sicher, dass Sie diesen Status löschen möchten: ',
  deletedSuccessfully: 'Dienstleistung erfolgreich gelöscht',
  createService: 'Dienstleistung erstellen',
  createSuccess: 'Dienstleistung erfolgreich erstellt',
  updateSuccess: 'Dienstleistung erfolgreich aktualisiert',
  name: 'Name',
  icon: 'Symbol',
  showMore: 'Mehr anzeigen',

  detail: 'Dienstleistungsdetails',
  type: 'Typ',
  image: 'Bild',
  sku: 'Artikelnummer',
  description: 'Beschreibung',
  currency: 'Währung',
  price: 'Preis',
  priceType: 'Preistyp',
  discountedPrice: 'Reduzierter Preis',
  unit: 'Einheit',
  categoryId: 'Kategorie',
  natTaxRate: 'Nationaler Steuersatz',
  intTaxRate: 'Internationaler Steuersatz',

  categories: 'Kategorien',
  rate: 'Rate',
  rateType: 'Ratentyp',
  discountedRate: 'Reduzierte Rate',
  emptyState1: 'Noch keine Dienstleistungen erfasst.',
  emptyState2:
    'Verwalten und überprüfen Sie hier alle Dienstleistungsrückgaben, um Ihre Aufzeichnungen genau und die Kundenbedürfnisse erfüllt zu halten.',
};

export default locale; 
const locale = {
  returns: 'Resi',
  return: 'Reso',
  returnConversions: 'Conversioni di reso',
  title: 'Resi',
  create: 'Aggiungi reso',
  update: 'Aggiorna reso',
  delete: 'Elimina reso',
  createSuccess: 'Reso aggiunto con successo.',
  updateSuccess: 'Reso aggiornato con successo.',
  deleteSuccess: 'Reso eliminato con successo.',
  deleteConfirm: 'Sei sicuro di voler eliminare questo reso? Questa azione non può essere annullata.',
  emptyState1: 'Nessun reso registrato finora.',
  emptyState2:
    'Gestisci e rivedi qui qualsiasi reso di prodotti o servizi per mantenere i tuoi registri accurati e soddisfare le esigenze dei clienti.',

  // detailDrawer
  generalInfoTitle: 'Informazioni generali',
  isOpen: 'È aperto',
  refundAmount: 'Importo rimborso',
  resolveReason: 'Motivo di risoluzione',
  disapproveReason: 'Motivo di disapprovazione',
  returnProducts: 'Prodotti restituiti',
  amount: 'Importo',
  reason: 'Motivo',
  isPartial: 'È parziale',
  note: 'Nota',
  statusChangeSuccess: 'Stato modificato con successo',
  changeStatus: 'Cambia stato',
};

export default locale; 
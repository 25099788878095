const locale = {
  projects: 'Projets',
  project: 'Projet',
  projectConversions: 'Conversions de Projet',
  title: 'Projets',
  create: 'Créer un Projet',
  update: 'Mettre à jour le Projet',
  delete: 'Supprimer le Projet',
  createSuccess: 'Projet créé avec succès.',
  updateSuccess: 'Projet mis à jour avec succès.',
  deleteSuccess: 'Projet supprimé avec succès.',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce projet ? Cette action ne peut pas être annulée.',
  emptyState1: 'Aucun projet démarré pour le moment.',
  emptyState2:
    'Créez et gérez vos projets ici pour suivre la progression, attribuer des tâches et collaborer avec votre équipe.',
  progressCalculationType: 'Calcul de Progression',
  progressPercentage: 'Progression %',
  isBillable: 'Facturable',
  billingType: 'Type de Facturation',
  billingRatePerHour: 'Taux Horaire',
  members: 'Membres',
  startDate: 'Date de Début',
  deadline: 'Date Limite',
  estimatedHours: 'Heures Estimées',
  // labels
  welcome: 'Bienvenue',
  description: "Votre progression de projet cette semaine est incroyable. Continuons.",

  // detail
  changeStatus: 'Changer le Statut',
  generalInfoTitle: 'Informations Générales',
  customerInfoTitle: 'Informations Client',
  contactPhoneNumber: 'Numéro de Téléphone de Contact',
  membersTitle: 'Membres',
  milestone: 'Jalon',
  timeSheet: 'Feuille de Temps',
  projectInfoTitle: 'Informations du Projet',
  billingInfoTitle: 'Informations de Facturation',
  statusChangeSuccess: 'Statut du projet modifié avec succès.',
  detail: 'Détail du Projet',
  noMember: 'Pas encore de membre.',

  // timeSheet
  noAssigneeToCreateTimeSheet: 'Veuillez assigner des utilisateurs au projet pour créer des feuilles de temps',
  emptyStateTimeSheetList1: "Il n'y a pas encore de feuille de temps.",
  addTimeSheet: 'Ajouter une Feuille de Temps',
  timeSheetCreate: 'Créer une Feuille de Temps',
  timeSheetUpdate: 'Mettre à jour la Feuille de Temps',
  member: 'Membre',
  startTime: 'Heure de Début',
  endTime: 'Heure de Fin',
  duration: 'Durée',
  timeSheetCreateSuccess: 'Feuille de temps créée avec succès.',
  timeSheetUpdateSuccess: 'Feuille de temps mise à jour avec succès.',
  timeSheetDeleteSuccess: 'Feuille de temps supprimée avec succès.',

  // milestone
  emptyStateMilestoneList1: "Il n'y a pas encore de jalon.",
  addMilestone: 'Ajouter un Jalon',
  milestoneCreate: 'Créer un Jalon',
  milestoneUpdate: 'Mettre à jour le Jalon',
  milestoneCreateSuccess: 'Jalon créé avec succès.',
  milestoneUpdateSuccess: 'Jalon mis à jour avec succès.',
  milestoneDeleteSuccess: 'Jalon supprimé avec succès.',
  addTask: 'Ajouter une Tâche',
};

export default locale; 
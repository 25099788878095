import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Typography, Stack, Box, Button } from '@mui/material';
import { Iconify } from 'components';
import { IInvoiceDetailCustomerResult } from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';

const FILE_DOWNLOAD_QUERY = gql`
query InvoiceFileDownloadPublic($input: FileDownloadInput!) {
  invoiceFileDownloadPublic(input: $input) {
    downloadPresignedUrl
    expiresIn
  }
}
`;

const Documents = (props: { invoiceData: IInvoiceDetailCustomerResult | undefined }) => {
  const { t } = useTranslation();

  const [fileDownload] = useLazyQuery(FILE_DOWNLOAD_QUERY);

  const handleDownload = (_id: string) => {
    fileDownload({
      variables: {
        input: {
          _id,
        },
      },
    }).then((res) => {
      console.log(res.data);

      window.open(res.data?.invoiceFileDownloadPublic?.downloadPresignedUrl);
    });
  };
  return (
    <Stack gap={1.25}>
      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: '6px',
          p: 2,
        }}
        onClick={() => console.log('Open upload dialog')}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'divider',
            borderRadius: '6px',
            width: 32,
            height: 32,
          }}
        >
          <Iconify
            icon="material-symbols-light:upload-rounded"
            width={24}
            sx={{ color: 'text.secondary' }}
          />
        </Box>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {t('auth.documents')}
        </Typography>
      </Box> */}

      {/* <Button
        type="submit"
        variant="contained"
        color="error"
        fullWidth
        onClick={() => console.log('Decline')}
        sx={{ fontSize: '15px', borderRadius: '6px', height: 38, fontWeight: 400 }}
      >
        {t('auth.addDocument')}
      </Button> */}

      <Stack>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {t('auth.documents')}
        </Typography>

        {props.invoiceData?.documents ? (
          props.invoiceData?.documents?.length > 0 ? (
            props.invoiceData?.documents.map((document, index) => (
              <Box
                key={index}
                onClick={() => handleDownload(document.fileId)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  bgcolor: 'divider',
                  borderRadius: '6px',
                  p: 1.25,
                  mt: 1,
                  cursor: 'pointer',
                }}
              >
                <Iconify icon="line-md:document" width={20} sx={{ color: 'error.main' }} />
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {document?.customName}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('crm.preSale.estimate.noDocuments')}
            </Typography>
          )
        ) : null}
      </Stack>
    </Stack>
  );
};

export default Documents;

const locale = {
  demo: "Demo",
  title: "¿Qué es <highlight>CoCRM</highlight> Marketing?",
  description:
    "En CoCRM, no solo facilitamos la gestión de sus relaciones con clientes existentes, también le ayudamos a optimizar sus procesos de marketing para alcanzar sus objetivos empresariales. Con CoCRM Marketing, puede llegar a su público objetivo de manera más efectiva y hacer que sus campañas de marketing sean más eficientes.",
  featuresTitle: "Con CoCRM Marketing, puede:",
  features1:
    "Personalizar sus campañas de marketing por país, ciudad e industria para una gestión más estratégica,",
  features2:
    "Crear campañas personalizadas adaptadas a los mercados específicos donde desea aumentar el conocimiento de su marca,",
  features3:
    "Seguir el recorrido del cliente de manera más eficiente para apoyar sus objetivos de ventas y marketing,",
  features4:
    "Desarrollar estrategias orientadas a resultados mediante la integración con herramientas de marketing digital.",
  buttonTitle: "Solicitar presupuesto",
  formDescription:
    "Complete el formulario y nos pondremos en contacto con usted lo antes posible.",
  sendSuccess: "¡Gracias! Nos pondremos en contacto con usted lo antes posible.",
  formTitle: "Solicitar presupuesto",
  formButtonTitle: "Enviar",
  fullName: "Nombre completo",
  email: "Correo electrónico",
  phone: "Teléfono",
  orgName: "Nombre de la organización",
};

export default locale; 
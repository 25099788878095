import reportLocale from "../subdomains/report/locales/de";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  report: {
    ...reportLocale,
  },
};

export default locale; 
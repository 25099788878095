import profileLocale from "../domains/profile/locales/it";

const locale = {
  profile: {
    ...profileLocale,
  },
  profileo: "Richiesta di preventivo"
};

export default locale;

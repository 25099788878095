const locale = {
  organization: 'Organizasyon',
  organizations: 'Organizasyonlar',
  organizationChart: 'Organizasyon Şeması',
  update: 'Güncelle',
  delete: 'Sil',
  view: 'Görüntüle',
  list: 'Liste',
  createdSuccessfully: 'Kullanıcı başarıyla oluşturuldu',
  updatedSuccessfully: 'Kullanıcı başarıyla güncellendi',
  deleteConfirm: 'Kullanıcı hesabını silmek istediğinizden emin misiniz "',
  deletedSuccessfully: 'Kullanıcı başarıyla silindi',
  info: 'Bu bölümden ekibinize kullanıcılar ekleyebilirsiniz.',
  name: 'Ad',
  email: 'E-posta',
  surname: 'Soyad',
  viewProfile: 'Profili Görüntüle',
  roles: 'Roller',
  editChart: 'Şemayı Düzenle',
  save: 'Kaydet',
  editOrganizationChart: 'Organizasyon Şemasını Düzenle',
  editOrganizationDetail: 'Kullanıcıları istediğiniz konuma sürükleyip bırakın.',
  createRole: 'Rol Oluştur',
  userList: 'Kullanıcı Listesi',
  rolesInfo: 'Roller oluşturabilir ve bunlara izinler atayabilirsiniz.',
  createUserSuccess: 'Kullanıcı başarıyla oluşturuldu',
  chart: 'Şema',
  emptyState1: 'Burada hiçbir Kullanıcı bulunmadı.',
  createUser: 'Kullanıcı Oluştur',
  users: 'Kullanıcılar',
  user: 'Kullanıcı',
  activeUsers: 'Aktif Kullanıcı',
  activeUser: 'Aktif Kullanıcı',
  create: 'Oluştur',
  new: 'Yeni',
  roleName: 'Rol Adı',
  addRole: 'Ekle',
  updateRole: 'Güncelle',
  deleteRole: 'Sil',
  roleCreatedSuccess: 'Rol başarıyla oluşturuldu',
  roleUpdatedSuccess: 'Rol başarıyla güncellendi',
  roleDeletedSuccess: 'Rol başarıyla silindi',
  errorFetchingPermissions: 'İzinler getirilirken hata oluştu',
  errorFetchingRoles: 'Roller getirilirken hata oluştu',
  errorCreatingRole: 'Rol oluşturulurken hata oluştu',
  errorUpdatingRole: 'Rol güncellenirken hata oluştu',
  errorDeletingRole: 'Rol silinirken hata oluştu',
  permissionUpdateSuccess: 'Kullanıcı izinleri başarıyla güncellendi',
  subject: 'Konu',
  capabilities: 'Yetkinlikler',
  allPermissions: 'Tüm İzinler',
  all: 'Tümü',
  advancedOptions: 'Gelişmiş Seçenekler',
  id: 'Kimlik',
  emailAddress: 'E-posta Adresi',
  status: 'Durum',
  emailVerified: 'E-posta Doğrulandı',
  phoneNumber: 'Telefon',
  country: 'Ülke',
  city: 'Şehir',
  phone: 'Telefon No',
  lastLoginDate: 'Son Giriş Tarihi',
  createdAt: 'Oluşturulma Tarihi',
  actions: 'İşlemler',
  addNewRole: 'Yeni Rol Ekle',
  yes: 'Evet',
  no: 'Hayır',
  details: 'Detaylar',
  jobTitle: 'İş Ünvanı',
  deleteMessage: 'Bu kullanıcıyı silmek istediğinize emin misiniz?',
  cancel: 'İptal',
  userDeleteSuccess: 'Kullanıcı başarıyla silindi!',
  userDeleteError: 'Kullanıcı silinirken bir hata oluştu',
  updateChartSuccessfully: 'Organizasyon şeması başarıyla güncellendi!',
  address: 'Adres',
  birthDate: 'Doğum Tarihi',
  gender: 'Cinsiyet',
  language: 'Dil',
  description: 'Açıklama',
  rolesAndPermissions: 'Roller & İzinler',
  ownPermissionsAlert: 'Kendi izinlerinizi değiştiremezsiniz.',
  zoom: 'Yakınlaştır',
  tree: 'Ağaç Görünümü',
  listView: 'Liste Görünümü',
  createSuccess: 'Organizasyon başarıyla oluşturuldu',
  updateSuccess: 'Organizasyon başarıyla güncellendi',
  updateError: 'Organizasyon güncellenirken hata oluştu',
  fetchError: 'Organizasyon getirilirken hata oluştu',
  fetchSuccess: 'Organizasyon başarıyla getirildi',
  undefinedUser: 'Tanımsız Kullanıcı',
  chartUpdateSuccess: 'Şema başarıyla güncellendi!',
  actionScope: 'Eylem Kapsamı',
  action: 'Eylem',
  userDetail: 'Kullanıcı Detayı',
  admin: 'Admin',
  department: 'Departman',
  addUser: 'Kullanıcı Ekle',
  createDepartment: 'Departman Oluştur',
  updateUser: 'Kullanıcı Güncelle',
  notDeleteOwnConfirm: 'Kendi kendinizi silemezsiniz.',
  updateUserSuccess: 'Kullanıcı başarıyla güncellendi',
  removeAccount: 'Hesabı Sil',
  profile: 'Profil',
  permissions: 'İzinler',
  associatedLeads: 'Bağlanan Leadler',
  associatedCustomers: 'Bağlanan Müşteriler',
  associatedEstimates: 'Bağlanan Tahminler',
  associatedProposals: 'Bağlanan Teklifler',
  associatedInvoices: 'Bağlanan Faturalar',
  associatedTasks: 'Görevler',
  about: 'Hakkında',
  detail: 'Kullanıcı Detayı',
  users_: 'kullanıcı',
  resendConfirmation: 'Tekrar Doğrulama Gönder',
  resendSuccess: 'Tekrar doğrulama gönderildi',
};

export default locale;

const locale = {
  organization: 'Organizzazione',
  organizations: 'Organizzazioni',
  organizationChart: 'Organigramma',
  update: 'Aggiorna',
  delete: 'Elimina',
  view: 'Visualizza',
  createdSuccessfully: 'Utente creato con successo',
  updatedSuccessfully: 'Utente aggiornato con successo',
  deleteConfirm: 'Sei sicuro di voler rimuovere l\'account utente "',
  deletedSuccessfully: 'Utente eliminato con successo',
  info: 'Puoi aggiungere utenti al tuo team da questa sezione.',
  name: 'Nome',
  email: 'Email',
  surname: 'Cognome',
  viewProfile: 'Visualizza profilo',
  roles: 'Ruoli',
  editChart: 'Modifica organigramma',
  save: 'Salva',
  editOrganizationChart: 'Modifica organigramma',
  editOrganizationDetail: 'Trascina e rilascia gli utenti nella posizione desiderata.',
  createRole: 'Crea ruolo',
  userList: 'Lista utenti',
  rolesInfo: 'Puoi creare ruoli e assegnare loro permessi.',
  createUserSuccess: 'Utente creato con successo',
  chart: 'Vista organigramma',
  list: 'Vista elenco',
  users: 'Utenti',
  user: 'Utente',
  activeUsers: 'Utenti attivi',
  activeUser: 'Utente attivo',
  create: 'Crea',
  new: 'Nuovo',
  roleName: 'Nome ruolo',
  addRole: 'Aggiungi',
  updateRole: 'Aggiorna',
  deleteRole: 'Elimina',
  roleCreatedSuccess: 'Ruolo creato con successo',
  roleUpdatedSuccess: 'Ruolo aggiornato con successo',
  roleDeletedSuccess: 'Ruolo eliminato con successo',
  errorFetchingPermissions: 'Errore nel recupero dei permessi',
  errorFetchingRoles: 'Errore nel recupero dei ruoli',
  errorCreatingRole: 'Errore nella creazione del ruolo',
  errorUpdatingRole: 'Errore nell\'aggiornamento del ruolo',
  errorDeletingRole: 'Errore nell\'eliminazione del ruolo',
  permissionUpdateSuccess: 'I permessi dell\'utente sono stati aggiornati con successo',
  subject: 'Soggetto',
  capabilities: 'Capacità',
  allPermissions: 'Tutti i permessi',
  all: 'Tutti',
  advancedOptions: 'Opzioni avanzate',
  id: 'ID',
  emailAddress: 'Indirizzo email',
  status: 'Stato',
  emailVerified: 'Email verificata',
  phoneNumber: 'Numero di telefono',
  country: 'Paese',
  city: 'Città',
  phone: 'Numero di telefono',
  lastLoginDate: 'Data ultimo accesso',
  createdAt: 'Creato il',
  actions: 'Azioni',
  addNewRole: 'Aggiungi nuovo ruolo',
  yes: 'Sì',
  no: 'No',
  details: 'Dettagli',
  jobTitle: 'Titolo di lavoro',
  deleteMessage: 'Sei sicuro di voler eliminare questo utente?',
  cancel: 'Annulla',
  userDeleteSuccess: 'Utente eliminato con successo',
  userDeleteError: 'Eliminazione utente fallita',
  updateChartSuccessfully: 'Organigramma aggiornato con successo',
  address: 'Indirizzo',
  birthDate: 'Data di nascita',
  gender: 'Genere',
  language: 'Lingua',
  description: 'Descrizione',
  rolesAndPermissions: 'Ruoli e permessi',
  ownPermissionsAlert: 'Non puoi modificare i tuoi permessi.',
  zoom: 'Zoom',
  tree: 'Vista ad albero',
  createSuccess: 'Organigramma creato con successo',
  updateSuccess: 'Organigramma aggiornato con successo',
  updateError: 'Errore nell\'aggiornamento dell\'organigramma',
  fetchError: 'Errore nel recupero dell\'organigramma',
  fetchSuccess: 'Organigramma recuperato con successo',
  undefinedUser: 'Utente non definito',
  chartUpdateSuccess: 'Organigramma aggiornato con successo!',
  listView: 'Vista elenco',
  action: 'Azione',
  actionScope: 'Ambito azione',
  userDetail: 'Dettaglio utente',
  admin: 'Amministratore',
  department: 'Dipartimento',
  addUser: 'Aggiungi utente',
  createDepartment: 'Crea dipartimento',
  updateUser: 'Aggiorna utente',
  notDeleteOwnConfirm: 'Non puoi eliminare te stesso.',
  updateUserSuccess: 'Utente aggiornato con successo',
  removeAccount: 'Rimuovi account',
  profile: 'Profilo',
  permissions: 'Permessi',
  associatedLeads: 'Lead associati',
  associatedCustomers: 'Clienti associati',
  associatedEstimates: 'Preventivi associati',
  associatedProposals: 'Proposte associate',
  associatedInvoices: 'Fatture associate',
  associatedTasks: 'Attività associate',
  about: 'Informazioni',
  detail: 'Dettaglio utente',
  users_: 'utenti',
  resendConfirmation: 'Rinvia conferma',
  resendSuccess: 'Conferma inviata con successo',
};

export default locale; 
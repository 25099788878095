const locale = {
  payments: 'Pagamenti',
  payment: 'Pagamento',
  paymentConversions: 'Conversioni di Pagamento',
  title: 'Pagamenti',
  create: 'Aggiungi Pagamento',
  update: 'Aggiorna Pagamento',
  delete: 'Elimina Pagamento',
  createSuccess: 'Pagamento aggiunto con successo.',
  updateSuccess: 'Pagamento aggiornato con successo.',
  deleteSuccess: 'Pagamento eliminato con successo.',
  deleteConfirm: 'Sei sicuro di voler eliminare questo pagamento? Questa azione non può essere annullata.',
  emptyState1: 'Nessun pagamento registrato finora.',
  emptyState2:
    'Tieni traccia e gestisci i pagamenti delle tue fatture qui per mantenere le tue finanze organizzate e aggiornate.',

  // detailDrawer
  generalInfoTitle: 'Informazioni Generali',
  date: 'Data di Pagamento',
  amountPaid: 'Importo Pagato',
  confirmationNumber: 'Numero di Conferma',
  currency: 'Valuta',
};

export default locale; 
import { GridColDef } from '@mui/x-data-grid-pro';
import { IGridColConfigInput } from '../infra/IGridColConfigInput';
import { generateBaseColDef } from '../infra/GridColDefFactory';
import { convertMaterailToIconifyIconName } from 'utils/convertMaterialToIconifyIcon';
import { Iconify } from 'components/iconify';

export const BaseIconGridColumnDef = (params: { config: IGridColConfigInput }): GridColDef => {
  const baseColDef = generateBaseColDef({
    config: params.config,
  });

  baseColDef.renderCell = (cellParams: any) => {
    return (
      <Iconify
        icon={convertMaterailToIconifyIconName(cellParams.value)}
        sx={{ mt: 2, ml: 0.5, fontSize: 14 }}
      />
    );
  };
  baseColDef.renderEditCell = () => null;

  return baseColDef;
};

const locale = {
  returns: 'Rücksendungen',
  return: 'Rücksendung',
  returnConversions: 'Rücksendungsumwandlungen',
  title: 'Rücksendungen',
  create: 'Rücksendung hinzufügen',
  update: 'Rücksendung aktualisieren',
  delete: 'Rücksendung löschen',
  createSuccess: 'Rücksendung erfolgreich hinzugefügt.',
  updateSuccess: 'Rücksendung erfolgreich aktualisiert.',
  deleteSuccess: 'Rücksendung erfolgreich gelöscht.',
  deleteConfirm: 'Sind Sie sicher, dass Sie diese Rücksendung löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  emptyState1: 'Noch keine Rücksendungen erfasst.',
  emptyState2:
    'Verwalten und überprüfen Sie hier alle Produkt- oder Servicerücksendungen, um Ihre Aufzeichnungen genau zu halten und Kundenbedürfnisse zu erfüllen.',

  // detailDrawer
  generalInfoTitle: 'Allgemeine Informationen',
  isOpen: 'Ist offen',
  refundAmount: 'Rückerstattungsbetrag',
  resolveReason: 'Lösungsgrund',
  disapproveReason: 'Ablehnungsgrund',
  returnProducts: 'Rücksendeprodukte',
  amount: 'Betrag',
  reason: 'Grund',
  isPartial: 'Ist teilweise',
  note: 'Notiz',
  statusChangeSuccess: 'Status erfolgreich geändert',
  changeStatus: 'Status ändern',
};

export default locale; 
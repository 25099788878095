const locale = {
  somethingWentWrong: 'Что-то пошло не так!',
  loginSuccess: 'Вход выполнен успешно',
  signinText: 'Войдите в систему.',
  emailAddress: 'Электронная почта',
  password: 'Пароль',
  forgotPassword: 'Забыли пароль?',
  login: 'Войти',
  continueWith: 'или продолжить с',
  noAccount: 'Нет аккаунта?',
  signUp: 'Зарегистрироваться',
  logoAlt: 'логотип',
  growYourBusiness: 'Все, что нужно для развития вашего',
  business: 'Бизнеса',
  bgPattern: 'фоновый узор',
  manageYourBusiness:
    'Эффективно управляйте своим бизнесом, увеличивайте продажи, привлекайте потенциальных клиентов и развивайтесь, присоединившись к сообществу.',

  quote1: 'Ваши самые недовольные клиенты - ваш самый большой источник для обучения.',
  quoteName1: 'Билл Гейтс',
  quoteCompany1: 'Microsoft',
  quoteImage1: 'Bill-Gates',

  quote2: 'Обслуживание клиентов не должно быть отделом. Это должна быть вся компания.',
  quoteName2: 'Тони Шей',
  quoteCompany2: 'Zappos',
  quoteImage2: 'Tony-Hsieh',

  quote3: 'Единственная причина, по которой мы занимаемся бизнесом - это сделать жизнь наших клиентов проще.',
  quoteName3: 'Мэтью Оджерс',
  quoteCompany3: 'Odgers Law Group',
  quoteImage3: 'Matthew-Odgers',

  quote4: 'Если вы не заботитесь о своем клиенте, это сделает ваш конкурент.',
  quoteName4: 'Боб Хуи',
  quoteCompany4: 'Ideaman',
  quoteImage4: 'Bob-Hooey',

  quote5: 'В продажах рекомендация - это ключ к двери сопротивления.',
  quoteName5: 'Бо Беннетт',
  quoteCompany5: 'Archieboy',
  quoteImage5: 'Bo-Bennett',

  quote6: 'Всегда делайте все возможное. То, что вы сеете сейчас, пожнете позже.',
  quoteName6: 'Ог Мандино',
  quoteCompany6: 'Author',
  quoteImage6: 'Og-Mandino',

  quote7: 'Один из величайших подарков, которые вы можете дать кому-то - это подарок вашего внимания.',
  quoteName7: 'Джим Рон',
  quoteCompany7: 'Author',
  quoteImage7: 'Jim-Rohn',

  quote8: 'Делайте то, что вы делаете так хорошо, чтобы люди захотели увидеть это снова и привести своих друзей.',
  quoteName8: 'Уолт Дисней',
  quoteCompany8: 'Disney',
  quoteImage8: 'Walt-Disney',

  passwordResetSuccess: 'Ваш пароль успешно сброшен!',
  resetPasswordTitle: 'Сброс пароля',
  enterNewPassword: 'Пожалуйста, введите новый пароль.',
  passwordAgain: 'Подтвердите пароль',
  resetPassword: 'Сбросить пароль',
  alreadyHaveAccount: 'Уже есть аккаунт?',
  resetPasswordRequestSuccess: 'Запрос на сброс пароля успешно отправлен',
  forgotPasswordTitle: 'Забыли пароль',
  resetPasswordInstructions: 'Вы можете сбросить пароль, введя свой email адрес.',
  rememberPassword: 'Вспомнили пароль?',
  userConfirmedSuccess: 'Пользователь успешно подтвержден!',
  emailConfirmationTitle: 'Подтверждение email',
  TwoFAConfirmationTitle: 'Двухфакторная аутентификация',
  enterOtp: 'Пожалуйста, введите код OTP, отправленный на ваш email.',
  submit: 'Проверить',
  didNotReceiveCode: 'Не получили код?',
  resendCode: 'Отправить код повторно',
  resendCodeTitle: 'Повторная отправка кода подтверждения',
  enterEmailToResend: 'Пожалуйста, введите ваш email для получения кода подтверждения.',
  email: 'Email',
  close: 'Закрыть',
  send: 'Отправить',
  signupSuccess: 'Регистрация прошла успешно',
  letsRegister: 'Давайте зарегистрируемся.',
  name: 'Имя',
  surname: 'Фамилия',
  iAccept: 'Я принимаю',
  and: 'и',
  with: 'и',
  termsAndConditions: 'Условия использования',
  privacyPolicy: 'Политику конфиденциальности',
  explicitConsent: 'Политику явного согласия',
  kvkkPolicy: 'Политику информирования KVKK',
  orContinueWith: 'или продолжить с',
  createPassword: 'Создать пароль',
  createPasswordTitle: 'Создание пароля',
  signupSuccessfullTitle: 'Регистрация прошла успешно!',
  signupSuccessfullDescription: 'Пожалуйста, проверьте свою почту для подтверждения аккаунта.',
  backToLogin: 'Вернуться к входу',
  resendConfirmationSuccessTitle: 'Код подтверждения успешно отправлен повторно',
  resendConfirmationSuccessText: 'Пожалуйста, проверьте свою почту для подтверждения аккаунта.',
  resentCodeSuccess: 'Код успешно отправлен повторно',
  acceptAllPolicies: 'Пожалуйста, примите все политики!',
  alreadyRegisteredError: 'Вы уже зарегистрированы! Пожалуйста, войдите.',
  userNotFoundError: 'Пользователь не найден!',

  proposal: 'Предложение',
  estimate: 'Смета',
  invoice: 'Счет',
  startDate: 'Дата начала',
  expireDate: 'Дата истечения',
  deadline: 'Срок',
  proposalInformation: 'Информация о предложении',
  estimateInformation: 'Информация о смете',
  invoiceInformation: 'Информация о счете',
  billTo: 'Плательщик',
  bankName: 'Название банка',
  country: 'Страна',
  iban: 'IBAN',
  currency: 'Валюта',
  productDescription: 'Описание продукта',
  qty: 'КОЛ-ВО',
  rate: 'Ставка',
  tax: 'Налог',
  amount: 'Сумма',
  projectTimeline: 'График проекта',
  timeline: 'График',
  accept: 'Принять',
  decline: 'Отклонить',
  download: 'Скачать',
  comments: 'Комментарии',
  documents: 'Документы',
  uploadDocument: 'Загрузить документ',
  addDocument: 'Добавить документ',

  estimateAccepted: 'Смета принята',
  estimateDeclined: 'Смета отклонена',
  proposalAccepted: 'Предложение принято',
  proposalDeclined: 'Предложение отклонено',
  invoiceAccepted: 'Счет принят',
  invoiceDeclined: 'Счет отклонен',
  declineReason: 'Причина отказа',
  declineReasonPlaceholder: 'Пожалуйста, укажите причину отказа'
};

export default locale;

import crmLocale from '../../apps/crm/locales/pt';
import authLocale from '../../apps/auth/locales/pt';
import leadPlusLocale from '../../apps/leadPlus/locales/pt';
import communityLocale from '../../apps/community/locales/pt';
import marketingLocale from '../../apps/marketing/locales/pt';
import windowLocale from '../../apps/window/locales/pt';
import settingsLocale from '../../apps/settings/locales/pt';
import accountLocale from '../../apps/account/locales/pt';
import webNotificationLocale from '../../apps/webNotification/locales/pt';
import diskLocale from '../../apps/disk/locales/pt';

const locale = {
  crm: {
    ...crmLocale,
  },
  auth: {
    ...authLocale,
  },
  leadPlus: {
    ...leadPlusLocale,
  },
  community: {
    ...communityLocale,
  },
  marketing: {
    ...marketingLocale,
  },
  window: {
    ...windowLocale,
  },
  settings: {
    ...settingsLocale,
  },
  account: {
    ...accountLocale,
  },
  webNotification: {
    ...webNotificationLocale,
  },
  disk: {
    ...diskLocale,
  },
  enums: {
    '-': '-',
    undefined: '-',
    null: '-',
    active: 'Ativo',
    pending: 'Pendente',
    passive: 'Passivo',
    neutral: 'Neutro',
    cold: 'Frio',
    warm: 'Morno',
    hot: 'Quente',
    customer: 'Cliente',
    unqualified: 'Não qualificado',
    IQL: 'IQL (Informação)',
    MQL: 'MQL (Marketing)',
    SQL: 'SQL (Vendas)',
    en: 'Inglês',
    fr: 'Francês',
    de: 'Alemão',
    it: 'Italiano',
    pt: 'Português',
    ru: 'Russo',
    es: 'Espanhol',
    tr: 'Turco',
    individual: 'Individual',
    business: 'Empresa',
    government: 'Governo',
    b2b: 'B2B',
    b2c: 'B2C',
    TRY: 'TRY',
    EUR: 'EUR',
    USD: 'USD',
    IRR: 'IRR',
    GBP: 'GBP',
    NOK: 'NOK',
    RUB: 'RUB',
    CHF: 'CHF',
    flat: 'Fixo',
    hourly: 'Por hora',
    daily: 'Diário',
    monthly: 'Mensal',
    NDA: 'NDA',
    termsAndConditions: 'Termos e Condições',
    lead: 'Lead',
    unknown: 'Desconhecido',
    everyday: 'Todos os dias',
    everyWeek: 'Toda semana',
    annual: 'Anual',
    email: 'Email',
    sms: 'SMS',
    push: 'Push',
    web: 'Web',
    male: 'Masculino',
    female: 'Feminino',
    other: 'Outro',
    All: 'Todos',
    Unread: 'Não lido',
    automatic: 'Automático',
    manual: 'Manual',
    high: 'Alto',
    low: 'Baixo',
    medium: 'Médio',
    urgent: 'Urgente',
    draft: 'Rascunho',
    sent: 'Enviado',
    opened: 'Aberto',
    revised: 'Revisado',
    declined: 'Recusado',
    accepted: 'Aceito',
    product: 'Produto',
    service: 'Serviço',
    iyzico: 'Iyzico',
    stripe: 'Stripe',
    posbasit: 'POS Basit',
    paytr: 'PayTR',
    moneyTransfer: 'Transferência bancária',
    defective: 'Defeituoso',
    incorrectItem: 'Item incorreto',
    changeOfMind: 'Mudança de ideia',
    throughTasks: 'Através de tarefas',
    fixedRate: 'Taxa fixa',
    projectHours: 'Horas de projeto',
    taskHours: 'Horas de tarefa',
    onHold: 'Em espera',
    nearToExpire: 'Próximo de expirar',
    trash: 'Lixeira',
    A: 'A',
    B: 'B',
    C: 'C',
    yes: 'Sim',
    no: 'Não',
    default: 'Padrão',
    meeting: 'Reunião',
    call: 'Chamada',
    event: 'Evento',
    postponed: 'Adiado',
    canceled: 'Cancelado',
    TWENTY_FOUR_HOURS: '24 horas',
    TWELVE_HOURS: '12 horas',
    '24-hours': '24 horas',
    '12-hours': '12 horas',
    requested: 'Solicitado',
    inspected: 'Inspecionado',
    approved: 'Aprovado',
    disapproved: 'Reprovado',
    resolved: 'Resolvido',
    refund: 'Reembolso',
    replacement: 'Substituição',
    repair: 'Reparo',
    exchange: 'Troca',

    // UIElementType
    fieldInput: 'Campo de entrada',
    header: 'Cabeçalho',
    paragraph: 'Parágrafo',

    // LeadFieldEnum
    fullName: 'Nome completo',
    position: 'Cargo',
    website: 'Site',
    phoneNumber: 'Número de telefone',
    company: 'Empresa',
    sector: 'Setor',
    country: 'País',
    state: 'Estado',
    city: 'Cidade',
    documents: 'Documentos',

    // reminder
    schedule: 'Agendar',
    complete: 'Completar',
    cancel: 'Cancelar',
    scheduled: 'Agendado',
    unscheduled: 'Não agendado',
    expired: 'Expirado',
    cancelled: 'Cancelado',

    // subscription status
    ongoing: 'ATIVO',
    ongoingWithFailure: 'ATIVO',
    ongoingWithCancelled: 'ATIVO',
    failed: 'FALHOU',

    //task status
    notStarted: 'Não iniciado',
    inProgress: 'Em andamento',
    tested: 'Testado',
    awaitingFeedback: 'Aguardando feedback',
    completed: 'Concluído',

    // target type
    Lead: 'Lead',
    Customer: 'Cliente',
    Estimate: 'Orçamento',
    Proposal: 'Proposta',
    Invoice: 'Fatura',
    Task: 'Tarefa',
    User: 'Usuário',
    Organization: 'Organização',
    Project: 'Projeto',
    None: 'Nenhum',
    Ticket: 'Ticket',
    Expense: 'Despesa',
    Contract: 'Contrato',

    //ticket categories
    incidentTicket: 'Ticket de incidente',
    requestForInformation: 'Solicitação de informação',
    serviceRequest: 'Solicitação de serviço',
    problemTicket: 'Ticket de problema',
    changeRequest: 'Solicitação de alteração',
    featureRequest: 'Solicitação de recurso',
    feedback: 'Feedback',
    complaint: 'Reclamação',
    maintenanceAndOutageReport: 'Relatório de manutenção e interrupção',
    billingAndPaymentIssue: 'Problema de faturamento e pagamento',
    internalTicket: 'Ticket interno',
    open: 'Aberto',
    answered: 'Respondido',
    closed: 'Fechado',

    //permissions
    auth: 'Autenticação',
    comment: 'Comentário',
    dms: 'Documento',
    file: 'Documento',
    note: 'Nota',
    notification: 'Notificação',
    payment: 'Pagamento',
    presale: 'Pré-venda',
    reminder: 'Lembrete',
    sale: 'Venda',
    task: 'Tarefa',
    user: 'Usuário',
    permission: 'Permissão',
    role: 'Função',
    leadSource: 'Fonte de lead',
    leadStatus: 'Status de lead',
    leadTag: 'Tag de lead',
    productCategory: 'Categoria de produto',
    webNotification: 'Notificação web',
    notificationHistory: 'Histórico de notificações',
    order: 'Pedido',
    purchase: 'Compra',
    subscription: 'Assinatura',
    proposal: 'Proposta',
    estimate: 'Orçamento',
    customerCategory: 'Categoria de cliente',
    customerTag: 'Tag de cliente',
    invoice: 'Fatura',
    invoicePayment: 'Pagamento',
    invoiceReturn: 'Devolução',
    organization: 'Organização',
    organizationSettings: 'Configurações da organização',
    organizationChart: 'Organograma',
    department: 'Departamento',
    userRole: 'Função do usuário',
    userPermission: 'Permissão do usuário',
    manage: 'Gerenciar',
    view: 'Visualizar',
    export: 'Exportar',
    import: 'Importar',
    calendar: 'Calendário',
    appointment: 'Compromisso',
    contract: 'Contrato',
    contractTemplate: 'Modelo de contrato',
    expense: 'Despesa',
    project: 'Projeto',
    ticket: 'Ticket',
    dashboard: 'Painel',

    // file extension
    jpg: 'jpg',
    png: 'png',
    pdf: 'pdf',
    doc: 'doc',
    docx: 'docx',
    xls: 'xls',
    xlsx: 'xlsx',
    ppt: 'ppt',
    pptx: 'pptx',
    txt: 'txt',
    mp4: 'mp4',
    zip: 'zip',

    // payment status
    unPaid: 'Não pago',
    partiallyPaid: 'Parcialmente pago',
    totallyPaid: 'Totalmente pago',
    
  },
  organization: 'Organização',
  department: 'Departamento',
  user: 'Usuário',
  aboutUs: 'Sobre nós',
  termsAndConditions: 'Termos e Condições',
  uploadDocuments: 'Carregar documentos',
  privacyPolicy: 'Política de Privacidade',
  cookiesPolicy: 'Política de Cookies',
  resources: 'Recursos',
  allRightsReserved: 'Todos os direitos reservados',
  rowsPerPage: 'Linhas por página',
  chartUpdateSuccess: 'Gráfico atualizado com sucesso!',
  imageUploadSuccess: 'Imagem carregada com sucesso!',
  uploadPicture: 'Carregar uma imagem',
  expiresIn: 'Expira em ',
  expiresDays: ' dias',
  subscriptionExpired: 'Seu plano expirou!',
  noOptionsText: 'Sem opções',
  noCategory: 'Sem categoria',

  ////////////////////////////////////////////////////////////////
  // Validations
  ////////////////////////////////////////////////////////////////

  isRequired: 'Este campo é obrigatório',
  minValue0: 'O valor mínimo é 0',
  fullNameIsRequired: 'Nome completo é obrigatório',
  emailIsRequired: 'Email é obrigatório',
  emailIsInvalid: 'Email é inválido',
  phoneIsInvalid: 'Telefone é inválido',
  nameIsRequired: 'Nome é obrigatório',
  titleIsRequired: 'Título é obrigatório',
  profileImageIsRequired: 'Imagem de perfil é obrigatória',
  descriptionIsRequired: 'Descrição é obrigatória',
  subjectIsRequired: 'Assunto é obrigatório',
  phoneNumberIsRequired: 'Número de telefone é obrigatório',
  quotaYupIsRequired: 'Cota é obrigatória',
  dateYupIsRequired: 'Data é obrigatória',
  statusYupIsRequired: 'Status é obrigatório',
  locationYupIsRequired: 'Localização é obrigatória',
  detailsIsRequired: 'Detalhes são obrigatórios',
  surnameIsRequired: 'Sobrenome é obrigatório',
  birthDateIsRequired: 'Data de nascimento é obrigatória',
  genderIsRequired: 'Gênero é obrigatório',
  countryIsRequired: 'País é obrigatório',
  cityIsRequired: 'Cidade é obrigatória',
  addressIsRequired: 'Endereço é obrigatório',
  contentIsRequired: 'Conteúdo é obrigatório',
  readingTimeIsRequired: 'Tempo de leitura é obrigatório',
  tagsIsRequired: 'Tags são obrigatórias',
  referencesIsRequired: 'Referências são obrigatórias',
  relatedVideoIsRequired: 'Vídeo relacionado é obrigatório',
  authorIsRequired: 'Autor é obrigatório',
  categoryIsRequired: 'Categoria é obrigatória',
  targetCategoryIsRequired: 'Categoria alvo é obrigatória',
  languageIsRequired: 'Idioma é obrigatório',
  domainIsRequired: 'Domínio é obrigatório',
  targetIsRequired: 'Alvo é obrigatório',
  questionIsRequired: 'Pergunta é obrigatória',
  answerIsRequired: 'Resposta é obrigatória',
  titleIsTooShort25: 'Título é muito curto, o comprimento mínimo é 25',
  titleIsTooLong50: 'Título é muito longo, o comprimento máximo é 50',
  contentIsTooShort50: 'Conteúdo é muito curto, o comprimento mínimo é 50',
  contentIsTooLong250: 'Conteúdo é muito longo, o comprimento máximo é 250',
  descriptionIsTooShort400: 'Descrição é muito curta, o comprimento mínimo é 400',
  descriptionIsTooLong4000: 'Descrição é muito longa, o comprimento máximo é 4000',
  quotaMustBePositive: 'Cota deve ser positiva',
  quotaMustBeMultipleOf10: 'Cota deve ser múltiplo de 10',
  participantsIsRequired: 'Participantes são obrigatórios',
  startDateIsRequired: 'Data de início é obrigatória',
  durationIsRequired: 'Duração é obrigatória',
  quotaIsRequired: 'Cota é obrigatória',
  lastApplicationDateIsRequired: 'Data da última aplicação é obrigatória',
  statusIsRequired: 'Status é obrigatório',
  locationIsRequired: 'Localização é obrigatória',
  typeIsRequired: 'Tipo é obrigatório',
  participationLinkIsRequired: 'Link de participação é obrigatório',
  durationMustBeMultipleOf5: 'Duração deve ser múltiplo de 5',
  durationMustBePositive: 'Duração deve ser positiva',
  nameIsTooShort2: 'Nome é muito curto, o comprimento mínimo é 2',
  nameIsTooLong50: 'Nome é muito longo, o comprimento máximo é 50',
  surnameIsTooShort2: 'Sobrenome é muito curto, o comprimento mínimo é 2',
  surnameIsTooLong50: 'Sobrenome é muito longo, o comprimento máximo é 50',
  stateIsRequired: 'Estado é obrigatório',
  positionIsRequired: 'Cargo é obrigatório',
  companyIsRequired: 'Empresa é obrigatória',
  sectorIsRequired: 'Setor é obrigatório',
  qualificationIsRequired: 'Qualificação é obrigatória',
  prospectNameIsRequired: 'Nome do prospecto é obrigatório',
  prospectPhoneNumberIsRequired: 'Número de telefone do prospecto é obrigatório',
  prospectContactFirstNameIsRequired: 'Nome do contato do prospecto é obrigatório',
  prospectContactLastNameIsRequired: 'Sobrenome do contato do prospecto é obrigatório',
  prospectContactEmailIsRequired: 'Email do contato do prospecto é obrigatório',
  websiteIsInvalid: 'Site é inválido',
  identityNumberIsRequired: 'Número de identidade é obrigatório',
  taxOfficeIsRequired: 'Escritório fiscal é obrigatório',
  streetIsRequired: 'Rua é obrigatória',
  zipIsRequired: 'CEP é obrigatório',
  passwordIsRequired: 'Senha é obrigatória',
  passwordIsInvalid: 'Deve ter pelo menos 8 caracteres, incluir maiúsculas, minúsculas, números e símbolos.',
  passwordAgainIsInvalid: 'As senhas devem coincidir',
  cannotBeGreaterThanPrice: 'Não pode ser maior que o preço',
  endTimeMustBeLaterThanStartTime: 'Deve ser posterior à hora de início',
  mustBeNumber: 'Deve ser um número',

  cardValidation: {
    required: 'Este campo é obrigatório',
    invalid: 'Inválido',
    expired: 'Expirado',
  },

  ////////////////////////////////////////////////////////////////
  // Buttons
  ////////////////////////////////////////////////////////////////

  cancel: 'Cancelar',
  save: 'Salvar',
  update: 'Atualizar',
  delete: 'Excluir',
  create: 'Criar',
  add: 'Adicionar',
  close: 'Fechar',
  submit: 'Enviar',
  addDocument: 'Adicionar documento',
  upload: 'Carregar',
  view: 'Visualizar',
  edit: 'Editar',
  remove: 'Remover',
  myPlan: 'Meu plano',
  expandAll: 'Expandir tudo',
  collapseAll: 'Recolher tudo',
  show: 'Mostrar',
  download: 'Baixar',
  yes: 'Sim',
  no: 'Não',
  completed: 'Concluído',
  uploadDocument: 'Carregar documento',
  newTask: 'Nova tarefa',
  loadMore: 'Carregar mais',

  ////////////////////////////////////////////////////////////////
  // Tables
  ////////////////////////////////////////////////////////////////

  actions: 'Ações',
  title: 'Título',
  content: 'Conteúdo',
  category: 'Categoria',
  target: 'Alvo',
  status: 'Status',
  date: 'Data',
  location: 'Localização',
  details: 'Detalhes',
  name: 'Nome',
  surname: 'Sobrenome',
  birthDate: 'Data de nascimento',
  fullName: 'Nome completo',
  email: 'Email',
  subject: 'Assunto',
  createdAt: 'Criado em',
  userType: 'Tipo de usuário',
  page: 'Página',
  news: 'Notícias',
  offer: 'Oferta',
  products: 'Produtos',
  updatedAt: 'Atualizado em',
  readingTime: 'Tempo de leitura',
  tags: 'Tags',
  references: 'Referências',
  version: 'Versão',
  domain: 'Domínio',
  type: 'Tipo',
  language: 'Idioma',
  duration: 'Duração',
  startDate: 'Data de início',
  participants: 'Participantes',
  quota: 'Cota',
  question: 'Pergunta',
  answer: 'Resposta',
  lastApplicationDate: 'Data da última aplicação',
  selectAnOption: 'Selecione uma opção',
  showing: 'Mostrando {{start}} a {{end}} de {{total}} entradas',

  ////////////////////////////////////////////////////////////////
  // Menus
  ////////////////////////////////////////////////////////////////
  dashboard: 'Painel',
  blog: 'Blog',
  blogs: 'Blogs',
  categories: 'Categorias',
  authors: 'Autores',
  faq: 'FAQ',
  faqs: 'FAQs',
  faqCategory: 'Categorias de FAQ',
  form: 'Formulário',
  contactForm: 'Formulários de contato',
  subscriptionForm: 'Formulários de assinatura',
  blogTargetCategories: 'Categorias alvo',
  users: 'Usuários',
  departments: 'Departamentos',
  roles: 'Funções',
  permissions: 'Permissões',
  leadStatuses: 'Status de leads',
  leadSources: 'Fontes de leads',
  leadForms: 'Formulários de leads',
  customers: 'Clientes',
  contacts: 'Contatos',
  customerCategories: 'Categorias de clientes',
  estimates: 'Orçamentos',
  proposals: 'Propostas',
  invoices: 'Faturas',
  payments: 'Pagamentos',
  contracts: 'Contratos',
  returns: 'Devoluções',
  services: 'Serviços',
  reminders: 'Lembretes',
  appointments: 'Compromissos',
  starred: 'Favoritos',
  recent: 'Recentes',
  actionMenu: 'Menu de ações',
  themeMenu: 'Menu de temas',
  notifications: 'Notificações',
  languageMenu: 'Menu de idiomas',

  ////////////////////////////////////////////////////////////////
  // Permissions
  ////////////////////////////////////////////////////////////////

  organizationLevel: 'Nível da organização',
  organizationLevelDesc: 'O nível da organização em que o usuário está localizado.',
  departmentLevel: 'Nível do departamento',
  departmentLevelDesc: 'O nível do departamento em que o usuário está localizado.',
  userLevel: 'Nível do usuário',
  userLevelDesc: 'O nível do usuário em que o usuário está localizado.',

  ////////////////////////////////////////////////////////////////
  // Abbreviations
  ////////////////////////////////////////////////////////////////
  min: 'min',

  ////////////////////////////////////////////////////////////////
  // Inputs
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // Days
  ////////////////////////////////////////////////////////////////
  sunday: 'Domingo',
  monday: 'Segunda-feira',
  tuesday: 'Terça-feira',
  wednesday: 'Quarta-feira',
  thursday: 'Quinta-feira',
  friday: 'Sexta-feira',
  saturday: 'Sábado',
  today: 'Hoje',
};

export default locale; 
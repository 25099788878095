

export const routes = {
    auth: {
        login: "/",
        signup: "/signup",
        forgotPassword: "/forgot-password",
        forgotPasswordSuccessfull: "/forgot-password-successfull",
        signupSuccessfull: "/signup-successfull",
        confirmation: "/confirmation",
        createPassword: "/create-password",
        resetPassword: "/reset-password",
        resendConfirmationSuccessfull: "/resend-confirmation-successfull",
        twoFactorLogin: "/two-factor-login",
    },
}
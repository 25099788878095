const locale = {
  myAccount: "Il mio account",
  profilePicture: "Immagine del profilo",
  profile: "Profilo",
  name: "Nome",
  surname: "Cognome",
  birthDate: "Data di nascita",
  gender: "Genere",
  update: "Aggiorna",
  changeImage: "Cambia immagine",
  change: "Modifica",
  updateProfileSuccess: "Profilo aggiornato con successo",
  updateProfilePictureSuccess: "Immagine del profilo aggiornata con successo",
  country: "Paese",
  city: "Città",
  signatureImage: "Immagine della firma",
  twoFactorAuthEmail: "Email di autenticazione a due fattori",
};

export default locale; 
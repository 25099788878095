const locale = {
  // fields
  users: "Ricorda agli utenti",
  date: "Data promemoria",
  title: "Elenco promemoria",
  detail: "Dettaglio promemoria",
  sharedList: "Promemoria condivisi",
  privateList: "Promemoria privati",
  ownList: "I miei promemoria",
  allList: "Tutti i promemoria",
  reminder: "Promemoria",

  // actions
  create: "Crea promemoria",
  add: "Aggiungi promemoria",
  update: "Aggiorna promemoria",
  delete: "Elimina promemoria",

  // situational text
  createSuccess: "Promemoria creato con successo",
  updateSuccess: "Promemoria aggiornato con successo",
  deletedSuccessfully: "Promemoria eliminato con successo",
  deleteContent: "Sei sicuro di voler eliminare questo promemoria? Questa azione non può essere annullata.",
  emptyState1: "Nessun promemoria aggiunto ancora.",
  emptyState2: "Memorizza e gestisci qui tutti i dettagli dei promemoria per semplificare la fatturazione e mantenere accordi chiari con i tuoi clienti.",

  // detail drawer
  changeStatus: "Cambia stato",
  generalInfoTitle: "Informazioni generali",
  remindUsers: "Utenti da ricordare",
  remindDate: "Data promemoria",
  description: "Descrizione",
  status: "Stato",
  createdBy: "Creato da",
  schedule: "Programma",
  cancelSuccess: "Promemoria annullato con successo",
  completeSuccess: "Promemoria completato con successo",
  scheduleSuccess: "Promemoria programmato con successo",
};

export default locale; 
const locale = {
  demo: 'Демо',
  title: 'Что такое <highlight>CoCRM</highlight> Сообщество?',
  description:
    'В CoCRM, помимо эффективного управления вашими бизнес-процессами и взаимоотношениями с клиентами, мы также предлагаем пространство для сотрудничества, разработанное для расширения вашей сети и деловых возможностей.',
  featuresTitle: 'С Сообществом CoCRM вы можете:',
  features1: 'Получать новые бизнес-возможности через рекомендации.',
  features2: 'Связываться с надежными поставщиками.',
  features3: 'Находить надежных деловых партнеров.',
  features4: 'Расширять свою сеть, чтобы предлагать клиентам услуги на 360°.',
  buttonTitle: 'Присоединиться к Сообществу',
  formTitle: 'Присоединиться к Листу Ожидания',
  formDescription:
    'Пожалуйста, заполните форму ниже, и мы свяжемся с вами как можно скорее.',
  sendSuccess: 'Спасибо! Мы свяжемся с вами как можно скорее.',
  formButtonTitle: 'Присоединиться',
  fullName: 'Полное Имя',
  email: 'Электронная почта',
  phone: 'Телефон',
  orgName: 'Название Организации',
};

export default locale;

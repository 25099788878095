const locale = {
  somethingWentWrong: 'Bir şeyler yanlış gitti!',
  loginSuccess: 'Başarıyla giriş yapıldı',
  signinText: 'Hadi giriş yapalım.',
  emailAddress: 'E-posta adresi',
  password: 'Şifre',
  forgotPassword: 'Şifreni mi unuttun?',
  login: 'Giriş Yap',
  continueWith: 'veya devam et',
  noAccount: 'Hesabınız yok mu?',
  signUp: 'Kayıt ol',
  logoAlt: 'logo',
  growYourBusiness: 'İşinizi Büyütmek için ',
  business: 'Her Şey',
  manageYourBusiness:
    'İşletmenizi verimli bir şekilde yönetin, satışlarınızı artırın, potansiyel müşterilere ulaşın ve topluluğa katılarak kendinizi güçlendirin.',
  bgPattern: 'arka plan deseni',
  johnDoe: 'John Doe',
  ceoCompany: 'CEO, Şirket Test',
  passwordResetSuccess: 'Şifreniz başarıyla sıfırlandı!',
  resetPasswordTitle: 'Şifreyi Sıfırla',
  enterNewPassword: 'Lütfen yeni şifrenizi girin.',
  passwordAgain: 'Şifreyi Tekrar Girin',
  resetPassword: 'Şifreyi Sıfırla',
  alreadyHaveAccount: 'Zaten bir hesabınız var mı?',
  resetPasswordRequestSuccess: 'Şifre Sıfırlama Talebi Başarıyla Gönderildi',
  forgotPasswordTitle: 'Şifremi Unuttum',
  resetPasswordInstructions: 'E-posta adresinizi girerek şifrenizi sıfırlayabilirsiniz.',
  rememberPassword: 'Şifrenizi hatırladınız mı?',
  userConfirmedSuccess: 'Kullanıcı başarıyla onaylandı!',
  emailConfirmationTitle: 'E-posta Onayı',
  TwoFAConfirmationTitle: 'İki Faktörlü Kimlik Doğrulama',
  enterOtp: "E-postanıza gönderilen OTP'yi girin.",
  submit: 'Doğrula',
  didNotReceiveCode: 'Kodu almadınız mı?',
  resendCode: 'Kodu tekrar gönder',
  resendCodeTitle: 'Onaylama Kodunu Tekrar Gönder',
  enterEmailToResend: 'Onaylama kodunu almak için lütfen e-posta adresinizi girin.',
  email: 'E-posta',
  close: 'Kapat',
  send: 'Gönder',
  signupSuccess: 'Başarıyla kayıt olundu',
  letsRegister: 'Sizi kaydedelim.',
  name: 'İsim',
  surname: 'Soyisim',
  iAccept: 'Kabul ediyorum',
  and: 've',
  with: 'ile',
  termsAndConditions: 'Şartlar ve Koşullar',
  privacyPolicy: 'Gizlilik Politikası',
  explicitConsent: 'Açık Rıza Metni',
  kvkkPolicy: 'KVKK Aydınlatma Metni',
  orContinueWith: 'veya devam et',
  createPassword: 'Şifre Oluştur',
  createPasswordTitle: 'Şifre Oluştur',
  signupSuccessfullTitle: 'Başarıyla kayıt olundu!',
  backToLogin: 'Girişe Dön',
  signupSuccessfullDescription: 'Hesabınızı onaylamak için lütfen e-postanızı kontrol edin.',
  resendConfirmationSuccessTitle: 'E-posta onayı tekrar gönderildi!',
  resendConfirmationSuccessText: 'E-posta onayı tekrar gönderildi. E-postanızı kontrol edin.',
  resentCodeSuccess: 'Onaylama kodu tekrar gönderdi!',
  quote1: 'En mutsuz müşterileriniz, öğrenme için en büyük kaynağınızdır.',
  quoteName1: 'Bill Gates',
  quoteCompany1: 'Microsoft',
  quoteImage1: 'Bill-Gates',

  quote2: 'Müşteri hizmetleri bir departman olmamalıdır. Tüm şirket olmalıdır.',
  quoteName2: 'Tony Hsieh',
  quoteCompany2: 'Zappos',
  quoteImage2: 'Tony-Hsieh',

  quote3:
    'İşimizde var olmamızın tek nedeni, müşterilerimizin hayatını daha az zor hale getirmektir.',
  quoteName3: 'Matthew Odgers',
  quoteCompany3: 'Odgers Law Group',
  quoteImage3: 'Matthew-Odgers',

  quote4: 'Eğer müşterinize bakmıyorsanız, rakibiniz bakacaktır.',
  quoteName4: 'Bob Hooey',
  quoteCompany4: 'Ideaman',
  quoteImage4: 'Bob-Hooey',

  quote5: 'Satışta, bir referans direnç kapısının anahtarıdır.',
  quoteName5: 'Bo Bennett',
  quoteCompany5: 'Archieboy',
  quoteImage5: 'Bo-Bennett',

  quote6: 'Her zaman elinizden gelenin en iyisini yapın. Şimdi ektiğinizi, sonra biçeceksiniz.',
  quoteName6: 'Og Mandino',
  quoteCompany6: 'Yazar',
  quoteImage6: 'Og-Mandino',

  quote7: 'Birine verebileceğiniz en büyük hediyelerden biri, ona dikkatinizi vermektir.',
  quoteName7: 'Jim Rohn',
  quoteCompany7: 'Yazar',
  quoteImage7: 'Jim-Rohn',

  quote8: 'Yaptığınız işi öyle iyi yapın ki, tekrar görmek ve arkadaşlarını getirmek istesinler.',
  quoteName8: 'Walt Disney',
  quoteCompany8: 'Disney',
  quoteImage8: 'Walt-Disney',

  acceptAllPolicies: 'Lütfen tüm politikaları onaylayınız!',
  alreadyRegisteredError: 'Hesabınız zaten kayıtlı!',
  userNotFoundError: 'Kullanıcı bulunamadı!',

  proposal: 'Teklif',
  estimate: 'Tahmin',
  invoice: 'Fatura',
  startDate: 'Başlangıc Tarihi',
  deadline: 'Bitis Tarihi',
  proposalInformation: 'Teklif Bilgileri',
  estimateInformation: 'Tahmin Bilgileri',
  invoiceInformation: 'Fatura Bilgileri',
  billTo: 'Faturalanacak',
  bankName: 'Banka Adi',
  country: 'Ülke',
  iban: 'IBAN',
  currency: 'Para Birimi',
  productDescription: 'Ürün Aciklamasi',
  qty: 'QTY',
  rate: 'Oran',
  tax: 'Vergi',
  amount: 'Tutar',
  projectTimeline: 'Proje Zaman Çizelgesi',
  timeline: 'Zaman Çizelgesi',
  accept: 'Kabul Et',
  decline: 'Reddet',
  download: 'İndir',
  comments: 'Yorumlar',
  documents: 'Dökümanlar',
  uploadDocument: 'Döküman Yükle',
  addDocument: 'Döküman Ekle',

  estimateAccepted: 'Tahmin kabul edildi',
  estimateDeclined: 'Tahmin reddedildi',
  proposalAccepted: 'Teklif kabul edildi',
  proposalDeclined: 'Teklif reddedildi',
  invoiceAccepted: 'Fatura kabul edildi',
  invoiceDeclined: 'Fatura reddedildi',
  declineReason: 'Reddetme Nedeni',
  declineReasonPlaceholder: 'Lütfen reddetme nedeninizi giriniz.',
};

export default locale;

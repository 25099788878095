const locale = {
  title: "Отделы",
  delete: "Удалить",
  deleteConfirm: "Вы уверены, что хотите удалить этот отдел? Это действие нельзя отменить.",
  deletedSuccessfully: "Отдел успешно удален",
  create: "Создать Отдел",
  departmentNameLabel: "Название Отдела",
  rolesLabel: "Роли",
  addRole: "Добавить Роль",
  createDepartmentButton: "Создать Отдел",
  createSuccess: "Отдел успешно создан",
  columnId: "#",
  columnName: "Название",
  columnRoles: "Роли",
  columnActions: "Действия",
  headerTitle: "Отделы",
  createDepartment: "Создать Отдел",
  rolesButton: "Роли",
  emptyState1: "Отделы не найдены",
  emptyState2: "Пожалуйста, создайте новый отдел",
  addNewRole: "Добавить Новую Роль",
  updateDepartmentTitle: "Обновить Отдел",
  updateDepartmentButton: "Обновить",
  updateSuccess: "Отдел успешно обновлен",
};

export default locale; 
import crmLocale from '../../apps/crm/locales/ru';
import authLocale from '../../apps/auth/locales/ru';
import leadPlusLocale from '../../apps/leadPlus/locales/ru';
import communityLocale from '../../apps/community/locales/ru';
import marketingLocale from '../../apps/marketing/locales/ru';
import windowLocale from '../../apps/window/locales/ru';
import settingsLocale from '../../apps/settings/locales/ru';
import accountLocale from '../../apps/account/locales/ru';
import webNotificationLocale from '../../apps/webNotification/locales/ru';
import diskLocale from '../../apps/disk/locales/ru';

const locale = {
  crm: {
    ...crmLocale,
  },
  auth: {
    ...authLocale,
  },
  leadPlus: {
    ...leadPlusLocale,
  },
  community: {
    ...communityLocale,
  },
  marketing: {
    ...marketingLocale,
  },
  window: {
    ...windowLocale,
  },
  settings: {
    ...settingsLocale,
  },
  account: {
    ...accountLocale,
  },
  webNotification: {
    ...webNotificationLocale,
  },
  disk: {
    ...diskLocale,
  },
  enums: {
    '-': '-',
    undefined: '-',
    null: '-',
    active: 'Активный',
    pending: 'В ожидании',
    passive: 'Пассивный',
    neutral: 'Нейтральный',
    cold: 'Холодный',
    warm: 'Теплый',
    hot: 'Горячий',
    customer: 'Клиент',
    unqualified: 'Неквалифицированный',
    IQL: 'IQL (Информация)',
    MQL: 'MQL (Маркетинг)',
    SQL: 'SQL (Продажи)',
    en: 'Английский',
    fr: 'Французский',
    de: 'Немецкий',
    it: 'Итальянский',
    pt: 'Португальский',
    ru: 'Русский',
    es: 'Испанский',
    tr: 'Турецкий',
    individual: 'Индивидуальный',
    business: 'Бизнес',
    government: 'Правительство',
    b2b: 'B2B',
    b2c: 'B2C',
    TRY: 'TRY',
    EUR: 'EUR',
    USD: 'USD',
    IRR: 'IRR',
    GBP: 'GBP',
    NOK: 'NOK',
    RUB: 'RUB',
    CHF: 'CHF',
    flat: 'Фиксированный',
    hourly: 'Почасовой',
    daily: 'Ежедневный',
    monthly: 'Ежемесячный',
    NDA: 'NDA',
    termsAndConditions: 'Условия и положения',
    lead: 'Лид',
    unknown: 'Неизвестно',
    everyday: 'Каждый день',
    everyWeek: 'Каждую неделю',
    annual: 'Ежегодный',
    email: 'Email',
    sms: 'SMS',
    push: 'Push',
    web: 'Веб',
    male: 'Мужской',
    female: 'Женский',
    other: 'Другой',
    All: 'Все',
    Unread: 'Непрочитанные',
    automatic: 'Автоматический',
    manual: 'Ручной',
    high: 'Высокий',
    low: 'Низкий',
    medium: 'Средний',
    urgent: 'Срочный',
    draft: 'Черновик',
    sent: 'Отправлено',
    opened: 'Открыто',
    revised: 'Пересмотрено',
    declined: 'Отклонено',
    accepted: 'Принято',
    product: 'Продукт',
    service: 'Услуга',
    iyzico: 'Iyzico',
    stripe: 'Stripe',
    posbasit: 'POS Basit',
    paytr: 'PayTR',
    moneyTransfer: 'Банковский перевод',
    defective: 'Дефектный',
    incorrectItem: 'Неверный товар',
    changeOfMind: 'Изменение решения',
    throughTasks: 'Через задачи',
    fixedRate: 'Фиксированная ставка',
    projectHours: 'Часы проекта',
    taskHours: 'Часы задачи',
    onHold: 'На удержании',
    nearToExpire: 'Близко к истечению срока',
    trash: 'Корзина',
    A: 'A',
    B: 'B',
    C: 'C',
    yes: 'Да',
    no: 'Нет',
    default: 'По умолчанию',
    meeting: 'Встреча',
    call: 'Звонок',
    event: 'Событие',
    postponed: 'Отложено',
    canceled: 'Отменено',
    TWENTY_FOUR_HOURS: '24 часа',
    TWELVE_HOURS: '12 часов',
    '24-hours': '24 часа',
    '12-hours': '12 часов',
    requested: 'Запрошено',
    inspected: 'Проверено',
    approved: 'Одобрено',
    disapproved: 'Не одобрено',
    resolved: 'Решено',
    refund: 'Возврат',
    replacement: 'Замена',
    repair: 'Ремонт',
    exchange: 'Обмен',

    // UIElementType
    fieldInput: 'Поле ввода',
    header: 'Заголовок',
    paragraph: 'Параграф',

    // LeadFieldEnum
    fullName: 'Полное имя',
    position: 'Должность',
    website: 'Веб-сайт',
    phoneNumber: 'Номер телефона',
    company: 'Компания',
    sector: 'Сектор',
    country: 'Страна',
    state: 'Штат',
    city: 'Город',
    documents: 'Документы',

    // reminder
    schedule: 'Запланировать',
    complete: 'Завершить',
    cancel: 'Отменить',
    scheduled: 'Запланировано',
    unscheduled: 'Не запланировано',
    expired: 'Истекло',
    cancelled: 'Отменено',

    // subscription status
    ongoing: 'АКТИВНЫЙ',
    ongoingWithFailure: 'АКТИВНЫЙ',
    ongoingWithCancelled: 'АКТИВНЫЙ',
    failed: 'НЕУДАЧНЫЙ',

    //task status
    notStarted: 'Не начато',
    inProgress: 'В процессе',
    tested: 'Протестировано',
    awaitingFeedback: 'Ожидание отзыва',
    completed: 'Завершено',

    // target type
    Lead: 'Лид',
    Customer: 'Клиент',
    Estimate: 'Оценка',
    Proposal: 'Предложение',
    Invoice: 'Счет',
    Task: 'Задача',
    User: 'Пользователь',
    Organization: 'Организация',
    Project: 'Проект',
    None: 'Нет',
    Ticket: 'Тикет',
    Expense: 'Расход',
    Contract: 'Контракт',

    //ticket categories
    incidentTicket: 'Тикет инцидента',
    requestForInformation: 'Запрос информации',
    serviceRequest: 'Запрос на обслуживание',
    problemTicket: 'Тикет проблемы',
    changeRequest: 'Запрос на изменение',
    featureRequest: 'Запрос на функцию',
    feedback: 'Отзыв',
    complaint: 'Жалоба',
    maintenanceAndOutageReport: 'Отчет о техобслуживании и простое',
    billingAndPaymentIssue: 'Проблема с выставлением счетов и оплатой',
    internalTicket: 'Внутренний тикет',
    open: 'Открыто',
    answered: 'Отвечено',
    closed: 'Закрыто',

    //permissions
    auth: 'Аутентификация',
    comment: 'Комментарий',
    dms: 'Документ',
    file: 'Документ',
    note: 'Заметка',
    notification: 'Уведомление',
    payment: 'Оплата',
    presale: 'Предпродажа',
    reminder: 'Напоминание',
    sale: 'Продажа',
    task: 'Задача',
    user: 'Пользователь',
    permission: 'Разрешение',
    role: 'Роль',
    leadSource: 'Источник лида',
    leadStatus: 'Статус лида',
    leadTag: 'Тег лида',
    productCategory: 'Категория продукта',
    webNotification: 'Веб-уведомление',
    notificationHistory: 'История уведомлений',
    order: 'Заказ',
    purchase: 'Покупка',
    subscription: 'Подписка',
    proposal: 'Предложение',
    estimate: 'Оценка',
    customerCategory: 'Категория клиента',
    customerTag: 'Тег клиента',
    invoice: 'Счет',
    invoicePayment: 'Оплата',
    invoiceReturn: 'Возврат',
    organization: 'Организация',
    organizationSettings: 'Настройки организации',
    organizationChart: 'Организационная структура',
    department: 'Отдел',
    userRole: 'Роль пользователя',
    userPermission: 'Разрешение пользователя',
    manage: 'Управлять',
    view: 'Просмотр',
    export: 'Экспорт',
    import: 'Импорт',
    calendar: 'Календарь',
    appointment: 'Встреча',
    contract: 'Контракт',
    contractTemplate: 'Шаблон контракта',
    expense: 'Расход',
    project: 'Проект',
    ticket: 'Тикет',
    dashboard: 'Панель управления',

    // file extension
    jpg: 'jpg',
    png: 'png',
    pdf: 'pdf',
    doc: 'doc',
    docx: 'docx',
    xls: 'xls',
    xlsx: 'xlsx',
    ppt: 'ppt',
    pptx: 'pptx',
    txt: 'txt',
    mp4: 'mp4',
    zip: 'zip',

    // payment status
    unPaid: 'Не оплачено',
    partiallyPaid: 'Частично оплачено',
    totallyPaid: 'Полностью оплачено',
    
  },
  organization: 'Организация',
  department: 'Отдел',
  user: 'Пользователь',
  aboutUs: 'О нас',
  termsAndConditions: 'Условия и положения',
  uploadDocuments: 'Загрузить документы',
  privacyPolicy: 'Политика конфиденциальности',
  cookiesPolicy: 'Политика использования файлов cookie',
  resources: 'Ресурсы',
  allRightsReserved: 'Все права защищены',
  rowsPerPage: 'Строк на странице',
  chartUpdateSuccess: 'График успешно обновлен!',
  imageUploadSuccess: 'Изображение успешно загружено!',
  uploadPicture: 'Загрузить изображение',
  expiresIn: 'Истекает через ',
  expiresDays: ' дней',
  subscriptionExpired: 'Ваш план истек!',
  noOptionsText: 'Нет вариантов',
  noCategory: 'Без категории',

  ////////////////////////////////////////////////////////////////
  // Validations
  ////////////////////////////////////////////////////////////////

  isRequired: 'Это поле обязательно',
  minValue0: 'Минимальное значение 0',
  fullNameIsRequired: 'Полное имя обязательно',
  emailIsRequired: 'Email обязателен',
  emailIsInvalid: 'Email недействителен',
  phoneIsInvalid: 'Телефон недействителен',
  nameIsRequired: 'Имя обязательно',
  titleIsRequired: 'Заголовок обязателен',
  profileImageIsRequired: 'Изображение профиля обязательно',
  descriptionIsRequired: 'Описание обязательно',
  subjectIsRequired: 'Тема обязательна',
  phoneNumberIsRequired: 'Номер телефона обязателен',
  quotaYupIsRequired: 'Квота обязательна',
  dateYupIsRequired: 'Дата обязательна',
  statusYupIsRequired: 'Статус обязателен',
  locationYupIsRequired: 'Местоположение обязательно',
  detailsIsRequired: 'Детали обязательны',
  surnameIsRequired: 'Фамилия обязательна',
  birthDateIsRequired: 'Дата рождения обязательна',
  genderIsRequired: 'Пол обязателен',
  countryIsRequired: 'Страна обязательна',
  cityIsRequired: 'Город обязателен',
  addressIsRequired: 'Адрес обязателен',
  contentIsRequired: 'Содержание обязательно',
  readingTimeIsRequired: 'Время чтения обязательно',
  tagsIsRequired: 'Теги обязательны',
  referencesIsRequired: 'Ссылки обязательны',
  relatedVideoIsRequired: 'Связанное видео обязательно',
  authorIsRequired: 'Автор обязателен',
  categoryIsRequired: 'Категория обязательна',
  targetCategoryIsRequired: 'Целевая категория обязательна',
  languageIsRequired: 'Язык обязателен',
  domainIsRequired: 'Домен обязателен',
  targetIsRequired: 'Цель обязательна',
  questionIsRequired: 'Вопрос обязателен',
  answerIsRequired: 'Ответ обязателен',
  titleIsTooShort25: 'Заголовок слишком короткий, минимальная длина 25',
  titleIsTooLong50: 'Заголовок слишком длинный, максимальная длина 50',
  contentIsTooShort50: 'Содержание слишком короткое, минимальная длина 50',
  contentIsTooLong250: 'Содержание слишком длинное, максимальная длина 250',
  descriptionIsTooShort400: 'Описание слишком короткое, минимальная длина 400',
  descriptionIsTooLong4000: 'Описание слишком длинное, максимальная длина 4000',
  quotaMustBePositive: 'Квота должна быть положительной',
  quotaMustBeMultipleOf10: 'Квота должна быть кратной 10',
  participantsIsRequired: 'Участники обязательны',
  startDateIsRequired: 'Дата начала обязательна',
  durationIsRequired: 'Продолжительность обязательна',
  quotaIsRequired: 'Квота обязательна',
  lastApplicationDateIsRequired: 'Дата последней заявки обязательна',
  statusIsRequired: 'Статус обязателен',
  locationIsRequired: 'Местоположение обязательно',
  typeIsRequired: 'Тип обязателен',
  participationLinkIsRequired: 'Ссылка на участие обязательна',
  durationMustBeMultipleOf5: 'Продолжительность должна быть кратной 5',
  durationMustBePositive: 'Продолжительность должна быть положительной',
  nameIsTooShort2: 'Имя слишком короткое, минимальная длина 2',
  nameIsTooLong50: 'Имя слишком длинное, максимальная длина 50',
  surnameIsTooShort2: 'Фамилия слишком короткая, минимальная длина 2',
  surnameIsTooLong50: 'Фамилия слишком длинная, максимальная длина 50',
  stateIsRequired: 'Штат обязателен',
  positionIsRequired: 'Должность обязательна',
  companyIsRequired: 'Компания обязательна',
  sectorIsRequired: 'Сектор обязателен',
  qualificationIsRequired: 'Квалификация обязательна',
  prospectNameIsRequired: 'Имя потенциального клиента обязательно',
  prospectPhoneNumberIsRequired: 'Номер телефона потенциального клиента обязателен',
  prospectContactFirstNameIsRequired: 'Имя контакта потенциального клиента обязательно',
  prospectContactLastNameIsRequired: 'Фамилия контакта потенциального клиента обязательна',
  prospectContactEmailIsRequired: 'Email контакта потенциального клиента обязателен',
  websiteIsInvalid: 'Веб-сайт недействителен',
  identityNumberIsRequired: 'Идентификационный номер обязателен',
  taxOfficeIsRequired: 'Налоговая инспекция обязательна',
  streetIsRequired: 'Улица обязательна',
  zipIsRequired: 'Почтовый индекс обязателен',
  passwordIsRequired: 'Пароль обязателен',
  passwordIsInvalid: 'Должен содержать не менее 8 символов, включать заглавные, строчные буквы, цифры и символы.',
  passwordAgainIsInvalid: 'Пароли должны совпадать',
  cannotBeGreaterThanPrice: 'Не может быть больше цены',
  endTimeMustBeLaterThanStartTime: 'Должно быть позже времени начала',
  mustBeNumber: 'Должно быть числом',

  cardValidation: {
    required: 'Это поле обязательно',
    invalid: 'Недействительно',
    expired: 'Истекло',
  },

  ////////////////////////////////////////////////////////////////
  // Buttons
  ////////////////////////////////////////////////////////////////

  cancel: 'Отмена',
  save: 'Сохранить',
  update: 'Обновить',
  delete: 'Удалить',
  create: 'Создать',
  add: 'Добавить',
  close: 'Закрыть',
  submit: 'Отправить',
  addDocument: 'Добавить документ',
  upload: 'Загрузить',
  view: 'Просмотр',
  edit: 'Редактировать',
  remove: 'Удалить',
  myPlan: 'Мой план',
  expandAll: 'Развернуть все',
  collapseAll: 'Свернуть все',
  show: 'Показать',
  download: 'Скачать',
  yes: 'Да',
  no: 'Нет',
  completed: 'Завершено',
  uploadDocument: 'Загрузить документ',
  newTask: 'Новая задача',
  loadMore: 'Загрузить еще',

  ////////////////////////////////////////////////////////////////
  // Tables
  ////////////////////////////////////////////////////////////////

  actions: 'Действия',
  title: 'Заголовок',
  content: 'Содержание',
  category: 'Категория',
  target: 'Цель',
  status: 'Статус',
  date: 'Дата',
  location: 'Местоположение',
  details: 'Детали',
  name: 'Имя',
  surname: 'Фамилия',
  birthDate: 'Дата рождения',
  fullName: 'Полное имя',
  email: 'Email',
  subject: 'Тема',
  createdAt: 'Создано',
  userType: 'Тип пользователя',
  page: 'Страница',
  news: 'Новости',
  offer: 'Предложение',
  products: 'Продукты',
  updatedAt: 'Обновлено',
  readingTime: 'Время чтения',
  tags: 'Теги',
  references: 'Ссылки',
  version: 'Версия',
  domain: 'Домен',
  type: 'Тип',
  language: 'Язык',
  duration: 'Продолжительность',
  startDate: 'Дата начала',
  participants: 'Участники',
  quota: 'Квота',
  question: 'Вопрос',
  answer: 'Ответ',
  lastApplicationDate: 'Дата последней заявки',
  selectAnOption: 'Выберите вариант',
  showing: 'Показано с {{start}} по {{end}} из {{total}} записей',

  ////////////////////////////////////////////////////////////////
  // Menus
  ////////////////////////////////////////////////////////////////
  dashboard: 'Панель управления',
  blog: 'Блог',
  blogs: 'Блоги',
  categories: 'Категории',
  authors: 'Авторы',
  faq: 'FAQ',
  faqs: 'FAQs',
  faqCategory: 'Категории FAQ',
  form: 'Форма',
  contactForm: 'Контактные формы',
  subscriptionForm: 'Формы подписки',
  blogTargetCategories: 'Целевые категории',
  users: 'Пользователи',
  departments: 'Отделы',
  roles: 'Роли',
  permissions: 'Разрешения',
  leadStatuses: 'Статусы лидов',
  leadSources: 'Источники лидов',
  leadForms: 'Формы лидов',
  customers: 'Клиенты',
  contacts: 'Контакты',
  customerCategories: 'Категории клиентов',
  estimates: 'Оценки',
  proposals: 'Предложения',
  invoices: 'Счета',
  payments: 'Платежи',
  contracts: 'Контракты',
  returns: 'Возвраты',
  services: 'Услуги',
  reminders: 'Напоминания',
  appointments: 'Встречи',
  starred: 'Избранное',
  recent: 'Недавние',
  actionMenu: 'Меню действий',
  themeMenu: 'Меню тем',
  notifications: 'Уведомления',
  languageMenu: 'Меню языков',

  ////////////////////////////////////////////////////////////////
  // Permissions
  ////////////////////////////////////////////////////////////////

  organizationLevel: 'Уровень организации',
  organizationLevelDesc: 'Уровень организации, в которой находится пользователь.',
  departmentLevel: 'Уровень отдела',
  departmentLevelDesc: 'Уровень отдела, в котором находится пользователь.',
  userLevel: 'Уровень пользователя',
  userLevelDesc: 'Уровень пользователя, на котором находится пользователь.',

  ////////////////////////////////////////////////////////////////
  // Abbreviations
  ////////////////////////////////////////////////////////////////
  min: 'мин',

  ////////////////////////////////////////////////////////////////
  // Inputs
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // Days
  ////////////////////////////////////////////////////////////////
  sunday: 'Воскресенье',
  monday: 'Понедельник',
  tuesday: 'Вторник',
  wednesday: 'Среда',
  thursday: 'Четверг',
  friday: 'Пятница',
  saturday: 'Суббота',
  today: 'Сегодня',
};

export default locale; 
const locale = {
  appointments: 'Termine',
  appointment: 'Termin',
  appointmentConversions: 'Terminkonvertierungen',
  title: 'Termine',
  create: 'Termin erstellen',
  update: 'Termin aktualisieren',
  delete: 'Termin löschen',
  details: 'Termindetails',
  createSuccess: 'Termin erfolgreich erstellt.',
  updateSuccess: 'Termin erfolgreich aktualisiert.',
  emptyState1: 'Noch keine Termine geplant.',
  emptyState2:
    'Richten Sie hier Ihre Termine ein und verwalten Sie sie, um organisiert zu bleiben und nahtlos mit Ihren Kunden in Kontakt zu treten.',
  statusChangeSuccess: 'Terminstatus erfolgreich geändert.',
  deleteConfirm: 'Sind Sie sicher, dass Sie diesen Termin löschen möchten?',
  deletedSuccessfully: 'Termin erfolgreich gelöscht.',

  //fields
  startTime: 'Startzeit',
  endTime: 'Endzeit',
  timezone: 'Zeitzone',
  location: 'Ort',
  organizer: 'Organisator',
  targetType: 'Zieltyp',
  attendees: 'Teilnehmer',
  category: 'Kategorie',
  meetingLink: 'Meeting-Link',
  duration: 'Dauer',

  //detail drawer
  changeStatus: 'Status ändern',
  generalInfoTitle: 'Allgemeine Informationen',
  organizerInfoTitle: 'Organisator-Informationen',
  appointmentInfoTitle: 'Termininformationen',
};

export default locale; 
/* eslint-disable no-constant-binary-expression */
/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Box,
  Stack,
  Collapse,
  TextField,
  InputAdornment,
  Typography,
  useMediaQuery,
  Theme,
  Tooltip,
  Avatar,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  ClickAwayListener,
  Grid,
  InputLabel,
  Button,
  Badge,
} from '@mui/material';
import LogoMini from 'assets/images/co-black.svg';
import LogoMiniWhite from 'assets/images/co-white.svg';
import { Iconify } from 'components';
import { useState, forwardRef, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, TreeItemProps } from '@mui/x-tree-view/TreeItem';
import { useSpring, animated } from '@react-spring/web';
import { styled } from '@mui/system';
import { Settings, Logout, Lock } from '@mui/icons-material';
import { commonApi, useAppDispatch, useAppSelector } from 'context';
import { removeUser } from 'apps/auth';
import { setRoute, setThemeMode } from 'context/slices';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { changeLanguage, getCurrentLanguage } from 'localization';
import { IBaseError, IGraphqlVariables, Language } from 'corede-common';
import { useTranslation } from 'react-i18next';
import { IOrganizationCreateOwnInput, OrganizationStatus } from 'corede-common-cocrm';
import { useFormik } from 'formik';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import {
  useLogoutMutation,
  useOrganizationCreateOwnMutation,
  useUserDetailOwnQuery,
} from 'apps/auth/context';
import { validateCreateOrganizationOwnInput } from 'apps/crm/validations/createOrganizationOwn.validation';
import { enqueueSnackbar } from 'notistack';
import { useOrganizationChartUpdateMutation } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import ActionDialog from 'components/dialog/ActionDialog';
import { baseLeadPlusRoute } from 'apps/leadPlus/domains/demo/routes/demo.base.route';
import { baseMarketingRoute } from 'apps/marketing/domains/demo/routes/demo.base.route';
import { baseWindowRoute } from 'apps/window/domains/demo/routes/demo.base.route';
import { baseCommunityRoute } from 'apps/community/domains/demo/routes/demo.base.route';
import { baseDiskRoute } from 'apps/disk/domains/document/routes/document.base.route';
import { baseSettingsRoute } from 'apps/settings/domains/settings/routes/demo.base.route';
import { baseAccountRoute } from 'apps/account/domains/profile/routes/profile.base.route';
import { baseOrganizationRoute } from 'apps/crm/domains/01-dashboard/routes/dashboard.base.route';
import NotificationDrawerWrapper from 'apps/webNotification/pages/NotificationDrawerWrapper';
import { useLazyWebNotificationUnreadCountOwnQuery } from 'apps/webNotification';
import moment from 'moment';
import {
  useLazyOrganizationDetailOwnQuery,
  useOrganizationDetailOwnQuery,
} from 'apps/settings/domains/settings/subdomains/settings';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { SearchMainComponent } from 'components/search/SearchMainComponent';
import { EntityIcons } from 'icons/entity-icons';
import ActionMenu from './ActionMenu';
import MemoryUsage from 'utils/memoryUsage';

declare module '@mui/material/styles' {
  interface TypeBackground {
    firstWithBlur?: string;
    secondWithBlur?: string;
    thirdWithBlur?: string;
  }
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '25px',
    width: '300px',
    height: '45px',
    backgroundColor: theme.palette.background.secondary,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    padding: '10px 10px',
    fontWeight: 600,
    fontSize: '14px',
  },
}));

function TransitionComponent(props: Readonly<TransitionProps>) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const CustomTreeItem = forwardRef((props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
  <TreeItem
    {...props}
    slots={{
      groupTransition: TransitionComponent,
      ...props.slots,
    }}
    ref={ref}
  />
));

CustomTreeItem.displayName = 'CustomTreeItem';

const MainLayout = ({
  children,
  treeItems,
  expandedMenu,
}: {
  children: React.ReactNode;
  treeItems: TreeItemProps[];
  expandedMenu?: string[];
}) => {
  const { data, isLoading, refetch } = useUserDetailOwnQuery({});
  const { route } = useAppSelector((state) => state.route);
  const { refreshToken } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [isTreeVisible, setTreeVisible] = useState(!downMd);
  const [treeMenuOpacity, setTreeMenuOpacity] = useState(1);
  const [anchorElAccountMenu, setAnchorElAccountMenu] = useState<null | HTMLElement>(null);
  const openAccountMenu = Boolean(anchorElAccountMenu);
  const [anchorElThemeMenu, setAnchorElThemeMenu] = useState<null | HTMLElement>(null);
  const openThemeMenu = Boolean(anchorElThemeMenu);
  const [anchorElLanguageMenu, setAnchorElLanguageMenu] = useState<null | HTMLElement>(null);
  const openLanguageMenu = Boolean(anchorElLanguageMenu);
  const [anchorElActionMenu, setAnchorElActionMenu] = useState<null | HTMLElement>(null);
  const openActionMenu = Boolean(anchorElActionMenu);
  const [language, setLanguage] = useState<Language>(currentLanguage);
  const [isDarkMode, setDarkMode] = useState(false);
  const [treeMenuDisplay, setTreeMenuDisplay] = useState('block');
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [totalMemory, setTotalMemory] = useState<number>(0);
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const [
    organizationCreateOwn,
    {
      data: organizationCreateOwnData,
      isLoading: organizationCreateOwnLoading,
      error: organizationCreateOwnError,
    },
  ] = useOrganizationCreateOwnMutation();
  const { data: organizationDetailOwnData } = useOrganizationDetailOwnQuery(
    {},
    {
      skip: !userDetailOwnData?.organization,
    },
  );
  const [logout] = useLogoutMutation();
  const [organizationChartUpdate, { isLoading: organizationChartUpdateLoading }] =
    useOrganizationChartUpdateMutation();
  const handleClickAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccountMenu(event.currentTarget);
  };
  const handleClickThemeMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElThemeMenu(event.currentTarget);
  };
  const handleClickLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLanguageMenu(event.currentTarget);
  };
  const handleClickActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElActionMenu(event.currentTarget);
  };
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
  // const normalizePath = (path: string) => {
  //     return path.includes("/settings/") ? path :
  //         path === "/" ? "/dashboard/" :
  //             path;
  // };

  const normalizePath = (path: string) => {
    if (path.includes('/settings/')) {
      return path;
    }

    if (path === '/') {
      return '/dashboard/';
    }

    return path;
  };

  const [selectedItems, setSelectedItems] = useState(normalizePath(path));
  const [expandedItems] = useState([
    '/' + (path === '/' ? 'dashboard/' : path.split('/')[1] + '/'),
  ]);
  const [getOrganizationDetailOwn, { data: organizationDetailOwn }] =
    useLazyOrganizationDetailOwnQuery();
  const [getWebNotificationUnreadCountOwn, { data: countResult }] =
    useLazyWebNotificationUnreadCountOwnQuery();

  const status = organizationDetailOwn?.status;

  const treeMenuIcons = {
    '/dashboard/': EntityIcons.dashboard,
    '/organizational-chart/': EntityIcons.organizationalChart,
    '/lead/': EntityIcons.lead,
    '/customer/': EntityIcons.customer,
    '/preSale/': EntityIcons.preSale,
    '/sales/': EntityIcons.sales,
    '/contract/': EntityIcons.contract,
    '/productsAndServices/': EntityIcons.productsAndServices,
    '/expenses/': EntityIcons.expenses,
    '/projects/': EntityIcons.projects,
    '/tasks/': EntityIcons.tasks,
    '/calendar/': EntityIcons.calendar,
    '/reports/': EntityIcons.reports,
    '/notes/': EntityIcons.notes,
    '/ticket/': EntityIcons.ticket,
  };

  const handleCloseAccountMenu = () => {
    setAnchorElAccountMenu(null);
  };

  const handleCloseThemeMenu = () => {
    setAnchorElThemeMenu(null);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorElLanguageMenu(null);
  };

  const handleCloseActionMenu = () => {
    setAnchorElActionMenu(null);
    dispatch(setRoute('crm'));
  };

  const toggleDarkMode = (checked: boolean) => {
    setDarkMode(checked);
    dispatch(setThemeMode(checked ? 'dark' : 'light'));
  };

  const handleLogout = () => {
    logout({
      input: { refreshToken: refreshToken },
    })
      .then(() => {
        dispatch(removeUser());
        dispatch(commonApi.util.resetApiState());
        window.location.href = '/';
      })
      .catch(() => {
        dispatch(removeUser());
        dispatch(commonApi.util.resetApiState());
        window.location.href = '/';
      });
  };

  const customNavigate = (route: string) => {
    if (totalMemory > 1000) {
      window.location.href = route;
    } else {
      navigate(route);
    }
  };

  const handleButtonClick = (param: string) => {
    if (param !== 'close' && param !== 'clickaway') {
      dispatch(setRoute(param));
    }
    if (
      param !== 'close' &&
      param !== 'clickaway' &&
      param === 'crm' &&
      (path.includes('leadPlus') ||
        path.includes('marketing') ||
        path.includes('window') ||
        path.includes('community') ||
        path.includes('disk') ||
        path.includes('settings') ||
        path.includes('account'))
    ) {
      customNavigate(baseOrganizationRoute());
    }
    if (param !== 'close' && param !== 'clickaway' && param === 'leadPlus') {
      customNavigate(baseLeadPlusRoute());
    }
    if (param !== 'close' && param !== 'clickaway' && param === 'marketing') {
      customNavigate(baseMarketingRoute());
    }
    if (param !== 'close' && param !== 'clickaway' && param === 'window') {
      customNavigate(baseWindowRoute());
    }
    if (param !== 'close' && param !== 'clickaway' && param === 'community') {
      customNavigate(baseCommunityRoute());
    }
    if (param !== 'close' && param !== 'clickaway' && param === 'disk') {
      customNavigate(baseDiskRoute());
    }
    if (param !== 'close' && param !== 'clickaway' && param === 'settings') {
      customNavigate(baseSettingsRoute());
    }
    if (param !== 'close' && param !== 'clickaway' && param === 'account') {
      customNavigate(baseAccountRoute());
    }

    if (param === route && isTreeVisible) {
      setTreeVisible(false);
      setTreeMenuOpacity(-1);
    } else {
      setTreeMenuOpacity(1);
      setTreeVisible(true);
    }
    if (param === 'close') {
      setTreeVisible(false);
      setTreeMenuOpacity(-1);
    }
    if (param === 'open') {
      setTreeVisible(true);
      setTreeMenuOpacity(1);
    }
    if (param === 'clickaway') {
      setTreeVisible(false);
      setTreeMenuOpacity(-1);
    }
  };

  const headerMenuSlotProps = {
    paper: {
      elevation: 0,
      sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        borderRadius: '6px',
        mt: '6px',
        px: 1,
        minWidth: '200px',
        '& .MuiAvatar-root': {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
      },
    },
  };

  const { values, handleSubmit, handleBlur, setFieldValue, errors, touched } = useFormik({
    initialValues: {
      input: {
        name: '',
      },
    },
    enableReinitialize: true,
    validationSchema: validateCreateOrganizationOwnInput,
    onSubmit: async (values: IGraphqlVariables<IOrganizationCreateOwnInput>) => {
      const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
        input: values,
      });
      await organizationCreateOwn(
        transformedValues as IGraphqlVariables<IOrganizationCreateOwnInput>,
      );
    },
  });

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme) {
      dispatch(setThemeMode(theme as 'light' | 'dark'));
      setDarkMode(theme === 'dark');
    }
  }, [dispatch]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (treeMenuOpacity === -1) {
      timeout = setTimeout(() => {
        setTreeMenuDisplay('none');
      }, 15000);
    } else {
      setTreeMenuDisplay('block');
    }

    return () => clearTimeout(timeout);
  }, [treeMenuOpacity]);

  useEffect(() => {
    if (data && data?.type !== 'cocrm') {
      setOpenBackdrop(true);
    }
    if (data?.organization && !isLoading) {
      setOpenBackdrop(false);
      getOrganizationDetailOwn({});
      getWebNotificationUnreadCountOwn({});
    }
  }, [data]);

  useEffect(() => {
    if (
      organizationCreateOwnData &&
      organizationDetailOwnData?.status === OrganizationStatus.active
    ) {
      organizationChartUpdate({
        input: {
          chart: {
            user: {
              _id: data?._id as string,
            },
            children: [],
          },
        },
      }).then(() => {
        refetch();
        setOpenBackdrop(false);
        enqueueSnackbar(t('crm.mustCreateOrganizationSuccess'), {
          variant: 'success',
        });
      });
    }
  }, [organizationCreateOwnData, organizationDetailOwnData]);

  useEffect(() => {
    const normalizedPath = normalizePath(path);
    if (normalizedPath !== selectedItems) {
      setSelectedItems(normalizedPath);
    }
  }, [path]);

  // useEffect(() => {
  //   if (accessToken) {
  //     const interval = setInterval(() => {
  //       getWebNotificationUnreadCountOwn({});
  //     }, 15000);

  //     return () => clearInterval(interval);
  //   }
  // }, [accessToken]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(organizationCreateOwnError as IBaseError, currentLanguage);
  }, [organizationCreateOwnError]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        bgcolor: 'background.default',
        // background:
        //     !isDarkMode
        //         ? 'linear-gradient( 320deg, hsl(219deg 55% 69%) 0%, hsl(219deg 54% 70%) 1%, hsl(219deg 53% 71%) 1%, hsl(220deg 51% 72%) 2%, hsl(220deg 50% 73%) 3%, hsl(220deg 49% 74%) 3%, hsl(220deg 47% 75%) 4%, hsl(220deg 46% 76%) 5%, hsl(221deg 44% 77%) 6%, hsl(221deg 43% 78%) 8%, hsl(221deg 41% 79%) 9%, hsl(221deg 39% 79%) 11%, hsl(221deg 37% 80%) 13%, hsl(221deg 35% 81%) 15%, hsl(221deg 33% 82%) 17%, hsl(221deg 31% 83%) 20%, hsl(222deg 29% 84%) 23%, hsl(222deg 26% 84%) 27%, hsl(222deg 23% 85%) 31%, hsl(222deg 20% 86%) 36%, hsl(222deg 17% 87%) 42%, hsl(222deg 13% 87%) 50%, hsl(222deg 9% 88%) 60%, hsl(222deg 5% 89%) 75%, hsl(0deg 0% 89%) 100% )'
        //         : 'linear-gradient(to top, #2b2f3a, #353946, #3f4353, #494d60, #53576d, #5a5d73, #616479, #696b7f, #707185, #77778b, #7e7d91, #858498)'
      }}
    >
      <ActionDialog
        open={openBackdrop}
        onClose={() => {
          handleLogout();
          setOpenBackdrop(false);
        }}
        title={t('crm.mustCreateOrganizationTitle')}
        buttonColor="secondary"
        buttonTitle={t('crm.create')}
        handleClick={() => handleSubmit()}
        disabled={organizationChartUpdateLoading || organizationCreateOwnLoading}
        loading={organizationChartUpdateLoading || organizationCreateOwnLoading}
        buttonCancelTitle={t('crm.logout')}
      >
        <Grid container spacing={2} sx={{ px: { xs: 1, lg: 5 } }}>
          <Grid item xs={12} sx={{ marginTop: 0, paddingTop: 0 }}>
            <Typography>{t('crm.mustCreateOrganizationContent')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="stretch" sx={{ cursor: 'pointer' }}>
              {/* <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.companyLogo")}*</InputLabel>
                                    <Box sx={{ width: 'calc(100% - 12px)', border: '1px dashed', borderColor: "primary.light", borderRadius: '16px', p: 1, flexGrow: 1 }}>
                                        <Stack direction="column" justifyContent="center" alignItems="center" gap={1} sx={{ width: '100%', height: '100%' }}>
                                            <Avatar
                                                sx={{ width: 50, height: 50, padding: 2, backgroundColor: "background.default" }}
                                                src={"https://cdn-icons-png.flaticon.com/128/9261/9261196.png"}
                                            />
                                            <Typography variant="body2"> {t("crm.uploadLogo")}</Typography>
                                        </Stack>
                                    </Box>
                                </Grid> */}
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={12}>
                    <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                      {t('crm.companyName')}*
                    </InputLabel>
                    <TextField
                      fullWidth
                      name="input.name"
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      value={(values as any).input.name}
                      onChange={(e) =>
                        setFieldValue(
                          'input.name',
                          e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                        )
                      }
                      onBlur={handleBlur}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.companyEmail")}*</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="input.name"
                                                value={(values as any).input.name}
                                                onChange={(e) => setFieldValue("input.email", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                                onBlur={handleBlur}
                                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                                            />
                                        </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.companyPhone")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.country")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.city")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.industry")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.timezone")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.language")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid> */}
        </Grid>
      </ActionDialog>

      <Stack direction={'row'} sx={{ width: '100%' }}>
        {/* Drawer */}
        <ClickAwayListener
          onClickAway={() => {
            if (
              treeMenuDisplay === 'block' &&
              downMd &&
              (route === 'crm' ||
                route === 'leadPlus' ||
                route === 'community' ||
                route === 'marketing' ||
                route === 'window')
            )
              handleButtonClick('clickaway');
          }}
        >
          <Stack
            direction="row"
            id={'main-menu'}
            sx={{
              height: downMd ? 'calc(100vh - 138px)' : '100vh',
              position: 'fixed',
              zIndex: 10,
              left: 0,
              top: 0,
              width: isTreeVisible ? (downMd ? '100%' : '360px') : downMd ? 0 : '76px',
              marginTop: downMd ? '138px' : 0,
              backgroundColor: '#ffffff',
              transition: 'width .3s ease-in',
            }}
          >
            {/* Route Menu */}
            <Stack
              direction="column"
              id={'route-menu'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={{ xs: 1, lg: 3 }}
              zIndex={11}
              sx={{
                width: '76px',
                p: { xs: '16px', lg: '20px' },
                boxShadow: isTreeVisible ? 'none' : '2px 0px 8px 0px rgba(30, 30, 30, 0.1)',
                transform: downMd
                  ? isTreeVisible
                    ? 'translateX(0px)'
                    : 'translateX(-432px)'
                  : 'translateX(0px)',
                transition: 'transform .3s ease-in',
                zIndex: 10,
                backgroundColor: 'background.default',
                border: '1px solid',
                borderColor: 'divider',
              }}
            >
              <Stack
                direction="column"
                justifyContent={'flex-start'}
                alignItems={'center'}
                gap={{ xs: 1, lg: 2 }}
                my={{ xs: '5px', lg: '10px' }}
                ml={{ xs: '12px', lg: 0 }}
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    customNavigate('/');
                    dispatch(setRoute('crm'));
                  }}
                >
                  <img
                    src={!isDarkMode ? LogoMini : LogoMiniWhite}
                    alt="logo"
                    style={{
                      width: downMd ? '36px' : '45px',
                      height: downMd ? '36px' : '20px',
                      marginBottom: downMd ? '5px' : '10px',
                    }}
                  />
                </Box>
                <Tooltip title="CRM">
                  <Box
                    sx={{
                      width: '44px',
                      height: '44px',
                      borderRadius: '23px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      bgcolor: route === 'crm' ? 'secondary.main' : 'background.thirdWithBlur',
                      border: '1px solid rgba(233, 233, 233, 1)',
                    }}
                    onClick={() => handleButtonClick('crm')}
                  >
                    <Iconify
                      icon={'carbon:gui-management'}
                      width={24}
                      sx={{
                        color: route === 'crm' ? 'text.white' : 'text.primary',
                      }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title="Lead +">
                  <Box
                    sx={{
                      width: '44px',
                      height: '44px',
                      borderRadius: '23px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      bgcolor: route === 'leadPlus' ? 'secondary.main' : 'background.thirdWithBlur',
                      border: '1px solid rgba(233, 233, 233, 1)',
                    }}
                    onClick={() => handleButtonClick('leadPlus')}
                  >
                    <Iconify
                      icon={'ph:circles-three-plus'}
                      width={24}
                      sx={{
                        color: route === 'leadPlus' ? 'text.white' : 'text.primary',
                      }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title="Marketing">
                  <Box
                    sx={{
                      width: '44px',
                      height: '44px',
                      borderRadius: '23px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      bgcolor:
                        route === 'marketing' ? 'secondary.main' : 'background.thirdWithBlur',
                      border: '1px solid rgba(233, 233, 233, 1)',
                    }}
                    onClick={() => handleButtonClick('marketing')}
                  >
                    <Iconify
                      icon={'nimbus:marketing'}
                      width={24}
                      sx={{
                        color: route === 'marketing' ? 'text.white' : 'text.primary',
                      }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title="Window">
                  <Box
                    sx={{
                      width: '44px',
                      height: '44px',
                      borderRadius: '23px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      bgcolor: route === 'window' ? 'secondary.main' : 'background.thirdWithBlur',
                      border: '1px solid rgba(233, 233, 233, 1)',
                    }}
                    onClick={() => handleButtonClick('window')}
                  >
                    <Iconify
                      icon={'ph:grid-four-light'}
                      width={24}
                      sx={{
                        color: route === 'window' ? 'text.white' : 'text.primary',
                      }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title="Community">
                  <Box
                    sx={{
                      width: '44px',
                      height: '44px',
                      borderRadius: '23px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      bgcolor:
                        route === 'community' ? 'secondary.main' : 'background.thirdWithBlur',
                      border: '1px solid rgba(233, 233, 233, 1)',
                    }}
                    onClick={() => handleButtonClick('community')}
                  >
                    <Iconify
                      icon={'fluent:people-community-28-regular'}
                      width={24}
                      sx={{
                        color: route === 'community' ? 'text.white' : 'text.primary',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Stack>
              <Stack
                direction="column"
                justifyContent={'flex-start'}
                alignItems={'center'}
                gap={{ xs: 1, lg: 3 }}
                mb={{ xs: '5px', lg: '0px' }}
                ml={{ xs: '12px', lg: 0 }}
              >
                <Tooltip title={status !== 'active' ? 'Disk (locked)' : 'Disk'}>
                  <Box
                    onClick={() => status === 'active' && handleButtonClick('disk')}
                    sx={{
                      width: '44px',
                      height: '44px',
                      borderRadius: '23px',
                      bgcolor: route === 'disk' ? 'secondary.main' : 'background.thirdWithBlur',
                      cursor: 'pointer',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid rgba(233, 233, 233, 1)',
                    }}
                  >
                    <Iconify
                      icon={'clarity:hard-disk-outline-badged'}
                      width={24}
                      sx={{
                        color: route === 'disk' ? 'text.white' : 'text.primary',
                      }}
                    />
                    {status !== 'active' && (
                      <Lock
                        fontSize="small"
                        sx={{
                          position: 'absolute',
                          right: -3,
                          top: -8,
                          color: 'secondary.main',
                        }}
                      />
                    )}
                  </Box>
                </Tooltip>
                <Tooltip title={t('crm.settings')}>
                  <Box
                    onClick={() => {
                      handleButtonClick('settings');
                    }}
                    sx={{
                      cursor: 'pointer',
                      width: '44px',
                      height: '44px',
                      borderRadius: '23px',
                      bgcolor: route === 'settings' ? 'secondary.main' : 'background.thirdWithBlur',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid rgba(233, 233, 233, 1)',
                    }}
                  >
                    <Iconify
                      icon={'gravity-ui:gear'}
                      width={24}
                      sx={{
                        color: route === 'settings' ? 'text.white' : 'text.primary',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Stack>
            </Stack>

            {/* Tree Menu */}
            {treeItems?.length > 0 && (
              <Stack
                direction="column"
                id={'tree-menu'}
                sx={{
                  pb: { xs: 5, lg: 0 },
                  bgcolor: 'background.default',
                  borderLeft: downMd ? '1px solid' : 'none',
                  borderColor: 'divider',
                  height: '100%',
                  boxShadow: downMd ? 'none' : '2px 0px 8px 0px rgba(30, 30, 30, 0.1)',
                  px: { xs: '12px', lg: '12px' },
                  width: '100%',
                  transform: isTreeVisible ? 'translateX(0px)' : 'translateX(-432px)',
                  transition: 'transform .3s ease-in, opacity .3s ease-in .3s',
                  zIndex: 9,
                  opacity: isTreeVisible ? 1 : 0,
                }}
              >
                <Box
                  sx={{
                    mb: { xs: '40px', lg: '30px' },
                    mt: { xs: '20px', lg: '40px' },
                  }}
                >
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    {!downMd && (
                      <Typography fontSize={'16px'} fontWeight={'bold'}>
                        {data?.organization?.name}
                      </Typography>
                    )}
                    {!downMd && (
                      <Iconify
                        icon={'tabler:circle-dot'}
                        width={24}
                        onClick={() => handleButtonClick('close')}
                        sx={{ cursor: 'pointer', color: 'text.primary' }}
                      />
                    )}
                  </Stack>
                </Box>
                {status === 'active' &&
                false &&
                !path.includes('settings') &&
                !path.includes('disk') &&
                !path.includes('account') ? (
                  <Stack
                    direction="row"
                    alignItems={'center'}
                    gap={1}
                    py={0.5}
                    px={2}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Iconify
                      icon="solar:star-line-duotone"
                      width={20}
                      sx={{ color: 'text.secondary' }}
                    />
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'semiBold'}
                      sx={{ color: 'text.secondary' }}
                    >
                      {t('starred')}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack height={'20px'} />
                )}
                {status === 'active' &&
                false &&
                !path.includes('settings') &&
                !path.includes('disk') &&
                !path.includes('account') ? (
                  <Stack
                    direction="row"
                    alignItems={'center'}
                    gap={1}
                    py={0.5}
                    px={2}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Iconify
                      icon="material-symbols:replay-rounded"
                      width={20}
                      sx={{ color: 'text.secondary' }}
                    />
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'semiBold'}
                      sx={{ color: 'text.secondary' }}
                    >
                      {t('recent')}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack height={'20px'} />
                )}

                <Box
                  sx={{
                    minHeight: 352,
                    minWidth: 250,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  <SimpleTreeView
                    selectedItems={selectedItems}
                    defaultExpandedItems={expandedMenu ?? expandedItems}
                  >
                    {treeItems?.map((item: TreeItemProps) => (
                      <CustomTreeItem
                        key={item.itemId}
                        itemId={item.itemId}
                        label={
                          <Link
                            to={(item as TreeItemProps & { href?: string }).href ?? '#'}
                            style={{
                              textDecoration: 'none',
                              color: 'inherit',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                            onClick={() => {
                              if (downMd) {
                                handleButtonClick('close');
                              }
                              if (totalMemory > 1000) {
                                window.location.href =
                                  (item as TreeItemProps & { href?: string }).href ?? '';
                              }
                            }}
                          >
                            <Iconify
                              icon={
                                treeMenuIcons[item.itemId as keyof typeof treeMenuIcons] ||
                                'tabler:circle-dot'
                              }
                              width={20}
                            />
                            {item.label}
                          </Link>
                        }
                        sx={{
                          marginBottom: '2px',
                          '& .MuiTreeItem-content': {
                            flexDirection: item.children ? 'row-reverse' : 'row',
                            paddingX: item.children ? '16px' : '8px',
                            color: 'text.default',
                          },
                          '& .MuiTreeItem-content:hover': {
                            backgroundColor: 'secondary.extraLight',
                            color: 'secondary.main',
                          },
                          '& .MuiTreeItem-iconContainer': {
                            width: 0,
                          },
                          '& .MuiTreeItem-content.Mui-selected': {
                            backgroundColor: 'secondary.main',
                            color: 'text.white',
                          },
                          '& .MuiTreeItem-content.Mui-focused': {
                            backgroundColor: 'secondary.main',
                            color: 'text.white',
                          },
                          '& .MuiTreeItem-content.Mui-selected:hover': {
                            backgroundColor: 'secondary.extraLight',
                            color: 'secondary.main',
                          },
                          //  TODO: etkileyen bir yer var mı gözlemle ????
                          '& .MuiTreeItem-content .MuiTreeItem-label': {
                            fontSize: '14px',
                            lineHeight: '22px',
                          },
                          '& .MuiTreeItem-content.Mui-selected .MuiTreeItem-label': {
                            fontWeight: 700,
                          },
                        }}
                      >
                        {Array.isArray(item.children) &&
                          item.children.map((child: TreeItemProps) => (
                            <CustomTreeItem
                              key={child.itemId}
                              itemId={child.itemId}
                              label={
                                <Link
                                  to={(child as TreeItemProps & { href?: string }).href ?? '#'}
                                  style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                  }}
                                  onClick={() => {
                                    if (downMd) {
                                      handleButtonClick('close');
                                    }
                                  }}
                                >
                                  <Iconify icon="tabler:circle" width={12} />
                                  {child.label}
                                </Link>
                              }
                              sx={{
                                '& .MuiTreeItem-iconContainer': {
                                  width: 0,
                                },

                                '&.Mui-selected.Mui-focused': {
                                  backgroundColor: 'red', // İstediğiniz renk
                                },
                              }}
                            />
                          ))}
                      </CustomTreeItem>
                    ))}
                  </SimpleTreeView>
                </Box>

                {organizationDetailOwn?.activeSubscriptions?.trial && (
                  <Stack
                    py={3}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    gap={1}
                    mt={'auto'}
                    sx={{ borderTop: '1px solid', borderColor: 'divider' }}
                  >
                    {moment(organizationDetailOwn?.activeSubscriptions?.trial?.dueDate).isAfter(
                      moment(),
                    ) ? (
                      <Typography fontSize={'14px'} fontWeight={400} color={'secondary.main'}>
                        {t('expiresIn')}
                        {moment(organizationDetailOwn?.activeSubscriptions?.trial?.dueDate).diff(
                          moment(),
                          'days',
                        )}
                        {t('expiresDays')}
                      </Typography>
                    ) : (
                      <Typography fontSize={'14px'} fontWeight={400} color={'secondary.main'}>
                        {t('subscriptionExpired')}
                      </Typography>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        dispatch(setRoute('settings'));
                        customNavigate(baseSettingsRoute() + 'payment/');
                      }}
                      sx={{
                        fontSize: 14,
                        height: 'auto',
                        borderRadius: '40px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('myPlan')}
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </ClickAwayListener>

        {/* Main */}
        <Stack
          direction="column"
          sx={{
            width: '100%',
            paddingX: '20px',
            overflow: 'hidden',
            marginLeft: downMd ? 0 : isTreeVisible ? '368px' : '90px',
            height: downMd && isTreeVisible ? '100vh' : 'auto',
            transition: 'margin-left .3s ease-in',
          }}
        >
          {/* Header*/}
          <Stack
            direction="row"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: '30px',
              padding: '4px 0',
              bgcolor: downMd ? 'transparent' : 'background.secondWithBlur',
              boxShadow: downMd ? 'none' : '0px 4px 30px rgba(0, 0, 0, 0.1)',
              borderBottom: downMd ? 'none' : '1px solid rgba(255, 125, 125, 1)',
              margin: downMd ? '12px 0' : '24px 0',
            }}
          >
            <MemoryUsage setTotalMemory={setTotalMemory} />
            {/* Search Bar */}
            {!downMd && (
              <Stack direction={'row'} gap={1} alignItems={'center'}>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  {!isTreeVisible && (
                    <Iconify
                      icon={'si:sort-line'}
                      width={24}
                      onClick={() => handleButtonClick('open')}
                      sx={{ cursor: 'pointer', color: 'text.primary', ml: 2 }}
                    />
                  )}
                  <SearchMainComponent />
                </Stack>
              </Stack>
            )}

            {downMd && (
              <Stack
                direction={'row'}
                alignItems={'center'}
                ml={downMd ? 0 : 1}
                gap={1}
                position={'relative'}
              >
                {downMd && (
                  <Iconify
                    icon={'si:sort-line'}
                    width={24}
                    onClick={() => handleButtonClick('open')}
                    sx={{
                      cursor: 'pointer',
                      color: isTreeVisible ? 'secondary.main' : 'text.primary',
                    }}
                  />
                )}
                {/* <Typography fontSize={"12px"} fontWeight={"bold"}>
                  My Company
                </Typography> */}
                {/* <Iconify icon={"gravity-ui:layout-split-columns"} width={12} sx={{ bgcolor: '#fff', p: "4px", position: 'absolute', bottom:-30, borderRadius: '6px' }} /> */}
              </Stack>
            )}

            {/* Action Buttons */}
            <Stack direction={'row'} pr={4} sx={{ alignItems: 'center' }}>
              <Tooltip title={t('language')}>
                <Box
                  p={1}
                  sx={{ display: 'flex', alignItems: 'center' }}
                  onClick={handleClickLanguageMenu}
                >
                  <Iconify
                    icon={'flowbite:language-outline'}
                    width={24}
                    sx={{ cursor: 'pointer', color: 'text.primary' }}
                  />
                </Box>
              </Tooltip>

              <Menu
                anchorEl={anchorElLanguageMenu}
                id={t('crm.languageSelectLabel')}
                open={openLanguageMenu}
                onClose={handleCloseLanguageMenu}
                slotProps={headerMenuSlotProps}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                disableAutoFocusItem={true}
              >
                <MenuItem
                  selected={language === 'en'}
                  data-value={'en'}
                  onClick={(e) => {
                    setLanguage(e.currentTarget.dataset.value as Language);
                    changeLanguage(e.currentTarget.dataset.value as Language);
                  }}
                >
                  {t('crm.english')}
                </MenuItem>
                <MenuItem
                  selected={language === 'tr'}
                  data-value={'tr'}
                  onClick={(e) => {
                    setLanguage(e.currentTarget.dataset.value as Language);
                    changeLanguage(e.currentTarget.dataset.value as Language);
                  }}
                >
                  {t('crm.turkish')}
                </MenuItem>
                <MenuItem
                  selected={language === 'fr'}
                  data-value={'fr'}
                  onClick={(e) => {
                    setLanguage(e.currentTarget.dataset.value as Language);
                    changeLanguage(e.currentTarget.dataset.value as Language);
                  }}
                >
                  {t('crm.french')}
                </MenuItem>
                <MenuItem
                  selected={language === 'de'}
                  data-value={'de'}
                  onClick={(e) => {
                    setLanguage(e.currentTarget.dataset.value as Language);
                    changeLanguage(e.currentTarget.dataset.value as Language);
                  }}
                >
                  {t('crm.german')}
                </MenuItem>
                <MenuItem
                  selected={language === 'it'}
                  data-value={'it'}
                  onClick={(e) => {
                    setLanguage(e.currentTarget.dataset.value as Language);
                    changeLanguage(e.currentTarget.dataset.value as Language);
                  }}
                >
                  {t('crm.italian')}
                </MenuItem>
                <MenuItem
                  selected={language === 'pt'}
                  data-value={'pt'}
                  onClick={(e) => {
                    setLanguage(e.currentTarget.dataset.value as Language);
                    changeLanguage(e.currentTarget.dataset.value as Language);
                  }}
                >
                  {t('crm.portuguese')}
                </MenuItem>
                <MenuItem
                  selected={language === 'ru'}
                  data-value={'ru'}
                  onClick={(e) => {
                    setLanguage(e.currentTarget.dataset.value as Language);
                    changeLanguage(e.currentTarget.dataset.value as Language);
                  }}
                >
                  {t('crm.russian')}
                </MenuItem>
                <MenuItem
                  selected={language === 'es'}
                  data-value={'es'}
                  onClick={(e) => {
                    setLanguage(e.currentTarget.dataset.value as Language);
                    changeLanguage(e.currentTarget.dataset.value as Language);
                  }}
                >
                  {t('crm.spanish')}
                </MenuItem>
                <MenuItem
                  selected={language === 'tr'}
                  data-value={'nl'}
                  onClick={(e) => {
                    setLanguage(e.currentTarget.dataset.value as Language);
                    changeLanguage(e.currentTarget.dataset.value as Language);
                  }}
                >
                  {t('crm.dutch')}
                </MenuItem>
              </Menu>

              <Tooltip title={t('themeMenu')}>
                <Box
                  p={1}
                  sx={{ display: 'flex', alignItems: 'center' }}
                  onClick={handleClickThemeMenu}
                >
                  {isDarkMode ? (
                    <Iconify
                      icon={'basil:sun-outline'}
                      width={24}
                      sx={{ cursor: 'pointer', color: 'text.primary' }}
                    />
                  ) : (
                    <Iconify
                      icon={'tdesign:mode-dark'}
                      width={24}
                      sx={{ cursor: 'pointer', color: 'text.primary' }}
                    />
                  )}
                </Box>
              </Tooltip>

              <Menu
                anchorEl={anchorElThemeMenu}
                id={t('crm.themeMenu')}
                open={openThemeMenu}
                onClose={handleCloseThemeMenu}
                slotProps={headerMenuSlotProps}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                disableAutoFocusItem={true}
              >
                <MenuItem selected={!isDarkMode} onClick={() => toggleDarkMode(false)}>
                  <ListItemIcon>
                    <Iconify icon={'basil:sun-outline'} width={22} />
                  </ListItemIcon>
                  {t('crm.lightMode')}
                </MenuItem>
                <MenuItem selected={isDarkMode} onClick={() => toggleDarkMode(true)}>
                  <ListItemIcon>
                    <Iconify icon={'tdesign:mode-dark'} width={22} />
                  </ListItemIcon>
                  {t('crm.darkMode')}
                </MenuItem>
              </Menu>

              <Tooltip title={t('notifications')}>
                <Box
                  p={1}
                  onClick={() => {
                    setNotificationDrawerOpen(true);
                  }}
                >
                  <Badge
                    variant="dot"
                    color="secondary"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    invisible={countResult?.count === 0}
                    sx={{
                      '& .MuiBadge-badge.MuiBadge-dot': {
                        top: '7px',
                        right: '7px',
                        border: '1px solid',
                      },
                    }}
                  >
                    <Iconify
                      icon={'mdi:bell-outline'}
                      width={24}
                      sx={{ cursor: 'pointer', color: 'text.primary' }}
                    />
                  </Badge>
                </Box>
              </Tooltip>

              <Tooltip title={t('crm.accountMenu')}>
                <Stack
                  direction={'row'}
                  gap={{ xs: 1, lg: 2 }}
                  bgcolor={'background.thirdWithBlur'}
                  borderRadius={'100%'}
                  onClick={handleClickAccountMenu}
                  aria-controls={openAccountMenu ? 'theme-menu' : undefined}
                  aria-expanded={openAccountMenu ? 'true' : undefined}
                  sx={{ cursor: 'pointer', ml: 1 }}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Badge
                    variant="dot"
                    color="success"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    sx={{
                      '& .MuiBadge-badge.MuiBadge-dot': {
                        border: '1px solid',
                        bottom: '6px',
                        right: '6px',
                        height: ' 12px',
                        width: '12px',
                        borderRadius: '50%',
                      },
                    }}
                  >
                    <Avatar
                      src={data?.profileImage?.thumbnailPublicUrl}
                      sx={{
                        width: downMd ? '36px' : '44px',
                        height: downMd ? '36px' : '44px',
                        borderRadius: '100%',
                      }}
                    />
                  </Badge>
                </Stack>
              </Tooltip>

              <Menu
                anchorEl={anchorElAccountMenu}
                id={t('crm.accountMenu')}
                open={openAccountMenu}
                onClose={handleCloseAccountMenu}
                slotProps={headerMenuSlotProps}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem
                  onClick={() => {
                    customNavigate(baseAccountRoute());
                    dispatch(setRoute('account'));
                  }}
                  sx={{ fontSize: '14px' }}
                >
                  <Avatar
                    src={data?.profileImage?.thumbnailPublicUrl}
                    sx={{
                      width: downMd ? '15px' : '30px',
                      height: downMd ? '15px' : '30px',
                      borderRadius: '15px',
                    }}
                  />
                  {t('crm.myAccount')}
                </MenuItem>
                <Divider />

                <MenuItem
                  onClick={() => {
                    customNavigate(baseSettingsRoute());
                    dispatch(setRoute('settings'));
                  }}
                  sx={{ fontSize: '14px' }}
                >
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  {t('crm.settings')}
                </MenuItem>
                <MenuItem onClick={handleLogout} sx={{ fontSize: '14px' }}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {t('crm.logout')}
                </MenuItem>
              </Menu>
            </Stack>
          </Stack>

          {downMd && (
            <>
              <Divider
                sx={{
                  border: '1px solid',
                  borderColor: 'divider',
                }}
              />
              <CustomTextField
                id="outlined-search"
                variant="outlined"
                placeholder={t('crm.searchPlaceholder')}
                sx={{
                  bgcolor: 'primary.contrastText',
                  '& .MuiInputBase-input': {
                    bgcolor: '#00000000',
                  },
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'background.thirdWithBlur',
                  },
                  boxShadow: '0px 2px 4px 0px rgba(35, 32, 32, 0.1)',
                  width: '100%',
                  borderRadius: '68px',
                  marginTop: '16px',
                  marginBottom: '16px',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon={'mynaui:search'} width={24} />
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}

          {/* Content */}
          <Stack
            direction="row"
            sx={{
              position: 'relative',
              bgcolor: 'background.secondWithBlur',
              boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
              borderRadius: '30px',
              height: {
                xs: 'calc(100dvh - 60px)',
                lg: 'calc(100dvh - 120px)',
              },
              zIndex: 0,
              marginBottom: '8px',
            }}
          >
            <Box
              width={'100%'}
              sx={{ borderRadius: '30px' }}
              overflow={'auto'}
              p={{ xs: 2, lg: 3 }}
            >
              {data?.organization && children}
              <ActionMenu
                anchorElActionMenu={anchorElActionMenu}
                handleClickActionMenu={handleClickActionMenu}
                handleCloseActionMenu={handleCloseActionMenu}
                headerMenuSlotProps={headerMenuSlotProps}
                openActionMenu={openActionMenu}
                status={status}
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <NotificationDrawerWrapper
        open={notificationDrawerOpen}
        setOpen={setNotificationDrawerOpen}
      />
    </Box>
  );
};

export default MainLayout;

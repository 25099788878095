const locale = {
  title: 'Modello di Contratto',
  emptyState1: 'Nessun modello di contratto ancora.',
  emptyState2:
    'Memorizza e gestisci qui tutti i dettagli del contratto per semplificare la fatturazione e mantenere accordi chiari con i tuoi clienti.',
  create: 'Crea Modello di Contratto',
  update: 'Aggiorna Modello di Contratto',
  delete: 'Elimina Modello di Contratto',
  createSuccess: 'Modello di contratto creato con successo.',
  updateSuccess: 'Modello di contratto aggiornato con successo.',
  deleteSuccess: 'Modello di contratto eliminato con successo.',
  deleteConfirm: 'Sei sicuro di voler eliminare questo modello di contratto? Questa azione non può essere annullata.',
};

export default locale; 
import proposalLocale from "../subdomains/proposal/locales/it";
import estimateLocale from "../subdomains/estimate/locales/it";

const locale = {
  estimate: {
    ...estimateLocale,
  },
  proposal: {
    ...proposalLocale,
  },
};

export default locale; 
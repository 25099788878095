const locale = {
  expenses: 'Gastos',
  expense: 'Gasto',
  expenseConversions: 'Conversiones de Gastos',
  title: 'Gastos',
  create: 'Crear Gasto',
  delete: 'Eliminar Gasto',
  update: 'Actualizar Gasto',
  deletedSuccessfully: 'Gasto eliminado con éxito.',
  createSuccess: 'Gasto creado con éxito.',
  updateSuccess: 'Gasto actualizado con éxito.',
  deleteConfirm: '¿Está seguro de que desea eliminar este gasto? Esta acción no se puede deshacer.',
  emptyState1: 'Aún no se han añadido gastos.',
  emptyState2:
    'Almacene y gestione todos los detalles de gastos aquí para agilizar su facturación y mantener acuerdos claros con sus clientes.',
  downloadFormSuccessfully: 'Lista de gastos descargada con éxito.',
  importExpense: 'Importar Gastos',
  importTitle: 'Importar Gastos',
  importText1: 'Puede importar sus datos de gastos desde un archivo Excel.',
  importText2: 'Haga clic en el botón Descargar Ejemplo para descargar la plantilla.',
  // labels
  id: "ID de Gasto",
  totalAmount: "Importe Total",
  totalTax: "Impuesto Total",
  isBillable: "Es Facturable",
  isInvoiced: "Está Facturado",

  // detailDrawer
  changeStatus: 'Cambiar Estado',
  generalInfoTitle: 'Información General',
  customerInfoTitle: 'Información del Cliente',
  expenseInfoTitle: 'Información del Gasto',
  projectInfoTitle: 'Información del Proyecto',
  projectName: 'Nombre del Proyecto',
  paymentMethod: 'Método de Pago',
};

export default locale; 
const locale = {
  returns: 'Returns',
  return: 'Return',
  returnConversions: 'Return Conversions',
  title: 'Returns',
  create: 'Add Return',
  update: 'Update Return',
  delete: 'Delete Return',
  createSuccess: 'Return added successfully.',
  updateSuccess: 'Return updated successfully.',
  deleteSuccess: 'Return deleted successfully.',
  deleteConfirm: 'Are you sure you want to delete this return. This action cannot be undone.',
  emptyState1: 'No returns recorded yet.',
  emptyState2:
    'Manage and review any product or service returns here to keep your records accurate and customer needs met.',

  // detailDrawer
  generalInfoTitle: 'General Information',
  isOpen: 'Is Open',
  refundAmount: 'Refund Amount',
  resolveReason: 'Resolve Reason',
  disapproveReason: 'Disapprove Reason',
  returnProducts: 'Return Products',
  amount: 'Amount',
  reason: 'Reason',
  isPartial: 'Is Partial',
  note: 'Note',
  statusChangeSuccess: 'Status changed successfully',
  changeStatus: 'Change Status',
};

export default locale;

const locale = {
  demo: 'Demo',
  title: 'Was ist die <highlight>CoCRM</highlight> Community?',
  description:
    'Bei CoCRM bieten wir Ihnen nicht nur eine effektive Verwaltung Ihrer Geschäfts- und Kundenprozesse, sondern auch einen Kollaborationsraum, der darauf ausgelegt ist, Ihr Netzwerk und Ihre Geschäftsmöglichkeiten zu erweitern.',
  featuresTitle: 'Mit der CoCRM Community können Sie:',
  features1: 'Neue Geschäftsmöglichkeiten durch Empfehlungen gewinnen.',
  features2: 'Mit zuverlässigen Lieferanten in Kontakt treten.',
  features3: 'Vertrauenswürdige Geschäftspartner finden.',
  features4: 'Ihr Netzwerk erweitern, um Ihren Kunden 360°-Dienstleistungen anzubieten.',
  buttonTitle: 'Der Community beitreten',
  formTitle: 'Warteliste beitreten',
  formDescription:
    'Bitte füllen Sie das untenstehende Formular aus, und wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.',
  sendSuccess: 'Vielen Dank! Wir werden uns so schnell wie möglich bei Ihnen melden.',
  formButtonTitle: 'Beitreten',
  fullName: 'Vollständiger Name',
  email: 'E-Mail',
  phone: 'Telefon',
  orgName: 'Organisationsname',
};

export default locale;

import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LogoMini from 'assets/images/co-black.svg';
import { IProposalDetailProspectResult } from 'corede-common-cocrm';
import moment from 'moment';

const Header = (props: { proposalData: IProposalDetailProspectResult | undefined }) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      sx={{ bgcolor: '#2320200D', borderRadius: '6px', p: 3 }}
    >
      <Stack alignItems={'flex-start'} gap={3}>
        {props.proposalData?.organization?.logo ? (
          <img
            src={props.proposalData?.organization?.logo?.publicUrl}
            alt="coCRM"
            style={{
              height: '40px',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Typography variant="h5">{props.proposalData?.organization?.name}</Typography>
        )}

        <Typography
          variant="body2"
          sx={{ maxWidth: '300px', color: 'text.secondary', whiteSpace: 'pre-wrap' }}
        >
          {(props.proposalData?.organization?.logo?.publicUrl
            ? props.proposalData?.organization?.name + ` \n`
            : '') +
            (props.proposalData?.organization?.addresses?.[0]?.address
              ? props.proposalData?.organization?.addresses?.[0]?.address + ' '
              : '') +
            (props.proposalData?.organization?.addresses?.[0]?.country
              ? props.proposalData?.organization?.addresses?.[0]?.country + ' '
              : '') +
            (props.proposalData?.organization?.addresses?.[0]?.state
              ? props.proposalData?.organization?.addresses?.[0]?.state + ' '
              : '') +
            (props.proposalData?.organization?.addresses?.[0]?.city
              ? props.proposalData?.organization?.addresses?.[0]?.city + ' '
              : '') +
            (props.proposalData?.organization?.addresses?.[0]?.street
              ? props.proposalData?.organization?.addresses?.[0]?.street + ' '
              : '') +
            (props.proposalData?.organization?.addresses?.[0]?.zip
              ? props.proposalData?.organization?.addresses?.[0]?.zip + ' '
              : '') +
            (props.proposalData?.organization?.addresses?.[0]?.phone
              ? props.proposalData?.organization?.addresses?.[0]?.phone
              : '')}
        </Typography>
      </Stack>

      <Stack direction={'column'} justifyContent={'space-between'} gap={3}>
        <Typography variant="h5">
          {t('auth.proposal')} - #{props.proposalData?.proposalId}
        </Typography>

        <Stack>
          {props.proposalData?.createdAt && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('createdAt')}:{' '}
              {props.proposalData?.createdAt
                ? moment(props.proposalData?.createdAt).format('DD/MM/YYYY')
                : '-'}
            </Typography>
          )}
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('auth.expireDate')}:{' '}
            {props.proposalData?.expirationEmailSentDate
              ? moment(props.proposalData?.expirationEmailSentDate).format('DD/MM/YYYY')
              : '-'}
          </Typography> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;

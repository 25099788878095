const locale = {
  leadSources: "Lead-Quellen",
  title: "Lead-Quellen",
  create: "Lead-Quelle erstellen",
  delete: "Lead-Quelle löschen",
  deleteConfirm: "Sind Sie sicher, dass Sie diese Quelle löschen möchten: ",
  deletedSuccessfully: "Lead-Quelle erfolgreich gelöscht",
  createSuccess: "Lead-Quelle erfolgreich erstellt",
  updateSuccess: "Lead-Quelle erfolgreich aktualisiert",
  update: "Lead-Quelle aktualisieren",
  updateLeadSourceSuccess: "Lead-Quelle erfolgreich aktualisiert",
  selectColor: "Farbe auswählen",
  selectIcon: "Symbol auswählen",
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, memo, SetStateAction, useEffect, useMemo, useState } from 'react';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables, Language } from 'corede-common';
import { INoteCreateInput, NoteTargetType } from 'corede-common-cocrm';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import { enqueueSnackbar } from 'notistack';
import 'react-quill/dist/quill.snow.css';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useNoteCreateMutation } from '../../context/note.api';
import { validateCreateNoteInput } from '../../validations/create.validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpsertNoteComponent } from '../UpsertNote.component';
import ActionDrawer from 'components/drawer/ActionDrawer';
import ActionDialog, { OverlayType } from 'components/dialog/ActionDialog';

export interface INoteCreateOverlay {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  targetId?: string;
  targetType?: NoteTargetType;
  overlayType: OverlayType;
}

const NoteCreateOverlay = memo((props: INoteCreateOverlay) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);

  // queries

  // mutations
  const [
    noteCreate,
    { data: noteCreateData, isLoading: noteCreateLoading, error: noteCreateError },
  ] = useNoteCreateMutation();

  // constants
  const initialValues = useMemo<IGraphqlVariables<INoteCreateInput>>(
    () => ({
      input: {
        title: '',
        content: '',
        targetId: props.targetId,
        targetType: props.targetType,
      },
    }),
    [],
  );

  // form setup
  const noteCreateUseForm = useForm<IGraphqlVariables<INoteCreateInput>>({
    defaultValues: initialValues,
    resolver: yupResolver(validateCreateNoteInput),
    mode: 'onChange',
  });

  const onSubmit = async (values: IGraphqlVariables<INoteCreateInput>) => {
    setLoading(true);
    const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
      input: values,
    });
    await noteCreate(transformedValues as IGraphqlVariables<INoteCreateInput>);
    setLoading(false);
  };

  // useEffects.success

  useEffect(() => {
    if (noteCreateData) {
      enqueueSnackbar(t('crm.note.note.createSuccess'), {
        variant: 'success',
      });
      noteCreateUseForm.reset();
      props.setOpen(false);
    }
  }, [noteCreateData, noteCreateUseForm.reset]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(noteCreateError as IBaseError, currentLanguage);
  }, [noteCreateError]);

  const commonContent = (
    <UpsertNoteComponent
      open={props.open}
      setOpen={props.setOpen}
      loading={noteCreateLoading || loading}
      useForm={noteCreateUseForm}
      targetId={props.targetId}
      targetType={props.targetType}
    />
  );

  switch (props.overlayType) {
    case OverlayType.drawer:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          width={500}
          backgroundColor={'background.default'}
          title={t('crm.note.note.create')}
          handleSubmit={noteCreateUseForm.handleSubmit(onSubmit)}
          disabled={noteCreateLoading || loading || !noteCreateUseForm.formState.isValid}
          loading={noteCreateLoading || loading}
          buttonTitle={t('crm.note.note.create')}
        >
          {commonContent}
        </ActionDialog>
      );

    case OverlayType.dialog:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          title={t('crm.note.note.create')}
          handleClick={noteCreateUseForm.handleSubmit(onSubmit)}
          disabled={noteCreateLoading || loading || !noteCreateUseForm.formState.isValid}
          loading={noteCreateLoading || loading}
          buttonTitle={t('crm.note.note.create')}
          width={500}
        >
          {commonContent}
        </ActionDialog>
      );

    default:
      return null;
  }
});

export default NoteCreateOverlay;

const locale = {
  // fields
  users: 'Пользователи заметки',
  date: 'Дата заметки',
  title: 'Список заметок',
  detail: 'Детали заметки',
  sharedList: "Общие заметки",
  privateList: "Личные заметки",
  ownList: "Мои заметки",
  allList: "Все заметки",

  // actions
  create: 'Создать заметку',
  add: 'Добавить заметку',
  update: 'Обновить заметку',
  delete: 'Удалить заметку',

  // situational text
  tagsPlaceholder: 'Пожалуйста, добавьте теги и нажмите enter',
  createSuccess: 'Заметка успешно создана',
  updateSuccess: 'Заметка успешно обновлена',
  deletedSuccessfully: 'Заметка успешно удалена',
  deleteContent: 'Вы уверены, что хотите удалить эту заметку? Это действие нельзя отменить.',
  emptyState1: 'Заметки еще не добавлены.',
  emptyState2:
    'Создавайте и организуйте свои заметки здесь, чтобы отслеживать важные детали и разговоры в вашей CRM.',

  createdBy: "Заметка создана",
};

export default locale; 
const locale = {
  pricing: 'Prezzi',
  saveUpTo: 'Risparmia fino a',
  monthly: 'mensile',
  monthlyUppercase: 'Mensile',
  annually: 'annuale',
  annuallyUppercase: 'Annuale',
  organization: 'Organizzazione',
  month: 'Mese',
  billedMonthly: 'Fatturato Mensilmente',
  billedAnnually: 'Fatturato Annualmente',
  users: 'Utenti',
  startTrialForFree: 'Inizia la Prova Gratuita',
  buy: 'ACQUISTA',
  contactUs: 'Contattaci',
  starterPlan: 'Piano Iniziale',
  enterprisePlan: 'Piano Aziendale',
  mostPopular: 'Più Popolare',
  buyPackage: 'Acquista Pacchetto',
  buyPackageDescription:
    'Questo pacchetto chiamato {{name}} include un limite di archiviazione di {{storageLimit}} GB e un limite di utenti di {{userLimit}}.',
  dontHaveBillingInfo: 'Non hai informazioni di fatturazione da utilizzare.',
  dontHaveCard: 'Non hai alcuna carta da utilizzare.',
  addBillingInfo: 'Aggiungi Informazioni di Fatturazione',
  addCard: 'Aggiungi Carta',
  manageBillingInfo: 'Gestisci Informazioni di Fatturazione',
  manageCards: 'Gestisci Carte',
  summary: 'Riepilogo',
  iAcceptThe: 'Accetto le',
  preliminaryInformation: 'Informazioni Preliminari',
  distanceSalesAgreement: 'Accordo di Vendita a Distanza',
  close: 'Chiudi',
  cancel: 'Annulla',
  subscribeSuccess1: 'Il tuo abbonamento è stato avviato con successo.',
  subscribeSuccess2: 'Benvenuto a bordo.',
  continue: 'Continua',
  letUsCall: 'Lascia che ti chiamiamo',
  billingInfo: 'Informazioni di Fatturazione',
  selectedCard: 'Carta Selezionata',
  currentPlan: 'Piano Attuale',
  renewAt: 'Rinnova il',
  active: 'Attivo',
  usageLimits: 'Limiti di Utilizzo',
  storageUsage: 'Utilizzo dello spazio',
  activeCard: 'Carta Attiva',
  trialPackage: 'Pacchetto di Prova',
  trialPackageDescription:
    'Questo pacchetto offre un periodo di utilizzo di 7 giorni, con un limite di archiviazione di {{storageLimit}} GB e un limite di utenti di {{userLimit}}.',
  confirmTrial: 'Sei sicuro di voler iniziare la prova?',
  beginTrial: 'Inizia la Prova',
  contactFormSuccess: 'La tua richiesta è stata inviata con successo. Ti contatteremo presto.',

  packageDescription:
    'Questo pacchetto offre un periodo di utilizzo di 7 giorni, con un limite di archiviazione di {{storageLimit}} GB e un limite di utenti di {{userLimit}}.',
  beginTrialSuccess: 'Prova avviata con successo.',
  purchaseSuccess: 'Acquistato con successo.',
  title: 'Prezzi',
  unknownError: 'Si è verificato un errore sconosciuto.',
  contactMessage: 'Telefono: {{phone}}, Nome Organizzazione: {{orgName}}',
  detailTitle: 'Dettaglio',
  trialPackageTitle: 'Pacchetto di Prova',
  buyPackageTitle: 'Acquista Pacchetto',
  contactUsTitle: 'Contattaci',
  preliminaryInfoTitle: 'Informazioni Preliminari',
  distanceSalesAgreementTitle: 'Accordo di Vendita a Distanza',
  welcomeTrial1: 'Il tuo pacchetto di prova è stato avviato con successo. Benvenuto a bordo.',
  welcomeTrial2: 'Benvenuto a bordo.',
  noBillingInfo: 'Non hai informazioni di fatturazione da utilizzare.',
  billingInfoLabel: 'Informazioni di Fatturazione*',
  noCardInfo: 'Non hai alcuna carta da utilizzare.',
  selectedCardLabel: 'Carta Selezionata*',
  acceptPreliminaryInfo: 'Accetto le Informazioni Preliminari',
  acceptDistanceSalesAgreement: 'Accetto l\'Accordo di Vendita a Distanza',
  preliminaryInfoDescription:
    'Questo pacchetto offre un periodo di utilizzo di 7 giorni, con un limite di archiviazione e permessi utente come descritto nell\'accordo.',
  subscriptionSuccess: 'Il tuo abbonamento è stato avviato con successo. Benvenuto a bordo.',
  selectedPeriodLabel: '/{{selectedPeriod}}',
  distanceSalesAgreementDescription:
    'Questo pacchetto offre un periodo di utilizzo di 7 giorni, con un limite di archiviazione e permessi utente come descritto nell\'accordo di vendita a distanza.',
  goCRM: 'Vai al CRM',
  tryForFree: 'Prova Gratuitamente',
  onePaymentOf: 'Un pagamento di',

  cancelled: 'Annullato',
  expired: 'Scaduto',
  completed: 'Completato',
  failed: 'Fallito',
  ongoingWithCancelled: 'In corso con annullamento',
  ongoingWithFailure: 'In corso con fallimento',

  payment: 'Pagamento',
  upgrade: 'AGGIORNA',
  paymentSuccess: 'Pagamento completato con successo.',
  paymentFailure: 'Processo di pagamento completato',
};

export default locale; 
const locale = {
  contracts: 'Contrats',
  contract: 'Contrat',
  contractConversions: 'Conversions de Contrats',
  title: 'Contrats',
  create: 'Créer un Contrat',
  update: 'Mettre à jour le Contrat',
  delete: 'Supprimer le Contrat',
  createSuccess: 'Contrat créé avec succès.',
  updateSuccess: 'Contrat mis à jour avec succès.',
  deleteSuccess: 'Contrat supprimé avec succès.',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce contrat ? Cette action ne peut pas être annulée.',
  emptyState1: 'Aucun contrat ajouté pour le moment.',
  emptyState2:
    'Stockez et gérez tous les détails du contrat ici pour simplifier votre facturation et maintenir des accords clairs avec vos clients.',

  // labels
  contractTemplateLabel: 'Modèle de Contrat',
  contractValue: 'Valeur du Contrat',
  contractType: 'Type de Contrat',
  signedDate: 'Date de Signature',
  signedUser: 'Utilisateur Signataire',
  signStatus: 'Statut de Signature',

  //detail drawer
  changeStatus: 'Changer le Statut',
  statusChangeSuccess: 'Statut modifié avec succès.',
  generalInfoTitle: 'Informations Générales',
  customerInfoTitle: 'Informations Client',
  projectInfoTitle: 'Informations Projet',
  contractRenew: 'Renouveler le Contrat',
  renew: 'Renouveler le Contrat',
  renewSuccess: 'Contrat renouvelé avec succès.',
  emptyStateRenew: 'Aucun contrat à renouveler.',
  renewHistory: 'Historique des Renouvellements',
};

export default locale; 
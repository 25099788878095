import {
  Box,
  Menu,
  Grow,
  Collapse,
  MenuItem,
  Tooltip,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { baseLeadRoute } from 'apps/crm/domains/03-lead/routes/lead.base.route';
import { baseCustomerRoute } from 'apps/crm/domains/04-customer/routes/customer.base.route';
import {
  listEstimatesRoute,
  listProposalsRoute,
} from 'apps/crm/domains/05-preSale/routes/preSales.base.route';
import { listInvoicesRoute } from 'apps/crm/domains/06-sale/routes/sales.base.route';
import { baseTasksRoute } from 'apps/crm/domains/11-task/routes/tasks.base.route';
import { Iconify } from 'components';
import { PermissionAction } from 'corede-common';
import { OrganizationStatus, PermissionSubject } from 'corede-common-cocrm';
import { usePermissions } from 'permission/PermissionContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const ActionMenu = (props: {
  status: OrganizationStatus | undefined;
  anchorElActionMenu: HTMLElement | null;
  openActionMenu: boolean;
  handleClickActionMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseActionMenu: () => void;
  headerMenuSlotProps: any;
}) => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);

  const { hasPermission } = usePermissions();

  const hasLeadPermission = hasPermission({
    subject: PermissionSubject.lead,
    action: PermissionAction.create,
  });
  const hasCustomerPermission = hasPermission({
    subject: PermissionSubject.customer,
    action: PermissionAction.create,
  });
  const hasTaskPermission = hasPermission({
    subject: PermissionSubject.task,
    action: PermissionAction.create,
  });
  const hasEstimatePermission = hasPermission({
    subject: PermissionSubject.estimate,
    action: PermissionAction.create,
  });
  const hasProposalPermission = hasPermission({
    subject: PermissionSubject.proposal,
    action: PermissionAction.create,
  });
  const hasInvoicePermission = hasPermission({
    subject: PermissionSubject.invoice,
    action: PermissionAction.create,
  });

  const hasAnyPermission =
    hasLeadPermission ||
    hasCustomerPermission ||
    hasTaskPermission ||
    hasEstimatePermission ||
    hasProposalPermission ||
    hasInvoicePermission;

  if (props.status !== 'active' || !hasAnyPermission || !visible) {
    return null;
  }

  return (
    <>
      {props.status === 'active' && (
        <Tooltip title={t('actionMenu')}>
          <Box
            sx={{
              width: { xs: '30px', lg: '45px' },
              height: { xs: '30px', lg: '45px' },
              borderRadius: '23px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              bgcolor: 'secondary.main',
              position: 'fixed',
              right: downMd ? '36px' : '45px',
              bottom: downMd ? '36px' : '52px',
              zIndex: 1000,
            }}
            onClick={props.handleClickActionMenu}
          >
            <Iconify icon={'gravity-ui:plus'} width={downMd ? 16 : 24} sx={{ color: '#FFF' }} />
          </Box>
        </Tooltip>
      )}
      <Menu
        anchorEl={props.anchorElActionMenu}
        id={t('crm.actionMenu')}
        open={props.openActionMenu}
        onClose={props.handleCloseActionMenu}
        slotProps={props.headerMenuSlotProps}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        disableAutoFocusItem={true}
        TransitionComponent={Grow}
        transitionDuration={500}
      >
        <Collapse in={props.openActionMenu} timeout={500} unmountOnExit>
          {hasLeadPermission && (
            <MenuItem
              onClick={() => {
                props.handleCloseActionMenu();
                navigate(baseLeadRoute() + '#create');
              }}
            >
              <Iconify
                icon={'material-symbols-light:social-leaderboard-outline-rounded'}
                width={24}
                sx={{ color: 'text.primary', mr: 1 }}
              />
              {t('crm.leadCreate')}
            </MenuItem>
          )}
          {hasCustomerPermission && (
            <MenuItem
              onClick={() => {
                props.handleCloseActionMenu();
                navigate(baseCustomerRoute() + '#create');
              }}
            >
              <Iconify
                icon={'solar:user-linear'}
                width={24}
                sx={{ color: 'text.primary', mr: 1 }}
              />
              {t('crm.customerCreate')}
            </MenuItem>
          )}
          {hasTaskPermission && (
            <MenuItem
              onClick={() => {
                props.handleCloseActionMenu();
                navigate(baseTasksRoute() + '#create');
              }}
            >
              <Iconify icon={'carbon:product'} width={24} sx={{ color: 'text.primary', mr: 1 }} />
              {t('crm.taskCreate')}
            </MenuItem>
          )}
          {hasEstimatePermission && (
            <MenuItem
              onClick={() => {
                props.handleCloseActionMenu();
                navigate(listEstimatesRoute() + '#create');
              }}
            >
              <Iconify icon={'mage:preview'} width={24} sx={{ color: 'text.primary', mr: 1 }} />
              {t('crm.estimateCreate')}
            </MenuItem>
          )}
          {hasProposalPermission && (
            <MenuItem
              onClick={() => {
                props.handleCloseActionMenu();
                navigate(listProposalsRoute() + '#create');
              }}
            >
              <Iconify icon={'carbon:document'} width={24} sx={{ color: 'text.primary', mr: 1 }} />
              {t('crm.proposalCreate')}
            </MenuItem>
          )}
          {hasInvoicePermission && (
            <MenuItem
              onClick={() => {
                props.handleCloseActionMenu();
                navigate(listInvoicesRoute() + '#create');
              }}
            >
              <Iconify
                icon={'solar:sale-broken'}
                width={24}
                sx={{ color: 'text.primary', mr: 1 }}
              />
              {t('crm.invoiceCreate')}
            </MenuItem>
          )}
          <Divider />
          <MenuItem
            onClick={() => {
              setVisible(false);
            }}
          >
            <Iconify
              icon={'ic:twotone-hide-source'}
              width={20}
              sx={{ color: 'text.primary', mr: 1.2 }}
            />
            {t('crm.hideButton')}
          </MenuItem>
        </Collapse>
      </Menu>
    </>
  );
};

export default ActionMenu;

const locale = {
  product: 'Produkte',
  title: 'Produkte',
  create: 'Produkt erstellen',
  update: 'Produkt aktualisieren',
  delete: 'Produkt löschen',
  deleteConfirm: 'Sind Sie sicher, dass Sie diesen Status löschen möchten: ',
  deletedSuccessfully: 'Produkt erfolgreich gelöscht',
  createProduct: 'Produkt erstellen',
  createSuccess: 'Produkt erfolgreich erstellt',
  updateSuccess: 'Produkt erfolgreich aktualisiert',
  name: 'Name',
  icon: 'Symbol',
  showMore: 'Mehr anzeigen',
  detail: 'Produktdetail',
  type: 'Typ',
  image: 'Bild',
  sku: 'Artikelnummer',
  description: 'Beschreibung',
  currency: 'Währung',
  price: 'Preis',
  priceType: 'Preistyp',
  discountedPrice: 'Reduzierter Preis',
  unit: 'Einheit',
  categoryId: 'Kategorie',
  natTaxRate: 'Nationale Steuerrate',
  intTaxRate: 'Internationale Steuerrate',
  unitPrice: 'Stückpreis',
  categories: 'Kategorien',

  emptyState1: 'Noch keine Produkte erfasst.',
  emptyState2:
    'Verwalten und überprüfen Sie hier Produkte, um Ihre Aufzeichnungen genau und Kundenbedürfnisse erfüllt zu halten.',
};

export default locale; 
const locale = {
  supports: 'Ticket',
  support: 'Ticket',
  detail: 'Dettagli del Ticket',
  subject: 'Oggetto',
  title: 'Ticket',
  create: 'Crea Ticket',
  createSuccess: 'Ticket creato con successo.',
  update: 'Aggiorna Ticket',
  updateSuccess: 'Ticket aggiornato con successo.',
  delete: 'Elimina Ticket',
  deleteSuccess: 'Ticket eliminato con successo.',
  deleteContent: 'Sei sicuro di voler eliminare il ticket?',
  emptyState1: 'Nessun ticket ancora.',
  emptyState2:
    'Non hai creato alcun ticket, o sono già stati risolti. Una volta che il tuo team invierà una richiesta di supporto, tutti i ticket appariranno qui.',

  // detailDrawer
  generalInfoTitle: 'Informazioni Generali',
  ticketInfoTitle: 'Informazioni sul Ticket',
  closedDate: 'Data di Chiusura',
  changeStatus: 'Cambia Stato',
  statusChangeSuccess: 'Stato cambiato con successo.',
};

export default locale; 
const locale = {
  permissions: "Permissões",
  errorFetchingPermissions: "Erro ao buscar permissões",
  allPermissions: "Todas as permissões",
  all: "Todos",
  ownPermissionsAlert: "Você não pode alterar suas próprias permissões.",
  action: "Ação",
  actionScope: "Escopo da ação",
  updateSuccess: "Permissão atualizada com sucesso",
};

export default locale; 
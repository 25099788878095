const locale = {
  payments: 'Pagos',
  payment: 'Pago',
  paymentConversions: 'Conversiones de Pago',
  title: 'Pagos',
  create: 'Añadir Pago',
  update: 'Actualizar Pago',
  delete: 'Eliminar Pago',
  createSuccess: 'Pago añadido con éxito.',
  updateSuccess: 'Pago actualizado con éxito.',
  deleteSuccess: 'Pago eliminado con éxito.',
  deleteConfirm: '¿Está seguro de que desea eliminar este pago? Esta acción no se puede deshacer.',
  emptyState1: 'Aún no hay pagos registrados.',
  emptyState2:
    'Realice un seguimiento y gestione los pagos de sus facturas aquí para mantener sus finanzas organizadas y actualizadas.',

  // detailDrawer
  generalInfoTitle: 'Información General',
  date: 'Fecha de Pago',
  amountPaid: 'Cantidad Pagada',
  confirmationNumber: 'Número de Confirmación',
  currency: 'Moneda',
};

export default locale; 
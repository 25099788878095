const locale = {
  permissions: "Разрешения",
  errorFetchingPermissions: "Ошибка при получении разрешений",
  allPermissions: "Все разрешения",
  all: "Все",
  ownPermissionsAlert: "Вы не можете изменить свои собственные разрешения.",
  action: "Действие",
  actionScope: "Область действия",
  updateSuccess: "Разрешение успешно обновлено",
};

export default locale; 
import settingsLocale from "../subdomains/settings/locales/fr";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  settings: {
    ...settingsLocale,
  },
};

export default locale; 
const locale = {
  title: 'Tahminler',
  create: 'Tahmin Oluştur',
  update: 'Tahmin Güncelle',
  delete: 'Sil',
  view: "Tahmin'i Görüntüle",
  list: 'Tahmin Listesi',
  detail: 'Tahmin Detayı',
  createdSuccessfully: 'Tahmin başarıyla oluşturuldu',
  updatedSuccessfully: 'Tahmin başarıyla güncellendi',
  deleteConfirm: 'Kaydı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
  deletedSuccessfully: 'Tahmin başarıyla silindi',
  type: 'Tip',
  name: 'Ad',
  content: 'İçerik',
  description: 'Açıklama',
  createInfo: 'Bu bölümden yeni kayıt oluşturabilirsiniz.',
  emptyState1: 'Tahmin bulunamadı',
  emptyState2: 'Hemen aşağıdaki butonu kullanarak tahmin oluşturabilirsiniz.',
  createTahmin: 'Tahmin Oluştur',
  importTahmin: 'Tahmin İçe Aktar',
  importTitle: 'Tahminleri İçe Aktar',
  downloadExample: 'Örnek Formu İndir',
  importText1: 'Bu sayfadan tahminleri içeriye aktarabilirsiniz.',
  importText2: 'Tahminlerinizi yayınlayabilmeniz için aşağıdaki örnek formu kullanabilirsiniz.',
  importExport: 'İçeri/Dışarı Aktar',
  exportData: 'Veriyi Dışarı Aktar',  
  importData: 'Veriyi İçe Aktar',
  b2b: 'B2B',
  b2c: 'B2C',
  viewTahmin: 'Görüntüle',
  edit: 'Düzenle',
  junk: 'Spam',
  unJunk: 'Spami Kaldır',
  addNewRole: 'Yeni Rol Ekle',
  actions: 'İşlemler',
  tahmins: 'Sözlşemeler',
  businessName: 'Firma Adı',
  governmentAgency: 'Devlet Dairesi',
  fullName: 'Tam Adı',
  VAT: 'Vergi Dairesi',
  phoneNumber: 'Telefon',
  country: 'İl',
  state: 'İlçe',
  city: 'İlçe',
  ZIP: 'Posta Kodu',
  sentDate: 'Giden Tarih',
  sendEmailOnExpiration: 'E-posta Gönder',
  legalNDAId: 'Gizlilik Sözleşmesi',

  prefix: 'Bilgi',
  estimates: 'Tahminler',
  addEstimate: 'Tahmin Ekle',
  noEstimateContent1: 'Herhangi bir estimate bulunamadı.',
  noEstimate: 'Henüz bir tahmin eklenmedi.',
  generalInfo: 'Genel Bilgiler',
  sentSuccessfully: 'Tahmin gönderildi',
  send: 'Tahmin Gönder',
  resend: 'Yeniden Gönder',
  sendSureTitle: 'Tahmini Gönder',
  sendSureDesc: 'Tahmini göndermek istediğinizden emin misiniz?',
  changeStatus: 'Durumu Değiştir',
  statusUpdatedSuccessfully: 'Durum başarıyla güncellendi',

  // generalInfo
  generalInfoTitle: 'Genel Bilgiler',
  status: 'Durum',
  createdAt: 'Oluşturulma Tarihi',
  reference: 'Referans',
  createdBy: 'Oluşturan',
  updatedAt: 'Güncellenme Tarihi',
  notAvailable: 'Mevcut değil',
  updateStatus: 'Durumu Güncelle',

  // customerInfo
  customerInfoTitle: 'Müşteri Bilgileri',
  prospectName: 'Müşteri Adı',
  contactFirstName: 'İsim',
  contactLastName: 'Soyisim',
  contactEmail: 'E-posta',
  contactPhoneNumber: 'Telefon',
  address: 'Adres',

  //productInfo
  productInfoTitle: 'Ürün Bilgileri',
  productNameUnknown: 'Bilinmeyen Ürün',
  price: 'Birim Fiyat',
  discountedPrice: 'İndirimli Fiyat',
  quantity: 'Miktar',
  finalPrice: 'Toplam Fiyat',
  tax: 'Vergi Oranı',
  noProducts: 'Ürün bulunmamaktadır.',

  //legalInfo
  legalInfoTitle: 'Yasal ve Hukuki Bilgiler',
  ndaTitle: 'Gizlilik Sözleşmesi (NDA)',
  termsTitle: 'Şartlar ve Koşullar',
  documentName: 'Belge Adı',
  documentDescription: 'Açıklama',
  documentContent: 'İçerik',

  //financialInfo
  financialInfoTitle: 'Finansal Bilgiler',
  currency: 'Para Birimi',
  subTotal: 'Ara Toplam',
  discount: 'İndirim',
  taxTotal: 'Vergi Toplamı',
  shippingTotal: 'Kargo Toplamı',
  total: 'Toplam Fiyat',
  noFinancialData: 'Finansal bilgi bulunmamaktadır.',

  //documentInfo
  documentsTitle: 'Belgeler',
  documentNameUnknown: 'Bilinmeyen Belge',
  mimeType: 'Dosya Türü',
  download: 'İndir',
  noDocuments: 'Belge bulunmamaktadır.',

  //salesAgents
  salesAgentsTitle: 'Satış Temsilcileri',
  unknownAgent: 'Bilinmeyen',
  noSalesAgents: 'Satış temsilcisi bulunmamaktadır.',

  //signature
  signatureTitle: 'İmza Bilgileri',
  additionalInfoTitle: 'Ek Bilgiler',
  signature: 'İmza',
  signerUser: 'İmzalayan Kullanıcı',

  //additionalInfo
  scopeOfWork: 'İş Kapsamı',
  assumptionsAndExclusions: 'Varsayımlar ve Hariçler',
  useLegalNDA: 'Gizlilik Sözleşmesi Kullanımı',
  useLegalTermAndCond: 'Şartlar ve Koşullar Kullanımı',
  yes: 'Evet',
  no: 'Hayır',

  //tabs
  generalInformation: 'Genel Bilgiler*',
  prospect: 'Müşteri Adayı*',
  detailedInformation: 'Detaylı Bilgiler',
  productsAndServices: 'Ürünler ve Hizmetler*',
  salesAgents: 'Satış Temsilcileri',
  legal: 'Sözleşmeler',
  signatureTab: 'İmza*',
};

export default locale;

const locale = {
  title: "Categorie di Clienti",
  headerTitle: "Categorie di Clienti",
  create: "Crea Categoria Cliente",
  columnId: "ID",
  columnName: "Nome",
  columnRoles: "Ruoli",
  columnActions: "Azioni",
  rolesButton: "Modifica Ruoli",
  createCustomerCategoryTitle: "Crea Nuova Categoria Cliente",
  customerCategoryNameLabel: "Nome Categoria Cliente",
  rolesLabel: "Ruoli",
  createCustomerCategoryButton: "Crea",
  emptyStateContent1: "Nessuna categoria cliente trovata",
  emptyStateContent2:
    "Puoi creare nuove categorie di clienti cliccando sul pulsante qui sotto.",
  addNewRole: "Aggiungi Nuovo Ruolo",
  deletedSuccessfully: "Categoria cliente eliminata con successo",
  deleteConfirm: "Sei sicuro di voler eliminare questa categoria cliente?",
  delete: "Elimina",
  enTranslation: "Traduzione Inglese",
  trTranslation: "Traduzione Turca",
  department: "Dipartimento",
  parentCategory: "Categoria Principale",
  icon: "Icona",
  name: "Nome",
  createCustomerCategorySuccess: "Categoria cliente creata con successo",
  addDepartment: "Aggiungi Dipartimento",
  showMore: "Mostra di Più",
  columnIcon: "Icona",
  columnDepartment: "Dipartimento",
  columnParentCategory: "Categoria Principale",
  selectIconTitle: "Seleziona un'Icona",
  searchIcons: "Cerca Icone",
  update: "Aggiorna Categoria Cliente",
  updateCustomerCategorySuccess: "Categoria cliente aggiornata con successo",
  actions: "Azioni",
  createdAt: "Creato Il",
  emptyState1: "Nessuna categoria cliente trovata",
  emptyState2: "Puoi creare nuove categorie di clienti cliccando sul pulsante qui sotto.",
};

export default locale; 
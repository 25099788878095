const locale = {
  service: 'Services',
  title: 'Services',
  create: 'Créer un Service',
  update: 'Mettre à jour le Service',
  delete: 'Supprimer le Service',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce statut : ',
  deletedSuccessfully: 'Service supprimé avec succès',
  createService: 'Créer un Service',
  createSuccess: 'Service créé avec succès',
  updateSuccess: 'Service mis à jour avec succès',
  name: 'Nom',
  icon: 'Icône',
  showMore: 'Afficher Plus',

  detail: 'Détail du Service',
  type: 'Type',
  image: 'Image',
  sku: 'Référence',
  description: 'Description',
  currency: 'Devise',
  price: 'Prix',
  priceType: 'Type de Prix',
  discountedPrice: 'Prix Réduit',
  unit: 'Unité',
  categoryId: 'Catégorie',
  natTaxRate: 'Taux de Taxe National',
  intTaxRate: 'Taux de Taxe International',

  categories: 'Catégories',
  rate: 'Tarif',
  rateType: 'Type de Tarif',
  discountedRate: 'Tarif Réduit',
  emptyState1: 'Aucun service enregistré pour le moment.',
  emptyState2:
    'Gérez et examinez ici tous les retours de service pour maintenir vos enregistrements précis et répondre aux besoins des clients.',
};

export default locale; 
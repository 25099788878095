const locale = {
  myAccount: "Mi cuenta",
  profilePicture: "Foto de perfil",
  profile: "Perfil",
  name: "Nombre",
  surname: "Apellido",
  birthDate: "Fecha de nacimiento",
  gender: "Género",
  update: "Actualizar",
  changeImage: "Cambiar imagen",
  change: "Cambiar",
  updateProfileSuccess: "Perfil actualizado con éxito",
  updateProfilePictureSuccess: "Foto de perfil actualizada con éxito",
  country: "País",
  city: "Ciudad",
  signatureImage: "Imagen de firma",
  twoFactorAuthEmail: "Correo de autenticación de dos factores",
};

export default locale; 
const locale = {
  estimate: 'Angebot',
  title: 'Angebote',
  create: 'Angebot erstellen',
  update: 'Angebot aktualisieren',
  delete: 'Löschen',
  view: 'Angebot anzeigen',
  list: 'Angebote auflisten',
  detail: 'Angebotsdetails',
  createdSuccessfully: 'Angebot erfolgreich erstellt',
  updatedSuccessfully: 'Angebot erfolgreich aktualisiert',
  deleteConfirm: 'Sind Sie sicher, dass Sie löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  deletedSuccessfully: 'Angebot erfolgreich gelöscht',
  createInfo: 'Hier können Sie neue Angebote erstellen.',
  prefix: 'Präfix',
  timeZone: 'Zeitzone',
  prospectTargetEntityId: 'Interessent',
  prospectTargetEntityRef: 'Interessent-Ref',
  prospectType: 'Interessent-Typ',
  prospectName: 'Interessent-Name',
  prospectVAT: 'Interessent-USt-IdNr',
  prospectPhoneNumber: 'Interessent-Telefonnummer',
  prospectCountry: 'Interessent-Land',
  prospectState: 'Interessent-Bundesland',
  prospectCity: 'Interessent-Stadt',
  prospectZIP: 'Interessent-PLZ',
  prospectAddress: 'Interessent-Adresse',
  prospectContactFirstName: 'Interessent-Kontakt Vorname',
  prospectContactLastName: 'Interessent-Kontakt Nachname',
  prospectContactEmail: 'Interessent-Kontakt E-Mail',
  language: 'Sprache',
  date: 'Datum',
  expireDate: 'Ablaufdatum',
  sendEmailOnExpiration: 'E-Mail senden',
  expirationEmailSentDate: 'Ablauf-E-Mail gesendet am',
  projectOrServiceDesc: 'Projekt- oder Dienstleistungsbeschreibung',
  paymentTerms: 'Zahlungsbedingungen',
  products: 'Produkte',
  addProducts: 'Produkte hinzufügen',
  offers: 'Angebote',
  salesAgentIds: 'Vertriebsmitarbeiter',
  allowComments: 'Kommentare erlauben',
  legalNDAId: 'NDA',
  legalTermAndCondId: 'AGB',
  signerUserId: 'Unterzeichner',
  listInfo: 'Liste aller Angebote.',
  importedError: 'Importfehler',
  importedSuccessfully: 'Erfolgreich importiert',
  downloadFormError: 'Fehler beim Herunterladen des Formulars',
  downloadFormSuccessfully: 'Formular erfolgreich heruntergeladen',
  downloadExampleFormSuccessfully: 'Beispielformular erfolgreich heruntergeladen',
  downloadExampleFormError: 'Fehler beim Herunterladen des Beispielformulars',
  emptyState1: 'Keine Angebote gefunden.',
  emptyState2: 'Sie können sofort Angebote erstellen oder importieren.',
  createEstimate: 'Angebot erstellen',
  importEstimate: 'Angebot importieren',
  importTitle: 'Angebote importieren',
  downloadExample: 'Beispiel herunterladen',
  importText1: 'Sie können Angebote von dieser Seite importieren.',
  importText2: 'Sie können das Beispielformular unten verwenden, um Ihre Angebote zu veröffentlichen.',
  importExport: 'Import/Export',
  exportData: 'Daten exportieren',
  importData: 'Daten importieren',
  b2b: 'B2B',
  b2c: 'B2C',
  viewEstimate: 'Anzeigen',
  edit: 'Bearbeiten',
  junk: 'Ablegen',
  unJunk: 'Wiederherstellen',
  addNewRole: 'Neue Rolle hinzufügen',
  actions: 'Aktionen',
  legalAgreements: 'Rechtliche Vereinbarungen',
  businessName: 'Firmenname',
  governmentAgency: 'Behörde',
  fullName: 'Vollständiger Name',
  VAT: 'USt-IdNr',
  phoneNumber: 'Telefonnummer',
  country: 'Land',
  state: 'Bundesland',
  city: 'Stadt',
  ZIP: 'PLZ',
  sentDate: 'Gesendet am',
  name: 'Name',
  description: 'Beschreibung',
  productUnit: 'Einheit',
  priceType: 'Preistyp',
  service: 'Dienstleistung',
  product: 'Produkt',
  services: 'Dienstleistungen',
  references: 'Referenzen',
  add: 'Hinzufügen',
  salesAgent: 'Vertriebsmitarbeiter',
  estimateCreateSuccess: 'Angebot erfolgreich erstellt',

  addEstimate: 'Angebot hinzufügen',
  noEstimateContent1: 'Keine Angebote gefunden',
  noEstimate: 'Es gibt keine Angebote',
  generalInfo: 'Allgemeine Informationen',
  sentSuccessfully: 'Angebot erfolgreich gesendet',
  send: 'Angebot senden',
  resend: 'Angebot erneut senden',
  sendSureTitle: 'Angebot senden',
  sendSureDesc: 'Sind Sie sicher, dass Sie dieses Angebot senden möchten?',
  changeStatus: 'Status ändern',
  statusUpdatedSuccessfully: 'Status erfolgreich aktualisiert',

  // generalInfo
  generalInfoTitle: 'Allgemeine Informationen',
  estimateId: 'Angebot',
  status: 'Status',
  createdAt: 'Erstellt am',
  reference: 'Referenz',
  createdBy: 'Erstellt von',
  updatedAt: 'Aktualisiert am',
  notAvailable: 'Nicht verfügbar',
  updateStatus: 'Status aktualisieren',

  // customerInfo
  customerInfoTitle: 'Kundeninformationen',
  contactFirstName: 'Vorname',
  contactLastName: 'Nachname',
  contactEmail: 'E-Mail',
  contactPhoneNumber: 'Telefonnummer',
  address: 'Adresse',

  //productInfo
  price: 'Preis',
  productInfoTitle: 'Produktdetails',
  productNameUnknown: 'Unbekanntes Produkt',
  discountedPrice: 'Rabattierter Preis',
  quantity: 'Menge',
  finalPrice: 'Endpreis',
  tax: 'Steuersatz',
  noProducts: 'Keine Produkte verfügbar.',

  //legalInfo
  legalInfoTitle: 'Rechtliche Informationen',
  ndaTitle: 'Geheimhaltungsvereinbarung (NDA)',
  termsTitle: 'Allgemeine Geschäftsbedingungen',
  documentName: 'Dokumentname',
  documentDescription: 'Beschreibung',
  documentContent: 'Inhalt',

  //financialInfo
  financialInfoTitle: 'Finanzielle Informationen',
  currency: 'Währung',
  subTotal: 'Zwischensumme',
  discount: 'Rabatt',
  taxTotal: 'Steuern gesamt',
  shippingTotal: 'Versandkosten gesamt',
  total: 'Gesamtpreis',
  noFinancialData: 'Keine finanziellen Daten verfügbar.',

  // documentInfo
  documentsTitle: 'Dokumente',
  documentNameUnknown: 'Unbekanntes Dokument',
  mimeType: 'Dateityp',
  download: 'Herunterladen',
  noDocuments: 'Keine Dokumente verfügbar.',

  // salesAgents
  salesAgentsTitle: 'Vertriebsmitarbeiter',
  unknownAgent: 'Unbekannter Mitarbeiter',
  noSalesAgents: 'Keine Vertriebsmitarbeiter verfügbar.',

  //signature
  signatureTitle: 'Unterschriftsinformationen',
  additionalInfoTitle: 'Zusätzliche Informationen',
  signature: 'Unterschrift',
  signerUser: 'Unterzeichner',

  //additionalInfo
  scopeOfWork: 'Arbeitsumfang',
  assumptionsAndExclusions: 'Annahmen und Ausschlüsse',
  useLegalNDA: 'Rechtliche NDA verwenden',
  useLegalTermAndCond: 'AGB verwenden',
  yes: 'Ja',
  no: 'Nein',

  //tabs
  generalInformation: 'Allgemeine Informationen*',
  prospect: 'Interessent*',
  detailedInformation: 'Detaillierte Informationen',
  productsAndServices: 'Produkte und Dienstleistungen*',
  salesAgents: 'Vertriebsmitarbeiter',
  legal: 'Rechtliches',
  signatureTab: 'Unterschrift*',
};

export default locale; 
import React, { memo, useEffect, useState } from 'react';
import { Add, FileUpload } from '@mui/icons-material';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import { IBaseError, IGraphqlVariables, IHasId, Language, PermissionAction } from 'corede-common';
import {
  ILead,
  ILeadDetailResult,
  ILeadListInput,
  ILeadSourceResult,
  ILeadStatusResult,
  LeadBusinessType,
  LeadQualification,
  LeadType,
  PermissionSubject,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { importLeadRoute } from '../../../../routes/lead.base.route';
import { useLeadSourceListQuery } from '../../../leadSource';
import { LeadSourcesCreateDialog } from '../../../leadSource/pages';
import { useLeadStatusListQuery } from '../../../leadStatus';
import { LeadStatusCreateDialog } from '../../../leadStatus/pages';
import {
  useLeadListCountQuery,
  useLeadListQuery,
  useLeadUpdateMutation,
} from '../../context/lead.api';
import LeadDetailDrawerWrapper from '../detailDrawer/LeadDetailDrawer.wrapper';
import { LeadDeleteDialog } from '../LeadDeleteDialog';
import LeadUpdateDrawerWrapper from '../update/LeadUpdateWrapper';
import { handleDownloadLeadList } from '../../util/handleDownloadLeadList.action';
import { JunkActionButtonComponent } from './LeadList-grid-junkActionButton.component';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  useHandleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import { Iconify } from 'components';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import { BaseGridColumnDefFactory } from 'components/baseDataGrid/factories/base-grid-column.factory';

type TBaseLeadListFilter = {
  businessTypes: LeadBusinessType[] | undefined;
  isJunk: boolean;
};

export interface ILeadListGridComponent {
  // lead
  leadStatusList?: ILeadStatusResult[];
  leadSourceList?: ILeadSourceResult[];

  setOpenLeadCreateDrawer?: (value: boolean) => void | undefined;
}

// eslint-disable-next-line react/display-name
const LeadListGridComponent = memo((props: ILeadListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const navigate = useNavigate();

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.lead,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.lead,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.lead,
    action: PermissionAction.delete,
  });
  const hasLeadStatusCreatePermission = hasPermission({
    subject: PermissionSubject.leadStatus,
    action: PermissionAction.create,
  });
  const hasLeadSourceCreatePermission = hasPermission({
    subject: PermissionSubject.leadSource,
    action: PermissionAction.create,
  });

  // states
  const [selectedLead, setSelectedLead] = useState<TListGridSelectedEntity<ILead>>();
  const [openLeadDetailDrawer, setOpenLeadDetailDrawer] = useState(false);
  const [openLeadDeleteDialog, setOpenLeadDeleteDialog] = useState(false);
  const [openLeadUpdateDrawer, setOpenLeadUpdateDrawer] = useState(false);
  const [leadStatusCreateOpen, setLeadStatusCreateOpen] = useState(false);
  const [leadSourceCreateOpen, setLeadSourceCreateOpen] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [baseLeadListFilter, setBaseLeadListFilter] = useState<TBaseLeadListFilter | undefined>(
    undefined,
  );
  const [customLeadListFilter, setCustomLeadListFilter] = useState<
    IGraphqlVariables<ILeadListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const [leadListFilter, setLeadListFilter] = useState<IGraphqlVariables<ILeadListInput>>({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });
  const [isJunk, setIsJunk] = useState<boolean>(false);
  const [businessType, setBusinessType] = useState<LeadBusinessType | undefined>(undefined);

  const currentPage = leadListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = leadListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: leadListData,
    isLoading: leadListLoading,
    isFetching: leadListFetching,
    error: leadListError,
    refetch,
  } = useLeadListQuery(leadListFilter);

  const { data: leadListCountData } = useLeadListCountQuery({});

  const {
    data: leadStatusListDataFromApi,
    error: leadStatusListError,
  } = useLeadStatusListQuery(
    {},
    {
      skip: !!props.leadStatusList, // Skip the query if props data exists
    },
  );
  const leadStatusList: ILeadStatusResult[] | undefined =
    props.leadStatusList || leadStatusListDataFromApi?.data;

  const {
    data: leadSourceListDataFromApi,
    error: leadSourceListError,
  } = useLeadSourceListQuery(
    {},
    {
      skip: !!props.leadSourceList, // Skip the query if props data exists
    },
  );
  const leadSourceList: ILeadSourceResult[] | undefined =
    props.leadSourceList || leadSourceListDataFromApi?.data;

  // used for direct update in the list rows
  const [leadUpdate, { isLoading: leadUpdateLoading, error: leadUpdateError }] =
    useLeadUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setLeadListLoading && props.setLeadListLoading(leadListLoading);
  // }, [leadListLoading]);
  useEffect(() => {
    refetch();
    return () => {
    };
  }, [leadListFilter, refetch]);

  useEffect(() => {
    setBaseLeadListFilter({
      isJunk: isJunk,
      businessTypes: businessType ? [businessType] : undefined,
    });
    return () => {
    };
  }, [isJunk, businessType]);

  useEffect(() => {
    let isMounted = true;
    const filter: IGraphqlVariables<ILeadListInput, undefined> = {
      ...customLeadListFilter,
      input: {
        ...customLeadListFilter?.input,
        filter: {
          ...baseLeadListFilter,
          ...customLeadListFilter.input?.filter,
        },
        pagination: {
          ...customLeadListFilter.input?.pagination,
        },
      },
    };
    if (isMounted) {
      setLeadListFilter(filter);
    }
    return () => {
      isMounted = false;
    };
  }, [baseLeadListFilter, customLeadListFilter]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadStatusListError as IBaseError, currentLanguage);
    return () => {
    };
  }, [currentLanguage, leadStatusListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadSourceListError as IBaseError, currentLanguage);
    return () => {
    };
  }, [currentLanguage, leadSourceListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadListError as IBaseError, currentLanguage);
    return () => {
    };
  }, [currentLanguage, leadListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadUpdateError as IBaseError, currentLanguage);
  }, [currentLanguage, leadUpdateError]);

  const isEmptyState =
    leadListData?.data?.length === 0 &&
    !leadListLoading &&
    !leadListFetching &&
    leadListCountData?.count === 0;

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedLead}
      setSelectedEntity={setSelectedLead}
      listFilter={leadListFilter}
      setListFilter={setLeadListFilter}
      listData={leadListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
        {leadListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                leadListData?.data?.map((lead, index) => ({
                  id:
                    ((leadListFilter.input?.pagination?.page || 1) - 1) *
                      (leadListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: lead._id,
                  leadInfo: {
                    fullName: lead.fullName ?? '',
                    secondaryInfo: lead.email,
                    image: lead.image?.thumbnailPublicUrl ?? '',
                  },
                  fullName: lead.fullName || '-',
                  position: lead.position || '-',
                  email: lead.email || '-',
                  website: lead.website || '-',
                  phoneNumber: lead.phoneNumber || '-',
                  company: lead.company || '-',
                  sector: lead.sector || '-',
                  status: lead.status || null,
                  source: lead.source || null,
                  type: lead.type || '-',
                  qualification: lead.qualification || '-',
                  country: lead.country || '-',
                  state: lead.state || '-',
                  city: lead.city || '-',
                  language: lead.language || '-',
                  leadValue: lead.leadValue || '-',
                  organization: lead.organization._id || '-',
                  department: lead.department || '-',
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: 'center',
                    },
                    headerConfig: {
                      name: '#',
                      align: 'center',
                    },
                  },
                },
                BaseGridColumnDefFactory.EntityProfileColumnDef({
                  field: 'leadInfo',
                  headerName: `${t('crm.name')}`,
                }),
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'fullName',
                      filterable: true,
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.fullName'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'position',
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.position'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'email',
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.email'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'phoneNumber',
                      filterable: false,
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.phone'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.object,
                    column: {
                      field: 'status',
                      editable: hasUpdatePermission,
                      width: 150,
                    },
                    headerConfig: {
                      name: t('crm.status'),
                      creatable: hasUpdatePermission && hasLeadStatusCreatePermission,
                      createFunc: setLeadStatusCreateOpen,
                    },
                    filterConfig: {
                      customFieldName: `statusIds`,
                      isArrayFilter: true,
                    },
                    valueOptionFields: leadStatusList,
                    //   changed: ["valueOptions", "cell", "editCell", "header"],
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.object,
                    column: {
                      field: 'source',
                      editable: hasUpdatePermission,
                      width: 150,
                    },
                    headerConfig: {
                      name: t('crm.source'),
                      creatable: hasUpdatePermission && hasLeadSourceCreatePermission,
                      createFunc: setLeadSourceCreateOpen,
                    },
                    filterConfig: {
                      customFieldName: `sourceIds`,
                      isArrayFilter: true,
                    },
                    valueOptionFields: leadSourceList,
                  },
                  //   changed: ["valueOptions", "cell", "editCell", "header"],
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'website',
                      width: 140,
                      filterable: false,
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.website'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'company',
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.company'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'sector',
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      icon: 'ic:round-business',
                      name: t('crm.sector'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'type',
                      editable: hasUpdatePermission,
                      width: 90,
                    },
                    headerConfig: {
                      name: t('crm.type'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `types`,
                      isArrayFilter: true,
                    },
                  },
                  data: LeadType,
                  //   changed: ["valueOptions", "cell", "editCell"],
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'qualification',
                      editable: hasUpdatePermission,
                      width: 150,
                    },
                    headerConfig: {
                      name: t('crm.lead.lead.qualification'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `qualifications`,
                      isArrayFilter: true,
                    },
                  },
                  data: LeadQualification,
                  //   changed: ["valueOptions", "cell", "editCell"],
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'country',
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.country'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'state',
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.state'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'city',
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.city'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'language',
                      width: 100,
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.language'),
                    },
                    filterConfig: {
                      customFieldName: `languages`,
                      isArrayFilter: true,
                    },
                  },
                  data: Language,
                  //   changed: ["valueOptions", "cell"],
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'leadValue',
                      width: 150,
                      filterable: false,
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.lead.lead.leadValue'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'department',
                      width: 150,
                      editable: false,
                    },
                    headerConfig: {
                      name: t('crm.department'),
                    },
                    filterConfig: {
                      customFieldName: `departmentIds`,
                      isArrayFilter: true,
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: hasDetailPermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedLead,
                          setOpenAction: setOpenLeadDetailDrawer,
                        },
                      }
                    : undefined,
                  edit: hasUpdatePermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedLead,
                          setOpenAction: setOpenLeadUpdateDrawer,
                        },
                      }
                    : undefined,
                  delete: hasDeletePermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedLead,
                          setOpenAction: setOpenLeadDeleteDialog,
                        },
                      }
                    : undefined,
                },
                customCellItems: hasUpdatePermission
                  ? [
                      JunkActionButtonComponent({
                        isJunk,
                        leadUpdate,
                      }),
                    ]
                  : [],
                actionHeaderName: t('crm.actions'),
              }}
              loading={leadListLoading || leadUpdateLoading || leadListFetching}
              listFilter={{
                filterInput: customLeadListFilter,
                setFilterInput: setCustomLeadListFilter,
              }}
              update={{
                updateQuery: leadUpdate,
              }}
              count={leadListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  fullName: false,
                  website: false,
                  company: false,
                  sector: false,
                  country: false,
                  state: false,
                  city: false,
                  language: false,
                  leadValue: false,
                  department: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [
                  // eslint-disable-next-line react/jsx-key
                  <Box display="flex" alignItems="center">
                    <ToggleButtonGroup
                      value={businessType}
                      id="businessType"
                      size="small"
                      exclusive
                      color="secondary"
                      onChange={(_, value) => setBusinessType && setBusinessType(value)}
                    >
                      <ToggleButton value={LeadBusinessType.b2b}>
                        <Typography fontWeight={'bold'} fontSize={'12px'}>
                          {t('crm.lead.lead.b2b')}
                        </Typography>
                      </ToggleButton>
                      <ToggleButton value={LeadBusinessType.b2c}>
                        <Typography fontWeight={'bold'} fontSize={'12px'}>
                          {t('crm.lead.lead.b2c')}
                        </Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>,

                  // eslint-disable-next-line react/jsx-key
                  <Box display="flex" alignItems="center">
                    <ToggleButtonGroup
                      value={!isJunk}
                      id="isJunk"
                      color="secondary"
                      size="small"
                      exclusive
                      onChange={() => setIsJunk && setIsJunk(!isJunk)}
                    >
                      <ToggleButton value={false}>
                        <Iconify icon={'tabler:trash'} width={16} sx={{ mr: 0.5 }} />
                        <Typography fontWeight={'bold'} fontSize={'12px'}>
                          {t('crm.lead.lead.isJunk')}
                        </Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>,

                  // eslint-disable-next-line react/jsx-key
                  <PermissionWrapper
                    check={{ subject: PermissionSubject.lead, action: PermissionAction.export }}
                  >
                    <Box display="flex" alignItems="center" ml={'auto'}>
                      <ToggleButtonGroup id="export" color="secondary" size="small" exclusive>
                        <ToggleButton
                          value={false}
                          onClick={() =>
                            handleDownloadLeadList({
                              currentLanguage: currentLanguage,
                              filter: leadListFilter?.input?.filter,
                            })
                          }
                        >
                          <Iconify icon={'uil:export'} width={14} sx={{ mr: 0.5 }} />
                          <Typography fontWeight={'bold'} fontSize={'12px'} color={'inherit'}>
                            {t('crm.export')}
                          </Typography>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </PermissionWrapper>,
                ],
              }}
              customStyle={
                {
                  // '& .MuiDataGrid-columnHeader': {
                  //   background: isJunk ? '#F6E8D6' : null,
                  // },
                  // '& .MuiDataGrid-filler--pinnedRight': {
                  //   background: isJunk ? '#F6E8D6' : null,
                  // },
                  // '& .MuiDataGrid-filler--pinnedLeft': {
                  //   background: isJunk ? '#F6E8D6' : null,
                  // },
                  // '& .MuiDataGrid-cell--pinnedRight': {
                  //   background: isJunk ? '#F6E8D6' : null,
                  // },
                  // '& .MuiDataGrid-cell--pinnedLeft': {
                  //   background: isJunk ? '#F6E8D6' : null,
                  // },
                }
              }
            />
          ) : (
            <EmptyState
              content1={t('crm.lead.lead.emptyState1')}
              content2={t('crm.lead.lead.emptyState2')}
              button1={{
                title: t('crm.lead.lead.create'),
                onClick: () => (props?.setOpenLeadCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
                permission: {
                  subject: PermissionSubject.lead,
                  action: PermissionAction.create,
                },
              }}
              button2={{
                title: t('crm.lead.lead.importLead'),
                onClick: () => navigate(importLeadRoute()),
                leftIcon: <FileUpload sx={{ mr: 1 }} fontSize="small" />,
                permission: {
                  subject: PermissionSubject.lead,
                  action: PermissionAction.import,
                },
              }}
            />
          )
        ) : null}
    
      <Box sx={{ width: '100%', height: '60px' }} />

      <PermissionWrapper
        check={{
          subject: PermissionSubject.lead,
          action: PermissionAction.detail,
        }}
      >
        <LeadDetailDrawerWrapper
          open={openLeadDetailDrawer}
          setOpen={setOpenLeadDetailDrawer}
          selectedLead={selectedLead as ILeadDetailResult & IHasId<number>}
          sideNavigationProps={{
            count: leadListData?.count ?? 0,
            handleNavigate: useHandleSideNavigationNavigate({
              currentPage,
              currentPageSize,
              listData: leadListData,
              listFilter: leadListFilter,
              setListFilter: setLeadListFilter,
              selectedEntity: selectedLead,
              setSelectedEntity: setSelectedLead,
              setPendingNavigation: setPendingNavigation,
            }),
          }}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.lead,
          action: PermissionAction.update,
        }}
      >
        <LeadUpdateDrawerWrapper
          open={openLeadUpdateDrawer}
          setOpen={setOpenLeadUpdateDrawer}
          selectedLead={selectedLead}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.lead,
          action: PermissionAction.delete,
        }}
      >
        <LeadDeleteDialog
          open={openLeadDeleteDialog}
          leadId={selectedLead?._id ?? ''}
          setLead={setSelectedLead}
          onClose={() => {
            setSelectedLead(undefined);
            setOpenLeadDeleteDialog(false);
          }}
          key={selectedLead?._id}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.leadStatus,
          action: PermissionAction.create,
        }}
      >
        <LeadStatusCreateDialog open={leadStatusCreateOpen} setOpen={setLeadStatusCreateOpen} />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.leadSource,
          action: PermissionAction.create,
        }}
      >
        <LeadSourcesCreateDialog open={leadSourceCreateOpen} setOpen={setLeadSourceCreateOpen} />
      </PermissionWrapper>
    </DefaultListGridComponent>
  );
});

export default LeadListGridComponent;

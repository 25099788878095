const locale = {
  supports: 'Tickets',
  support: 'Ticket',
  detail: 'Ticket-Details',
  subject: 'Betreff',
  title: 'Tickets',
  create: 'Ticket erstellen',
  createSuccess: 'Ticket erfolgreich erstellt.',
  update: 'Ticket aktualisieren',
  updateSuccess: 'Ticket erfolgreich aktualisiert.',
  delete: 'Ticket löschen',
  deleteSuccess: 'Ticket erfolgreich gelöscht.',
  deleteContent: 'Sind Sie sicher, dass Sie das Ticket löschen möchten?',
  emptyState1: 'Noch keine Tickets.',
  emptyState2:
    'Sie haben noch keine Tickets erstellt oder sie wurden bereits gelöst. Sobald Ihr Team eine Support-Anfrage stellt, werden alle Tickets hier angezeigt.',

  // detailDrawer
  generalInfoTitle: 'Allgemeine Informationen',
  ticketInfoTitle: 'Ticket-Informationen',
  closedDate: 'Schließungsdatum',
  changeStatus: 'Status ändern',
  statusChangeSuccess: 'Status erfolgreich geändert.',
};

export default locale; 
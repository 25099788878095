/* eslint-disable react-hooks/exhaustive-deps */
import { AssignmentReturnOutlined, DeleteOutlineRounded, ReplyAll } from '@mui/icons-material';
import { MenuItem, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useResendRegistrationByUserConfirmationMutation } from 'apps/auth/context';
import { IBaseError, UserConfirmationStatus } from 'corede-common';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';

export function ResendConfirmationButtonComponent(renderCellParams: GridRenderCellParams) {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [
    resendRegisttrationByUserConfirmation,
    { data: resendData, isLoading: resendLoading, error: resendError },
  ] = useResendRegistrationByUserConfirmationMutation();

  useEffect(() => {
    if (resendData) {
      enqueueSnackbar(t('crm.organizationalChart.user.resendSuccess'), { variant: 'success' });
    }
  }, [resendData]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(resendError as IBaseError, currentLanguage);
  }, [resendError, currentLanguage]);

  if (renderCellParams.row.status !== UserConfirmationStatus.pending) {
    return null;
  }

  return (
    <MenuItem
      onClick={() => {
        resendRegisttrationByUserConfirmation({
          input: { email: renderCellParams.row.email },
        });
      }}
      disabled={resendLoading}
    >
      <Stack direction={'row'} width={'100%'} justifyContent={'flex-start'} alignItems={'center'}>
        <ReplyAll color="warning" fontSize="small" />
        <Typography fontSize={'12px'} fontWeight={'bold'} ml={1}>
          {t('crm.organizationalChart.user.resendConfirmation')}
        </Typography>
      </Stack>
    </MenuItem>
  );
}

const locale = {
  returns: 'Devoluções',
  return: 'Devolução',
  returnConversions: 'Conversões de devolução',
  title: 'Devoluções',
  create: 'Adicionar devolução',
  update: 'Atualizar devolução',
  delete: 'Excluir devolução',
  createSuccess: 'Devolução adicionada com sucesso.',
  updateSuccess: 'Devolução atualizada com sucesso.',
  deleteSuccess: 'Devolução excluída com sucesso.',
  deleteConfirm: 'Tem certeza de que deseja excluir esta devolução? Esta ação não pode ser desfeita.',
  emptyState1: 'Nenhuma devolução registrada ainda.',
  emptyState2:
    'Gerencie e revise aqui quaisquer devoluções de produtos ou serviços para manter seus registros precisos e atender às necessidades dos clientes.',

  // detailDrawer
  generalInfoTitle: 'Informações gerais',
  isOpen: 'Está aberto',
  refundAmount: 'Valor do reembolso',
  resolveReason: 'Motivo da resolução',
  disapproveReason: 'Motivo da desaprovação',
  returnProducts: 'Produtos devolvidos',
  amount: 'Valor',
  reason: 'Motivo',
  isPartial: 'É parcial',
  note: 'Nota',
  statusChangeSuccess: 'Status alterado com sucesso',
  changeStatus: 'Alterar status',
};

export default locale; 
const locale = {
  supports: 'Тикеты',
  support: 'Тикет',
  detail: 'Детали тикета',
  subject: 'Тема',
  title: 'Тикеты',
  create: 'Создать тикет',
  createSuccess: 'Тикет успешно создан.',
  update: 'Обновить тикет',
  updateSuccess: 'Тикет успешно обновлен.',
  delete: 'Удалить тикет',
  deleteSuccess: 'Тикет успешно удален.',
  deleteContent: 'Вы уверены, что хотите удалить тикет?',
  emptyState1: 'Пока нет тикетов.',
  emptyState2:
    'Вы еще не создали тикеты, или они уже были решены. Как только ваша команда отправит запрос в службу поддержки, все тикеты появятся здесь.',

  // detailDrawer
  generalInfoTitle: 'Общая информация',
  ticketInfoTitle: 'Информация о тикете',
  closedDate: 'Дата закрытия',
  changeStatus: 'Изменить статус',
  statusChangeSuccess: 'Статус успешно изменен.',
};

export default locale; 
const locale = {
  productCategories: 'Categorias',
  title: 'Categorias de Produtos/Serviços',
  create: 'Criar Categoria',
  delete: 'Excluir Categoria',
  deleteConfirm: 'Tem certeza que deseja excluir esta categoria: ',
  deletedSuccessfully: 'Categoria excluída com sucesso',
  createCategory: 'Criar Categoria',
  categoryCreateSuccess: 'Categoria criada com sucesso',
  name: 'Nome da Categoria',
  icon: 'Ícone da Categoria',
  showMore: 'Mostrar Mais',
  update: 'Atualizar',
  updateCategory: 'Atualizar Categoria',
  categoryUpdateSuccess: 'Categoria atualizada com sucesso',
  en: 'Inglês',
  tr: 'Turco',
  createSuccess: 'Categoria criada com sucesso',
  updateSuccess: 'Categoria de produto atualizada com sucesso',
  actions: 'Ações',
  createdAt: 'Criado em',
  emptyState1: 'Nenhuma categoria encontrada',
  emptyState2: 'Por favor, crie uma nova categoria',
  createProductCategory: 'Criar Categoria de Produto',
};

export default locale; 
const locale = {
  contracts: 'Verträge',
  contract: 'Vertrag',
  contractConversions: 'Vertragsumwandlungen',
  title: 'Verträge',
  create: 'Vertrag erstellen',
  update: 'Vertrag aktualisieren',
  delete: 'Vertrag löschen',
  createSuccess: 'Vertrag erfolgreich erstellt.',
  updateSuccess: 'Vertrag erfolgreich aktualisiert.',
  deleteSuccess: 'Vertrag erfolgreich gelöscht.',
  deleteConfirm: 'Sind Sie sicher, dass Sie diesen Vertrag löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  emptyState1: 'Noch keine Verträge hinzugefügt.',
  emptyState2:
    'Speichern und verwalten Sie hier alle Vertragsdetails, um Ihre Rechnungsstellung zu optimieren und klare Vereinbarungen mit Ihren Kunden zu treffen.',

  // labels
  contractTemplateLabel: 'Vertragsvorlage',
  contractValue: 'Vertragswert',
  contractType: 'Vertragstyp',
  signedDate: 'Unterzeichnungsdatum',
  signedUser: 'Unterzeichnender Benutzer',
  signStatus: 'Unterzeichnungsstatus',

  //detail drawer
  changeStatus: 'Status ändern',
  statusChangeSuccess: 'Status erfolgreich geändert.',
  generalInfoTitle: 'Allgemeine Informationen',
  customerInfoTitle: 'Kundeninformationen',
  projectInfoTitle: 'Projektinformationen',
  contractRenew: 'Vertrag erneuern',
  renew: 'Vertrag erneuern',
  renewSuccess: 'Vertrag erfolgreich erneuert.',
  emptyStateRenew: 'Keine Verträge zur Erneuerung.',
  renewHistory: 'Erneuerungsverlauf',
};

export default locale; 
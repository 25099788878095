const locale = {
  organization: 'Organização',
  organizations: 'Organizações',
  organizationChart: 'Organograma',
  update: 'Atualizar',
  delete: 'Excluir',
  view: 'Visualizar',
  createdSuccessfully: 'Usuário criado com sucesso',
  updatedSuccessfully: 'Usuário atualizado com sucesso',
  deleteConfirm: 'Tem certeza de que deseja remover a conta de usuário "',
  deletedSuccessfully: 'Usuário excluído com sucesso',
  info: 'Você pode adicionar usuários à sua equipe nesta seção.',
  name: 'Nome',
  email: 'E-mail',
  surname: 'Sobrenome',
  viewProfile: 'Ver perfil',
  roles: 'Funções',
  editChart: 'Editar organograma',
  save: 'Salvar',
  editOrganizationChart: 'Editar organograma',
  editOrganizationDetail: 'Arraste e solte os usuários para o local desejado.',
  createRole: 'Criar função',
  userList: 'Lista de usuários',
  rolesInfo: 'Você pode criar funções e atribuir permissões a elas.',
  createUserSuccess: 'Usuário criado com sucesso',
  chart: 'Visualização do organograma',
  list: 'Visualização em lista',
  users: 'Usuários',
  user: 'Usuário',
  activeUsers: 'Usuários ativos',
  activeUser: 'Usuário ativo',
  create: 'Criar',
  new: 'Novo',
  roleName: 'Nome da função',
  addRole: 'Adicionar',
  updateRole: 'Atualizar',
  deleteRole: 'Excluir',
  roleCreatedSuccess: 'Função criada com sucesso',
  roleUpdatedSuccess: 'Função atualizada com sucesso',
  roleDeletedSuccess: 'Função excluída com sucesso',
  errorFetchingPermissions: 'Erro ao buscar permissões',
  errorFetchingRoles: 'Erro ao buscar funções',
  errorCreatingRole: 'Erro ao criar função',
  errorUpdatingRole: 'Erro ao atualizar função',
  errorDeletingRole: 'Erro ao excluir função',
  permissionUpdateSuccess: 'As permissões do usuário foram atualizadas com sucesso',
  subject: 'Assunto',
  capabilities: 'Capacidades',
  allPermissions: 'Todas as permissões',
  all: 'Todos',
  advancedOptions: 'Opções avançadas',
  id: 'ID',
  emailAddress: 'Endereço de e-mail',
  status: 'Status',
  emailVerified: 'E-mail verificado',
  phoneNumber: 'Número de telefone',
  country: 'País',
  city: 'Cidade',
  phone: 'Número de telefone',
  lastLoginDate: 'Data do último login',
  createdAt: 'Criado em',
  actions: 'Ações',
  addNewRole: 'Adicionar nova função',
  yes: 'Sim',
  no: 'Não',
  details: 'Detalhes',
  jobTitle: 'Cargo',
  deleteMessage: 'Tem certeza de que deseja excluir este usuário?',
  cancel: 'Cancelar',
  userDeleteSuccess: 'Usuário excluído com sucesso',
  userDeleteError: 'Falha ao excluir usuário',
  updateChartSuccessfully: 'Organograma atualizado com sucesso',
  address: 'Endereço',
  birthDate: 'Data de nascimento',
  gender: 'Gênero',
  language: 'Idioma',
  description: 'Descrição',
  rolesAndPermissions: 'Funções e permissões',
  ownPermissionsAlert: 'Você não pode alterar suas próprias permissões.',
  zoom: 'Zoom',
  tree: 'Visualização em árvore',
  createSuccess: 'Organograma criado com sucesso',
  updateSuccess: 'Organograma atualizado com sucesso',
  updateError: 'Erro ao atualizar o organograma',
  fetchError: 'Erro ao buscar o organograma',
  fetchSuccess: 'Organograma buscado com sucesso',
  undefinedUser: 'Usuário não definido',
  chartUpdateSuccess: 'Organograma atualizado com sucesso!',
  listView: 'Visualização em lista',
  action: 'Ação',
  actionScope: 'Escopo da ação',
  userDetail: 'Detalhe do usuário',
  admin: 'Administrador',
  department: 'Departamento',
  addUser: 'Adicionar usuário',
  createDepartment: 'Criar departamento',
  updateUser: 'Atualizar usuário',
  notDeleteOwnConfirm: 'Você não pode excluir a si mesmo.',
  updateUserSuccess: 'Usuário atualizado com sucesso',
  removeAccount: 'Remover conta',
  profile: 'Perfil',
  permissions: 'Permissões',
  associatedLeads: 'Leads associados',
  associatedCustomers: 'Clientes associados',
  associatedEstimates: 'Orçamentos associados',
  associatedProposals: 'Propostas associadas',
  associatedInvoices: 'Faturas associadas',
  associatedTasks: 'Tarefas associadas',
  about: 'Sobre',
  detail: 'Detalhe do usuário',
  users_: 'usuários',
  resendConfirmation: 'Reenviar confirmação',
  resendSuccess: 'Confirmação enviada com sucesso',
};

export default locale; 
const locale = {
  title: 'Teklifler',
  create: 'Teklif Oluştur',

  // common
  proposals: 'Teklifler',

  // fields
  prospectName: 'Müşteri Adı: ',

  // alert
  createdSuccessfully: 'Teklif başarıyla oluşturuldu.',
  updatedSuccessfully: 'Teklif başarıyla güncellendi.',
  
  noProposalContent1: 'Herhangi bir teklif bulunamadı.',
  noProposal: 'Henüz bir teklif eklenmedi.',
  noProposalSend: 'Henüz bir teklif gönderilmedi.',

  generalInfo: 'Genel Bilgiler',
  sentSuccessfully: 'Teklif gönderildi',
  send: 'Teklif Gönder',
  resend: 'Yeniden Gönder',
  sendSureTitle: 'Teklifi Gönder',
  sendSureDesc: 'Teklifi göndermek istediğinizden emin misiniz?',
  changeStatus: 'Durumu Değiştir',
  statusUpdatedSuccessfully: 'Durum başarıyla güncellendi',

  // generalInfo
  generalInfoTitle: 'Genel Bilgiler',
  status: 'Durum',
  createdAt: 'Oluşturulma Tarihi',
  reference: 'Referans',
  createdBy: 'Oluşturan',
  updatedAt: 'Güncellenme Tarihi',
  notAvailable: 'Mevcut değil',
  updateStatus: 'Durumu Güncelle',

  // customerInfo
  customerInfoTitle: 'Müşteri Bilgileri',
  contactFirstName: 'İsim',
  contactLastName: 'Soyisim',
  contactEmail: 'E-posta',
  contactPhoneNumber: 'Telefon',
  address: 'Adres',

  //productInfo
  productInfoTitle: 'Ürün Bilgileri',
  productNameUnknown: 'Bilinmeyen Ürün',
  price: 'Birim Fiyat',
  discountedPrice: 'İndirimli Fiyat',
  quantity: 'Miktar',
  finalPrice: 'Toplam Fiyat',
  tax: 'Vergi Oranı',
  noProducts: 'Ürün bulunmamaktadır.',

  //legalInfo
  legalInfoTitle: 'Yasal ve Hukuki Bilgiler',
  ndaTitle: 'Gizlilik Sözleşmesi (NDA)',
  termsTitle: 'Şartlar ve Koşullar',
  documentName: 'Belge Adı',
  documentDescription: 'Açıklama',
  documentContent: 'İçerik',

  //financialInfo
  financialInfoTitle: 'Finansal Bilgiler',
  currency: 'Para Birimi',
  subTotal: 'Ara Toplam',
  discount: 'İndirim',
  taxTotal: 'Vergi Toplamı',
  shippingTotal: 'Kargo Toplamı',
  total: 'Toplam Fiyat',
  noFinancialData: 'Finansal bilgi bulunmamaktadır.',

  //documentInfo
  documentsTitle: 'Belgeler',
  documentNameUnknown: 'Bilinmeyen Belge',
  mimeType: 'Dosya Türü',
  download: 'İndir',
  noDocuments: 'Belge bulunmamaktadır.',

  //salesAgents
  salesAgentsTitle: 'Satış Temsilcileri',
  unknownAgent: 'Bilinmeyen',
  noSalesAgents: 'Satış temsilcisi bulunmamaktadır.',

  //signature
  signatureTitle: 'İmza Bilgileri',
  additionalInfoTitle: 'Ek Bilgiler',
  signature: 'İmza',
  signerUser: 'İmzalayan Kullanıcı',

  //additionalInfo
  scopeOfWork: 'İş Kapsamı',
  assumptionsAndExclusions: 'Varsayımlar ve Hariçler',
  useLegalNDA: 'Gizlilik Sözleşmesi Kullanımı',
  useLegalTermAndCond: 'Şartlar ve Koşullar Kullanımı',
  yes: 'Evet',
  no: 'Hayır',

  // emptyState
  emptyState1: "Teklif bulunamadı.",
  emptyState2: 'Hemen aşağıdaki butonu kullanarak teklif oluşturabilirsiniz.',
};

export default locale;

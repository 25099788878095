const locale = {
  reports: "Relatórios",
  report: "Relatório",
  reportConversions: "Conversões de Relatórios",
  title: "Relatórios",
  addReport: "Adicionar Relatório",
  emptyStateDesc1: "Ainda não há relatórios disponíveis.",
  emptyStateDesc2: "Gere e visualize aqui insights detalhados para acompanhar suas atividades de CRM e tomar decisões baseadas em dados."
};

export default locale; 
const locale = {
  leadForms: 'Lead-Formulare',
  title: 'Lead-Formulare',
  newForm: 'Neues Formular',
  emptyStateDesc1:
    'Ihre Lead-Formulare werden hier angezeigt, wenn Sie sie erstellen, um wertvolle Kundeninformationen zu erfassen.',
  emptyStateDesc2:
    'Verwalten und passen Sie Ihre Formulare einfach an, um sicherzustellen, dass Sie die richtigen Details für jede Interaktion sammeln.',

  create: 'Lead-Formular erstellen',
  update: 'Lead-Formular aktualisieren',
  updateSuccess: 'Lead-Formular erfolgreich aktualisiert',
  createSuccess: 'Lead-Formular erfolgreich erstellt',
  deleteSuccess: 'Lead-Formular erfolgreich gelöscht',
  deleteConfirm: 'Sind Sie sicher, dass Sie dieses Lead-Formular löschen möchten?',
  deletedSuccessfully: 'Lead-Formular erfolgreich gelöscht',

  // tabs
  detailedInfo: 'Detaillierte Informationen',
  branding: 'Branding',
  formFields: 'Formularfelder',
  submission: 'Einreichung*',
  notification: 'Benachrichtigung',

  //fields
  addField: 'Feld hinzufügen',
  updateField: 'Feld aktualisieren',
  label: 'Bezeichnung',
  formName: 'Formularname',
  defaultLanguage: 'Standardsprache',
  defaultPrefix: 'Standardpräfix',
  defaultStatus: 'Standardstatus',
  defaultSource: 'Standardquelle',
  defaultAssigned: 'Standardmäßig zugewiesen',
  defaultBusinessType: 'Standard-Geschäftstyp',
  defaultType: 'Standardtyp',
  defaultQualification: 'Standardqualifikation',
  defaultTags: 'Standard-Tags',
  submitButtonText: 'Text der Senden-Schaltfläche',
  header: 'Kopfzeile',
  isRequired: 'Erforderlich',
  type: 'Typ',
  defaultIsPublic: 'Standardmäßig öffentlich',
  followUpRedirectUrl: 'Weiterleitungs-URL für Nachverfolgung',
  followUpMessage: 'Nachverfolgungsnachricht',
  specificUsersToNotify: 'Bestimmte zu benachrichtigende Benutzer',
  willNotify: 'Wird benachrichtigen',
  willNotifyResponsibleUsers: 'Wird verantwortliche Benutzer benachrichtigen',
  leadField: 'Lead-Feld',
  placeholder: 'Platzhalter',
  fullNamePlaceholder: 'Geben Sie Ihren vollständigen Namen ein',
  emailPlaceholder: 'Geben Sie Ihre E-Mail-Adresse ein',
  formTitle: 'Formular',
};

export default locale; 
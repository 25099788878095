const locale = {
  invoice: "Facture",
  invoices: "Factures",
  create: "Créer une Facture",
  update: "Mettre à jour la Facture",
  delete: "Supprimer",
  view: "Voir la Facture",
  list: "Liste des Factures",
  createdSuccessfully: "Facture créée avec succès",
  updatedSuccessfully: "Facture mise à jour avec succès",
  deleteConfirm:
    "Êtes-vous sûr de vouloir supprimer ? Cette action ne peut pas être annulée.",
  deletedSuccessfully: "Facture supprimée avec succès",
  createInfo: "Vous pouvez créer de nouvelles factures ici.",
  prefix: "Préfixe",
  invoiceId: "Facture",
  timeZone: "Fuseau Horaire",
  customerId: "Client",
  customerName: "Nom du Client",
  customerType: "Type de Client",
  customerCity: "Ville du Client",
  customerCountry: "Pays du Client",
  customerContactEmail: "Email du Client",
  customerContactLastName: "Nom de Famille du Client",
  customerContactFirstName: "Prénom du Client",
  billingInfo: "Informations de Facturation",
  ["billingInfo.fullName"]: "Nom Complet",
  ["billingInfo.phone"]: "Téléphone",
  ["billingInfo.address"]: "Adresse",
  ["billingInfo.country"]: "Pays",
  ["billingInfo.state"]: "État/Région",
  ["billingInfo.city"]: "Ville",
  ["billingInfo.street"]: "Rue",
  ["billingInfo.zip"]: "Code Postal",
  ["billingInfo.vat"]: "TVA",
  ["billingInfo.taxOffice"]: "Bureau des Impôts",
  shippingInfo: "Informations de Livraison",
  ["shippingInfo.fullName"]: "Nom Complet",
  ["shippingInfo.phone"]: "Téléphone",
  ["shippingInfo.address"]: "Adresse",
  ["shippingInfo.country"]: "Pays",
  ["shippingInfo.state"]: "État/Région",
  ["shippingInfo.city"]: "Ville",
  ["shippingInfo.street"]: "Rue",
  ["shippingInfo.zip"]: "Code Postal",
  generalInformation: "Informations Générales*",
  prospect: "Prospect*",
  detailedInformation: "Informations Détaillées",
  billingInformation: "Informations de Facturation*",
  shippingInformation: "Informations de Livraison",
  productsAndServices: "Produits et Services*",
  salesAgents: "Agents Commerciaux",
  legal: "Juridique",
  signatureTab: "Signature*",
  isRecurring: "Est Récurrent",
  recurringFrequency: "Fréquence de Récurrence",
  recurringDueDate: "Date d'Échéance Récurrente",
  recurringCount: "Nombre de Récurrences",
  language: "Langue",
  date: "Date",
  expireDate: "Date d'Expiration",
  sendEmailOnExpiration: "Envoyer un Email à l'Expiration",
  expirationEmailSentDate: "Date d'Envoi de l'Email d'Expiration",
  allowedPaymentMethods: "Méthodes de Paiement Autorisées",
  paymentTerms: "Conditions de Paiement",
  currency: "Devise",
  products: "Produits",
  addProducts: "Ajouter des Produits",
  offers: "Offres",
  reference: "Référence",
  salesAgentIds: "Agents Commerciaux",
  allowComments: "Autoriser les Commentaires",
  useLegalNDA: "Utiliser un Accord de Confidentialité",
  legalNDAId: "Accord de Confidentialité",
  useLegalTermAndCond: "Utiliser les Termes et Conditions",
  legalTermAndCondId: "Termes et Conditions",
  signerUserId: "Utilisateur Signataire",
  listInfo: "Liste de toutes les factures.",
  importedError: "Erreur d'importation",
  importedSuccessfully: "Importé avec succès",
  downloadFormError: "Erreur de téléchargement du formulaire",
  downloadFormSuccessfully: "Formulaire téléchargé avec succès",
  downloadExampleFormSuccessfully: "Formulaire d'exemple téléchargé avec succès",
  downloadExampleFormError: "Erreur de téléchargement du formulaire d'exemple",
  title: "Factures",
  emptyState1: "Aucune facture trouvée ici.",
  emptyState2: "Vous pouvez créer ou importer des factures immédiatement.",
  createInvoice: "Créer une Facture",
  importInvoice: "Importer une Facture",
  importTitle: "Importer des Factures",
  downloadExample: "Télécharger un Exemple",
  importText1: "Vous pouvez importer des factures depuis cette page.",
  importText2: "Vous pouvez utiliser le formulaire d'exemple ci-dessous pour publier vos factures.",
  importExport: "Importer/Exporter",
  exportData: "Exporter les Données",
  importData: "Importer les Données",
  b2b: "B2B",
  b2c: "B2C",
  viewInvoice: "Voir",
  edit: "Modifier",
  junk: "Indésirable",
  unJunk: "Récupérer",
  addNewRole: "Ajouter un Nouveau Rôle",
  actions: "Actions",
  allowedPaymentMethodsPlaceholder: "Ajouter une Méthode de Paiement",
  timezone: "Fuseau Horaire",
  followUp: "Suivi",
  lastSent: "Dernier Envoi",
  dueDate: "Date d'Échéance",
  sendEmail: "Envoyer un Email",
  customer: "Client",
  country: "Pays",
  city: "Ville",
  contactFirstName: "Prénom du Contact",
  contactLastName: "Nom de Famille du Contact",
  contactEmail: "Email du Contact",
  services: "Services",
  businessName: "Nom de l'Entreprise",
  governmentAgency: "Agence Gouvernementale",
  fullName: "Nom Complet",
  VAT: "TVA",
  phoneNumber: "Numéro de Téléphone",
  state: "État/Région",
  ZIP: "Code Postal",
  address: "Adresse",
  sentDate: "Date d'Envoi",
  name: "Nom",
  description: "Description",
  productUnit: "Unité",
  quantity: "Quantité",
  price: "Prix",
  priceType: "Type de Prix",
  discountedPrice: "Prix Réduit",
  finalPrice: "Prix Final",
  tax: "Taxe",
  service: "Service",
  subTotal: "Sous-Total",
  taxTotal: "Total des Taxes",
  discount: "Remise",
  product: "Produit",
  references: "Références",
  add: "Ajouter",
  salesAgent: "Agent Commercial",
  executiveSummary: "Résumé Exécutif",
  companyBackground: "Contexte de l'Entreprise",
  projectorServiceDesc: "Description Détaillée du Projet/Service",
  costBreakdown: "Ventilation des Coûts",
  projectTimeline: "Calendrier du Projet",
  testimonialCaseStudies: "Témoignages et Études de Cas",
  conclusion: "Conclusion",

  sentSuccessfully: 'Facture envoyée avec succès',
  send: 'Envoyer la Facture',
  resend: 'Renvoyer la Facture',
  sendSureTitle: 'Envoyer la Facture',
  sendSureDesc: 'Êtes-vous sûr de vouloir envoyer cette facture ?',
  changeStatus: 'Changer le Statut',
  statusUpdatedSuccessfully: 'Statut mis à jour avec succès',

  // generalInfo
  generalInfoTitle: 'Informations Générales',
  status: 'Statut',
  createdAt: 'Créé le',
  createdBy: 'Créé par',
  updatedAt: 'Mis à jour le',
  notAvailable: 'Non Disponible',
  updateStatus: 'Mettre à jour le Statut',

  // customerInfo
  customerInfoTitle: 'Informations du Client',
  contactPhoneNumber: 'Numéro de Téléphone',

  //productInfo
  productInfoTitle: 'Détails du Produit',
  productNameUnknown: 'Produit Inconnu',
  noProducts: 'Aucun produit disponible.',

  //legalInfo
  legalInfoTitle: 'Informations Juridiques',
  ndaTitle: 'Accord de Confidentialité (NDA)',
  termsTitle: 'Termes et Conditions',
  documentName: 'Nom du Document',
  documentDescription: 'Description',
  documentContent: 'Contenu',

  //financialInfo
  financialInfoTitle: 'Informations Financières',
  shippingTotal: 'Total de Livraison',
  total: 'Prix Total',
  noFinancialData: 'Aucune donnée financière disponible.',

  // documentInfo
  documentsTitle: 'Documents',
  documentNameUnknown: 'Document Inconnu',
  mimeType: 'Type de Fichier',
  download: 'Télécharger',
  noDocuments: 'Aucun document disponible.',

  // salesAgents
  salesAgentsTitle: 'Agents Commerciaux',
  unknownAgent: 'Agent Inconnu',
  noSalesAgents: 'Aucun agent commercial disponible.',

  //signature
  signatureTitle: 'Informations de Signature',
  additionalInfoTitle: 'Informations Supplémentaires',
  signature: 'Signature',
  signerUser: 'Utilisateur Signataire',

  //additionalInfo
  yes: 'Oui',
  no: 'Non',
};

export default locale; 
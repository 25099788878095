const locale = {
  demo: "Demo",
  title: "O que é o <highlight>CoCRM</highlight> Lead+?",
  description:
    "Na CoCRM, não apenas apoiamos você na gestão dos seus processos de negócios e clientes; também temos o prazer de ajudá-lo a adquirir novos clientes.",
  featuresTitle: "Com o CoCRM Lead+,",
  features1:
    "Temos o prazer de fornecer os dados de clientes potenciais que você precisa com base em",
  features2:
    "Os parâmetros de país, cidade e/ou indústria que você tem como alvo para marketing.",
  features3: "As áreas específicas que você definiu para seus objetivos de vendas.",
  features4: "Os mercados onde você deseja aumentar o reconhecimento da marca.",
  buttonTitle: "Solicitar Orçamento",
  formDescription:
    "Por favor, preencha o formulário abaixo e entraremos em contato com você o mais breve possível.",
  formTitle: "Solicitar Orçamento",
  formButtonTitle: "Enviar",
  sendSuccess: "Obrigado! Entraremos em contato com você o mais breve possível.",
  fullName: "Nome Completo",
  email: "Email",
  phone: "Telefone",
  orgName: "Nome da Organização",
};

export default locale; 
const locale = {
  // fields
  users: 'Utilisateurs de Note',
  date: 'Date de Note',
  title: 'Liste des Notes',
  detail: 'Détail de Note',
  sharedList: "Notes Partagées",
  privateList: "Notes Privées",
  ownList: "Mes Notes",
  allList: "Toutes les Notes",

  // actions
  create: 'Créer une Note',
  add: 'Ajouter une Note',
  update: 'Mettre à jour la Note',
  delete: 'Supprimer la Note',

  // situational text
  tagsPlaceholder: 'Veuillez ajouter des tags et appuyer sur entrée',
  createSuccess: 'Note créée avec succès',
  updateSuccess: 'Note mise à jour avec succès',
  deletedSuccessfully: 'Note supprimée avec succès',
  deleteContent: 'Êtes-vous sûr de vouloir supprimer cette note? Cette action ne peut pas être annulée.',
  emptyState1: 'Aucune note ajoutée pour le moment.',
  emptyState2:
    'Créez et organisez vos notes ici pour suivre les détails importants et les conversations dans votre CRM.',

  createdBy: "Note créée par",
};

export default locale; 
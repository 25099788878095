import contractTemplateLocale from "../subdomains/contractTemplate/locales/es";
import contractLocale from "../subdomains/contract/locales/es";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  contract: {
    ...contractLocale,
  },
  contractTemplate: {
    ...contractTemplateLocale,
  },
};

export default locale; 

import profileLocale from "../subdomains/profile/locales/it";

const locale = {

  profile: {
    ...profileLocale,
  },
};

export default locale;




const locale = {
  // actions
  create: 'Créer un Lead',
  update: 'Mettre à jour le Lead',
  detail: 'Détail du Lead',
  view: 'Voir le Lead',
  list: 'Lister les Leads',
  addNewRole: 'Ajouter un Nouveau Rôle',
  tagsPlaceholder: 'Ajouter des tags',
  listInfo: 'Liste de tous les leads.',
  weeklyReport: 'Rapport Hebdomadaire',

  // situational messages
  createdSuccessfully: 'Lead créé avec succès',
  updatedSuccessfully: 'Lead mis à jour avec succès',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ? Cette action ne peut pas être annulée.',
  deletedSuccessfully: 'Lead supprimé avec succès',
  createInfo: 'Vous pouvez créer de nouveaux leads ici.',
  updateLeadSuccess: 'Lead mis à jour avec succès',
  createLeadSuccess: 'Lead créé avec succès',
  emptyState1: 'Aucun lead trouvé ici.',
  emptyState2: 'Vous pouvez créer ou importer des leads immédiatement.',

  // import
  importLead: 'Importer un Lead',
  importTitle: 'Importer des Leads',
  importText1: 'Vous pouvez importer des leads depuis cette page.',
  importText2: 'Vous pouvez utiliser le formulaire exemple ci-dessous pour publier vos leads.',

  // fields and names
  lead: 'Lead',
  leads: 'Leads',
  title: 'Lister les Leads',
  assigneds: 'Assignés',
  qualification: 'Qualification',
  justOwn: 'Seulement les Miens',
  isJunk: 'Est Indésirable',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Indésirable',
  unJunk: 'Non Indésirable',
  prospectName: 'Nom du Prospect : ',
  businessType: 'Type d\'Entreprise',
  leadType: 'Type de Lead',
  leadCount: 'leads',
  leadValue: 'Valeur du Lead',
  tracking: 'Suivi',
  image: 'Image du Lead',
  
  // contact
  addContact: 'Ajouter un Contact',
  contactedDate: 'Date de Contact',
  emptyStateContactContent1: 'Aucun contact trouvé ici.',
  contactDetail: 'Détail du Contact',
  contactHistory: 'Historique des Contacts',
  lastContacted: 'Dernier Contact',
  lastContact: 'Dernier Contact',
  contacted: 'Contacté',

  // statistics
  leadConversions: 'Conversions de Leads',

  // lead to customer
  convertToCustomer: 'Convertir en Client',
  customerType: 'Type de Client',
  leadConvertedSuccessfully: 'Lead converti avec succès',

  onSiteEstimateWith: 'Estimation sur site avec',

  // labels
  welcome: 'Bienvenue',
  description: 'Votre progression de leads cette semaine est incroyable. Continuons.',
};

export default locale; 
const locale = {
  leadSources: "Источники лидов",
  title: "Источники лидов",
  create: "Создать источник лида",
  delete: "Удалить источник лида",
  deleteConfirm: "Вы уверены, что хотите удалить этот источник: ",
  deletedSuccessfully: "Источник лида успешно удален",
  createSuccess: "Источник лида успешно создан",
  updateSuccess: "Источник лида успешно обновлен",
  update: "Обновить источник лида",
  updateLeadSourceSuccess: "Источник лида успешно обновлен",
  selectColor: "Выбрать цвет",
  selectIcon: "Выбрать иконку",
};

export default locale; 
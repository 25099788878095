import profileLocale from "../domains/profile/locales/fr";

const locale = {
  profile: {
    ...profileLocale,
  },
  profileo: "Demande de devis"
};

export default locale;

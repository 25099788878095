const locale = {
  title: 'Modèle de Contrat',
  emptyState1: 'Pas encore de modèles de contrat.',
  emptyState2:
    'Stockez et gérez tous les détails du contrat ici pour simplifier votre facturation et maintenir des accords clairs avec vos clients.',
  create: 'Créer un Modèle de Contrat',
  update: 'Mettre à jour le Modèle de Contrat',
  delete: 'Supprimer le Modèle de Contrat',
  createSuccess: 'Modèle de contrat créé avec succès.',
  updateSuccess: 'Modèle de contrat mis à jour avec succès.',
  deleteSuccess: 'Modèle de contrat supprimé avec succès.',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce modèle de contrat ? Cette action ne peut pas être annulée.',
};

export default locale; 
const locale = {
  demo: 'Demo',
  adjustment: 'Ajuste',
  currency: 'Moneda',
  exchangeRates: 'Tipos de cambio',
  fromCurrency: 'De moneda',
  toCurrency: 'A moneda',
  rate: 'Tasa',
  updatedAt: 'Actualizado el',
  language: 'Idioma',
  locale: 'Localización',
  updateSettings: 'Actualizar configuración',
  NDA: 'Acuerdo de confidencialidad',
  followUpMailTemplate: 'Plantilla de correo de seguimiento',
  emailType: 'Tipo de correo electrónico',
  subject: 'Asunto',
  content: 'Contenido',
  variables: 'Variables',
  prefix: 'Prefijo',
  paymentPrefix: 'Prefijo de pago',
  returnPrefix: 'Prefijo de devolución',
  termsAndConditions: 'Términos y condiciones',
  paymentMethods: 'Métodos de pago',
  companyBackground: 'Antecedentes de la empresa',
  companyReferences: 'Referencias de la empresa',
  timezone: 'Zona horaria',
  updateSuccess: 'Configuración actualizada con éxito',
  name: 'Nombre',
  surname: 'Apellido',
  email: 'Correo electrónico',
  phone: 'Teléfono',
  identityNumber: 'Número de identidad',
  vat: 'Número de IVA',
  country: 'País',
  state: 'Estado/Provincia',
  city: 'Ciudad',
  street: 'Calle',
  address: 'Dirección',
  taxOffice: 'Oficina de impuestos',
  zip: 'Código postal',
  fullName: 'Nombre completo',
  update: 'Actualizar',
  create: 'Crear',
  updateBillingInfo: 'Actualizar información de facturación',
  updateBillingInfoSuccess: 'Información de facturación actualizada con éxito',
  commonSettingsUpdateSuccess: 'Configuración actualizada con éxito',
  save: 'Guardar',
  organizationSettings: 'Organización',
  monthly: 'mensual',
  trial: 'Prueba',
  free: 'Gratis',
  trialEnds: 'La prueba termina',
  browsePackages: 'Explorar paquetes',
  cancelSubscription: 'Cancelar suscripción',
  updateOrganizationLogoSuccess: 'Logo de la organización actualizado con éxito',
  organizationLogo: 'Logo de la organización',
  summary: 'Resumen',
  website: 'Sitio web',
  legalName: 'Nombre legal',
  dateFormat: 'Formato de fecha',
  timeFormat: 'Formato de hora',
  vatNumber: 'Número de IVA',
  taxRate: 'Tasa de impuesto',
  exchangeRateMode: 'Modo de tipo de cambio',
  general: 'General',
  localizations: 'Localizaciones',
  finance: 'Finanzas',
  generalInfo: 'Información general',
  socialMedia: 'Redes sociales',
  billingInfo: 'Información de facturación',
  cards: 'Tarjetas',
  currentPlan: 'Plan actual',
  invoices: 'Facturas',
  cardListSecure: 'Sus tarjetas se almacenan en la base de datos de iyzico.',
  noInvoiceContent1: "No tiene ninguna factura todavía.",
  noInvoiceContent2:
    'Realice un seguimiento y gestione los pagos de sus facturas aquí para mantener sus finanzas organizadas y actualizadas.',
  noLanguageContent1: "No tiene ningún idioma todavía.",
  noLanguageContent2:
    'Cree y gestione idiomas aquí para hacer que su sitio web sea accesible para una audiencia global.',
  noLeadContent1: 'La configuración de leads no está disponible actualmente.',
  noLeadContent2:
    'Personalice cómo se capturan, asignan y rastrean los leads para optimizar su flujo de trabajo de CRM.',
  noCustomerContent1: 'Aún no se ha configurado ninguna configuración de cliente.',
  noCustomerContent2:
    'Configure preferencias para gestionar la información del cliente y las interacciones de manera efectiva dentro de su CRM.',
  noEstimateContent1: 'Aún no se ha configurado ninguna configuración de factura.',
  noEstimateContent2:
    'Personalice sus preferencias de factura aquí para agilizar la facturación y garantizar registros precisos.',
  noProposalContent1: 'La configuración de propuestas no está disponible actualmente.',
  noProposalContent2:
    'Personalice sus preferencias de propuesta aquí para agilizar la comunicación con el cliente y mejorar las ventas.',
  noInvoiceContent3: 'Aún no se ha configurado ninguna configuración de factura.',
  noInvoiceContent4:
    'Personalice sus preferencias de factura aquí para agilizar la facturación y garantizar registros precisos.',
  noProjectContent1: 'Aún no se ha accedido a la configuración del proyecto.',
  noProjectContent2:
    'Personalice sus preferencias de proyecto aquí para optimizar la gestión de tareas y el flujo de trabajo en su CRM.',
  noTaskContent1: 'Aún no se ha accedido a la configuración de tareas.',
  noTaskContent2:
    'Configure sus preferencias de tareas aquí para agilizar la gestión de tareas y mejorar la productividad del equipo.',
  noTicketContent1: 'Aún no se ha accedido a la configuración de tickets/soporte.',
  noTicketContent2:
    'Configure sus preferencias aquí para gestionar las solicitudes de soporte al cliente y garantizar resoluciones oportunas.',
  noFileManagementContent1: 'Aún no se ha accedido a la configuración de gestión de archivos.',
  noFileManagementContent2:
    'Configure sus preferencias aquí para organizar y gestionar documentos de manera eficiente dentro de su CRM.',
  noCalendarContent1: 'Aún no se ha accedido a la configuración del calendario.',
  noCalendarContent2:
    'Configure sus preferencias aquí para personalizar su calendario y optimizar la programación dentro de su CRM.',
  noNotificationContent1: 'Aún no se ha accedido a la configuración de notificaciones.',
  noNotificationContent2:
    'Personalice sus preferencias de notificación aquí para mantenerse informado y actualizado sobre actividades importantes dentro de su CRM.',
  noLeadPlusContent1: 'No hay producto o plan Lead+ activo.',
  noLeadPlusContent2:
    'Compre o autorice un plan para desbloquear acceso completo y personalizar su configuración de Lead+.',
  noMarketingContent1: 'No hay producto o plan de Marketing activo.',
  noMarketingContent2:
    'Compre o autorice un plan para desbloquear acceso completo y personalizar su configuración de Marketing.',
  noWindowContent1: 'No hay producto o plan de Window activo.',
  noWindowContent2:
    'Compre o autorice un plan para desbloquear acceso completo y personalizar su configuración de Window.',
  noCommunityContent1: 'No hay producto o plan de Community activo.',
  noCommunityContent2:
    'Compre o autorice un plan para desbloquear acceso completo y personalizar su configuración de Community.',
  noIntegrationsContent1: 'Aún no se ha configurado ninguna integración.',
  noIntegrationsContent2:
    'Configure integraciones aquí para mejorar las capacidades de CoCRM y agilizar los flujos de trabajo con conexiones autorizadas.',
  noMenuContent1: 'La configuración del menú no está disponible actualmente.',
  noMenuContent2:
    'Autorice el acceso para personalizar el diseño de su menú y agilizar la navegación en su CRM.',
  noLegalAgreementsContent1: 'La configuración de acuerdos legales no está disponible actualmente. ',
  noLegalAgreementsContent2:
    'Autorice el acceso para gestionar y personalizar acuerdos legales dentro de su CRM.',
  noCrmContent1: 'No hay producto o plan de CRM activo.',
  noCrmContent2:
    'Compre o autorice un plan para desbloquear acceso completo y personalizar su configuración de CRM.',
  noUserListContent1: 'No hay producto o plan de Lista de Usuarios activo.',
  noUserListContent2:
    'Compre o autorice un plan para desbloquear acceso completo y personalizar su configuración de Lista de Usuarios.',
  noPermissionsContent1: 'No hay producto o plan de Permisos activo.',
  noPermissionsContent2:
    'Compre o autorice un plan para desbloquear acceso completo y personalizar su configuración de Permisos.',
  lead: 'Lead',
  crm: 'CRM',
  menu: 'Menú',
  customer: 'Cliente',
  estimate: 'Presupuesto',
  proposal: 'Propuesta',
  invoice: 'Factura',
  project: 'Proyecto',
  task: 'Tarea',
  ticket: 'Ticket',
  fileManagement: 'Gestión de archivos',
  calendar: 'Calendario',
  notification: 'Notificación',
  leadPlus: 'Lead+',
  marketing: 'Marketing',
  window: 'Window',
  community: 'Community',
  integrations: 'Integraciones',
  userList: 'Lista de usuarios',
  permissions: 'Permisos',
  userType: 'Tipo de usuario',
  companyName: 'Nombre de la empresa',
  allowableFileFormats: 'Formatos de archivo permitidos',

  // currentPlan
  yourCurrentPlan: 'Su plan actual es ',
  yourCurrentPlanDesc: 'Un comienzo simple para todos',
  activeUntil: 'Activo hasta',
  activeUntilDesc: 'Le enviaremos una notificación al vencimiento de la suscripción',
  paymentDesc: 'El pago se cargará a su cuenta',
  paymentForTrialDesc: 'Debe comprar una suscripción para continuar usando',
  perMonth: 'Por mes',
  prtYear: 'Por año',
  perMonthDesc: 'Puede cancelar su suscripción en cualquier momento',
  upgradePlan: 'Actualizar plan',
  attentionTitle: '¡Necesitamos su atención!',
  attentionDesc: 'Su plan requiere actualizaciones',
  days: 'Días',
  of: 'de',
  remainingDesc: 'días restantes hasta que su plan requiera actualización',
  usageOfStorage: 'Uso de almacenamiento',
  users: 'Usuarios',

  //legalAgreements
  legalAgreements: 'Acuerdos legales',
  createLegalAgreement: 'Crear acuerdo legal',
  updateLegalAgreement: 'Actualizar acuerdo legal',
  deleteLegalAgreement: 'Eliminar acuerdo legal',
  createLegalAgreementSuccess: 'Acuerdo legal creado con éxito',
  updateLegalAgreementSuccess: 'Acuerdo legal actualizado con éxito',
  deleteLegalAgreementSuccess: 'Acuerdo legal eliminado con éxito',
  deleteLegalAgreementDesc: '¿Está seguro de que desea eliminar este acuerdo legal?',

  // cards
  deleteCard: 'Eliminar tarjeta',
  addCard: 'Añadir nueva tarjeta',
  createCardSuccess: 'Tarjeta creada con éxito',
  addCardSuccess: 'Tarjeta añadida con éxito',
  deleteCardSuccess: 'Tarjeta eliminada con éxito',
  deleteCardDesc: '¿Está seguro de que desea eliminar esta tarjeta?',
  requiredCard: 'El número de tarjeta es obligatorio',
  requiredCardHolder: 'El nombre del titular de la tarjeta es obligatorio',
  requiredExpireMonth: 'El mes de vencimiento es obligatorio',
  requiredExpireYear: 'El año de vencimiento es obligatorio',
  requiredCvc: 'El CVC es obligatorio',
};

export default locale; 
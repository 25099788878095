const locale = {
  permissions: 'Autorisations',
  roles: 'Rôles',
  createRole: 'Créer un Rôle',
  rolesInfo: 'Vous pouvez créer des rôles et leur attribuer des autorisations.',
  roleName: 'Nom du Rôle',
  roleDetail: 'Détail du Rôle',
  addRole: 'Ajouter',
  updateRole: 'Mettre à jour',
  deleteRole: 'Supprimer',
  roleCreatedSuccess: 'Rôle créé avec succès',
  roleUpdatedSuccess: 'Rôle mis à jour avec succès',
  roleDeletedSuccess: 'Rôle supprimé avec succès',
  errorFetchingRoles: 'Erreur lors de la récupération des rôles',
  errorCreatingRole: 'Erreur lors de la création du rôle',
  errorUpdatingRole: 'Erreur lors de la mise à jour du rôle',
  errorDeletingRole: 'Erreur lors de la suppression du rôle',
  capabilities: 'Capacités',
  advancedOptions: 'Options Avancées',
  addNewRole: 'Ajouter un Nouveau Rôle',
  rolesAndPermissions: 'Rôles et Autorisations',
  action: 'Action',
  actionScope: 'Portée de l\'Action',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer le rôle ',
  deletedSuccessfully: "Rôle supprimé avec succès",
};

export default locale; 
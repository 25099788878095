const locale = {
  contacts: 'Contatos',
  title: 'Contatos',
  createContact: 'Criar Contato',
  columnId: '#',
  columnName: 'Nome',
  columnIcon: 'Ícone',
  columnDepartment: 'Departamento',
  columnParentCategory: 'Categoria Principal',
  columnActions: 'Ações',
  emptyStateContent1: 'Contatos não encontrados',
  emptyStateContent2: 'Por favor, crie um novo contato',
  selectIconTitle: 'Selecionar um Ícone',
  searchIcons: 'Pesquisar Ícones',
  relatedContacts: 'Contatos Relacionados',
  b2bB2c: 'B2B / B2C',
  createContactTitle: 'Criar Contato',
  contactNameLabel: 'Nome do Contato',
  contactEmailLabel: 'Email do Contato',
  contactPhoneLabel: 'Telefone do Contato',
  contactTypeLabel: 'Tipo de Contato',
  contactPictureLabel: 'Foto do Contato',
  departmentLabel: 'Departamento',
  categoryLabel: 'Categoria',
  contactLanguageLabel: 'Idioma do Contato',
  countryLabel: 'País',
  stateLabel: 'Estado',
  cityLabel: 'Cidade',
  uploadPictureText: 'Carregar uma foto',
  createContactButton: 'Criar Contato',
  createSuccess: 'Contato criado com sucesso',
  delete: 'Excluir',
  deleteConfirm: 'Tem certeza de que deseja excluir este contato? Esta ação não pode ser desfeita.',
  deletedSuccessfully: 'Contato excluído com sucesso',
  cancel: 'Cancelar',
  id: '#',
  firstName: 'Nome',
  lastName: 'Sobrenome',
  email: 'Email',
  type: 'Tipo',
  phone: 'Telefone',
  position: 'Cargo',
  language: 'Idioma',
  update: 'Atualizar Contato',
  updateContactButton: 'Atualizar Contato',
  customerId: 'Selecionar um Cliente',
  primary: 'Principal',
  customer: 'Cliente',
  actions: 'Ações',
  create: 'Criar Contato',
  emptyState1: 'Nenhum contato encontrado aqui.',
  emptyState2: 'Você pode criar ou importar contatos imediatamente.',
  deletePrimaryError: 'O contato principal não pode ser excluído.',
  updateSuccess: 'Contato atualizado com sucesso',
};

export default locale; 
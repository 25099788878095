const locale = {
  supports: 'Tickets',
  support: 'Ticket',
  detail: 'Detalles del Ticket',
  subject: 'Asunto',
  title: 'Tickets',
  create: 'Crear Ticket',
  createSuccess: 'Ticket creado con éxito.',
  update: 'Actualizar Ticket',
  updateSuccess: 'Ticket actualizado con éxito.',
  delete: 'Eliminar Ticket',
  deleteSuccess: 'Ticket eliminado con éxito.',
  deleteContent: '¿Está seguro de que desea eliminar el ticket?',
  emptyState1: 'Aún no hay tickets.',
  emptyState2:
    'No ha creado ningún ticket o ya se han resuelto. Una vez que su equipo presente una solicitud de soporte, todos los tickets aparecerán aquí.',

  // detailDrawer
  generalInfoTitle: 'Información General',
  ticketInfoTitle: 'Información del Ticket',
  closedDate: 'Fecha de Cierre',
  changeStatus: 'Cambiar Estado',
  statusChangeSuccess: 'Estado cambiado con éxito.',
};

export default locale; 
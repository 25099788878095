const locale = {
  // fields
  users: 'Notiz-Benutzer',
  date: 'Notizdatum',
  title: 'Notizen auflisten',
  detail: 'Notizdetail',
  sharedList: "Geteilte Notizen",
  privateList: "Private Notizen",
  ownList: "Meine Notizen",
  allList: "Alle Notizen",

  // actions
  create: 'Notiz erstellen',
  add: 'Notiz hinzufügen',
  update: 'Notiz aktualisieren',
  delete: 'Notiz löschen',

  // situational text
  tagsPlaceholder: 'Bitte Tags hinzufügen und Enter drücken',
  createSuccess: 'Notiz erfolgreich erstellt',
  updateSuccess: 'Notiz erfolgreich aktualisiert',
  deletedSuccessfully: 'Notiz erfolgreich gelöscht',
  deleteContent: 'Sind Sie sicher, dass Sie diese Notiz löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  emptyState1: 'Noch keine Notizen hinzugefügt.',
  emptyState2:
    'Erstellen und organisieren Sie hier Ihre Notizen, um wichtige Details und Gespräche in Ihrem CRM zu verfolgen.',

  createdBy: "Notiz erstellt von",
};

export default locale; 
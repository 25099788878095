const locale = {
  title: "Departamentos",
  delete: "Excluir",
  deleteConfirm: "Tem certeza que deseja excluir este departamento? Esta ação não pode ser desfeita.",
  deletedSuccessfully: "Departamento excluído com sucesso",
  create: "Criar Departamento",
  departmentNameLabel: "Nome do Departamento",
  rolesLabel: "Funções",
  addRole: "Adicionar Função",
  createDepartmentButton: "Criar Departamento",
  createSuccess: "Departamento criado com sucesso",
  columnId: "#",
  columnName: "Nome",
  columnRoles: "Funções",
  columnActions: "Ações",
  headerTitle: "Departamentos",
  createDepartment: "Criar Departamento",
  rolesButton: "Funções",
  emptyState1: "Nenhum departamento encontrado",
  emptyState2: "Por favor, crie um novo departamento",
  addNewRole: "Adicionar Nova Função",
  updateDepartmentTitle: "Atualizar Departamento",
  updateDepartmentButton: "Atualizar",
  updateSuccess: "Departamento atualizado com sucesso",
};

export default locale; 
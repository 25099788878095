/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button, Menu, MenuItem, Typography } from '@mui/material';
import { RefreshRounded } from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProposalStatus, IProposalDetailResult, PermissionSubject } from 'corede-common-cocrm';
import React from 'react';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import {  useProposalStatusUpdateMutation } from '../../context/proposal.api';
import { enqueueSnackbar } from 'notistack';
import { IBaseError, PermissionAction } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export interface IProposalDetailDrawerLeftPanelActionsComponentProps {
  proposalDetailData: IProposalDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * contains actions: add appointment, convert proposal to customer
 */
export const ProposalDetailDrawerLeftPanelActionsComponent = (
  props: IProposalDetailDrawerLeftPanelActionsComponentProps,
) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const [
    proposalStatusUpdate,
    { data: proposalStatusUpdateData, isLoading: proposalStatusUpdateLoading, error: proposalStatusUpdateError },
  ] = useProposalStatusUpdateMutation();


  const handleChangeStatus = (status: ProposalStatus) => {
    proposalStatusUpdate({
      filter: {
        _id: props.proposalDetailData?._id ?? '',
      },
      input: {
        status: status,
      },
    });
  };

  useEffect(() => {
    if (proposalStatusUpdateData) {
      enqueueSnackbar(t('crm.preSale.proposal.statusUpdatedSuccessfully'), {
        variant: 'success',
      });
    }
  }, [proposalStatusUpdateData]);

  useEffect(() => {
    if (proposalStatusUpdateError) {
      DefaultErrorHandlerUseEffect(proposalStatusUpdateError as IBaseError, currentLanguage);
    }
  }, [proposalStatusUpdateError]);

  const StatusMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (  
      <div>
        <PermissionWrapper
          check={{
            subject: PermissionSubject.proposal,
            action: PermissionAction.update,
          }}
        >
          <Button
            id="proposal-detail-button"
            aria-controls={open ? 'proposal-detail-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            color="primary"
            variant="contained"
            disabled={proposalStatusUpdateLoading}
            sx={{
              height: 40,
              fontSize: '13px',
              borderRadius: 100,
            }}
          >
            <RefreshRounded sx={{ mr: 1 }} />
            {t('crm.preSale.proposal.changeStatus')}
          </Button>
          <Menu
            id="proposal-detail-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'proposal-detail-button',
            }}
          >
            {Object.entries(ProposalStatus).map(([key, value]) => (
              <MenuItem key={key} onClick={() => handleChangeStatus(value)}>
                <Typography fontSize={'small'} fontWeight={'bold'}>
                  {getTranslatedEnumValue(value)}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </PermissionWrapper>
      </div>
    );
  };

  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      {/* Proposal base actions */}
      <StatusMenu />
    </Stack>
  );
};

import { Box, Typography } from '@mui/material';
import { isNullOrUndefinedOrEmpty } from 'corede-common';
import { Iconify } from 'components/iconify';
import { convertMaterailToIconifyIconName } from 'utils/convertMaterialToIconifyIcon';

interface IBaseChip {
  borderColor?: string;
  icon?: string;
  color?: string;
  label: string;
  borderRadius?: string;
  backgroundColor?: string;
  fontWeight?: string;
}

const BaseChip = (props: IBaseChip) => {
  return isNullOrUndefinedOrEmpty(props.label) && isNullOrUndefinedOrEmpty(props.label) ? null : (
    <Box
      sx={{
        bgcolor: props.backgroundColor ?? 'background.secondWithBlur',
        backdropFilter: 'blur(3px)',
        opacity: 0.7,
        border: '1px solid',
        borderColor: props.borderColor
          ? props.borderColor
          : props.color
            ? props.color
            : 'primary.main',
        borderRadius: props.borderRadius ?? '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        py: 0,
        px: 1,
      }}
    >
      {props.icon && (
        <Iconify
          icon={convertMaterailToIconifyIconName(props.icon ?? '')}
          sx={{
            mr: 0.5,
            fontSize: 14,
          }}
        />
      )}
      <Typography
        fontSize={'12px'}
        textAlign={'center'}
        fontWeight={props.fontWeight ?? '700'}
        textTransform={'capitalize'}
        color={props.color ?? 'primary.main'}
      >
        {props.label}
      </Typography>
    </Box>
  );
};

export default BaseChip;

const locale = {
  payments: 'Paiements',
  payment: 'Paiement',
  paymentConversions: 'Conversions de Paiement',
  title: 'Paiements',
  create: 'Ajouter un Paiement',
  update: 'Mettre à jour le Paiement',
  delete: 'Supprimer le Paiement',
  createSuccess: 'Paiement ajouté avec succès.',
  updateSuccess: 'Paiement mis à jour avec succès.',
  deleteSuccess: 'Paiement supprimé avec succès.',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce paiement ? Cette action ne peut pas être annulée.',
  emptyState1: 'Aucun paiement enregistré pour le moment.',
  emptyState2:
    'Suivez et gérez les paiements de vos factures ici pour garder vos finances organisées et à jour.',

  // detailDrawer
  generalInfoTitle: 'Informations Générales',
  date: 'Date de Paiement',
  amountPaid: 'Montant Payé',
  confirmationNumber: 'Numéro de Confirmation',
  currency: 'Devise',
};

export default locale; 
const locale = {
  // actions
  create: 'Lead erstellen',
  update: 'Lead aktualisieren',
  detail: 'Lead-Details',
  view: 'Lead anzeigen',
  list: 'Leads auflisten',
  addNewRole: 'Neue Rolle hinzufügen',
  tagsPlaceholder: 'Tags hinzufügen',
  listInfo: 'Liste aller Leads.',
  weeklyReport: 'Wochenbericht',

  // situational messages
  createdSuccessfully: 'Lead erfolgreich erstellt',
  updatedSuccessfully: 'Lead erfolgreich aktualisiert',
  deleteConfirm: 'Sind Sie sicher, dass Sie löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  deletedSuccessfully: 'Lead erfolgreich gelöscht',
  createInfo: 'Hier können Sie neue Leads erstellen.',
  updateLeadSuccess: 'Lead erfolgreich aktualisiert',
  createLeadSuccess: 'Lead erfolgreich erstellt',
  emptyState1: 'Keine Leads gefunden.',
  emptyState2: 'Sie können sofort Leads erstellen oder importieren.',

  // import
  importLead: 'Lead importieren',
  importTitle: 'Leads importieren',
  importText1: 'Sie können Leads von dieser Seite importieren.',
  importText2: 'Sie können das Beispielformular unten verwenden, um Ihre Leads zu veröffentlichen.',

  // fields and names
  lead: 'Lead',
  leads: 'Leads',
  title: 'Leads auflisten',
  assigneds: 'Zugewiesene',
  qualification: 'Qualifikation',
  justOwn: 'Nur eigene',
  isJunk: 'Ist Spam',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Spam',
  unJunk: 'Kein Spam',
  prospectName: 'Interessentenname: ',
  businessType: 'Geschäftstyp',
  leadType: 'Lead-Typ',
  leadCount: 'Leads',
  leadValue: 'Lead-Wert',
  tracking: 'Verfolgung',
  image: 'Lead-Bild',
  
  // contact
  addContact: 'Kontakt hinzufügen',
  contactedDate: 'Kontaktdatum',
  emptyStateContactContent1: 'Keine Kontakte gefunden.',
  contactDetail: 'Kontaktdetails',
  contactHistory: 'Kontaktverlauf',
  lastContacted: 'Zuletzt kontaktiert',
  lastContact: 'Letzter Kontakt',
  contacted: 'Kontaktiert',

  // statistics
  leadConversions: 'Lead-Konversionen',

  // lead to customer
  convertToCustomer: 'Zum Kunden konvertieren',
  customerType: 'Kundentyp',
  leadConvertedSuccessfully: 'Lead erfolgreich konvertiert',

  onSiteEstimateWith: 'Vor-Ort-Schätzung mit',

  // labels
  welcome: 'Willkommen',
  description: 'Ihr Lead-Fortschritt in dieser Woche ist erstaunlich. Lassen Sie uns weitermachen.',
};

export default locale; 
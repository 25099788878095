const locale = {
  title: 'Шаблон Контракта',
  emptyState1: 'Пока нет шаблонов контрактов.',
  emptyState2:
    'Храните и управляйте всеми деталями контракта здесь, чтобы упростить выставление счетов и поддерживать четкие соглашения с вашими клиентами.',
  create: 'Создать Шаблон Контракта',
  update: 'Обновить Шаблон Контракта',
  delete: 'Удалить Шаблон Контракта',
  createSuccess: 'Шаблон контракта успешно создан.',
  updateSuccess: 'Шаблон контракта успешно обновлен.',
  deleteSuccess: 'Шаблон контракта успешно удален.',
  deleteConfirm: 'Вы уверены, что хотите удалить этот шаблон контракта? Это действие нельзя отменить.',
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { IInvoicePaymentDetailResult, InvoicePaymentStatus } from 'corede-common-cocrm';
import { getTranslatedEnumValue } from 'localization';

interface IPaymentDetailDrawerHeaderStatusComponentProps {
  paymentDetailData: IInvoicePaymentDetailResult | undefined;
}

export const PaymentDetailDrawerHeaderStatusComponent = (
  props: IPaymentDetailDrawerHeaderStatusComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return downLg ? null : (
    <Stack direction="row" spacing={0} sx={{ borderRadius: '16px', overflow: 'hidden', mb: 1 }}>
      {/* {Object.entries(InvoicePaymentStatus).map(([key, value], index) => (
        <Box
          key={key}
          sx={{
            backgroundColor: 'secondary.main',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'center',
            py: 1,
            px: 3,
            ...(index === 0 && { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }),
            ...(index === Object.entries(InvoicePaymentStatus).length - 1 && {
              borderTopRightRadius: '16px',
              borderBottomRightRadius: '16px',
            }),
            flexBasis: `calc(100% / ${Object.entries(InvoicePaymentStatus).length})`,
          }}
        >
          <Typography
            fontSize={'12px'}
            sx={{
              color: 'white',
              px: 1,
            }}
          >
            {getTranslatedEnumValue(value as InvoicePaymentStatus)}
          </Typography>
        </Box>
      ))} */}
    </Stack>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button, Menu, MenuItem, Typography } from '@mui/material';
import { RefreshRounded } from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectStatus, IProjectDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { useProjectUpdateMutation } from '../../context/project.api';
import { enqueueSnackbar } from 'notistack';
import { IBaseError } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';

export interface IProjectDetailDrawerLeftPanelActionsComponentProps {
  projectDetailData: IProjectDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * contains actions: add appointment, convert project to customer
 */
export const ProjectDetailDrawerLeftPanelActionsComponent = (
  props: IProjectDetailDrawerLeftPanelActionsComponentProps,
) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const [
    projectUpdate,
    { data: projectUpdateData, isLoading: projectUpdateLoading, error: projectUpdateError },
  ] = useProjectUpdateMutation();

  const handleChangeStatus = (status: ProjectStatus) => {
    projectUpdate({
      filter: {
        _id: props.projectDetailData?._id ?? '',
      },
      input: {
        status: status,
      },
    });
  };

  useEffect(() => {
    if (projectUpdateData) {
      enqueueSnackbar(t('crm.project.project.statusChangeSuccess'), {
        variant: 'success',
      });
    }
  }, [projectUpdateData]);

  useEffect(() => {
    if (projectUpdateError) {
      DefaultErrorHandlerUseEffect(projectUpdateError as IBaseError, currentLanguage);
    }
  }, [projectUpdateError]);

  const StatusMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button
          id="project-detail-button"
          aria-controls={open ? 'project-detail-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          color="primary"
          variant="contained"
          disabled={projectUpdateLoading}
          sx={{
            height: 40,
            fontSize: '13px',
            borderRadius: 100,
          }}
        >
          <RefreshRounded sx={{ mr: 1 }} />
          {t('crm.project.project.changeStatus')}
        </Button>
        <Menu
          id="project-detail-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'project-detail-button',
          }}
          sx={{
            '& .MuiPaper-root': {
              bgcolor: 'background.secondWithBlur',
              backdropFilter: 'blur(3px)',
              borderRadius: 2,
            },
          }}
        >
          {Object.entries(ProjectStatus).map(([key, value]) => (
            <MenuItem key={key} onClick={() => handleChangeStatus(value)}>
              <Typography fontSize={'small'} fontWeight={'bold'}>
                {getTranslatedEnumValue(value)}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      {/* Project base actions */}
      <StatusMenu />
    </Stack>
  );
};

import supportLocale from "../subdomains/support/locales/fr";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  support: {
    ...supportLocale,
  },
};

export default locale; 
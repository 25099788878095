const locale = {
  tasks: "Tarefas",
  task: "Tarefa",
  taskConversions: "Conversões de Tarefas",
  title: "Tarefas",
  addTask: "Adicionar Tarefa",
  emptyState1: "Nenhuma tarefa atribuída ainda.",
  emptyState2: "Crie tarefas aqui para organizar seu trabalho, definir prioridades e acompanhar suas atividades de CRM.",
  create: "Criar Tarefa",
  subject: "Assunto",
  description: "Descrição",
  startDate: "Data de início",
  dueDate: "Data de vencimento",
  assigneeIds: "Responsáveis",
  followerIds: "Seguidores",
  checklist: "Lista de verificação",
  department: "Departamento",
  priority: "Prioridade",
  tags: "Tags",
  hourlyRate: "Taxa horária",
  hourlyRateCurrency: "Moeda da taxa horária",
  isBillable: "Faturável",
  isRecurrent: "Recorrente",
  repeatDays: "Dias de repetição",
  totalCycle: "Ciclo total",
  addChecklistItem: "Adicionar item à lista",
  noAssigneeToCreateChecklist: "Atribua usuários para criar listas de verificação",
  assignee: "Responsável",
  createTaskSuccess: "Tarefa criada com sucesso.",
  deleteTask: "Excluir Tarefa",
  deleteTaskContent: "Tem certeza de que deseja excluir esta tarefa? Esta ação não pode ser desfeita.",
  deletedSuccessfully: "Tarefa excluída com sucesso",
  updateTask: "Atualizar Tarefa",
  updateSuccess: "Tarefa atualizada com sucesso.",

  detail: "Detalhe da Tarefa",
  subtask: "Subtarefa",

  addSubtask: "Adicionar Subtarefa",
  updateStatus: "Atualizar Status",

  createdBy: "Tarefa criada por ",

  // detailDrawer
  detailedInfo: "Informações Detalhadas",
  recurrentInfo: "Informações de Recorrência",
  billableInfo: "Informações de Faturamento",
  documents: "Documentos",
  editAssignees: "Editar Responsáveis",
};

export default locale; 
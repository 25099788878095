const locale = {
  returns: 'Retours',
  return: 'Retour',
  returnConversions: 'Conversions de retour',
  title: 'Retours',
  create: 'Ajouter un retour',
  update: 'Mettre à jour le retour',
  delete: 'Supprimer le retour',
  createSuccess: 'Retour ajouté avec succès.',
  updateSuccess: 'Retour mis à jour avec succès.',
  deleteSuccess: 'Retour supprimé avec succès.',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce retour ? Cette action ne peut pas être annulée.',
  emptyState1: 'Aucun retour enregistré pour le moment.',
  emptyState2:
    'Gérez et examinez ici tous les retours de produits ou de services pour maintenir vos dossiers précis et répondre aux besoins des clients.',

  // detailDrawer
  generalInfoTitle: 'Informations générales',
  isOpen: 'Est ouvert',
  refundAmount: 'Montant du remboursement',
  resolveReason: 'Raison de résolution',
  disapproveReason: 'Raison de désapprobation',
  returnProducts: 'Produits retournés',
  amount: 'Montant',
  reason: 'Raison',
  isPartial: 'Est partiel',
  note: 'Note',
  statusChangeSuccess: 'Statut modifié avec succès',
  changeStatus: 'Changer le statut',
};

export default locale; 
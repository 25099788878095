const locale = {
  title: "Категории Клиентов",
  headerTitle: "Категории Клиентов",
  create: "Создать Категорию Клиента",
  columnId: "ID",
  columnName: "Название",
  columnRoles: "Роли",
  columnActions: "Действия",
  rolesButton: "Редактировать Роли",
  createCustomerCategoryTitle: "Создать Новую Категорию Клиента",
  customerCategoryNameLabel: "Название Категории Клиента",
  rolesLabel: "Роли",
  createCustomerCategoryButton: "Создать",
  emptyStateContent1: "Категории клиентов не найдены",
  emptyStateContent2:
    "Вы можете создать новые категории клиентов, нажав на кнопку ниже.",
  addNewRole: "Добавить Новую Роль",
  deletedSuccessfully: "Категория клиента успешно удалена",
  deleteConfirm: "Вы уверены, что хотите удалить эту категорию клиента?",
  delete: "Удалить",
  enTranslation: "Перевод на Английский",
  trTranslation: "Перевод на Турецкий",
  department: "Отдел",
  parentCategory: "Родительская Категория",
  icon: "Иконка",
  name: "Название",
  createCustomerCategorySuccess: "Категория клиента успешно создана",
  addDepartment: "Добавить Отдел",
  showMore: "Показать Больше",
  columnIcon: "Иконка",
  columnDepartment: "Отдел",
  columnParentCategory: "Родительская Категория",
  selectIconTitle: "Выбрать Иконку",
  searchIcons: "Поиск Иконок",
  update: "Обновить Категорию Клиента",
  updateCustomerCategorySuccess: "Категория клиента успешно обновлена",
  actions: "Действия",
  createdAt: "Создано",
  emptyState1: "Категории клиентов не найдены",
  emptyState2: "Вы можете создать новые категории клиентов, нажав на кнопку ниже.",
};

export default locale; 
const locale = {
  demo: "Демо",
  title: "Что такое Окно <highlight>CoCRM</highlight>?",
  description:
    "Окно CoCRM - это область развития бизнеса, разработанная исключительно для компаний-пользователей CoCRM, где они могут осуществлять прямые переводы участникам экосистемы CoCRM относительно своих продуктов, услуг или кампаний, и использовать бизнес-возможности, определяя специальные скидки для частных лиц и организаций, посещающих наш сайт.",
  featuresTitle: "С Окном CoCRM;",
  features1:
    "Вы можете делать предложения напрямую посетителям нашего сайта с ежемесячным трафиком более 1 млн,",
  features2:
    "Вы можете делать различные объявления о кампаниях для компаний в нашей экосистеме,",
  features3:
    "Вы можете предоставлять подробную информацию о своих продуктах и услугах, участвуя в блог-кампаниях, которые мы будем проводить,",
  features4:
    "Вы можете внести дополнительный вклад в 36,2% в узнаваемость вашего бренда!",
  buttonTitle: "Запросить коммерческое предложение!",
  formDescription:
    "Заполните форму, и мы свяжемся с вами в ближайшее время.",
  sendSuccess: "Спасибо! Мы свяжемся с вами в ближайшее время.",
  formTitle: "Запросить коммерческое предложение",
  formButtonTitle: "Отправить",
  fullName: "Полное имя",
  email: "Электронная почта",
  phone: "Телефон",
  orgName: "Название организации",
};

export default locale; 
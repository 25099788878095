import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { List } from '../pages';

import { ExpenseLayout } from '../layouts/ExpenseLayout';

export const ExpenseRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ExpenseLayout />}>
        <Route index element={<List />} />
      </Route>
    </Routes>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { IInvoiceReturnDetailResult } from 'corede-common-cocrm';
import { ReturnDetailDrawerHeaderStatusComponent } from './ReturnDetailDrawer-rightPanel-header-status.component';
// import { ReturnDetailDrawerBaseInfoComponent } from './ReturnDetailDrawer-rightPanel-header-baseInfo.component';

export interface IReturnDetailDrawerRightPanelHeaderComponentProps {
  returnDetailData: IInvoiceReturnDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const ReturnDetailDrawerRightPanelHeaderComponent = (
  props: IReturnDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      <ReturnDetailDrawerHeaderStatusComponent returnDetailData={props.returnDetailData} />
      {/* <ReturnDetailDrawerBaseInfoComponent returnDetailData={props.returnDetailData} /> */}
    </>
  );
};

const locale = {
  returns: 'Возвраты',
  return: 'Возврат',
  returnConversions: 'Конверсии возвратов',
  title: 'Возвраты',
  create: 'Добавить возврат',
  update: 'Обновить возврат',
  delete: 'Удалить возврат',
  createSuccess: 'Возврат успешно добавлен.',
  updateSuccess: 'Возврат успешно обновлен.',
  deleteSuccess: 'Возврат успешно удален.',
  deleteConfirm: 'Вы уверены, что хотите удалить этот возврат? Это действие нельзя отменить.',
  emptyState1: 'Пока нет зарегистрированных возвратов.',
  emptyState2:
    'Управляйте и просматривайте здесь любые возвраты продуктов или услуг, чтобы ваши записи были точными и соответствовали потребностям клиентов.',

  // detailDrawer
  generalInfoTitle: 'Общая информация',
  isOpen: 'Открыт',
  refundAmount: 'Сумма возмещения',
  resolveReason: 'Причина разрешения',
  disapproveReason: 'Причина отклонения',
  returnProducts: 'Возвращаемые продукты',
  amount: 'Сумма',
  reason: 'Причина',
  isPartial: 'Частичный',
  note: 'Примечание',
  statusChangeSuccess: 'Статус успешно изменен',
  changeStatus: 'Изменить статус',
};

export default locale; 
import React from 'react';
import { Box, Stack } from '@mui/material';
import { IHasId, IUserProfile, PermissionAction } from 'corede-common';
import {
  ITask,
  ITaskChecklistItem,
  ITaskDetailResult,
  ITaskListItemResult,
  PermissionSubject,
  TaskStatus,
} from 'corede-common-cocrm';
import ColumnContainer from './TaskList-kanban-columnContainer.component';
import TaskCard from './TaskList-kanban-taskCard.component';
import { getTranslatedEnumValue } from 'localization';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTaskKanbanUpdateMutation, useTaskListQuery } from '../../context/task.api';
import TaskDetailDrawer from '../detailDrawer/TaskDetailDrawer';
import { TaskDeleteDialog } from '../TaskDeleteDialog';
import TaskUpdateDrawerWrapper from '../update/TaskUpdateOverlay';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { OverlayType } from 'components/dialog/ActionDialog';
import {
  DndContext,
  DragOverlay,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
  useSensors,
  useSensor,
  PointerSensor,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export type Id = string | number;

export type Column = {
  id: Id;
  title: string;
};

export type Task = {
  id: Id;
  status: Id;
  subject: string;
  description: string;
  checklistTotal: number;
  checklistCompleted: number;
  documentCount: number;
  assignees: IUserProfile[];
  dueDate: Date | undefined;
};

export interface ITaskListKanbanComponent {
  setOpenTaskCreateDrawer?: (value: boolean) => void | undefined;
  setSelectedStatus?: (value: TaskStatus) => void | undefined;
}

// Sabit sayfa boyutu
const PAGE_SIZE = 5;

// Sütun genişliği
const COLUMN_WIDTH = 300;

const TaskListKanbanComponent = memo((props: ITaskListKanbanComponent) => {
  // states
  const [selectedTask, setSelectedTask] = useState<TListGridSelectedEntity<ITask>>();
  const [openTaskDetailDrawer, setOpenTaskDetailDrawer] = useState(false);
  const [openTaskDeleteDialog, setOpenTaskDeleteDialog] = useState(false);
  const [openTaskUpdateDrawer, setOpenTaskUpdateDrawer] = useState(false);

  // Pagination state'lerini tek bir objede topla
  const [pagination, setPagination] = useState<
    Record<TaskStatus, { page: number; pageSize: number }>
  >({
    [TaskStatus.awaitingFeedback]: { page: 1, pageSize: PAGE_SIZE },
    [TaskStatus.completed]: { page: 1, pageSize: PAGE_SIZE },
    [TaskStatus.inProgress]: { page: 1, pageSize: PAGE_SIZE },
    [TaskStatus.notStarted]: { page: 1, pageSize: PAGE_SIZE },
    [TaskStatus.tested]: { page: 1, pageSize: PAGE_SIZE },
    [TaskStatus.expired]: { page: 1, pageSize: PAGE_SIZE },
  });

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.task,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.task,
    action: PermissionAction.update,
  });

  // queries
  const { data: awaitingFeedbackTasks } = useTaskListQuery({
    input: {
      filter: {
        statuses: [TaskStatus.awaitingFeedback],
      },
      pagination: pagination[TaskStatus.awaitingFeedback],
    },
  });
  const { data: completedTasks } = useTaskListQuery({
    input: {
      filter: {
        statuses: [TaskStatus.completed],
      },
      pagination: pagination[TaskStatus.completed],
    },
  });
  const { data: inProgressTasks } = useTaskListQuery({
    input: {
      filter: {
        statuses: [TaskStatus.inProgress],
      },
      pagination: pagination[TaskStatus.inProgress],
    },
  });
  const { data: notStartedTasks } = useTaskListQuery({
    input: {
      filter: {
        statuses: [TaskStatus.notStarted],
      },
      pagination: pagination[TaskStatus.notStarted],
    },
  });
  const { data: testedTasks } = useTaskListQuery({
    input: {
      filter: {
        statuses: [TaskStatus.tested],
      },
      pagination: pagination[TaskStatus.tested],
    },
  });
  const { data: expiredTasks } = useTaskListQuery({
    input: {
      filter: {
        statuses: [TaskStatus.expired],
      },
      pagination: pagination[TaskStatus.expired],
    },
  });

  // mutation
  const [taskKanbanUpdate] = useTaskKanbanUpdateMutation();

  // kanban
  const [columns, setColumns] = useState<Column[]>(
    Object.values(TaskStatus).map((status) => ({
      id: status,
      title: getTranslatedEnumValue(status),
    })),
  );
  const [activeColumn, setActiveColumn] = useState<Column | null>(null);
  const [activeTask, setActiveTask] = useState<Task | null>(null);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskCounts, setTaskCounts] = useState<{ [key: string]: number }>({});
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 3 },
  });
  const allSensors = useSensors(pointerSensor);
  const sensors = hasUpdatePermission ? allSensors : [];

  const updateTask = useCallback((id: Id, content: string) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => (task.id === id ? { ...task, content } : task)),
    );
  }, []);

  const onDragStart = useCallback((event: DragStartEvent) => {
    if (event.active.data.current?.type === 'Column') {
      setActiveColumn(event.active.data.current.column);
    } else if (event.active.data.current?.type === 'Task') {
      setActiveTask(event.active.data.current.task);
    }
  }, []);

  const onDragEnd = useCallback(
    async (event: DragEndEvent) => {
      console.log('onDragEnd called', event);
      setActiveColumn(null);
      setActiveTask(null);

      const { active, over } = event;
      if (!over) {
        console.log('No over element');
        return;
      }

      // Burada active ve over elementlerinin tiplerini kontrol edelim
      const activeType = active.data.current?.type;
      const overType = over.data.current?.type;

      console.log('Active:', active);
      console.log('Over:', over);

      // Sürükleme işlemi tamamlandığında API'ye güncelleme gönder
      if (activeType === 'Task') {
        const activeTask = active.data.current?.task;
        let newStatus: TaskStatus | undefined;

        // Eğer üzerine bırakılan bir sütun ise
        if (overType === 'Column') {
          newStatus = over.id.toString() as TaskStatus;
          console.log('Dropped on column:', newStatus);
        }
        // Eğer üzerine bırakılan bir görev ise, o görevin bulunduğu sütunun statüsünü al
        else if (overType === 'Task') {
          const overTask = tasks.find((task) => task.id === over.id);
          if (overTask) {
            newStatus = overTask.status.toString() as TaskStatus;
            console.log('Dropped on task, using status:', newStatus);
          }
        }

        await taskKanbanUpdate({
          filter: {
            _id: activeTask.id,
          },
          input: {
            status: newStatus,
          },
        });
      }

      // Sütunların sırasını güncelle
      if (active.data.current?.type === 'Column' && over.data.current?.type === 'Column') {
        setColumns((prevColumns) => {
          const activeIndex = prevColumns.findIndex((col) => col.id === active.id);
          const overIndex = prevColumns.findIndex((col) => col.id === over.id);
          return arrayMove(prevColumns, activeIndex, overIndex);
        });
      }
    },
    [taskKanbanUpdate, tasks],
  );

  const onDragOver = useCallback((event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) return;

    if (active.id === over.id) return;

    const activeId = active.id;
    const overId = over.id;

    const isTask = active.data.current?.type === 'Task';
    const isOverTask = over.data.current?.type === 'Task';

    if (isTask && isOverTask) {
      setTasks((prevTasks) => {
        const activeIndex = prevTasks.findIndex((task) => task.id === activeId);
        const overIndex = prevTasks.findIndex((task) => task.id === overId);

        if (activeIndex !== -1 && overIndex !== -1) {
          const updatedTasks = [...prevTasks];
          updatedTasks[activeIndex] = {
            ...updatedTasks[activeIndex],
            status: updatedTasks[overIndex].status,
          };
          return arrayMove(updatedTasks, activeIndex, overIndex);
        }
        return prevTasks;
      });
    } else if (isTask && over.data.current?.type === 'Column') {
      setTasks((prevTasks) => {
        const activeIndex = prevTasks.findIndex((task) => task.id === activeId);
        if (activeIndex !== -1) {
          const updatedTasks = [...prevTasks];
          updatedTasks[activeIndex] = { ...updatedTasks[activeIndex], status: overId };
          return updatedTasks;
        }
        return prevTasks;
      });
    }
  }, []);

  const handleLoadMore = useCallback((status: TaskStatus) => {
    setPagination((prev) => ({
      ...prev,
      [status]: {
        ...prev[status],
        pageSize: prev[status].pageSize + PAGE_SIZE,
      },
    }));
  }, []);

  // Görevleri dönüştüren yardımcı fonksiyon
  const transformTasks = useCallback((tasks: ITaskListItemResult[] | undefined = []) => {
    return tasks.map((task) => ({
      id: task._id,
      status: task.status,
      subject: task.subject,
      description: task.description,
      checklistTotal: task.checklist?.length ?? 0,
      checklistCompleted:
        task.checklist?.filter((item: ITaskChecklistItem) => item.isChecked).length ?? 0,
      documentCount: task.documents?.length ?? 0,
      assignees: task.assignees,
      dueDate: task.dueDate ?? undefined,
    }));
  }, []);

  // effects
  useEffect(() => {
    if (
      awaitingFeedbackTasks ||
      completedTasks ||
      inProgressTasks ||
      testedTasks ||
      notStartedTasks ||
      expiredTasks
    ) {
      const combinedTasks = [
        ...transformTasks(awaitingFeedbackTasks?.data),
        ...transformTasks(completedTasks?.data),
        ...transformTasks(inProgressTasks?.data),
        ...transformTasks(testedTasks?.data),
        ...transformTasks(notStartedTasks?.data),
        ...transformTasks(expiredTasks?.data),
      ];

      setTasks(combinedTasks);

      setTaskCounts({
        [TaskStatus.awaitingFeedback]: awaitingFeedbackTasks?.count ?? 0,
        [TaskStatus.completed]: completedTasks?.count ?? 0,
        [TaskStatus.inProgress]: inProgressTasks?.count ?? 0,
        [TaskStatus.notStarted]: notStartedTasks?.count ?? 0,
        [TaskStatus.tested]: testedTasks?.count ?? 0,
        [TaskStatus.expired]: expiredTasks?.count ?? 0,
      });
    }
  }, [
    awaitingFeedbackTasks,
    completedTasks,
    inProgressTasks,
    testedTasks,
    notStartedTasks,
    expiredTasks,
    transformTasks,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            overflowY: 'hidden',
            height: '100%',
            pb: 2, // Kaydırma çubuğu için alt boşluk
            '&::-webkit-scrollbar': {
              height: 8,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.2)',
              borderRadius: 4,
            },
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              minWidth: 'max-content',
              p: 1,
              height: '100%',
            }}
          >
            <SortableContext items={columnsId}>
              {columns.map((col) => (
                <Box
                  key={col.id}
                  sx={{ width: COLUMN_WIDTH, flexShrink: 0, height: '100%' }}
                  data-column-id={col.id}
                >
                  <ColumnContainer
                    column={col}
                    tasks={tasks.filter((task) => task.status === col.id)}
                    updateTask={updateTask}
                    setSelectedTask={setSelectedTask}
                    setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
                    setSelectedStatus={props.setSelectedStatus}
                    setOpenTaskCreateDrawer={props.setOpenTaskCreateDrawer}
                    handleLoadMore={handleLoadMore}
                    taskCount={taskCounts[col.id]}
                  />
                </Box>
              ))}
            </SortableContext>
          </Stack>
        </Box>

        {createPortal(
          <DragOverlay>
            {activeColumn && (
              <Box sx={{ width: COLUMN_WIDTH }}>
                <ColumnContainer
                  column={activeColumn}
                  tasks={tasks.filter((task) => task.status === activeColumn.id)}
                  updateTask={updateTask}
                  setSelectedTask={setSelectedTask}
                  setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
                  setSelectedStatus={props.setSelectedStatus}
                  setOpenTaskCreateDrawer={props.setOpenTaskCreateDrawer}
                  handleLoadMore={handleLoadMore}
                  taskCount={taskCounts[activeColumn.id]}
                />
              </Box>
            )}
            {activeTask && (
              <TaskCard
                task={activeTask}
                updateTask={updateTask}
                setSelectedTask={setSelectedTask}
                setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
                hasDetailPermission={hasDetailPermission}
              />
            )}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.task,
          action: PermissionAction.detail,
        }}
      >
        <TaskDetailDrawer
          setSelectedTask={setSelectedTask}
          open={openTaskDetailDrawer}
          setOpen={setOpenTaskDetailDrawer}
          selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.lead,
          action: PermissionAction.update,
        }}
      >
        <TaskUpdateDrawerWrapper
          open={openTaskUpdateDrawer}
          setOpen={setOpenTaskUpdateDrawer}
          targetId={selectedTask?.relatedEntity?._id ?? ''}
          targetEntityRef={selectedTask?.relatedEntityRef}
          item={selectedTask}
          overlayType={OverlayType.drawer}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.lead,
          action: PermissionAction.delete,
        }}
      >
        <TaskDeleteDialog
          open={openTaskDeleteDialog}
          selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
          setItem={setSelectedTask}
          key={selectedTask?._id}
          onClose={() => {
            setSelectedTask(undefined);
            setOpenTaskDeleteDialog(false);
          }}
        />
      </PermissionWrapper>
    </Box>
  );
});

TaskListKanbanComponent.displayName = 'TaskListKanbanComponent';

export default TaskListKanbanComponent;

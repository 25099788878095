import dashboardLocale from '../domains/01-dashboard/locales/fr';
import productAndServiceLocale from '../domains/08-productAndService/locales/fr';
import organizationalChartLocale from '../domains/02-organizationalChart/locales/fr';
import leadLocale from '../domains/03-lead/locales/fr';
import customerLocale from '../domains/04-customer/locales/fr';
import preSaleLocale from '../domains/05-preSale/locales/fr';
import saleLocale from '../domains/06-sale/locales/fr';
import pricingLocale from '../domains/16-pricing/locales/fr';
import calendarLocale from '../domains/12-calendar/locales/fr';
import contractLocale from '../domains/07-contract/locales/fr';
import supportLocale from '../domains/15-support/locales/fr';
import reportLocale from '../domains/13-report/locales/fr';
import taskLocale from '../domains/11-task/locales/fr';
import projectLocale from '../domains/10-project/locales/fr';
import expenseLocale from '../domains/09-expense/locales/fr';
import noteLocale from '../domains/14-note/locales/fr';

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  productAndService: {
    ...productAndServiceLocale,
  },
  customer: {
    ...customerLocale,
  },
  preSale: {
    ...preSaleLocale,
  },
  sale: {
    ...saleLocale,
  },
  pricing: {
    ...pricingLocale,
  },
  calendar: {
    ...calendarLocale,
  },
  contract: {
    ...contractLocale,
  },
  support: {
    ...supportLocale,
  },
  report: {
    ...reportLocale,
  },
  task: {
    ...taskLocale,
  },
  project: {
    ...projectLocale,
  },
  expense: {
    ...expenseLocale,
  },
  note: {
    ...noteLocale,
  },

  ////////////////////////////////////////////////////////////////
  // common used names
  ////////////////////////////////////////////////////////////////
  preSaleLabel: 'Pré-Vente',
  preSales: 'Pré-Ventes',
  contactDetails: 'Détails',
  localization: 'Localisation',
  info: 'Info',
  about: 'À propos',
  contact: 'Contact',
  record: 'Enregistrement',
  records: 'Enregistrements',
  tracking: 'Suivi',
  pricingLabel: 'Tarification',
  dashboardLabel: 'Tableau de bord',
  marketing: 'Marketing',
  campaigns: 'Campagnes',
  emails: 'Emails',
  sms: 'SMS',
  socialMedia: 'Médias Sociaux',
  salesLabel: 'Ventes',
  businessIntelligence: 'Business Intelligence',
  calendarLabel: 'Calendrier',
  supportLabel: 'Ticket',
  accountMenu: 'Menu du Compte',
  profile: 'Profil',
  myAccount: 'Mon Compte',
  logout: 'Déconnexion',
  searchPlaceholder: 'Essayez de rechercher "insights"',
  searchAnIcon: 'Rechercher une Icône',

  // --------------------
  // settings related
  // --------------------
  settings: 'Paramètres',
  english: 'Anglais',
  turkish: 'Turc',
  french: 'Français',
  german: 'Allemand',
  italian: 'Italien',
  portuguese: 'Portugais',
  russian: 'Russe',
  spanish: 'Espagnol',
  dutch: 'Néerlandais',
  darkMode: 'Mode Sombre',
  lightMode: 'Mode Clair',
  languageSelectLabel: 'Sélectionner la Langue',

  ////////////////////////////////////////////////////////////////
  // common action texts
  ////////////////////////////////////////////////////////////////
  actions: 'Actions',
  yes: 'Oui',
  no: 'Non',
  importExport: 'Importer/Exporter',
  view: 'Voir',
  list: 'Liste',
  create: 'Créer',
  update: 'Mettre à jour',
  delete: 'Supprimer',
  edit: 'Modifier',
  cancel: 'Annuler',
  downloadSuccessful: 'Téléchargement réussi',
  export: 'Exporter',
  import: 'Importer',
  exportData: 'Exporter les Données',
  importData: 'Importer les Données',
  showMore: 'Afficher Plus',
  selectColor: 'Sélectionner la Couleur',
  justForMe: 'Juste Pour Moi',

  ////////////////////////////////////////////////////////////////
  // common situational texts
  ////////////////////////////////////////////////////////////////
  total: 'Total',
  weeklyTotal: 'Total Hebdomadaire',
  hasCreatedAt: 'a été créé le ',
  createdBy: 'Créé par ',
  updatedBy: 'Mis à jour par ',
  uploadLogo: 'Télécharger le Logo',
  selectIconTitle: 'Sélectionner une Icône',
  searchIcons: 'Rechercher des Icônes',
  uploadPictureText: 'Télécharger une image',
  searchForAnIcon: 'Rechercher une Icône',
  selectedIcon: 'Icône Sélectionnée:',
  signedUser: 'Utilisateur Connecté',

  // --------------------
  // delete dialog
  // --------------------
  deletedEntitySuccessfully: 'Supprimé avec succès',
  deleteEntityContent: 'Êtes-vous sûr de vouloir supprimer? Cette action ne peut pas être annulée.',
  deleteEntityTitle: 'Supprimer',

  // --------------------
  // organization
  // --------------------
  mustCreateOrganizationTitle: 'Créer une Organisation',
  mustCreateOrganizationContent: 'Vous devez créer une organisation pour utiliser le panneau.',
  mustCreateOrganizationSuccess: 'Organisation créée avec succès.',

  // --------------------
  // import export
  // --------------------
  importedError: 'Erreur d\'importation',
  importedSuccessfully: 'Importé avec succès',
  downloadExample: 'Télécharger l\'exemple',
  downloadFormError: 'Erreur de téléchargement du formulaire',
  downloadFormSuccessfully: 'Formulaire téléchargé avec succès',
  downloadExampleFormSuccessfully: 'Formulaire d\'exemple téléchargé avec succès',
  downloadExampleFormError: 'Erreur de téléchargement du formulaire d\'exemple',

  // --------------------
  // estimate
  // --------------------
  addEstimate: 'Ajouter une Estimation',
  noEstimate: 'Il n\'y a pas d\'estimations',
  noEstimateContent1: 'Estimations non trouvées',
  additionalInfo: 'Informations Supplémentaires',

  // --------------------
  // proposal
  // --------------------
  addProposal: 'Ajouter une Proposition',
  noProposal: 'Il n\'y a pas de propositions',
  lastProposal: 'Dernière Proposition',
  proposalSend: 'Proposition Envoyée',
  noProposalSend: 'Aucune proposition envoyée',
  noProposalContent1: 'Propositions non trouvées',
  sendEmailOnExpiration: 'Envoyer un email à l\'expiration',
  testimonialCaseStudies: 'Études de Cas Témoignages',
  projectTimeline: 'Calendrier du Projet',
  executiveSummary: 'Résumé Exécutif',
  costBreakdown: 'Ventilation des Coûts',
  conclusion: 'Conclusion',
  projectorServiceDesc: 'Description Détaillée du Projet/Service',
  noSalentAgents: 'Il n\'y a pas d\'agents commerciaux',

  // --------------------
  // document
  // --------------------
  addDocument: 'Ajouter un Document',
  noDocument: 'Il n\'y a pas de documents',
  noDocumentContent1: 'Documents non trouvés',

  // --------------------
  // task
  // --------------------
  noTask: 'Il n\'y a pas de tâches',
  noTaskContent1: 'Tâches non trouvées',

  // --------------------
  // activity
  // --------------------
  noActivity: 'Il n\'y a pas d\'activités',
  addActivity: 'Ajouter une Activité',
  noActivityContent1: 'Activités non trouvées',
  noActivityContent2: 'Il n\'y a pas d\'activités pour cette entité',

  // --------------------
  // note
  // --------------------
  addNote: 'Ajouter une Note',
  noNote: 'Il n\'y a pas de notes',
  emptyStateNoteList1: 'Notes non trouvées',
  noteDate: 'Date de la Note',
  noteUsers: 'Utilisateurs de la Note',
  noteTitle: 'Titre',

  // --------------------
  // reminder
  // --------------------
  addReminder: 'Ajouter un Rappel',
  noReminder: 'Il n\'y a pas de rappels',
  emptyStateReminderList1: 'Rappels non trouvés',
  remindDate: 'Date de Rappel',
  remindUsers: 'Rappeler aux Utilisateurs',
  remindTitle: 'Titre',

  // --------------------
  // appointment
  // --------------------
  noAppointments: 'Il n\'y a pas de rendez-vous',
  makeAnAppointment: 'Prendre un Rendez-vous',

  // --------------------
  // assignee
  // --------------------
  assignees: 'Assignés',
  noAssignees: 'Pas d\'Assignés',
  addAssignee: 'Ajouter un Assigné',

  // --------------------
  // followers
  // --------------------
  followers: 'Abonnés',
  noFollowers: 'Pas d\'Abonnés',

  // --------------------
  // product and services
  // --------------------
  addProduct: 'Ajouter un Produit',
  addProductOrService: 'Ajouter un Produit ou Service',
  editProdutctOrService: 'Modifier un Produit ou Service',
  productType: 'Type de Produit',
  editProduct: 'Modifier le Produit',
  product: 'Produit',

  // --------------------
  // ticket
  // --------------------
  subject: 'Sujet',
  contactName: 'Nom du Contact',
  tickets: 'Tickets',

  ////////////////////////////////////////////////////////////////
  // common used words
  ////////////////////////////////////////////////////////////////
  of: 'de',

  ////////////////////////////////////////////////////////////////
  // common used entity names
  ////////////////////////////////////////////////////////////////
  leadLabel: 'Lead',
  leads: 'Leads',
  leadForms: 'Formulaires de Lead',
  leadStatuses: 'Statuts de Lead',
  leadSources: 'Sources de Lead',
  customerLabel: 'Client',
  customers: 'Clients',
  estimateLabel: 'Estimation',
  estimates: 'Estimations',
  proposalLabel: 'Proposition',
  proposals: 'Propositions',
  invoiceLabel: 'Facture',
  invoices: 'Factures',
  productsServices: 'Produits & Services',
  activityLabel: 'Activité',
  activities: 'Activités',
  noteLabel: 'Note',
  notes: 'Notes',
  documentLabel: 'Document',
  documents: 'Documents',
  appointmentLabel: 'Rendez-vous',
  appointments: 'Rendez-vous',
  reminderLabel: 'Rappels',
  reminders: 'Rappels',
  departmentLabel: 'Département',
  departments: 'Départements',
  contractLabel: 'Contrat',
  contractTemplate: 'Modèle de Contrat',
  contracts: 'Contrats',
  paymentLabel: 'Paiement',
  payments: 'Paiements',
  organizationalChartLabel: 'Organigramme',
  expenseLabel: 'Dépense',
  expenses: 'Dépenses',
  productAndServiceLabel: 'Produits & Services',
  projectLabel: 'Projet',
  projects: 'Projets',
  taskLabel: 'Tâche',
  tasks: 'Tâches',
  reportLabel: 'Rapport',
  reports: 'Rapports',
  billingInformation: 'Informations de Facturation',
  billingInfo: 'Info de Facturation',
  otherBillingInformation: 'Autres Informations de Facturation',
  shippingInfo: 'Info d\'Expédition',
  contactInformation: 'Informations de Contact',
  otherContactInformation: 'Autres Informations de Contact',
  warningBillingInfo: 'Une fois qu\'un champ est rempli, tous les champs doivent être complétés.',
  addRole: 'Ajouter un Rôle',

  ////////////////////////////////////////////////////////////////
  // common fields
  ////////////////////////////////////////////////////////////////
  color: 'Couleur',
  icon: 'Icône',
  index: 'Index',
  title: 'Titre',
  fullName: 'Nom Complet',
  firstName: 'Prénom',
  lastName: 'Nom',
  surname: 'Nom de famille',
  convert: 'Convertir',
  country: 'Pays',
  state: 'État',
  city: 'Ville',
  industry: 'Industrie',
  address: 'Adresse',
  website: 'Site Web',
  emailAddress: 'Adresse Email',
  name: 'Nom',
  companyName: 'Nom de l\'Entreprise',
  companyEmail: 'Email de l\'Entreprise',
  companyPhone: 'Téléphone de l\'Entreprise',
  timezone: 'Fuseau Horaire',
  companyLogo: 'Logo de l\'Entreprise',
  language: 'Langue',
  department: 'Département',
  email: 'Email',
  phoneNumber: 'Numéro de Téléphone',
  type: 'Type',
  createdAt: 'Créé Le',
  updatedAt: 'Mis à Jour Le',
  prefix: 'Préfixe',
  tags: 'Tags',
  tagsPlaceholder: 'Tags',
  addTagsPlaceholder: 'Ajouter des Tags',
  addNewOption: 'Ajouter une nouvelle option',
  enterText: 'Entrer du texte...',
  company: 'Entreprise',
  sector: 'Secteur',
  phone: 'Téléphone',
  content: 'Contenu',
  priority: 'Priorité',
  position: 'Position',
  status: 'Statut',
  source: 'Source',
  date: 'Date',
  expireDate: 'Date d\'Expiration',
  totalPrice: 'Prix Total',
  currency: 'Devise',
  category: 'Catégorie',
  parentCategory: 'Catégorie Parent',
  vat: 'Numéro de TVA',
  zip: 'Code Postal',
  street: 'Rue',
  taxOffice: 'Bureau des Impôts',
  target: 'Cible',
  targetType: 'Type de Cible',
  description: 'Description',
  birthDate: 'Date de Naissance',
  gender: 'Genre',
  isPrimary: 'Est Principal',
  prospectName: 'Nom du Prospect',
  prospectAddress: 'Adresse du Prospect',
  prospectContactName: 'Nom du Contact du Prospect',
  prospectType: 'Type de Prospect',
  leadValue: 'Valeur du Lead',
  estimateId: 'Id d\'Estimation',
  proposalId: 'Id de Proposition',
  invoiceId: 'Id de Facture',
  paymentId: 'Id de Paiement',
  returnId: 'Id de Retour',
  expenseId: 'Id de Dépense',
  customerName: 'Nom du Client',
  customerEmail: 'Email du Client',
  customerType: 'Type de Client',
  finalPrice: 'Prix Final',
  paymentMethod: 'Méthode de Paiement',
  confirmationNumber: 'Numéro de Confirmation',
  amoundPaid: 'Montant Payé',
  refundAmount: 'Montant du Remboursement',
  isOpen: 'Est Ouvert',
  resolveReason: 'Raison de Résolution',
  disapproveReason: 'Raison de Désapprobation',
  sku: 'SKU',
  priceType: 'Type de Prix',
  price: 'Prix',
  discountedPrice: 'Prix Réduit',
  discount: 'Remise',
  unit: 'Unité',
  unitPrice: 'Prix Unitaire',
  natTaxRate: 'Taux de Taxe National',
  intTaxRate: 'Taux de Taxe International',
  selectRoles: 'Sélectionner les Rôles',
  timeZone: 'Fuseau Horaire',
  prospect: 'Prospect',
  prospectTargetEntityRef: 'Réf d\'Entité Cible du Prospect',
  prospectTarget: 'Cible du Prospect',
  prospectVAT: 'TVA du Prospect',
  prospectPhoneNumber: 'Numéro de Téléphone du Prospect',
  prospectZIP: 'Code Postal du Prospect',
  contactFirstName: 'Prénom du Contact',
  contactLastName: 'Nom du Contact',
  contactEmail: 'Email du Contact',
  expiration: 'Expiration',
  detailedInformation: 'Informations Détaillées',
  projectOrServiceDesc: 'Description du Projet/Service',
  scopeOfWork: 'Portée du Travail',
  assumptionsAndExclusions: 'Hypothèses et Exclusions',
  paymentTerms: 'Conditions de Paiement',
  products: 'Produits',
  services: 'Services',
  productName: 'Nom du Produit',
  productDescription: 'Description du Produit',
  productUnit: 'Unité du Produit',
  quantity: 'Quantité',
  tax: 'Taxe',
  extraInformation: 'Informations Supplémentaires',
  offers: 'Offres',
  reference: 'Référence',
  allowComments: 'Autoriser les Commentaires',
  totally: 'Totalement',
  subTotal: 'Sous-Total',
  taxTotal: 'Total des Taxes',
  extraDiscount: 'Remise Supplémentaire',
  shippingTotal: 'Coût Total d\'Expédition',
  salesAgents: 'Agents Commerciaux',
  legal: 'Juridique',
  useLegalNDA: 'Utiliser NDA Juridique',
  useLegalTermAndCond: 'Utiliser Termes et Conditions Juridiques',
  signature: 'Signature',
  signerUser: 'Utilisateur Signataire',
  signedDate: 'Date de Signature',
  generalInfo: 'Informations Générales',
  startDate: 'Date de Début',
  dueDate: 'Date d\'Échéance',
  endDate: 'Date de Fin',
  expirationEmailSentDate: 'Date d\'Envoi de l\'Email d\'Expiration',
  allowedPaymentMethods: 'Méthodes de Paiement Autorisées',
  sendEmail: 'Envoyer un Email',
  identityNumber: 'Numéro d\'Identité',
  recurringCount: 'Nombre de Récurrences',
  isRecurring: 'Est Récurrent',
  recurringFrequency: 'Fréquence de Récurrence',
  recurringDueDate: 'Date d\'Échéance Récurrente',
  paymentID: 'ID de Paiement',
  paymentPrefix: 'Préfixe de Paiement',
  returnPrefix: 'Préfixe de Retour',
  paymentDate: 'Date de Paiement',
  amountPaid: 'Montant Payé',
  invoiceID: 'ID de Facture',
  targetDetail: 'Détail de la Cible',
  productsAndServices: 'Produits & Services',
  popularSearch: 'Recherche Populaire',
  returnReason: 'Raison du Retour',
  partialReturn: 'Retour Partiel',
  amount: 'Montant',
  relationship: 'Relation',
  relationshipType: 'Type de Relation',
  relatedEntity: 'Entité Liée',
  relatedEstimate: 'Estimation Liée',
  relatedProposal: 'Proposition Liée',
  relatedTask: 'Tâche Liée',
  relatedProject: 'Projet Lié',
  relatedLead: 'Lead Lié',
  relatedCustomer: 'Client Lié',
  relatedInvoice: 'Facture Liée',
  relatedPayment: 'Paiement Lié',
  relatedUser: 'Utilisateur Lié',
  relatedExpense: 'Dépense Liée',
  relatedContract: 'Contrat Lié',
  order: 'Commande',

  ////////////////////////////////////////////////////////////////
  // SEARCH
  ////////////////////////////////////////////////////////////////
  leadCreate: 'Créer un Lead',
  leadList: 'Liste des Leads',
  leadImport: 'Importer des Leads',
  customerCreate: 'Créer un Client',
  customerList: 'Liste des Clients',
  customerImport: 'Importer des Clients',
  estimateCreate: 'Créer une Estimation',
  estimateList: 'Liste des Estimations',
  proposalCreate: 'Créer une Proposition',
  proposalList: 'Liste des Propositions',
  invoiceCreate: 'Créer une Facture',
  invoiceList: 'Liste des Factures',
  paymentCreate: 'Créer un Paiement',
  paymentList: 'Liste des Paiements',
  returnCreate: 'Créer un Retour',
  returnList: 'Liste des Retours',
  contractCreate: 'Créer un Contrat',
  contractList: 'Liste des Contrats',
  productCreate: 'Créer un Produit',
  productList: 'Liste des Produits',
  serviceList: 'Liste des Services',
  serviceCreate: 'Créer un Service',
  expenseCreate: 'Créer une Dépense',
  expenseList: 'Liste des Dépenses',
  projectCreate: 'Créer un Projet',
  projectList: 'Liste des Projets',
  taskCreate: 'Créer une Tâche',
  taskList: 'Liste des Tâches',
  reminderList: 'Liste des Rappels',
  reminderCreate: 'Créer un Rappel',
  appointmentList: 'Liste des Rendez-vous',
  appointmentCreate: 'Créer un Rendez-vous',
  noteCreate: 'Créer une Note',
  noteList: 'Liste des Notes',
  ticketList: 'Liste des Tickets',
  ticketCreate: 'Créer un Ticket',
  noResultsFound: 'Aucun résultat trouvé',
  trySearching: 'Essayez de rechercher',
  hideButton: 'Masquer le Bouton',
};

export default locale;
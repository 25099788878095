import { Box, Card, Typography } from '@mui/material';
import Lottie from 'react-lottie-player';
import OkAnimation from 'assets/animations/ok.json';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';

export const PaymentSuccess = React.memo(() => {
  const { t } = useTranslation();
  const lottieRef = useRef<any>(null);
  localStorage.setItem('payment_status', 'success');

  useEffect(() => {
    const lottieInstance = lottieRef.current;
    return () => {
      if (lottieInstance) {
        lottieInstance.stop();
      }
    };
  }, []);

  return (
    <Box sx={{ height: '90dvh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: 0,
        }}
      >
        <Typography variant="h6" textAlign={'center'} mb={5}>
          {t('crm.pricing.pricing.paymentSuccess')}
        </Typography>
        <Lottie
          ref={lottieRef}
          animationData={OkAnimation}
          play
          loop={false}
          style={{ width: 150, height: 150 }}
        />
      </Card>
    </Box>
  );
});

const locale = {
  product: 'Productos',
  title: 'Productos',
  create: 'Crear Producto',
  update: 'Actualizar Producto',
  delete: 'Eliminar Producto',
  deleteConfirm: '¿Está seguro de que desea eliminar este estado: ',
  deletedSuccessfully: 'Producto eliminado con éxito',
  createProduct: 'Crear Producto',
  createSuccess: 'Producto creado con éxito',
  updateSuccess: 'Producto actualizado con éxito',
  name: 'Nombre',
  icon: 'Icono',
  showMore: 'Mostrar Más',
  detail: 'Detalle del Producto',
  type: 'Tipo',
  image: 'Imagen',
  sku: 'Código',
  description: 'Descripción',
  currency: 'Moneda',
  price: 'Precio',
  priceType: 'Tipo de Precio',
  discountedPrice: 'Precio con Descuento',
  unit: 'Unidad',
  categoryId: 'Categoría',
  natTaxRate: 'Tasa de Impuesto Nacional',
  intTaxRate: 'Tasa de Impuesto Internacional',
  unitPrice: 'Precio Unitario',
  categories: 'Categorías',

  emptyState1: 'Aún no hay productos registrados.',
  emptyState2:
    'Gestione y revise cualquier producto aquí para mantener sus registros precisos y satisfacer las necesidades de los clientes.',
};

export default locale; 
import {
  Box,
  Grid,
  Stack,
  Theme,
  useMediaQuery,
  Button,
  Typography,
  MenuList,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { listUsersRoute } from '../../../../routes/organizationalChart.base.route';
import { useUserDetailQuery } from 'apps/auth/context';
import { UserDetailProfileSectionComponent } from './UserDetail-profileSection.component';
import { MyTab, Iconify } from 'components';
import { UserDetailTabInfoSectionComponent } from './UserDetail-tab-infoSection.component';
import UserUpdateDrawerWrapper from '../update/UserUpdateDialog.wrapper';
import { DefaultDetailTabsComponent } from 'apps/crm/components/detail/DefaultDetail.tabs.component';
import { AssociatedLeadsTab } from 'apps/crm/domains/03-lead/subdomains/lead/components/AssociatedLeadsTab';
import { AssociatedCustomersTab } from 'apps/crm/domains/04-customer/subdomains/customer/components/AssociatedCustomersTab';
import { AssociatedEstimatesTab } from 'apps/crm/domains/05-preSale/subdomains/estimate/components/AssociatedEstimatesTab';
import { AssociatedProposalsTab } from 'apps/crm/domains/05-preSale/subdomains/proposal/components/AssociatedProposalsTab';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import {
  DocumentTargetType,
  IUserDetailResult,
  NoteTargetType,
  PermissionSubject,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import {
  useUserDocumentCreateManyMutation,
  useUserDocumentAddManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import { OverlayType } from 'components/dialog/ActionDialog';
import { UserTabDetailPermissionsTab } from './UserDetail-tab-permissions.component';
import { PermissionAction } from 'corede-common';
import { usePermissions } from 'permission/PermissionContext';
/**
 * @property children - list of components of the tabs. Each component should have props extending `DetailDrawerTabComponentProps` interface

 */
export const UserDetailComponent = () => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    navigate(listUsersRoute());
  }

  const icons = [
    <Iconify key="info" icon="fluent:info-32-regular" width={18} />,
    <Iconify key="person" icon="material-symbols:person-outline" width={18} />,
    <Iconify key="contact" icon="hugeicons:contact" width={18} />,
    <Iconify key="estimate" icon="teenyicons:cost-estimate-outline" width={18} />,
    <Iconify key="money" icon="nimbus:money" width={18} />,
    <Iconify key="bell" icon="iconoir:bell" width={18} />,
    <Iconify key="document" icon="fluent:document-32-regular" width={18} />,
    <Iconify key="task" icon="hugeicons:task-01" width={18} />,
    <Iconify key="product" icon="carbon:product" width={18} />,
    <Iconify key="activity" icon="tabler:activity" width={18} />,
  ] as React.ReactElement[];

  const { hasPermission } = usePermissions();
  const hasLeadPermission = hasPermission({
    subject: PermissionSubject.lead,
    action: PermissionAction.list,
  });

  const UserDetail = memo(UserDetailComponent);
  UserDetail.displayName = 'UserDetail';
  const hasCustomerPermission = hasPermission({
    subject: PermissionSubject.customer,
    action: PermissionAction.list,
  });
  const hasEstimatePermission = hasPermission({
    subject: PermissionSubject.estimate,
    action: PermissionAction.list,
  });
  const hasProposalPermission = hasPermission({
    subject: PermissionSubject.proposal,
    action: PermissionAction.list,
  });
  const hasNotePermission = hasPermission({
    subject: PermissionSubject.note,
    action: PermissionAction.list,
  });
  const hasReminderPermission = hasPermission({
    subject: PermissionSubject.reminder,
    action: PermissionAction.list,
  });
  const hasTaskPermission = hasPermission({
    subject: PermissionSubject.task,
    action: PermissionAction.list,
  });
  const hasDocumentPermission = hasPermission({
    subject: PermissionSubject.file,
    action: PermissionAction.list,
  });
  const hasPermissionForUserPermissionSubdomain = hasPermission({
    subject: PermissionSubject.userPermission,
    action: PermissionAction.list,
  });

  const tabNames = new Map<string, string>([['info', t('crm.info')]]);

  if (hasLeadPermission)
    tabNames.set('associatedLeads', t('crm.organizationalChart.user.associatedLeads'));
  if (hasCustomerPermission)
    tabNames.set('associatedCustomers', t('crm.organizationalChart.user.associatedCustomers'));
  if (hasEstimatePermission)
    tabNames.set('associatedEstimates', t('crm.organizationalChart.user.associatedEstimates'));
  if (hasProposalPermission)
    tabNames.set('associatedProposals', t('crm.organizationalChart.user.associatedProposals'));
  if (hasNotePermission) tabNames.set('notes', t('crm.notes'));
  if (hasReminderPermission) tabNames.set('reminders', t('crm.reminders'));
  if (hasTaskPermission) tabNames.set('tasks', t('crm.tasks'));
  if (hasDocumentPermission) tabNames.set('documents', t('crm.documentLabel'));
  if (hasPermissionForUserPermissionSubdomain)
    tabNames.set('permissions', t('crm.organizationalChart.user.permissions'));

  const tabKeys = Array.from(tabNames.keys());
  const tabValues = Array.from(tabNames.values());
  const firstSelectedTab = tabNames.get(tabKeys.at(0) ?? '');

  // useStates
  const [selectedTabName, setSelectedTabName] = useState(firstSelectedTab);
  const [openUserUpdateDrawer, setOpenUserUpdateDrawer] = useState(false);

  // queries
  const { data: userData } = useUserDetailQuery({
    input: {
      _id: id || '',
    },
  });

  return (
    <Grid item xs={12}>
      {!downMd && (
        <HeaderBase title={t('crm.organizationalChart.user.detail')} showBackButton={true} />
      )}

      <Stack
        direction={'column'}
        mt={2}
        borderRadius={3}
        sx={{ boxShadow: downMd ? 'none' : '0px 3px 12px 0px rgba(35, 32, 32, 0.1)' }}
      >
        {!downMd && (
          <Box
            width={'100%'}
            sx={{
              borderTopLeftRadius: '24px',
              borderTopRightRadius: '24px',
              height: '72px',
              bgcolor: 'secondary.extraLight',
            }}
          />
        )}

        <Stack direction={'column'} gap={1}>
          {!downMd && <UserDetailProfileSectionComponent userData={userData} />}

          {downMd && (
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <Button
                sx={{
                  borderRadius: '50%',
                  width: 24,
                  height: 24,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid',
                  padding: 0,
                  minWidth: '24px',
                }}
                onClick={() => setSelectedTabName('')}
              >
                <Iconify icon={'iconamoon:arrow-left-2-light'} width={18} />
              </Button>

              <Typography variant="body1">
                {selectedTabName !== '' ? selectedTabName : t('crm.info')}
              </Typography>
            </Stack>
          )}

          {selectedTabName === '' && (
            <MenuList>
              {tabValues.map((tabName, index) => (
                <MenuItem
                  key={tabName}
                  sx={{
                    width: '100%',
                    borderRadius: '6px',
                    boxShadow: '0px 2px 5px 0px rgba(35, 32, 32, 0.1)',
                    border: '1px solid',
                    borderColor: 'divider',
                    boxSizing: 'border-box',
                    p: 1.5,
                    mb: 1,
                  }}
                  onClick={() => setSelectedTabName(tabName)}
                >
                  <ListItemIcon
                    sx={{
                      width: 32,
                      height: 32,
                      backgroundColor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mr: 1,
                    }}
                  >
                    {icons[index]}
                  </ListItemIcon>
                  <ListItemText>
                    {' '}
                    <Typography variant="body1">{tabName}</Typography>
                  </ListItemText>
                  <Iconify icon={'iconamoon:arrow-right-2-light'} width={18} />
                </MenuItem>
              ))}
            </MenuList>
          )}

          {!downMd && (
            <Stack width={'100%'} direction={'row'} justifyContent={'center'}>
              <MyTab
                labels={tabValues}
                setSelectedTab={setSelectedTabName}
                selectedTab={selectedTabName}
                icons={icons}
              />
            </Stack>
          )}

          {downMd && <Divider />}
        </Stack>

        {selectedTabName !== '' && (
          <DefaultDetailTabsComponent
            tabNames={tabNames}
            selectedTabName={selectedTabName}
            entityData={userData}
          >
            <UserDetailTabInfoSectionComponent
              userData={userData}
              setOpenUserUpdateDrawer={setOpenUserUpdateDrawer}
              tabKey="info"
              name="crm.info"
            />

            <UserTabDetailPermissionsTab
              tabKey="permissions"
              name="crm.permissions"
              entityData={userData}
              hasPermission={hasPermissionForUserPermissionSubdomain}
            />

            <AssociatedLeadsTab
              tabKey="associatedLeads"
              name="crm.organizationalChart.user.associatedLeads"
              hasPermission={hasLeadPermission}
            />

            <AssociatedCustomersTab
              tabKey="associatedCustomers"
              name="crm.organizationalChart.user.associatedCustomers"
              hasPermission={hasCustomerPermission}
            />

            <AssociatedEstimatesTab
              tabKey="associatedEstimates"
              name="crm.organizationalChart.user.associatedEstimates"
              hasPermission={hasEstimatePermission}
            />

            <AssociatedProposalsTab
              tabKey="associatedProposals"
              name="crm.organizationalChart.user.associatedProposals"
              hasPermission={hasProposalPermission}
            />

            <NoteListTabComponent
              targetId={userData?._id ?? ''}
              targetEntityRef={NoteTargetType.User}
              targetDisplayName={userData?.name + ' ' + userData?.surname}
              tabKey="notes"
              name="crm.notes"
              overlayType={OverlayType.drawer}
              hasPermission={hasNotePermission}
            />

            <ReminderListTabComponent
              targetId={userData?._id ?? ''}
              targetEntityRef={ReminderTargetType.User}
              targetDisplayName={userData?.name + ' ' + userData?.surname}
              tabKey="reminders"
              name="crm.reminders"
              overlayType={OverlayType.drawer}
              hasPermission={hasNotePermission}
            />

            <TaskListTabComponent
              tabKey="tasks"
              name="crm.tasks"
              targetId={userData?._id ?? ''}
              targetEntityRef={TaskTargetType.User}
              entityData={userData}
              nameTranslation={t('crm.tasks')}
              overlayType={OverlayType.drawer}
              hasPermission={hasTaskPermission}
            />

            <DocumentListTabComponent
              targetId={userData?._id ?? ''}
              targetEntityRef={DocumentTargetType.User}
              tabKey={'documents'}
              name={'crm.documents'}
              useDocumentCreateManyMutation={useUserDocumentCreateManyMutation}
              useDocumentAddManyMutation={useUserDocumentAddManyMutation}
              smallSize
              hasPermission={hasDocumentPermission}
            />

            {/* <DefaultEmptyTabComponent
              title={t('crm.activities')}
              emptyStateContent={t('crm.noActivityContent1')}
              tabKey="activities"
              nameTranslation={t('crm.activities')}
            /> */}
          </DefaultDetailTabsComponent>
        )}

        <Overlays
          openUserUpdateDrawer={openUserUpdateDrawer}
          setOpenUserUpdateDrawer={setOpenUserUpdateDrawer}
          userData={userData}
        />
      </Stack>
    </Grid>
  );
};

const Overlays = ({
  openUserUpdateDrawer,
  setOpenUserUpdateDrawer,
  userData,
}: {
  openUserUpdateDrawer: boolean;
  setOpenUserUpdateDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  userData: IUserDetailResult | undefined;
}) => {
  return (
    <>
      <UserUpdateDrawerWrapper
        open={openUserUpdateDrawer}
        setOpen={setOpenUserUpdateDrawer}
        selectedUser={userData}
      />
    </>
  );
};

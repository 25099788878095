const locale = {
  service: 'Servizi',
  title: 'Servizi',
  create: 'Crea Servizio',
  update: 'Aggiorna Servizio',
  delete: 'Elimina Servizio',
  deleteConfirm: 'Sei sicuro di voler eliminare questo stato: ',
  deletedSuccessfully: 'Servizio eliminato con successo',
  createService: 'Crea Servizio',
  createSuccess: 'Servizio creato con successo',
  updateSuccess: 'Servizio aggiornato con successo',
  name: 'Nome',
  icon: 'Icona',
  showMore: 'Mostra di più',

  detail: 'Dettaglio Servizio',
  type: 'Tipo',
  image: 'Immagine',
  sku: 'Codice',
  description: 'Descrizione',
  currency: 'Valuta',
  price: 'Prezzo',
  priceType: 'Tipo di Prezzo',
  discountedPrice: 'Prezzo Scontato',
  unit: 'Unità',
  categoryId: 'Categoria',
  natTaxRate: 'Aliquota Fiscale Nazionale',
  intTaxRate: 'Aliquota Fiscale Internazionale',

  categories: 'Categorie',
  rate: 'Tariffa',
  rateType: 'Tipo di Tariffa',
  discountedRate: 'Tariffa Scontata',
  emptyState1: 'Nessun servizio registrato finora.',
  emptyState2:
    'Gestisci e rivedi qui qualsiasi reso di servizio per mantenere i tuoi registri accurati e soddisfare le esigenze dei clienti.',
};

export default locale; 
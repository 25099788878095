const locale = {
  estimate: 'Devis',
  title: 'Devis',
  create: 'Créer un Devis',
  update: 'Mettre à jour le Devis',
  delete: 'Supprimer',
  view: 'Voir le Devis',
  list: 'Liste des Devis',
  detail: 'Détail du Devis',
  createdSuccessfully: 'Devis créé avec succès',
  updatedSuccessfully: 'Devis mis à jour avec succès',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ? Cette action ne peut pas être annulée.',
  deletedSuccessfully: 'Devis supprimé avec succès',
  createInfo: 'Vous pouvez créer de nouveaux devis ici.',
  prefix: 'Préfixe',
  timeZone: 'Fuseau Horaire',
  prospectTargetEntityId: 'Prospect',
  prospectTargetEntityRef: 'Réf. Prospect',
  prospectType: 'Type de Prospect',
  prospectName: 'Nom du Prospect',
  prospectVAT: 'TVA du Prospect',
  prospectPhoneNumber: 'Téléphone du Prospect',
  prospectCountry: 'Pays du Prospect',
  prospectState: 'Région du Prospect',
  prospectCity: 'Ville du Prospect',
  prospectZIP: 'Code Postal du Prospect',
  prospectAddress: 'Adresse du Prospect',
  prospectContactFirstName: 'Prénom du Contact Prospect',
  prospectContactLastName: 'Nom du Contact Prospect',
  prospectContactEmail: 'Email du Contact Prospect',
  language: 'Langue',
  date: 'Date',
  expireDate: 'Date d\'Expiration',
  sendEmailOnExpiration: 'Envoyer Email',
  expirationEmailSentDate: 'Date d\'Envoi de l\'Email d\'Expiration',
  projectOrServiceDesc: 'Description du Projet ou Service',
  paymentTerms: 'Conditions de Paiement',
  products: 'Produits',
  addProducts: 'Ajouter des Produits',
  offers: 'Offres',
  salesAgentIds: 'Agents Commerciaux',
  allowComments: 'Autoriser les Commentaires',
  legalNDAId: 'NDA',
  legalTermAndCondId: 'Conditions Générales',
  signerUserId: 'Utilisateur Signataire',
  listInfo: 'Liste de tous les devis.',
  importedError: 'Erreur d\'importation',
  importedSuccessfully: 'Importé avec succès',
  downloadFormError: 'Erreur de téléchargement du formulaire',
  downloadFormSuccessfully: 'Formulaire téléchargé avec succès',
  downloadExampleFormSuccessfully: 'Formulaire d\'exemple téléchargé avec succès',
  downloadExampleFormError: 'Erreur de téléchargement du formulaire d\'exemple',
  emptyState1: 'Aucun devis trouvé.',
  emptyState2: 'Vous pouvez créer ou importer des devis immédiatement.',
  createEstimate: 'Créer un Devis',
  importEstimate: 'Importer un Devis',
  importTitle: 'Importer des Devis',
  downloadExample: 'Télécharger un Exemple',
  importText1: 'Vous pouvez importer des devis depuis cette page.',
  importText2: 'Vous pouvez utiliser le formulaire d\'exemple ci-dessous pour publier vos devis.',
  importExport: 'Importer/Exporter',
  exportData: 'Exporter les Données',
  importData: 'Importer les Données',
  b2b: 'B2B',
  b2c: 'B2C',
  viewEstimate: 'Voir',
  edit: 'Modifier',
  junk: 'Indésirable',
  unJunk: 'Récupérer',
  addNewRole: 'Ajouter un Nouveau Rôle',
  actions: 'Actions',
  legalAgreements: 'Accords Juridiques',
  businessName: 'Nom de l\'Entreprise',
  governmentAgency: 'Organisme Gouvernemental',
  fullName: 'Nom Complet',
  VAT: 'TVA',
  phoneNumber: 'Numéro de Téléphone',
  country: 'Pays',
  state: 'Région',
  city: 'Ville',
  ZIP: 'Code Postal',
  sentDate: 'Date d\'Envoi',
  name: 'Nom',
  description: 'Description',
  productUnit: 'Unité',
  priceType: 'Type de Prix',
  service: 'Service',
  product: 'Produit',
  services: 'Services',
  references: 'Références',
  add: 'Ajouter',
  salesAgent: 'Agent Commercial',
  estimateCreateSuccess: 'Devis créé avec succès',

  addEstimate: 'Ajouter un Devis',
  noEstimateContent1: 'Aucun devis trouvé',
  noEstimate: 'Il n\'y a pas de devis',
  generalInfo: 'Informations Générales',
  sentSuccessfully: 'Devis envoyé avec succès',
  send: 'Envoyer le Devis',
  resend: 'Renvoyer le Devis',
  sendSureTitle: 'Envoyer le Devis',
  sendSureDesc: 'Êtes-vous sûr de vouloir envoyer ce devis ?',
  changeStatus: 'Changer le Statut',
  statusUpdatedSuccessfully: 'Statut mis à jour avec succès',

  // generalInfo
  generalInfoTitle: 'Informations Générales',
  estimateId: 'Devis',
  status: 'Statut',
  createdAt: 'Créé le',
  reference: 'Référence',
  createdBy: 'Créé par',
  updatedAt: 'Mis à jour le',
  notAvailable: 'Non Disponible',
  updateStatus: 'Mettre à jour le Statut',

  // customerInfo
  customerInfoTitle: 'Informations Client',
  contactFirstName: 'Prénom',
  contactLastName: 'Nom',
  contactEmail: 'Email',
  contactPhoneNumber: 'Numéro de Téléphone',
  address: 'Adresse',

  //productInfo
  price: 'Prix',
  productInfoTitle: 'Détails du Produit',
  productNameUnknown: 'Produit Inconnu',
  discountedPrice: 'Prix Remisé',
  quantity: 'Quantité',
  finalPrice: 'Prix Final',
  tax: 'Taux de TVA',
  noProducts: 'Aucun produit disponible.',

  //legalInfo
  legalInfoTitle: 'Informations Juridiques',
  ndaTitle: 'Accord de Confidentialité (NDA)',
  termsTitle: 'Conditions Générales',
  documentName: 'Nom du Document',
  documentDescription: 'Description',
  documentContent: 'Contenu',

  //financialInfo
  financialInfoTitle: 'Informations Financières',
  currency: 'Devise',
  subTotal: 'Sous-total',
  discount: 'Remise',
  taxTotal: 'Total TVA',
  shippingTotal: 'Total Livraison',
  total: 'Prix Total',
  noFinancialData: 'Aucune donnée financière disponible.',

  // documentInfo
  documentsTitle: 'Documents',
  documentNameUnknown: 'Document Inconnu',
  mimeType: 'Type de Fichier',
  download: 'Télécharger',
  noDocuments: 'Aucun document disponible.',

  // salesAgents
  salesAgentsTitle: 'Agents Commerciaux',
  unknownAgent: 'Agent Inconnu',
  noSalesAgents: 'Aucun agent commercial disponible.',

  //signature
  signatureTitle: 'Informations de Signature',
  additionalInfoTitle: 'Informations Supplémentaires',
  signature: 'Signature',
  signerUser: 'Utilisateur Signataire',

  //additionalInfo
  scopeOfWork: 'Portée du Travail',
  assumptionsAndExclusions: 'Hypothèses et Exclusions',
  useLegalNDA: 'Utiliser NDA Juridique',
  useLegalTermAndCond: 'Utiliser Conditions Générales',
  yes: 'Oui',
  no: 'Non',

  //tabs
  generalInformation: 'Informations Générales*',
  prospect: 'Prospect*',
  detailedInformation: 'Informations Détaillées',
  productsAndServices: 'Produits et Services*',
  salesAgents: 'Agents Commerciaux',
  legal: 'Juridique',
  signatureTab: 'Signature*',
};

export default locale; 
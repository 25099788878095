const locale = {
  // fields
  users: 'Usuarios de Nota',
  date: 'Fecha de Nota',
  title: 'Listar Notas',
  detail: 'Detalle de Nota',
  sharedList: "Notas Compartidas",
  privateList: "Notas Privadas",
  ownList: "Mis Notas",
  allList: "Todas las Notas",

  // actions
  create: 'Crear Nota',
  add: 'Añadir Nota',
  update: 'Actualizar Nota',
  delete: 'Eliminar Nota',

  // situational text
  tagsPlaceholder: 'Por favor, añada etiquetas y presione enter',
  createSuccess: 'Nota creada con éxito',
  updateSuccess: 'Nota actualizada con éxito',
  deletedSuccessfully: 'Nota eliminada con éxito',
  deleteContent: '¿Está seguro de que desea eliminar esta nota? Esta acción no se puede deshacer.',
  emptyState1: 'Aún no se han añadido notas.',
  emptyState2:
    'Cree y organice sus notas aquí para realizar un seguimiento de detalles importantes y conversaciones en su CRM.',

  createdBy: "Nota creada por",
};

export default locale; 
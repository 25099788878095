const locale = {
  demo: 'Demo',
  title: 'Cos\'è la <highlight>CoCRM</highlight> Community?',
  description:
    'In CoCRM, oltre a fornirti una gestione efficace dei tuoi processi aziendali e di relazione con i clienti, offriamo anche uno spazio di collaborazione progettato per espandere la tua rete e le tue opportunità di business.',
  featuresTitle: 'Con la Community CoCRM, puoi:',
  features1: 'Ottenere nuove opportunità di business attraverso referenze.',
  features2: 'Connetterti con fornitori affidabili.',
  features3: 'Trovare partner commerciali fidati.',
  features4: 'Espandere la tua rete per offrire servizi a 360° ai tuoi clienti.',
  buttonTitle: 'Unisciti alla Community',
  formTitle: 'Iscriviti alla Lista d\'Attesa',
  formDescription:
    'Compila il modulo qui sotto e ti contatteremo il prima possibile.',
  sendSuccess: 'Grazie! Ti risponderemo il prima possibile.',
  formButtonTitle: 'Iscriviti',
  fullName: 'Nome Completo',
  email: 'Email',
  phone: 'Telefono',
  orgName: 'Nome dell\'Organizzazione',
};

export default locale;

const locale = {
  // actions
  create: 'Создать Клиента',
  update: 'Обновить Клиента',
  detail: 'Детали Клиента',
  view: 'Просмотр Клиента',
  list: 'Список Клиентов',
  addNewRole: 'Добавить Новую Роль',
  tagsPlaceholder: 'Добавить теги',
  listInfo: 'Список всех клиентов.',
  weeklyReport: 'Еженедельный Отчет',
  copyBillingInfo: 'Копировать Платежную Информацию',

  // situational messages
  createdSuccessfully: 'Клиент успешно создан',
  updatedSuccessfully: 'Клиент успешно обновлен',
  deleteConfirm: 'Вы уверены, что хотите удалить этого клиента? Это действие нельзя отменить.',
  deletedSuccessfully: 'Клиент успешно удален',
  createInfo: 'Здесь вы можете создавать новых клиентов.',
  updateCustomerSuccess: 'Клиент успешно обновлен',
  createCustomerSuccess: 'Клиент успешно создан',
  emptyState1: 'Клиенты не найдены',
  emptyState2: 'Пожалуйста, создайте нового клиента',

  // import
  importCustomer: 'Импорт Клиента',
  import: 'Импорт Клиента',
  importTitle: 'Импорт Клиентов',
  importText1: 'Вы можете импортировать клиентов с этой страницы.',
  importText2: 'Вы можете использовать образец формы ниже для публикации ваших клиентов.',

  // fields and names
  title: 'Клиенты',
  customer: 'Клиент',
  customers: 'Клиенты',
  assigneds: 'Назначенные',
  qualification: 'Квалификация',
  justOwn: 'Только Свои',
  isJunk: 'Нежелательный',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Нежелательный',
  unJunk: 'Не Нежелательный',
  prospectName: 'Имя Потенциального Клиента',
  businessType: 'Тип Бизнеса',
  customerType: 'Тип Клиента',
  customerCount: 'клиенты',
  customerValue: 'Ценность Клиента',

  // contact
  addContact: 'Добавить Контакт',
  contactedDate: 'Дата Контакта',
  emptyStateContactContent1: 'Контакты не найдены.',
  contactDetail: 'Детали Контакта',
  contactHistory: 'История Контактов',
  lastContacted: 'Последний Контакт',
  lastContact: 'Последний Контакт',
  contacted: 'Связались',
  otherContacts: 'Другие Контакты',

  onSiteEstimateWith: 'Оценка на месте с',

  // new
  customerListTitle: 'Список Клиентов',
  headerTitle: 'Клиенты',
  relatedCustomers: 'Связанные Клиенты',
  b2bB2c: 'B2B / B2C',
  customerNameLabel: 'Имя Клиента',
  customerEmailLabel: 'Email Клиента',
  customerPhoneLabel: 'Телефон Клиента',
  customerTypeLabel: 'Тип Клиента',
  customerPictureLabel: 'Изображение Клиента',
  customerLanguageLabel: 'Язык Клиента',

  createSuccess: 'Клиент успешно создан',

  prospectType: 'Тип Потенциального Клиента',
  prospectAddress: 'Адрес Потенциального Клиента',
  prospectContactName: 'Имя Контакта Потенциального Клиента',

  // accordions
  primaryContact: 'Основной Контакт*',
  primaryBillingInfo: 'Основная Платежная Информация',
  primaryShippingInfo: 'Основная Информация о Доставке',
  detailInformation: 'Подробная Информация',
  address: 'Адрес',

  // labels
  welcome: 'Добро пожаловать',
  description: 'Ваш прогресс с клиентами на этой неделе потрясающий. Давайте продолжим.',

  // new keys
  customerDetail: 'Детали Клиента',
  viewMüşteri: 'Просмотр',
  createMüşteri: 'Создать Клиента',
  columnParentCategory: 'Родительская Категория',
  columnActions: 'Действия',
  createCustomerTitle: 'Создать Клиента',
  uploadPictureText: 'Загрузить Изображение',
  createCustomerButton: 'Создать Клиента',
  selectIconTitle: 'Выбрать Иконку',
  searchIcons: 'Поиск Иконок',
  searchForAnIcon: 'Поиск иконки',
};

export default locale; 
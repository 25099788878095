import { Grid, InputLabel, Avatar, Typography, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import ActionDialog from 'components/dialog/ActionDialog';
import { IUser } from 'corede-common-cocrm';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

export const EditAssigneeDialog = (props: {
  openAssigneeAddDialog: boolean;
  setOpenAssigneeAddDialog: Dispatch<React.SetStateAction<boolean>>;
  assignedUserList: IUser[];
  taskUpdateLoading: boolean;
  handleDeleteAssignee: (user: IUser) => void;
}) => {
  const { t } = useTranslation();
  return (
    <ActionDialog
      open={props.openAssigneeAddDialog}
      onClose={() => props.setOpenAssigneeAddDialog(false)}
      buttonCancelTitle={t('close')}
      title={t('crm.editAssignees')}
      width={500}
      loading={props.taskUpdateLoading}
    >
      <Grid item xs={12} sx={{ mt: 1 }}>
        <InputLabel sx={{ ml: 1, mb: 1 }}>{t('crm.assignees')}</InputLabel>
        <Grid container direction="column" spacing={2}>
          {props.assignedUserList?.map((user) => (
            <Grid item key={user._id}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Avatar
                    alt={`${user.name} ${user.surname}`}
                    src={user.profileImage?.thumbnailPublicUrl}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="body1">
                    {user.name} {user.surname}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => props.handleDeleteAssignee(user)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </ActionDialog>
  );
};

const locale = {
  product: 'Prodotti',
  title: 'Prodotti',
  create: 'Crea Prodotto',
  update: 'Aggiorna Prodotto',
  delete: 'Elimina Prodotto',
  deleteConfirm: 'Sei sicuro di voler eliminare questo stato: ',
  deletedSuccessfully: 'Prodotto eliminato con successo',
  createProduct: 'Crea Prodotto',
  createSuccess: 'Prodotto creato con successo',
  updateSuccess: 'Prodotto aggiornato con successo',
  name: 'Nome',
  icon: 'Icona',
  showMore: 'Mostra di più',
  detail: 'Dettaglio Prodotto',
  type: 'Tipo',
  image: 'Immagine',
  sku: 'Codice',
  description: 'Descrizione',
  currency: 'Valuta',
  price: 'Prezzo',
  priceType: 'Tipo di Prezzo',
  discountedPrice: 'Prezzo Scontato',
  unit: 'Unità',
  categoryId: 'Categoria',
  natTaxRate: 'Aliquota Fiscale Nazionale',
  intTaxRate: 'Aliquota Fiscale Internazionale',
  unitPrice: 'Prezzo Unitario',
  categories: 'Categorie',

  emptyState1: 'Nessun prodotto registrato finora.',
  emptyState2:
    'Gestisci e rivedi qualsiasi prodotto qui per mantenere i tuoi registri accurati e soddisfare le esigenze dei clienti.',
};

export default locale; 
const locale = {
  organization: 'Organisation',
  organizations: 'Organisationen',
  organizationChart: 'Organisationsstruktur',
  update: 'Aktualisieren',
  delete: 'Löschen',
  view: 'Ansehen',
  createdSuccessfully: 'Benutzer erfolgreich erstellt',
  updatedSuccessfully: 'Benutzer erfolgreich aktualisiert',
  deleteConfirm: 'Sind Sie sicher, dass Sie das Benutzerkonto entfernen möchten "',
  deletedSuccessfully: 'Benutzer erfolgreich gelöscht',
  info: 'Sie können in diesem Bereich Benutzer zu Ihrem Team hinzufügen.',
  name: 'Name',
  email: 'E-Mail',
  surname: 'Nachname',
  viewProfile: 'Profil anzeigen',
  roles: 'Rollen',
  editChart: 'Diagramm bearbeiten',
  save: 'Speichern',
  editOrganizationChart: 'Organisationsstruktur bearbeiten',
  editOrganizationDetail: 'Ziehen Sie die Benutzer an die gewünschte Position.',
  createRole: 'Rolle erstellen',
  userList: 'Benutzerliste',
  rolesInfo: 'Sie können Rollen erstellen und ihnen Berechtigungen zuweisen.',
  createUserSuccess: 'Benutzer erfolgreich erstellt',
  chart: 'Diagrammansicht',
  list: 'Listenansicht',
  users: 'Benutzer',
  user: 'Benutzer',
  activeUsers: 'Aktive Benutzer',
  activeUser: 'Aktiver Benutzer',
  create: 'Erstellen',
  new: 'Neu',
  roleName: 'Rollenname',
  addRole: 'Hinzufügen',
  updateRole: 'Aktualisieren',
  deleteRole: 'Löschen',
  roleCreatedSuccess: 'Rolle erfolgreich erstellt',
  roleUpdatedSuccess: 'Rolle erfolgreich aktualisiert',
  roleDeletedSuccess: 'Rolle erfolgreich gelöscht',
  errorFetchingPermissions: 'Fehler beim Abrufen der Berechtigungen',
  errorFetchingRoles: 'Fehler beim Abrufen der Rollen',
  errorCreatingRole: 'Fehler beim Erstellen der Rolle',
  errorUpdatingRole: 'Fehler beim Aktualisieren der Rolle',
  errorDeletingRole: 'Fehler beim Löschen der Rolle',
  permissionUpdateSuccess: 'Benutzerberechtigungen wurden erfolgreich aktualisiert',
  subject: 'Betreff',
  capabilities: 'Fähigkeiten',
  allPermissions: 'Alle Berechtigungen',
  all: 'Alle',
  advancedOptions: 'Erweiterte Optionen',
  id: 'ID',
  emailAddress: 'E-Mail-Adresse',
  status: 'Status',
  emailVerified: 'E-Mail verifiziert',
  phoneNumber: 'Telefonnummer',
  country: 'Land',
  city: 'Stadt',
  phone: 'Telefonnummer',
  lastLoginDate: 'Letztes Anmeldedatum',
  createdAt: 'Erstellt am',
  actions: 'Aktionen',
  addNewRole: 'Neue Rolle hinzufügen',
  yes: 'Ja',
  no: 'Nein',
  details: 'Details',
  jobTitle: 'Berufsbezeichnung',
  deleteMessage: 'Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?',
  cancel: 'Abbrechen',
  userDeleteSuccess: 'Benutzer erfolgreich gelöscht',
  userDeleteError: 'Benutzer konnte nicht gelöscht werden',
  updateChartSuccessfully: 'Organisationsstruktur erfolgreich aktualisiert',
  address: 'Adresse',
  birthDate: 'Geburtsdatum',
  gender: 'Geschlecht',
  language: 'Sprache',
  description: 'Beschreibung',
  rolesAndPermissions: 'Rollen & Berechtigungen',
  ownPermissionsAlert: 'Sie können Ihre eigenen Berechtigungen nicht ändern.',
  zoom: 'Zoom',
  tree: 'Baumansicht',
  createSuccess: 'Organisationsstruktur erfolgreich erstellt',
  updateSuccess: 'Organisationsstruktur erfolgreich aktualisiert',
  updateError: 'Fehler beim Aktualisieren der Organisationsstruktur',
  fetchError: 'Fehler beim Abrufen der Organisationsstruktur',
  fetchSuccess: 'Organisationsstruktur erfolgreich abgerufen',
  undefinedUser: 'Undefinierter Benutzer',
  chartUpdateSuccess: 'Diagramm erfolgreich aktualisiert!',
  listView: 'Listenansicht',
  action: 'Aktion',
  actionScope: 'Aktionsbereich',
  userDetail: 'Benutzerdetails',
  admin: 'Administrator',
  department: 'Abteilung',
  addUser: 'Benutzer hinzufügen',
  createDepartment: 'Abteilung erstellen',
  updateUser: 'Benutzer aktualisieren',
  notDeleteOwnConfirm: 'Sie können sich nicht selbst löschen.',
  updateUserSuccess: 'Benutzer erfolgreich aktualisiert',
  removeAccount: 'Konto entfernen',
  profile: 'Profil',
  permissions: 'Berechtigungen',
  associatedLeads: 'Zugehörige Leads',
  associatedCustomers: 'Zugehörige Kunden',
  associatedEstimates: 'Zugehörige Angebote',
  associatedProposals: 'Zugehörige Vorschläge',
  associatedInvoices: 'Zugehörige Rechnungen',
  associatedTasks: 'Zugehörige Aufgaben',
  about: 'Über',
  detail: 'Benutzerdetails',
  users_: 'Benutzer',
  resendConfirmation: 'Bestätigung erneut senden',
  resendSuccess: 'Bestätigung erfolgreich gesendet',
};

export default locale; 
const locale = {
  leadForms: 'Moduli Lead',
  title: 'Moduli Lead',
  newForm: 'Nuovo Modulo',
  emptyStateDesc1:
    'I tuoi moduli lead appariranno qui man mano che li crei per acquisire preziose informazioni sui clienti.',
  emptyStateDesc2:
    'Gestisci e personalizza facilmente i tuoi moduli per assicurarti di raccogliere i dettagli giusti per ogni interazione.',

  create: 'Crea Modulo Lead',
  update: 'Aggiorna Modulo Lead',
  updateSuccess: 'Modulo lead aggiornato con successo',
  createSuccess: 'Modulo lead creato con successo',
  deleteSuccess: 'Modulo lead eliminato con successo',
  deleteConfirm: 'Sei sicuro di voler eliminare questo modulo lead?',
  deletedSuccessfully: 'Modulo lead eliminato con successo',

  // tabs
  detailedInfo: 'Informazioni Dettagliate',
  branding: 'Branding',
  formFields: 'Campi del Modulo',
  submission: 'Invio*',
  notification: 'Notifica',

  //fields
  addField: 'Aggiungi Campo',
  updateField: 'Aggiorna Campo',
  label: 'Etichetta',
  formName: 'Nome del Modulo',
  defaultLanguage: 'Lingua Predefinita',
  defaultPrefix: 'Prefisso Predefinito',
  defaultStatus: 'Stato Predefinito',
  defaultSource: 'Fonte Predefinita',
  defaultAssigned: 'Assegnazione Predefinita',
  defaultBusinessType: 'Tipo di Attività Predefinito',
  defaultType: 'Tipo Predefinito',
  defaultQualification: 'Qualificazione Predefinita',
  defaultTags: 'Tag Predefiniti',
  submitButtonText: 'Testo del Pulsante di Invio',
  header: 'Intestazione',
  isRequired: 'Obbligatorio',
  type: 'Tipo',
  defaultIsPublic: 'Pubblico per Impostazione Predefinita',
  followUpRedirectUrl: 'URL di Reindirizzamento per Follow-up',
  followUpMessage: 'Messaggio di Follow-up',
  specificUsersToNotify: 'Utenti Specifici da Notificare',
  willNotify: 'Notificherà',
  willNotifyResponsibleUsers: 'Notificherà gli Utenti Responsabili',
  leadField: 'Campo Lead',
  placeholder: 'Segnaposto',
  fullNamePlaceholder: 'Inserisci il tuo nome completo',
  emailPlaceholder: 'Inserisci la tua email',
  formTitle: 'Modulo',
};

export default locale; 
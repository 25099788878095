const locale = {
  somethingWentWrong: 'Etwas ist schief gelaufen!',
  loginSuccess: 'Erfolgreich eingeloggt',
  signinText: "Lass uns einloggen.",
  emailAddress: 'E-Mail-Adresse',
  password: 'Passwort',
  forgotPassword: 'Passwort vergessen?',
  login: 'Anmelden',
  continueWith: 'oder fortfahren mit',
  noAccount: "Noch kein Konto?",
  signUp: 'Registrieren',
  logoAlt: 'Logo',
  growYourBusiness: 'Alles was Sie brauchen, um Ihr',
  business: 'Unternehmen zu entwickeln',
  bgPattern: 'Hintergrundmuster',
  manageYourBusiness:
    'Verwalten Sie Ihr Unternehmen effizient, steigern Sie Ihre Verkäufe, erreichen Sie potenzielle Kunden und stärken Sie sich durch den Beitritt zur Community.',

  quote1: 'Ihre unzufriedensten Kunden sind Ihre beste Quelle zum Lernen.',
  quoteName1: 'Bill Gates',
  quoteCompany1: 'Microsoft',
  quoteImage1: 'Bill-Gates',

  quote2: 'Kundenservice sollte keine Abteilung sein. Es sollte das gesamte Unternehmen sein.',
  quoteName2: 'Tony Hsieh',
  quoteCompany2: 'Zappos',
  quoteImage2: 'Tony-Hsieh',

  quote3: 'Der einzige Grund, warum wir im Geschäft sind, ist das Leben unserer Kunden zu erleichtern.',
  quoteName3: 'Matthew Odgers',
  quoteCompany3: 'Odgers Law Group',
  quoteImage3: 'Matthew-Odgers',

  quote4: 'Wenn Sie sich nicht um Ihren Kunden kümmern, wird es Ihr Konkurrent tun.',
  quoteName4: 'Bob Hooey',
  quoteCompany4: 'Ideaman',
  quoteImage4: 'Bob-Hooey',

  quote5: 'Im Verkauf ist eine Empfehlung der Schlüssel zur Tür des Widerstands.',
  quoteName5: 'Bo Bennett',
  quoteCompany5: 'Archieboy',
  quoteImage5: 'Bo-Bennett',

  quote6: 'Geben Sie immer Ihr Bestes. Was Sie jetzt pflanzen, werden Sie später ernten.',
  quoteName6: 'Og Mandino',
  quoteCompany6: 'Author',
  quoteImage6: 'Og-Mandino',

  quote7: 'Eines der größten Geschenke, die Sie jemandem machen können, ist das Geschenk Ihrer Aufmerksamkeit.',
  quoteName7: 'Jim Rohn',
  quoteCompany7: 'Author',
  quoteImage7: 'Jim-Rohn',

  quote8: 'Machen Sie das, was Sie tun, so gut, dass die Leute es wieder sehen wollen und ihre Freunde mitbringen.',
  quoteName8: 'Walt Disney',
  quoteCompany8: 'Disney',
  quoteImage8: 'Walt-Disney',

  passwordResetSuccess: 'Ihr Passwort wurde erfolgreich zurückgesetzt!',
  resetPasswordTitle: 'Passwort zurücksetzen',
  enterNewPassword: 'Bitte geben Sie Ihr neues Passwort ein.',
  passwordAgain: 'Passwort wiederholen',
  resetPassword: 'Passwort zurücksetzen',
  alreadyHaveAccount: 'Haben Sie bereits ein Konto?',
  resetPasswordRequestSuccess: 'Passwort-Reset-Anfrage erfolgreich',
  forgotPasswordTitle: 'Passwort vergessen',
  resetPasswordInstructions: 'Sie können Ihr Passwort zurücksetzen, indem Sie Ihre E-Mail-Adresse eingeben.',
  rememberPassword: 'Erinnern Sie sich an Ihr Passwort?',
  userConfirmedSuccess: 'Benutzer erfolgreich bestätigt!',
  emailConfirmationTitle: 'E-Mail-Bestätigung',
  TwoFAConfirmationTitle: '2FA-Bestätigung',
  enterOtp: 'Bitte geben Sie den an Ihre E-Mail gesendeten OTP ein.',
  submit: 'Überprüfen',
  didNotReceiveCode: "Keinen Code erhalten?",
  resendCode: 'Code erneut senden',
  resendCodeTitle: 'Bestätigungscode erneut senden',
  enterEmailToResend: 'Bitte geben Sie Ihre E-Mail-Adresse ein, um den Bestätigungscode zu erhalten.',
  email: 'E-Mail',
  close: 'Schließen',
  send: 'Senden',
  signupSuccess: 'Registrierung erfolgreich',
  letsRegister: "Lassen Sie uns Sie registrieren.",
  name: 'Vorname',
  surname: 'Nachname',
  iAccept: 'Ich akzeptiere die',
  and: 'und',
  with: 'und',
  termsAndConditions: 'Allgemeinen Geschäftsbedingungen',
  privacyPolicy: 'Datenschutzerklärung',
  explicitConsent: 'Ausdrückliche Einwilligungserklärung',
  kvkkPolicy: 'DSGVO-Informationserklärung',
  orContinueWith: 'oder fortfahren mit',
  createPassword: 'Passwort erstellen',
  createPasswordTitle: 'Passwort erstellen',
  signupSuccessfullTitle: 'Registrierung erfolgreich!',
  signupSuccessfullDescription: 'Bitte überprüfen Sie Ihre E-Mail, um Ihr Konto zu bestätigen.',
  backToLogin: 'Zurück zur Anmeldung',
  resendConfirmationSuccessTitle: 'Bestätigungscode erfolgreich erneut gesendet',
  resendConfirmationSuccessText: 'Bitte überprüfen Sie Ihre E-Mail, um Ihr Konto zu bestätigen.',
  resentCodeSuccess: 'Code erfolgreich erneut gesendet',
  acceptAllPolicies: 'Bitte akzeptieren Sie alle Richtlinien!',
  alreadyRegisteredError: 'Sie sind bereits registriert! Bitte melden Sie sich an.',
  userNotFoundError: 'Benutzer nicht gefunden!',

  proposal: 'Angebot',
  estimate: 'Kostenvoranschlag',
  invoice: 'Rechnung',
  startDate: 'Startdatum',
  expireDate: 'Ablaufdatum',
  deadline: 'Frist',
  proposalInformation: 'Angebotsinformationen',
  estimateInformation: 'Kostenvoranschlagsinformationen',
  invoiceInformation: 'Rechnungsinformationen',
  billTo: 'Rechnung an',
  bankName: 'Bankname',
  country: 'Land',
  iban: 'IBAN',
  currency: 'Währung',
  productDescription: 'Produktbeschreibung',
  qty: 'Menge',
  rate: 'Rate',
  tax: 'Steuer',
  amount: 'Betrag',
  projectTimeline: 'Projektzeitplan',
  timeline: 'Zeitplan',
  accept: 'Akzeptieren',
  decline: 'Ablehnen',
  download: 'Herunterladen',
  comments: 'Kommentare',
  documents: 'Dokumente',
  uploadDocument: 'Dokument hochladen',
  addDocument: 'Dokument hinzufügen',

  estimateAccepted: 'Kostenvoranschlag akzeptiert',
  estimateDeclined: 'Kostenvoranschlag abgelehnt',
  proposalAccepted: 'Angebot akzeptiert',
  proposalDeclined: 'Angebot abgelehnt',
  invoiceAccepted: 'Rechnung akzeptiert',
  invoiceDeclined: 'Rechnung abgelehnt',
  declineReason: 'Ablehnungsgrund',
  declineReasonPlaceholder: 'Bitte geben Sie den Grund für die Ablehnung ein',
};

export default locale;

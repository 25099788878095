const locale = {
  contacts: 'Контакты',
  title: 'Контакты',
  createContact: 'Создать Контакт',
  columnId: '#',
  columnName: 'Имя',
  columnIcon: 'Иконка',
  columnDepartment: 'Отдел',
  columnParentCategory: 'Родительская Категория',
  columnActions: 'Действия',
  emptyStateContent1: 'Контакты не найдены',
  emptyStateContent2: 'Пожалуйста, создайте новый контакт',
  selectIconTitle: 'Выберите Иконку',
  searchIcons: 'Поиск Иконок',
  relatedContacts: 'Связанные Контакты',
  b2bB2c: 'B2B / B2C',
  createContactTitle: 'Создать Контакт',
  contactNameLabel: 'Имя Контакта',
  contactEmailLabel: 'Email Контакта',
  contactPhoneLabel: 'Телефон Контакта',
  contactTypeLabel: 'Тип Контакта',
  contactPictureLabel: 'Фото Контакта',
  departmentLabel: 'Отдел',
  categoryLabel: 'Категория',
  contactLanguageLabel: 'Язык Контакта',
  countryLabel: 'Страна',
  stateLabel: 'Область',
  cityLabel: 'Город',
  uploadPictureText: 'Загрузить фото',
  createContactButton: 'Создать Контакт',
  createSuccess: 'Контакт успешно создан',
  delete: 'Удалить',
  deleteConfirm: 'Вы уверены, что хотите удалить этот контакт? Это действие нельзя отменить.',
  deletedSuccessfully: 'Контакт успешно удален',
  cancel: 'Отмена',
  id: '#',
  firstName: 'Имя',
  lastName: 'Фамилия',
  email: 'Email',
  type: 'Тип',
  phone: 'Телефон',
  position: 'Должность',
  language: 'Язык',
  update: 'Обновить Контакт',
  updateContactButton: 'Обновить Контакт',
  customerId: 'Выберите Клиента',
  primary: 'Основной',
  customer: 'Клиент',
  actions: 'Действия',
  create: 'Создать Контакт',
  emptyState1: 'Контакты не найдены.',
  emptyState2: 'Вы можете сразу создать или импортировать контакты.',
  deletePrimaryError: 'Основной контакт не может быть удален.',
  updateSuccess: 'Контакт успешно обновлен',
};

export default locale; 
import proposalLocale from "../subdomains/proposal/locales/ru";
import estimateLocale from "../subdomains/estimate/locales/ru";

const locale = {
  estimate: {
    ...estimateLocale,
  },
  proposal: {
    ...proposalLocale,
  },
};

export default locale; 
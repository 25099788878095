/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  Currency,
  IBaseError,
  IGraphqlVariables,
  IHasId,
  isNullOrUndefinedOrEmptyObject,
  PermissionAction,
} from 'corede-common';
import {
  IContract,
  IContractListInput,
  ContractStatus,
  IContractDetailResult,
  ContractType,
  ContractSignStatus,
  PermissionSubject,
  ProjectRelatedTargetType,
  UserRelatedTargetType,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useContractListQuery, useContractUpdateMutation } from '../../context/contract.api';
import { ContractDeleteDialog } from '../ContractDeleteDialog';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  useHandleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representDateAsString, representHtmlAsString } from 'utils/representationHelper';
import ContractUpdateDrawerWrapper from '../update/ContractUpdateDrawer.wrapper';
import { useProjectListByRelatedQuery } from 'apps/crm/domains/10-project/subdomains/project';
import ContractDetailDrawer from '../detailDrawer/ContractDetailDrawer';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import { useUserListByRelatedQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import {
  convertUserListToObjectAutoCompleteItem,
  convertUserListToUserProfileList,
} from 'utils/user.entity.util';
import { BaseGridColumnDefFactory } from 'components/baseDataGrid/factories/base-grid-column.factory';
import {
  EntityProfileFactory,
  UserProfileFactory,
} from 'apps/crm/components/profile/profile-column-cell.component.helper';
import { EntityIcons } from 'icons/entity-icons';
import { UserProfileColumnCellComponent } from 'apps/crm/components/profile/profile-column-cell.component';

export interface IContractListGridComponent {
  // contract
  setOpenContractCreateDrawer?: (value: boolean) => void | undefined;
}

const ContractListGridComponent = memo((props: IContractListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedContract, setSelectedContract] = useState<TListGridSelectedEntity<IContract>>();
  const [openContractDetailDrawer, setOpenContractDetailDrawer] = useState(false);
  const [openContractDeleteDialog, setOpenContractDeleteDialog] = useState(false);
  const [openContractUpdateDrawer, setOpenContractUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.contract,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.contract,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.contract,
    action: PermissionAction.delete,
  });

  const [contractListFilter, setContractListFilter] = useState<
    IGraphqlVariables<IContractListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = contractListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = contractListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: contractListData,
    isLoading: contractListLoading,
    isFetching: contractListFetching,
    error: contractListError,
    refetch,
  } = useContractListQuery(contractListFilter);

  const { data: userListData } = useUserListByRelatedQuery({
    input: {
      filter: {
        relatedTargetType: UserRelatedTargetType.Contract,
      },
    },
  });

  const { data: projectListData } = useProjectListByRelatedQuery({
    input: {
      filter: {
        relatedTargetType: ProjectRelatedTargetType.Contract,
      },
    },
  });

  // used for direct update in the list rows
  const [contractUpdate, { isLoading: contractUpdateLoading, error: contractUpdateError }] =
    useContractUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setContractListLoading && props.setContractListLoading(contractListLoading);
  // }, [contractListLoading]);
  useEffect(() => {
    refetch();
  }, [contractListFilter]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(contractListError as IBaseError, currentLanguage);
  }, [contractListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(contractUpdateError as IBaseError, currentLanguage);
  }, [contractUpdateError]);

  const isFilterEmpty = isNullOrUndefinedOrEmptyObject(contractListFilter?.input?.filter);
  const isJunkUndefined = (contractListFilter as any)?.filter?.isJunk === undefined;
  const isBusinessTypesUndefined = contractListFilter?.input?.filter?.businessTypes === undefined;
  const isEmptyState =
    contractListData?.data?.length === 0 &&
    !contractListLoading &&
    !contractListFetching &&
    (isFilterEmpty || (isJunkUndefined && isBusinessTypesUndefined));

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedContract}
      setSelectedEntity={setSelectedContract}
      listFilter={contractListFilter}
      setListFilter={setContractListFilter}
      listData={contractListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
        {contractListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                contractListData?.data?.map((contract, index) => ({
                  id:
                    ((contractListFilter.input?.pagination?.page || 1) - 1) *
                      (contractListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: contract._id,
                  title: contract.title || '-',
                  customerInfo: EntityProfileFactory.CreateEntityProfileFrom({
                    _id: contract?.customer?._id,
                    fullName: contract?.customer?.name,
                    image: contract?.customer?.image?.thumbnailPublicUrl,
                  }),
                  projectInfo: contract?.project
                    ? EntityProfileFactory.CreateEntityProfileFrom({
                        _id: contract?.project?._id,
                        fullName: contract?.project?.name,
                        secondaryInfo: contract?.project?.status,
                        icon: EntityIcons.projects,
                      })
                    : undefined,
                  description: representHtmlAsString({ data: contract.description }),
                  status: contract.status || '-',
                  startDate: representDateAsString(contract.startDate),
                  endDate: representDateAsString(contract.endDate),
                  contractValue: contract.contractValue || '-',
                  contractType: contract.contractType || '-',
                  currency: contract.currency || '-',
                  project: contract.project?.name || '-',
                  signedDate: representDateAsString(contract.signedDate),
                  signedUser: UserProfileFactory.CreateUserProfileFrom({
                    _id: contract?.signedUser?._id,
                    name: contract?.signedUser?.name,
                    surname: contract?.signedUser?.surname,
                    profileImage: contract?.signedUser?.profileImage,
                  }),
                  signStatus: contract.signStatus || '-',
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: 'center',
                    },
                    headerConfig: {
                      name: '#',
                      align: 'center',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'title',
                      editable: hasUpdatePermission,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.title'),
                    },
                  },
                },
                BaseGridColumnDefFactory.EntityProfileColumnDef({
                  field: 'customerInfo',
                  headerName: `${t('crm.customerLabel')}`,
                }),
                BaseGridColumnDefFactory.EntityProfileColumnDef({
                  field: 'projectInfo',
                  headerName: `${t('crm.projectLabel')}`,
                }),
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'description',
                      editable: false,
                      filterable: true,
                      width: 180,
                    },
                    headerConfig: {
                      name: t('crm.description'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'status',
                      editable: hasUpdatePermission,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.status'),
                    },
                    filterConfig: {
                      customFieldName: `status`,
                    },
                  },
                  data: ContractStatus,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'startDate',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.startDate'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'endDate',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.endDate'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'contractValue',
                      editable: hasUpdatePermission,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.contract.contract.contractValue'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'currency',
                      editable: hasUpdatePermission,
                      filterable: false,
                    },
                    headerConfig: {
                      name: t('crm.currency'),
                    },
                    filterConfig: {
                      customFieldName: `currency`,
                    },
                  },
                  data: Currency,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'contractType',
                      editable: hasUpdatePermission,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.contract.contract.contractType'),
                    },
                    filterConfig: {
                      customFieldName: `contractType`,
                    },
                  },
                  data: ContractType,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'signedDate',
                      editable: hasUpdatePermission,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.signedDate'),
                    },
                  },
                },
                BaseGridColumnDefFactory.SignedUserProfileColumnDef({
                  t: t,
                }),
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'signStatus',
                      editable: hasUpdatePermission,
                      filterable: true,
                    },
                    headerConfig: {
                      name: t('crm.contract.contract.signStatus'),
                    },
                    filterConfig: {
                      customFieldName: `signStatus`,
                    },
                  },
                  data: ContractSignStatus,
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: hasDetailPermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedContract,
                          setOpenAction: setOpenContractDetailDrawer,
                        },
                      }
                    : undefined,
                  edit: hasUpdatePermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedContract,
                          setOpenAction: setOpenContractUpdateDrawer,
                        },
                      }
                    : undefined,
                  delete: hasDeletePermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedContract,
                          setOpenAction: setOpenContractDeleteDialog,
                        },
                      }
                    : undefined,
                },
                actionHeaderName: t('crm.actions'),
              }}
              loading={contractListLoading || contractUpdateLoading || contractListFetching}
              listFilter={{
                filterInput: contractListFilter,
                setFilterInput: setContractListFilter,
              }}
              update={{
                updateQuery: hasUpdatePermission ? contractUpdate : undefined,
              }}
              count={contractListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  prospectAddress: false,
                  sector: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.contract.contract.emptyState1')}
              content2={t('crm.contract.contract.emptyState2')}
              button1={{
                title: t('crm.contract.contract.create'),
                onClick: () => (props?.setOpenContractCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
                permission: {
                  subject: PermissionSubject.contract,
                  action: PermissionAction.create,
                },
              }}
            />
          )
        ) : null}

      <PermissionWrapper
        check={{
          subject: PermissionSubject.contract,
          action: PermissionAction.detail,
        }}
      >
        <ContractDetailDrawer
          open={openContractDetailDrawer}
          setOpen={setOpenContractDetailDrawer}
          selectedContract={selectedContract as IContractDetailResult & IHasId<number>}
          setSelectedContract={setSelectedContract}
          sideNavigationProps={{
            count: contractListData?.count ?? 0,
            handleNavigate: useHandleSideNavigationNavigate({
              currentPage,
              currentPageSize,
              listData: contractListData,
              listFilter: contractListFilter,
              setListFilter: setContractListFilter,
              selectedEntity: selectedContract,
              setSelectedEntity: setSelectedContract,
              setPendingNavigation: setPendingNavigation,
            }),
          }}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.contract,
          action: PermissionAction.update,
        }}
      >
        <ContractUpdateDrawerWrapper
          open={openContractUpdateDrawer}
          setOpen={setOpenContractUpdateDrawer}
          selectedContract={selectedContract}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.contract,
          action: PermissionAction.delete,
        }}
      >
        <ContractDeleteDialog
          open={openContractDeleteDialog}
          contractId={selectedContract?._id ?? ''}
          setContract={setSelectedContract}
          onClose={() => {
            setSelectedContract(undefined);
            setOpenContractDeleteDialog(false);
          }}
          key={selectedContract?._id}
        />
      </PermissionWrapper>
    </DefaultListGridComponent>
  );
});

export default ContractListGridComponent;

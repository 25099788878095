const locale = {
  expenses: 'Ausgaben',
  expense: 'Ausgabe',
  expenseConversions: 'Ausgabenumrechnungen',
  title: 'Ausgaben',
  create: 'Ausgabe erstellen',
  delete: 'Ausgabe löschen',
  update: 'Ausgabe aktualisieren',
  deletedSuccessfully: 'Ausgabe erfolgreich gelöscht.',
  createSuccess: 'Ausgabe erfolgreich erstellt.',
  updateSuccess: 'Ausgabe erfolgreich aktualisiert.',
  deleteConfirm: 'Sind Sie sicher, dass Sie diese Ausgabe löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  emptyState1: 'Noch keine Ausgaben hinzugefügt.',
  emptyState2:
    'Speichern und verwalten Sie hier alle Ausgabendetails, um Ihre Rechnungsstellung zu optimieren und klare Vereinbarungen mit Ihren Kunden zu pflegen.',
  downloadFormSuccessfully: 'Ausgabenliste erfolgreich heruntergeladen.', 
  importExpense: 'Ausgaben importieren',
  importTitle: 'Ausgaben importieren',
  importText1: 'Sie können Ihre Ausgabendaten aus einer Excel-Datei importieren.',
  importText2: 'Klicken Sie auf den Download-Beispiel-Button, um das Beispiel herunterzuladen.',

  // labels
  id: "Ausgaben-ID",
  totalAmount: "Gesamtbetrag",
  totalTax: "Gesamtsteuer",
  isBillable: 'Ist abrechenbar',
  isInvoiced: 'Ist in Rechnung gestellt',

  // detailDrawer
  changeStatus: 'Status ändern',
  generalInfoTitle: 'Allgemeine Informationen',
  customerInfoTitle: 'Kundeninformationen',
  expenseInfoTitle: 'Ausgabeninformationen',
  projectInfoTitle: 'Projektinformationen',
  projectName: 'Projektname',
  paymentMethod: 'Zahlungsmethode',
};

export default locale; 
const locale = {
  service: 'Услуги',
  title: 'Услуги',
  create: 'Создать Услугу',
  update: 'Обновить Услугу',
  delete: 'Удалить Услугу',
  deleteConfirm: 'Вы уверены, что хотите удалить этот статус: ',
  deletedSuccessfully: 'Услуга успешно удалена',
  createService: 'Создать Услугу',
  createSuccess: 'Услуга успешно создана',
  updateSuccess: 'Услуга успешно обновлена',
  name: 'Название',
  icon: 'Иконка',
  showMore: 'Показать Больше',

  detail: 'Детали Услуги',
  type: 'Тип',
  image: 'Изображение',
  sku: 'Артикул',
  description: 'Описание',
  currency: 'Валюта',
  price: 'Цена',
  priceType: 'Тип Цены',
  discountedPrice: 'Цена со Скидкой',
  unit: 'Единица',
  categoryId: 'Категория',
  natTaxRate: 'Национальная Налоговая Ставка',
  intTaxRate: 'Международная Налоговая Ставка',

  categories: 'Категории',
  rate: 'Тариф',
  rateType: 'Тип Тарифа',
  discountedRate: 'Тариф со Скидкой',
  emptyState1: 'Пока не зарегистрировано ни одной услуги.',
  emptyState2:
    'Управляйте и просматривайте здесь любые возвраты услуг, чтобы ваши записи были точными, а потребности клиентов удовлетворены.',
};

export default locale; 
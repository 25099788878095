import userLocale from "../subdomains/user/locales/fr";
import departmentLocale from "../subdomains/department/locales/fr";
import roleLocale from "../subdomains/role/locales/fr";
import permissionLocale from "../subdomains/permission/locales/fr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  user: {
    ...userLocale,
  },
  department: {
    ...departmentLocale,
  },
  role: {
    ...roleLocale
  },
  permission: {
    ...permissionLocale
  }
};

export default locale; 
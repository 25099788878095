const locale = {
  myAccount: "Mein Konto",
  profilePicture: "Profilbild",
  profile: "Profil",
  name: "Vorname",
  surname: "Nachname",
  birthDate: "Geburtsdatum",
  gender: "Geschlecht",
  update: "Aktualisieren",
  changeImage: "Bild ändern",
  change: "Ändern",
  updateProfileSuccess: "Profil erfolgreich aktualisiert",
  updateProfilePictureSuccess: "Profilbild erfolgreich aktualisiert",
  country: "Land",
  city: "Stadt",
  signatureImage: "Unterschriftsbild",
  twoFactorAuthEmail: "Zwei-Faktor-Authentifizierungs-E-Mail",
};

export default locale; 
const locale = {
  demo: "Demo",
  title: "Cos'è <highlight>CoCRM</highlight> Lead+?",
  description:
    "In CoCRM, non ti supportiamo solo nella gestione dei tuoi processi aziendali e dei clienti; siamo anche lieti di aiutarti ad acquisire nuovi clienti.",
  featuresTitle: "Con CoCRM Lead+,",
  features1:
    "Siamo lieti di fornirti i dati dei potenziali clienti di cui hai bisogno in base a",
  features2:
    "I parametri di paese, città e/o settore che hai come target per il marketing.",
  features3: "Le aree specifiche che hai definito per i tuoi obiettivi di vendita.",
  features4: "I mercati in cui desideri aumentare la consapevolezza del marchio.",
  buttonTitle: "Richiedi un Preventivo",
  formDescription:
    "Compila il modulo sottostante e ti contatteremo il prima possibile.",
  formTitle: "Richiedi un Preventivo",
  formButtonTitle: "Invia",
  sendSuccess: "Grazie! Ti risponderemo il prima possibile.",
  fullName: "Nome Completo",
  email: "Email",
  phone: "Telefono",
  orgName: "Nome dell'Organizzazione",
};

export default locale; 
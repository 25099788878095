const locale = {
  demo: 'Демо',
  adjustment: 'Корректировка',
  currency: 'Валюта',
  exchangeRates: 'Курсы валют',
  fromCurrency: 'Из валюты',
  toCurrency: 'В валюту',
  rate: 'Курс',
  updatedAt: 'Обновлено',
  language: 'Язык',
  locale: 'Локаль',
  updateSettings: 'Обновить настройки',
  NDA: 'Соглашение о неразглашении',
  followUpMailTemplate: 'Шаблон письма для последующей связи',
  emailType: 'Тип электронной почты',
  subject: 'Тема',
  content: 'Содержание',
  variables: 'Переменные',
  prefix: 'Префикс',
  paymentPrefix: 'Префикс платежа',
  returnPrefix: 'Префикс возврата',
  termsAndConditions: 'Условия и положения',
  paymentMethods: 'Способы оплаты',
  companyBackground: 'История компании',
  companyReferences: 'Рекомендации компании',
  timezone: 'Часовой пояс',
  updateSuccess: 'Настройки успешно обновлены',
  name: 'Имя',
  surname: 'Фамилия',
  email: 'Электронная почта',
  phone: 'Телефон',
  identityNumber: 'Идентификационный номер',
  vat: 'ИНН',
  country: 'Страна',
  state: 'Область/Регион',
  city: 'Город',
  street: 'Улица',
  address: 'Адрес',
  taxOffice: 'Налоговая инспекция',
  zip: 'Почтовый индекс',
  fullName: 'Полное имя',
  update: 'Обновить',
  create: 'Создать',
  updateBillingInfo: 'Обновить платежную информацию',
  updateBillingInfoSuccess: 'Платежная информация успешно обновлена',
  commonSettingsUpdateSuccess: 'Настройки успешно обновлены',
  save: 'Сохранить',
  organizationSettings: 'Организация',
  monthly: 'ежемесячно',
  trial: 'Пробный период',
  free: 'Бесплатно',
  trialEnds: 'Пробный период заканчивается',
  browsePackages: 'Просмотреть пакеты',
  cancelSubscription: 'Отменить подписку',
  updateOrganizationLogoSuccess: 'Логотип организации успешно обновлен',
  organizationLogo: 'Логотип организации',
  summary: 'Сводка',
  website: 'Веб-сайт',
  legalName: 'Юридическое название',
  dateFormat: 'Формат даты',
  timeFormat: 'Формат времени',
  vatNumber: 'ИНН',
  taxRate: 'Налоговая ставка',
  exchangeRateMode: 'Режим курса валют',
  general: 'Общие',
  localizations: 'Локализации',
  finance: 'Финансы',
  generalInfo: 'Общая информация',
  socialMedia: 'Социальные сети',
  billingInfo: 'Платежная информация',
  cards: 'Карты',
  currentPlan: 'Текущий план',
  invoices: 'Счета',
  cardListSecure: 'Ваши карты хранятся в базе данных iyzico.',
  noInvoiceContent1: "У вас еще нет счетов.",
  noInvoiceContent2:
    'Отслеживайте и управляйте платежами по вашим счетам здесь, чтобы ваши финансы были организованы и актуальны.',
  noLanguageContent1: "У вас еще нет языков.",
  noLanguageContent2:
    'Создавайте и управляйте языками здесь, чтобы сделать ваш веб-сайт доступным для глобальной аудитории.',
  noLeadContent1: 'Настройки лидов в настоящее время недоступны.',
  noLeadContent2:
    'Настройте, как лиды захватываются, назначаются и отслеживаются, чтобы оптимизировать ваш рабочий процесс CRM.',
  noCustomerContent1: 'Настройки клиентов еще не настроены.',
  noCustomerContent2:
    'Настройте предпочтения для эффективного управления информацией о клиентах и взаимодействиями в вашей CRM.',
  noEstimateContent1: 'Настройки счетов еще не настроены.',
  noEstimateContent2:
    'Настройте здесь свои предпочтения по счетам, чтобы упростить выставление счетов и обеспечить точные записи.',
  noProposalContent1: 'Настройки предложений в настоящее время недоступны.',
  noProposalContent2:
    'Настройте здесь свои предпочтения по предложениям, чтобы упростить общение с клиентами и улучшить продажи.',
  noInvoiceContent3: 'Настройки счетов еще не настроены.',
  noInvoiceContent4:
    'Настройте здесь свои предпочтения по счетам, чтобы упростить выставление счетов и обеспечить точные записи.',
  noProjectContent1: 'Настройки проектов еще не были доступны.',
  noProjectContent2:
    'Настройте здесь свои предпочтения по проектам, чтобы оптимизировать управление задачами и рабочий процесс в вашей CRM.',
  noTaskContent1: 'Настройки задач еще не были доступны.',
  noTaskContent2:
    'Настройте здесь свои предпочтения по задачам, чтобы упростить управление задачами и повысить производительность команды.',
  noTicketContent1: 'Настройки тикетов/поддержки еще не были доступны.',
  noTicketContent2:
    'Настройте здесь свои предпочтения для управления запросами поддержки клиентов и обеспечения своевременных решений.',
  noFileManagementContent1: 'Настройки управления файлами еще не были доступны.',
  noFileManagementContent2:
    'Настройте здесь свои предпочтения для эффективной организации и управления документами в вашей CRM.',
  noCalendarContent1: 'Настройки календаря еще не были доступны.',
  noCalendarContent2:
    'Настройте здесь свои предпочтения, чтобы настроить свой календарь и оптимизировать планирование в вашей CRM.',
  noNotificationContent1: 'Настройки уведомлений еще не были доступны.',
  noNotificationContent2:
    'Настройте здесь свои предпочтения по уведомлениям, чтобы быть в курсе и получать актуальную информацию о важных действиях в вашей CRM.',
  noLeadPlusContent1: 'Нет активного продукта или плана Lead+.',
  noLeadPlusContent2:
    'Приобретите или авторизуйте план, чтобы разблокировать полный доступ и настроить ваши настройки Lead+.',
  noMarketingContent1: 'Нет активного продукта или плана Маркетинг.',
  noMarketingContent2:
    'Приобретите или авторизуйте план, чтобы разблокировать полный доступ и настроить ваши настройки Маркетинга.',
  noWindowContent1: 'Нет активного продукта или плана Window.',
  noWindowContent2:
    'Приобретите или авторизуйте план, чтобы разблокировать полный доступ и настроить ваши настройки Window.',
  noCommunityContent1: 'Нет активного продукта или плана Community.',
  noCommunityContent2:
    'Приобретите или авторизуйте план, чтобы разблокировать полный доступ и настроить ваши настройки Community.',
  noIntegrationsContent1: 'Интеграции еще не настроены.',
  noIntegrationsContent2:
    'Настройте здесь интеграции, чтобы расширить возможности CoCRM и упростить рабочие процессы с авторизованными подключениями.',
  noMenuContent1: 'Настройки меню в настоящее время недоступны.',
  noMenuContent2:
    'Авторизуйте доступ для настройки макета вашего меню и упрощения навигации в вашей CRM.',
  noLegalAgreementsContent1: 'Настройки юридических соглашений в настоящее время недоступны. ',
  noLegalAgreementsContent2:
    'Авторизуйте доступ для управления и настройки юридических соглашений в вашей CRM.',
  noCrmContent1: 'Нет активного продукта или плана CRM.',
  noCrmContent2:
    'Приобретите или авторизуйте план, чтобы разблокировать полный доступ и настроить ваши настройки CRM.',
  noUserListContent1: 'Нет активного продукта или плана Список пользователей.',
  noUserListContent2:
    'Приобретите или авторизуйте план, чтобы разблокировать полный доступ и настроить ваши настройки Списка пользователей.',
  noPermissionsContent1: 'Нет активного продукта или плана Разрешения.',
  noPermissionsContent2:
    'Приобретите или авторизуйте план, чтобы разблокировать полный доступ и настроить ваши настройки Разрешений.',
  lead: 'Лид',
  crm: 'CRM',
  menu: 'Меню',
  customer: 'Клиент',
  estimate: 'Смета',
  proposal: 'Предложение',
  invoice: 'Счет',
  project: 'Проект',
  task: 'Задача',
  ticket: 'Тикет',
  fileManagement: 'Управление файлами',
  calendar: 'Календарь',
  notification: 'Уведомление',
  leadPlus: 'Lead+',
  marketing: 'Маркетинг',
  window: 'Window',
  community: 'Community',
  integrations: 'Интеграции',
  userList: 'Список пользователей',
  permissions: 'Разрешения',
  userType: 'Тип пользователя',
  companyName: 'Название компании',
  allowableFileFormats: 'Допустимые форматы файлов',

  // currentPlan
  yourCurrentPlan: 'Ваш текущий план - ',
  yourCurrentPlanDesc: 'Простое начало для всех',
  activeUntil: 'Активен до',
  activeUntilDesc: 'Мы отправим вам уведомление по истечении срока подписки',
  paymentDesc: 'Оплата будет списана с вашего счета',
  paymentForTrialDesc: 'Вам необходимо приобрести подписку, чтобы продолжить использование',
  perMonth: 'В месяц',
  prtYear: 'В год',
  perMonthDesc: 'Вы можете отменить подписку в любое время',
  upgradePlan: 'Обновить план',
  attentionTitle: 'Нам нужно ваше внимание!',
  attentionDesc: 'Ваш план требует обновления',
  days: 'Дней',
  of: 'из',
  remainingDesc: 'осталось дней до того, как ваш план потребует обновления',
  usageOfStorage: 'Использование хранилища',
  users: 'Пользователи',

  //legalAgreements
  legalAgreements: 'Юридические соглашения',
  createLegalAgreement: 'Создать юридическое соглашение',
  updateLegalAgreement: 'Обновить юридическое соглашение',
  deleteLegalAgreement: 'Удалить юридическое соглашение',
  createLegalAgreementSuccess: 'Юридическое соглашение успешно создано',
  updateLegalAgreementSuccess: 'Юридическое соглашение успешно обновлено',
  deleteLegalAgreementSuccess: 'Юридическое соглашение успешно удалено',
  deleteLegalAgreementDesc: 'Вы уверены, что хотите удалить это юридическое соглашение?',

  // cards
  deleteCard: 'Удалить карту',
  addCard: 'Добавить новую карту',
  createCardSuccess: 'Карта успешно создана',
  addCardSuccess: 'Карта успешно добавлена',
  deleteCardSuccess: 'Карта успешно удалена',
  deleteCardDesc: 'Вы уверены, что хотите удалить эту карту?',
  requiredCard: 'Номер карты обязателен',
  requiredCardHolder: 'Имя владельца карты обязательно',
  requiredExpireMonth: 'Месяц истечения срока обязателен',
  requiredExpireYear: 'Год истечения срока обязателен',
  requiredCvc: 'CVC обязателен',
};

export default locale; 
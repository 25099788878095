const locale = {
  // fields
  users: 'Usuários da Nota',
  date: 'Data da Nota',
  title: 'Listar Notas',
  detail: 'Detalhe da Nota',
  sharedList: "Notas Compartilhadas",
  privateList: "Notas Privadas",
  ownList: "Minhas Notas",
  allList: "Todas as Notas",

  // actions
  create: 'Criar Nota',
  add: 'Adicionar Nota',
  update: 'Atualizar Nota',
  delete: 'Excluir Nota',

  // situational text
  tagsPlaceholder: 'Adicione tags e pressione enter',
  createSuccess: 'Nota criada com sucesso',
  updateSuccess: 'Nota atualizada com sucesso',
  deletedSuccessfully: 'Nota excluída com sucesso',
  deleteContent: 'Tem certeza de que deseja excluir esta nota? Esta ação não pode ser desfeita.',
  emptyState1: 'Nenhuma nota adicionada ainda.',
  emptyState2:
    'Crie e organize suas notas aqui para acompanhar detalhes importantes e conversas em seu CRM.',

  createdBy: "Nota criada por",
};

export default locale; 
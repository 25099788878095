const locale = {
  tasks: "Задачи",
  task: "Задача",
  taskConversions: "Конверсии задач",
  title: "Задачи",
  addTask: "Добавить задачу",
  emptyState1: "Пока нет назначенных задач.",
  emptyState2: "Создавайте задачи здесь, чтобы организовать свою работу, установить приоритеты и отслеживать свои CRM-активности.",
  create: "Создать задачу",
  subject: "Тема",
  description: "Описание",
  startDate: "Дата начала",
  dueDate: "Срок выполнения",
  assigneeIds: "Исполнители",
  followerIds: "Наблюдатели",
  checklist: "Чек-лист",
  department: "Отдел",
  priority: "Приоритет",
  tags: "Теги",
  hourlyRate: "Почасовая ставка",
  hourlyRateCurrency: "Валюта почасовой ставки",
  isBillable: "Оплачиваемая",
  isRecurrent: "Повторяющаяся",
  repeatDays: "Дни повторения",
  totalCycle: "Общий цикл",
  addChecklistItem: "Добавить пункт в чек-лист",
  noAssigneeToCreateChecklist: "Назначьте пользователей для создания чек-листов",
  assignee: "Исполнитель",
  createTaskSuccess: "Задача успешно создана.",
  deleteTask: "Удалить задачу",
  deleteTaskContent: "Вы уверены, что хотите удалить эту задачу? Это действие нельзя отменить.",
  deletedSuccessfully: "Задача успешно удалена",
  updateTask: "Обновить задачу",
  updateSuccess: "Задача успешно обновлена.",

  detail: "Детали задачи",
  subtask: "Подзадача",

  addSubtask: "Добавить подзадачу",
  updateStatus: "Обновить статус",

  createdBy: "Задача создана ",

  // detailDrawer
  detailedInfo: "Подробная информация",
  recurrentInfo: "Информация о повторении",
  billableInfo: "Информация о биллинге",
  documents: "Документы",
  editAssignees: "Редактировать исполнителей",
};

export default locale; 
import { Stack, Typography, Grid, Box, Divider } from '@mui/material';
import {
  CategoryOutlined,
  EmailOutlined,
  PersonOutlined,
  PhoneOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLeadDetailQuery } from 'apps/crm/domains/03-lead/subdomains/lead';
import { useCustomerDetailQuery } from 'apps/crm/domains/04-customer/subdomains/customer';
import { useEstimateDetailQuery } from 'apps/crm/domains/05-preSale/subdomains/estimate';
import { useProposalDetailQuery } from 'apps/crm/domains/05-preSale/subdomains/proposal';
import { useInvoiceDetailQuery } from 'apps/crm/domains/06-sale/subdomains/invoice';
import { useEffect, useState } from 'react';
import { useUserDetailQuery } from 'apps/auth/context';
import { getTranslatedEnumValue } from 'localization';
import {
  DocumentTargetType,
  NoteTargetType,
  ReminderTargetType,
  TaskTargetType,
  TicketTargetType,
} from 'corede-common-cocrm';
import { useTaskDetailQuery } from 'apps/crm/domains/11-task/subdomains/task';
import { useContractDetailQuery } from 'apps/crm/domains/07-contract/subdomains/contract';
import { useTicketDetailQuery } from 'apps/crm/domains/15-support/subdomains/support';

interface ITargetInfo {
  name?: string;
  type?:
    | TaskTargetType
    | NoteTargetType
    | DocumentTargetType
    | ReminderTargetType
    | TicketTargetType
    | ReminderTargetType;
  email?: string;
  phone?: string;
}

export const DetailDrawerTargetComponent = (props: {
  targetType:
    | TaskTargetType
    | NoteTargetType
    | DocumentTargetType
    | ReminderTargetType
    | TicketTargetType
    | ReminderTargetType;
  targetId: string;
  smallSize?: boolean;
  extraSmallSize?: boolean;
  headerType?: 'textWithDivider';
}) => {
  const [target, setTarget] = useState<ITargetInfo>({});

  const {
    data: leadDetailData,
    isLoading: leadDetailLoading,
    error: leadDetailError,
  } = useLeadDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.Lead),
    },
  );

  const {
    data: customerDetailData,
    isLoading: customerDetailLoading,
    error: customerDetailError,
  } = useCustomerDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.Customer),
    },
  );

  const {
    data: estimateDetailData,
    isLoading: estimateDetailLoading,
    error: estimateDetailError,
  } = useEstimateDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.Estimate),
    },
  );

  const {
    data: proposalDetailData,
    isLoading: proposalDetailLoading,
    error: proposalDetailError,
  } = useProposalDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.Proposal),
    },
  );

  const {
    data: invoiceDetailData,
    isLoading: invoiceDetailLoading,
    error: invoiceDetailError,
  } = useInvoiceDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.Invoice),
    },
  );

  const {
    data: userDetailData,
    isLoading: userDetailLoading,
    error: userDetailError,
  } = useUserDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.User),
    },
  );

  const {
    data: taskDetailData,
    isLoading: taskDetailLoading,
    error: taskDetailError,
  } = useTaskDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TicketTargetType.Task),
    },
  );

  const {
    data: contractDetailData,
    isLoading: contractDetailLoading,
    error: contractDetailError,
  } = useContractDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === TaskTargetType.Contract),
    },
  );

  const {
    data: ticketDetailData,
    isLoading: ticketDetailLoading,
    error: ticketDetailError,
  } = useTicketDetailQuery(
    {
      input: {
        _id: props.targetId,
      },
    },
    {
      skip: !(props.targetType === ReminderTargetType.Ticket),
    },
  );

  useEffect(() => {
    if (leadDetailData && props.targetType === TaskTargetType.Lead) {
      setTarget({
        name: leadDetailData.fullName,
        email: leadDetailData.email,
        phone: leadDetailData.phoneNumber,
        type: TaskTargetType.Lead,
      });
    } else if (customerDetailData && props.targetType === TaskTargetType.Customer) {
      setTarget({
        name: customerDetailData.name,
        email: customerDetailData.email,
        phone: customerDetailData.phone,
        type: TaskTargetType.Customer,
      });
    } else if (estimateDetailData && props.targetType === TaskTargetType.Estimate) {
      setTarget({
        name: estimateDetailData.prefix,
        email: estimateDetailData.prospectContactEmail,
        phone: estimateDetailData.prospectPhoneNumber,
        type: TaskTargetType.Estimate,
      });
    } else if (proposalDetailData && props.targetType === TaskTargetType.Proposal) {
      setTarget({
        name: proposalDetailData.prefix,
        email: proposalDetailData.prospectContactEmail,
        phone: proposalDetailData.prospectPhoneNumber,
        type: TaskTargetType.Proposal,
      });
    } else if (invoiceDetailData && props.targetType === TaskTargetType.Invoice) {
      setTarget({
        name: invoiceDetailData.prefix,
        email: invoiceDetailData.customerContactEmail,
        phone: '-',
        type: TaskTargetType.Invoice,
      });
    } else if (userDetailData && props.targetType === TaskTargetType.User) {
      setTarget({
        name: userDetailData.name + ' ' + userDetailData.surname,
        email: userDetailData.email,
        phone: userDetailData.phoneNumber,
        type: TaskTargetType.User,
      });
    } else if (taskDetailData && props.targetType === TicketTargetType.Task) {
      setTarget({
        name: taskDetailData.subject,
        email: '-',
        phone: '-',
        type: TicketTargetType.Task,
      });
    } else if (contractDetailData && props.targetType === TaskTargetType.Contract) {
      setTarget({
        name: contractDetailData.title,
        email: '-',
        phone: '-',
        type: TaskTargetType.Contract,
      });
    } else if (ticketDetailData && props.targetType === ReminderTargetType.Ticket) {
      setTarget({
        name: ticketDetailData.subject,
        email: '-',
        phone: '-',
        type: ReminderTargetType.Ticket,
      });
    }
  }, [
    leadDetailData,
    customerDetailData,
    estimateDetailData,
    proposalDetailData,
    invoiceDetailData,
    userDetailData,
    taskDetailData,
    contractDetailData,
    ticketDetailData,
    props.targetType,
  ]);

  const { t } = useTranslation();
  return (
    <>
      {props.headerType === 'textWithDivider' && (
        <Divider textAlign="left" sx={{ marginLeft: -5 }}>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            {t('crm.target')}
          </Typography>
        </Divider>
      )}

      {props.headerType !== 'textWithDivider' && (
        <>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            {t('crm.target')}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
        </>
      )}

      <Grid container spacing={1.5}>
        <Grid
          item
          xs={12}
          sm={props.extraSmallSize ? 12 : 6}
          lg={props.extraSmallSize ? 12 : props.smallSize ? 6 : 3}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Box
              sx={{
                width: 30,
                height: 30,
                bgcolor: 'secondary.extraLight',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <PersonOutlined sx={{ color: 'secondary.main', width: 20 }} />
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                {target.name ?? '-'}
              </Typography>
              <Typography variant="caption">{t('crm.name')}</Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sm={props.extraSmallSize ? 12 : 6}
          lg={props.extraSmallSize ? 12 : props.smallSize ? 6 : 3}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Box
              sx={{
                width: 30,
                height: 30,
                bgcolor: 'secondary.extraLight',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CategoryOutlined sx={{ color: 'secondary.main', width: 20 }} />
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                {getTranslatedEnumValue(target.type as TaskTargetType) ?? '-'}
              </Typography>
              <Typography variant="caption">{t('crm.type')}</Typography>
            </Box>
          </Stack>
        </Grid>

        {target.email !== '-' && (
          <Grid
            item
            xs={12}
            sm={props.extraSmallSize ? 12 : 6}
            lg={props.extraSmallSize ? 12 : props.smallSize ? 6 : 3}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <EmailOutlined sx={{ color: 'secondary.main', width: 20 }} />
              </Box>
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                  {target.email ?? '-'}
                </Typography>
                <Typography variant="caption">{t('crm.email')}</Typography>
              </Box>
            </Stack>
          </Grid>
        )}

        {target.phone !== '-' && (
          <Grid
            item
            xs={12}
            sm={props.extraSmallSize ? 12 : 6}
            lg={props.extraSmallSize ? 12 : props.smallSize ? 6 : 3}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <PhoneOutlined sx={{ color: 'secondary.main', width: 20 }} />
              </Box>
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                  {target.phone ?? '-'}
                </Typography>
                <Typography variant="caption">{t('crm.phone')}</Typography>
              </Box>
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
};

import React from "react";
import { Outlet } from "react-router";
import MainLayout from 'layouts/MainLayout';
import { demoDomainMenuItem } from "../domains/demo/menu/items";
import { useTranslation } from "react-i18next";


const CommunityLayout = () => {

  const { t } = useTranslation();

  const treeItems = [
    {
      itemId: demoDomainMenuItem.path,
      label: t("community.demoo"),
      subItems: demoDomainMenuItem.children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path,
      })),
    },
  ];

  return <MainLayout treeItems={treeItems}>
    <Outlet />
  </MainLayout>

}

export default CommunityLayout;

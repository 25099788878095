import { Grid, Typography } from '@mui/material';
import { IInvoiceDetailCustomerResult } from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';

const InfoSection = (props: { invoiceData: IInvoiceDetailCustomerResult | undefined }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          {t('auth.invoiceInformation')}
        </Typography>

        <Typography
          variant="body2"
          sx={{ maxWidth: '300px', color: 'text.secondary', whiteSpace: 'pre-wrap' }}
        >
          {/* {`Thomas shelby\nShelby Company Limited\nSmall Heath, B10 0HF, UK\n718-986-6062\npeakyFBlinders@gmail.com`} */}
          {props.invoiceData?.customerContactFirstName} {props.invoiceData?.customerContactLastName}
          {'\n'}
          {
            props.invoiceData?.customerCity
          } {props.invoiceData?.customerState} {props.invoiceData?.customerCountry} {'\n'}
          {props.invoiceData?.customerContactEmail}
        </Typography>
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          {t('auth.billTo')}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {t('auth.bankName')}: American Bank
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {t('auth.country')}: United States
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {t('auth.iban')}: ETD95476213874685
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {t('auth.currency')}: Dollar/$
        </Typography>
      </Grid> */}
    </Grid>
  );
};

export default InfoSection;

import crmLocale from '../../apps/crm/locales/es';
import authLocale from '../../apps/auth/locales/es';
import leadPlusLocale from '../../apps/leadPlus/locales/es';
import communityLocale from '../../apps/community/locales/es';
import marketingLocale from '../../apps/marketing/locales/es';
import windowLocale from '../../apps/window/locales/es';
import settingsLocale from '../../apps/settings/locales/es';
import accountLocale from '../../apps/account/locales/es';
import webNotificationLocale from '../../apps/webNotification/locales/es';
import diskLocale from '../../apps/disk/locales/es';

const locale = {
  crm: {
    ...crmLocale,
  },
  auth: {
    ...authLocale,
  },
  leadPlus: {
    ...leadPlusLocale,
  },
  community: {
    ...communityLocale,
  },
  marketing: {
    ...marketingLocale,
  },
  window: {
    ...windowLocale,
  },
  settings: {
    ...settingsLocale,
  },
  account: {
    ...accountLocale,
  },
  webNotification: {
    ...webNotificationLocale,
  },
  disk: {
    ...diskLocale,
  },
  enums: {
    '-': '-',
    undefined: '-',
    null: '-',
    active: 'Activo',
    pending: 'Pendiente',
    passive: 'Pasivo',
    neutral: 'Neutral',
    cold: 'Frío',
    warm: 'Tibio',
    hot: 'Caliente',
    customer: 'Cliente',
    unqualified: 'No calificado',
    IQL: 'IQL (Información)',
    MQL: 'MQL (Marketing)',
    SQL: 'SQL (Ventas)',
    en: 'Inglés',
    fr: 'Francés',
    de: 'Alemán',
    it: 'Italiano',
    pt: 'Portugués',
    ru: 'Ruso',
    es: 'Español',
    tr: 'Turco',
    individual: 'Individual',
    business: 'Empresa',
    government: 'Gobierno',
    b2b: 'B2B',
    b2c: 'B2C',
    TRY: 'TRY',
    EUR: 'EUR',
    USD: 'USD',
    IRR: 'IRR',
    GBP: 'GBP',
    NOK: 'NOK',
    RUB: 'RUB',
    CHF: 'CHF',
    flat: 'Tarifa plana',
    hourly: 'Por hora',
    daily: 'Diario',
    monthly: 'Mensual',
    NDA: 'NDA',
    termsAndConditions: 'Términos y condiciones',
    lead: 'Lead',
    unknown: 'Desconocido',
    everyday: 'Todos los días',
    everyWeek: 'Cada semana',
    annual: 'Anual',
    email: 'Correo electrónico',
    sms: 'SMS',
    push: 'Push',
    web: 'Web',
    male: 'Masculino',
    female: 'Femenino',
    other: 'Otro',
    All: 'Todos',
    Unread: 'No leído',
    automatic: 'Automático',
    manual: 'Manual',
    high: 'Alto',
    low: 'Bajo',
    medium: 'Medio',
    urgent: 'Urgente',
    draft: 'Borrador',
    sent: 'Enviado',
    opened: 'Abierto',
    revised: 'Revisado',
    declined: 'Rechazado',
    accepted: 'Aceptado',
    product: 'Producto',
    service: 'Servicio',
    iyzico: 'Iyzico',
    stripe: 'Stripe',
    posbasit: 'POS Basit',
    paytr: 'PayTR',
    moneyTransfer: 'Transferencia de dinero',
    defective: 'Defectuoso',
    incorrectItem: 'Artículo incorrecto',
    changeOfMind: 'Cambio de opinión',
    throughTasks: 'A través de tareas',
    fixedRate: 'Tarifa fija',
    projectHours: 'Horas de proyecto',
    taskHours: 'Horas de tarea',
    onHold: 'En espera',
    nearToExpire: 'Cerca de expirar',
    trash: 'Papelera',
    A: 'A',
    B: 'B',
    C: 'C',
    yes: 'Sí',
    no: 'No',
    default: 'Predeterminado',
    meeting: 'Reunión',
    call: 'Llamada',
    event: 'Evento',
    postponed: 'Pospuesto',
    canceled: 'Cancelado',
    TWENTY_FOUR_HOURS: '24 horas',
    TWELVE_HOURS: '12 horas',
    '24-hours': '24 horas',
    '12-hours': '12 horas',
    requested: 'Solicitado',
    inspected: 'Inspeccionado',
    approved: 'Aprobado',
    disapproved: 'Desaprobado',
    resolved: 'Resuelto',
    refund: 'Reembolso',
    replacement: 'Reemplazo',
    repair: 'Reparación',
    exchange: 'Intercambio',

    // UIElementType
    fieldInput: 'Campo de entrada',
    header: 'Encabezado',
    paragraph: 'Párrafo',

    // LeadFieldEnum
    fullName: 'Nombre completo',
    position: 'Posición',
    website: 'Sitio web',
    phoneNumber: 'Número de teléfono',
    company: 'Empresa',
    sector: 'Sector',
    country: 'País',
    state: 'Estado',
    city: 'Ciudad',
    documents: 'Documentos',

    // reminder
    schedule: 'Programar',
    complete: 'Completar',
    cancel: 'Cancelar',
    scheduled: 'Programado',
    unscheduled: 'No programado',
    expired: 'Expirado',
    cancelled: 'Cancelado',

    // subscription status
    ongoing: 'ACTIVO',
    ongoingWithFailure: 'ACTIVO',
    ongoingWithCancelled: 'ACTIVO',
    failed: 'FALLIDO',

    //task status
    notStarted: 'No iniciado',
    inProgress: 'En progreso',
    tested: 'Probado',
    awaitingFeedback: 'Esperando comentarios',
    completed: 'Completado',

    // target type
    Lead: 'Lead',
    Customer: 'Cliente',
    Estimate: 'Estimación',
    Proposal: 'Propuesta',
    Invoice: 'Factura',
    Task: 'Tarea',
    User: 'Usuario',
    Organization: 'Organización',
    Project: 'Proyecto',
    None: 'Ninguno',
    Ticket: 'Ticket',
    Expense: 'Gasto',
    Contract: 'Contrato',

    //ticket categories
    incidentTicket: 'Ticket de incidente',
    requestForInformation: 'Solicitud de información',
    serviceRequest: 'Solicitud de servicio',
    problemTicket: 'Ticket de problema',
    changeRequest: 'Solicitud de cambio',
    featureRequest: 'Solicitud de función',
    feedback: 'Comentarios',
    complaint: 'Queja',
    maintenanceAndOutageReport: 'Informe de mantenimiento y corte',
    billingAndPaymentIssue: 'Problema de facturación y pago',
    internalTicket: 'Ticket interno',
    open: 'Abierto',
    answered: 'Respondido',
    closed: 'Cerrado',

    //permissions
    auth: 'Autenticación',
    comment: 'Comentario',
    dms: 'Documento',
    file: 'Documento',
    note: 'Nota',
    notification: 'Notificación',
    payment: 'Pago',
    presale: 'Preventa',
    reminder: 'Recordatorio',
    sale: 'Venta',
    task: 'Tarea',
    user: 'Usuario',
    permission: 'Permiso',
    role: 'Rol',
    leadSource: 'Fuente de lead',
    leadStatus: 'Estado de lead',
    leadTag: 'Etiqueta de lead',
    productCategory: 'Categoría de producto',
    webNotification: 'Notificación web',
    notificationHistory: 'Historial de notificaciones',
    order: 'Pedido',
    purchase: 'Compra',
    subscription: 'Suscripción',
    proposal: 'Propuesta',
    estimate: 'Estimación',
    customerCategory: 'Categoría de cliente',
    customerTag: 'Etiqueta de cliente',
    invoice: 'Factura',
    invoicePayment: 'Pago',
    invoiceReturn: 'Devolución',
    organization: 'Organización',
    organizationSettings: 'Configuración de la organización',
    organizationChart: 'Organigrama',
    department: 'Departamento',
    userRole: 'Rol de usuario',
    userPermission: 'Permiso de usuario',
    manage: 'Gestionar',
    view: 'Ver',
    export: 'Exportar',
    import: 'Importar',
    calendar: 'Calendario',
    appointment: 'Cita',
    contract: 'Contrato',
    contractTemplate: 'Plantilla de contrato',
    expense: 'Gasto',
    project: 'Proyecto',
    ticket: 'Ticket',
    dashboard: 'Panel',

    // file extension
    jpg: 'jpg',
    png: 'png',
    pdf: 'pdf',
    doc: 'doc',
    docx: 'docx',
    xls: 'xls',
    xlsx: 'xlsx',
    ppt: 'ppt',
    pptx: 'pptx',
    txt: 'txt',
    mp4: 'mp4',
    zip: 'zip',

    // payment status
    unPaid: 'No pagado',
    partiallyPaid: 'Parcialmente pagado',
    totallyPaid: 'Totalmente pagado',
    
  },
  organization: 'Organización',
  department: 'Departamento',
  user: 'Usuario',
  aboutUs: 'Sobre nosotros',
  termsAndConditions: 'Términos y condiciones',
  uploadDocuments: 'Subir documentos',
  privacyPolicy: 'Política de privacidad',
  cookiesPolicy: 'Política de cookies',
  resources: 'Recursos',
  allRightsReserved: 'Todos los derechos reservados',
  rowsPerPage: 'Filas por página',
  chartUpdateSuccess: '¡Gráfico actualizado con éxito!',
  imageUploadSuccess: '¡Imagen subida con éxito!',
  uploadPicture: 'Subir una imagen',
  expiresIn: 'Expira en ',
  expiresDays: ' días',
  subscriptionExpired: '¡Tu plan ha expirado!',
  noOptionsText: 'Sin opciones',
  noCategory: 'Sin categoría',

  ////////////////////////////////////////////////////////////////
  // Validations
  ////////////////////////////////////////////////////////////////

  isRequired: 'Este campo es obligatorio',
  minValue0: 'El valor mínimo es 0',
  fullNameIsRequired: 'El nombre completo es obligatorio',
  emailIsRequired: 'El correo electrónico es obligatorio',
  emailIsInvalid: 'El correo electrónico no es válido',
  phoneIsInvalid: 'El teléfono no es válido',
  nameIsRequired: 'El nombre es obligatorio',
  titleIsRequired: 'El título es obligatorio',
  profileImageIsRequired: 'La imagen de perfil es obligatoria',
  descriptionIsRequired: 'La descripción es obligatoria',
  subjectIsRequired: 'El asunto es obligatorio',
  phoneNumberIsRequired: 'El número de teléfono es obligatorio',
  quotaYupIsRequired: 'La cuota es obligatoria',
  dateYupIsRequired: 'La fecha es obligatoria',
  statusYupIsRequired: 'El estado es obligatorio',
  locationYupIsRequired: 'La ubicación es obligatoria',
  detailsIsRequired: 'Los detalles son obligatorios',
  surnameIsRequired: 'El apellido es obligatorio',
  birthDateIsRequired: 'La fecha de nacimiento es obligatoria',
  genderIsRequired: 'El género es obligatorio',
  countryIsRequired: 'El país es obligatorio',
  cityIsRequired: 'La ciudad es obligatoria',
  addressIsRequired: 'La dirección es obligatoria',
  contentIsRequired: 'El contenido es obligatorio',
  readingTimeIsRequired: 'El tiempo de lectura es obligatorio',
  tagsIsRequired: 'Las etiquetas son obligatorias',
  referencesIsRequired: 'Las referencias son obligatorias',
  relatedVideoIsRequired: 'El video relacionado es obligatorio',
  authorIsRequired: 'El autor es obligatorio',
  categoryIsRequired: 'La categoría es obligatoria',
  targetCategoryIsRequired: 'La categoría objetivo es obligatoria',
  languageIsRequired: 'El idioma es obligatorio',
  domainIsRequired: 'El dominio es obligatorio',
  targetIsRequired: 'El objetivo es obligatorio',
  questionIsRequired: 'La pregunta es obligatoria',
  answerIsRequired: 'La respuesta es obligatoria',
  titleIsTooShort25: 'El título es demasiado corto, la longitud mínima es 25',
  titleIsTooLong50: 'El título es demasiado largo, la longitud máxima es 50',
  contentIsTooShort50: 'El contenido es demasiado corto, la longitud mínima es 50',
  contentIsTooLong250: 'El contenido es demasiado largo, la longitud máxima es 250',
  descriptionIsTooShort400: 'La descripción es demasiado corta, la longitud mínima es 400',
  descriptionIsTooLong4000: 'La descripción es demasiado larga, la longitud máxima es 4000',
  quotaMustBePositive: 'La cuota debe ser positiva',
  quotaMustBeMultipleOf10: 'La cuota debe ser múltiplo de 10',
  participantsIsRequired: 'Los participantes son obligatorios',
  startDateIsRequired: 'La fecha de inicio es obligatoria',
  durationIsRequired: 'La duración es obligatoria',
  quotaIsRequired: 'La cuota es obligatoria',
  lastApplicationDateIsRequired: 'La fecha de última aplicación es obligatoria',
  statusIsRequired: 'El estado es obligatorio',
  locationIsRequired: 'La ubicación es obligatoria',
  typeIsRequired: 'El tipo es obligatorio',
  participationLinkIsRequired: 'El enlace de participación es obligatorio',
  durationMustBeMultipleOf5: 'La duración debe ser múltiplo de 5',
  durationMustBePositive: 'La duración debe ser positiva',
  nameIsTooShort2: 'El nombre es demasiado corto, la longitud mínima es 2',
  nameIsTooLong50: 'El nombre es demasiado largo, la longitud máxima es 50',
  surnameIsTooShort2: 'El apellido es demasiado corto, la longitud mínima es 2',
  surnameIsTooLong50: 'El apellido es demasiado largo, la longitud máxima es 50',
  stateIsRequired: 'El estado es obligatorio',
  positionIsRequired: 'La posición es obligatoria',
  companyIsRequired: 'La empresa es obligatoria',
  sectorIsRequired: 'El sector es obligatorio',
  qualificationIsRequired: 'La calificación es obligatoria',
  prospectNameIsRequired: 'El nombre del prospecto es obligatorio',
  prospectPhoneNumberIsRequired: 'El número de teléfono del prospecto es obligatorio',
  prospectContactFirstNameIsRequired: 'El nombre del contacto del prospecto es obligatorio',
  prospectContactLastNameIsRequired: 'El apellido del contacto del prospecto es obligatorio',
  prospectContactEmailIsRequired: 'El correo electrónico del contacto del prospecto es obligatorio',
  websiteIsInvalid: 'El sitio web no es válido',
  identityNumberIsRequired: 'El número de identidad es obligatorio',
  taxOfficeIsRequired: 'La oficina de impuestos es obligatoria',
  streetIsRequired: 'La calle es obligatoria',
  zipIsRequired: 'El código postal es obligatorio',
  passwordIsRequired: 'La contraseña es obligatoria',
  passwordIsInvalid: 'Debe tener al menos 8 caracteres, incluir mayúsculas, minúsculas, números y símbolos.',
  passwordAgainIsInvalid: 'Las contraseñas deben coincidir',
  cannotBeGreaterThanPrice: 'No puede ser mayor que el precio',
  endTimeMustBeLaterThanStartTime: 'Debe ser posterior a la hora de inicio',
  mustBeNumber: 'Debe ser un número',

  cardValidation: {
    required: 'Este campo es obligatorio',
    invalid: 'No válido',
    expired: 'Expirado',
  },

  ////////////////////////////////////////////////////////////////
  // Buttons
  ////////////////////////////////////////////////////////////////

  cancel: 'Cancelar',
  save: 'Guardar',
  update: 'Actualizar',
  delete: 'Eliminar',
  create: 'Crear',
  add: 'Añadir',
  close: 'Cerrar',
  submit: 'Enviar',
  addDocument: 'Añadir documento',
  upload: 'Subir',
  view: 'Ver',
  edit: 'Editar',
  remove: 'Eliminar',
  myPlan: 'Mi plan',
  expandAll: 'Expandir todo',
  collapseAll: 'Colapsar todo',
  show: 'Mostrar',
  download: 'Descargar',
  yes: 'Sí',
  no: 'No',
  completed: 'Completado',
  uploadDocument: 'Subir documento',
  newTask: 'Nueva tarea',
  loadMore: 'Cargar más',

  ////////////////////////////////////////////////////////////////
  // Tables
  ////////////////////////////////////////////////////////////////

  actions: 'Acciones',
  title: 'Título',
  content: 'Contenido',
  category: 'Categoría',
  target: 'Objetivo',
  status: 'Estado',
  date: 'Fecha',
  location: 'Ubicación',
  details: 'Detalles',
  name: 'Nombre',
  surname: 'Apellido',
  birthDate: 'Fecha de nacimiento',
  fullName: 'Nombre completo',
  email: 'Correo electrónico',
  subject: 'Asunto',
  createdAt: 'Creado el',
  userType: 'Tipo de usuario',
  page: 'Página',
  news: 'Noticias',
  offer: 'Oferta',
  products: 'Productos',
  updatedAt: 'Actualizado el',
  readingTime: 'Tiempo de lectura',
  tags: 'Etiquetas',
  references: 'Referencias',
  version: 'Versión',
  domain: 'Dominio',
  type: 'Tipo',
  language: 'Idioma',
  duration: 'Duración',
  startDate: 'Fecha de inicio',
  participants: 'Participantes',
  quota: 'Cuota',
  question: 'Pregunta',
  answer: 'Respuesta',
  lastApplicationDate: 'Fecha de última aplicación',
  selectAnOption: 'Seleccione una opción',
  showing: 'Mostrando {{start}} a {{end}} de {{total}} entradas',

  ////////////////////////////////////////////////////////////////
  // Menus
  ////////////////////////////////////////////////////////////////
  dashboard: 'Panel',
  blog: 'Blog',
  blogs: 'Blogs',
  categories: 'Categorías',
  authors: 'Autores',
  faq: 'FAQ',
  faqs: 'FAQs',
  faqCategory: 'Categorías de FAQ',
  form: 'Formulario',
  contactForm: 'Formularios de contacto',
  subscriptionForm: 'Formularios de suscripción',
  blogTargetCategories: 'Categorías objetivo',
  users: 'Usuarios',
  departments: 'Departamentos',
  roles: 'Roles',
  permissions: 'Permisos',
  leadStatuses: 'Estados de lead',
  leadSources: 'Fuentes de lead',
  leadForms: 'Formularios de lead',
  customers: 'Clientes',
  contacts: 'Contactos',
  customerCategories: 'Categorías de cliente',
  estimates: 'Estimaciones',
  proposals: 'Propuestas',
  invoices: 'Facturas',
  payments: 'Pagos',
  contracts: 'Contratos',
  returns: 'Devoluciones',
  services: 'Servicios',
  reminders: 'Recordatorios',
  appointments: 'Citas',
  starred: 'Destacados',
  recent: 'Recientes',
  actionMenu: 'Menú de acciones',
  themeMenu: 'Menú de temas',
  notifications: 'Notificaciones',
  languageMenu: 'Menú de idiomas',

  ////////////////////////////////////////////////////////////////
  // Permissions
  ////////////////////////////////////////////////////////////////

  organizationLevel: 'Nivel de organización',
  organizationLevelDesc: 'El nivel de la organización en el que se encuentra el usuario.',
  departmentLevel: 'Nivel de departamento',
  departmentLevelDesc: 'El nivel del departamento en el que se encuentra el usuario.',
  userLevel: 'Nivel de usuario',
  userLevelDesc: 'El nivel del usuario en el que se encuentra el usuario.',

  ////////////////////////////////////////////////////////////////
  // Abbreviations
  ////////////////////////////////////////////////////////////////
  min: 'min',

  ////////////////////////////////////////////////////////////////
  // Inputs
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // Days
  ////////////////////////////////////////////////////////////////
  sunday: 'Domingo',
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  today: 'Hoy',
};

export default locale; 
const locale = {
  title: "Kundenkategorien",
  headerTitle: "Kundenkategorien",
  create: "Kundenkategorie erstellen",
  columnId: "ID",
  columnName: "Name",
  columnRoles: "Rollen",
  columnActions: "Aktionen",
  rolesButton: "Rollen bearbeiten",
  createCustomerCategoryTitle: "Neue Kundenkategorie erstellen",
  customerCategoryNameLabel: "Name der Kundenkategorie",
  rolesLabel: "Rollen",
  createCustomerCategoryButton: "Erstellen",
  emptyStateContent1: "Keine Kundenkategorien gefunden",
  emptyStateContent2:
    "Sie können neue Kundenkategorien erstellen, indem Sie auf die Schaltfläche unten klicken.",
  addNewRole: "Neue Rolle hinzufügen",
  deletedSuccessfully: "Kundenkategorie erfolgreich gelöscht",
  deleteConfirm: "Sind Sie sicher, dass Sie diese Kundenkategorie löschen möchten?",
  delete: "Löschen",
  enTranslation: "Englische Übersetzung",
  trTranslation: "Türkische Übersetzung",
  department: "Abteilung",
  parentCategory: "Übergeordnete Kategorie",
  icon: "Symbol",
  name: "Name",
  createCustomerCategorySuccess: "Kundenkategorie erfolgreich erstellt",
  addDepartment: "Abteilung hinzufügen",
  showMore: "Mehr anzeigen",
  columnIcon: "Symbol",
  columnDepartment: "Abteilung",
  columnParentCategory: "Übergeordnete Kategorie",
  selectIconTitle: "Symbol auswählen",
  searchIcons: "Symbole suchen",
  update: "Kundenkategorie aktualisieren",
  updateCustomerCategorySuccess: "Kundenkategorie erfolgreich aktualisiert",
  actions: "Aktionen",
  createdAt: "Erstellt am",
  emptyState1: "Keine Kundenkategorien gefunden",
  emptyState2: "Sie können neue Kundenkategorien erstellen, indem Sie auf die Schaltfläche unten klicken.",
};

export default locale; 
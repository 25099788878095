const locale = {
  title: 'Plantilla de Contrato',
  emptyState1: 'Aún no hay plantillas de contrato.',
  emptyState2:
    'Almacene y gestione todos los detalles del contrato aquí para agilizar su facturación y mantener acuerdos claros con sus clientes.',
  create: 'Crear Plantilla de Contrato',
  update: 'Actualizar Plantilla de Contrato',
  delete: 'Eliminar Plantilla de Contrato',
  createSuccess: 'Plantilla de contrato creada con éxito.',
  updateSuccess: 'Plantilla de contrato actualizada con éxito.',
  deleteSuccess: 'Plantilla de contrato eliminada con éxito.',
  deleteConfirm: '¿Está seguro de que desea eliminar esta plantilla de contrato? Esta acción no se puede deshacer.',
};

export default locale; 
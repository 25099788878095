const locale = {
  pricing: 'Precios',
  saveUpTo: 'Ahorre hasta',
  monthly: 'mensual',
  monthlyUppercase: 'Mensual',
  annually: 'anual',
  annuallyUppercase: 'Anual',
  organization: 'Organización',
  month: 'Mes',
  billedMonthly: 'Facturado Mensualmente',
  billedAnnually: 'Facturado Anualmente',
  users: 'Usuarios',
  startTrialForFree: 'Comenzar Prueba Gratis',
  buy: 'COMPRAR',
  contactUs: 'Contáctenos',
  starterPlan: 'Plan Inicial',
  enterprisePlan: 'Plan Empresarial',
  mostPopular: 'Más Popular',
  buyPackage: 'Comprar Paquete',
  buyPackageDescription:
    'Este paquete llamado {{name}} incluye un límite de almacenamiento de {{storageLimit}} GB y un límite de usuarios de {{userLimit}}.',
  dontHaveBillingInfo: 'No tiene información de facturación para usar.',
  dontHaveCard: 'No tiene ninguna tarjeta para usar.',
  addBillingInfo: 'Añadir Información de Facturación',
  addCard: 'Añadir Tarjeta',
  manageBillingInfo: 'Gestionar Información de Facturación',
  manageCards: 'Gestionar Tarjetas',
  summary: 'Resumen',
  iAcceptThe: 'Acepto la',
  preliminaryInformation: 'Información Preliminar',
  distanceSalesAgreement: 'Acuerdo de Venta a Distancia',
  close: 'Cerrar',
  cancel: 'Cancelar',
  subscribeSuccess1: 'Su suscripción ha comenzado con éxito.',
  subscribeSuccess2: 'Bienvenido a bordo.',
  continue: 'Continuar',
  letUsCall: 'Permítanos Llamarle',
  billingInfo: 'Información de Facturación',
  selectedCard: 'Tarjeta Seleccionada',
  currentPlan: 'Plan Actual',
  renewAt: 'Renovar el',
  active: 'Activo',
  usageLimits: 'Límites de Uso',
  storageUsage: 'Uso de almacenamiento',
  activeCard: 'Tarjeta Activa',
  trialPackage: 'Paquete de Prueba',
  trialPackageDescription:
    'Este paquete ofrece un período de uso de 7 días, con un límite de almacenamiento de {{storageLimit}} GB y un límite de usuarios de {{userLimit}}.',
  confirmTrial: '¿Está seguro de que desea comenzar la prueba?',
  beginTrial: 'Comenzar Prueba',
  contactFormSuccess: 'Su solicitud ha sido enviada con éxito. Nos pondremos en contacto con usted pronto.',

  packageDescription:
    'Este paquete ofrece un período de uso de 7 días, con un límite de almacenamiento de {{storageLimit}} GB y un límite de usuarios de {{userLimit}}.',
  beginTrialSuccess: 'Prueba iniciada con éxito.',
  purchaseSuccess: 'Comprado con éxito.',
  title: 'Precios',
  unknownError: 'Se produjo un error desconocido.',
  contactMessage: 'Teléfono: {{phone}}, Nombre de la Organización: {{orgName}}',
  detailTitle: 'Detalle',
  trialPackageTitle: 'Paquete de Prueba',
  buyPackageTitle: 'Comprar Paquete',
  contactUsTitle: 'Contáctenos',
  preliminaryInfoTitle: 'Información Preliminar',
  distanceSalesAgreementTitle: 'Acuerdo de Venta a Distancia',
  welcomeTrial1: 'Su paquete de prueba ha comenzado con éxito. Bienvenido a bordo.',
  welcomeTrial2: 'Bienvenido a bordo.',
  noBillingInfo: 'No tiene información de facturación para usar.',
  billingInfoLabel: 'Información de Facturación*',
  noCardInfo: 'No tiene ninguna tarjeta para usar.',
  selectedCardLabel: 'Tarjeta Seleccionada*',
  acceptPreliminaryInfo: 'Acepto la Información Preliminar',
  acceptDistanceSalesAgreement: 'Acepto el Acuerdo de Venta a Distancia',
  preliminaryInfoDescription:
    'Este paquete ofrece un período de uso de 7 días, con un límite de almacenamiento y permisos de usuario como se describe en el acuerdo.',
  subscriptionSuccess: 'Su suscripción ha comenzado con éxito. Bienvenido a bordo.',
  selectedPeriodLabel: '/{{selectedPeriod}}',
  distanceSalesAgreementDescription:
    'Este paquete ofrece un período de uso de 7 días, con un límite de almacenamiento y permisos de usuario como se describe en el acuerdo de venta a distancia.',
  goCRM: 'Ir al CRM',
  tryForFree: 'Probar Gratis',
  onePaymentOf: 'Un pago de',

  cancelled: 'Cancelado',
  expired: 'Expirado',
  completed: 'Completado',
  failed: 'Fallido',
  ongoingWithCancelled: 'En curso con cancelación',
  ongoingWithFailure: 'En curso con fallo',

  payment: 'Pago',
  upgrade: 'ACTUALIZAR',
  paymentSuccess: 'Pago completado con éxito.',
  paymentFailure: 'Proceso de pago completado',
};

export default locale; 
const locale = {
  contracts: 'Contratos',
  contract: 'Contrato',
  contractConversions: 'Conversões de Contratos',
  title: 'Contratos',
  create: 'Criar Contrato',
  update: 'Atualizar Contrato',
  delete: 'Excluir Contrato',
  createSuccess: 'Contrato criado com sucesso.',
  updateSuccess: 'Contrato atualizado com sucesso.',
  deleteSuccess: 'Contrato excluído com sucesso.',
  deleteConfirm: 'Tem certeza de que deseja excluir este contrato? Esta ação não pode ser desfeita.',
  emptyState1: 'Nenhum contrato adicionado ainda.',
  emptyState2:
    'Armazene e gerencie todos os detalhes do contrato aqui para agilizar seu faturamento e manter acordos claros com seus clientes.',

  // labels
  contractTemplateLabel: 'Modelo de Contrato',
  contractValue: 'Valor do Contrato',
  contractType: 'Tipo de Contrato',
  signedDate: 'Data de Assinatura',
  signedUser: 'Usuário Assinante',
  signStatus: 'Status de Assinatura',

  //detail drawer
  changeStatus: 'Alterar Status',
  statusChangeSuccess: 'Status alterado com sucesso.',
  generalInfoTitle: 'Informações Gerais',
  customerInfoTitle: 'Informações do Cliente',
  projectInfoTitle: 'Informações do Projeto',
  contractRenew: 'Renovar Contrato',
  renew: 'Renovar Contrato',
  renewSuccess: 'Contrato renovado com sucesso.',
  emptyStateRenew: 'Nenhum contrato para renovar.',
  renewHistory: 'Histórico de Renovações',
};

export default locale; 
const locale = {
  demo: "Demo",
  title: "Cos'è <highlight>CoCRM</highlight> Marketing?",
  description:
    "In CoCRM, non ci limitiamo a rendere più facile la gestione delle relazioni con i clienti esistenti — ti aiutiamo anche a ottimizzare i tuoi processi di marketing per raggiungere i tuoi obiettivi aziendali. Con CoCRM Marketing, puoi raggiungere il tuo pubblico target in modo più efficace e rendere le tue campagne di marketing più efficienti.",
  featuresTitle: "Con CoCRM Marketing, puoi:",
  features1:
    "Personalizzare le tue campagne di marketing per paese, città e settore per una gestione più strategica,",
  features2:
    "Creare campagne personalizzate adattate ai mercati specifici in cui desideri aumentare la consapevolezza del tuo marchio,",
  features3:
    "Tracciare il percorso del cliente in modo più efficiente per supportare i tuoi obiettivi di vendita e marketing,",
  features4:
    "Sviluppare strategie orientate ai risultati integrando strumenti di marketing digitale.",
  buttonTitle: "Richiedi un preventivo",
  formDescription:
    "Compila il modulo e ti risponderemo il prima possibile.",
  sendSuccess: "Grazie! Ti risponderemo il prima possibile.",
  formTitle: "Richiedi un preventivo",
  formButtonTitle: "Invia",
  fullName: "Nome completo",
  email: "Email",
  phone: "Telefono",
  orgName: "Nome dell'organizzazione",
};

export default locale; 
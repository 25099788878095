const locale = {
  productCategories: 'Kategorien',
  title: 'Produkt/Dienstleistungskategorien',
  create: 'Kategorie erstellen',
  delete: 'Kategorie löschen',
  deleteConfirm: 'Sind Sie sicher, dass Sie diese Kategorie löschen möchten: ',
  deletedSuccessfully: 'Kategorie erfolgreich gelöscht',
  createCategory: 'Kategorie erstellen',
  categoryCreateSuccess: 'Kategorie erfolgreich erstellt',
  name: 'Kategoriename',
  icon: 'Kategoriesymbol',
  showMore: 'Mehr anzeigen',
  update: 'Aktualisieren',
  updateCategory: 'Kategorie aktualisieren',
  categoryUpdateSuccess: 'Kategorie erfolgreich aktualisiert',
  en: 'Englisch',
  tr: 'Türkisch',
  createSuccess: 'Kategorie erfolgreich erstellt',
  updateSuccess: 'Produktkategorie erfolgreich aktualisiert',
  actions: 'Aktionen',
  createdAt: 'Erstellt am',
  emptyState1: 'Keine Kategorien gefunden',
  emptyState2: 'Bitte erstellen Sie eine neue Kategorie',
  createProductCategory: 'Produktkategorie erstellen',
};

export default locale; 
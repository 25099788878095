const locale = {
  proposal: 'Propuesta',
  title: 'Propuestas',
  proposals: 'Propuestas',
  create: 'Crear Propuesta',
  update: 'Actualizar Propuesta',
  delete: 'Eliminar',
  view: 'Ver Propuesta',
  list: 'Listar Propuestas',
  createdSuccessfully: 'Propuesta creada con éxito',
  updatedSuccessfully: 'Propuesta actualizada con éxito',
  deleteConfirm: '¿Está seguro de que desea eliminar? Esta acción no se puede deshacer.',
  deletedSuccessfully: 'Propuesta eliminada con éxito',
  createInfo: 'Puede crear nuevas propuestas aquí.',
  prefix: 'Prefijo',
  proposalId: 'Propuesta',
  timeZone: 'Zona Horaria',
  prospectTargetEntityId: 'Objetivo Prospecto',
  prospectTargetEntityRef: 'Referencia Objetivo Prospecto',
  prospectType: 'Tipo de Prospecto',
  prospectName: 'Nombre del Prospecto',
  prospectVAT: 'IVA del Prospecto',
  prospectPhoneNumber: 'Teléfono del Prospecto',
  prospectCountry: 'País del Prospecto',
  prospectState: 'Estado del Prospecto',
  prospectCity: 'Ciudad del Prospecto',
  prospectZIP: 'Código Postal del Prospecto',
  prospectAddress: 'Dirección del Prospecto',
  prospectContactFirstName: 'Nombre de Contacto del Prospecto',
  prospectContactLastName: 'Apellido de Contacto del Prospecto',
  prospectContactEmail: 'Email de Contacto del Prospecto',
  language: 'Idioma',
  date: 'Fecha',
  expireDate: 'Fecha de Vencimiento',
  sendEmailOnExpiration: 'Enviar Email al Vencimiento',
  expirationEmailSentDate: 'Fecha de Envío de Email de Vencimiento',
  projectOrServiceDesc: 'Descripción del Proyecto o Servicio',
  scopeOfWork: 'Alcance del Trabajo',
  assumptionsAndExclusions: 'Suposiciones y Exclusiones',
  paymentTerms: 'Condiciones de Pago',
  currency: 'Moneda',
  products: 'Productos',
  addProducts: 'Añadir Productos',
  offers: 'Ofertas',
  reference: 'Referencia',
  salesAgentIds: 'Agentes de Ventas',
  allowComments: 'Permitir Comentarios',
  useLegalNDA: 'Usar NDA',
  legalNDAId: 'NDA Legal',
  useLegalTermAndCond: 'Usar Términos y Condiciones',
  legalTermAndCondId: 'Términos y Condiciones Legales',
  signerUserId: 'Usuario Firmante',
  listInfo: 'Lista de todas las propuestas.',
  importedError: 'Error importado',
  importedSuccessfully: 'Importado con éxito',
  downloadFormError: 'Error al descargar formulario',
  downloadFormSuccessfully: 'Formulario descargado con éxito',
  downloadExampleFormSuccessfully: 'Formulario de ejemplo descargado con éxito',
  downloadExampleFormError: 'Error al descargar formulario de ejemplo',
  emptyState1: 'No se encontraron propuestas.',
  emptyState2: 'Puede crear o importar propuestas inmediatamente.',
  createProposal: 'Crear Propuesta',
  importProposal: 'Importar Propuesta',
  importTitle: 'Importar Propuestas',
  downloadExample: 'Descargar Ejemplo',
  importText1: 'Puede importar propuestas desde esta página.',
  importText2: 'Puede utilizar el formulario de ejemplo a continuación para publicar sus propuestas.',
  importExport: 'Importar/Exportar',
  exportData: 'Exportar Datos',
  importData: 'Importar Datos',
  b2b: 'B2B',
  b2c: 'B2C',
  viewProposal: 'Ver',
  edit: 'Editar',
  junk: 'Basura',
  unJunk: 'No Basura',
  addNewRole: 'Añadir Nuevo Rol',
  actions: 'Acciones',
  businessName: 'Nombre de la Empresa',
  governmentAgency: 'Agencia Gubernamental',
  fullName: 'Nombre Completo',
  VAT: 'IVA',
  phoneNumber: 'Número de Teléfono',
  country: 'País',
  state: 'Estado',
  city: 'Ciudad',
  ZIP: 'Código Postal',
  address: 'Dirección',
  contactFirstName: 'Nombre de Contacto',
  contactLastName: 'Apellido de Contacto',
  contactEmail: 'Email de Contacto',
  sentDate: 'Fecha de Envío',
  name: 'Nombre',
  description: 'Descripción',
  productUnit: 'Unidad',
  quantity: 'Cantidad',
  price: 'Precio',
  priceType: 'Tipo de Precio',
  discountedPrice: 'Precio con Descuento',
  finalPrice: 'Precio Final',
  tax: 'Impuesto',
  service: 'Servicio',
  subTotal: 'Subtotal',
  taxTotal: 'Total de Impuestos',
  discount: 'Descuento',
  product: 'Producto',
  services: 'Servicios',
  references: 'Referencias',
  add: 'Añadir',
  salesAgent: 'Agente de Ventas',
  executiveSummary: 'Resumen Ejecutivo',
  companyBackground: 'Antecedentes de la Empresa',
  projectorServiceDesc: 'Descripción Detallada del Proyecto/Servicio',
  costBreakdown: 'Desglose de Costos',
  projectTimeline: 'Cronograma del Proyecto',
  testimonialCaseStudies: 'Testimonios y Casos de Estudio',
  conclusion: 'Conclusión',
  proposalCreateSuccess: 'Propuesta creada con éxito',
  sentSuccessfully: 'Propuesta enviada con éxito',
  send: 'Enviar Propuesta',
  resend: 'Reenviar Propuesta',
  sendSureTitle: 'Enviar Propuesta',
  sendSureDesc: '¿Está seguro de que desea enviar esta propuesta?',
  changeStatus: 'Cambiar Estado',
  statusUpdatedSuccessfully: 'Estado actualizado con éxito',

  // alert
  noProposal: 'No hay propuestas',
  noProposalContent1: 'No se encontraron propuestas',
  noProposalSend: 'No hay propuestas enviadas',

  // generalInfo
  generalInfoTitle: 'Información General',
  status: 'Estado',
  createdAt: 'Creado el',
  createdBy: 'Creado por',
  updatedAt: 'Actualizado el',
  notAvailable: 'No Disponible',
  updateStatus: 'Actualizar Estado',

  // customerInfo
  customerInfoTitle: 'Información del Cliente',
  contactPhoneNumber: 'Número de Teléfono',

  //productInfo
  productInfoTitle: 'Detalles del Producto',
  productNameUnknown: 'Producto Desconocido',
  noProducts: 'No hay productos disponibles.',

  //legalInfo
  legalInfoTitle: 'Información Legal',
  ndaTitle: 'Acuerdo de Confidencialidad (NDA)',
  termsTitle: 'Términos y Condiciones',
  documentName: 'Nombre del Documento',
  documentDescription: 'Descripción',
  documentContent: 'Contenido',

  //financialInfo
  financialInfoTitle: 'Información Financiera',
  shippingTotal: 'Total de Envío',
  total: 'Precio Total',
  noFinancialData: 'No hay datos financieros disponibles.',

  // documentInfo
  documentsTitle: 'Documentos',
  documentNameUnknown: 'Documento Desconocido',
  mimeType: 'Tipo de Archivo',
  download: 'Descargar',
  noDocuments: 'No hay documentos disponibles.',

  // salesAgents
  salesAgentsTitle: 'Agentes de Ventas',
  unknownAgent: 'Agente Desconocido',
  noSalesAgents: 'No hay agentes de ventas disponibles.',

  //signature
  signatureTitle: 'Información de Firma',
  additionalInfoTitle: 'Información Adicional',
  signature: 'Firma',
  signerUser: 'Usuario Firmante',

  //additionalInfo
  yes: 'Sí',
  no: 'No',
};

export default locale; 
import dashboardLocale from '../domains/01-dashboard/locales/pt';
import productAndServiceLocale from '../domains/08-productAndService/locales/pt';
import organizationalChartLocale from '../domains/02-organizationalChart/locales/pt';
import leadLocale from '../domains/03-lead/locales/pt';
import customerLocale from '../domains/04-customer/locales/pt';
import preSaleLocale from '../domains/05-preSale/locales/pt';
import saleLocale from '../domains/06-sale/locales/pt';
import pricingLocale from '../domains/16-pricing/locales/pt';
import calendarLocale from '../domains/12-calendar/locales/pt';
import contractLocale from '../domains/07-contract/locales/pt';
import supportLocale from '../domains/15-support/locales/pt';
import reportLocale from '../domains/13-report/locales/pt';
import taskLocale from '../domains/11-task/locales/pt';
import projectLocale from '../domains/10-project/locales/pt';
import expenseLocale from '../domains/09-expense/locales/pt';
import noteLocale from '../domains/14-note/locales/pt';

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  productAndService: {
    ...productAndServiceLocale,
  },
  customer: {
    ...customerLocale,
  },
  preSale: {
    ...preSaleLocale,
  },
  sale: {
    ...saleLocale,
  },
  pricing: {
    ...pricingLocale,
  },
  calendar: {
    ...calendarLocale,
  },
  contract: {
    ...contractLocale,
  },
  support: {
    ...supportLocale,
  },
  report: {
    ...reportLocale,
  },
  task: {
    ...taskLocale,
  },
  project: {
    ...projectLocale,
  },
  expense: {
    ...expenseLocale,
  },
  note: {
    ...noteLocale,
  },

  ////////////////////////////////////////////////////////////////
  // common used names
  ////////////////////////////////////////////////////////////////
  preSaleLabel: 'Pré-Venda',
  preSales: 'Pré-Vendas',
  contactDetails: 'Detalhes',
  localization: 'Localização',
  info: 'Info',
  about: 'Sobre',
  contact: 'Contato',
  record: 'Registro',
  records: 'Registros',
  tracking: 'Rastreamento',
  pricingLabel: 'Preços',
  dashboardLabel: 'Painel',
  marketing: 'Marketing',
  campaigns: 'Campanhas',
  emails: 'E-mails',
  sms: 'SMS',
  socialMedia: 'Mídia Social',
  salesLabel: 'Vendas',
  businessIntelligence: 'Inteligência de Negócios',
  calendarLabel: 'Calendário',
  supportLabel: 'Suporte',
  accountMenu: 'Menu da Conta',
  profile: 'Perfil',
  myAccount: 'Minha Conta',
  logout: 'Sair',
  searchPlaceholder: 'Tente pesquisar por "insights"',
  searchAnIcon: 'Pesquisar um Ícone',

  // --------------------
  // settings related
  // --------------------
  settings: 'Configurações',
  english: 'Inglês',
  turkish: 'Turco',
  french: 'Francês',
  german: 'Alemão',
  italian: 'Italiano',
  portuguese: 'Português',
  russian: 'Russo',
  spanish: 'Espanhol',
  dutch: 'Holandês',
  darkMode: 'Modo Escuro',
  lightMode: 'Modo Claro',
  languageSelectLabel: 'Selecionar Idioma',

  ////////////////////////////////////////////////////////////////
  // common action texts
  ////////////////////////////////////////////////////////////////
  actions: 'Ações',
  yes: 'Sim',
  no: 'Não',
  importExport: 'Importar/Exportar',
  view: 'Visualizar',
  list: 'Lista',
  create: 'Criar',
  update: 'Atualizar',
  delete: 'Excluir',
  edit: 'Editar',
  cancel: 'Cancelar',
  downloadSuccessful: 'Download bem-sucedido',
  export: 'Exportar',
  import: 'Importar',
  exportData: 'Exportar Dados',
  importData: 'Importar Dados',
  showMore: 'Mostrar Mais',
  selectColor: 'Selecionar Cor',
  justForMe: 'Apenas Para Mim',

  ////////////////////////////////////////////////////////////////
  // common situational texts
  ////////////////////////////////////////////////////////////////
  total: 'Total',
  weeklyTotal: 'Total Semanal',
  hasCreatedAt: 'foi criado em ',
  createdBy: 'Criado por ',
  updatedBy: 'Atualizado por ',
  uploadLogo: 'Carregar Logo',
  selectIconTitle: 'Selecionar um Ícone',
  searchIcons: 'Pesquisar Ícones',
  uploadPictureText: 'Carregar uma imagem',
  searchForAnIcon: 'Pesquisar um Ícone',
  selectedIcon: 'Ícone Selecionado:',
  signedUser: 'Usuário Conectado',

  // --------------------
  // delete dialog
  // --------------------
  deletedEntitySuccessfully: 'Excluído com sucesso',
  deleteEntityContent: 'Tem certeza de que deseja excluir? Esta ação não pode ser desfeita.',
  deleteEntityTitle: 'Excluir',

  // --------------------
  // organization
  // --------------------
  mustCreateOrganizationTitle: 'Criar Organização',
  mustCreateOrganizationContent: 'Você deve criar uma organização para usar o painel.',
  mustCreateOrganizationSuccess: 'Organização criada com sucesso.',

  // --------------------
  // import export
  // --------------------
  importedError: 'Erro de importação',
  importedSuccessfully: 'Importado com sucesso',
  downloadExample: 'Baixar Exemplo',
  downloadFormError: 'Erro ao baixar o formulário',
  downloadFormSuccessfully: 'Formulário baixado com sucesso',
  downloadExampleFormSuccessfully: 'Formulário de exemplo baixado com sucesso',
  downloadExampleFormError: 'Erro ao baixar o formulário de exemplo',

  // --------------------
  // estimate
  // --------------------
  addEstimate: 'Adicionar Orçamento',
  noEstimate: 'Não há orçamentos',
  noEstimateContent1: 'Orçamentos não encontrados',
  additionalInfo: 'Informações Adicionais',

  // --------------------
  // proposal
  // --------------------
  addProposal: 'Adicionar Proposta',
  noProposal: 'Não há propostas',
  lastProposal: 'Última Proposta',
  proposalSend: 'Proposta Enviada',
  noProposalSend: 'Não há propostas enviadas',
  noProposalContent1: 'Propostas não encontradas',
  sendEmailOnExpiration: 'Enviar e-mail na expiração',
  testimonialCaseStudies: 'Estudos de Caso de Depoimentos',
  projectTimeline: 'Cronograma do Projeto',
  executiveSummary: 'Resumo Executivo',
  costBreakdown: 'Detalhamento de Custos',
  conclusion: 'Conclusão',
  projectorServiceDesc: 'Descrição Detalhada do Projeto/Serviço',
  noSalentAgents: 'Não há agentes de vendas',

  // --------------------
  // document
  // --------------------
  addDocument: 'Adicionar Documento',
  noDocument: 'Não há documentos',
  noDocumentContent1: 'Documentos não encontrados',

  // --------------------
  // task
  // --------------------
  noTask: 'Não há tarefas',
  noTaskContent1: 'Tarefas não encontradas',

  // --------------------
  // activity
  // --------------------
  noActivity: 'Não há atividades',
  addActivity: 'Adicionar Atividade',
  noActivityContent1: 'Atividades não encontradas',
  noActivityContent2: 'Não há atividades para esta entidade',

  // --------------------
  // note
  // --------------------
  addNote: 'Adicionar Nota',
  noNote: 'Não há notas',
  emptyStateNoteList1: 'Notas não encontradas',
  noteDate: 'Data da Nota',
  noteUsers: 'Usuários da Nota',
  noteTitle: 'Título',

  // --------------------
  // reminder
  // --------------------
  addReminder: 'Adicionar Lembrete',
  noReminder: 'Não há lembretes',
  emptyStateReminderList1: 'Lembretes não encontrados',
  remindDate: 'Data do Lembrete',
  remindUsers: 'Lembrar Usuários',
  remindTitle: 'Título',

  // --------------------
  // appointment
  // --------------------
  noAppointments: 'Não há compromissos',
  makeAnAppointment: 'Marcar um Compromisso',

  // --------------------
  // assignee
  // --------------------
  assignees: 'Responsáveis',
  noAssignees: 'Sem Responsáveis',
  addAssignee: 'Adicionar Responsável',

  // --------------------
  // followers
  // --------------------
  followers: 'Seguidores',
  noFollowers: 'Sem Seguidores',

  // --------------------
  // product and services
  // --------------------
  addProduct: 'Adicionar Produto',
  addProductOrService: 'Adicionar Produto ou Serviço',
  editProdutctOrService: 'Editar Produto ou Serviço',
  productType: 'Tipo de Produto',
  editProduct: 'Editar Produto',
  product: 'Produto',

  // --------------------
  // ticket
  // --------------------
  subject: 'Assunto',
  contactName: 'Nome do Contato',
  tickets: 'Tickets',

  ////////////////////////////////////////////////////////////////
  // common used words
  ////////////////////////////////////////////////////////////////
  of: 'de',

  ////////////////////////////////////////////////////////////////
  // common used entity names
  ////////////////////////////////////////////////////////////////
  leadLabel: 'Lead',
  leads: 'Leads',
  leadForms: 'Formulários de Lead',
  leadStatuses: 'Status de Lead',
  leadSources: 'Fontes de Lead',
  customerLabel: 'Cliente',
  customers: 'Clientes',
  estimateLabel: 'Orçamento',
  estimates: 'Orçamentos',
  proposalLabel: 'Proposta',
  proposals: 'Propostas',
  invoiceLabel: 'Fatura',
  invoices: 'Faturas',
  productsServices: 'Produtos & Serviços',
  activityLabel: 'Atividade',
  activities: 'Atividades',
  noteLabel: 'Nota',
  notes: 'Notas',
  documentLabel: 'Documento',
  documents: 'Documentos',
  appointmentLabel: 'Compromisso',
  appointments: 'Compromissos',
  reminderLabel: 'Lembrete',
  reminders: 'Lembretes',
  departmentLabel: 'Departamento',
  departments: 'Departamentos',
  contractLabel: 'Contrato',
  contractTemplate: 'Modelo de Contrato',
  contracts: 'Contratos',
  paymentLabel: 'Pagamento',
  payments: 'Pagamentos',
  organizationalChartLabel: 'Organograma',
  expenseLabel: 'Despesa',
  expenses: 'Despesas',
  productAndServiceLabel: 'Produtos & Serviços',
  projectLabel: 'Projeto',
  projects: 'Projetos',
  taskLabel: 'Tarefa',
  tasks: 'Tarefas',
  reportLabel: 'Relatório',
  reports: 'Relatórios',
  billingInformation: 'Informações de Faturamento',
  billingInfo: 'Info de Faturamento',
  otherBillingInformation: 'Outras Informações de Faturamento',
  shippingInfo: 'Info de Envio',
  contactInformation: 'Informações de Contato',
  otherContactInformation: 'Outras Informações de Contato',
  warningBillingInfo: 'Uma vez que um campo é preenchido, todos os campos devem ser preenchidos.',
  addRole: 'Adicionar Função',

  ////////////////////////////////////////////////////////////////
  // common fields
  ////////////////////////////////////////////////////////////////
  color: 'Cor',
  icon: 'Ícone',
  index: 'Índice',
  title: 'Título',
  fullName: 'Nome Completo',
  firstName: 'Primeiro Nome',
  lastName: 'Sobrenome',
  surname: 'Sobrenome',
  convert: 'Converter',
  country: 'País',
  state: 'Estado',
  city: 'Cidade',
  industry: 'Indústria',
  address: 'Endereço',
  website: 'Website',
  emailAddress: 'Endereço de E-mail',
  name: 'Nome',
  companyName: 'Nome da Empresa',
  companyEmail: 'E-mail da Empresa',
  companyPhone: 'Telefone da Empresa',
  timezone: 'Fuso Horário',
  companyLogo: 'Logo da Empresa',
  language: 'Idioma',
  department: 'Departamento',
  email: 'E-mail',
  phoneNumber: 'Número de Telefone',
  type: 'Tipo',
  createdAt: 'Criado Em',
  updatedAt: 'Atualizado Em',
  prefix: 'Prefixo',
  tags: 'Tags',
  tagsPlaceholder: 'Tags',
  addTagsPlaceholder: 'Adicionar Tags',
  addNewOption: 'Adicionar nova opção',
  enterText: 'Digite o texto...',
  company: 'Empresa',
  sector: 'Setor',
  phone: 'Telefone',
  content: 'Conteúdo',
  priority: 'Prioridade',
  position: 'Posição',
  status: 'Status',
  source: 'Fonte',
  date: 'Data',
  expireDate: 'Data de Expiração',
  totalPrice: 'Preço Total',
  currency: 'Moeda',
  category: 'Categoria',
  parentCategory: 'Categoria Principal',
  vat: 'VAT',
  zip: 'CEP',
  street: 'Rua',
  taxOffice: 'Escritório Fiscal',
  target: 'Alvo',
  targetType: 'Tipo de Alvo',
  description: 'Descrição',
  birthDate: 'Data de Nascimento',
  gender: 'Gênero',
  isPrimary: 'É Primário',
  prospectName: 'Nome do Prospecto',
  prospectAddress: 'Endereço do Prospecto',
  prospectContactName: 'Nome de Contato do Prospecto',
  prospectType: 'Tipo de Prospecto',
  leadValue: 'Valor do Lead',
  estimateId: 'Id do Orçamento',
  proposalId: 'Id da Proposta',
  invoiceId: 'Id da Fatura',
  paymentId: 'Id do Pagamento',
  returnId: 'Id do Retorno',
  expenseId: 'Id da Despesa',
  customerName: 'Nome do Cliente',
  customerEmail: 'E-mail do Cliente',
  customerType: 'Tipo de Cliente',
  finalPrice: 'Preço Final',
  paymentMethod: 'Método de Pagamento',
  confirmationNumber: 'Número de Confirmação',
  amoundPaid: 'Valor Pago',
  refundAmount: 'Valor do Reembolso',
  isOpen: 'Está Aberto',
  resolveReason: 'Motivo da Resolução',
  disapproveReason: 'Motivo da Desaprovação',
  sku: 'SKU',
  priceType: 'Tipo de Preço',
  price: 'Preço',
  discountedPrice: 'Preço com Desconto',
  discount: 'Desconto',
  unit: 'Unidade',
  unitPrice: 'Preço Unitário',
  natTaxRate: 'Taxa de Imposto Nacional',
  intTaxRate: 'Taxa de Imposto Internacional',
  selectRoles: 'Selecionar Funções',
  timeZone: 'Fuso Horário',
  prospect: 'Prospecto',
  prospectTargetEntityRef: 'Ref da Entidade Alvo do Prospecto',
  prospectTarget: 'Alvo do Prospecto',
  prospectVAT: 'VAT do Prospecto',
  prospectPhoneNumber: 'Número de Telefone do Prospecto',
  prospectZIP: 'CEP do Prospecto',
  contactFirstName: 'Primeiro Nome do Contato',
  contactLastName: 'Sobrenome do Contato',
  contactEmail: 'E-mail do Contato',
  expiration: 'Expiração',
  detailedInformation: 'Informações Detalhadas',
  projectOrServiceDesc: 'Descrição do Projeto/Serviço',
  scopeOfWork: 'Escopo do Trabalho',
  assumptionsAndExclusions: 'Suposições e Exclusões',
  paymentTerms: 'Termos de Pagamento',
  products: 'Produtos',
  services: 'Serviços',
  productName: 'Nome do Produto',
  productDescription: 'Descrição do Produto',
  productUnit: 'Unidade do Produto',
  quantity: 'Quantidade',
  tax: 'Imposto',
  extraInformation: 'Informações Extras',
  offers: 'Ofertas',
  reference: 'Referência',
  allowComments: 'Permitir Comentários',
  totally: 'Total',
  subTotal: 'Subtotal',
  taxTotal: 'Total de Impostos',
  extraDiscount: 'Desconto Extra',
  shippingTotal: 'Total de Envio',
  salesAgents: 'Agentes de Vendas',
  legal: 'Legal',
  useLegalNDA: 'Usar NDA Legal',
  useLegalTermAndCond: 'Usar Termos e Condições Legais',
  signature: 'Assinatura',
  signerUser: 'Usuário Assinante',
  signedDate: 'Data de Assinatura',
  generalInfo: 'Informações Gerais',
  startDate: 'Data de Início',
  dueDate: 'Data de Vencimento',
  endDate: 'Data de Término',
  expirationEmailSentDate: 'Data de Envio do E-mail de Expiração',
  allowedPaymentMethods: 'Métodos de Pagamento Permitidos',
  sendEmail: 'Enviar E-mail',
  identityNumber: 'Número de Identidade',
  recurringCount: 'Contagem Recorrente',
  isRecurring: 'É Recorrente',
  recurringFrequency: 'Frequência Recorrente',
  recurringDueDate: 'Data de Vencimento Recorrente',
  paymentID: 'ID do Pagamento',
  paymentPrefix: 'Prefixo do Pagamento',
  returnPrefix: 'Prefixo do Retorno',
  paymentDate: 'Data do Pagamento',
  amountPaid: 'Valor Pago',
  invoiceID: 'ID da Fatura',
  targetDetail: 'Detalhe do Alvo',
  productsAndServices: 'Produtos & Serviços',
  popularSearch: 'Pesquisa Popular',
  returnReason: 'Motivo do Retorno',
  partialReturn: 'Retorno Parcial',
  amount: 'Valor',
  relationship: 'Relacionamento',
  relationshipType: 'Tipo de Relacionamento',
  relatedEntity: 'Entidade Relacionada',
  relatedEstimate: 'Orçamento Relacionado',
  relatedProposal: 'Proposta Relacionada',
  relatedTask: 'Tarefa Relacionada',
  relatedProject: 'Projeto Relacionado',
  relatedLead: 'Lead Relacionado',
  relatedCustomer: 'Cliente Relacionado',
  relatedInvoice: 'Fatura Relacionada',
  relatedPayment: 'Pagamento Relacionado',
  relatedUser: 'Usuário Relacionado',
  relatedExpense: 'Despesa Relacionada',
  relatedContract: 'Contrato Relacionado',
  order: 'Pedido',

  ////////////////////////////////////////////////////////////////
  // SEARCH
  ////////////////////////////////////////////////////////////////
  leadCreate: 'Criar Lead',
  leadList: 'Lista de Leads',
  leadImport: 'Importar Leads',
  customerCreate: 'Criar Cliente',
  customerList: 'Lista de Clientes',
  customerImport: 'Importar Clientes',
  estimateCreate: 'Criar Orçamento',
  estimateList: 'Lista de Orçamentos',
  proposalCreate: 'Criar Proposta',
  proposalList: 'Lista de Propostas',
  invoiceCreate: 'Criar Fatura',
  invoiceList: 'Lista de Faturas',
  paymentCreate: 'Criar Pagamento',
  paymentList: 'Lista de Pagamentos',
  returnCreate: 'Criar Retorno',
  returnList: 'Lista de Retornos',
  contractCreate: 'Criar Contrato',
  contractList: 'Lista de Contratos',
  productCreate: 'Criar Produto',
  productList: 'Lista de Produtos',
  serviceList: 'Lista de Serviços',
  serviceCreate: 'Criar Serviço',
  expenseCreate: 'Criar Despesa',
  expenseList: 'Lista de Despesas',
  projectCreate: 'Criar Projeto',
  projectList: 'Lista de Projetos',
  taskCreate: 'Criar Tarefa',
  taskList: 'Lista de Tarefas',
  reminderList: 'Lista de Lembretes',
  reminderCreate: 'Criar Lembrete',
  appointmentList: 'Lista de Compromissos',
  appointmentCreate: 'Criar Compromisso',
  noteCreate: 'Criar Nota',
  noteList: 'Lista de Notas',
  ticketList: 'Lista de Tickets',
  ticketCreate: 'Criar Ticket',
  noResultsFound: 'Nenhum resultado encontrado',
  trySearching: 'Tente pesquisar por',
  hideButton: 'Ocultar Botão',
};

export default locale; 
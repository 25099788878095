import profileLocale from "../domains/profile/locales/de";

const locale = {
  profile: {
    ...profileLocale,
  },
  profileo: "Angebotsanfrage"
};

export default locale;

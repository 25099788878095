const locale = {
  contacts: 'Kontakte',
  title: 'Kontakte',
  createContact: 'Kontakt erstellen',
  columnId: '#',
  columnName: 'Name',
  columnIcon: 'Symbol',
  columnDepartment: 'Abteilung',
  columnParentCategory: 'Übergeordnete Kategorie',
  columnActions: 'Aktionen',
  emptyStateContent1: 'Keine Kontakte gefunden',
  emptyStateContent2: 'Bitte erstellen Sie einen neuen Kontakt',
  selectIconTitle: 'Symbol auswählen',
  searchIcons: 'Symbole suchen',
  relatedContacts: 'Verwandte Kontakte',
  b2bB2c: 'B2B / B2C',
  createContactTitle: 'Kontakt erstellen',
  contactNameLabel: 'Kontaktname',
  contactEmailLabel: 'Kontakt-E-Mail',
  contactPhoneLabel: 'Kontakttelefon',
  contactTypeLabel: 'Kontakttyp',
  contactPictureLabel: 'Kontaktbild',
  departmentLabel: 'Abteilung',
  categoryLabel: 'Kategorie',
  contactLanguageLabel: 'Kontaktsprache',
  countryLabel: 'Land',
  stateLabel: 'Bundesland',
  cityLabel: 'Stadt',
  uploadPictureText: 'Bild hochladen',
  createContactButton: 'Kontakt erstellen',
  createSuccess: 'Kontakt erfolgreich erstellt',
  delete: 'Löschen',
  deleteConfirm: 'Sind Sie sicher, dass Sie diesen Kontakt löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  deletedSuccessfully: 'Kontakt erfolgreich gelöscht',
  cancel: 'Abbrechen',
  id: '#',
  firstName: 'Vorname',
  lastName: 'Nachname',
  email: 'E-Mail',
  type: 'Typ',
  phone: 'Telefon',
  position: 'Position',
  language: 'Sprache',
  update: 'Kontakt aktualisieren',
  updateContactButton: 'Kontakt aktualisieren',
  customerId: 'Kunden auswählen',
  primary: 'Primär',
  customer: 'Kunde',
  actions: 'Aktionen',
  create: 'Kontakt erstellen',
  emptyState1: 'Keine Kontakte gefunden.',
  emptyState2: 'Sie können sofort Kontakte erstellen oder importieren.',
  deletePrimaryError: 'Primärkontakt kann nicht gelöscht werden.',
  updateSuccess: 'Kontakt erfolgreich aktualisiert',
};

export default locale; 
const locale = {
  expenses: 'Spese',
  expense: 'Spesa',
  expenseConversions: 'Conversioni di Spesa',
  title: 'Spese',
  create: 'Crea Spesa',
  delete: 'Elimina Spesa',
  update: 'Aggiorna Spesa',
  deletedSuccessfully: 'Spesa eliminata con successo.',
  createSuccess: 'Spesa creata con successo.',
  updateSuccess: 'Spesa aggiornata con successo.',
  deleteConfirm: 'Sei sicuro di voler eliminare questa spesa? Questa azione non può essere annullata.',
  emptyState1: 'Nessuna spesa aggiunta finora.',
  emptyState2:
    'Memorizza e gestisci qui tutti i dettagli delle spese per semplificare la fatturazione e mantenere accordi chiari con i tuoi clienti.',
  downloadFormSuccessfully: 'Lista delle spese scaricata con successo.',
  importExpense: 'Importa Spese',
  importTitle: 'Importa Spese',
  importText1: 'Puoi importare i tuoi dati delle spese da un file Excel.',
  importText2: 'Clicca sul pulsante Scarica Esempio per scaricare il modello.',
  // labels
  id: "ID Spesa",
  totalAmount: "Importo Totale",
  totalTax: "Imposta Totale",
  isBillable: "È Fatturabile",
  isInvoiced: "È Fatturato",

  // detailDrawer
  changeStatus: 'Cambia Stato',
  generalInfoTitle: 'Informazioni Generali',
  customerInfoTitle: 'Informazioni Cliente',
  expenseInfoTitle: 'Informazioni sulla Spesa',
  projectInfoTitle: 'Informazioni sul Progetto',
  projectName: 'Nome Progetto',
  paymentMethod: 'Metodo di Pagamento',
};

export default locale; 
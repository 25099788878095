const locale = {
  demo: "Demo",
  title: "¿Qué es la Ventana <highlight>CoCRM</highlight>?",
  description:
    "La Ventana CoCRM es un área de desarrollo empresarial diseñada exclusivamente para empresas usuarias de CoCRM, donde pueden proporcionar transferencias directamente a miembros dentro del ecosistema CoCRM con respecto a sus productos, servicios o campañas, y capturar oportunidades de negocio definiendo descuentos especiales para individuos e instituciones que visitan nuestro sitio web.",
  featuresTitle: "Con la Ventana CoCRM;",
  features1:
    "Puede hacer ofertas directamente a los visitantes de nuestro sitio web con más de 1 millón de tráfico mensual,",
  features2:
    "Puede hacer varios anuncios de campañas a empresas dentro de nuestro ecosistema,",
  features3:
    "Puede proporcionar información detallada sobre sus productos y servicios participando en las campañas de blog que realizaremos,",
  features4:
    "¡Puede hacer una contribución adicional del 36,2% a su reconocimiento de marca!",
  buttonTitle: "¡Solicitar presupuesto!",
  formDescription:
    "Complete el formulario y nos pondremos en contacto con usted lo antes posible.",
  sendSuccess: "¡Gracias! Nos pondremos en contacto con usted lo antes posible.",
  formTitle: "Solicitar presupuesto",
  formButtonTitle: "Enviar",
  fullName: "Nombre completo",
  email: "Correo electrónico",
  phone: "Teléfono",
  orgName: "Nombre de la organización",
};

export default locale; 
const locale = {
  title: "Departamentos",
  delete: "Eliminar",
  deleteConfirm: "¿Está seguro de que desea eliminar este departamento? Esta acción no se puede deshacer.",
  deletedSuccessfully: "Departamento eliminado con éxito",
  create: "Crear Departamento",
  departmentNameLabel: "Nombre del Departamento",
  rolesLabel: "Roles",
  addRole: "Añadir Rol",
  createDepartmentButton: "Crear Departamento",
  createSuccess: "Departamento creado con éxito",
  columnId: "#",
  columnName: "Nombre",
  columnRoles: "Roles",
  columnActions: "Acciones",
  headerTitle: "Departamentos",
  createDepartment: "Crear Departamento",
  rolesButton: "Roles",
  emptyState1: "No se encontraron departamentos",
  emptyState2: "Por favor, cree un nuevo departamento",
  addNewRole: "Añadir Nuevo Rol",
  updateDepartmentTitle: "Actualizar Departamento",
  updateDepartmentButton: "Actualizar",
  updateSuccess: "Departamento actualizado con éxito",
};

export default locale; 
const locale = {
  readAllSuccess: 'Все уведомления успешно прочитаны!',
  title: 'Уведомления',
  markAllAsRead: 'Отметить все как прочитанные.',
  close: 'Закрыть',
  noNotifications1: "Нет уведомлений",
  noNotifications2: "Вы еще не получили ни одного уведомления.",
  refresh: 'Обновить',
};

export default locale; 
const locale = {
  reports: "Отчеты",
  report: "Отчет",
  reportConversions: "Конверсии отчетов",
  title: "Отчеты",
  addReport: "Добавить отчет",
  emptyStateDesc1: "Пока нет доступных отчетов.",
  emptyStateDesc2: "Создавайте и просматривайте здесь подробную аналитику для отслеживания ваших CRM-активностей и принятия решений на основе данных."
};

export default locale; 
import settingsLocale from "../domains/settings/locales/fr";

const locale = {
  settings: {
    ...settingsLocale,
  },
  demoo: "Demande de Devis",
  settingsLabel: "Paramètres",
  paymentSettings: "Paiement",
  menuSettings: "Paramètres du Menu",
  crmSettings: "CRM",
  leadPlusSettings: "Lead Plus",
  marketingSettings: "Marketing",
  windowSettings: "Fenêtre",
  communitySettings: "Communauté",
  notificationSettings: "Notification",
  organizationSettings: "Organisation",
  myOrganization: "Mon Organisation",
  generalSettings: "Général",
  moduleSettings: "Paramètres des Modules",
  userSettings: "Paramètres Utilisateur",
  integrations: "Intégrations",
  legalAgreements: "Accords Juridiques",
};

export default locale; 
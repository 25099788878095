const locale = {
  appointments: 'Rendez-vous',
  appointment: 'Rendez-vous',
  appointmentConversions: 'Conversions de rendez-vous',
  title: 'Rendez-vous',
  create: 'Créer un rendez-vous',
  update: 'Mettre à jour le rendez-vous',
  delete: 'Supprimer le rendez-vous',
  details: 'Détails du rendez-vous',
  createSuccess: 'Rendez-vous créé avec succès.',
  updateSuccess: 'Rendez-vous mis à jour avec succès.',
  emptyState1: 'Aucun rendez-vous programmé pour le moment.',
  emptyState2:
    'Configurez et gérez vos rendez-vous ici pour rester organisé et vous connecter avec vos clients en toute transparence.',
  statusChangeSuccess: 'Statut du rendez-vous modifié avec succès.',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce rendez-vous?',
  deletedSuccessfully: 'Rendez-vous supprimé avec succès.',

  //fields
  startTime: 'Heure de début',
  endTime: 'Heure de fin',
  timezone: 'Fuseau horaire',
  location: 'Lieu',
  organizer: 'Organisateur',
  targetType: 'Type de cible',
  attendees: 'Participants',
  category: 'Catégorie',
  meetingLink: 'Lien de réunion',
  duration: 'Durée',

  //detail drawer
  changeStatus: 'Changer le statut',
  generalInfoTitle: 'Informations générales',
  organizerInfoTitle: 'Informations sur l\'organisateur',
  appointmentInfoTitle: 'Informations sur le rendez-vous',
};

export default locale; 
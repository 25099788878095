const locale = {
  projects: 'Projetos',
  project: 'Projeto',
  projectConversions: 'Conversões de Projeto',
  title: 'Projetos',
  create: 'Criar Projeto',
  update: 'Atualizar Projeto',
  delete: 'Excluir Projeto',
  createSuccess: 'Projeto criado com sucesso.',
  updateSuccess: 'Projeto atualizado com sucesso.',
  deleteSuccess: 'Projeto excluído com sucesso.',
  deleteConfirm: 'Tem certeza de que deseja excluir este projeto? Esta ação não pode ser desfeita.',
  emptyState1: 'Nenhum projeto iniciado ainda.',
  emptyState2:
    'Crie e gerencie seus projetos aqui para acompanhar o progresso, atribuir tarefas e colaborar com sua equipe.',
  progressCalculationType: 'Cálculo de Progresso',
  progressPercentage: 'Progresso %',
  isBillable: 'Faturável',
  billingType: 'Tipo de Faturamento',
  billingRatePerHour: 'Taxa por Hora',
  members: 'Membros',
  startDate: 'Data de Início',
  deadline: 'Prazo Final',
  estimatedHours: 'Horas Estimadas',
  // labels
  welcome: 'Bem-vindo',
  description: "Seu progresso no projeto esta semana é incrível. Vamos continuar.",

  // detail
  changeStatus: 'Alterar Status',
  generalInfoTitle: 'Informações Gerais',
  customerInfoTitle: 'Informações do Cliente',
  contactPhoneNumber: 'Número de Telefone de Contato',
  membersTitle: 'Membros',
  milestone: 'Marco',
  timeSheet: 'Planilha de Horas',
  projectInfoTitle: 'Informações do Projeto',
  billingInfoTitle: 'Informações de Faturamento',
  statusChangeSuccess: 'Status do projeto alterado com sucesso.',
  detail: 'Detalhe do Projeto',
  noMember: 'Nenhum membro ainda.',

  // timeSheet
  noAssigneeToCreateTimeSheet: 'Atribua usuários ao projeto para criar planilhas de horas',
  emptyStateTimeSheetList1: 'Ainda não há planilha de horas.',
  addTimeSheet: 'Adicionar Planilha de Horas',
  timeSheetCreate: 'Criar Planilha de Horas',
  timeSheetUpdate: 'Atualizar Planilha de Horas',
  member: 'Membro',
  startTime: 'Hora de Início',
  endTime: 'Hora de Término',
  duration: 'Duração',
  timeSheetCreateSuccess: 'Planilha de horas criada com sucesso.',
  timeSheetUpdateSuccess: 'Planilha de horas atualizada com sucesso.',
  timeSheetDeleteSuccess: 'Planilha de horas excluída com sucesso.',

  // milestone
  emptyStateMilestoneList1: 'Ainda não há marco.',
  addMilestone: 'Adicionar Marco',
  milestoneCreate: 'Criar Marco',
  milestoneUpdate: 'Atualizar Marco',
  milestoneCreateSuccess: 'Marco criado com sucesso.',
  milestoneUpdateSuccess: 'Marco atualizado com sucesso.',
  milestoneDeleteSuccess: 'Marco excluído com sucesso.',
  addTask: 'Adicionar Tarefa',
};

export default locale; 
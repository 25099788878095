const locale = {
  title: 'Dashboard',
  description:
    'Attiva il tuo abbonamento Passa a un piano di abbonamento adatto per utilizzare tutte le funzionalità del sistema CRM e far crescere la tua attività',
  featuresTitle: 'Con abbonamento:',
  features1: 'Accesso illimitato a tutti i tuoi dati',
  features2: 'Strumenti di reportistica personalizzati',
  features3: 'Più spazio di archiviazione',
  features4: 'Supporto tecnico 24/7',
  buttonTitle: 'Esplora i piani di abbonamento',
  trialExpired: 'Il piano di prova è scaduto. Si prega di scegliere un altro piano.',
  mainFailed: 'Il tuo piano non è attivato. Si prega di controllare le informazioni di fatturazione.',
  mainCompleted: 'Il tuo piano è completato. Si prega di scegliere un altro piano.',
  welcome: 'Benvenuto ',
  platformValue: 'Valore della piattaforma',
  dealsAmount: 'Importo degli affari',
  deals: 'Affari',
  byRefererCategory: 'per categoria di referente',
  revenue: 'Ricavi',
  leads: 'Lead',
  wl: 'V/P',
  winLoss: 'Vittoria/Perdita',
  averageMonthly: 'Media mensile',
  monthly: 'Mensile',
  bestDeal: 'Miglior affare',
  sales: 'Vendite',
  topSales: 'Migliori vendite',
  salesStreak: 'Serie di vendite',
  topReview: 'Migliore recensione',
  workWithPlatforms: 'Lavoro con piattaforme',
  salesDynamics: 'Dinamica delle vendite',
  timeframe: 'Periodo di tempo',
  bestDetal: 'Miglior affare',
  winRate: 'Tasso di successo',
  prevMonth: 'mese precedente',
  vsPrevMonth: 'vs mese precedente',
  details: 'Dettagli',
  filters: 'Filtri',
  statistics: 'Statistiche',

  //LEAD
  totalLead: 'Lead totali',
  totalLeadSubtitle: 'Lead totali con dati storici giornalieri',
  today: 'Oggi',
  yesterday: 'Ieri',
  leadConversion: 'Conversione lead',
  leadConversionSubtitle: 'Conversione lead con dati storici giornalieri',
  weeklyReport: 'Report settimanale',

  //CUSTOMER
  totalCustomer: 'Clienti totali',
  totalCustomerSubtitle: 'Clienti totali con dati storici giornalieri',
  customerFilterType: 'Filtro clienti per tipo',
  customerFilterCategory: 'Filtro clienti per categoria',

  //ESTIMATE
  totalEstimate: 'Preventivi totali',
  totalEstimateSubtitle: 'Preventivi totali con dati storici giornalieri',
  estimateTotalPrice: 'Prezzo totale preventivi',
  estimateTotalPriceSubtitle: 'Prezzo totale preventivi con dati storici giornalieri',
  estimatesByStatus: 'Preventivi per stato',
  estimatesByTypes: 'Preventivi per tipo',
  estimatesByTarget: 'Preventivi per obiettivo',

  //PROPOSAL
  totalProposal: 'Proposte totali',
  totalProposalSubtitle: 'Proposte totali con dati storici giornalieri',
  proposalTotalPrice: 'Prezzo totale proposte',
  proposalTotalPriceSubtitle: 'Prezzo totale proposte con dati storici giornalieri',
  proposalsByStatus: 'Proposte per stato',
  proposalsByTypes: 'Proposte per tipo',
  proposalsByTarget: 'Proposte per obiettivo',

  //TASK
  totalTask: 'Attività totali',
  totalTaskSubtitle: 'Attività totali con dati storici giornalieri',
  taskFilterPriority: 'Filtro attività per priorità',
  taskFilterStatus: 'Filtro attività per stato',
};

export default locale; 
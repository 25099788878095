const locale = {
  contacts: 'Contactos',
  title: 'Contactos',
  createContact: 'Crear Contacto',
  columnId: '#',
  columnName: 'Nombre',
  columnIcon: 'Icono',
  columnDepartment: 'Departamento',
  columnParentCategory: 'Categoría Principal',
  columnActions: 'Acciones',
  emptyStateContent1: 'No se encontraron contactos',
  emptyStateContent2: 'Por favor, cree un nuevo contacto',
  selectIconTitle: 'Seleccionar un Icono',
  searchIcons: 'Buscar Iconos',
  relatedContacts: 'Contactos Relacionados',
  b2bB2c: 'B2B / B2C',
  createContactTitle: 'Crear Contacto',
  contactNameLabel: 'Nombre del Contacto',
  contactEmailLabel: 'Correo del Contacto',
  contactPhoneLabel: 'Teléfono del Contacto',
  contactTypeLabel: 'Tipo de Contacto',
  contactPictureLabel: 'Imagen del Contacto',
  departmentLabel: 'Departamento',
  categoryLabel: 'Categoría',
  contactLanguageLabel: 'Idioma del Contacto',
  countryLabel: 'País',
  stateLabel: 'Estado',
  cityLabel: 'Ciudad',
  uploadPictureText: 'Subir una imagen',
  createContactButton: 'Crear Contacto',
  createSuccess: 'Contacto creado con éxito',
  delete: 'Eliminar',
  deleteConfirm: '¿Está seguro de que desea eliminar este contacto? Esta acción no se puede deshacer.',
  deletedSuccessfully: 'Contacto eliminado con éxito',
  cancel: 'Cancelar',
  id: '#',
  firstName: 'Nombre',
  lastName: 'Apellido',
  email: 'Correo',
  type: 'Tipo',
  phone: 'Teléfono',
  position: 'Posición',
  language: 'Idioma',
  update: 'Actualizar Contacto',
  updateContactButton: 'Actualizar Contacto',
  customerId: 'Seleccionar un Cliente',
  primary: 'Principal',
  customer: 'Cliente',
  actions: 'Acciones',
  create: 'Crear Contacto',
  emptyState1: 'No se encontraron contactos aquí.',
  emptyState2: 'Puede crear o importar contactos inmediatamente.',
  deletePrimaryError: 'El contacto principal no puede ser eliminado.',
  updateSuccess: 'Contacto actualizado con éxito',
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button, Menu, MenuItem, Typography } from '@mui/material';
import { RefreshRounded } from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EstimateStatus, IEstimateDetailResult, PermissionSubject } from 'corede-common-cocrm';
import React from 'react';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { useEstimateStatusUpdateMutation } from '../../context/estimate.api';
import { enqueueSnackbar } from 'notistack';
import { IBaseError, PermissionAction } from 'corede-common';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';

export interface IEstimateDetailDrawerLeftPanelActionsComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * contains actions: add appointment, convert estimate to customer
 */
export const EstimateDetailDrawerLeftPanelActionsComponent = (
  props: IEstimateDetailDrawerLeftPanelActionsComponentProps,
) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const [
    estimateStatusUpdate,
    { data: estimateStatusUpdateData, isLoading: estimateStatusUpdateLoading, error: estimateStatusUpdateError },
  ] = useEstimateStatusUpdateMutation();

  const handleChangeStatus = (status: EstimateStatus) => {
    estimateStatusUpdate({
      filter: {
        _id: props.estimateDetailData?._id ?? '',
      },
      input: {
        status: status,
      },
    });
  };

  useEffect(() => {
    if (estimateStatusUpdateData) {
      enqueueSnackbar(t('crm.preSale.estimate.statusUpdatedSuccessfully'), {
        variant: 'success',
      });
    }
  }, [estimateStatusUpdateData]);

  useEffect(() => {
    if (estimateStatusUpdateError) {
      DefaultErrorHandlerUseEffect(estimateStatusUpdateError as IBaseError, currentLanguage);
    }
  }, [estimateStatusUpdateError]);

  const StatusMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (  
      <div>
        <PermissionWrapper
          check={{
            subject: PermissionSubject.estimate,
            action: PermissionAction.update,
          }}
        >
          <Button
            id="estimate-detail-button"
            aria-controls={open ? 'estimate-detail-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            color="primary"
            variant="contained"
            disabled={estimateStatusUpdateLoading}
            sx={{
              height: 40,
              fontSize: '13px',
              borderRadius: 100,
            }}
          >
            <RefreshRounded sx={{ mr: 1 }} />
            {t('crm.preSale.estimate.changeStatus')}
          </Button>
          <Menu
            id="estimate-detail-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'estimate-detail-button',
            }}
          >
            {Object.entries(EstimateStatus).map(([key, value]) => (
              <MenuItem key={key} onClick={() => handleChangeStatus(value)}>
                <Typography fontSize={'small'} fontWeight={'bold'}>
                  {getTranslatedEnumValue(value)}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </PermissionWrapper>
      </div>
    );
  };
  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      {/* Estimate base actions */}
      <StatusMenu />
    </Stack>
  );
};

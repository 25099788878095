const locale = {
  readAllSuccess: 'Todas as notificações lidas com sucesso!',
  title: 'Notificações',
  markAllAsRead: 'Marcar todas como lidas.',
  close: 'Fechar',
  noNotifications1: "Sem notificações",
  noNotifications2: "Você ainda não recebeu nenhuma notificação.",
  refresh: 'Atualizar',
};

export default locale; 
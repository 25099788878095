const locale = {
  organization: 'Organisation',
  organizations: 'Organisations',
  organizationChart: 'Organigramme',
  update: 'Mettre à jour',
  delete: 'Supprimer',
  view: 'Voir',
  createdSuccessfully: 'Utilisateur créé avec succès',
  updatedSuccessfully: 'Utilisateur mis à jour avec succès',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer le compte utilisateur "',
  deletedSuccessfully: 'Utilisateur supprimé avec succès',
  info: 'Vous pouvez ajouter des utilisateurs à votre équipe à partir de cette section.',
  name: 'Nom',
  email: 'Email',
  surname: 'Prénom',
  viewProfile: 'Voir le profil',
  roles: 'Rôles',
  editChart: 'Modifier l\'organigramme',
  save: 'Enregistrer',
  editOrganizationChart: 'Modifier l\'organigramme',
  editOrganizationDetail: 'Faites glisser et déposez les utilisateurs à l\'emplacement souhaité.',
  createRole: 'Créer un rôle',
  userList: 'Liste des utilisateurs',
  rolesInfo: 'Vous pouvez créer des rôles et leur attribuer des autorisations.',
  createUserSuccess: 'Utilisateur créé avec succès',
  chart: 'Vue organigramme',
  list: 'Vue liste',
  users: 'Utilisateurs',
  user: 'Utilisateur',
  activeUsers: 'Utilisateurs actifs',
  activeUser: 'Utilisateur actif',
  create: 'Créer',
  new: 'Nouveau',
  roleName: 'Nom du rôle',
  addRole: 'Ajouter',
  updateRole: 'Mettre à jour',
  deleteRole: 'Supprimer',
  roleCreatedSuccess: 'Rôle créé avec succès',
  roleUpdatedSuccess: 'Rôle mis à jour avec succès',
  roleDeletedSuccess: 'Rôle supprimé avec succès',
  errorFetchingPermissions: 'Erreur lors de la récupération des autorisations',
  errorFetchingRoles: 'Erreur lors de la récupération des rôles',
  errorCreatingRole: 'Erreur lors de la création du rôle',
  errorUpdatingRole: 'Erreur lors de la mise à jour du rôle',
  errorDeletingRole: 'Erreur lors de la suppression du rôle',
  permissionUpdateSuccess: 'Les autorisations de l\'utilisateur ont été mises à jour avec succès',
  subject: 'Sujet',
  capabilities: 'Capacités',
  allPermissions: 'Toutes les autorisations',
  all: 'Tous',
  advancedOptions: 'Options avancées',
  id: 'ID',
  emailAddress: 'Adresse email',
  status: 'Statut',
  emailVerified: 'Email vérifié',
  phoneNumber: 'Numéro de téléphone',
  country: 'Pays',
  city: 'Ville',
  phone: 'Numéro de téléphone',
  lastLoginDate: 'Dernière date de connexion',
  createdAt: 'Créé le',
  actions: 'Actions',
  addNewRole: 'Ajouter un nouveau rôle',
  yes: 'Oui',
  no: 'Non',
  details: 'Détails',
  jobTitle: 'Titre du poste',
  deleteMessage: 'Êtes-vous sûr de vouloir supprimer cet utilisateur?',
  cancel: 'Annuler',
  userDeleteSuccess: 'Utilisateur supprimé avec succès',
  userDeleteError: 'Échec de la suppression de l\'utilisateur',
  updateChartSuccessfully: 'Organigramme mis à jour avec succès',
  address: 'Adresse',
  birthDate: 'Date de naissance',
  gender: 'Genre',
  language: 'Langue',
  description: 'Description',
  rolesAndPermissions: 'Rôles et autorisations',
  ownPermissionsAlert: 'Vous ne pouvez pas modifier vos propres autorisations.',
  zoom: 'Zoom',
  tree: 'Vue arborescente',
  createSuccess: 'Organigramme créé avec succès',
  updateSuccess: 'Organigramme mis à jour avec succès',
  updateError: 'Erreur lors de la mise à jour de l\'organigramme',
  fetchError: 'Erreur lors de la récupération de l\'organigramme',
  fetchSuccess: 'Organigramme récupéré avec succès',
  undefinedUser: 'Utilisateur non défini',
  chartUpdateSuccess: 'Organigramme mis à jour avec succès!',
  listView: 'Vue liste',
  action: 'Action',
  actionScope: 'Portée de l\'action',
  userDetail: 'Détail de l\'utilisateur',
  admin: 'Administrateur',
  department: 'Département',
  addUser: 'Ajouter un utilisateur',
  createDepartment: 'Créer un département',
  updateUser: 'Mettre à jour l\'utilisateur',
  notDeleteOwnConfirm: 'Vous ne pouvez pas vous supprimer vous-même.',
  updateUserSuccess: 'Utilisateur mis à jour avec succès',
  removeAccount: 'Supprimer le compte',
  profile: 'Profil',
  permissions: 'Autorisations',
  associatedLeads: 'Prospects associés',
  associatedCustomers: 'Clients associés',
  associatedEstimates: 'Devis associés',
  associatedProposals: 'Propositions associées',
  associatedInvoices: 'Factures associées',
  associatedTasks: 'Tâches associées',
  about: 'À propos',
  detail: 'Détail de l\'utilisateur',
  users_: 'utilisateurs',
  resendConfirmation: 'Renvoyer la confirmation',
  resendSuccess: 'Confirmation envoyée avec succès',
};

export default locale; 
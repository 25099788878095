const locale = {
  estimate: 'Preventivo',
  title: 'Preventivi',
  create: 'Crea Preventivo',
  update: 'Aggiorna Preventivo',
  delete: 'Elimina',
  view: 'Visualizza Preventivo',
  list: 'Elenco Preventivi',
  detail: 'Dettaglio Preventivo',
  createdSuccessfully: 'Preventivo creato con successo',
  updatedSuccessfully: 'Preventivo aggiornato con successo',
  deleteConfirm: 'Sei sicuro di voler eliminare? Questa azione non può essere annullata.',
  deletedSuccessfully: 'Preventivo eliminato con successo',
  createInfo: 'Puoi creare nuovi preventivi qui.',
  prefix: 'Prefisso',
  timeZone: 'Fuso Orario',
  prospectTargetEntityId: 'Cliente Potenziale',
  prospectTargetEntityRef: 'Rif. Cliente Potenziale',
  prospectType: 'Tipo di Cliente Potenziale',
  prospectName: 'Nome Cliente Potenziale',
  prospectVAT: 'Partita IVA Cliente Potenziale',
  prospectPhoneNumber: 'Telefono Cliente Potenziale',
  prospectCountry: 'Paese Cliente Potenziale',
  prospectState: 'Regione Cliente Potenziale',
  prospectCity: 'Città Cliente Potenziale',
  prospectZIP: 'CAP Cliente Potenziale',
  prospectAddress: 'Indirizzo Cliente Potenziale',
  prospectContactFirstName: 'Nome Contatto Cliente Potenziale',
  prospectContactLastName: 'Cognome Contatto Cliente Potenziale',
  prospectContactEmail: 'Email Contatto Cliente Potenziale',
  language: 'Lingua',
  date: 'Data',
  expireDate: 'Data di Scadenza',
  sendEmailOnExpiration: 'Invia Email',
  expirationEmailSentDate: 'Data Invio Email di Scadenza',
  projectOrServiceDesc: 'Descrizione Progetto o Servizio',
  paymentTerms: 'Termini di Pagamento',
  products: 'Prodotti',
  addProducts: 'Aggiungi Prodotti',
  offers: 'Offerte',
  salesAgentIds: 'Agenti di Vendita',
  allowComments: 'Consenti Commenti',
  legalNDAId: 'NDA',
  legalTermAndCondId: 'Termini e Condizioni',
  signerUserId: 'Utente Firmatario',
  listInfo: 'Elenco di tutti i preventivi.',
  importedError: 'Errore di importazione',
  importedSuccessfully: 'Importato con successo',
  downloadFormError: 'Errore nel download del modulo',
  downloadFormSuccessfully: 'Modulo scaricato con successo',
  downloadExampleFormSuccessfully: 'Modulo di esempio scaricato con successo',
  downloadExampleFormError: 'Errore nel download del modulo di esempio',
  emptyState1: 'Nessun preventivo trovato.',
  emptyState2: 'Puoi creare o importare preventivi immediatamente.',
  createEstimate: 'Crea Preventivo',
  importEstimate: 'Importa Preventivo',
  importTitle: 'Importa Preventivi',
  downloadExample: 'Scarica Esempio',
  importText1: 'Puoi importare preventivi da questa pagina.',
  importText2: 'Puoi utilizzare il modulo di esempio qui sotto per pubblicare i tuoi preventivi.',
  importExport: 'Importa/Esporta',
  exportData: 'Esporta Dati',
  importData: 'Importa Dati',
  b2b: 'B2B',
  b2c: 'B2C',
  viewEstimate: 'Visualizza',
  edit: 'Modifica',
  junk: 'Cestina',
  unJunk: 'Recupera',
  addNewRole: 'Aggiungi Nuovo Ruolo',
  actions: 'Azioni',
  legalAgreements: 'Accordi Legali',
  businessName: 'Nome Azienda',
  governmentAgency: 'Ente Governativo',
  fullName: 'Nome Completo',
  VAT: 'Partita IVA',
  phoneNumber: 'Numero di Telefono',
  country: 'Paese',
  state: 'Regione',
  city: 'Città',
  ZIP: 'CAP',
  sentDate: 'Data di Invio',
  name: 'Nome',
  description: 'Descrizione',
  productUnit: 'Unità',
  priceType: 'Tipo di Prezzo',
  service: 'Servizio',
  product: 'Prodotto',
  services: 'Servizi',
  references: 'Riferimenti',
  add: 'Aggiungi',
  salesAgent: 'Agente di Vendita',
  estimateCreateSuccess: 'Preventivo creato con successo',

  addEstimate: 'Aggiungi Preventivo',
  noEstimateContent1: 'Nessun preventivo trovato',
  noEstimate: 'Non ci sono preventivi',
  generalInfo: 'Informazioni Generali',
  sentSuccessfully: 'Preventivo inviato con successo',
  send: 'Invia Preventivo',
  resend: 'Reinvia Preventivo',
  sendSureTitle: 'Invia Preventivo',
  sendSureDesc: 'Sei sicuro di voler inviare questo preventivo?',
  changeStatus: 'Cambia Stato',
  statusUpdatedSuccessfully: 'Stato aggiornato con successo',

  // generalInfo
  generalInfoTitle: 'Informazioni Generali',
  estimateId: 'Preventivo',
  status: 'Stato',
  createdAt: 'Creato il',
  reference: 'Riferimento',
  createdBy: 'Creato da',
  updatedAt: 'Aggiornato il',
  notAvailable: 'Non Disponibile',
  updateStatus: 'Aggiorna Stato',

  // customerInfo
  customerInfoTitle: 'Informazioni Cliente',
  contactFirstName: 'Nome',
  contactLastName: 'Cognome',
  contactEmail: 'Email',
  contactPhoneNumber: 'Numero di Telefono',
  address: 'Indirizzo',

  //productInfo
  price: 'Prezzo',
  productInfoTitle: 'Dettagli Prodotto',
  productNameUnknown: 'Prodotto Sconosciuto',
  discountedPrice: 'Prezzo Scontato',
  quantity: 'Quantità',
  finalPrice: 'Prezzo Finale',
  tax: 'Aliquota IVA',
  noProducts: 'Nessun prodotto disponibile.',

  //legalInfo
  legalInfoTitle: 'Informazioni Legali',
  ndaTitle: 'Accordo di Riservatezza (NDA)',
  termsTitle: 'Termini e Condizioni',
  documentName: 'Nome Documento',
  documentDescription: 'Descrizione',
  documentContent: 'Contenuto',

  //financialInfo
  financialInfoTitle: 'Informazioni Finanziarie',
  currency: 'Valuta',
  subTotal: 'Subtotale',
  discount: 'Sconto',
  taxTotal: 'Totale IVA',
  shippingTotal: 'Totale Spedizione',
  total: 'Prezzo Totale',
  noFinancialData: 'Nessun dato finanziario disponibile.',

  // documentInfo
  documentsTitle: 'Documenti',
  documentNameUnknown: 'Documento Sconosciuto',
  mimeType: 'Tipo di File',
  download: 'Scarica',
  noDocuments: 'Nessun documento disponibile.',

  // salesAgents
  salesAgentsTitle: 'Agenti di Vendita',
  unknownAgent: 'Agente Sconosciuto',
  noSalesAgents: 'Nessun agente di vendita disponibile.',

  //signature
  signatureTitle: 'Informazioni Firma',
  additionalInfoTitle: 'Informazioni Aggiuntive',
  signature: 'Firma',
  signerUser: 'Utente Firmatario',

  //additionalInfo
  scopeOfWork: 'Ambito di Lavoro',
  assumptionsAndExclusions: 'Assunzioni ed Esclusioni',
  useLegalNDA: 'Usa NDA Legale',
  useLegalTermAndCond: 'Usa Termini e Cond.',
  yes: 'Sì',
  no: 'No',

  //tabs
  generalInformation: 'Informazioni Generali*',
  prospect: 'Cliente Potenziale*',
  detailedInformation: 'Informazioni Dettagliate',
  productsAndServices: 'Prodotti e Servizi*',
  salesAgents: 'Agenti di Vendita',
  legal: 'Legale',
  signatureTab: 'Firma*',
};

export default locale; 
const locale = {
  proposal: 'Proposta',
  title: 'Propostas',
  proposals: 'Propostas',
  create: 'Criar Proposta',
  update: 'Atualizar Proposta',
  delete: 'Excluir',
  view: 'Visualizar Proposta',
  list: 'Listar Propostas',
  createdSuccessfully: 'Proposta criada com sucesso',
  updatedSuccessfully: 'Proposta atualizada com sucesso',
  deleteConfirm: 'Tem certeza que deseja excluir? Esta ação não pode ser desfeita.',
  deletedSuccessfully: 'Proposta excluída com sucesso',
  createInfo: 'Você pode criar novas propostas aqui.',
  prefix: 'Prefixo',
  proposalId: 'Proposta',
  timeZone: 'Fuso Horário',
  prospectTargetEntityId: 'Alvo do Prospecto',
  prospectTargetEntityRef: 'Referência do Alvo do Prospecto',
  prospectType: 'Tipo de Prospecto',
  prospectName: 'Nome do Prospecto',
  prospectVAT: 'VAT do Prospecto',
  prospectPhoneNumber: 'Telefone do Prospecto',
  prospectCountry: 'País do Prospecto',
  prospectState: 'Estado do Prospecto',
  prospectCity: 'Cidade do Prospecto',
  prospectZIP: 'CEP do Prospecto',
  prospectAddress: 'Endereço do Prospecto',
  prospectContactFirstName: 'Nome do Contato do Prospecto',
  prospectContactLastName: 'Sobrenome do Contato do Prospecto',
  prospectContactEmail: 'Email do Contato do Prospecto',
  language: 'Idioma',
  date: 'Data',
  expireDate: 'Data de Expiração',
  sendEmailOnExpiration: 'Enviar Email na Expiração',
  expirationEmailSentDate: 'Data de Envio do Email de Expiração',
  projectOrServiceDesc: 'Descrição do Projeto ou Serviço',
  scopeOfWork: 'Escopo do Trabalho',
  assumptionsAndExclusions: 'Suposições e Exclusões',
  paymentTerms: 'Condições de Pagamento',
  currency: 'Moeda',
  products: 'Produtos',
  addProducts: 'Adicionar Produtos',
  offers: 'Ofertas',
  reference: 'Referência',
  salesAgentIds: 'Agentes de Vendas',
  allowComments: 'Permitir Comentários',
  useLegalNDA: 'Usar NDA',
  legalNDAId: 'NDA Legal',
  useLegalTermAndCond: 'Usar Termos e Condições',
  legalTermAndCondId: 'Termos e Condições Legais',
  signerUserId: 'Usuário Assinante',
  listInfo: 'Lista de todas as propostas.',
  importedError: 'Erro importado',
  importedSuccessfully: 'Importado com sucesso',
  downloadFormError: 'Erro ao baixar formulário',
  downloadFormSuccessfully: 'Formulário baixado com sucesso',
  downloadExampleFormSuccessfully: 'Formulário de exemplo baixado com sucesso',
  downloadExampleFormError: 'Erro ao baixar formulário de exemplo',
  emptyState1: 'Nenhuma proposta encontrada.',
  emptyState2: 'Você pode criar ou importar propostas imediatamente.',
  createProposal: 'Criar Proposta',
  importProposal: 'Importar Proposta',
  importTitle: 'Importar Propostas',
  downloadExample: 'Baixar Exemplo',
  importText1: 'Você pode importar propostas a partir desta página.',
  importText2: 'Você pode usar o formulário de exemplo abaixo para publicar suas propostas.',
  importExport: 'Importar/Exportar',
  exportData: 'Exportar Dados',
  importData: 'Importar Dados',
  b2b: 'B2B',
  b2c: 'B2C',
  viewProposal: 'Visualizar',
  edit: 'Editar',
  junk: 'Lixo',
  unJunk: 'Não é Lixo',
  addNewRole: 'Adicionar Novo Papel',
  actions: 'Ações',
  businessName: 'Nome da Empresa',
  governmentAgency: 'Agência Governamental',
  fullName: 'Nome Completo',
  VAT: 'VAT',
  phoneNumber: 'Número de Telefone',
  country: 'País',
  state: 'Estado',
  city: 'Cidade',
  ZIP: 'CEP',
  address: 'Endereço',
  contactFirstName: 'Nome do Contato',
  contactLastName: 'Sobrenome do Contato',
  contactEmail: 'Email do Contato',
  sentDate: 'Data de Envio',
  name: 'Nome',
  description: 'Descrição',
  productUnit: 'Unidade',
  quantity: 'Quantidade',
  price: 'Preço',
  priceType: 'Tipo de Preço',
  discountedPrice: 'Preço com Desconto',
  finalPrice: 'Preço Final',
  tax: 'Imposto',
  service: 'Serviço',
  subTotal: 'Subtotal',
  taxTotal: 'Total de Impostos',
  discount: 'Desconto',
  product: 'Produto',
  services: 'Serviços',
  references: 'Referências',
  add: 'Adicionar',
  salesAgent: 'Agente de Vendas',
  executiveSummary: 'Resumo Executivo',
  companyBackground: 'Histórico da Empresa',
  projectorServiceDesc: 'Descrição Detalhada do Projeto/Serviço',
  costBreakdown: 'Detalhamento de Custos',
  projectTimeline: 'Cronograma do Projeto',
  testimonialCaseStudies: 'Depoimentos e Estudos de Caso',
  conclusion: 'Conclusão',
  proposalCreateSuccess: 'Proposta criada com sucesso',
  sentSuccessfully: 'Proposta enviada com sucesso',
  send: 'Enviar Proposta',
  resend: 'Reenviar Proposta',
  sendSureTitle: 'Enviar Proposta',
  sendSureDesc: 'Tem certeza que deseja enviar esta proposta?',
  changeStatus: 'Alterar Status',
  statusUpdatedSuccessfully: 'Status atualizado com sucesso',

  // alert
  noProposal: 'Não há propostas',
  noProposalContent1: 'Propostas não encontradas',
  noProposalSend: 'Não há propostas enviadas',

  // generalInfo
  generalInfoTitle: 'Informações Gerais',
  status: 'Status',
  createdAt: 'Criado em',
  createdBy: 'Criado por',
  updatedAt: 'Atualizado em',
  notAvailable: 'Não Disponível',
  updateStatus: 'Atualizar Status',

  // customerInfo
  customerInfoTitle: 'Informações do Cliente',
  contactPhoneNumber: 'Número de Telefone',

  //productInfo
  productInfoTitle: 'Detalhes do Produto',
  productNameUnknown: 'Produto Desconhecido',
  noProducts: 'Nenhum produto disponível.',

  //legalInfo
  legalInfoTitle: 'Informações Legais',
  ndaTitle: 'Acordo de Confidencialidade (NDA)',
  termsTitle: 'Termos e Condições',
  documentName: 'Nome do Documento',
  documentDescription: 'Descrição',
  documentContent: 'Conteúdo',

  //financialInfo
  financialInfoTitle: 'Informações Financeiras',
  shippingTotal: 'Total de Envio',
  total: 'Preço Total',
  noFinancialData: 'Nenhum dado financeiro disponível.',

  // documentInfo
  documentsTitle: 'Documentos',
  documentNameUnknown: 'Documento Desconhecido',
  mimeType: 'Tipo de Arquivo',
  download: 'Baixar',
  noDocuments: 'Nenhum documento disponível.',

  // salesAgents
  salesAgentsTitle: 'Agentes de Vendas',
  unknownAgent: 'Agente Desconhecido',
  noSalesAgents: 'Nenhum agente de vendas disponível.',

  //signature
  signatureTitle: 'Informações de Assinatura',
  additionalInfoTitle: 'Informações Adicionais',
  signature: 'Assinatura',
  signerUser: 'Usuário Assinante',

  //additionalInfo
  yes: 'Sim',
  no: 'Não',
};

export default locale; 
const locale = {
  // actions
  create: 'Criar Lead',
  update: 'Atualizar Lead',
  detail: 'Detalhe do Lead',
  view: 'Visualizar Lead',
  list: 'Listar Leads',
  addNewRole: 'Adicionar Novo Papel',
  tagsPlaceholder: 'Adicionar tags',
  listInfo: 'Lista de todos os leads.',
  weeklyReport: 'Relatório Semanal',

  // situational messages
  createdSuccessfully: 'Lead criado com sucesso',
  updatedSuccessfully: 'Lead atualizado com sucesso',
  deleteConfirm: 'Tem certeza que deseja excluir? Esta ação não pode ser desfeita.',
  deletedSuccessfully: 'Lead excluído com sucesso',
  createInfo: 'Você pode criar novos leads aqui.',
  updateLeadSuccess: 'Lead atualizado com sucesso',
  createLeadSuccess: 'Lead criado com sucesso',
  emptyState1: 'Nenhum lead encontrado aqui.',
  emptyState2: 'Você pode criar ou importar leads imediatamente.',

  // import
  importLead: 'Importar Lead',
  importTitle: 'Importar Leads',
  importText1: 'Você pode importar leads desta página.',
  importText2: 'Você pode usar o formulário de exemplo abaixo para publicar seus leads.',

  // fields and names
  lead: 'Lead',
  leads: 'Leads',
  title: 'Listar Leads',
  assigneds: 'Atribuídos',
  qualification: 'Qualificação',
  justOwn: 'Apenas Próprios',
  isJunk: 'É Lixo',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Lixo',
  unJunk: 'Não Lixo',
  prospectName: 'Nome do Prospecto: ',
  businessType: 'Tipo de Negócio',
  leadType: 'Tipo de Lead',
  leadCount: 'leads',
  leadValue: 'Valor do Lead',
  tracking: 'Rastreamento',
  image: 'Imagem do Lead',
  
  // contact
  addContact: 'Adicionar Contato',
  contactedDate: 'Data de Contato',
  emptyStateContactContent1: 'Nenhum contato encontrado aqui.',
  contactDetail: 'Detalhe do Contato',
  contactHistory: 'Histórico de Contato',
  lastContacted: 'Último Contato',
  lastContact: 'Último Contato',
  contacted: 'Contatado',

  // statistics
  leadConversions: 'Conversões de Leads',

  // lead to customer
  convertToCustomer: 'Converter para Cliente',
  customerType: 'Tipo de Cliente',
  leadConvertedSuccessfully: 'Lead convertido com sucesso',

  onSiteEstimateWith: 'Estimativa no local com',

  // labels
  welcome: 'Bem-vindo',
  description: 'Seu progresso de leads esta semana é incrível. Vamos continuar.',
};

export default locale; 
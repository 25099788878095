import taskLocale from "../subdomains/task/locales/fr";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  task: {
    ...taskLocale,
  },
};

export default locale; 
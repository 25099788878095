const locale = {
  supports: 'Tickets',
  support: 'Ticket',
  detail: 'Detalhes do Ticket',
  subject: 'Assunto',
  title: 'Tickets',
  create: 'Criar Ticket',
  createSuccess: 'Ticket criado com sucesso.',
  update: 'Atualizar Ticket',
  updateSuccess: 'Ticket atualizado com sucesso.',
  delete: 'Excluir Ticket',
  deleteSuccess: 'Ticket excluído com sucesso.',
  deleteContent: 'Tem certeza de que deseja excluir o ticket?',
  emptyState1: 'Ainda não há tickets.',
  emptyState2:
    'Você não criou nenhum ticket ou eles já foram resolvidos. Assim que sua equipe enviar uma solicitação de suporte, todos os tickets aparecerão aqui.',

  // detailDrawer
  generalInfoTitle: 'Informações Gerais',
  ticketInfoTitle: 'Informações do Ticket',
  closedDate: 'Data de Encerramento',
  changeStatus: 'Alterar Status',
  statusChangeSuccess: 'Status alterado com sucesso.',
};

export default locale; 
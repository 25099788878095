import React from 'react';
import { Outlet, useLocation } from 'react-router';
import MainLayout from 'layouts/MainLayout';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { useEffect } from 'react';
import { useLazyUserPermissionListOwnQuery } from 'apps/account/domains/profile/subdomains/profile';
import { useCrmMenuItem } from '../menu/items';
import { useRestrictedCrmMenuItem } from '../menu/items.restricted';
import { useLazyOrganizationDetailOwnQuery } from 'apps/settings/domains/settings/subdomains/settings';

const CrmLayout = () => {
  const location = useLocation();
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const [getOrganizationDetailOwn, { data: organizationDetailOwnData }] =
    useLazyOrganizationDetailOwnQuery();
  const [getUserPermissionListOwn, { data: userPermissionListOwnData }] =
    useLazyUserPermissionListOwnQuery({});

  const status = organizationDetailOwnData?.status;

  const treeItems = useCrmMenuItem({
    userPermissions: userPermissionListOwnData,
  });

  const restrictedTreeItems = useRestrictedCrmMenuItem();

  useEffect(() => {
    if (userDetailOwnData?.organization) {
      getUserPermissionListOwn({});
      getOrganizationDetailOwn({});
    }
  }, [userDetailOwnData]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <MainLayout treeItems={status !== 'active' ? (restrictedTreeItems as any) : (treeItems as any)}>
      <Outlet key={location.pathname} />
    </MainLayout>
  );
};

export default CrmLayout;

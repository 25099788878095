import profileLocale from "../domains/profile/locales/pt";

const locale = {
  profile: {
    ...profileLocale,
  },
  profileo: "Pedido de orçamento"
};

export default locale;

const locale = {
  reports: "Rapports",
  report: "Rapport",
  reportConversions: "Conversions de Rapports",
  title: "Rapports",
  addReport: "Ajouter un Rapport",
  emptyStateDesc1: "Aucun rapport disponible pour le moment.",
  emptyStateDesc2: "Générez et consultez ici des informations détaillées pour suivre vos activités CRM et prendre des décisions basées sur les données."
};

export default locale; 
import { useEffect, useState } from 'react';
import { Container, Grid, Stack } from '@mui/material';
import { Iconify, MyTab } from 'components';
import { useTranslation } from 'react-i18next';

import Header from './components/Header';
import InfoSection from './components/InfoSection';
import TableComponent from './components/Table';
import Descriptions from './components/Descriptions';
import Comments from './components/Comments';
import Documents from './components/Documents';
import ActionButtons from './components/ActionButtons';
import { ApolloClient, ApolloProvider, InMemoryCache, useMutation, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import {
  ProposalStatus,
  IProposalDetailProspectResult,
  proposalDetailProspectQuery,
  proposalOpenProspectQuery,
} from 'corede-common-cocrm';

const ProposalComponent = () => {
  const { t } = useTranslation();

  const { loading, error, data, refetch } = useQuery<{
    proposalDetailPublic: IProposalDetailProspectResult;
  }>(proposalDetailProspectQuery);
  const proposalData = data?.proposalDetailPublic;

  const [proposalOpen, { data: proposalOpenData }] = useMutation(proposalOpenProspectQuery);

  const tabIcons = [
    <Iconify icon="mdi:comment-outline" width={20} />,
    <Iconify icon="mdi:file-document-outline" width={20} />,
  ] as React.ReactElement[];

  const tabLabels = [
    // t('auth.comments'),
    t('auth.documents'),
  ];
  const [selectedTab, setSelectedTab] = useState(t('auth.documents'));

  const boxStyles = {
    boxSizing: 'border-box',
    bgcolor: 'background.default',
    width: '100%',
    borderRadius: 3,
    boxShadow: '0px 3px 12px 0px rgba(47, 43, 61, 0.14)',
    px: 3,
    py: 6,
    mt: 3,
    gap: 3,
  };

  useEffect(() => {
    if (proposalData?.status === ProposalStatus.sent && proposalOpen) {
      proposalOpen();
    }
  }, [proposalData, proposalOpen]);

  useEffect(() => {
    if (proposalOpenData) {
      refetch();
    }
  }, [proposalOpenData, refetch]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          <Stack sx={boxStyles}>
            <Header proposalData={proposalData} />

            <InfoSection proposalData={proposalData} />

            <TableComponent proposalData={proposalData} />

            {/* <Timeline /> */}

            <Descriptions proposalData={proposalData} />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Stack sx={{ ...boxStyles, p: 3, gap: 1 }}>
            <MyTab
              icons={tabIcons}
              labels={tabLabels}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />

            {selectedTab === t('auth.comments') && <Comments />}

            {selectedTab === t('auth.documents') && <Documents proposalData={proposalData} />}
          </Stack>

          <Stack sx={{ ...boxStyles, p: 3 }}>
            <ActionButtons proposalData={proposalData} refetch={refetch} />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

const Proposal = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_BASE_URL}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      authorization: `${token}`,
    },
  });

  return (
    <ApolloProvider client={client}>
      <ProposalComponent />
    </ApolloProvider>
  );
};

export default Proposal;

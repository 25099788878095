const locale = {
  service: 'Servicios',
  title: 'Servicios',
  create: 'Crear Servicio',
  update: 'Actualizar Servicio',
  delete: 'Eliminar Servicio',
  deleteConfirm: '¿Está seguro de que desea eliminar este estado: ',
  deletedSuccessfully: 'Servicio eliminado con éxito',
  createService: 'Crear Servicio',
  createSuccess: 'Servicio creado con éxito',
  updateSuccess: 'Servicio actualizado con éxito',
  name: 'Nombre',
  icon: 'Icono',
  showMore: 'Mostrar Más',

  detail: 'Detalle del Servicio',
  type: 'Tipo',
  image: 'Imagen',
  sku: 'Código',
  description: 'Descripción',
  currency: 'Moneda',
  price: 'Precio',
  priceType: 'Tipo de Precio',
  discountedPrice: 'Precio con Descuento',
  unit: 'Unidad',
  categoryId: 'Categoría',
  natTaxRate: 'Tasa de Impuesto Nacional',
  intTaxRate: 'Tasa de Impuesto Internacional',

  categories: 'Categorías',
  rate: 'Tarifa',
  rateType: 'Tipo de Tarifa',
  discountedRate: 'Tarifa con Descuento',
  emptyState1: 'Aún no hay servicios registrados.',
  emptyState2:
    'Gestione y revise aquí cualquier devolución de servicio para mantener sus registros precisos y satisfacer las necesidades del cliente.',
};

export default locale; 
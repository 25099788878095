/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card, Grid } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  IBaseError,
  IGraphqlVariables,
  isNullOrUndefinedOrEmptyObject,
  PermissionAction,
} from 'corede-common';
import { IDepartment, IDepartmentListInput, PermissionSubject } from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useDepartmentListQuery, useDepartmentUpdateMutation } from '../../context/department.api';
// import { DepartmentDeleteDialog } from "../DepartmentDeleteDialog";
import DepartmentUpdateDrawerWrapper from '../update/DepartmentUpdateWrapper';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import { IPendingNavigation } from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representString, representArrayOfObjectAsString } from 'utils/representationHelper';
import { DepartmentDeleteDialog } from '../delete/DepartmentDeleteDialog';

export interface IDepartmentListGridComponent {
  // department
  setOpenDepartmentCreateDrawer?: (value: boolean) => void | undefined;
}

const DepartmentListGridComponent = memo((props: IDepartmentListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const navigate = useNavigate();

  // states
  const [selectedDepartment, setSelectedDepartment] =
    useState<TListGridSelectedEntity<IDepartment>>();
  const [openDepartmentDeleteDialog, setOpenDepartmentDeleteDialog] = useState(false);
  const [openDepartmentUpdateDrawer, setOpenDepartmentUpdateDrawer] = useState(false);

  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [departmentListFilter, setDepartmentListFilter] = useState<
    IGraphqlVariables<IDepartmentListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = departmentListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = departmentListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: departmentListData,
    isLoading: departmentListLoading,
    isFetching: departmentListFetching,
    error: departmentListError,
    refetch,
  } = useDepartmentListQuery(departmentListFilter);

  // used for direct update in the list rows
  const [departmentUpdate, { isLoading: departmentUpdateLoading, error: departmentUpdateError }] =
    useDepartmentUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setDepartmentListLoading && props.setDepartmentListLoading(departmentListLoading);
  // }, [departmentListLoading]);
  useEffect(() => {
    refetch();
    return () => {
      // Cleanup function
      setSelectedDepartment(undefined);
      setOpenDepartmentDeleteDialog(false);
      setOpenDepartmentUpdateDrawer(false);
    };
  }, [departmentListFilter]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(departmentListError as IBaseError, currentLanguage);
    return () => {
      // Cleanup function
    };
  }, [departmentListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(departmentUpdateError as IBaseError, currentLanguage);
    return () => {
      // Cleanup function
    };
  }, [departmentUpdateError]);

  const isFilterEmpty = isNullOrUndefinedOrEmptyObject(departmentListFilter?.input?.filter);
  const isJunkUndefined = (departmentListFilter as any)?.filter?.isJunk === undefined;
  const isBusinessTypesUndefined = departmentListFilter?.input?.filter?.businessTypes === undefined;
  const isEmptyState =
    departmentListData?.data?.length === 0 &&
    !departmentListLoading &&
    !departmentListFetching &&
    (isFilterEmpty || (isJunkUndefined && isBusinessTypesUndefined));

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedDepartment}
      setSelectedEntity={setSelectedDepartment}
      listFilter={departmentListFilter}
      setListFilter={setDepartmentListFilter}
      listData={departmentListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      {departmentListData ? (
        !isEmptyState ? (
          <BaseDataGrid
            rows={
              departmentListData?.data?.map((department, index) => ({
                id:
                  ((departmentListFilter.input?.pagination?.page || 1) - 1) *
                    (departmentListFilter.input?.pagination?.pageSize || 0) +
                  index +
                  1,
                _id: department._id,
                name: representString(department.name),
                roles: representArrayOfObjectAsString({
                  dataList: department.roles,
                }),
              })) || []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'id',
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                    align: 'center',
                  },
                  headerConfig: {
                    name: '#',
                    align: 'center',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'name',
                    editable: true,
                    align: 'left',
                    width: 150,
                  },
                  headerConfig: {
                    name: t('crm.organizationalChart.department.columnName'),
                    icon: 'mdi:account-group',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'roles',
                    editable: false,
                    sortable: false,
                    width: 300,
                  },
                  headerConfig: {
                    name: t('crm.organizationalChart.department.columnRoles'),
                    icon: 'mdi:briefcase-account',
                  },
                },
              },
            ]}
            actionColumn={{
              width: 80,
              defaultActions: {
                edit: {
                  clickConfig: {
                    setSelectedRow: setSelectedDepartment,
                    setOpenAction: setOpenDepartmentUpdateDrawer,
                  },
                },
                delete: {
                  clickConfig: {
                    setSelectedRow: setSelectedDepartment,
                    setOpenAction: setOpenDepartmentDeleteDialog,
                  },
                },
              },
              actionHeaderName: t('crm.actions'),
            }}
            loading={departmentListLoading || departmentUpdateLoading || departmentListFetching}
            listFilter={{
              filterInput: departmentListFilter,
              setFilterInput: setDepartmentListFilter,
            }}
            update={{
              updateQuery: departmentUpdate,
            }}
            count={departmentListData?.count ?? 0}
            config={{
              columnVisibilityModel: {},
              features: [],
            }}
            toolbar={{
              customActions: [],
            }}
          />
        ) : (
          <EmptyState
            content1={t('crm.organizationalChart.department.emptyState1')}
            content2={t('crm.organizationalChart.department.emptyState2')}
            button1={{
              title: t('crm.organizationalChart.department.create'),
              onClick: () => (props?.setOpenDepartmentCreateDrawer ?? (() => {}))(true),
              leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              permission: {
                subject: PermissionSubject.department,
                action: PermissionAction.create,
              },
            }}
          />
        )
      ) : null}

      <DepartmentUpdateDrawerWrapper
        open={openDepartmentUpdateDrawer}
        setOpen={setOpenDepartmentUpdateDrawer}
        selectedDepartment={selectedDepartment}
      />

      <DepartmentDeleteDialog
        open={openDepartmentDeleteDialog}
        onClose={() => {
          setSelectedDepartment(undefined);
          setOpenDepartmentDeleteDialog(false);
        }}
        item={selectedDepartment}
        key={selectedDepartment?._id}
      />
    </DefaultListGridComponent>
  );
});

export default DepartmentListGridComponent;

const locale = {
  // actions
  create: 'Criar Cliente',
  update: 'Atualizar Cliente',
  detail: 'Detalhe do Cliente',
  view: 'Ver Cliente',
  list: 'Listar Clientes',
  addNewRole: 'Adicionar Novo Papel',
  tagsPlaceholder: 'Adicionar tags',
  listInfo: 'Lista de todos os clientes.',
  weeklyReport: 'Relatório Semanal',
  copyBillingInfo: 'Copiar Informações de Faturamento',

  // situational messages
  createdSuccessfully: 'Cliente criado com sucesso',
  updatedSuccessfully: 'Cliente atualizado com sucesso',
  deleteConfirm: 'Tem certeza que deseja excluir este cliente? Esta ação não pode ser desfeita.',
  deletedSuccessfully: 'Cliente excluído com sucesso',
  createInfo: 'Você pode criar novos clientes aqui.',
  updateCustomerSuccess: 'Cliente atualizado com sucesso',
  createCustomerSuccess: 'Cliente criado com sucesso',
  emptyState1: 'Nenhum cliente encontrado',
  emptyState2: 'Por favor, crie um novo cliente',

  // import
  importCustomer: 'Importar Cliente',
  import: 'Importar Cliente',
  importTitle: 'Importar Clientes',
  importText1: 'Você pode importar clientes desta página.',
  importText2: 'Você pode usar o formulário de exemplo abaixo para publicar seus clientes.',

  // fields and names
  title: 'Clientes',
  customer: 'Cliente',
  customers: 'Clientes',
  assigneds: 'Atribuídos',
  qualification: 'Qualificação',
  justOwn: 'Apenas Próprios',
  isJunk: 'É Lixo',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Lixo',
  unJunk: 'Não Lixo',
  prospectName: 'Nome do Prospecto',
  businessType: 'Tipo de Negócio',
  customerType: 'Tipo de Cliente',
  customerCount: 'clientes',
  customerValue: 'Valor do Cliente',

  // contact
  addContact: 'Adicionar Contato',
  contactedDate: 'Data de Contato',
  emptyStateContactContent1: 'Nenhum contato encontrado aqui.',
  contactDetail: 'Detalhe do Contato',
  contactHistory: 'Histórico de Contato',
  lastContacted: 'Último Contato',
  lastContact: 'Último Contato',
  contacted: 'Contatado',
  otherContacts: 'Outros Contatos',

  onSiteEstimateWith: 'Estimativa no local com',

  // new
  customerListTitle: 'Lista de Clientes',
  headerTitle: 'Clientes',
  relatedCustomers: 'Clientes Relacionados',
  b2bB2c: 'B2B / B2C',
  customerNameLabel: 'Nome do Cliente',
  customerEmailLabel: 'Email do Cliente',
  customerPhoneLabel: 'Telefone do Cliente',
  customerTypeLabel: 'Tipo de Cliente',
  customerPictureLabel: 'Imagem do Cliente',
  customerLanguageLabel: 'Idioma do Cliente',

  createSuccess: 'Cliente criado com sucesso',

  prospectType: 'Tipo de Prospecto',
  prospectAddress: 'Endereço do Prospecto',
  prospectContactName: 'Nome de Contato do Prospecto',

  // accordions
  primaryContact: 'Contato Principal*',
  primaryBillingInfo: 'Informações de Faturamento Principal',
  primaryShippingInfo: 'Informações de Envio Principal',
  detailInformation: 'Informações Detalhadas',
  address: 'Endereço',

  // labels
  welcome: 'Bem-vindo',
  description: 'Seu progresso com clientes esta semana é incrível. Vamos continuar.',

  // new keys
  customerDetail: 'Detalhe do Cliente',
  viewMüşteri: 'Ver',
  createMüşteri: 'Criar Cliente',
  columnParentCategory: 'Categoria Principal',
  columnActions: 'Ações',
  createCustomerTitle: 'Criar Cliente',
  uploadPictureText: 'Carregar Imagem',
  createCustomerButton: 'Criar Cliente',
  selectIconTitle: 'Selecionar um Ícone',
  searchIcons: 'Pesquisar Ícones',
  searchForAnIcon: 'Pesquisar um ícone',
};

export default locale; 
const locale = {
  permissions: 'Разрешения',
  roles: 'Роли',
  createRole: 'Создать Роль',
  rolesInfo: 'Вы можете создавать роли и назначать им разрешения.',
  roleName: 'Название Роли',
  roleDetail: 'Детали Роли',
  addRole: 'Добавить',
  updateRole: 'Обновить',
  deleteRole: 'Удалить',
  roleCreatedSuccess: 'Роль успешно создана',
  roleUpdatedSuccess: 'Роль успешно обновлена',
  roleDeletedSuccess: 'Роль успешно удалена',
  errorFetchingRoles: 'Ошибка при получении ролей',
  errorCreatingRole: 'Ошибка при создании роли',
  errorUpdatingRole: 'Ошибка при обновлении роли',
  errorDeletingRole: 'Ошибка при удалении роли',
  capabilities: 'Возможности',
  advancedOptions: 'Расширенные Настройки',
  addNewRole: 'Добавить Новую Роль',
  rolesAndPermissions: 'Роли и Разрешения',
  action: 'Действие',
  actionScope: 'Область Действия',
  deleteConfirm: 'Вы уверены, что хотите удалить роль ',
  deletedSuccessfully: "Роль успешно удалена",
};

export default locale; 
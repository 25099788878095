const locale = {
  title: 'Tableau de bord',
  description:
    'Activez votre abonnement Passez à un plan d\'abonnement adapté pour utiliser toutes les fonctionnalités du système CRM et développer votre entreprise',
  featuresTitle: 'Avec abonnement:',
  features1: 'Accès illimité à toutes vos données',
  features2: 'Outils de reporting personnalisés',
  features3: 'Plus de stockage',
  features4: 'Support technique 24/7',
  buttonTitle: 'Explorer les plans d\'abonnement',
  trialExpired: 'Le plan d\'essai a expiré. Veuillez choisir un autre plan.',
  mainFailed: 'Votre plan n\'est pas activé. Veuillez contrôler vos informations de facturation.',
  mainCompleted: 'Votre plan est terminé. Veuillez choisir un autre plan.',
  welcome: 'Bienvenue ',
  platformValue: 'Valeur de la plateforme',
  dealsAmount: 'Montant des affaires',
  deals: 'Affaires',
  byRefererCategory: 'par catégorie de référent',
  revenue: 'Revenus',
  leads: 'Prospects',
  wl: 'G/P',
  winLoss: 'Gain/Perte',
  averageMonthly: 'Moyenne mensuelle',
  monthly: 'Mensuel',
  bestDeal: 'Meilleure affaire',
  sales: 'Ventes',
  topSales: 'Meilleures ventes',
  salesStreak: 'Série de ventes',
  topReview: 'Meilleur avis',
  workWithPlatforms: 'Travail avec plateformes',
  salesDynamics: 'Dynamique des ventes',
  timeframe: 'Période',
  bestDetal: 'Meilleure affaire',
  winRate: 'Taux de réussite',
  prevMonth: 'mois précédent',
  vsPrevMonth: 'vs mois précédent',
  details: 'Détails',
  filters: 'Filtres',
  statistics: 'Statistiques',

  //LEAD
  totalLead: 'Total des prospects',
  totalLeadSubtitle: 'Total des prospects avec données historiques quotidiennes',
  today: 'Aujourd\'hui',
  yesterday: 'Hier',
  leadConversion: 'Conversion de prospects',
  leadConversionSubtitle: 'Conversion de prospects avec données historiques quotidiennes',
  weeklyReport: 'Rapport hebdomadaire',

  //CUSTOMER
  totalCustomer: 'Total des clients',
  totalCustomerSubtitle: 'Total des clients avec données historiques quotidiennes',
  customerFilterType: 'Filtre clients par type',
  customerFilterCategory: 'Filtre clients par catégorie',

  //ESTIMATE
  totalEstimate: 'Total des devis',
  totalEstimateSubtitle: 'Total des devis avec données historiques quotidiennes',
  estimateTotalPrice: 'Prix total estimé',
  estimateTotalPriceSubtitle: 'Prix total estimé avec données historiques quotidiennes',
  estimatesByStatus: 'Devis par statut',
  estimatesByTypes: 'Devis par type',
  estimatesByTarget: 'Devis par cible',

  //PROPOSAL
  totalProposal: 'Total des propositions',
  totalProposalSubtitle: 'Total des propositions avec données historiques quotidiennes',
  proposalTotalPrice: 'Prix total des propositions',
  proposalTotalPriceSubtitle: 'Prix total des propositions avec données historiques quotidiennes',
  proposalsByStatus: 'Propositions par statut',
  proposalsByTypes: 'Propositions par type',
  proposalsByTarget: 'Propositions par cible',

  //TASK
  totalTask: 'Total des tâches',
  totalTaskSubtitle: 'Total des tâches avec données historiques quotidiennes',
  taskFilterPriority: 'Filtre tâches par priorité',
  taskFilterStatus: 'Filtre tâches par statut',
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  Currency,
  IBaseError,
  IGraphqlVariables,
  IHasId,
  isNullOrUndefinedOrEmptyObject,
} from 'corede-common';
import {
  IBaseProductEntity,
  IProduct,
  IProductDetailResult,
  IProductListInput,
  PriceType,
  ProductType,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import {
  useProductListQuery,
  useProductUpdateMutation,
} from '../../../common/context/productsService.api';

import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  useHandleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representHtmlAsString } from 'utils/representationHelper';
import ServiceUpdateDrawerWrapper from '../update/ServiceUpdateDrawer.wrapper';
import { ServiceDeleteDialog } from '../delete/ServiceDeleteDialog';
import ProductDetailDrawerWrapper from '../detailDrawer/ServiceDetailDrawer.wrapper';
import { useProductCategoryListQuery } from '../../../productCategory/context/productCategory.api';

export interface IServiceListGridComponent {
  // product

  setOpenServiceCreateDrawer?: (value: boolean) => void | undefined;
}

const ServiceListGridComponent = memo((props: IServiceListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedProduct, setSelectedProduct] = useState<
    TListGridSelectedEntity<IProduct> | undefined
  >(undefined);
  const [openProductDetailDrawer, setOpenProductDetailDrawer] = useState(false);
  const [openProductDeleteDialog, setOpenProductDeleteDialog] = useState(false);
  const [openProductUpdateDrawer, setOpenProductUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [productListFilter, setProductListFilter] = useState<IGraphqlVariables<IProductListInput>>({
    input: {
      filter: {
        types: [ProductType.service],
      },
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = productListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = productListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: productListData,
    isLoading: productListLoading,
    isFetching: productListFetching,
    error: productListError,
    refetch,
  } = useProductListQuery(productListFilter);

  const { data: productCategoryListData } = useProductCategoryListQuery({});

  // used for direct update in the list rows
  const [productUpdate, { isLoading: productUpdateLoading, error: productUpdateError }] =
    useProductUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setProductListLoading && props.setProductListLoading(productListLoading);
  // }, [productListLoading]);
  useEffect(() => {
    refetch();
  }, [productListFilter]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(productListError as IBaseError, currentLanguage);
  }, [productListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(productUpdateError as IBaseError, currentLanguage);
  }, [productUpdateError]);

  const isFilterEmpty = isNullOrUndefinedOrEmptyObject(productListFilter?.input?.filter);
  const isJunkUndefined = (productListFilter as any)?.filter?.isJunk === undefined;
  const isBusinessTypesUndefined = productListFilter?.input?.filter?.businessTypes === undefined;
  const isEmptyState =
    productListData?.data?.length === 0 &&
    !productListLoading &&
    !productListFetching &&
    (isFilterEmpty || (isJunkUndefined && isBusinessTypesUndefined));

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedProduct}
      setSelectedEntity={setSelectedProduct}
      listFilter={productListFilter}
      setListFilter={setProductListFilter}
      listData={productListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      {productListData ? (
        !isEmptyState ? (
          <BaseDataGrid
            rows={
              productListData?.data?.map((product, index) => ({
                id:
                  ((productListFilter.input?.pagination?.page || 1) - 1) *
                    (productListFilter.input?.pagination?.pageSize || 0) +
                  index +
                  1,
                _id: product._id,
                name: product.name,
                sku: product.sku,
                type: product.type,
                description: representHtmlAsString({ data: product.description }),
                currency: product.currency,
                price: product.price,
                priceType: product.priceType || null,
                discountedPrice: product.discountedPrice || null,
                unit: product.unit,
                unitPrice: product.unitPrice?.toFixed(2),
                category: product.category,
                natTaxRate: product.natTaxRate,
                intTaxRate: product.intTaxRate,
              })) || []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'id',
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                    align: 'center',
                  },
                  headerConfig: {
                    name: '#',
                    align: 'center',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'name',
                    filterable: true,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'mdi:label-outline',
                    name: t('crm.name'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'sku',
                    editable: true,
                    filterable: true,
                  },
                  headerConfig: {
                    icon: 'mdi:barcode',
                    name: t('crm.sku'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'type',
                    editable: true,
                    filterable: true,
                    width: 90,
                  },
                  headerConfig: {
                    icon: 'mdi:folder-outline',
                    name: t('crm.type'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `types`,
                    isArrayFilter: true,
                  },
                },
                data: ProductType,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'currency',
                    editable: true,
                    filterable: true,
                    width: 90,
                  },
                  headerConfig: {
                    icon: 'mdi:currency-usd',
                    name: t('crm.currency'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `currency`,
                    isArrayFilter: true,
                  },
                },
                data: Currency,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'price',
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'mdi:cash',
                    name: t('crm.price'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'priceType',
                    editable: true,
                    filterable: true,
                    width: 110,
                  },
                  headerConfig: {
                    icon: 'mdi:label-percent-outline',
                    name: t('crm.priceType'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `priceType`,
                    isArrayFilter: true,
                  },
                },
                data: PriceType,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'discountedPrice',
                    filterable: true,
                    editable: true,
                    width: 130,
                  },
                  headerConfig: {
                    icon: 'mdi:sale',
                    name: t('crm.discountedPrice'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'unit',
                    filterable: true,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'mdi:ruler',
                    name: t('crm.unit'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'unitPrice',
                    filterable: true,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'mdi:cash-check',
                    name: t('crm.unitPrice'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.object,
                  column: {
                    field: 'category',
                    filterable: true,
                    editable: true,
                    width: 140,
                  },
                  headerConfig: {
                    icon: 'mdi:folder-outline',
                    name: t('crm.category'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `category`,
                    isArrayFilter: true,
                  },
                  valueOptionFields: productCategoryListData?.data,
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'natTaxRate',
                    filterable: true,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'mdi:finance',
                    name: t('crm.natTaxRate'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'intTaxRate',
                    filterable: true,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'mdi:earth',
                    name: t('crm.intTaxRate'),
                  },
                },
              },
            ]}
            actionColumn={{
              width: 80,
              defaultActions: {
                view: {
                  clickConfig: {
                    setSelectedRow: setSelectedProduct,
                    setOpenAction: setOpenProductDetailDrawer,
                  },
                },
                edit: {
                  clickConfig: {
                    setSelectedRow: setSelectedProduct,
                    setOpenAction: setOpenProductUpdateDrawer,
                  },
                },
                delete: {
                  clickConfig: {
                    setSelectedRow: setSelectedProduct,
                    setOpenAction: setOpenProductDeleteDialog,
                  },
                },
              },
              actionHeaderName: t('crm.actions'),
            }}
            loading={productListLoading || productUpdateLoading || productListFetching}
            listFilter={{
              filterInput: productListFilter,
              setFilterInput: setProductListFilter,
            }}
            update={{
              updateQuery: productUpdate,
            }}
            count={productListData?.count ?? 0}
            config={{
              columnVisibilityModel: {
                fullName: false,
                website: false,
                company: false,
                sector: false,
                country: false,
                state: false,
                city: false,
                language: false,
                productValue: false,
                department: false,
              },
              features: [],
            }}
            toolbar={{
              customActions: [],
            }}
          />
        ) : (
          <EmptyState
            content1={t('crm.productAndService.service.emptyState1')}
            content2={t('crm.productAndService.service.emptyState2')}
            button1={{
              title: t('crm.productAndService.service.create'),
              onClick: () => (props?.setOpenServiceCreateDrawer ?? (() => {}))(true),
              leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
            }}
          />
        )
      ) : null}

      <ProductDetailDrawerWrapper
        open={openProductDetailDrawer}
        setOpen={setOpenProductDetailDrawer}
        selectedProduct={selectedProduct as IProductDetailResult & IHasId<number>} // TODO:
        sideNavigationProps={{
          count: productListData?.count ?? 0,
          handleNavigate: useHandleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: productListData,
            listFilter: productListFilter,
            setListFilter: setProductListFilter,
            selectedEntity: selectedProduct,
            setSelectedEntity: setSelectedProduct,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      />

      <ServiceUpdateDrawerWrapper
        open={openProductUpdateDrawer}
        setOpen={setOpenProductUpdateDrawer}
        productId={selectedProduct?._id ?? ''}
      />

      <ServiceDeleteDialog
        open={openProductDeleteDialog}
        item={selectedProduct as IBaseProductEntity}
        onClose={() => {
          setSelectedProduct(undefined);
          setOpenProductDeleteDialog(false);
        }}
        key={selectedProduct?._id}
      />
    </DefaultListGridComponent>
  );
});

export default ServiceListGridComponent;

import crmLocale from '../../apps/crm/locales/de';
import authLocale from '../../apps/auth/locales/de';
import leadPlusLocale from '../../apps/leadPlus/locales/de';
import communityLocale from '../../apps/community/locales/de';
import marketingLocale from '../../apps/marketing/locales/de';
import windowLocale from '../../apps/window/locales/de';
import settingsLocale from '../../apps/settings/locales/de';
import accountLocale from '../../apps/account/locales/de';
import webNotificationLocale from '../../apps/webNotification/locales/de';
import diskLocale from '../../apps/disk/locales/de';

const locale = {
  crm: {
    ...crmLocale,
  },
  auth: {
    ...authLocale,
  },
  leadPlus: {
    ...leadPlusLocale,
  },
  community: {
    ...communityLocale,
  },
  marketing: {
    ...marketingLocale,
  },
  window: {
    ...windowLocale,
  },
  settings: {
    ...settingsLocale,
  },
  account: {
    ...accountLocale,
  },
  webNotification: {
    ...webNotificationLocale,
  },
  disk: {
    ...diskLocale,
  },
  enums: {
    '-': '-',
    undefined: '-',
    null: '-',
    active: 'Aktiv',
    pending: 'Ausstehend',
    passive: 'Passiv',
    neutral: 'Neutral',
    cold: 'Kalt',
    warm: 'Warm',
    hot: 'Heiß',
    customer: 'Kunde',
    unqualified: 'Nicht qualifiziert',
    IQL: 'IQL (Information)',
    MQL: 'MQL (Marketing)',
    SQL: 'SQL (Vertrieb)',
    en: 'Englisch',
    fr: 'Französisch',
    de: 'Deutsch',
    it: 'Italienisch',
    pt: 'Portugiesisch',
    ru: 'Russisch',
    es: 'Spanisch',
    tr: 'Türkisch',
    individual: 'Einzelperson',
    business: 'Unternehmen',
    government: 'Regierung',
    b2b: 'B2B',
    b2c: 'B2C',
    TRY: 'TRY',
    EUR: 'EUR',
    USD: 'USD',
    IRR: 'IRR',
    GBP: 'GBP',
    NOK: 'NOK',
    RUB: 'RUB',
    CHF: 'CHF',
    flat: 'Pauschal',
    hourly: 'Stündlich',
    daily: 'Täglich',
    monthly: 'Monatlich',
    NDA: 'NDA',
    termsAndConditions: 'Allgemeine Geschäftsbedingungen',
    lead: 'Lead',
    unknown: 'Unbekannt',
    everyday: 'Täglich',
    everyWeek: 'Wöchentlich',
    annual: 'Jährlich',
    email: 'E-Mail',
    sms: 'SMS',
    push: 'Push',
    web: 'Web',
    male: 'Männlich',
    female: 'Weiblich',
    other: 'Andere',
    All: 'Alle',
    Unread: 'Ungelesen',
    automatic: 'Automatisch',
    manual: 'Manuell',
    high: 'Hoch',
    low: 'Niedrig',
    medium: 'Mittel',
    urgent: 'Dringend',
    draft: 'Entwurf',
    sent: 'Gesendet',
    opened: 'Geöffnet',
    revised: 'Überarbeitet',
    declined: 'Abgelehnt',
    accepted: 'Akzeptiert',
    product: 'Produkt',
    service: 'Dienstleistung',
    iyzico: 'Iyzico',
    stripe: 'Stripe',
    posbasit: 'POS Basit',
    paytr: 'PayTR',
    moneyTransfer: 'Überweisung',
    defective: 'Defekt',
    incorrectItem: 'Falscher Artikel',
    changeOfMind: 'Meinungsänderung',
    throughTasks: 'Durch Aufgaben',
    fixedRate: 'Festpreis',
    projectHours: 'Projektstunden',
    taskHours: 'Aufgabenstunden',
    onHold: 'In Wartestellung',
    nearToExpire: 'Kurz vor Ablauf',
    trash: 'Papierkorb',
    A: 'A',
    B: 'B',
    C: 'C',
    yes: 'Ja',
    no: 'Nein',
    default: 'Standard',
    meeting: 'Besprechung',
    call: 'Anruf',
    event: 'Veranstaltung',
    postponed: 'Verschoben',
    canceled: 'Abgesagt',
    TWENTY_FOUR_HOURS: '24 Stunden',
    TWELVE_HOURS: '12 Stunden',
    '24-hours': '24 Stunden',
    '12-hours': '12 Stunden',
    requested: 'Angefordert',
    inspected: 'Geprüft',
    approved: 'Genehmigt',
    disapproved: 'Abgelehnt',
    resolved: 'Gelöst',
    refund: 'Rückerstattung',
    replacement: 'Ersatz',
    repair: 'Reparatur',
    exchange: 'Austausch',

    // UIElementType
    fieldInput: 'Feldeingabe',
    header: 'Überschrift',
    paragraph: 'Absatz',

    // LeadFieldEnum
    fullName: 'Vollständiger Name',
    position: 'Position',
    website: 'Webseite',
    phoneNumber: 'Telefonnummer',
    company: 'Unternehmen',
    sector: 'Branche',
    country: 'Land',
    state: 'Bundesland',
    city: 'Stadt',
    documents: 'Dokumente',

    // reminder
    schedule: 'Planen',
    complete: 'Abschließen',
    cancel: 'Abbrechen',
    scheduled: 'Geplant',
    unscheduled: 'Ungeplant',
    expired: 'Abgelaufen',
    cancelled: 'Abgesagt',

    // subscription status
    ongoing: 'AKTIV',
    ongoingWithFailure: 'AKTIV',
    ongoingWithCancelled: 'AKTIV',
    failed: 'FEHLGESCHLAGEN',

    //task status
    notStarted: 'Nicht begonnen',
    inProgress: 'In Bearbeitung',
    tested: 'Getestet',
    awaitingFeedback: 'Warte auf Feedback',
    completed: 'Abgeschlossen',

    // target type
    Lead: 'Lead',
    Customer: 'Kunde',
    Estimate: 'Angebot',
    Proposal: 'Vorschlag',
    Invoice: 'Rechnung',
    Task: 'Aufgabe',
    User: 'Benutzer',
    Organization: 'Organisation',
    Project: 'Projekt',
    None: 'Keine',
    Ticket: 'Ticket',
    Expense: 'Ausgabe',
    Contract: 'Vertrag',

    //ticket categories
    incidentTicket: 'Vorfallticket',
    requestForInformation: 'Informationsanfrage',
    serviceRequest: 'Serviceanfrage',
    problemTicket: 'Problemticket',
    changeRequest: 'Änderungsanfrage',
    featureRequest: 'Funktionsanfrage',
    feedback: 'Feedback',
    complaint: 'Beschwerde',
    maintenanceAndOutageReport: 'Wartungs- und Ausfallbericht',
    billingAndPaymentIssue: 'Abrechnungs- und Zahlungsproblem',
    internalTicket: 'Internes Ticket',
    open: 'Offen',
    answered: 'Beantwortet',
    closed: 'Geschlossen',

    //permissions
    auth: 'Authentifizierung',
    comment: 'Kommentar',
    dms: 'Dokument',
    file: 'Dokument',
    note: 'Notiz',
    notification: 'Benachrichtigung',
    payment: 'Zahlung',
    presale: 'Vorverkauf',
    reminder: 'Erinnerung',
    sale: 'Verkauf',
    task: 'Aufgabe',
    user: 'Benutzer',
    permission: 'Berechtigung',
    role: 'Rolle',
    leadSource: 'Lead-Quelle',
    leadStatus: 'Lead-Status',
    leadTag: 'Lead-Tag',
    productCategory: 'Produktkategorie',
    webNotification: 'Web-Benachrichtigung',
    notificationHistory: 'Benachrichtigungsverlauf',
    order: 'Bestellung',
    purchase: 'Einkauf',
    subscription: 'Abonnement',
    proposal: 'Vorschlag',
    estimate: 'Angebot',
    customerCategory: 'Kundenkategorie',
    customerTag: 'Kunden-Tag',
    invoice: 'Rechnung',
    invoicePayment: 'Zahlung',
    invoiceReturn: 'Rückgabe',
    organization: 'Organisation',
    organizationSettings: 'Organisationseinstellungen',
    organizationChart: 'Organigramm',
    department: 'Abteilung',
    userRole: 'Benutzerrolle',
    userPermission: 'Benutzerberechtigung',
    manage: 'Verwalten',
    view: 'Ansehen',
    export: 'Exportieren',
    import: 'Importieren',
    calendar: 'Kalender',
    appointment: 'Termin',
    contract: 'Vertrag',
    contractTemplate: 'Vertragsvorlage',
    expense: 'Ausgabe',
    project: 'Projekt',
    ticket: 'Ticket',
    dashboard: 'Dashboard',

    // file extension
    jpg: 'jpg',
    png: 'png',
    pdf: 'pdf',
    doc: 'doc',
    docx: 'docx',
    xls: 'xls',
    xlsx: 'xlsx',
    ppt: 'ppt',
    pptx: 'pptx',
    txt: 'txt',
    mp4: 'mp4',
    zip: 'zip',

    // payment status
    unPaid: 'Unbezahlt',
    partiallyPaid: 'Teilweise bezahlt',
    totallyPaid: 'Vollständig bezahlt',
    
  },
  organization: 'Organisation',
  department: 'Abteilung',
  user: 'Benutzer',
  aboutUs: 'Über uns',
  termsAndConditions: 'Allgemeine Geschäftsbedingungen',
  uploadDocuments: 'Dokumente hochladen',
  privacyPolicy: 'Datenschutzrichtlinie',
  cookiesPolicy: 'Cookie-Richtlinie',
  resources: 'Ressourcen',
  allRightsReserved: 'Alle Rechte vorbehalten',
  rowsPerPage: 'Zeilen pro Seite',
  chartUpdateSuccess: 'Diagramm erfolgreich aktualisiert!',
  imageUploadSuccess: 'Bild erfolgreich hochgeladen!',
  uploadPicture: 'Bild hochladen',
  expiresIn: 'Läuft ab in ',
  expiresDays: ' Tagen',
  subscriptionExpired: 'Ihr Plan ist abgelaufen!',
  noOptionsText: 'Keine Optionen',
  noCategory: 'Keine Kategorie',

  ////////////////////////////////////////////////////////////////
  // Validations
  ////////////////////////////////////////////////////////////////

  isRequired: 'Dieses Feld ist erforderlich',
  minValue0: 'Mindestwert ist 0',
  fullNameIsRequired: 'Vollständiger Name ist erforderlich',
  emailIsRequired: 'E-Mail ist erforderlich',
  emailIsInvalid: 'E-Mail ist ungültig',
  phoneIsInvalid: 'Telefon ist ungültig',
  nameIsRequired: 'Name ist erforderlich',
  titleIsRequired: 'Titel ist erforderlich',
  profileImageIsRequired: 'Profilbild ist erforderlich',
  descriptionIsRequired: 'Beschreibung ist erforderlich',
  subjectIsRequired: 'Betreff ist erforderlich',
  phoneNumberIsRequired: 'Telefonnummer ist erforderlich',
  quotaYupIsRequired: 'Kontingent ist erforderlich',
  dateYupIsRequired: 'Datum ist erforderlich',
  statusYupIsRequired: 'Status ist erforderlich',
  locationYupIsRequired: 'Standort ist erforderlich',
  detailsIsRequired: 'Details sind erforderlich',
  surnameIsRequired: 'Nachname ist erforderlich',
  birthDateIsRequired: 'Geburtsdatum ist erforderlich',
  genderIsRequired: 'Geschlecht ist erforderlich',
  countryIsRequired: 'Land ist erforderlich',
  cityIsRequired: 'Stadt ist erforderlich',
  addressIsRequired: 'Adresse ist erforderlich',
  contentIsRequired: 'Inhalt ist erforderlich',
  readingTimeIsRequired: 'Lesezeit ist erforderlich',
  tagsIsRequired: 'Tags sind erforderlich',
  referencesIsRequired: 'Referenzen sind erforderlich',
  relatedVideoIsRequired: 'Zugehöriges Video ist erforderlich',
  authorIsRequired: 'Autor ist erforderlich',
  categoryIsRequired: 'Kategorie ist erforderlich',
  targetCategoryIsRequired: 'Zielkategorie ist erforderlich',
  languageIsRequired: 'Sprache ist erforderlich',
  domainIsRequired: 'Domain ist erforderlich',
  targetIsRequired: 'Ziel ist erforderlich',
  questionIsRequired: 'Frage ist erforderlich',
  answerIsRequired: 'Antwort ist erforderlich',
  titleIsTooShort25: 'Titel ist zu kurz, Mindestlänge ist 25',
  titleIsTooLong50: 'Titel ist zu lang, maximale Länge ist 50',
  contentIsTooShort50: 'Inhalt ist zu kurz, Mindestlänge ist 50',
  contentIsTooLong250: 'Inhalt ist zu lang, maximale Länge ist 250',
  descriptionIsTooShort400: 'Beschreibung ist zu kurz, Mindestlänge ist 400',
  descriptionIsTooLong4000: 'Beschreibung ist zu lang, maximale Länge ist 4000',
  quotaMustBePositive: 'Kontingent muss positiv sein',
  quotaMustBeMultipleOf10: 'Kontingent muss ein Vielfaches von 10 sein',
  participantsIsRequired: 'Teilnehmer sind erforderlich',
  startDateIsRequired: 'Startdatum ist erforderlich',
  durationIsRequired: 'Dauer ist erforderlich',
  quotaIsRequired: 'Kontingent ist erforderlich',
  lastApplicationDateIsRequired: 'Letztes Bewerbungsdatum ist erforderlich',
  statusIsRequired: 'Status ist erforderlich',
  locationIsRequired: 'Standort ist erforderlich',
  typeIsRequired: 'Typ ist erforderlich',
  participationLinkIsRequired: 'Teilnahmelink ist erforderlich',
  durationMustBeMultipleOf5: 'Dauer muss ein Vielfaches von 5 sein',
  durationMustBePositive: 'Dauer muss positiv sein',
  nameIsTooShort2: 'Name ist zu kurz, Mindestlänge ist 2',
  nameIsTooLong50: 'Name ist zu lang, maximale Länge ist 50',
  surnameIsTooShort2: 'Nachname ist zu kurz, Mindestlänge ist 2',
  surnameIsTooLong50: 'Nachname ist zu lang, maximale Länge ist 50',
  stateIsRequired: 'Bundesland ist erforderlich',
  positionIsRequired: 'Position ist erforderlich',
  companyIsRequired: 'Unternehmen ist erforderlich',
  sectorIsRequired: 'Branche ist erforderlich',
  qualificationIsRequired: 'Qualifikation ist erforderlich',
  prospectNameIsRequired: 'Interessentenname ist erforderlich',
  prospectPhoneNumberIsRequired: 'Interessenten-Telefonnummer ist erforderlich',
  prospectContactFirstNameIsRequired: 'Vorname des Interessentenkontakts ist erforderlich',
  prospectContactLastNameIsRequired: 'Nachname des Interessentenkontakts ist erforderlich',
  prospectContactEmailIsRequired: 'E-Mail des Interessentenkontakts ist erforderlich',
  websiteIsInvalid: 'Webseite ist ungültig',
  identityNumberIsRequired: 'Ausweisnummer ist erforderlich',
  taxOfficeIsRequired: 'Finanzamt ist erforderlich',
  streetIsRequired: 'Straße ist erforderlich',
  zipIsRequired: 'PLZ ist erforderlich',
  passwordIsRequired: 'Passwort ist erforderlich',
  passwordIsInvalid: 'Muss mindestens 8 Zeichen lang sein und Groß- und Kleinbuchstaben, Zahlen und Symbole enthalten.',
  passwordAgainIsInvalid: 'Passwörter müssen übereinstimmen',
  cannotBeGreaterThanPrice: 'Kann nicht größer als der Preis sein',
  endTimeMustBeLaterThanStartTime: 'Muss später als die Startzeit sein',
  mustBeNumber: 'Muss eine Zahl sein',

  cardValidation: {
    required: 'Dieses Feld ist erforderlich',
    invalid: 'Ungültig',
    expired: 'Abgelaufen',
  },

  ////////////////////////////////////////////////////////////////
  // Buttons
  ////////////////////////////////////////////////////////////////

  cancel: 'Abbrechen',
  save: 'Speichern',
  update: 'Aktualisieren',
  delete: 'Löschen',
  create: 'Erstellen',
  add: 'Hinzufügen',
  close: 'Schließen',
  submit: 'Absenden',
  addDocument: 'Dokument hinzufügen',
  upload: 'Hochladen',
  view: 'Ansehen',
  edit: 'Bearbeiten',
  remove: 'Entfernen',
  myPlan: 'Mein Plan',
  expandAll: 'Alle erweitern',
  collapseAll: 'Alle einklappen',
  show: 'Anzeigen',
  download: 'Herunterladen',
  yes: 'Ja',
  no: 'Nein',
  completed: 'Abgeschlossen',
  uploadDocument: 'Dokument hochladen',
  newTask: 'Neue Aufgabe',
  loadMore: 'Mehr laden',

  ////////////////////////////////////////////////////////////////
  // Tables
  ////////////////////////////////////////////////////////////////

  actions: 'Aktionen',
  title: 'Titel',
  content: 'Inhalt',
  category: 'Kategorie',
  target: 'Ziel',
  status: 'Status',
  date: 'Datum',
  location: 'Standort',
  details: 'Details',
  name: 'Name',
  surname: 'Nachname',
  birthDate: 'Geburtsdatum',
  fullName: 'Vollständiger Name',
  email: 'E-Mail',
  subject: 'Betreff',
  createdAt: 'Erstellt am',
  userType: 'Benutzertyp',
  page: 'Seite',
  news: 'Nachrichten',
  offer: 'Angebot',
  products: 'Produkte',
  updatedAt: 'Aktualisiert am',
  readingTime: 'Lesezeit',
  tags: 'Tags',
  references: 'Referenzen',
  version: 'Version',
  domain: 'Domain',
  type: 'Typ',
  language: 'Sprache',
  duration: 'Dauer',
  startDate: 'Startdatum',
  participants: 'Teilnehmer',
  quota: 'Kontingent',
  question: 'Frage',
  answer: 'Antwort',
  lastApplicationDate: 'Letztes Bewerbungsdatum',
  selectAnOption: 'Wählen Sie eine Option',
  showing: 'Zeige {{start}} bis {{end}} von {{total}} Einträgen',

  ////////////////////////////////////////////////////////////////
  // Menus
  ////////////////////////////////////////////////////////////////
  dashboard: 'Dashboard',
  blog: 'Blog',
  blogs: 'Blogs',
  categories: 'Kategorien',
  authors: 'Autoren',
  faq: 'FAQ',
  faqs: 'FAQs',
  faqCategory: 'FAQ-Kategorien',
  form: 'Formular',
  contactForm: 'Kontaktformulare',
  subscriptionForm: 'Abonnementformulare',
  blogTargetCategories: 'Zielkategorien',
  users: 'Benutzer',
  departments: 'Abteilungen',
  roles: 'Rollen',
  permissions: 'Berechtigungen',
  leadStatuses: 'Lead-Status',
  leadSources: 'Lead-Quellen',
  leadForms: 'Lead-Formulare',
  customers: 'Kunden',
  contacts: 'Kontakte',
  customerCategories: 'Kundenkategorien',
  estimates: 'Angebote',
  proposals: 'Vorschläge',
  invoices: 'Rechnungen',
  payments: 'Zahlungen',
  contracts: 'Verträge',
  returns: 'Rückgaben',
  services: 'Dienstleistungen',
  reminders: 'Erinnerungen',
  appointments: 'Termine',
  starred: 'Markiert',
  recent: 'Kürzlich',
  actionMenu: 'Aktionsmenü',
  themeMenu: 'Themenmenü',
  notifications: 'Benachrichtigungen',
  languageMenu: 'Sprachmenü',

  ////////////////////////////////////////////////////////////////
  // Permissions
  ////////////////////////////////////////////////////////////////

  organizationLevel: 'Organisationsebene',
  organizationLevelDesc: 'Die Ebene der Organisation, in der sich der Benutzer befindet.',
  departmentLevel: 'Abteilungsebene',
  departmentLevelDesc: 'Die Ebene der Abteilung, in der sich der Benutzer befindet.',
  userLevel: 'Benutzerebene',
  userLevelDesc: 'Die Ebene des Benutzers, in der sich der Benutzer befindet.',

  ////////////////////////////////////////////////////////////////
  // Abbreviations
  ////////////////////////////////////////////////////////////////
  min: 'Min',

  ////////////////////////////////////////////////////////////////
  // Inputs
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // Days
  ////////////////////////////////////////////////////////////////
  sunday: 'Sonntag',
  monday: 'Montag',
  tuesday: 'Dienstag',
  wednesday: 'Mittwoch',
  thursday: 'Donnerstag',
  friday: 'Freitag',
  saturday: 'Samstag',
  today: 'Heute',
};

export default locale; 
const locale = {
  title: 'Панель управления',
  description:
    'Активируйте подписку Перейдите на подходящий план подписки, чтобы использовать все функции CRM-системы и развивать свой бизнес',
  featuresTitle: 'С подпиской:',
  features1: 'Неограниченный доступ ко всем вашим данным',
  features2: 'Настраиваемые инструменты отчетности',
  features3: 'Больше места для хранения',
  features4: 'Техническая поддержка 24/7',
  buttonTitle: 'Изучить планы подписки',
  trialExpired: 'Пробный план истек. Пожалуйста, выберите другой план.',
  mainFailed: 'Ваш план не активирован. Пожалуйста, проверьте информацию о выставлении счетов.',
  mainCompleted: 'Ваш план завершен. Пожалуйста, выберите другой план.',
  welcome: 'Добро пожаловать ',
  platformValue: 'Ценность платформы',
  dealsAmount: 'Сумма сделок',
  deals: 'Сделки',
  byRefererCategory: 'по категории источника',
  revenue: 'Доход',
  leads: 'Лиды',
  wl: 'В/П',
  winLoss: 'Выигрыш/Проигрыш',
  averageMonthly: 'Среднемесячный',
  monthly: 'Ежемесячно',
  bestDeal: 'Лучшая сделка',
  sales: 'Продажи',
  topSales: 'Лучшие продажи',
  salesStreak: 'Серия продаж',
  topReview: 'Лучший отзыв',
  workWithPlatforms: 'Работа с платформами',
  salesDynamics: 'Динамика продаж',
  timeframe: 'Временной период',
  bestDetal: 'Лучшая сделка',
  winRate: 'Коэффициент выигрыша',
  prevMonth: 'предыдущий месяц',
  vsPrevMonth: 'против предыдущего месяца',
  details: 'Детали',
  filters: 'Фильтры',
  statistics: 'Статистика',

  //LEAD
  totalLead: 'Всего лидов',
  totalLeadSubtitle: 'Всего лидов с ежедневными историческими данными',
  today: 'Сегодня',
  yesterday: 'Вчера',
  leadConversion: 'Конверсия лидов',
  leadConversionSubtitle: 'Конверсия лидов с ежедневными историческими данными',
  weeklyReport: 'Еженедельный отчет',

  //CUSTOMER
  totalCustomer: 'Всего клиентов',
  totalCustomerSubtitle: 'Всего клиентов с ежедневными историческими данными',
  customerFilterType: 'Фильтр клиентов по типу',
  customerFilterCategory: 'Фильтр клиентов по категории',

  //ESTIMATE
  totalEstimate: 'Всего оценок',
  totalEstimateSubtitle: 'Всего оценок с ежедневными историческими данными',
  estimateTotalPrice: 'Общая цена оценок',
  estimateTotalPriceSubtitle: 'Общая цена оценок с ежедневными историческими данными',
  estimatesByStatus: 'Оценки по статусам',
  estimatesByTypes: 'Оценки по типам',
  estimatesByTarget: 'Оценки по целям',

  //PROPOSAL
  totalProposal: 'Всего предложений',
  totalProposalSubtitle: 'Всего предложений с ежедневными историческими данными',
  proposalTotalPrice: 'Общая цена предложений',
  proposalTotalPriceSubtitle: 'Общая цена предложений с ежедневными историческими данными',
  proposalsByStatus: 'Предложения по статусам',
  proposalsByTypes: 'Предложения по типам',
  proposalsByTarget: 'Предложения по целям',

  //TASK
  totalTask: 'Всего задач',
  totalTaskSubtitle: 'Всего задач с ежедневными историческими данными',
  taskFilterPriority: 'Фильтр задач по приоритету',
  taskFilterStatus: 'Фильтр задач по статусу',
};

export default locale; 
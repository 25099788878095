const locale = {
  appointments: 'Citas',
  appointment: 'Cita',
  appointmentConversions: 'Conversiones de Citas',
  title: 'Citas',
  create: 'Crear Cita',
  update: 'Actualizar Cita',
  delete: 'Eliminar Cita',
  details: 'Detalles de la Cita',
  createSuccess: 'Cita creada con éxito.',
  updateSuccess: 'Cita actualizada con éxito.',
  emptyState1: 'Aún no hay citas programadas.',
  emptyState2:
    'Configure y gestione sus citas aquí para mantenerse organizado y conectarse con sus clientes sin problemas.',
  statusChangeSuccess: 'Estado de la cita cambiado con éxito.',
  deleteConfirm: '¿Está seguro de que desea eliminar esta cita?',
  deletedSuccessfully: 'Cita eliminada con éxito.',

  //fields
  startTime: 'Hora de inicio',
  endTime: 'Hora de finalización',
  timezone: 'Zona horaria',
  location: 'Ubicación',
  organizer: 'Organizador',
  targetType: 'Tipo de objetivo',
  attendees: 'Asistentes',
  category: 'Categoría',
  meetingLink: 'Enlace de reunión',
  duration: 'Duración',

  //detail drawer
  changeStatus: 'Cambiar estado',
  generalInfoTitle: 'Información general',
  organizerInfoTitle: 'Información del organizador',
  appointmentInfoTitle: 'Información de la cita',
};

export default locale; 
const locale = {
  // fields
  users: 'Utenti della Nota',
  date: 'Data della Nota',
  title: 'Elenco Note',
  detail: 'Dettaglio Nota',
  sharedList: "Note Condivise",
  privateList: "Note Private",
  ownList: "Le Mie Note",
  allList: "Tutte le Note",

  // actions
  create: 'Crea Nota',
  add: 'Aggiungi Nota',
  update: 'Aggiorna Nota',
  delete: 'Elimina Nota',

  // situational text
  tagsPlaceholder: 'Aggiungi tag e premi invio',
  createSuccess: 'Nota creata con successo',
  updateSuccess: 'Nota aggiornata con successo',
  deletedSuccessfully: 'Nota eliminata con successo',
  deleteContent: 'Sei sicuro di voler eliminare questa nota? Questa azione non può essere annullata.',
  emptyState1: 'Nessuna nota aggiunta ancora.',
  emptyState2:
    'Crea e organizza le tue note qui per tenere traccia di dettagli importanti e conversazioni nel tuo CRM.',

  createdBy: "Nota creata da",
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, useMediaQuery } from '@mui/material';
import { ITicketDetailResult, TicketTargetType } from 'corede-common-cocrm';
import React from 'react';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { TicketDetailDrawerLeftPanelHeaderComponent } from './TicketDetailDrawer-leftPanel-header.component';
import { TicketDetailDrawerLeftPanelGeneralInfoComponent } from './TicketDetailDrawer-leftPanel-generalInfo.component';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TicketDetailDrawerLeftPanelAssignedsComponent } from './TicketDetailDrawer-leftPanel-assigned.component';
import { DetailDrawerTargetComponent } from 'apps/crm/components/detailDrawer/DetailDrawer-target.component';
import { TicketDetailDrawerLeftPanelCreatedByComponent } from './TicketDetailDrawer-leftPanel-createdBy.component.';

export interface ITicketDetailDrawerLeftPanelComponentProps {
  ticketDetailData: ITicketDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TicketDetailDrawerLeftPanelComponent = (
  props: ITicketDetailDrawerLeftPanelComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <DefaultDetailDrawerLeftPanelComponent<ITicketDetailResult>
      entityData={props.ticketDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <TicketDetailDrawerLeftPanelHeaderComponent
        ticketDetailData={props.ticketDetailData}
        setOpen={props.setOpen}
      />

      {/* General Info */}
      <TicketDetailDrawerLeftPanelGeneralInfoComponent
        ticketDetailData={props.ticketDetailData}
        setOpen={props.setOpen}
      />

      {/* Assignees */}
      <TicketDetailDrawerLeftPanelAssignedsComponent
        setOpen={props.setOpen}
        ticketDetailData={props.ticketDetailData}
      />

      {/* Created By */}
      <TicketDetailDrawerLeftPanelCreatedByComponent
        setOpen={props.setOpen}
        ticketDetailData={props.ticketDetailData}
      />

      <Divider />

      {/* <Button
        variant="contained"
        color="secondary"
        sx={{
          width: { xs: '100%', sm: '200px', md: '200px', lg: '100%' },
          height: 32,
          fontSize: { xs: '13px', md: '13px' },
          lineHeight: 1,
          borderRadius: '24px',
        }}
        onClick={() => navigate(viewTicketRoute(props.ticketDetailData?._id ?? ''))}
      >
        {t('crm.support.support.detail')}
      </Button> */}
    </DefaultDetailDrawerLeftPanelComponent>
  );
};

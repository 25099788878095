const locale = {
  leadForms: 'Formulários de Leads',
  title: 'Formulários de Leads',
  newForm: 'Novo Formulário',
  emptyStateDesc1:
    'Seus formulários de leads aparecerão aqui conforme você os cria para capturar informações valiosas dos clientes.',
  emptyStateDesc2:
    'Gerencie e personalize facilmente seus formulários para garantir que você esteja coletando os detalhes certos para cada interação.',

  create: 'Criar Formulário de Lead',
  update: 'Atualizar Formulário de Lead',
  updateSuccess: 'Formulário de lead atualizado com sucesso',
  createSuccess: 'Formulário de lead criado com sucesso',
  deleteSuccess: 'Formulário de lead excluído com sucesso',
  deleteConfirm: 'Tem certeza de que deseja excluir este formulário de lead?',
  deletedSuccessfully: 'Formulário de lead excluído com sucesso',

  // tabs
  detailedInfo: 'Informações Detalhadas',
  branding: 'Marca',
  formFields: 'Campos do Formulário',
  submission: 'Envio*',
  notification: 'Notificação',

  //fields
  addField: 'Adicionar Campo',
  updateField: 'Atualizar Campo',
  label: 'Rótulo',
  formName: 'Nome do Formulário',
  defaultLanguage: 'Idioma Padrão',
  defaultPrefix: 'Prefixo Padrão',
  defaultStatus: 'Status Padrão',
  defaultSource: 'Fonte Padrão',
  defaultAssigned: 'Atribuição Padrão',
  defaultBusinessType: 'Tipo de Negócio Padrão',
  defaultType: 'Tipo Padrão',
  defaultQualification: 'Qualificação Padrão',
  defaultTags: 'Tags Padrão',
  submitButtonText: 'Texto do Botão de Envio',
  header: 'Cabeçalho',
  isRequired: 'Obrigatório',
  type: 'Tipo',
  defaultIsPublic: 'Público por Padrão',
  followUpRedirectUrl: 'URL de Redirecionamento de Acompanhamento',
  followUpMessage: 'Mensagem de Acompanhamento',
  specificUsersToNotify: 'Usuários Específicos para Notificar',
  willNotify: 'Notificará',
  willNotifyResponsibleUsers: 'Notificará Usuários Responsáveis',
  leadField: 'Campo de Lead',
  placeholder: 'Espaço Reservado',
  fullNamePlaceholder: 'Digite seu nome completo',
  emailPlaceholder: 'Digite seu email',
  formTitle: 'Formulário',
};

export default locale; 
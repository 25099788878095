const locale = {
  payments: 'Платежи',
  payment: 'Платеж',
  paymentConversions: 'Конвертации Платежей',
  title: 'Платежи',
  create: 'Добавить Платеж',
  update: 'Обновить Платеж',
  delete: 'Удалить Платеж',
  createSuccess: 'Платеж успешно добавлен.',
  updateSuccess: 'Платеж успешно обновлен.',
  deleteSuccess: 'Платеж успешно удален.',
  deleteConfirm: 'Вы уверены, что хотите удалить этот платеж? Это действие нельзя отменить.',
  emptyState1: 'Платежи еще не зарегистрированы.',
  emptyState2:
    'Отслеживайте и управляйте платежами по вашим счетам здесь, чтобы ваши финансы были организованы и актуальны.',

  // detailDrawer
  generalInfoTitle: 'Общая Информация',
  date: 'Дата Платежа',
  amountPaid: 'Оплаченная Сумма',
  confirmationNumber: 'Номер Подтверждения',
  currency: 'Валюта',
};

export default locale; 
const locale = {
  myAccount: "Мой аккаунт",
  profilePicture: "Фото профиля",
  profile: "Профиль",
  name: "Имя",
  surname: "Фамилия",
  birthDate: "Дата рождения",
  gender: "Пол",
  update: "Обновить",
  changeImage: "Изменить изображение",
  change: "Изменить",
  updateProfileSuccess: "Профиль успешно обновлен",
  updateProfilePictureSuccess: "Фото профиля успешно обновлено",
  country: "Страна",
  city: "Город",
  signatureImage: "Изображение подписи",
  twoFactorAuthEmail: "Двухфакторная аутентификация по электронной почте",
};

export default locale; 
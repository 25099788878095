const locale = {
  invoice: "Fatura",
  invoices: "Faturas",
  create: "Criar Fatura",
  update: "Atualizar Fatura",
  delete: "Excluir",
  view: "Visualizar Fatura",
  list: "Listar Faturas",
  createdSuccessfully: "Fatura criada com sucesso",
  updatedSuccessfully: "Fatura atualizada com sucesso",
  deleteConfirm:
    "Tem certeza que deseja excluir? Esta ação não pode ser desfeita.",
  deletedSuccessfully: "Fatura excluída com sucesso",
  createInfo: "Você pode criar novas faturas aqui.",
  prefix: "Prefixo",
  invoiceId: "Fatura",
  timeZone: "Fuso Horário",
  customerId: "Cliente",
  customerName: "Nome do Cliente",
  customerType: "Tipo de Cliente",
  customerCity: "Cidade do Cliente",
  customerCountry: "País do Cliente",
  customerContactEmail: "Email do Cliente",
  customerContactLastName: "Sobrenome do Cliente",
  customerContactFirstName: "Nome do Cliente",
  billingInfo: "Informações de Cobrança",
  ["billingInfo.fullName"]: "Nome Completo",
  ["billingInfo.phone"]: "Telefone",
  ["billingInfo.address"]: "Endereço",
  ["billingInfo.country"]: "País",
  ["billingInfo.state"]: "Estado",
  ["billingInfo.city"]: "Cidade",
  ["billingInfo.street"]: "Rua",
  ["billingInfo.zip"]: "CEP",
  ["billingInfo.vat"]: "CNPJ/CPF",
  ["billingInfo.taxOffice"]: "Escritório Fiscal",
  shippingInfo: "Informações de Envio",
  ["shippingInfo.fullName"]: "Nome Completo",
  ["shippingInfo.phone"]: "Telefone",
  ["shippingInfo.address"]: "Endereço",
  ["shippingInfo.country"]: "País",
  ["shippingInfo.state"]: "Estado",
  ["shippingInfo.city"]: "Cidade",
  ["shippingInfo.street"]: "Rua",
  ["shippingInfo.zip"]: "CEP",
  generalInformation: "Informações Gerais*",
  prospect: "Prospecto*",
  detailedInformation: "Informações Detalhadas",
  billingInformation: "Informações de Cobrança*",
  shippingInformation: "Informações de Envio",
  productsAndServices: "Produtos e Serviços*",
  salesAgents: "Agentes de Vendas",
  legal: "Jurídico",
  signatureTab: "Assinatura*",
  isRecurring: "É Recorrente",
  recurringFrequency: "Frequência de Recorrência",
  recurringDueDate: "Data de Vencimento Recorrente",
  recurringCount: "Número de Recorrências",
  language: "Idioma",
  date: "Data",
  expireDate: "Data de Expiração",
  sendEmailOnExpiration: "Enviar Email na Expiração",
  expirationEmailSentDate: "Data de Envio do Email de Expiração",
  allowedPaymentMethods: "Métodos de Pagamento Permitidos",
  paymentTerms: "Condições de Pagamento",
  currency: "Moeda",
  products: "Produtos",
  addProducts: "Adicionar Produtos",
  offers: "Ofertas",
  reference: "Referência",
  salesAgentIds: "Agentes de Vendas",
  allowComments: "Permitir Comentários",
  useLegalNDA: "Usar Acordo de Confidencialidade",
  legalNDAId: "Acordo de Confidencialidade",
  useLegalTermAndCond: "Usar Termos e Condições",
  legalTermAndCondId: "Termos e Condições",
  signerUserId: "Usuário Assinante",
  listInfo: "Lista de todas as faturas.",
  importedError: "Erro de importação",
  importedSuccessfully: "Importado com sucesso",
  downloadFormError: "Erro ao baixar formulário",
  downloadFormSuccessfully: "Formulário baixado com sucesso",
  downloadExampleFormSuccessfully: "Formulário de exemplo baixado com sucesso",
  downloadExampleFormError: "Erro ao baixar formulário de exemplo",
  title: "Faturas",
  emptyState1: "Nenhuma fatura encontrada aqui.",
  emptyState2: "Você pode criar ou importar faturas imediatamente.",
  createInvoice: "Criar Fatura",
  importInvoice: "Importar Fatura",
  importTitle: "Importar Faturas",
  downloadExample: "Baixar Exemplo",
  importText1: "Você pode importar faturas a partir desta página.",
  importText2: "Você pode usar o formulário de exemplo abaixo para publicar suas faturas.",
  importExport: "Importar/Exportar",
  exportData: "Exportar Dados",
  importData: "Importar Dados",
  b2b: "B2B",
  b2c: "B2C",
  viewInvoice: "Visualizar",
  edit: "Editar",
  junk: "Lixeira",
  unJunk: "Restaurar da Lixeira",
  addNewRole: "Adicionar Novo Papel",
  actions: "Ações",
  allowedPaymentMethodsPlaceholder: "Adicionar Método de Pagamento",
  timezone: "Fuso Horário",
  followUp: "Acompanhamento",
  lastSent: "Último Envio",
  dueDate: "Data de Vencimento",
  sendEmail: "Enviar Email",
  customer: "Cliente",
  country: "País",
  city: "Cidade",
  contactFirstName: "Nome do Contato",
  contactLastName: "Sobrenome do Contato",
  contactEmail: "Email do Contato",
  services: "Serviços",
  businessName: "Nome da Empresa",
  governmentAgency: "Agência Governamental",
  fullName: "Nome Completo",
  VAT: "CNPJ/CPF",
  phoneNumber: "Número de Telefone",
  state: "Estado",
  ZIP: "CEP",
  address: "Endereço",
  sentDate: "Data de Envio",
  name: "Nome",
  description: "Descrição",
  productUnit: "Unidade",
  quantity: "Quantidade",
  price: "Preço",
  priceType: "Tipo de Preço",
  discountedPrice: "Preço com Desconto",
  finalPrice: "Preço Final",
  tax: "Imposto",
  service: "Serviço",
  subTotal: "Subtotal",
  taxTotal: "Total de Impostos",
  discount: "Desconto",
  product: "Produto",
  references: "Referências",
  add: "Adicionar",
  salesAgent: "Agente de Vendas",
  executiveSummary: "Resumo Executivo",
  companyBackground: "Histórico da Empresa",
  projectorServiceDesc: "Descrição Detalhada do Projeto/Serviço",
  costBreakdown: "Detalhamento de Custos",
  projectTimeline: "Cronograma do Projeto",
  testimonialCaseStudies: "Depoimentos e Estudos de Caso",
  conclusion: "Conclusão",

  sentSuccessfully: 'Fatura enviada com sucesso',
  send: 'Enviar Fatura',
  resend: 'Reenviar Fatura',
  sendSureTitle: 'Enviar Fatura',
  sendSureDesc: 'Tem certeza que deseja enviar esta fatura?',
  changeStatus: 'Alterar Status',
  statusUpdatedSuccessfully: 'Status atualizado com sucesso',

  // generalInfo
  generalInfoTitle: 'Informações Gerais',
  status: 'Status',
  createdAt: 'Criado em',
  createdBy: 'Criado por',
  updatedAt: 'Atualizado em',
  notAvailable: 'Não Disponível',
  updateStatus: 'Atualizar Status',

  // customerInfo
  customerInfoTitle: 'Informações do Cliente',
  contactPhoneNumber: 'Número de Telefone',

  //productInfo
  productInfoTitle: 'Detalhes do Produto',
  productNameUnknown: 'Produto Desconhecido',
  noProducts: 'Nenhum produto disponível.',

  //legalInfo
  legalInfoTitle: 'Informações Jurídicas',
  ndaTitle: 'Acordo de Confidencialidade (NDA)',
  termsTitle: 'Termos e Condições',
  documentName: 'Nome do Documento',
  documentDescription: 'Descrição',
  documentContent: 'Conteúdo',

  //financialInfo
  financialInfoTitle: 'Informações Financeiras',
  shippingTotal: 'Total de Envio',
  total: 'Preço Total',
  noFinancialData: 'Nenhum dado financeiro disponível.',

  // documentInfo
  documentsTitle: 'Documentos',
  documentNameUnknown: 'Documento Desconhecido',
  mimeType: 'Tipo de Arquivo',
  download: 'Baixar',
  noDocuments: 'Nenhum documento disponível.',

  // salesAgents
  salesAgentsTitle: 'Agentes de Vendas',
  unknownAgent: 'Agente Desconhecido',
  noSalesAgents: 'Nenhum agente de vendas disponível.',

  //signature
  signatureTitle: 'Informações de Assinatura',
  additionalInfoTitle: 'Informações Adicionais',
  signature: 'Assinatura',
  signerUser: 'Usuário Assinante',

  //additionalInfo
  yes: 'Sim',
  no: 'Não',
};

export default locale; 
const locale = {
  invoice: "Fattura",
  invoices: "Fatture",
  create: "Crea Fattura",
  update: "Aggiorna Fattura",
  delete: "Elimina",
  view: "Visualizza Fattura",
  list: "Elenco Fatture",
  createdSuccessfully: "Fattura creata con successo",
  updatedSuccessfully: "Fattura aggiornata con successo",
  deleteConfirm:
    "Sei sicuro di voler eliminare? Questa azione non può essere annullata.",
  deletedSuccessfully: "Fattura eliminata con successo",
  createInfo: "Qui puoi creare nuove fatture.",
  prefix: "Prefisso",
  invoiceId: "Fattura",
  timeZone: "Fuso Orario",
  customerId: "Cliente",
  customerName: "Nome Cliente",
  customerType: "Tipo Cliente",
  customerCity: "Città Cliente",
  customerCountry: "Paese Cliente",
  customerContactEmail: "Email Cliente",
  customerContactLastName: "Cognome Cliente",
  customerContactFirstName: "Nome Cliente",
  billingInfo: "Informazioni di Fatturazione",
  ["billingInfo.fullName"]: "Nome Completo",
  ["billingInfo.phone"]: "Telefono",
  ["billingInfo.address"]: "Indirizzo",
  ["billingInfo.country"]: "Paese",
  ["billingInfo.state"]: "Stato/Regione",
  ["billingInfo.city"]: "Città",
  ["billingInfo.street"]: "Via",
  ["billingInfo.zip"]: "CAP",
  ["billingInfo.vat"]: "Partita IVA",
  ["billingInfo.taxOffice"]: "Ufficio Fiscale",
  shippingInfo: "Informazioni di Spedizione",
  ["shippingInfo.fullName"]: "Nome Completo",
  ["shippingInfo.phone"]: "Telefono",
  ["shippingInfo.address"]: "Indirizzo",
  ["shippingInfo.country"]: "Paese",
  ["shippingInfo.state"]: "Stato/Regione",
  ["shippingInfo.city"]: "Città",
  ["shippingInfo.street"]: "Via",
  ["shippingInfo.zip"]: "CAP",
  generalInformation: "Informazioni Generali*",
  prospect: "Prospetto*",
  detailedInformation: "Informazioni Dettagliate",
  billingInformation: "Informazioni di Fatturazione*",
  shippingInformation: "Informazioni di Spedizione",
  productsAndServices: "Prodotti e Servizi*",
  salesAgents: "Agenti di Vendita",
  legal: "Legale",
  signatureTab: "Firma*",
  isRecurring: "È Ricorrente",
  recurringFrequency: "Frequenza di Ricorrenza",
  recurringDueDate: "Data di Scadenza Ricorrente",
  recurringCount: "Numero di Ricorrenze",
  language: "Lingua",
  date: "Data",
  expireDate: "Data di Scadenza",
  sendEmailOnExpiration: "Invia Email alla Scadenza",
  expirationEmailSentDate: "Data di Invio Email di Scadenza",
  allowedPaymentMethods: "Metodi di Pagamento Consentiti",
  paymentTerms: "Termini di Pagamento",
  currency: "Valuta",
  products: "Prodotti",
  addProducts: "Aggiungi Prodotti",
  offers: "Offerte",
  reference: "Riferimento",
  salesAgentIds: "Agenti di Vendita",
  allowComments: "Consenti Commenti",
  useLegalNDA: "Usa Accordo di Riservatezza",
  legalNDAId: "Accordo di Riservatezza",
  useLegalTermAndCond: "Usa Termini e Condizioni",
  legalTermAndCondId: "Termini e Condizioni",
  signerUserId: "Utente Firmatario",
  listInfo: "Elenco di tutte le fatture.",
  importedError: "Errore di importazione",
  importedSuccessfully: "Importato con successo",
  downloadFormError: "Errore nel download del modulo",
  downloadFormSuccessfully: "Modulo scaricato con successo",
  downloadExampleFormSuccessfully: "Modulo di esempio scaricato con successo",
  downloadExampleFormError: "Errore nel download del modulo di esempio",
  title: "Fatture",
  emptyState1: "Nessuna fattura trovata qui.",
  emptyState2: "Puoi creare o importare fatture immediatamente.",
  createInvoice: "Crea Fattura",
  importInvoice: "Importa Fattura",
  importTitle: "Importa Fatture",
  downloadExample: "Scarica Esempio",
  importText1: "Puoi importare fatture da questa pagina.",
  importText2: "Puoi utilizzare il modulo di esempio qui sotto per pubblicare le tue fatture.",
  importExport: "Importa/Esporta",
  exportData: "Esporta Dati",
  importData: "Importa Dati",
  b2b: "B2B",
  b2c: "B2C",
  viewInvoice: "Visualizza",
  edit: "Modifica",
  junk: "Cestino",
  unJunk: "Recupera",
  addNewRole: "Aggiungi Nuovo Ruolo",
  actions: "Azioni",
  allowedPaymentMethodsPlaceholder: "Aggiungi Metodo di Pagamento",
  timezone: "Fuso Orario",
  followUp: "Seguito",
  lastSent: "Ultimo Invio",
  dueDate: "Data di Scadenza",
  sendEmail: "Invia Email",
  customer: "Cliente",
  country: "Paese",
  city: "Città",
  contactFirstName: "Nome del Contatto",
  contactLastName: "Cognome del Contatto",
  contactEmail: "Email del Contatto",
  services: "Servizi",
  businessName: "Nome Azienda",
  governmentAgency: "Agenzia Governativa",
  fullName: "Nome Completo",
  VAT: "Partita IVA",
  phoneNumber: "Numero di Telefono",
  state: "Stato/Regione",
  ZIP: "CAP",
  address: "Indirizzo",
  sentDate: "Data di Invio",
  name: "Nome",
  description: "Descrizione",
  productUnit: "Unità",
  quantity: "Quantità",
  price: "Prezzo",
  priceType: "Tipo di Prezzo",
  discountedPrice: "Prezzo Scontato",
  finalPrice: "Prezzo Finale",
  tax: "Imposta",
  service: "Servizio",
  subTotal: "Subtotale",
  taxTotal: "Totale Imposte",
  discount: "Sconto",
  product: "Prodotto",
  references: "Riferimenti",
  add: "Aggiungi",
  salesAgent: "Agente di Vendita",
  executiveSummary: "Riepilogo Esecutivo",
  companyBackground: "Background Aziendale",
  projectorServiceDesc: "Descrizione Dettagliata del Progetto/Servizio",
  costBreakdown: "Ripartizione dei Costi",
  projectTimeline: "Tempistica del Progetto",
  testimonialCaseStudies: "Testimonianze e Casi Studio",
  conclusion: "Conclusione",

  sentSuccessfully: 'Fattura inviata con successo',
  send: 'Invia Fattura',
  resend: 'Rinvia Fattura',
  sendSureTitle: 'Invia Fattura',
  sendSureDesc: 'Sei sicuro di voler inviare questa fattura?',
  changeStatus: 'Cambia Stato',
  statusUpdatedSuccessfully: 'Stato aggiornato con successo',

  // generalInfo
  generalInfoTitle: 'Informazioni Generali',
  status: 'Stato',
  createdAt: 'Creato il',
  createdBy: 'Creato da',
  updatedAt: 'Aggiornato il',
  notAvailable: 'Non Disponibile',
  updateStatus: 'Aggiorna Stato',

  // customerInfo
  customerInfoTitle: 'Informazioni Cliente',
  contactPhoneNumber: 'Numero di Telefono',

  //productInfo
  productInfoTitle: 'Dettagli Prodotto',
  productNameUnknown: 'Prodotto Sconosciuto',
  noProducts: 'Nessun prodotto disponibile.',

  //legalInfo
  legalInfoTitle: 'Informazioni Legali',
  ndaTitle: 'Accordo di Riservatezza (NDA)',
  termsTitle: 'Termini e Condizioni',
  documentName: 'Nome Documento',
  documentDescription: 'Descrizione',
  documentContent: 'Contenuto',

  //financialInfo
  financialInfoTitle: 'Informazioni Finanziarie',
  shippingTotal: 'Totale Spedizione',
  total: 'Prezzo Totale',
  noFinancialData: 'Nessun dato finanziario disponibile.',

  // documentInfo
  documentsTitle: 'Documenti',
  documentNameUnknown: 'Documento Sconosciuto',
  mimeType: 'Tipo di File',
  download: 'Scarica',
  noDocuments: 'Nessun documento disponibile.',

  // salesAgents
  salesAgentsTitle: 'Agenti di Vendita',
  unknownAgent: 'Agente Sconosciuto',
  noSalesAgents: 'Nessun agente di vendita disponibile.',

  //signature
  signatureTitle: 'Informazioni Firma',
  additionalInfoTitle: 'Informazioni Aggiuntive',
  signature: 'Firma',
  signerUser: 'Utente Firmatario',

  //additionalInfo
  yes: 'Sì',
  no: 'No',
};

export default locale; 
const locale = {
  somethingWentWrong: 'Quelque chose s\'est mal passé !',
  loginSuccess: 'Connexion réussie',
  signinText: 'Connectez-vous.',
  emailAddress: 'Adresse e-mail',
  password: 'Mot de passe',
  forgotPassword: 'Mot de passe oublié ?',
  login: 'Se connecter',
  continueWith: 'ou continuer avec',
  noAccount: 'Vous n\'avez pas de compte ?',
  signUp: 'S\'inscrire',
  logoAlt: 'logo',
  growYourBusiness: 'Tout ce dont vous avez besoin pour développer votre',
  business: 'Entreprise',
  bgPattern: 'motif de fond',
  manageYourBusiness:
    'Gérez votre entreprise efficacement, augmentez vos ventes, atteignez des clients potentiels et développez-vous en rejoignant la communauté.',

  quote1: 'Vos clients les plus mécontents sont votre plus grande source d\'apprentissage.',
  quoteName1: 'Bill Gates',
  quoteCompany1: 'Microsoft',
  quoteImage1: 'Bill-Gates',

  quote2: 'Le service client ne devrait pas être un département. Ce devrait être toute l\'entreprise.',
  quoteName2: 'Tony Hsieh',
  quoteCompany2: 'Zappos',
  quoteImage2: 'Tony-Hsieh',

  quote3: 'La seule raison pour laquelle nous sommes en affaires est de rendre la vie moins difficile pour nos clients.',
  quoteName3: 'Matthew Odgers',
  quoteCompany3: 'Odgers Law Group',
  quoteImage3: 'Matthew-Odgers',

  quote4: 'Si vous ne prenez pas soin de votre client, votre concurrent le fera.',
  quoteName4: 'Bob Hooey',
  quoteCompany4: 'Ideaman',
  quoteImage4: 'Bob-Hooey',

  quote5: 'En vente, une référence est la clé de la porte de la résistance.',
  quoteName5: 'Bo Bennett',
  quoteCompany5: 'Archieboy',
  quoteImage5: 'Bo-Bennett',

  quote6: 'Faites toujours de votre mieux. Ce que vous plantez maintenant, vous le récolterez plus tard.',
  quoteName6: 'Og Mandino',
  quoteCompany6: 'Author',
  quoteImage6: 'Og-Mandino',

  quote7: 'L\'un des plus beaux cadeaux que vous puissiez faire à quelqu\'un est le cadeau de votre attention.',
  quoteName7: 'Jim Rohn',
  quoteCompany7: 'Author',
  quoteImage7: 'Jim-Rohn',

  quote8: 'Faites ce que vous faites si bien qu\'ils voudront le revoir et amener leurs amis.',
  quoteName8: 'Walt Disney',
  quoteCompany8: 'Disney',
  quoteImage8: 'Walt-Disney',

  passwordResetSuccess: 'Votre mot de passe a été réinitialisé avec succès !',
  resetPasswordTitle: 'Réinitialiser le mot de passe',
  enterNewPassword: 'Veuillez entrer votre nouveau mot de passe.',
  passwordAgain: 'Confirmer le mot de passe',
  resetPassword: 'Réinitialiser le mot de passe',
  alreadyHaveAccount: 'Vous avez déjà un compte ?',
  resetPasswordRequestSuccess: 'Demande de réinitialisation du mot de passe réussie',
  forgotPasswordTitle: 'Mot de passe oublié',
  resetPasswordInstructions: 'Vous pouvez réinitialiser votre mot de passe en entrant votre adresse e-mail.',
  rememberPassword: 'Vous vous souvenez de votre mot de passe ?',
  userConfirmedSuccess: 'Utilisateur confirmé avec succès !',
  emailConfirmationTitle: 'Confirmation d\'e-mail',
  TwoFAConfirmationTitle: 'Confirmation 2FA',
  enterOtp: 'Veuillez entrer le code OTP envoyé à votre e-mail.',
  submit: 'Vérifier',
  didNotReceiveCode: 'Vous n\'avez pas reçu le code ?',
  resendCode: 'Renvoyer le code',
  resendCodeTitle: 'Renvoyer le code de confirmation',
  enterEmailToResend: 'Veuillez entrer votre adresse e-mail pour recevoir le code de confirmation.',
  email: 'E-mail',
  close: 'Fermer',
  send: 'Envoyer',
  signupSuccess: 'Inscription réussie',
  letsRegister: 'Inscrivons-vous.',
  name: 'Prénom',
  surname: 'Nom',
  iAccept: 'J\'accepte les',
  and: 'et',
  with: 'et',
  termsAndConditions: 'Conditions générales',
  privacyPolicy: 'Politique de confidentialité',
  explicitConsent: 'Politique de consentement explicite',
  kvkkPolicy: 'Politique d\'information KVKK',
  orContinueWith: 'ou continuer avec',
  createPassword: 'Créer un mot de passe',
  createPasswordTitle: 'Créer un mot de passe',
  signupSuccessfullTitle: 'Inscription réussie !',
  signupSuccessfullDescription: 'Veuillez vérifier votre e-mail pour confirmer votre compte.',
  backToLogin: 'Retour à la connexion',
  resendConfirmationSuccessTitle: 'Code de confirmation renvoyé avec succès',
  resendConfirmationSuccessText: 'Veuillez vérifier votre e-mail pour confirmer votre compte.',
  resentCodeSuccess: 'Code renvoyé avec succès',
  acceptAllPolicies: 'Veuillez accepter toutes les politiques !',
  alreadyRegisteredError: 'Vous êtes déjà inscrit ! Veuillez vous connecter.',
  userNotFoundError: 'Utilisateur non trouvé !',

  proposal: 'Proposition',
  estimate: 'Devis',
  invoice: 'Facture',
  startDate: 'Date de début',
  expireDate: 'Date d\'expiration',
  deadline: 'Date limite',
  proposalInformation: 'Informations sur la proposition',
  estimateInformation: 'Informations sur le devis',
  invoiceInformation: 'Informations sur la facture',
  billTo: 'Facturer à',
  bankName: 'Nom de la banque',
  country: 'Pays',
  iban: 'IBAN',
  currency: 'Devise',
  productDescription: 'Description du produit',
  qty: 'QTÉ',
  rate: 'Taux',
  tax: 'Taxe',
  amount: 'Montant',
  projectTimeline: 'Calendrier du projet',
  timeline: 'Calendrier',
  accept: 'Accepter',
  decline: 'Refuser',
  download: 'Télécharger',
  comments: 'Commentaires',
  documents: 'Documents',
  uploadDocument: 'Télécharger un document',
  addDocument: 'Ajouter un document',

  estimateAccepted: 'Devis accepté',
  estimateDeclined: 'Devis refusé',
  proposalAccepted: 'Proposition acceptée',
  proposalDeclined: 'Proposition refusée',
  invoiceAccepted: 'Facture acceptée',
  invoiceDeclined: 'Facture refusée',
  declineReason: 'Motif du refus',
  declineReasonPlaceholder: 'Veuillez entrer le motif du refus'
};

export default locale;

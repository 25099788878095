const locale = {
  payments: 'Zahlungen',
  payment: 'Zahlung',
  paymentConversions: 'Zahlungsumrechnungen',
  title: 'Zahlungen',
  create: 'Zahlung hinzufügen',
  update: 'Zahlung aktualisieren',
  delete: 'Zahlung löschen',
  createSuccess: 'Zahlung erfolgreich hinzugefügt.',
  updateSuccess: 'Zahlung erfolgreich aktualisiert.',
  deleteSuccess: 'Zahlung erfolgreich gelöscht.',
  deleteConfirm: 'Sind Sie sicher, dass Sie diese Zahlung löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  emptyState1: 'Noch keine Zahlungen erfasst.',
  emptyState2:
    'Verfolgen und verwalten Sie hier Zahlungen für Ihre Rechnungen, um Ihre Finanzen organisiert und aktuell zu halten.',

  // detailDrawer
  generalInfoTitle: 'Allgemeine Informationen',
  date: 'Zahlungsdatum',
  amountPaid: 'Gezahlter Betrag',
  confirmationNumber: 'Bestätigungsnummer',
  currency: 'Währung',
};

export default locale; 
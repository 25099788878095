import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { List } from '../pages';
import { UserDetailComponent } from '../pages/detail/UserDetail';

import { UsersLayout } from '../layouts/UsersLayout';

export const UsersRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersLayout />}>
        <Route index element={<List />} />
        <Route path="/:id" element={<UserDetailComponent />} />
      </Route>
    </Routes>
  );
};

const locale = {
  appointments: 'Встречи',
  appointment: 'Встреча',
  appointmentConversions: 'Конверсии встреч',
  title: 'Встречи',
  create: 'Создать встречу',
  update: 'Обновить встречу',
  delete: 'Удалить встречу',
  details: 'Детали встречи',
  createSuccess: 'Встреча успешно создана.',
  updateSuccess: 'Встреча успешно обновлена.',
  emptyState1: 'Пока не запланировано ни одной встречи.',
  emptyState2:
    'Настраивайте и управляйте своими встречами здесь, чтобы оставаться организованным и беспрепятственно общаться с клиентами.',
  statusChangeSuccess: 'Статус встречи успешно изменен.',
  deleteConfirm: 'Вы уверены, что хотите удалить эту встречу?',
  deletedSuccessfully: 'Встреча успешно удалена.',

  //fields
  startTime: 'Время начала',
  endTime: 'Время окончания',
  timezone: 'Часовой пояс',
  location: 'Место',
  organizer: 'Организатор',
  targetType: 'Тип цели',
  attendees: 'Участники',
  category: 'Категория',
  meetingLink: 'Ссылка на встречу',
  duration: 'Продолжительность',

  //detail drawer
  changeStatus: 'Изменить статус',
  generalInfoTitle: 'Общая информация',
  organizerInfoTitle: 'Информация об организаторе',
  appointmentInfoTitle: 'Информация о встрече',
};

export default locale; 
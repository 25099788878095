import customerLocale from "../subdomains/customer/locales/ru";
import customerCategoryLocale from "../subdomains/customerCategory/locales/ru";
import contactLocale from "../subdomains/contact/locales/ru";

const locale = {
  customer: {
    ...customerLocale,
  },
  contact: {
    ...contactLocale,
  },
  customerCategory: {
    ...customerCategoryLocale,
  },
};

export default locale; 
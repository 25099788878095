const locale = {
  tasks: "Attività",
  task: "Attività",
  taskConversions: "Conversioni di attività",
  title: "Attività",
  addTask: "Aggiungi attività",
  emptyState1: "Nessuna attività assegnata ancora.",
  emptyState2: "Crea attività qui per organizzare il tuo lavoro, impostare priorità e rimanere al passo con le tue attività CRM.",
  create: "Crea attività",
  subject: "Oggetto",
  description: "Descrizione",
  startDate: "Data di inizio",
  dueDate: "Data di scadenza",
  assigneeIds: "Assegnatari",
  followerIds: "Follower",
  checklist: "Lista di controllo",
  department: "Dipartimento",
  priority: "Priorità",
  tags: "Tag",
  hourlyRate: "Tariffa oraria",
  hourlyRateCurrency: "Valuta della tariffa oraria",
  isBillable: "Fatturabile",
  isRecurrent: "Ricorrente",
  repeatDays: "Giorni di ripetizione",
  totalCycle: "Ciclo totale",
  addChecklistItem: "Aggiungi elemento alla lista",
  noAssigneeToCreateChecklist: "Assegna utenti per creare liste di controllo",
  assignee: "Assegnatario",
  createTaskSuccess: "Attività creata con successo.",
  deleteTask: "Elimina attività",
  deleteTaskContent: "Sei sicuro di voler eliminare questa attività? Questa azione non può essere annullata.",
  deletedSuccessfully: "Attività eliminata con successo",
  updateTask: "Aggiorna attività",
  updateSuccess: "Attività aggiornata con successo.",

  detail: "Dettaglio attività",
  subtask: "Sottoattività",

  addSubtask: "Aggiungi sottoattività",
  updateStatus: "Aggiorna stato",

  createdBy: "Attività creata da ",

  // detailDrawer
  detailedInfo: "Informazioni dettagliate",
  recurrentInfo: "Informazioni sulla ricorrenza",
  billableInfo: "Informazioni sulla fatturazione",
  documents: "Documenti",
  editAssignees: "Modifica assegnatari",
};

export default locale; 
const locale = {
  permissions: 'Permissões',
  roles: 'Funções',
  createRole: 'Criar Função',
  rolesInfo: 'Você pode criar funções e atribuir permissões a elas.',
  roleName: 'Nome da Função',
  roleDetail: 'Detalhe da Função',
  addRole: 'Adicionar',
  updateRole: 'Atualizar',
  deleteRole: 'Excluir',
  roleCreatedSuccess: 'Função criada com sucesso',
  roleUpdatedSuccess: 'Função atualizada com sucesso',
  roleDeletedSuccess: 'Função excluída com sucesso',
  errorFetchingRoles: 'Erro ao buscar funções',
  errorCreatingRole: 'Erro ao criar função',
  errorUpdatingRole: 'Erro ao atualizar função',
  errorDeletingRole: 'Erro ao excluir função',
  capabilities: 'Capacidades',
  advancedOptions: 'Opções Avançadas',
  addNewRole: 'Adicionar Nova Função',
  rolesAndPermissions: 'Funções e Permissões',
  action: 'Ação',
  actionScope: 'Escopo da Ação',
  deleteConfirm: 'Tem certeza de que deseja remover a função ',
  deletedSuccessfully: "Função excluída com sucesso",
};

export default locale; 
import settingsLocale from "../domains/settings/locales/ru";

const locale = {
  settings: {
    ...settingsLocale,
  },
  demoo: "Запрос на коммерческое предложение",
  settingsLabel: "Настройки",
  paymentSettings: "Оплата",
  menuSettings: "Настройки меню",
  crmSettings: "CRM",
  leadPlusSettings: "Lead Plus",
  marketingSettings: "Маркетинг",
  windowSettings: "Окно",
  communitySettings: "Сообщество",
  notificationSettings: "Уведомления",
  organizationSettings: "Организация",
  myOrganization: "Моя организация",
  generalSettings: "Общие",
  moduleSettings: "Настройки модулей",
  userSettings: "Настройки пользователя",
  integrations: "Интеграции",
  legalAgreements: "Юридические соглашения",
};

export default locale; 
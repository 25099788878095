const locale = {
  // fields
  users: "Напомнить пользователям",
  date: "Дата напоминания",
  title: "Список напоминаний",
  detail: "Детали напоминания",
  sharedList: "Общие напоминания",
  privateList: "Личные напоминания",
  ownList: "Мои напоминания",
  allList: "Все напоминания",
  reminder: "Напоминание",

  // actions
  create: "Создать напоминание",
  add: "Добавить напоминание",
  update: "Обновить напоминание",
  delete: "Удалить напоминание",

  // situational text
  createSuccess: "Напоминание успешно создано",
  updateSuccess: "Напоминание успешно обновлено",
  deletedSuccessfully: "Напоминание успешно удалено",
  deleteContent: "Вы уверены, что хотите удалить это напоминание? Это действие нельзя отменить.",
  emptyState1: "Напоминания еще не добавлены.",
  emptyState2: "Храните и управляйте всеми деталями напоминаний здесь, чтобы упростить выставление счетов и поддерживать четкие соглашения с клиентами.",

  // detail drawer
  changeStatus: "Изменить статус",
  generalInfoTitle: "Общая информация",
  remindUsers: "Напомнить пользователям",
  remindDate: "Дата напоминания",
  description: "Описание",
  status: "Статус",
  createdBy: "Создано",
  schedule: "Запланировать",
  cancelSuccess: "Напоминание успешно отменено",
  completeSuccess: "Напоминание успешно завершено",
  scheduleSuccess: "Напоминание успешно запланировано",
};

export default locale; 
const locale = {
  permissions: "Berechtigungen",
  errorFetchingPermissions: "Fehler beim Abrufen der Berechtigungen",
  allPermissions: "Alle Berechtigungen",
  all: "Alle",
  ownPermissionsAlert: "Sie können Ihre eigenen Berechtigungen nicht ändern.",
  action: "Aktion",
  actionScope: "Aktionsbereich",
  updateSuccess: "Berechtigung erfolgreich aktualisiert",
};

export default locale; 
const locale = {
  leadStatuses: "Статусы лидов",
  title: "Статусы лидов",
  create: "Создать статус лида",
  delete: "Удалить статус лида",
  deleteConfirm: "Вы уверены, что хотите удалить этот статус: ",
  deletedSuccessfully: "Статус лида успешно удален",
  createSuccess: "Статус лида успешно создан",
  update: "Обновить статус лида",
  updateSuccess: "Статус лида успешно обновлен",
  selectColor: "Выбрать цвет",
  selectIcon: "Выбрать иконку",
};

export default locale; 
const locale = {
  product: 'Продукты',
  title: 'Продукты',
  create: 'Создать Продукт',
  update: 'Обновить Продукт',
  delete: 'Удалить Продукт',
  deleteConfirm: 'Вы уверены, что хотите удалить этот статус: ',
  deletedSuccessfully: 'Продукт успешно удален',
  createProduct: 'Создать Продукт',
  createSuccess: 'Продукт успешно создан',
  updateSuccess: 'Продукт успешно обновлен',
  name: 'Название',
  icon: 'Иконка',
  showMore: 'Показать Больше',
  detail: 'Детали Продукта',
  type: 'Тип',
  image: 'Изображение',
  sku: 'Артикул',
  description: 'Описание',
  currency: 'Валюта',
  price: 'Цена',
  priceType: 'Тип Цены',
  discountedPrice: 'Цена со Скидкой',
  unit: 'Единица',
  categoryId: 'Категория',
  natTaxRate: 'Национальная Налоговая Ставка',
  intTaxRate: 'Международная Налоговая Ставка',
  unitPrice: 'Цена за Единицу',
  categories: 'Категории',

  emptyState1: 'Пока нет зарегистрированных продуктов.',
  emptyState2:
    'Управляйте и просматривайте любые продукты здесь, чтобы поддерживать точность ваших записей и удовлетворять потребности клиентов.',
};

export default locale; 
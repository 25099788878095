import React from 'react';

import { ExpenseDomainLayout } from '../layouts';
import { ExpenseRouter } from '../subdomains/expense/routes/ExpenseRouter';
import { Route, Routes } from 'react-router-dom';
import { expenseDomainRoutes } from './expense.base.route';
import { commonRoutes } from 'routes/route.common';
import Import from '../subdomains/expense/pages/Import';

export const ExpenseDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ExpenseDomainLayout />}>
        <Route index element={<ExpenseRouter />} />
        <Route path="*" element={<ExpenseRouter />} />
        <Route path={`${expenseDomainRoutes.subdomains.expense.base}/*`} element={<ExpenseRouter />} />
        <Route path={`${expenseDomainRoutes.subdomains.expense.base}/${commonRoutes.import}`} element={<Import />} />
      </Route>
    </Routes>
  );
};

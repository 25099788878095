const locale = {
  // fields
  users: "Rappeler aux utilisateurs",
  date: "Date de rappel",
  title: "Liste des rappels",
  detail: "Détail du rappel",
  sharedList: "Rappels partagés",
  privateList: "Rappels privés",
  ownList: "Mes rappels",
  allList: "Tous les rappels",
  reminder: "Rappel",

  // actions
  create: "Créer un rappel",
  add: "Ajouter un rappel",
  update: "Mettre à jour le rappel",
  delete: "Supprimer le rappel",

  // situational text
  createSuccess: "Rappel créé avec succès",
  updateSuccess: "Rappel mis à jour avec succès",
  deletedSuccessfully: "Rappel supprimé avec succès",
  deleteContent: "Êtes-vous sûr de vouloir supprimer ce rappel ? Cette action ne peut pas être annulée.",
  emptyState1: "Aucun rappel n'a encore été ajouté.",
  emptyState2: "Stockez et gérez ici tous les détails des rappels pour simplifier votre facturation et maintenir des accords clairs avec vos clients.",

  // detail drawer
  changeStatus: "Changer le statut",
  generalInfoTitle: "Informations générales",
  remindUsers: "Utilisateurs à rappeler",
  remindDate: "Date de rappel",
  description: "Description",
  status: "Statut",
  createdBy: "Créé par",
  schedule: "Planifier",
  cancelSuccess: "Rappel annulé avec succès",
  completeSuccess: "Rappel terminé avec succès",
  scheduleSuccess: "Rappel planifié avec succès",
};

export default locale; 
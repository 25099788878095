/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Stack, Typography } from '@mui/material';
import { ILeadDetailResult } from 'corede-common-cocrm';

interface ILeadDetailProfileSectionComponent {
  leadData: ILeadDetailResult | undefined;
}

export const LeadDetailProfileSectionComponent = (props: ILeadDetailProfileSectionComponent) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'end'}
      gap={1}
      sx={{
        mt: '-54px',
      }}
    >
      <Avatar
        sx={{
          width: '108px',
          height: '108px',
          ml: 2,
          border: '5px solid #fff',
          objectFit: 'cover',
          fontSize: '40px',
        }}
        alt={props.leadData?.fullName}
        src={props.leadData?.image?.thumbnailPublicUrl}
      >
        {props.leadData?.fullName
          ?.split(' ')
          ?.map((n) => n[0])
          .join('')
          .toUpperCase()}
      </Avatar>
      <Stack direction={'column'}>
        <Typography variant="h5">{props.leadData?.fullName} </Typography>
        <Typography variant="subtitle2" color={'text.secondary'}>
          {props.leadData?.department?.name}{' '}
        </Typography>
      </Stack>
    </Stack>
  );
};

const locale = {
  title: "Abteilungen",
  delete: "Löschen",
  deleteConfirm: "Sind Sie sicher, dass Sie diese Abteilung löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  deletedSuccessfully: "Abteilung erfolgreich gelöscht",
  create: "Abteilung erstellen",
  departmentNameLabel: "Abteilungsname",
  rolesLabel: "Rollen",
  addRole: "Rolle hinzufügen",
  createDepartmentButton: "Abteilung erstellen",
  createSuccess: "Abteilung erfolgreich erstellt",
  columnId: "#",
  columnName: "Name",
  columnRoles: "Rollen",
  columnActions: "Aktionen",
  headerTitle: "Abteilungen",
  createDepartment: "Abteilung erstellen",
  rolesButton: "Rollen",
  emptyState1: "Keine Abteilungen gefunden",
  emptyState2: "Bitte erstellen Sie eine neue Abteilung",
  addNewRole: "Neue Rolle hinzufügen",
  updateDepartmentTitle: "Abteilung aktualisieren",
  updateDepartmentButton: "Aktualisieren",
  updateSuccess: "Abteilung erfolgreich aktualisiert",
};

export default locale; 
const locale = {
  leadSources: "Sources de Prospects",
  title: "Sources de Prospects",
  create: "Créer une Source de Prospect",
  delete: "Supprimer une Source de Prospect",
  deleteConfirm: "Êtes-vous sûr de vouloir supprimer cette source : ",
  deletedSuccessfully: "Source de prospect supprimée avec succès",
  createSuccess: "Source de prospect créée avec succès",
  updateSuccess: "Source de prospect mise à jour avec succès",
  update: "Mettre à jour la Source de Prospect",
  updateLeadSourceSuccess: "Source de prospect mise à jour avec succès",
  selectColor: "Sélectionner une Couleur",
  selectIcon: "Sélectionner une Icône",
};

export default locale; 
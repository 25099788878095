const locale = {
  demo: "Demo",
  title: "Was ist <highlight>CoCRM</highlight> Lead+?",
  description:
    "Bei CoCRM unterstützen wir Sie nicht nur bei der Verwaltung Ihrer Geschäfts- und Kundenprozesse, sondern helfen Ihnen auch gerne dabei, neue Kunden zu gewinnen.",
  featuresTitle: "Mit CoCRM Lead+,",
  features1:
    "Wir freuen uns, Ihnen die benötigten potenziellen Kundendaten basierend auf",
  features2:
    "Den Länder-, Stadt- und/oder Branchenparametern, die Sie für Marketing anvisieren.",
  features3: "Die spezifischen Bereiche, die Sie für Ihre Verkaufsziele definiert haben.",
  features4: "Die Märkte, in denen Sie die Markenbekanntheit steigern möchten.",
  buttonTitle: "Angebot anfordern",
  formDescription:
    "Bitte füllen Sie das untenstehende Formular aus und wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.",
  formTitle: "Angebot anfordern",
  formButtonTitle: "Absenden",
  sendSuccess: "Vielen Dank! Wir werden uns so schnell wie möglich bei Ihnen melden.",
  fullName: "Vollständiger Name",
  email: "E-Mail",
  phone: "Telefon",
  orgName: "Organisationsname",
};

export default locale; 
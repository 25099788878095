const locale = {
  contacts: 'Contacts',
  title: 'Contacts',
  createContact: 'Créer un Contact',
  columnId: '#',
  columnName: 'Nom',
  columnIcon: 'Icône',
  columnDepartment: 'Département',
  columnParentCategory: 'Catégorie Parente',
  columnActions: 'Actions',
  emptyStateContent1: 'Aucun contact trouvé',
  emptyStateContent2: 'Veuillez créer un nouveau contact',
  selectIconTitle: 'Sélectionner une Icône',
  searchIcons: 'Rechercher des Icônes',
  relatedContacts: 'Contacts Associés',
  b2bB2c: 'B2B / B2C',
  createContactTitle: 'Créer un Contact',
  contactNameLabel: 'Nom du Contact',
  contactEmailLabel: 'Email du Contact',
  contactPhoneLabel: 'Téléphone du Contact',
  contactTypeLabel: 'Type de Contact',
  contactPictureLabel: 'Photo du Contact',
  departmentLabel: 'Département',
  categoryLabel: 'Catégorie',
  contactLanguageLabel: 'Langue du Contact',
  countryLabel: 'Pays',
  stateLabel: 'État',
  cityLabel: 'Ville',
  uploadPictureText: 'Télécharger une photo',
  createContactButton: 'Créer un Contact',
  createSuccess: 'Contact créé avec succès',
  delete: 'Supprimer',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce contact ? Cette action ne peut pas être annulée.',
  deletedSuccessfully: 'Contact supprimé avec succès',
  cancel: 'Annuler',
  id: '#',
  firstName: 'Prénom',
  lastName: 'Nom',
  email: 'Email',
  type: 'Type',
  phone: 'Téléphone',
  position: 'Poste',
  language: 'Langue',
  update: 'Mettre à Jour le Contact',
  updateContactButton: 'Mettre à Jour le Contact',
  customerId: 'Sélectionner un Client',
  primary: 'Principal',
  customer: 'Client',
  actions: 'Actions',
  create: 'Créer un Contact',
  emptyState1: 'Aucun contact trouvé ici.',
  emptyState2: 'Vous pouvez créer ou importer des contacts immédiatement.',
  deletePrimaryError: 'Le contact principal ne peut pas être supprimé.',
  updateSuccess: 'Contact mis à jour avec succès',
};

export default locale; 
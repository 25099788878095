const locale = {
  demo: 'Demo',
  title: '¿Qué es la <highlight>CoCRM</highlight> Community?',
  description:
    'En CoCRM, además de proporcionarte una gestión eficaz de tus procesos empresariales y de clientes, también ofrecemos un espacio de colaboración diseñado para expandir tu red y oportunidades de negocio.',
  featuresTitle: 'Con la Comunidad CoCRM, puedes:',
  features1: 'Obtener nuevas oportunidades de negocio a través de referencias.',
  features2: 'Conectar con proveedores confiables.',
  features3: 'Encontrar socios comerciales de confianza.',
  features4: 'Ampliar tu red para ofrecer servicios de 360° a tus clientes.',
  buttonTitle: 'Únete a la Comunidad',
  formTitle: 'Únete a la Lista de Espera',
  formDescription:
    'Por favor, completa el formulario a continuación y nos pondremos en contacto contigo lo antes posible.',
  sendSuccess: '¡Gracias! Nos pondremos en contacto contigo lo antes posible.',
  formButtonTitle: 'Unirse',
  fullName: 'Nombre Completo',
  email: 'Correo Electrónico',
  phone: 'Teléfono',
  orgName: 'Nombre de la Organización',
};

export default locale;

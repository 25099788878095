const locale = {
  organization: 'Организация',
  organizations: 'Организации',
  organizationChart: 'Организационная структура',
  update: 'Обновить',
  delete: 'Удалить',
  view: 'Просмотр',
  createdSuccessfully: 'Пользователь успешно создан',
  updatedSuccessfully: 'Пользователь успешно обновлен',
  deleteConfirm: 'Вы уверены, что хотите удалить учетную запись пользователя "',
  deletedSuccessfully: 'Пользователь успешно удален',
  info: 'Вы можете добавлять пользователей в свою команду из этого раздела.',
  name: 'Имя',
  email: 'Электронная почта',
  surname: 'Фамилия',
  viewProfile: 'Просмотр профиля',
  roles: 'Роли',
  editChart: 'Редактировать структуру',
  save: 'Сохранить',
  editOrganizationChart: 'Редактировать организационную структуру',
  editOrganizationDetail: 'Перетащите пользователей в нужное место.',
  createRole: 'Создать роль',
  userList: 'Список пользователей',
  rolesInfo: 'Вы можете создавать роли и назначать им разрешения.',
  createUserSuccess: 'Пользователь успешно создан',
  chart: 'Структура',
  list: 'Список',
  users: 'Пользователи',
  user: 'Пользователь',
  activeUsers: 'Активные пользователи',
  activeUser: 'Активный пользователь',
  create: 'Создать',
  new: 'Новый',
  roleName: 'Название роли',
  addRole: 'Добавить',
  updateRole: 'Обновить',
  deleteRole: 'Удалить',
  roleCreatedSuccess: 'Роль успешно создана',
  roleUpdatedSuccess: 'Роль успешно обновлена',
  roleDeletedSuccess: 'Роль успешно удалена',
  errorFetchingPermissions: 'Ошибка при получении разрешений',
  errorFetchingRoles: 'Ошибка при получении ролей',
  errorCreatingRole: 'Ошибка при создании роли',
  errorUpdatingRole: 'Ошибка при обновлении роли',
  errorDeletingRole: 'Ошибка при удалении роли',
  permissionUpdateSuccess: 'Разрешения пользователя успешно обновлены',
  subject: 'Тема',
  capabilities: 'Возможности',
  allPermissions: 'Все разрешения',
  all: 'Все',
  advancedOptions: 'Расширенные настройки',
  id: 'ID',
  emailAddress: 'Адрес электронной почты',
  status: 'Статус',
  emailVerified: 'Электронная почта подтверждена',
  phoneNumber: 'Номер телефона',
  country: 'Страна',
  city: 'Город',
  phone: 'Номер телефона',
  lastLoginDate: 'Дата последнего входа',
  createdAt: 'Дата создания',
  actions: 'Действия',
  addNewRole: 'Добавить новую роль',
  yes: 'Да',
  no: 'Нет',
  details: 'Детали',
  jobTitle: 'Должность',
  deleteMessage: 'Вы уверены, что хотите удалить этого пользователя?',
  cancel: 'Отмена',
  userDeleteSuccess: 'Пользователь успешно удален',
  userDeleteError: 'Ошибка при удалении пользователя',
  updateChartSuccessfully: 'Организационная структура успешно обновлена',
  address: 'Адрес',
  birthDate: 'Дата рождения',
  gender: 'Пол',
  language: 'Язык',
  description: 'Описание',
  rolesAndPermissions: 'Роли и разрешения',
  ownPermissionsAlert: 'Вы не можете изменить свои собственные разрешения.',
  zoom: 'Масштаб',
  tree: 'Древовидная структура',
  createSuccess: 'Организационная структура успешно создана',
  updateSuccess: 'Организационная структура успешно обновлена',
  updateError: 'Ошибка при обновлении организационной структуры',
  fetchError: 'Ошибка при получении организационной структуры',
  fetchSuccess: 'Организационная структура успешно получена',
  undefinedUser: 'Неопределенный пользователь',
  chartUpdateSuccess: 'Структура успешно обновлена!',
  listView: 'Список',
  action: 'Действие',
  actionScope: 'Область действия',
  userDetail: 'Детали пользователя',
  admin: 'Администратор',
  department: 'Отдел',
  addUser: 'Добавить пользователя',
  createDepartment: 'Создать отдел',
  updateUser: 'Обновить пользователя',
  notDeleteOwnConfirm: 'Вы не можете удалить самого себя.',
  updateUserSuccess: 'Пользователь успешно обновлен',
  removeAccount: 'Удалить учетную запись',
  profile: 'Профиль',
  permissions: 'Разрешения',
  associatedLeads: 'Связанные лиды',
  associatedCustomers: 'Связанные клиенты',
  associatedEstimates: 'Связанные сметы',
  associatedProposals: 'Связанные предложения',
  associatedInvoices: 'Связанные счета',
  associatedTasks: 'Связанные задачи',
  about: 'О пользователе',
  detail: 'Детали пользователя',
  users_: 'пользователи',
  resendConfirmation: 'Повторно отправить подтверждение',
  resendSuccess: 'Подтверждение успешно отправлено',
};

export default locale; 
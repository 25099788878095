import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Box, Typography, Stack, Paper } from '@mui/material';
import { Column, Id, Task } from './TaskList-kanban.component';
import { useSortable, SortableContext } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TaskCard from './TaskList-kanban-taskCard.component';
import { useTranslation } from 'react-i18next';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { ITask, PermissionSubject, TaskStatus } from 'corede-common-cocrm';
import { OutlinedButton } from 'components';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionAction } from 'corede-common';
import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface Props {
  column: Column;
  tasks: Task[];
  updateTask: (id: Id, content: string) => void;
  setSelectedTask: Dispatch<SetStateAction<TListGridSelectedEntity<ITask>>>;
  setOpenTaskDetailDrawer: Dispatch<SetStateAction<boolean>>;
  handleLoadMore: (status: TaskStatus) => void;
  taskCount?: number;
  setSelectedStatus?: (value: TaskStatus) => void | undefined;
  setOpenTaskCreateDrawer?: (value: boolean) => void | undefined;
}

const ColumnContainer: React.FC<Props> = ({
  column,
  tasks,
  updateTask,
  setSelectedTask,
  setOpenTaskDetailDrawer,
  handleLoadMore,
  taskCount = 0,
  setSelectedStatus,
  setOpenTaskCreateDrawer,
}) => {
  const { t } = useTranslation();
  const [editMode] = useState(false);

  const tasksIds = useMemo(() => tasks.map((task) => task.id), [tasks]);

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.task,
    action: PermissionAction.detail,
  });

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: column.id,
    data: { type: 'Column', column },
    disabled: editMode,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <Box
        ref={setNodeRef}
        sx={{
          height: '100%',
          border: '2px solid',
          borderColor: 'primary.main',
          bgcolor: '#FF7D7D0D',
          borderRadius: 2,
        }}
        style={style}
      />
    );
  }

  return (
    <Paper
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      elevation={3}
      sx={{
        height: '100%',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        boxShadow: '0px 0px 5px rgba(200,200, 200, 0.5)',
        cursor: 'grab',
      }}
      style={style}
    >
      {/* Column Header */}
      <Box
        sx={{
          color: 'primary.contrastText',
          bgcolor: '#FF7D7D0D',
          py: 2,
          px: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {!editMode && <Typography variant="body1">{column.title}</Typography>}
          <Typography
            variant="body2"
            sx={{
              bgcolor: 'secondary.extraLight',
              color: 'secondary.main',
              padding: '4px 8px',
              borderRadius: 1,
            }}
          >
            {tasks.length}
          </Typography>
        </Stack>
        <IconButton
          sx={{ color: 'primary.main' }}
          onClick={() => {
            setOpenTaskCreateDrawer?.(true);
            setSelectedStatus?.(column.id as TaskStatus);
          }}
        >
          <Add />
        </IconButton>
      </Box>

      {/* Tasks */}
      <Box
        sx={{
          flexGrow: 1,
          padding: 2,
          overflowY: 'auto',
          bgcolor: '#FF7D7D0D',
        }}
      >
        <SortableContext items={tasksIds}>
          {tasks.map((task) => (
            <TaskCard
              key={task.id}
              task={task}
              updateTask={updateTask}
              setSelectedTask={setSelectedTask}
              setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
              hasDetailPermission={hasDetailPermission}
            />
          ))}
        </SortableContext>
        {tasks.length > 0 && (
          <Box sx={{ textAlign: 'center', marginTop: 2 }}>
            <OutlinedButton
              fullWidth
              title={t('loadMore')}
              onClick={() => handleLoadMore(column.id as TaskStatus)}
              disabled={tasks.length === 0 || tasks.length % 5 !== 0 || taskCount === tasks.length}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default ColumnContainer;

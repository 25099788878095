const locale = {
  profile: 'Profil',
  myAccount: 'Hesabım',
  profilePicture: 'Profil Resmi',
  name: 'İsim',
  surname: 'Soyisim',
  birthDate: 'Doğum Tarihi',
  gender: 'Cinsiyet',
  update: 'Güncelle',
  changeImage: 'Resmi Değiştir',
  change: 'Değiştir',
  updateProfileSuccess: 'Profil bilgileri güncellendi',
  updateProfilePictureSuccess: 'Profil resmi güncellendi',
  country: 'Ülke',
  city: 'Şehir',
  signatureImage: 'İmza Resmi',
  twoFactorAuthEmail: 'İki Faktörlü Doğrulama',
};

export default locale;

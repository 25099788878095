/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IContractDetailResult, IRenewContractHistoryItem } from 'corede-common-cocrm';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import {
  representDateAsString,
  representDateTimeAsString,
  representHtmlAsString,
} from 'utils/representationHelper';
import EmptyState from 'components/emptyState/EmptyState';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';
import { Currency } from 'corede-common';

interface IContractContactHistoryListTabSectionComponent
  extends DefaultTabComponentProps<IContractDetailResult> {
  renewHistoryList: IRenewContractHistoryItem[];
  tabKey: 'renewHistory';
  name: 'crm.contract.contract.renewHistory';
  entityData: IContractDetailResult | undefined;
}

export const ContractContactHistoryListTabSectionComponent = (
  props: IContractContactHistoryListTabSectionComponent,
) => {
  const { t } = useTranslation();

  return (
    <DefaultTabContainer title={''}>
      <Stack direction={'column'} gap={2} width={'100%'}>
        {props.renewHistoryList?.length > 0 ? (
          <BaseDataGrid
            rows={props.renewHistoryList?.map((renew, index) => ({
              id: index + 1,
              _id: renew._id,
              contractValue: renew.contractValue,
              currency: props.entityData?.currency,
              startDate: representDateAsString(renew.startDate),
              endDate: representDateAsString(renew.endDate),
              createdAt: representDateTimeAsString(renew.createdAt),
            }))}
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'id',
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                  },
                  headerConfig: {
                    name: '#',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'contractValue',
                    editable: false,
                    filterable: false,
                  },
                  headerConfig: {
                    name: t('crm.contract.contract.contractValue'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'currency',
                    editable: false,
                    filterable: false,
                  },
                  headerConfig: {
                    name: t('crm.currency'),
                  },
                },
                data: Currency,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'startDate',
                    editable: false,
                    filterable: false,
                  },
                  headerConfig: {
                    name: t('crm.startDate'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'endDate',
                    editable: false,
                    filterable: false,
                  },
                  headerConfig: {
                    name: t('crm.endDate'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'createdAt',
                    editable: false,
                    filterable: false,
                  },
                  headerConfig: {
                    name: t('crm.createdAt'),
                  },
                },
              },
            ]}
            loading={false}
            listFilter={{
              filterInput: {},
              setFilterInput: () => null,
            }}
            count={props.renewHistoryList?.length}
            config={{
              columnVisibilityModel: {},
              features: [],
            }}
            update={{
              updateQuery: () => null,
            }}
            disableColumnFilter={true}
            hideFooterPagination={true}
            sortingMode={'client'}
          />
        ) : (
          <EmptyState
            icon={
              <img
                src={DocumentEmptyIcon}
                style={{
                  width: '180px',
                  height: '180px',
                  marginBottom: '30px',
                }}
                alt="There is no document yet."
              />
            }
            content1={t('crm.contract.contract.emptyStateRenew')}
          />
        )}
      </Stack>
    </DefaultTabContainer>
  );
};

const locale = {
  demo: 'Démo',
  adjustment: 'Ajustement',
  currency: 'Devise',
  exchangeRates: 'Taux de change',
  fromCurrency: 'De la devise',
  toCurrency: 'À la devise',
  rate: 'Taux',
  updatedAt: 'Mis à jour le',
  language: 'Langue',
  locale: 'Paramètres régionaux',
  updateSettings: 'Mettre à jour les paramètres',
  NDA: 'Accord de confidentialité',
  followUpMailTemplate: 'Modèle de mail de suivi',
  emailType: 'Type d\'email',
  subject: 'Sujet',
  content: 'Contenu',
  variables: 'Variables',
  prefix: 'Préfixe',
  paymentPrefix: 'Préfixe de paiement',
  returnPrefix: 'Préfixe de retour',
  termsAndConditions: 'Conditions générales',
  paymentMethods: 'Méthodes de paiement',
  companyBackground: 'Contexte de l\'entreprise',
  companyReferences: 'Références de l\'entreprise',
  timezone: 'Fuseau horaire',
  updateSuccess: 'Paramètres mis à jour avec succès',
  name: 'Nom',
  surname: 'Prénom',
  email: 'Email',
  phone: 'Téléphone',
  identityNumber: 'Numéro d\'identité',
  vat: 'Numéro de TVA',
  country: 'Pays',
  state: 'État/Province',
  city: 'Ville',
  street: 'Rue',
  address: 'Adresse',
  taxOffice: 'Bureau des impôts',
  zip: 'Code postal',
  fullName: 'Nom complet',
  update: 'Mettre à jour',
  create: 'Créer',
  updateBillingInfo: 'Mettre à jour les informations de facturation',
  updateBillingInfoSuccess: 'Informations de facturation mises à jour avec succès',
  commonSettingsUpdateSuccess: 'Paramètres mis à jour avec succès',
  save: 'Enregistrer',
  organizationSettings: 'Organisation',
  monthly: 'mensuel',
  trial: 'Essai',
  free: 'Gratuit',
  trialEnds: 'L\'essai se termine',
  browsePackages: 'Parcourir les forfaits',
  cancelSubscription: 'Annuler l\'abonnement',
  updateOrganizationLogoSuccess: 'Logo de l\'organisation mis à jour avec succès',
  organizationLogo: 'Logo de l\'organisation',
  summary: 'Résumé',
  website: 'Site web',
  legalName: 'Nom légal',
  dateFormat: 'Format de date',
  timeFormat: 'Format d\'heure',
  vatNumber: 'Numéro de TVA',
  taxRate: 'Taux d\'imposition',
  exchangeRateMode: 'Mode de taux de change',
  general: 'Général',
  localizations: 'Localisations',
  finance: 'Finance',
  generalInfo: 'Informations générales',
  socialMedia: 'Réseaux sociaux',
  billingInfo: 'Informations de facturation',
  cards: 'Cartes',
  currentPlan: 'Forfait actuel',
  invoices: 'Factures',
  cardListSecure: 'Vos cartes sont stockées dans la base de données iyzico.',
  noInvoiceContent1: "Vous n'avez pas encore de facture.",
  noInvoiceContent2:
    'Suivez et gérez les paiements de vos factures ici pour garder vos finances organisées et à jour.',
  noLanguageContent1: "Vous n'avez pas encore de langue.",
  noLanguageContent2:
    'Créez et gérez des langues ici pour rendre votre site web accessible à un public mondial.',
  noLeadContent1: 'Les paramètres de leads ne sont pas disponibles actuellement.',
  noLeadContent2:
    'Personnalisez la façon dont les leads sont capturés, attribués et suivis pour optimiser votre flux de travail CRM.',
  noCustomerContent1: 'Aucun paramètre client configuré pour le moment.',
  noCustomerContent2:
    'Configurez des préférences pour gérer efficacement les informations et les interactions des clients dans votre CRM.',
  noEstimateContent1: 'Aucun paramètre de facture configuré pour le moment.',
  noEstimateContent2:
    'Personnalisez vos préférences de facture ici pour rationaliser la facturation et assurer des enregistrements précis.',
  noProposalContent1: 'Les paramètres de proposition ne sont pas disponibles actuellement.',
  noProposalContent2:
    'Personnalisez vos préférences de proposition ici pour rationaliser la communication avec les clients et améliorer les ventes.',
  noInvoiceContent3: 'Aucun paramètre de facture configuré pour le moment.',
  noInvoiceContent4:
    'Personnalisez vos préférences de facture ici pour rationaliser la facturation et assurer des enregistrements précis.',
  noProjectContent1: 'Les paramètres de projet n\'ont pas encore été consultés.',
  noProjectContent2:
    'Personnalisez vos préférences de projet ici pour optimiser la gestion des tâches et le flux de travail dans votre CRM.',
  noTaskContent1: 'Les paramètres de tâche n\'ont pas encore été consultés.',
  noTaskContent2:
    'Configurez vos préférences de tâche ici pour rationaliser la gestion des tâches et améliorer la productivité de l\'équipe.',
  noTicketContent1: 'Les paramètres de ticket/support n\'ont pas encore été consultés.',
  noTicketContent2:
    'Configurez vos préférences ici pour gérer les demandes de support client et assurer des résolutions rapides.',
  noFileManagementContent1: 'Les paramètres de gestion de fichiers n\'ont pas encore été consultés.',
  noFileManagementContent2:
    'Configurez vos préférences ici pour organiser et gérer efficacement les documents dans votre CRM.',
  noCalendarContent1: 'Les paramètres de calendrier n\'ont pas encore été consultés.',
  noCalendarContent2:
    'Configurez vos préférences ici pour personnaliser votre calendrier et optimiser la planification dans votre CRM.',
  noNotificationContent1: 'Les paramètres de notification n\'ont pas encore été consultés.',
  noNotificationContent2:
    'Personnalisez vos préférences de notification ici pour rester informé et à jour sur les activités importantes dans votre CRM.',
  noLeadPlusContent1: 'Aucun produit ou forfait Lead+ actif.',
  noLeadPlusContent2:
    'Achetez ou autorisez un forfait pour débloquer un accès complet et personnaliser vos paramètres Lead+.',
  noMarketingContent1: 'Aucun produit ou forfait Marketing actif.',
  noMarketingContent2:
    'Achetez ou autorisez un forfait pour débloquer un accès complet et personnaliser vos paramètres Marketing.',
  noWindowContent1: 'Aucun produit ou forfait Window actif.',
  noWindowContent2:
    'Achetez ou autorisez un forfait pour débloquer un accès complet et personnaliser vos paramètres Window.',
  noCommunityContent1: 'Aucun produit ou forfait Community actif.',
  noCommunityContent2:
    'Achetez ou autorisez un forfait pour débloquer un accès complet et personnaliser vos paramètres Community.',
  noIntegrationsContent1: 'Aucune intégration configurée pour le moment.',
  noIntegrationsContent2:
    'Configurez des intégrations ici pour améliorer les capacités de CoCRM et rationaliser les flux de travail avec des connexions autorisées.',
  noMenuContent1: 'Les paramètres de menu ne sont pas disponibles actuellement.',
  noMenuContent2:
    'Autorisez l\'accès pour personnaliser la disposition de votre menu et rationaliser la navigation dans votre CRM.',
  noLegalAgreementsContent1: 'Les paramètres d\'accords légaux ne sont pas disponibles actuellement. ',
  noLegalAgreementsContent2:
    'Autorisez l\'accès pour gérer et personnaliser les accords légaux dans votre CRM.',
  noCrmContent1: 'Aucun produit ou forfait CRM actif.',
  noCrmContent2:
    'Achetez ou autorisez un forfait pour débloquer un accès complet et personnaliser vos paramètres CRM.',
  noUserListContent1: 'Aucun produit ou forfait Liste d\'utilisateurs actif.',
  noUserListContent2:
    'Achetez ou autorisez un forfait pour débloquer un accès complet et personnaliser vos paramètres de Liste d\'utilisateurs.',
  noPermissionsContent1: 'Aucun produit ou forfait Permissions actif.',
  noPermissionsContent2:
    'Achetez ou autorisez un forfait pour débloquer un accès complet et personnaliser vos paramètres de Permissions.',
  lead: 'Lead',
  crm: 'CRM',
  menu: 'Menu',
  customer: 'Client',
  estimate: 'Devis',
  proposal: 'Proposition',
  invoice: 'Facture',
  project: 'Projet',
  task: 'Tâche',
  ticket: 'Ticket',
  fileManagement: 'Gestion de fichiers',
  calendar: 'Calendrier',
  notification: 'Notification',
  leadPlus: 'Lead+',
  marketing: 'Marketing',
  window: 'Window',
  community: 'Community',
  integrations: 'Intégrations',
  userList: 'Liste d\'utilisateurs',
  permissions: 'Permissions',
  userType: 'Type d\'utilisateur',
  companyName: 'Nom de l\'entreprise',
  allowableFileFormats: 'Formats de fichier autorisés',

  // currentPlan
  yourCurrentPlan: 'Votre forfait actuel est ',
  yourCurrentPlanDesc: 'Un démarrage simple pour tous',
  activeUntil: 'Actif jusqu\'au',
  activeUntilDesc: 'Nous vous enverrons une notification à l\'expiration de l\'abonnement',
  paymentDesc: 'Le paiement sera débité de votre compte',
  paymentForTrialDesc: 'Vous devez acheter un abonnement pour continuer à utiliser',
  perMonth: 'Par mois',
  prtYear: 'Par an',
  perMonthDesc: 'Vous pouvez annuler votre abonnement à tout moment',
  upgradePlan: 'Mettre à niveau le forfait',
  attentionTitle: 'Nous avons besoin de votre attention !',
  attentionDesc: 'Votre forfait nécessite des mises à jour',
  days: 'Jours',
  of: 'de',
  remainingDesc: 'jours restants avant que votre forfait ne nécessite une mise à jour',
  usageOfStorage: 'Utilisation du stockage',
  users: 'Utilisateurs',

  //legalAgreements
  legalAgreements: 'Accords légaux',
  createLegalAgreement: 'Créer un accord légal',
  updateLegalAgreement: 'Mettre à jour un accord légal',
  deleteLegalAgreement: 'Supprimer un accord légal',
  createLegalAgreementSuccess: 'Accord légal créé avec succès',
  updateLegalAgreementSuccess: 'Accord légal mis à jour avec succès',
  deleteLegalAgreementSuccess: 'Accord légal supprimé avec succès',
  deleteLegalAgreementDesc: 'Êtes-vous sûr de vouloir supprimer cet accord légal ?',

  // cards
  deleteCard: 'Supprimer la carte',
  addCard: 'Ajouter une nouvelle carte',
  createCardSuccess: 'Carte créée avec succès',
  addCardSuccess: 'Carte ajoutée avec succès',
  deleteCardSuccess: 'Carte supprimée avec succès',
  deleteCardDesc: 'Êtes-vous sûr de vouloir supprimer cette carte ?',
  requiredCard: 'Le numéro de carte est requis',
  requiredCardHolder: 'Le nom du titulaire de la carte est requis',
  requiredExpireMonth: 'Le mois d\'expiration est requis',
  requiredExpireYear: 'L\'année d\'expiration est requise',
  requiredCvc: 'Le CVC est requis',
};

export default locale; 
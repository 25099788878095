import React from 'react';
import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import { Stack, Button, CircularProgress, Typography } from '@mui/material';
import { Iconify } from 'components';
import ActionDialog from 'components/dialog/ActionDialog';
import {
  estimateAcceptProspectQuery,
  estimateDeclineProspectQuery,
  EstimateStatus,
  IEstimateDetailProspectResult,
} from 'corede-common-cocrm';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ActionButtons = (props: {
  estimateData: IEstimateDetailProspectResult | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      estimateDetailProspect: IEstimateDetailProspectResult;
    }>
  >;
}) => {
  const { t } = useTranslation();
  const [openDeclineReasonDialog, setOpenDeclineReasonDialog] = useState(false);
  const [reason, setReason] = useState('');
  const [estimateAccept, { data: estimateAcceptData, loading: estimateAcceptLoading }] =
    useMutation(estimateAcceptProspectQuery);
  const [estimateDecline, { data: estimateDeclineData, loading: estimateDeclineLoading }] =
    useMutation(estimateDeclineProspectQuery);

  const handleAccept = () => {
    estimateAccept();
  };

  const handleDecline = () => {
    estimateDecline({
      variables: {
        input: {
          reason,
        },
      },
    });
  };

  useEffect(() => {
    if (estimateAcceptData) {
      enqueueSnackbar(t('auth.estimateAccepted'), { variant: 'success' });
      props.refetch();
    }
  }, [estimateAcceptData, t, props]);

  useEffect(() => {
    if (estimateDeclineData) {
      setOpenDeclineReasonDialog(false);
      enqueueSnackbar(t('auth.estimateDeclined'), { variant: 'success' });
      props.refetch();
    }
  }, [estimateDeclineData, t, props]);

  return (
    <Stack gap={2}>
      {props.estimateData?.status === EstimateStatus.accepted && (
        <Typography
          fontSize={14}
          sx={{ fontWeight: 500 }}
          color={'success.main'}
          textAlign={'center'}
        >
          {t('auth.estimateAccepted')}
        </Typography>
      )}
      {props.estimateData?.status === EstimateStatus.declined && (
        <Typography
          fontSize={14}
          sx={{ fontWeight: 500 }}
          color={'error.main'}
          textAlign={'center'}
        >
          {t('auth.estimateDeclined')}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        color="success"
        fullWidth
        disabled={estimateAcceptLoading || props.estimateData?.status !== EstimateStatus.opened}
        onClick={() => handleAccept()}
        sx={{ fontSize: '15px', borderRadius: '6px', height: 38, fontWeight: 400 }}
      >
        {estimateDeclineLoading ? (
          <CircularProgress size={'1rem'} />
        ) : (
          <Iconify icon={'charm:tick'} width={24} sx={{ color: 'text.white', mr: 1 }} />
        )}
        {t('auth.accept')}
      </Button>

      <Button
        type="submit"
        variant="contained"
        color="error"
        fullWidth
        disabled={estimateDeclineLoading || props.estimateData?.status !== EstimateStatus.opened}
        onClick={() => setOpenDeclineReasonDialog(true)}
        sx={{ fontSize: '15px', borderRadius: '6px', height: 38, fontWeight: 400 }}
      >
        {estimateDeclineLoading ? (
          <CircularProgress size={'1rem'} />
        ) : (
          <Iconify
            icon={'material-symbols-light:close'}
            width={24}
            sx={{ color: 'text.white', mr: 1 }}
          />
        )}
        {t('auth.decline')}
      </Button>

      {/* <Button
        type="submit"
        variant="contained"
        color="info"
        fullWidth
        onClick={() => console.log('Download')}
        sx={{ fontSize: '15px', borderRadius: '6px', height: 38, fontWeight: 400 }}
      >
        <Iconify icon={'lucide:download'} width={24} sx={{ color: 'text.white', mr: 1 }} />
        {t('auth.download')}
      </Button> */}

      <ActionDialog
        open={openDeclineReasonDialog}
        onClose={() => setOpenDeclineReasonDialog(false)}
        title={t('auth.declineReason')}
        handleClick={handleDecline}
        width={500}
        loading={estimateDeclineLoading}
        disabled={reason === '' || estimateDeclineLoading}
      >
        <Stack gap={2} width={'100%'}>
          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
            {t('auth.declineReason')}
          </Typography>
          <textarea
            style={{
              width: '95%',
              padding: '10px',
              borderRadius: '6px',
              border: '1px solid #ccc',
              resize: 'none',
            }}
            rows={4}
            placeholder={t('auth.declineReasonPlaceholder')}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Stack>
      </ActionDialog>
    </Stack>
  );
};

export default ActionButtons;

import dashboardLocale from '../domains/01-dashboard/locales/it';
import productAndServiceLocale from '../domains/08-productAndService/locales/it';
import organizationalChartLocale from '../domains/02-organizationalChart/locales/it';
import leadLocale from '../domains/03-lead/locales/it';
import customerLocale from '../domains/04-customer/locales/it';
import preSaleLocale from '../domains/05-preSale/locales/it';
import saleLocale from '../domains/06-sale/locales/it';
import pricingLocale from '../domains/16-pricing/locales/it';
import calendarLocale from '../domains/12-calendar/locales/it';
import contractLocale from '../domains/07-contract/locales/it';
import supportLocale from '../domains/15-support/locales/it';
import reportLocale from '../domains/13-report/locales/it';
import taskLocale from '../domains/11-task/locales/it';
import projectLocale from '../domains/10-project/locales/it';
import expenseLocale from '../domains/09-expense/locales/it';
import noteLocale from '../domains/14-note/locales/it';

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  productAndService: {
    ...productAndServiceLocale,
  },
  customer: {
    ...customerLocale,
  },
  preSale: {
    ...preSaleLocale,
  },
  sale: {
    ...saleLocale,
  },
  pricing: {
    ...pricingLocale,
  },
  calendar: {
    ...calendarLocale,
  },
  contract: {
    ...contractLocale,
  },
  support: {
    ...supportLocale,
  },
  report: {
    ...reportLocale,
  },
  task: {
    ...taskLocale,
  },
  project: {
    ...projectLocale,
  },
  expense: {
    ...expenseLocale,
  },
  note: {
    ...noteLocale,
  },

  ////////////////////////////////////////////////////////////////
  // common used names
  ////////////////////////////////////////////////////////////////
  preSaleLabel: 'Pre-Vendita',
  preSales: 'Pre-Vendite',
  contactDetails: 'Dettagli',
  localization: 'Localizzazione',
  info: 'Info',
  about: 'Chi Siamo',
  contact: 'Contatto',
  record: 'Registrazione',
  records: 'Registrazioni',
  tracking: 'Monitoraggio',
  pricingLabel: 'Prezzi',
  dashboardLabel: 'Dashboard',
  marketing: 'Marketing',
  campaigns: 'Campagne',
  emails: 'Email',
  sms: 'SMS',
  socialMedia: 'Social Media',
  salesLabel: 'Vendite',
  businessIntelligence: 'Business Intelligence',
  calendarLabel: 'Calendario',
  supportLabel: 'Supporto',
  accountMenu: 'Menu Account',
  profile: 'Profilo',
  myAccount: 'Il Mio Account',
  logout: 'Esci',
  searchPlaceholder: 'Prova a cercare "approfondimenti"',
  searchAnIcon: 'Cerca un\'Icona',

  // --------------------
  // settings related
  // --------------------
  settings: 'Impostazioni',
  english: 'Inglese',
  turkish: 'Turco',
  french: 'Francese',
  german: 'Tedesco',
  italian: 'Italiano',
  portuguese: 'Portoghese',
  russian: 'Russo',
  spanish: 'Spagnolo',
  dutch: 'Olandese',
  darkMode: 'Modalità Scura',
  lightMode: 'Modalità Chiara',
  languageSelectLabel: 'Seleziona Lingua',

  ////////////////////////////////////////////////////////////////
  // common action texts
  ////////////////////////////////////////////////////////////////
  actions: 'Azioni',
  yes: 'Sì',
  no: 'No',
  importExport: 'Importa/Esporta',
  view: 'Visualizza',
  list: 'Lista',
  create: 'Crea',
  update: 'Aggiorna',
  delete: 'Elimina',
  edit: 'Modifica',
  cancel: 'Annulla',
  downloadSuccessful: 'Download completato',
  export: 'Esporta',
  import: 'Importa',
  exportData: 'Esporta Dati',
  importData: 'Importa Dati',
  showMore: 'Mostra di Più',
  selectColor: 'Seleziona Colore',
  justForMe: 'Solo Per Me',

  ////////////////////////////////////////////////////////////////
  // common situational texts
  ////////////////////////////////////////////////////////////////
  total: 'Totale',
  weeklyTotal: 'Totale Settimanale',
  hasCreatedAt: 'è stato creato il ',
  createdBy: 'Creato da ',
  updatedBy: 'Aggiornato da ',
  uploadLogo: 'Carica Logo',
  selectIconTitle: 'Seleziona un\'Icona',
  searchIcons: 'Cerca Icone',
  uploadPictureText: 'Carica un\'immagine',
  searchForAnIcon: 'Cerca un\'Icona',
  selectedIcon: 'Icona Selezionata:',
  signedUser: 'Utente Connesso',

  // --------------------
  // delete dialog
  // --------------------
  deletedEntitySuccessfully: 'Eliminato con successo',
  deleteEntityContent: 'Sei sicuro di voler eliminare? Questa azione non può essere annullata.',
  deleteEntityTitle: 'Elimina',

  // --------------------
  // organization
  // --------------------
  mustCreateOrganizationTitle: 'Crea Organizzazione',
  mustCreateOrganizationContent: 'Devi creare un\'organizzazione per utilizzare il pannello.',
  mustCreateOrganizationSuccess: 'Organizzazione creata con successo.',

  // --------------------
  // import export
  // --------------------
  importedError: 'Errore di importazione',
  importedSuccessfully: 'Importato con successo',
  downloadExample: 'Scarica Esempio',
  downloadFormError: 'Errore durante il download del modulo',
  downloadFormSuccessfully: 'Modulo scaricato con successo',
  downloadExampleFormSuccessfully: 'Modulo di esempio scaricato con successo',
  downloadExampleFormError: 'Errore durante il download del modulo di esempio',

  // --------------------
  // estimate
  // --------------------
  addEstimate: 'Aggiungi Preventivo',
  noEstimate: 'Non ci sono preventivi',
  noEstimateContent1: 'Preventivi non trovati',
  additionalInfo: 'Informazioni Aggiuntive',

  // --------------------
  // proposal
  // --------------------
  addProposal: 'Aggiungi Proposta',
  noProposal: 'Non ci sono proposte',
  lastProposal: 'Ultima Proposta',
  proposalSend: 'Proposta Inviata',
  noProposalSend: 'Non ci sono proposte inviate',
  noProposalContent1: 'Proposte non trovate',
  sendEmailOnExpiration: 'Invia email alla scadenza',
  testimonialCaseStudies: 'Case Study Testimoniali',
  projectTimeline: 'Cronologia del Progetto',
  executiveSummary: 'Riepilogo Esecutivo',
  costBreakdown: 'Dettaglio dei Costi',
  conclusion: 'Conclusione',
  projectorServiceDesc: 'Descrizione Dettagliata del Progetto/Servizio',
  noSalentAgents: 'Non ci sono agenti di vendita',

  // --------------------
  // document
  // --------------------
  addDocument: 'Aggiungi Documento',
  noDocument: 'Non ci sono documenti',
  noDocumentContent1: 'Documenti non trovati',

  // --------------------
  // task
  // --------------------
  noTask: 'Non ci sono attività',
  noTaskContent1: 'Attività non trovate',

  // --------------------
  // activity
  // --------------------
  noActivity: 'Non ci sono attività',
  addActivity: 'Aggiungi Attività',
  noActivityContent1: 'Attività non trovate',
  noActivityContent2: 'Non ci sono attività per questa entità',

  // --------------------
  // note
  // --------------------
  addNote: 'Aggiungi Nota',
  noNote: 'Non ci sono note',
  emptyStateNoteList1: 'Note non trovate',
  noteDate: 'Data Nota',
  noteUsers: 'Utenti Nota',
  noteTitle: 'Titolo',

  // --------------------
  // reminder
  // --------------------
  addReminder: 'Aggiungi Promemoria',
  noReminder: 'Non ci sono promemoria',
  emptyStateReminderList1: 'Promemoria non trovati',
  remindDate: 'Data Promemoria',
  remindUsers: 'Ricorda Utenti',
  remindTitle: 'Titolo',

  // --------------------
  // appointment
  // --------------------
  noAppointments: 'Non ci sono appuntamenti',
  makeAnAppointment: 'Fissa un Appuntamento',

  // --------------------
  // assignee
  // --------------------
  assignees: 'Assegnatari',
  noAssignees: 'Nessun Assegnatario',
  addAssignee: 'Aggiungi Assegnatario',

  // --------------------
  // followers
  // --------------------
  followers: 'Follower',
  noFollowers: 'Nessun Follower',

  // --------------------
  // product and services
  // --------------------
  addProduct: 'Aggiungi Prodotto',
  addProductOrService: 'Aggiungi Prodotto o Servizio',
  editProdutctOrService: 'Modifica Prodotto o Servizio',
  productType: 'Tipo di Prodotto',
  editProduct: 'Modifica Prodotto',
  product: 'Prodotto',

  // --------------------
  // ticket
  // --------------------
  subject: 'Oggetto',
  contactName: 'Nome Contatto',
  tickets: 'Ticket',

  ////////////////////////////////////////////////////////////////
  // common used words
  ////////////////////////////////////////////////////////////////
  of: 'di',

  ////////////////////////////////////////////////////////////////
  // common used entity names
  ////////////////////////////////////////////////////////////////
  leadLabel: 'Lead',
  leads: 'Lead',
  leadForms: 'Moduli Lead',
  leadStatuses: 'Stati Lead',
  leadSources: 'Fonti Lead',
  customerLabel: 'Cliente',
  customers: 'Clienti',
  estimateLabel: 'Preventivo',
  estimates: 'Preventivi',
  proposalLabel: 'Proposta',
  proposals: 'Proposte',
  invoiceLabel: 'Fattura',
  invoices: 'Fatture',
  productsServices: 'Prodotti & Servizi',
  activityLabel: 'Attività',
  activities: 'Attività',
  noteLabel: 'Nota',
  notes: 'Note',
  documentLabel: 'Documento',
  documents: 'Documenti',
  appointmentLabel: 'Appuntamento',
  appointments: 'Appuntamenti',
  reminderLabel: 'Promemoria',
  reminders: 'Promemoria',
  departmentLabel: 'Dipartimento',
  departments: 'Dipartimenti',
  contractLabel: 'Contratto',
  contractTemplate: 'Modello di Contratto',
  contracts: 'Contratti',
  paymentLabel: 'Pagamento',
  payments: 'Pagamenti',
  organizationalChartLabel: 'Organigramma',
  expenseLabel: 'Spesa',
  expenses: 'Spese',
  productAndServiceLabel: 'Prodotti & Servizi',
  projectLabel: 'Progetto',
  projects: 'Progetti',
  taskLabel: 'Attività',
  tasks: 'Attività',
  reportLabel: 'Report',
  reports: 'Report',
  billingInformation: 'Informazioni di Fatturazione',
  billingInfo: 'Info Fatturazione',
  otherBillingInformation: 'Altre Informazioni di Fatturazione',
  shippingInfo: 'Info Spedizione',
  contactInformation: 'Informazioni di Contatto',
  otherContactInformation: 'Altre Informazioni di Contatto',
  warningBillingInfo: 'Una volta compilato un campo, tutti i campi devono essere compilati.',
  addRole: 'Aggiungi Ruolo',

  ////////////////////////////////////////////////////////////////
  // common fields
  ////////////////////////////////////////////////////////////////
  color: 'Colore',
  icon: 'Icona',
  index: 'Indice',
  title: 'Titolo',
  fullName: 'Nome Completo',
  firstName: 'Nome',
  lastName: 'Cognome',
  surname: 'Cognome',
  convert: 'Converti',
  country: 'Paese',
  state: 'Stato',
  city: 'Città',
  industry: 'Settore',
  address: 'Indirizzo',
  website: 'Sito Web',
  emailAddress: 'Indirizzo Email',
  name: 'Nome',
  companyName: 'Nome Azienda',
  companyEmail: 'Email Aziendale',
  companyPhone: 'Telefono Aziendale',
  timezone: 'Fuso Orario',
  companyLogo: 'Logo Aziendale',
  language: 'Lingua',
  department: 'Dipartimento',
  email: 'Email',
  phoneNumber: 'Numero di Telefono',
  type: 'Tipo',
  createdAt: 'Creato Il',
  updatedAt: 'Aggiornato Il',
  prefix: 'Prefisso',
  tags: 'Tag',
  tagsPlaceholder: 'Tag',
  addTagsPlaceholder: 'Aggiungi Tag',
  addNewOption: 'Aggiungi nuova opzione',
  enterText: 'Inserisci testo...',
  company: 'Azienda',
  sector: 'Settore',
  phone: 'Telefono',
  content: 'Contenuto',
  priority: 'Priorità',
  position: 'Posizione',
  status: 'Stato',
  source: 'Fonte',
  date: 'Data',
  expireDate: 'Data di Scadenza',
  totalPrice: 'Prezzo Totale',
  currency: 'Valuta',
  category: 'Categoria',
  parentCategory: 'Categoria Principale',
  vat: 'IVA',
  zip: 'CAP',
  street: 'Via',
  taxOffice: 'Ufficio Fiscale',
  target: 'Obiettivo',
  targetType: 'Tipo di Obiettivo',
  description: 'Descrizione',
  birthDate: 'Data di Nascita',
  gender: 'Genere',
  isPrimary: 'È Primario',
  prospectName: 'Nome Prospetto',
  prospectAddress: 'Indirizzo Prospetto',
  prospectContactName: 'Nome Contatto Prospetto',
  prospectType: 'Tipo di Prospetto',
  leadValue: 'Valore Lead',
  estimateId: 'Id Preventivo',
  proposalId: 'Id Proposta',
  invoiceId: 'Id Fattura',
  paymentId: 'Id Pagamento',
  returnId: 'Id Reso',
  expenseId: 'Id Spesa',
  customerName: 'Nome Cliente',
  customerEmail: 'Email Cliente',
  customerType: 'Tipo di Cliente',
  finalPrice: 'Prezzo Finale',
  paymentMethod: 'Metodo di Pagamento',
  confirmationNumber: 'Numero di Conferma',
  amoundPaid: 'Importo Pagato',
  refundAmount: 'Importo Rimborso',
  isOpen: 'È Aperto',
  resolveReason: 'Motivo Risoluzione',
  disapproveReason: 'Motivo Rifiuto',
  sku: 'SKU',
  priceType: 'Tipo di Prezzo',
  price: 'Prezzo',
  discountedPrice: 'Prezzo Scontato',
  discount: 'Sconto',
  unit: 'Unità',
  unitPrice: 'Prezzo Unitario',
  natTaxRate: 'Aliquota Fiscale Nazionale',
  intTaxRate: 'Aliquota Fiscale Internazionale',
  selectRoles: 'Seleziona Ruoli',
  timeZone: 'Fuso Orario',
  prospect: 'Prospetto',
  prospectTargetEntityRef: 'Rif Entità Obiettivo Prospetto',
  prospectTarget: 'Obiettivo Prospetto',
  prospectVAT: 'IVA Prospetto',
  prospectPhoneNumber: 'Numero di Telefono Prospetto',
  prospectZIP: 'CAP Prospetto',
  contactFirstName: 'Nome Contatto',
  contactLastName: 'Cognome Contatto',
  contactEmail: 'Email Contatto',
  expiration: 'Scadenza',
  detailedInformation: 'Informazioni Dettagliate',
  projectOrServiceDesc: 'Descrizione Progetto/Servizio',
  scopeOfWork: 'Ambito di Lavoro',
  assumptionsAndExclusions: 'Assunzioni ed Esclusioni',
  paymentTerms: 'Termini di Pagamento',
  products: 'Prodotti',
  services: 'Servizi',
  productName: 'Nome Prodotto',
  productDescription: 'Descrizione Prodotto',
  productUnit: 'Unità Prodotto',
  quantity: 'Quantità',
  tax: 'Tassa',
  extraInformation: 'Informazioni Extra',
  offers: 'Offerte',
  reference: 'Riferimento',
  allowComments: 'Consenti Commenti',
  totally: 'Totalmente',
  subTotal: 'Subtotale',
  taxTotal: 'Totale Tasse',
  extraDiscount: 'Sconto Extra',
  shippingTotal: 'Totale Spedizione',
  salesAgents: 'Agenti di Vendita',
  legal: 'Legale',
  useLegalNDA: 'Usa NDA Legale',
  useLegalTermAndCond: 'Usa Termini e Condizioni Legali',
  signature: 'Firma',
  signerUser: 'Utente Firmatario',
  signedDate: 'Data Firma',
  generalInfo: 'Info Generali',
  startDate: 'Data di Inizio',
  dueDate: 'Data di Scadenza',
  endDate: 'Data di Fine',
  expirationEmailSentDate: 'Data Invio Email di Scadenza',
  allowedPaymentMethods: 'Metodi di Pagamento Consentiti',
  sendEmail: 'Invia Email',
  identityNumber: 'Numero di Identità',
  recurringCount: 'Conteggio Ricorrente',
  isRecurring: 'È Ricorrente',
  recurringFrequency: 'Frequenza Ricorrente',
  recurringDueDate: 'Data di Scadenza Ricorrente',
  paymentID: 'ID Pagamento',
  paymentPrefix: 'Prefisso Pagamento',
  returnPrefix: 'Prefisso Reso',
  paymentDate: 'Data Pagamento',
  amountPaid: 'Importo Pagato',
  invoiceID: 'ID Fattura',
  targetDetail: 'Dettaglio Obiettivo',
  productsAndServices: 'Prodotti & Servizi',
  popularSearch: 'Ricerca Popolare',
  returnReason: 'Motivo Reso',
  partialReturn: 'Reso Parziale',
  amount: 'Importo',
  relationship: 'Relazione',
  relationshipType: 'Tipo di Relazione',
  relatedEntity: 'Entità Correlata',
  relatedEstimate: 'Preventivo Correlato',
  relatedProposal: 'Proposta Correlata',
  relatedTask: 'Attività Correlata',
  relatedProject: 'Progetto Correlato',
  relatedLead: 'Lead Correlato',
  relatedCustomer: 'Cliente Correlato',
  relatedInvoice: 'Fattura Correlata',
  relatedPayment: 'Pagamento Correlato',
  relatedUser: 'Utente Correlato',
  relatedExpense: 'Spesa Correlata',
  relatedContract: 'Contratto Correlato',
  order: 'Ordine',

  ////////////////////////////////////////////////////////////////
  // SEARCH
  ////////////////////////////////////////////////////////////////
  leadCreate: 'Crea Lead',
  leadList: 'Lista Lead',
  leadImport: 'Importa Lead',
  customerCreate: 'Crea Cliente',
  customerList: 'Lista Clienti',
  customerImport: 'Importa Clienti',
  estimateCreate: 'Crea Preventivo',
  estimateList: 'Lista Preventivi',
  proposalCreate: 'Crea Proposta',
  proposalList: 'Lista Proposte',
  invoiceCreate: 'Crea Fattura',
  invoiceList: 'Lista Fatture',
  paymentCreate: 'Crea Pagamento',
  paymentList: 'Lista Pagamenti',
  returnCreate: 'Crea Reso',
  returnList: 'Lista Resi',
  contractCreate: 'Crea Contratto',
  contractList: 'Lista Contratti',
  productCreate: 'Crea Prodotto',
  productList: 'Lista Prodotti',
  serviceList: 'Lista Servizi',
  serviceCreate: 'Crea Servizio',
  expenseCreate: 'Crea Spesa',
  expenseList: 'Lista Spese',
  projectCreate: 'Crea Progetto',
  projectList: 'Lista Progetti',
  taskCreate: 'Crea Attività',
  taskList: 'Lista Attività',
  reminderList: 'Lista Promemoria',
  reminderCreate: 'Crea Promemoria',
  appointmentList: 'Lista Appuntamenti',
  appointmentCreate: 'Crea Appuntamento',
  noteCreate: 'Crea Nota',
  noteList: 'Lista Note',
  ticketList: 'Lista Ticket',
  ticketCreate: 'Crea Ticket',
  noResultsFound: 'Nessun risultato trovato',
  trySearching: 'Prova a cercare',
  hideButton: 'Nascondi Pulsante',
};

export default locale; 
import { object, string, array, ref, boolean } from 'yup';

export const emailYup = string().email('Invalid email address').required('Required');
export const passwordYup = string()
  .required('Required')
  .min(8, 'Password must be at least 8 characters')
  .max(50, 'Password must be at most 50 characters')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+,.\\\/;':"-])[A-Za-z\d!@#$%^&*()_+,.\\\/;':"-]{8,}$/,
    'Password must contain at least one uppercase letter, one lowercase letter and one number',
  );
export const passwordAgainYup = string()
  .oneOf([ref('password'), ''], 'Passwords must match')
  .required('Required');
export const titleYup = string().required('Title is required');
export const categoryYup = string().nullable();
export const letsTitleYup = string().required('Category is required');
export const quotaYup = string().required('Quota is required');
export const dateYup = string().required('Date is required');
export const reservationCloseDateYup = string().required('Reservation Close Date is required');
export const statusYup = string().required('Status is required');
export const locationYup = string().required('Location is required');
export const departmentsYup = array().required('Departments is required');
export const departmentYup = string().required('Department is required');
export const department2Yup = string().nullable();
export const reserveQuotaYup = string().required('Reserve Quota is required');
export const detailsYup = string().required('Details is required');
export const nameYup = string().nullable();
export const surnameYup = string().nullable();
export const codeYup = string().required('Code is required');
export const sessionsYup = array().nullable();
export const birthDateYup = string().nullable();
export const genderYup = string().nullable();
export const countryYup = string().nullable();
export const cityYup = string().nullable();
export const addressYup = string().nullable();
export const profileImageYup = object().nullable();
export const descriptionYup = string().nullable();
export const phoneNumberYup = string().nullable();
export const isCheckedYup = boolean().oneOf([true], 'You must accept the terms and conditions');

export const loginValidationSchema = object({
  email: emailYup,
  password: passwordYup,
});

export const registerValidationSchema = object({
  name: nameYup,
  surname: surnameYup,
  email: emailYup,
  password: passwordYup,
  passwordAgain: passwordAgainYup,
  isChecked: isCheckedYup,
});

export const resetPasswordAfterRequestValidationSchema = object({
  password: passwordYup,
  passwordAgain: passwordAgainYup,
});

export const resetPasswordValidationSchema = object({
  password: passwordYup,
  passwordAgain: passwordAgainYup,
});

export const forgotPasswordValidationSchema = object({
  email: emailYup,
});

export const createActivityValidationSchema = object({
  title: titleYup,
  category: categoryYup,
  quota: quotaYup,
  date: dateYup,
  reservationCloseDate: reservationCloseDateYup,
  location: locationYup,
  departments: departmentsYup,
  reserveQuota: reserveQuotaYup,
  details: detailsYup,
  letsTitle: letsTitleYup,
  sessions: sessionsYup,
});

export const updateUserValidationSchema = object({
  name: nameYup,
  surname: surnameYup,
  birthDate: birthDateYup,
  gender: genderYup,
  country: countryYup,
  city: cityYup,
  address: addressYup,
  profileImage: profileImageYup,
  description: descriptionYup,
  // email: emailYup,
  phoneNumber: phoneNumberYup,
  department: department2Yup,
});

export const updateActivityValidationSchema = object({
  title: titleYup,
  category: categoryYup,
  quota: quotaYup,
  date: dateYup,
  reservationCloseDate: reservationCloseDateYup,
  location: locationYup,
  reserveQuota: reserveQuotaYup,
  details: detailsYup,
  letsTitle: letsTitleYup,
});

export const createUserValidationSchema = object({
  name: nameYup,
  surname: surnameYup,
  email: emailYup,
  department: departmentYup,
});

export const createPasswordValidationSchema = object({
  code: codeYup,
  password: passwordYup,
  passwordAgain: passwordAgainYup,
});

export const confirmRegistrationValidationSchema = object({
  token: string().required('Token is required'),
  code: codeYup,
});

const locale = {
  proposal: 'Proposta',
  title: 'Proposte',
  proposals: 'Proposte',
  create: 'Crea Proposta',
  update: 'Aggiorna Proposta',
  delete: 'Elimina',
  view: 'Visualizza Proposta',
  list: 'Elenco Proposte',
  createdSuccessfully: 'Proposta creata con successo',
  updatedSuccessfully: 'Proposta aggiornata con successo',
  deleteConfirm: 'Sei sicuro di voler eliminare? Questa azione non può essere annullata.',
  deletedSuccessfully: 'Proposta eliminata con successo',
  createInfo: 'Puoi creare nuove proposte qui.',
  prefix: 'Prefisso',
  proposalId: 'Proposta',
  timeZone: 'Fuso Orario',
  prospectTargetEntityId: 'Target Prospetto',
  prospectTargetEntityRef: 'Riferimento Target Prospetto',
  prospectType: 'Tipo di Prospetto',
  prospectName: 'Nome Prospetto',
  prospectVAT: 'Partita IVA Prospetto',
  prospectPhoneNumber: 'Numero di Telefono Prospetto',
  prospectCountry: 'Paese Prospetto',
  prospectState: 'Regione Prospetto',
  prospectCity: 'Città Prospetto',
  prospectZIP: 'CAP Prospetto',
  prospectAddress: 'Indirizzo Prospetto',
  prospectContactFirstName: 'Nome Contatto Prospetto',
  prospectContactLastName: 'Cognome Contatto Prospetto',
  prospectContactEmail: 'Email Contatto Prospetto',
  language: 'Lingua',
  date: 'Data',
  expireDate: 'Data di Scadenza',
  sendEmailOnExpiration: 'Invia Email alla Scadenza',
  expirationEmailSentDate: 'Data Invio Email di Scadenza',
  projectOrServiceDesc: 'Descrizione Progetto o Servizio',
  scopeOfWork: 'Ambito di Lavoro',
  assumptionsAndExclusions: 'Assunzioni ed Esclusioni',
  paymentTerms: 'Termini di Pagamento',
  currency: 'Valuta',
  products: 'Prodotti',
  addProducts: 'Aggiungi Prodotti',
  offers: 'Offerte',
  reference: 'Riferimento',
  salesAgentIds: 'Agenti di Vendita',
  allowComments: 'Consenti Commenti',
  useLegalNDA: 'Usa NDA',
  legalNDAId: 'NDA Legale',
  useLegalTermAndCond: 'Usa Termini e Condizioni',
  legalTermAndCondId: 'Termini e Condizioni Legali',
  signerUserId: 'Utente Firmatario',
  listInfo: 'Elenco di tutte le proposte.',
  importedError: 'Errore importato',
  importedSuccessfully: 'Importato con successo',
  downloadFormError: 'Errore download modulo',
  downloadFormSuccessfully: 'Modulo scaricato con successo',
  downloadExampleFormSuccessfully: 'Modulo di esempio scaricato con successo',
  downloadExampleFormError: 'Errore download modulo di esempio',
  emptyState1: 'Nessuna proposta trovata.',
  emptyState2: 'Puoi creare o importare proposte immediatamente.',
  createProposal: 'Crea Proposta',
  importProposal: 'Importa Proposta',
  importTitle: 'Importa Proposte',
  downloadExample: 'Scarica Esempio',
  importText1: 'Puoi importare proposte da questa pagina.',
  importText2: 'Puoi utilizzare il modulo di esempio qui sotto per pubblicare le tue proposte.',
  importExport: 'Importa/Esporta',
  exportData: 'Esporta Dati',
  importData: 'Importa Dati',
  b2b: 'B2B',
  b2c: 'B2C',
  viewProposal: 'Visualizza',
  edit: 'Modifica',
  junk: 'Cestino',
  unJunk: 'Ripristina',
  addNewRole: 'Aggiungi Nuovo Ruolo',
  actions: 'Azioni',
  businessName: 'Nome Azienda',
  governmentAgency: 'Agenzia Governativa',
  fullName: 'Nome Completo',
  VAT: 'Partita IVA',
  phoneNumber: 'Numero di Telefono',
  country: 'Paese',
  state: 'Regione',
  city: 'Città',
  ZIP: 'CAP',
  address: 'Indirizzo',
  contactFirstName: 'Nome Contatto',
  contactLastName: 'Cognome Contatto',
  contactEmail: 'Email Contatto',
  sentDate: 'Data di Invio',
  name: 'Nome',
  description: 'Descrizione',
  productUnit: 'Unità',
  quantity: 'Quantità',
  price: 'Prezzo',
  priceType: 'Tipo di Prezzo',
  discountedPrice: 'Prezzo Scontato',
  finalPrice: 'Prezzo Finale',
  tax: 'Imposta',
  service: 'Servizio',
  subTotal: 'Subtotale',
  taxTotal: 'Totale Imposte',
  discount: 'Sconto',
  product: 'Prodotto',
  services: 'Servizi',
  references: 'Riferimenti',
  add: 'Aggiungi',
  salesAgent: 'Agente di Vendita',
  executiveSummary: 'Riepilogo Esecutivo',
  companyBackground: 'Background Aziendale',
  projectorServiceDesc: 'Descrizione Dettagliata Progetto/Servizio',
  costBreakdown: 'Dettaglio Costi',
  projectTimeline: 'Tempistica Progetto',
  testimonialCaseStudies: 'Testimonianze e Casi Studio',
  conclusion: 'Conclusione',
  proposalCreateSuccess: 'Proposta creata con successo',
  sentSuccessfully: 'Proposta inviata con successo',
  send: 'Invia Proposta',
  resend: 'Reinvia Proposta',
  sendSureTitle: 'Invia Proposta',
  sendSureDesc: 'Sei sicuro di voler inviare questa proposta?',
  changeStatus: 'Cambia Stato',
  statusUpdatedSuccessfully: 'Stato aggiornato con successo',

  // alert
  noProposal: 'Non ci sono proposte',
  noProposalContent1: 'Proposte non trovate',
  noProposalSend: 'Non ci sono proposte inviate',

  // generalInfo
  generalInfoTitle: 'Informazioni Generali',
  status: 'Stato',
  createdAt: 'Creato il',
  createdBy: 'Creato da',
  updatedAt: 'Aggiornato il',
  notAvailable: 'Non Disponibile',
  updateStatus: 'Aggiorna Stato',

  // customerInfo
  customerInfoTitle: 'Informazioni Cliente',
  contactPhoneNumber: 'Numero di Telefono',

  //productInfo
  productInfoTitle: 'Dettagli Prodotto',
  productNameUnknown: 'Prodotto Sconosciuto',
  noProducts: 'Nessun prodotto disponibile.',

  //legalInfo
  legalInfoTitle: 'Informazioni Legali',
  ndaTitle: 'Accordo di Riservatezza (NDA)',
  termsTitle: 'Termini e Condizioni',
  documentName: 'Nome Documento',
  documentDescription: 'Descrizione',
  documentContent: 'Contenuto',

  //financialInfo
  financialInfoTitle: 'Informazioni Finanziarie',
  shippingTotal: 'Totale Spedizione',
  total: 'Prezzo Totale',
  noFinancialData: 'Nessun dato finanziario disponibile.',

  // documentInfo
  documentsTitle: 'Documenti',
  documentNameUnknown: 'Documento Sconosciuto',
  mimeType: 'Tipo di File',
  download: 'Scarica',
  noDocuments: 'Nessun documento disponibile.',

  // salesAgents
  salesAgentsTitle: 'Agenti di Vendita',
  unknownAgent: 'Agente Sconosciuto',
  noSalesAgents: 'Nessun agente di vendita disponibile.',

  //signature
  signatureTitle: 'Informazioni Firma',
  additionalInfoTitle: 'Informazioni Aggiuntive',
  signature: 'Firma',
  signerUser: 'Utente Firmatario',

  //additionalInfo
  yes: 'Sì',
  no: 'No',
};

export default locale; 
const locale = {
  // actions
  create: 'Kunde erstellen',
  update: 'Kunde aktualisieren',
  detail: 'Kundendetails',
  view: 'Kunde anzeigen',
  list: 'Kunden auflisten',
  addNewRole: 'Neue Rolle hinzufügen',
  tagsPlaceholder: 'Tags hinzufügen',
  listInfo: 'Liste aller Kunden.',
  weeklyReport: 'Wochenbericht',
  copyBillingInfo: 'Rechnungsinformationen kopieren',

  // situational messages
  createdSuccessfully: 'Kunde erfolgreich erstellt',
  updatedSuccessfully: 'Kunde erfolgreich aktualisiert',
  deleteConfirm: 'Sind Sie sicher, dass Sie diesen Kunden löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  deletedSuccessfully: 'Kunde erfolgreich gelöscht',
  createInfo: 'Hier können Sie neue Kunden erstellen.',
  updateCustomerSuccess: 'Kunde erfolgreich aktualisiert',
  createCustomerSuccess: 'Kunde erfolgreich erstellt',
  emptyState1: 'Keine Kunden gefunden',
  emptyState2: 'Bitte erstellen Sie einen neuen Kunden',

  // import
  importCustomer: 'Kunde importieren',
  import: 'Kunde importieren',
  importTitle: 'Kunden importieren',
  importText1: 'Sie können Kunden von dieser Seite importieren.',
  importText2: 'Sie können das Beispielformular unten verwenden, um Ihre Kunden zu veröffentlichen.',

  // fields and names
  title: 'Kunden',
  customer: 'Kunde',
  customers: 'Kunden',
  assigneds: 'Zugewiesene',
  qualification: 'Qualifikation',
  justOwn: 'Nur eigene',
  isJunk: 'Ist Junk',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Junk',
  unJunk: 'Kein Junk',
  prospectName: 'Interessentenname',
  businessType: 'Geschäftstyp',
  customerType: 'Kundentyp',
  customerCount: 'Kunden',
  customerValue: 'Kundenwert',

  // contact
  addContact: 'Kontakt hinzufügen',
  contactedDate: 'Kontaktdatum',
  emptyStateContactContent1: 'Keine Kontakte gefunden.',
  contactDetail: 'Kontaktdetails',
  contactHistory: 'Kontaktverlauf',
  lastContacted: 'Zuletzt kontaktiert',
  lastContact: 'Letzter Kontakt',
  contacted: 'Kontaktiert',
  otherContacts: 'Andere Kontakte',

  onSiteEstimateWith: 'Vor-Ort-Schätzung mit',

  // new
  customerListTitle: 'Kundenliste',
  headerTitle: 'Kunden',
  relatedCustomers: 'Verwandte Kunden',
  b2bB2c: 'B2B / B2C',
  customerNameLabel: 'Kundenname',
  customerEmailLabel: 'Kunden-E-Mail',
  customerPhoneLabel: 'Kundentelefon',
  customerTypeLabel: 'Kundentyp',
  customerPictureLabel: 'Kundenbild',
  customerLanguageLabel: 'Kundensprache',

  createSuccess: 'Kunde erfolgreich erstellt',

  prospectType: 'Interessententyp',
  prospectAddress: 'Interessentenadresse',
  prospectContactName: 'Interessenten-Kontaktname',

  // accordions
  primaryContact: 'Primärer Kontakt*',
  primaryBillingInfo: 'Primäre Rechnungsinformationen',
  primaryShippingInfo: 'Primäre Versandinformationen',
  detailInformation: 'Detailinformationen',
  address: 'Adresse',

  // labels
  welcome: 'Willkommen',
  description: 'Ihr Kundenfortschritt in dieser Woche ist erstaunlich. Lassen Sie uns weitermachen.',

  // new keys
  customerDetail: 'Kundendetails',
  viewMüşteri: 'Anzeigen',
  createMüşteri: 'Kunde erstellen',
  columnParentCategory: 'Übergeordnete Kategorie',
  columnActions: 'Aktionen',
  createCustomerTitle: 'Kunde erstellen',
  uploadPictureText: 'Bild hochladen',
  createCustomerButton: 'Kunde erstellen',
  selectIconTitle: 'Symbol auswählen',
  searchIcons: 'Symbole suchen',
  searchForAnIcon: 'Nach einem Symbol suchen',
};

export default locale; 
const locale = {
  // actions
  create: 'Créer un Client',
  update: 'Mettre à jour le Client',
  detail: 'Détail du Client',
  view: 'Voir le Client',
  list: 'Liste des Clients',
  addNewRole: 'Ajouter un Nouveau Rôle',
  tagsPlaceholder: 'Ajouter des tags',
  listInfo: 'Liste de tous les clients.',
  weeklyReport: 'Rapport Hebdomadaire',
  copyBillingInfo: 'Copier les Informations de Facturation',

  // situational messages
  createdSuccessfully: 'Client créé avec succès',
  updatedSuccessfully: 'Client mis à jour avec succès',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce client? Cette action ne peut pas être annulée.',
  deletedSuccessfully: 'Client supprimé avec succès',
  createInfo: 'Vous pouvez créer de nouveaux clients ici.',
  updateCustomerSuccess: 'Client mis à jour avec succès',
  createCustomerSuccess: 'Client créé avec succès',
  emptyState1: 'Aucun client trouvé',
  emptyState2: 'Veuillez créer un nouveau client',

  // import
  importCustomer: 'Importer un Client',
  import: 'Importer un Client',
  importTitle: 'Importer des Clients',
  importText1: 'Vous pouvez importer des clients depuis cette page.',
  importText2: 'Vous pouvez utiliser le formulaire exemple ci-dessous pour publier vos clients.',

  // fields and names
  title: 'Clients',
  customer: 'Client',
  customers: 'Clients',
  assigneds: 'Assignés',
  qualification: 'Qualification',
  justOwn: 'Seulement les Miens',
  isJunk: 'Est Indésirable',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Indésirable',
  unJunk: 'Non Indésirable',
  prospectName: 'Nom du Prospect',
  businessType: 'Type d\'Entreprise',
  customerType: 'Type de Client',
  customerCount: 'clients',
  customerValue: 'Valeur du Client',

  // contact
  addContact: 'Ajouter un Contact',
  contactedDate: 'Date de Contact',
  emptyStateContactContent1: 'Aucun contact trouvé ici.',
  contactDetail: 'Détail du Contact',
  contactHistory: 'Historique des Contacts',
  lastContacted: 'Dernier Contact',
  lastContact: 'Dernier Contact',
  contacted: 'Contacté',
  otherContacts: 'Autres Contacts',

  onSiteEstimateWith: 'Estimation sur site avec',

  // new
  customerListTitle: 'Liste des Clients',
  headerTitle: 'Clients',
  relatedCustomers: 'Clients Liés',
  b2bB2c: 'B2B / B2C',
  customerNameLabel: 'Nom du Client',
  customerEmailLabel: 'Email du Client',
  customerPhoneLabel: 'Téléphone du Client',
  customerTypeLabel: 'Type de Client',
  customerPictureLabel: 'Photo du Client',
  customerLanguageLabel: 'Langue du Client',

  createSuccess: 'Client créé avec succès',

  prospectType: 'Type de Prospect',
  prospectAddress: 'Adresse du Prospect',
  prospectContactName: 'Nom du Contact Prospect',

  // accordions
  primaryContact: 'Contact Principal*',
  primaryBillingInfo: 'Informations de Facturation Principales',
  primaryShippingInfo: 'Informations d\'Expédition Principales',
  detailInformation: 'Informations Détaillées',
  address: 'Adresse',

  // labels
  welcome: 'Bienvenue',
  description: 'Votre progression client cette semaine est incroyable. Continuons.',

  // new keys
  customerDetail: 'Détail du Client',
  viewMüşteri: 'Voir',
  createMüşteri: 'Créer un Client',
  columnParentCategory: 'Catégorie Parent',
  columnActions: 'Actions',
  createCustomerTitle: 'Créer un Client',
  uploadPictureText: 'Télécharger une Photo',
  createCustomerButton: 'Créer un Client',
  selectIconTitle: 'Sélectionner une Icône',
  searchIcons: 'Rechercher des Icônes',
  searchForAnIcon: 'Rechercher une icône',
};

export default locale; 
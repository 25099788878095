import {
  IEntity,
  IFilter,
  IGraphqlVariables,
  IHasFilter,
  IHasPagination,
  IPaginated,
  IPagination,
} from "corede-common";
import { Dispatch, SetStateAction, useCallback } from "react";
import { TListGridSelectedEntity } from "../components/list/DefaultList-grid.component";

export interface IPendingNavigation {
  direction: "next" | "back";
  page: number;
}

export interface IHandleSideNavigationNavigateParams<T extends IEntity> {
  selectedEntity: TListGridSelectedEntity<T>;
  setSelectedEntity: Dispatch<SetStateAction<TListGridSelectedEntity<T>>>;
  listFilter: IGraphqlVariables<
    IHasPagination<IPagination> & IHasFilter<IFilter>
  >;
  setListFilter: Dispatch<
    SetStateAction<
      IGraphqlVariables<IHasPagination<IPagination> & IHasFilter<IFilter>>
    >
  >;
  setPendingNavigation: Dispatch<SetStateAction<IPendingNavigation | null>>;
  listData?: IPaginated<T>;
  currentPage: number;
  currentPageSize: number;
}

export const useHandleSideNavigationNavigate = ({
  currentPage,
  currentPageSize,
  listData,
  listFilter,
  setListFilter,
  selectedEntity,
  setSelectedEntity,
  setPendingNavigation,
}: {
  currentPage: number;
  currentPageSize: number;
  listData: any;
  listFilter: any;
  setListFilter: any;
  selectedEntity: any;
  setSelectedEntity: any;
  setPendingNavigation: any;
}) => {
  return useCallback((direction: "next" | "back") => {
    if (!selectedEntity || !listData?.data?.length) return;

    interface ISelectableEntity {
      _id: string;
    }

    const currentIndex: number = listData?.data.findIndex(
      (entity: ISelectableEntity): boolean =>
        entity._id === (selectedEntity as ISelectableEntity)?._id
    );

    let newIndex = currentIndex;
    const totalEntityCount = listData?.count || 0;
    const maxPage = Math.ceil(totalEntityCount / currentPageSize);

    if (direction === "next") {
      newIndex = currentIndex + 1;

      if (newIndex >= listData?.data.length) {
        if (currentPage >= maxPage) return;
        const nextPage = currentPage + 1;
        setListFilter({
          ...listFilter,
          input: {
            ...listFilter.input,
            pagination: {
              ...listFilter.input?.pagination,
              page: nextPage,
            },
          },
        });
        setPendingNavigation({
          direction: "next",
          page: nextPage,
        });
        return;
      }
    } else if (direction === "back") {
      newIndex = currentIndex - 1;
      if (newIndex < 0) {
        if (currentPage <= 1) return;
        const previousPage = currentPage - 1;
        setListFilter({
          ...listFilter,
          input: {
            ...listFilter.input,
            pagination: {
              ...listFilter.input?.pagination,
              page: previousPage,
            },
          },
        });
        setPendingNavigation({
          direction: "back",
          page: previousPage,
        });
        return;
      }
    }

    const globalId =
      (currentPage - 1) * currentPageSize + (newIndex + 1);
    setSelectedEntity({
      ...listData?.data[newIndex],
      id: globalId,
    });
  }, [
    currentPage,
    currentPageSize,
    listData,
    listFilter, // added dependency
    setListFilter,
    selectedEntity,
    setSelectedEntity,
    setPendingNavigation,
  ]);
};

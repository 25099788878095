const locale = {
  somethingWentWrong: '¡Algo salió mal!',
  loginSuccess: 'Inicio de sesión exitoso',
  signinText: 'Inicia sesión.',
  emailAddress: 'Correo electrónico',
  password: 'Contraseña',
  forgotPassword: '¿Olvidaste tu contraseña?',
  login: 'Iniciar sesión',
  continueWith: 'o continuar con',
  noAccount: '¿No tienes una cuenta?',
  signUp: 'Registrarse',
  logoAlt: 'logo',
  growYourBusiness: 'Todo lo que necesitas para hacer crecer tu',
  business: 'Negocio',
  bgPattern: 'patrón de fondo',
  manageYourBusiness:
    'Administra tu negocio de manera eficiente, aumenta tus ventas, llega a clientes potenciales y empodérate uniéndote a la comunidad.',

  quote1: 'Tus clientes más insatisfechos son tu mayor fuente de aprendizaje.',
  quoteName1: 'Bill Gates',
  quoteCompany1: 'Microsoft',
  quoteImage1: 'Bill-Gates',

  quote2: 'El servicio al cliente no debería ser un departamento. Debería ser toda la empresa.',
  quoteName2: 'Tony Hsieh',
  quoteCompany2: 'Zappos',
  quoteImage2: 'Tony-Hsieh',

  quote3: 'La única razón por la que estamos en el negocio es para hacer la vida menos difícil para nuestros clientes.',
  quoteName3: 'Matthew Odgers',
  quoteCompany3: 'Odgers Law Group',
  quoteImage3: 'Matthew-Odgers',

  quote4: 'Si no cuidas a tu cliente, tu competencia lo hará.',
  quoteName4: 'Bob Hooey',
  quoteCompany4: 'Ideaman',
  quoteImage4: 'Bob-Hooey',

  quote5: 'En ventas, una referencia es la llave para la puerta de la resistencia.',
  quoteName5: 'Bo Bennett',
  quoteCompany5: 'Archieboy',
  quoteImage5: 'Bo-Bennett',

  quote6: 'Siempre da lo mejor de ti. Lo que siembras hoy, lo cosecharás más tarde.',
  quoteName6: 'Og Mandino',
  quoteCompany6: 'Author',
  quoteImage6: 'Og-Mandino',

  quote7: 'Uno de los mejores regalos que puedes dar a alguien es el regalo de tu atención.',
  quoteName7: 'Jim Rohn',
  quoteCompany7: 'Author',
  quoteImage7: 'Jim-Rohn',

  quote8: 'Haz lo que haces tan bien que querrán volver a verlo y traer a sus amigos.',
  quoteName8: 'Walt Disney',
  quoteCompany8: 'Disney',
  quoteImage8: 'Walt-Disney',

  passwordResetSuccess: '¡Tu contraseña ha sido restablecida con éxito!',
  resetPasswordTitle: 'Restablecer Contraseña',
  enterNewPassword: 'Por favor, ingresa tu nueva contraseña.',
  passwordAgain: 'Confirmar Contraseña',
  resetPassword: 'Restablecer Contraseña',
  alreadyHaveAccount: '¿Ya tienes una cuenta?',
  resetPasswordRequestSuccess: 'Solicitud de restablecimiento de contraseña exitosa',
  forgotPasswordTitle: 'Olvidé mi Contraseña',
  resetPasswordInstructions: 'Puedes restablecer tu contraseña ingresando tu correo electrónico.',
  rememberPassword: '¿Recuerdas tu contraseña?',
  userConfirmedSuccess: '¡Usuario confirmado con éxito!',
  emailConfirmationTitle: 'Confirmación de Correo',
  TwoFAConfirmationTitle: 'Confirmación 2FA',
  enterOtp: 'Por favor, ingresa el código OTP enviado a tu correo.',
  submit: 'Verificar',
  didNotReceiveCode: '¿No recibiste el código?',
  resendCode: 'Reenviar código',
  resendCodeTitle: 'Reenviar Código de Confirmación',
  enterEmailToResend: 'Por favor, ingresa tu correo electrónico para recibir el código de confirmación.',
  email: 'Correo electrónico',
  close: 'Cerrar',
  send: 'Enviar',
  signupSuccess: 'Registro exitoso',
  letsRegister: 'Vamos a registrarte.',
  name: 'Nombre',
  surname: 'Apellido',
  iAccept: 'Acepto los',
  and: 'y',
  with: 'y',
  termsAndConditions: 'Términos y Condiciones',
  privacyPolicy: 'Política de Privacidad',
  explicitConsent: 'Política de Consentimiento Explícito',
  kvkkPolicy: 'Política de Información KVKK',
  orContinueWith: 'o continuar con',
  createPassword: 'Crear Contraseña',
  createPasswordTitle: 'Crear Contraseña',
  signupSuccessfullTitle: '¡Registro exitoso!',
  signupSuccessfullDescription: 'Por favor, revisa tu correo para confirmar tu cuenta.',
  backToLogin: 'Volver al Inicio de Sesión',
  resendConfirmationSuccessTitle: 'Código de Confirmación Reenviado con Éxito',
  resendConfirmationSuccessText: 'Por favor, revisa tu correo para confirmar tu cuenta.',
  resentCodeSuccess: 'Código reenviado con éxito',
  acceptAllPolicies: '¡Por favor, acepta todas las políticas!',
  alreadyRegisteredError: '¡Ya estás registrado! Por favor, inicia sesión.',
  userNotFoundError: '¡Usuario no encontrado!',

  proposal: 'Propuesta',
  estimate: 'Presupuesto',
  invoice: 'Factura',
  startDate: 'Fecha de Inicio',
  expireDate: 'Fecha de Vencimiento',
  deadline: 'Fecha Límite',
  proposalInformation: 'Información de la Propuesta',
  estimateInformation: 'Información del Presupuesto',
  invoiceInformation: 'Información de la Factura',
  billTo: 'Facturar a',
  bankName: 'Nombre del Banco',
  country: 'País',
  iban: 'IBAN',
  currency: 'Moneda',
  productDescription: 'Descripción del Producto',
  qty: 'CANT',
  rate: 'Tarifa',
  tax: 'Impuesto',
  amount: 'Monto',
  projectTimeline: 'Cronograma del Proyecto',
  timeline: 'Cronograma',
  accept: 'Aceptar',
  decline: 'Rechazar',
  download: 'Descargar',
  comments: 'Comentarios',
  documents: 'Documentos',
  uploadDocument: 'Subir documento',
  addDocument: 'Agregar Documento',

  estimateAccepted: 'Presupuesto aceptado',
  estimateDeclined: 'Presupuesto rechazado',
  proposalAccepted: 'Propuesta aceptada',
  proposalDeclined: 'Propuesta rechazada',
  invoiceAccepted: 'Factura aceptada',
  invoiceDeclined: 'Factura rechazada',
  declineReason: 'Motivo del rechazo',
  declineReasonPlaceholder: 'Por favor, ingresa el motivo del rechazo'
};

export default locale;

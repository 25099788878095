const locale = {
  contacts: 'Contatti',
  title: 'Contatti',
  createContact: 'Crea Contatto',
  columnId: '#',
  columnName: 'Nome',
  columnIcon: 'Icona',
  columnDepartment: 'Dipartimento',
  columnParentCategory: 'Categoria Principale',
  columnActions: 'Azioni',
  emptyStateContent1: 'Nessun contatto trovato',
  emptyStateContent2: 'Si prega di creare un nuovo contatto',
  selectIconTitle: 'Seleziona un\'Icona',
  searchIcons: 'Cerca Icone',
  relatedContacts: 'Contatti Correlati',
  b2bB2c: 'B2B / B2C',
  createContactTitle: 'Crea Contatto',
  contactNameLabel: 'Nome del Contatto',
  contactEmailLabel: 'Email del Contatto',
  contactPhoneLabel: 'Telefono del Contatto',
  contactTypeLabel: 'Tipo di Contatto',
  contactPictureLabel: 'Immagine del Contatto',
  departmentLabel: 'Dipartimento',
  categoryLabel: 'Categoria',
  contactLanguageLabel: 'Lingua del Contatto',
  countryLabel: 'Paese',
  stateLabel: 'Stato',
  cityLabel: 'Città',
  uploadPictureText: 'Carica un\'immagine',
  createContactButton: 'Crea Contatto',
  createSuccess: 'Contatto creato con successo',
  delete: 'Elimina',
  deleteConfirm: 'Sei sicuro di voler eliminare questo contatto? Questa azione non può essere annullata.',
  deletedSuccessfully: 'Contatto eliminato con successo',
  cancel: 'Annulla',
  id: '#',
  firstName: 'Nome',
  lastName: 'Cognome',
  email: 'Email',
  type: 'Tipo',
  phone: 'Telefono',
  position: 'Posizione',
  language: 'Lingua',
  update: 'Aggiorna Contatto',
  updateContactButton: 'Aggiorna Contatto',
  customerId: 'Seleziona un Cliente',
  primary: 'Principale',
  customer: 'Cliente',
  actions: 'Azioni',
  create: 'Crea Contatto',
  emptyState1: 'Nessun contatto trovato qui.',
  emptyState2: 'Puoi creare o importare contatti immediatamente.',
  deletePrimaryError: 'Il contatto principale non può essere eliminato.',
  updateSuccess: 'Contatto aggiornato con successo',
};

export default locale; 
const locale = {
  appointments: 'Compromissos',
  appointment: 'Compromisso',
  appointmentConversions: 'Conversões de Compromissos',
  title: 'Compromissos',
  create: 'Criar Compromisso',
  update: 'Atualizar Compromisso',
  delete: 'Excluir Compromisso',
  details: 'Detalhes do Compromisso',
  createSuccess: 'Compromisso criado com sucesso.',
  updateSuccess: 'Compromisso atualizado com sucesso.',
  emptyState1: 'Nenhum compromisso agendado ainda.',
  emptyState2:
    'Configure e gerencie seus compromissos aqui para se manter organizado e se conectar com seus clientes sem problemas.',
  statusChangeSuccess: 'Status do compromisso alterado com sucesso.',
  deleteConfirm: 'Tem certeza de que deseja excluir este compromisso?',
  deletedSuccessfully: 'Compromisso excluído com sucesso.',

  //fields
  startTime: 'Hora de início',
  endTime: 'Hora de término',
  timezone: 'Fuso horário',
  location: 'Localização',
  organizer: 'Organizador',
  targetType: 'Tipo de alvo',
  attendees: 'Participantes',
  category: 'Categoria',
  meetingLink: 'Link da reunião',
  duration: 'Duração',

  //detail drawer
  changeStatus: 'Alterar status',
  generalInfoTitle: 'Informações gerais',
  organizerInfoTitle: 'Informações do organizador',
  appointmentInfoTitle: 'Informações do compromisso',
};

export default locale; 
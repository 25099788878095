import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Box, Typography, Stack, Paper, Grid, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { Column, Id, Task } from './MilestoneKanban.component';
import { useSortable, SortableContext } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TaskCard from './MilestoneKanban-taskCard.component';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { ITask, TaskStatus } from 'corede-common-cocrm';
import { OutlinedButton } from 'components';
import { useTranslation } from 'react-i18next';
import { MoreVert, Edit, Delete } from '@mui/icons-material';

interface Props {
  column: Column;
  tasks: Task[];
  updateTask: (id: Id, content: string) => void;
  setSelectedTask: Dispatch<SetStateAction<TListGridSelectedEntity<ITask>>>;
  setOpenTaskDetailDrawer: Dispatch<SetStateAction<boolean>>;
  handleLoadMore: (status: TaskStatus) => void;
  taskCount?: number;
  onEditMilestone?: (milestoneId: Id) => void;
  onDeleteMilestone?: (milestoneId: Id) => void;
}

const ColumnContainer: React.FC<Props> = ({
  column,
  tasks,
  updateTask,
  setSelectedTask,
  setOpenTaskDetailDrawer,
  handleLoadMore,
  taskCount = 0,
  onEditMilestone,
  onDeleteMilestone,
}) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleEditClick = () => {
    if (onEditMilestone && column.id !== 'uncategorized') {
      onEditMilestone(column.id);
    }
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    if (onDeleteMilestone && column.id !== 'uncategorized') {
      onDeleteMilestone(column.id);
    }
    handleMenuClose();
  };

  const tasksIds = useMemo(() => tasks.map((task) => task.id), [tasks]);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: column.id,
    data: { type: 'Column', column },
    disabled: editMode,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <Box
        ref={setNodeRef}
        sx={{
          // width: 250,
          height: 700,
          border: '2px solid',
          borderColor: 'primary.main',
          bgcolor: '#FF7D7D0D',
          borderRadius: 2,
        }}
        style={style}
      />
    );
  }

  return (
    <Paper
      ref={setNodeRef}
      elevation={3}
      sx={{
        width: 250,
        height: 700,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        boxShadow: '0px 0px 5px rgba(200,200, 200, 0.5)',
      }}
      style={style}
    >
      {/* Column Header */}
      <Box
        {...attributes}
        {...listeners}
        sx={{
          color: 'primary.contrastText',
          bgcolor: '#FF7D7D0D',
          py: 2,
          px: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'grab',
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {!editMode && <Typography variant="body1">{column.title}</Typography>}
          <Typography
            variant="body2"
            sx={{
              bgcolor: 'secondary.extraLight',
              color: 'secondary.main',
              padding: '4px 8px',
              borderRadius: 1,
            }}
          >
            {tasks.length}
          </Typography>
        </Stack>
        {column.id !== 'uncategorized' && (
          <>
            <IconButton 
              sx={{ color: 'primary.main' }}
              onClick={handleMenuClick}
              size="small"
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              onClick={(e) => e.stopPropagation()}
            >
              <MenuItem onClick={handleEditClick}>
                <Edit fontSize="small" sx={{ mr: 1 }} />
                {t('crm.edit')}
              </MenuItem>
              <MenuItem onClick={handleDeleteClick}>
                <Delete fontSize="small" sx={{ mr: 1 }} />
                {t('crm.delete')}
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>

      {/* Tasks */}
      <Box
        sx={{
          flexGrow: 1,
          padding: 2,
          overflowY: 'auto',
          bgcolor: '#FF7D7D0D',
        }}
      >
        <SortableContext items={tasksIds}>
          {tasks.map((task) => (
            <TaskCard
              key={task.id}
              task={task}
              updateTask={updateTask}
              setSelectedTask={setSelectedTask}
              setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
            />
          ))}
        </SortableContext>
        {/* TODO task lengthi page sizedan getir */}
        {tasks.length > 5 && (
          <Box sx={{ textAlign: 'center', marginTop: 2 }}>
            <OutlinedButton
              fullWidth
              title={t('loadMore')}
              onClick={() => handleLoadMore(column.id as TaskStatus)}
              disabled={tasks.length === 0 || tasks.length % 2 !== 0 || taskCount === tasks.length}
            />
          </Box>
        )}
      </Box>

      {/* Column Footer */}
    </Paper>
  );
};

export default ColumnContainer;

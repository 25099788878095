const locale = {
  returns: 'Devoluciones',
  return: 'Devolución',
  returnConversions: 'Conversiones de devolución',
  title: 'Devoluciones',
  create: 'Añadir devolución',
  update: 'Actualizar devolución',
  delete: 'Eliminar devolución',
  createSuccess: 'Devolución añadida con éxito.',
  updateSuccess: 'Devolución actualizada con éxito.',
  deleteSuccess: 'Devolución eliminada con éxito.',
  deleteConfirm: '¿Está seguro de que desea eliminar esta devolución? Esta acción no se puede deshacer.',
  emptyState1: 'Aún no hay devoluciones registradas.',
  emptyState2:
    'Gestione y revise aquí cualquier devolución de productos o servicios para mantener sus registros precisos y satisfacer las necesidades de los clientes.',

  // detailDrawer
  generalInfoTitle: 'Información general',
  isOpen: 'Está abierto',
  refundAmount: 'Importe de reembolso',
  resolveReason: 'Motivo de resolución',
  disapproveReason: 'Motivo de desaprobación',
  returnProducts: 'Productos devueltos',
  amount: 'Importe',
  reason: 'Motivo',
  isPartial: 'Es parcial',
  note: 'Nota',
  statusChangeSuccess: 'Estado cambiado con éxito',
  changeStatus: 'Cambiar estado',
};

export default locale; 
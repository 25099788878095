const locale = {
  leadStatuses: "Status de Lead",
  title: "Status de Lead",
  create: "Criar Status de Lead",
  delete: "Excluir Status de Lead",
  deleteConfirm: "Tem certeza que deseja excluir este status: ",
  deletedSuccessfully: "Status de lead excluído com sucesso",
  createSuccess: "Status de lead criado com sucesso",
  update: "Atualizar Status de Lead",
  updateSuccess: "Status de lead atualizado com sucesso",
  selectColor: "Selecionar Cor",
  selectIcon: "Selecionar Ícone",
};

export default locale; 
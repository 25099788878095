const locale = {
  service: 'Serviços',
  title: 'Serviços',
  create: 'Criar Serviço',
  update: 'Atualizar Serviço',
  delete: 'Excluir Serviço',
  deleteConfirm: 'Tem certeza de que deseja excluir este status: ',
  deletedSuccessfully: 'Serviço excluído com sucesso',
  createService: 'Criar Serviço',
  createSuccess: 'Serviço criado com sucesso',
  updateSuccess: 'Serviço atualizado com sucesso',
  name: 'Nome',
  icon: 'Ícone',
  showMore: 'Mostrar Mais',

  detail: 'Detalhe do Serviço',
  type: 'Tipo',
  image: 'Imagem',
  sku: 'Código',
  description: 'Descrição',
  currency: 'Moeda',
  price: 'Preço',
  priceType: 'Tipo de Preço',
  discountedPrice: 'Preço com Desconto',
  unit: 'Unidade',
  categoryId: 'Categoria',
  natTaxRate: 'Taxa de Imposto Nacional',
  intTaxRate: 'Taxa de Imposto Internacional',

  categories: 'Categorias',
  rate: 'Taxa',
  rateType: 'Tipo de Taxa',
  discountedRate: 'Taxa com Desconto',
  emptyState1: 'Nenhum serviço registrado ainda.',
  emptyState2:
    'Gerencie e revise aqui quaisquer devoluções de serviço para manter seus registros precisos e atender às necessidades dos clientes.',
};

export default locale; 
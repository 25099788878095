import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  IInvoiceReturnListInput,
  IInvoiceReturnListRequest,
  IInvoiceReturnListResponse,
  IInvoiceReturnListResult,
  invoiceReturnListQuery,
  IInvoiceReturnDetailInput,
  IInvoiceReturnDetailRequest,
  IInvoiceReturnDetailResponse,
  IInvoiceReturnDetailResult,
  invoiceReturnDetailQuery,

  // mutations
  IInvoiceReturnAddInput,
  IInvoiceReturnAddRequest,
  IInvoiceReturnAddResponse,
  invoiceReturnAddQuery,
  IInvoiceReturnUpdateInput,
  IInvoiceReturnUpdateRequest,
  IInvoiceReturnUpdateResponse,
  invoiceReturnUpdateQuery,
  IInvoiceReturnUpdateFilterInput,
  IInvoiceReturnDeleteInput,
  IInvoiceReturnDeleteRequest,
  IInvoiceReturnDeleteResponse,
  invoiceReturnDeleteQuery,
  IInvoiceReturnAddFilterInput,
  invoiceReturnApproveQuery,
  IInvoiceReturnApproveInput,
  IInvoiceReturnApproveRequest,
  IInvoiceReturnApproveResponse,
  invoiceReturnDisapproveQuery,
  IInvoiceReturnDisapproveInput,
  IInvoiceReturnDisapproveRequest,
  IInvoiceReturnDisapproveResponse,
  invoiceReturnInspectQuery,
  IInvoiceReturnInspectInput,
  IInvoiceReturnInspectRequest,
  IInvoiceReturnInspectResponse,
  invoiceReturnResolveQuery,
  IInvoiceReturnResolveInput,
  IInvoiceReturnResolveRequest,
  IInvoiceReturnResolveResponse,
  IInvoiceReturnApproveResult,
  IInvoiceReturnDisapproveResult,
  IInvoiceReturnDisapproveFilterInput,
  IInvoiceReturnInspectResult,
  IInvoiceReturnResolveResult,
  IInvoiceReturnResolveFilterInput,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { returnTags } from './return.tags';
import { IGraphqlVariables, IBaseResult, IEntity } from 'corede-common';
import { gql } from '@apollo/client';

const invoiceReturnResolveQueryChanged = gql`
  mutation InvoiceReturnResolve(
    $filter: InvoiceReturnResolveFilterInput!
    $input: InvoiceReturnResolveInput!
  ) {
    invoiceReturnResolve(filter: $filter, input: $input) {
      success
    }
  }
`;

const invoiceReturnInspectQueryChanged = gql`
  mutation invoiceReturnInspect($input: InvoiceReturnInspectInput!) {
    invoiceReturnInspect(input: $input) {
      success
    }
  }
`;

const invoiceReturnDisapproveQueryChanged = gql`
  mutation InvoiceReturnDisapprove(
    $filter: InvoiceReturnDisapproveFilterInput!
    $input: InvoiceReturnDisapproveInput!
  ) {
    invoiceReturnDisapprove(filter: $filter, input: $input) {
      success
    }
  }
`;

const invoiceReturnApproveQueryChanged = gql`
  mutation invoiceReturnApprove($input: InvoiceReturnApproveInput!) {
    invoiceReturnApprove(input: $input) {
      success
    }
  }
`;

export const invoiceReturnsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    invoiceReturnList: builder.query<
      IInvoiceReturnListResult,
      IGraphqlVariables<IInvoiceReturnListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceReturnListRequest,
        IInvoiceReturnListResponse,
        IInvoiceReturnListResult,
        IInvoiceReturnListInput
      >({
        query: invoiceReturnListQuery,
        providesTags: [returnTags.returns],
      }),
    ),

    invoiceReturnDetail: builder.query<
      IInvoiceReturnDetailResult,
      IGraphqlVariables<IInvoiceReturnDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceReturnDetailRequest,
        IInvoiceReturnDetailResponse,
        IInvoiceReturnDetailResult,
        IInvoiceReturnDetailInput
      >({
        query: invoiceReturnDetailQuery,
        providesTags: [returnTags.return],
      }),
    ),

    // mutations

    invoiceReturnAdd: builder.mutation<
      IBaseResult,
      IGraphqlVariables<IInvoiceReturnAddInput, IInvoiceReturnAddFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceReturnAddRequest,
        IInvoiceReturnAddResponse,
        IBaseResult,
        IInvoiceReturnAddInput,
        IInvoiceReturnAddFilterInput
      >({
        query: invoiceReturnAddQuery,
        invalidatesTags: [returnTags.return, returnTags.returns],
      }),
    ),

    invoiceReturnUpdate: builder.mutation<
      IBaseResult,
      IGraphqlVariables<IInvoiceReturnUpdateInput, IInvoiceReturnUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceReturnUpdateRequest,
        IInvoiceReturnUpdateResponse,
        IBaseResult,
        IInvoiceReturnUpdateInput,
        IInvoiceReturnUpdateFilterInput
      >({
        query: invoiceReturnUpdateQuery,
        invalidatesTags: [returnTags.return, returnTags.returns],
      }),
    ),

    invoiceReturnDelete: builder.mutation<
      IBaseResult,
      IGraphqlVariables<IInvoiceReturnDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceReturnDeleteRequest,
        IInvoiceReturnDeleteResponse,
        IBaseResult,
        IInvoiceReturnDeleteInput
      >({
        query: invoiceReturnDeleteQuery,
        invalidatesTags: [returnTags.returns],
      }),
    ),

    invoiceReturnApprove: builder.mutation<
      IInvoiceReturnApproveResult,
      IGraphqlVariables<IInvoiceReturnApproveInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceReturnApproveRequest,
        IInvoiceReturnApproveResponse,
        IInvoiceReturnApproveResult,
        IInvoiceReturnApproveInput
      >({
        query: invoiceReturnApproveQueryChanged,
        invalidatesTags: [returnTags.return, returnTags.returns],
      }),
    ),

    invoiceReturnDisapprove: builder.mutation<
      IInvoiceReturnDisapproveResult,
      IGraphqlVariables<IInvoiceReturnDisapproveInput, IInvoiceReturnDisapproveFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceReturnDisapproveRequest,
        IInvoiceReturnDisapproveResponse,
        IInvoiceReturnDisapproveResult,
        IInvoiceReturnDisapproveInput,
        IInvoiceReturnDisapproveFilterInput
      >({
        query: invoiceReturnDisapproveQueryChanged,
        invalidatesTags: [returnTags.return, returnTags.returns],
      }),
    ),

    invoiceReturnInspect: builder.mutation<
      IInvoiceReturnInspectResult,
      IGraphqlVariables<IInvoiceReturnInspectInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceReturnInspectRequest,
        IInvoiceReturnInspectResponse,
        IInvoiceReturnInspectResult,
        IInvoiceReturnInspectInput
      >({
        query: invoiceReturnInspectQueryChanged,
        invalidatesTags: [returnTags.return, returnTags.returns],
      }),
    ),

    invoiceReturnResolve: builder.mutation<
      IInvoiceReturnResolveResult,
      IGraphqlVariables<IInvoiceReturnResolveInput, IInvoiceReturnResolveFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceReturnResolveRequest,
        IInvoiceReturnResolveResponse,
        IInvoiceReturnResolveResult,
        IInvoiceReturnResolveInput,
        IInvoiceReturnResolveFilterInput
      >({
        query: invoiceReturnResolveQueryChanged,
        invalidatesTags: [returnTags.return, returnTags.returns],
      }),
    ),
  }),
});

export const {
  useInvoiceReturnListQuery,
  useLazyInvoiceReturnListQuery,
  useInvoiceReturnDetailQuery,
  useLazyInvoiceReturnDetailQuery,
  useInvoiceReturnAddMutation,
  useInvoiceReturnUpdateMutation,
  useInvoiceReturnDeleteMutation,
  useInvoiceReturnApproveMutation,
  useInvoiceReturnDisapproveMutation,
  useInvoiceReturnInspectMutation,
  useInvoiceReturnResolveMutation,
} = invoiceReturnsApi;

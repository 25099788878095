const locale = {
  title: "Categorias de Clientes",
  headerTitle: "Categorias de Clientes",
  create: "Criar Categoria de Cliente",
  columnId: "ID",
  columnName: "Nome",
  columnRoles: "Funções",
  columnActions: "Ações",
  rolesButton: "Editar Funções",
  createCustomerCategoryTitle: "Criar Nova Categoria de Cliente",
  customerCategoryNameLabel: "Nome da Categoria de Cliente",
  rolesLabel: "Funções",
  createCustomerCategoryButton: "Criar",
  emptyStateContent1: "Nenhuma categoria de cliente encontrada",
  emptyStateContent2:
    "Você pode criar novas categorias de clientes clicando no botão abaixo.",
  addNewRole: "Adicionar Nova Função",
  deletedSuccessfully: "Categoria de cliente excluída com sucesso",
  deleteConfirm: "Tem certeza de que deseja excluir esta categoria de cliente?",
  delete: "Excluir",
  enTranslation: "Tradução em Inglês",
  trTranslation: "Tradução em Turco",
  department: "Departamento",
  parentCategory: "Categoria Principal",
  icon: "Ícone",
  name: "Nome",
  createCustomerCategorySuccess: "Categoria de cliente criada com sucesso",
  addDepartment: "Adicionar Departamento",
  showMore: "Mostrar Mais",
  columnIcon: "Ícone",
  columnDepartment: "Departamento",
  columnParentCategory: "Categoria Principal",
  selectIconTitle: "Selecionar um Ícone",
  searchIcons: "Pesquisar Ícones",
  update: "Atualizar Categoria de Cliente",
  updateCustomerCategorySuccess: "Categoria de cliente atualizada com sucesso",
  actions: "Ações",
  createdAt: "Criado Em",
  emptyState1: "Nenhuma categoria de cliente encontrada",
  emptyState2: "Você pode criar novas categorias de clientes clicando no botão abaixo.",
};

export default locale; 
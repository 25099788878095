const locale = {
  somethingWentWrong: 'Qualcosa è andato storto!',
  loginSuccess: 'Accesso effettuato con successo',
  signinText: 'Accedi.',
  emailAddress: 'Indirizzo email',
  password: 'Password',
  forgotPassword: 'Password dimenticata?',
  login: 'Accedi',
  continueWith: 'o continua con',
  noAccount: 'Non hai un account?',
  signUp: 'Registrati',
  logoAlt: 'logo',
  growYourBusiness: 'Tutto ciò di cui hai bisogno per far crescere la tua',
  business: 'Attività',
  bgPattern: 'pattern di sfondo',
  manageYourBusiness:
    'Gestisci la tua attività in modo efficiente, aumenta le tue vendite, raggiungi potenziali clienti e rafforzati unendoti alla community.',

  quote1: 'I clienti più insoddisfatti sono la tua più grande fonte di apprendimento.',
  quoteName1: 'Bill Gates',
  quoteCompany1: 'Microsoft',
  quoteImage1: 'Bill-Gates',

  quote2: 'Il servizio clienti non dovrebbe essere un dipartimento. Dovrebbe essere l\'intera azienda.',
  quoteName2: 'Tony Hsieh',
  quoteCompany2: 'Zappos',
  quoteImage2: 'Tony-Hsieh',

  quote3: 'L\'unica ragione per cui siamo in attività è rendere la vita meno difficile per i nostri clienti.',
  quoteName3: 'Matthew Odgers',
  quoteCompany3: 'Odgers Law Group',
  quoteImage3: 'Matthew-Odgers',

  quote4: 'Se non ti prendi cura del tuo cliente, lo farà il tuo concorrente.',
  quoteName4: 'Bob Hooey',
  quoteCompany4: 'Ideaman',
  quoteImage4: 'Bob-Hooey',

  quote5: 'Nelle vendite, una referenza è la chiave della porta della resistenza.',
  quoteName5: 'Bo Bennett',
  quoteCompany5: 'Archieboy',
  quoteImage5: 'Bo-Bennett',

  quote6: 'Fai sempre del tuo meglio. Ciò che pianti ora, lo raccoglierai più tardi.',
  quoteName6: 'Og Mandino',
  quoteCompany6: 'Author',
  quoteImage6: 'Og-Mandino',

  quote7: 'Uno dei più grandi doni che puoi fare a qualcuno è il dono della tua attenzione.',
  quoteName7: 'Jim Rohn',
  quoteCompany7: 'Author',
  quoteImage7: 'Jim-Rohn',

  quote8: 'Fai ciò che fai così bene che vorranno rivederlo e portare i loro amici.',
  quoteName8: 'Walt Disney',
  quoteCompany8: 'Disney',
  quoteImage8: 'Walt-Disney',

  passwordResetSuccess: 'La tua password è stata reimpostata con successo!',
  resetPasswordTitle: 'Reimposta Password',
  enterNewPassword: 'Inserisci la tua nuova password.',
  passwordAgain: 'Conferma Password',
  resetPassword: 'Reimposta Password',
  alreadyHaveAccount: 'Hai già un account?',
  resetPasswordRequestSuccess: 'Richiesta di reimpostazione password inviata con successo',
  forgotPasswordTitle: 'Password Dimenticata',
  resetPasswordInstructions: 'Puoi reimpostare la tua password inserendo il tuo indirizzo email.',
  rememberPassword: 'Ricordi la tua password?',
  userConfirmedSuccess: 'Utente confermato con successo!',
  emailConfirmationTitle: 'Conferma Email',
  TwoFAConfirmationTitle: 'Conferma 2FA',
  enterOtp: 'Inserisci il codice OTP inviato alla tua email.',
  submit: 'Verifica',
  didNotReceiveCode: 'Non hai ricevuto il codice?',
  resendCode: 'Invia di nuovo il codice',
  resendCodeTitle: 'Invia di nuovo il codice di conferma',
  enterEmailToResend: 'Inserisci il tuo indirizzo email per ricevere il codice di conferma.',
  email: 'Email',
  close: 'Chiudi',
  send: 'Invia',
  signupSuccess: 'Registrazione completata con successo',
  letsRegister: 'Registriamoci.',
  name: 'Nome',
  surname: 'Cognome',
  iAccept: 'Accetto i',
  and: 'e',
  with: 'e',
  termsAndConditions: 'Termini e Condizioni',
  privacyPolicy: 'Politica sulla Privacy',
  explicitConsent: 'Politica di Consenso Esplicito',
  kvkkPolicy: 'Politica Informativa KVKK',
  orContinueWith: 'o continua con',
  createPassword: 'Crea Password',
  createPasswordTitle: 'Crea Password',
  signupSuccessfullTitle: 'Registrazione completata con successo!',
  signupSuccessfullDescription: 'Controlla la tua email per confermare il tuo account.',
  backToLogin: 'Torna al Login',
  resendConfirmationSuccessTitle: 'Codice di conferma inviato con successo',
  resendConfirmationSuccessText: 'Controlla la tua email per confermare il tuo account.',
  resentCodeSuccess: 'Codice inviato nuovamente con successo',
  acceptAllPolicies: 'Accetta tutte le politiche!',
  alreadyRegisteredError: 'Sei già registrato! Effettua il login.',
  userNotFoundError: 'Utente non trovato!',

  proposal: 'Proposta',
  estimate: 'Preventivo',
  invoice: 'Fattura',
  startDate: 'Data di inizio',
  expireDate: 'Data di scadenza',
  deadline: 'Scadenza',
  proposalInformation: 'Informazioni sulla proposta',
  estimateInformation: 'Informazioni sul preventivo',
  invoiceInformation: 'Informazioni sulla fattura',
  billTo: 'Fatturare a',
  bankName: 'Nome della banca',
  country: 'Paese',
  iban: 'IBAN',
  currency: 'Valuta',
  productDescription: 'Descrizione del prodotto',
  qty: 'QTÀ',
  rate: 'Tariffa',
  tax: 'Imposta',
  amount: 'Importo',
  projectTimeline: 'Tempistica del progetto',
  timeline: 'Tempistica',
  accept: 'Accetta',
  decline: 'Rifiuta',
  download: 'Scarica',
  comments: 'Commenti',
  documents: 'Documenti',
  uploadDocument: 'Carica un documento',
  addDocument: 'Aggiungi documento',

  estimateAccepted: 'Preventivo accettato',
  estimateDeclined: 'Preventivo rifiutato',
  proposalAccepted: 'Proposta accettata',
  proposalDeclined: 'Proposta rifiutata',
  invoiceAccepted: 'Fattura accettata',
  invoiceDeclined: 'Fattura rifiutata',
  declineReason: 'Motivo del rifiuto',
  declineReasonPlaceholder: 'Inserisci il motivo del rifiuto'
};

export default locale;

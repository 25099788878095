import serviceLocale from "../subdomains/service/locales/es";
import productLocale from "../subdomains/product/locales/es";
import categoryLocale from "../subdomains/productCategory/locales/es";

const locale = {
  product: {
    ...productLocale,
  },
  productCategory: {
    ...categoryLocale,
  },
  service: {
    ...serviceLocale,
  },
};

export default locale; 
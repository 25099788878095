import settingsLocale from "../domains/settings/locales/it";

const locale = {
  settings: {
    ...settingsLocale,
  },
  demoo: "Richiesta di Preventivo",
  settingsLabel: "Impostazioni",
  paymentSettings: "Pagamento",
  menuSettings: "Impostazioni Menu",
  crmSettings: "CRM",
  leadPlusSettings: "Lead Plus",
  marketingSettings: "Marketing",
  windowSettings: "Finestra",
  communitySettings: "Comunità",
  notificationSettings: "Notifica",
  organizationSettings: "Organizzazione",
  myOrganization: "La Mia Organizzazione",
  generalSettings: "Generale",
  moduleSettings: "Impostazioni Modulo",
  userSettings: "Impostazioni Utente",
  integrations: "Integrazioni",
  legalAgreements: "Accordi Legali",
};

export default locale; 
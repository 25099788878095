const locale = {
  leadForms: 'Formularios de Leads',
  title: 'Formularios de Leads',
  newForm: 'Nuevo Formulario',
  emptyStateDesc1:
    'Sus formularios de leads aparecerán aquí a medida que los cree para capturar información valiosa de los clientes.',
  emptyStateDesc2:
    'Gestione y personalice fácilmente sus formularios para asegurarse de que está recopilando los detalles correctos para cada interacción.',

  create: 'Crear Formulario de Lead',
  update: 'Actualizar Formulario de Lead',
  updateSuccess: 'Formulario de lead actualizado con éxito',
  createSuccess: 'Formulario de lead creado con éxito',
  deleteSuccess: 'Formulario de lead eliminado con éxito',
  deleteConfirm: '¿Está seguro de que desea eliminar este formulario de lead?',
  deletedSuccessfully: 'Formulario de lead eliminado con éxito',

  // tabs
  detailedInfo: 'Información Detallada',
  branding: 'Marca',
  formFields: 'Campos del Formulario',
  submission: 'Envío*',
  notification: 'Notificación',

  //fields
  addField: 'Añadir Campo',
  updateField: 'Actualizar Campo',
  label: 'Etiqueta',
  formName: 'Nombre del Formulario',
  defaultLanguage: 'Idioma Predeterminado',
  defaultPrefix: 'Prefijo Predeterminado',
  defaultStatus: 'Estado Predeterminado',
  defaultSource: 'Fuente Predeterminada',
  defaultAssigned: 'Asignado Predeterminado',
  defaultBusinessType: 'Tipo de Negocio Predeterminado',
  defaultType: 'Tipo Predeterminado',
  defaultQualification: 'Calificación Predeterminada',
  defaultTags: 'Etiquetas Predeterminadas',
  submitButtonText: 'Texto del Botón de Envío',
  header: 'Encabezado',
  isRequired: 'Obligatorio',
  type: 'Tipo',
  defaultIsPublic: 'Es Público por Defecto',
  followUpRedirectUrl: 'URL de Redirección de Seguimiento',
  followUpMessage: 'Mensaje de Seguimiento',
  specificUsersToNotify: 'Usuarios Específicos para Notificar',
  willNotify: 'Notificará',
  willNotifyResponsibleUsers: 'Notificará a Usuarios Responsables',
  leadField: 'Campo de Lead',
  placeholder: 'Marcador de Posición',
  fullNamePlaceholder: 'Introduzca su nombre completo',
  emailPlaceholder: 'Introduzca su correo electrónico',
  formTitle: 'Formulario',
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import {
  IUserDetailResult,
  IProposalListInput,
  IProposal,
  ProposalProspectTargetEntityRef,
  ProposalStatus,
  PermissionSubject,
} from 'corede-common-cocrm';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { useProposalListQuery, useProposalUpdateMutation } from '../context/proposal.api';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import { Currency, IGraphqlVariables, PermissionAction } from 'corede-common';
// import ProposalDetailDrawerWrapper from '../pages/detailDrawer/ProposalDetailDrawer.wrapper';
import { ProposalDeleteDialog } from '../pages/delete/ProposalDeleteDialog';
import ProposalUpdateDrawerWrapper from '../pages/update/ProposalUpdateWrapper';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { IPendingNavigation } from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representDateAsString } from 'utils/representationHelper';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import { Stack } from '@mui/material';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';
import ProposalListItemComponent from './ProposalListItem';
import ProposalCreateDrawerWrapper from '../pages/create/ProposalCreateDrawer.wrapper';
import { Iconify } from 'components';
import { listProposalsRoute } from '../../../routes/preSales.base.route';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionWrapper } from 'permission/PermissionWrapper';

interface IAssociatedProposalsTabSectionComponent
  extends DefaultTabComponentProps<IUserDetailResult> {
  tabKey: 'associatedProposals';
  name: 'crm.organizationalChart.user.associatedProposals';
}

export const AssociatedProposalsTab = (props: IAssociatedProposalsTabSectionComponent) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);
  const [selectedProposal, setSelectedProposal] = useState<TListGridSelectedEntity<IProposal>>();
  const [openProposalDetailDrawer, setOpenProposalDetailDrawer] = useState(false);
  const [openProposalDeleteDialog, setOpenProposalDeleteDialog] = useState(false);
  const [openProposalUpdateDrawer, setOpenProposalUpdateDrawer] = useState(false);
  const [openProposalCreateDrawer, setOpenProposalCreateDrawer] = useState(false);
  const [proposalListFilter, setProposalListFilter] = useState<
    IGraphqlVariables<IProposalListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });
  const currentPage = proposalListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = proposalListFilter?.input?.pagination?.pageSize ?? 10;

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.proposal,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.proposal,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.proposal,
    action: PermissionAction.delete,
  });

  const {
    data: proposalListData,
    isLoading: proposalListLoading,
    isFetching: proposalListFetching,
    error: proposalListError,
  } = useProposalListQuery(
    {},
    {
      skip: !id,
    },
  );

  const [proposalUpdate, { isLoading: proposalUpdateLoading, error: proposalUpdateError }] =
    useProposalUpdateMutation();

  return (
    <DefaultTabContainer title={t('crm.organizationalChart.user.associatedProposals')}>
      {proposalListData && proposalListData?.count === 0 && (
        <EmptyState
          icon={
            <img
              src={DocumentEmptyIcon}
              style={{
                width: '180px',
                height: '180px',
                marginBottom: '30px',
              }}
              alt="There is no proposal yet."
            />
          }
          content1={t('crm.preSale.proposal.emptyState1')}
          // content2={t('crm.noProposal')}
          button1={{
            title: t('crm.preSale.proposal.create'),
            onClick: () => {
              navigate(listProposalsRoute() + '#create');
            },
            leftIcon: <Iconify icon={'mdi:plus'} sx={{ mr: 1 }} />,
          }}
        />
      )}

      {proposalListData && proposalListData?.count > 3 && (
        <BaseDataGrid
          rows={
            proposalListData?.data?.map((proposal, index) => ({
              id:
                ((proposalListFilter.input?.pagination?.page || 1) - 1) *
                  (proposalListFilter.input?.pagination?.pageSize || 0) +
                index +
                1,
              _id: proposal._id,
              prospectName: proposal.prospectName || '-',
              prospectAddress: proposal.prospectCity + ' ' + proposal.prospectCountry || '-',
              prospectContactName:
                proposal.prospectContactFirstName + ' ' + proposal.prospectContactLastName || '-',
              currency: proposal.currency || '-',
              totalPrice: proposal.totalPrice.total || '-',
              status: proposal.status || '-',
              prospectType: proposal.prospectType || '-',
              language: proposal.language || '-',
              createdAt: representDateAsString(proposal.createdAt) || '-',
            })) || []
          }
          columns={[
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'id',
                  disableColumnMenu: true,
                  filterable: false,
                  width: 40,
                  align: 'center',
                },
                headerConfig: {
                  name: '#',
                  align: 'center',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'prospectName',
                  editable: hasUpdatePermission,
                  filterable: false,
                },
                headerConfig: {
                  name: t('crm.prospectName'),
                  icon: 'mdi:account-outline',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'prospectAddress',
                  editable: hasUpdatePermission,
                  filterable: false,
                },
                headerConfig: {
                  name: t('crm.prospectAddress'),
                  icon: 'mdi:map-marker-outline',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'prospectContactName',
                  editable: hasUpdatePermission,
                  filterable: false,
                },
                headerConfig: {
                  name: t('crm.prospectContactName'),
                  icon: 'mdi:account-circle-outline',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'date',
                  filterable: false,
                  editable: hasUpdatePermission,
                },
                headerConfig: {
                  name: t('crm.date'),
                  icon: 'mdi:calendar-outline',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'expireDate',
                  editable: hasUpdatePermission,
                  width: 180,
                },
                headerConfig: {
                  name: t('crm.expireDate'),
                  icon: 'mdi:calendar-clock-outline',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.enum,
                column: {
                  field: 'currency',
                  editable: hasUpdatePermission,
                  filterable: false,
                },
                headerConfig: {
                  name: t('crm.currency'),
                  icon: 'mdi:cash-multiple',
                },
                filterConfig: {
                  customFieldName: `currency`,
                },
              },
              data: Currency,
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'totalPrice',
                  editable: hasUpdatePermission,
                  filterable: false,
                },
                headerConfig: {
                  name: t('crm.totalPrice'),
                  icon: 'mdi:currency-usd',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.enum,
                column: {
                  field: 'status',
                  editable: false,
                  filterable: true,
                },
                headerConfig: {
                  name: t('crm.status'),
                  icon: 'mdi:check-circle-outline',
                },
                filterConfig: {
                  customFieldName: `status`,
                  isArrayFilter: true,
                },
              },
              data: ProposalStatus,
            },
            {
              config: {
                baseGridColType: BaseGridColType.enum,
                column: {
                  field: 'prospectType',
                  editable: false,
                  filterable: true,
                },
                headerConfig: {
                  name: t('crm.prospectType'),
                  icon: 'mdi:format-list-bulleted',
                },
                filterConfig: {
                  customFieldName: `prospectType`,
                  isArrayFilter: true,
                },
              },
              data: ProposalProspectTargetEntityRef,
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'createdAt',
                  filterable: false,
                  editable: false,
                  width: 180,
                },
                headerConfig: {
                  name: t('crm.createdAt'),
                  icon: 'mdi:clock-outline',
                },
              },
            },
          ]}
          actionColumn={{
            width: 80,
            defaultActions: {
              view: hasDetailPermission
                ? {
                    clickConfig: {
                      setSelectedRow: setSelectedProposal,
                      setOpenAction: setOpenProposalDetailDrawer,
                    },
                  }
                : undefined,
              edit: hasDetailPermission
                ? {
                    clickConfig: {
                      setSelectedRow: setSelectedProposal,
                      setOpenAction: setOpenProposalUpdateDrawer,
                    },
                  }
                : undefined,
              delete: hasDetailPermission
                ? {
                    clickConfig: {
                      setSelectedRow: setSelectedProposal,
                      setOpenAction: setOpenProposalDeleteDialog,
                    },
                  }
                : undefined,
            },
            actionHeaderName: t('crm.actions'),
          }}
          loading={proposalListLoading || proposalUpdateLoading || proposalListFetching}
          listFilter={{
            filterInput: proposalListFilter,
            setFilterInput: setProposalListFilter,
          }}
          disableColumnFilter
          update={{
            updateQuery: proposalUpdate,
          }}
          count={proposalListData?.count ?? 0}
          config={{
            columnVisibilityModel: {
              prospectAddress: false,
              sector: false,
            },
            features: [],
          }}
          toolbar={{
            customActions: [],
          }}
        />
      )}

      {proposalListData && proposalListData?.count > 0 && proposalListData?.count < 4 && (
        <Stack direction={'column'} gap={1.5} sx={{ width: '100%' }}>
          {proposalListData?.data?.map((proposal, index) => {
            if (index > 3) return null;

            return <ProposalListItemComponent key={index} proposal={proposal} />;
          })}
        </Stack>
      )}

      {/* <ProposalDetailDrawerWrapper
        open={openProposalDetailDrawer}
        setOpen={setOpenProposalDetailDrawer}
        selectedProposal={selectedProposal as IProposalDetailResult & IHasId<number>}
        sideNavigationProps={{
          count: proposalListData?.count ?? 0,
          handleNavigate: useHandleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: proposalListData,
            listFilter: proposalListFilter,
            setListFilter: setProposalListFilter,
            selectedEntity: selectedProposal,
            setSelectedEntity: setSelectedProposal,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      /> */}

      <PermissionWrapper
        check={{
          subject: PermissionSubject.proposal,
          action: PermissionAction.create,
        }}
      >
        <ProposalCreateDrawerWrapper
          open={openProposalCreateDrawer}
          setOpen={setOpenProposalCreateDrawer}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.proposal,
          action: PermissionAction.update,
        }}
      >
        <ProposalUpdateDrawerWrapper
          open={openProposalUpdateDrawer}
          setOpen={setOpenProposalUpdateDrawer}
          selectedProposal={selectedProposal}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.proposal,
          action: PermissionAction.delete,
        }}
      >
        <ProposalDeleteDialog
          open={openProposalDeleteDialog}
          proposalId={selectedProposal?._id ?? ''}
          setProposal={setSelectedProposal}
          onClose={() => {
            setSelectedProposal(undefined);
            setOpenProposalDeleteDialog(false);
          }}
          key={selectedProposal?._id}
        />
      </PermissionWrapper>
    </DefaultTabContainer>
  );
};

const locale = {
  payments: 'Payments',
  payment: 'Payment',
  paymentConversions: 'Payment Conversions',
  title: 'Payments',
  create: 'Add Payment',
  update: 'Update Payment',
  delete: 'Delete Payment',
  createSuccess: 'Payment added successfully.',
  updateSuccess: 'Payment updated successfully.',
  deleteSuccess: 'Payment deleted successfully.',
  deleteConfirm: 'Are you sure you want to delete this payment. This action cannot be undone.',
  emptyState1: 'No payments recorded yet.',
  emptyState2:
    'Track and manage payments for your invoices here to keep your finances organized and up-to-date.',

  // detailDrawer
  generalInfoTitle: 'General Information',
  date: 'Payment Date',
  amountPaid: 'Amount Paid',
  confirmationNumber: 'Confirmation Number',
  currency: 'Currency',
};

export default locale;

const locale = {
  leadSources: "Fontes de Leads",
  title: "Fontes de Leads",
  create: "Criar Fonte de Lead",
  delete: "Excluir Fonte de Lead",
  deleteConfirm: "Tem certeza que deseja excluir esta fonte: ",
  deletedSuccessfully: "Fonte de lead excluída com sucesso",
  createSuccess: "Fonte de lead criada com sucesso",
  updateSuccess: "Fonte de lead atualizada com sucesso",
  update: "Atualizar Fonte de Lead",
  updateLeadSourceSuccess: "Fonte de lead atualizada com sucesso",
  selectColor: "Selecionar Cor",
  selectIcon: "Selecionar Ícone",
};

export default locale; 
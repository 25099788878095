const locale = {
  title: 'Dashboard',
  description:
    'Ative sua assinatura Mude para um plano de assinatura adequado para usar todos os recursos do sistema CRM e expandir seus negócios',
  featuresTitle: 'Com assinatura:',
  features1: 'Acesso ilimitado a todos os seus dados',
  features2: 'Ferramentas de relatórios personalizados',
  features3: 'Mais armazenamento',
  features4: 'Suporte técnico 24/7',
  buttonTitle: 'Explorar planos de assinatura',
  trialExpired: 'O plano de teste expirou. Por favor, escolha outro plano.',
  mainFailed: 'Seu plano não está ativado. Por favor, verifique suas informações de faturamento.',
  mainCompleted: 'Seu plano foi concluído. Por favor, escolha outro plano.',
  welcome: 'Bem-vindo ',
  platformValue: 'Valor da plataforma',
  dealsAmount: 'Valor dos negócios',
  deals: 'Negócios',
  byRefererCategory: 'por categoria de referência',
  revenue: 'Receita',
  leads: 'Leads',
  wl: 'G/P',
  winLoss: 'Ganho/Perda',
  averageMonthly: 'Média mensal',
  monthly: 'Mensal',
  bestDeal: 'Melhor negócio',
  sales: 'Vendas',
  topSales: 'Melhores vendas',
  salesStreak: 'Sequência de vendas',
  topReview: 'Melhor avaliação',
  workWithPlatforms: 'Trabalho com plataformas',
  salesDynamics: 'Dinâmica de vendas',
  timeframe: 'Período',
  bestDetal: 'Melhor negócio',
  winRate: 'Taxa de sucesso',
  prevMonth: 'mês anterior',
  vsPrevMonth: 'vs mês anterior',
  details: 'Detalhes',
  filters: 'Filtros',
  statistics: 'Estatísticas',

  //LEAD
  totalLead: 'Total de leads',
  totalLeadSubtitle: 'Total de leads com dados históricos diários',
  today: 'Hoje',
  yesterday: 'Ontem',
  leadConversion: 'Conversão de leads',
  leadConversionSubtitle: 'Conversão de leads com dados históricos diários',
  weeklyReport: 'Relatório semanal',

  //CUSTOMER
  totalCustomer: 'Total de clientes',
  totalCustomerSubtitle: 'Total de clientes com dados históricos diários',
  customerFilterType: 'Filtro de clientes por tipo',
  customerFilterCategory: 'Filtro de clientes por categoria',

  //ESTIMATE
  totalEstimate: 'Total de orçamentos',
  totalEstimateSubtitle: 'Total de orçamentos com dados históricos diários',
  estimateTotalPrice: 'Preço total dos orçamentos',
  estimateTotalPriceSubtitle: 'Preço total dos orçamentos com dados históricos diários',
  estimatesByStatus: 'Orçamentos por status',
  estimatesByTypes: 'Orçamentos por tipo',
  estimatesByTarget: 'Orçamentos por alvo',

  //PROPOSAL
  totalProposal: 'Total de propostas',
  totalProposalSubtitle: 'Total de propostas com dados históricos diários',
  proposalTotalPrice: 'Preço total das propostas',
  proposalTotalPriceSubtitle: 'Preço total das propostas com dados históricos diários',
  proposalsByStatus: 'Propostas por status',
  proposalsByTypes: 'Propostas por tipo',
  proposalsByTarget: 'Propostas por alvo',

  //TASK
  totalTask: 'Total de tarefas',
  totalTaskSubtitle: 'Total de tarefas com dados históricos diários',
  taskFilterPriority: 'Filtro de tarefas por prioridade',
  taskFilterStatus: 'Filtro de tarefas por status',
};

export default locale; 
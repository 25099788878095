const locale = {
  demo: 'Démo',
  title: 'Qu\'est-ce que la <highlight>CoCRM</highlight> Communauté?',
  description:
    'Chez CoCRM, en plus de vous offrir une gestion efficace de vos processus d\'affaires et de relation client, nous vous proposons également un espace de collaboration conçu pour élargir votre réseau et vos opportunités commerciales.',
  featuresTitle: 'Avec la Communauté CoCRM, vous pouvez:',
  features1: 'Obtenir de nouvelles opportunités commerciales grâce aux recommandations.',
  features2: 'Vous connecter avec des fournisseurs fiables.',
  features3: 'Trouver des partenaires commerciaux de confiance.',
  features4: 'Élargir votre réseau pour offrir des services à 360° à vos clients.',
  buttonTitle: 'Rejoindre la Communauté',
  formTitle: 'Rejoindre la Liste d\'Attente',
  formDescription:
    'Veuillez remplir le formulaire ci-dessous et nous vous contacterons dès que possible.',
  sendSuccess: 'Merci! Nous vous répondrons dès que possible.',
  formButtonTitle: 'Rejoindre',
  fullName: 'Nom Complet',
  email: 'Email',
  phone: 'Téléphone',
  orgName: 'Nom de l\'Organisation',
};

export default locale;

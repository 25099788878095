import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useUserPermissionListOwnQuery } from 'apps/account/domains/profile/subdomains/profile';
import { SnackbarProvider, InitializationProvider } from 'components';
import { setThemeMode, useAppDispatch, useAppSelector } from 'context';
import 'moment/locale/en-gb';
import 'moment/locale/tr';
import { PermissionProvider } from 'permission/PermissionContext';
import Router from 'Router';
import { theme } from 'theme';

const Wrapper = () => {
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector((state) => state.auth);
  const { themeMode } = useAppSelector((state) => state.theme);

  const { data: userPermissionListOwnData } = useUserPermissionListOwnQuery(
    {},
    { skip: !accessToken },
  );

  if (process.env.REACT_APP_PROJECT_STATUS === 'PROD') {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme) {
      dispatch(setThemeMode(theme as 'light' | 'dark'));
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme(themeMode === 'light' ? 'light' : 'dark')}>
      <SnackbarProvider>
        <PermissionProvider permissions={userPermissionListOwnData ?? []}>
          <InitializationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Router />
            </LocalizationProvider>
          </InitializationProvider>
        </PermissionProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Wrapper;

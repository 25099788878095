const locale = {
  estimate: 'Orçamento',
  title: 'Orçamentos',
  create: 'Criar Orçamento',
  update: 'Atualizar Orçamento',
  delete: 'Excluir',
  view: 'Visualizar Orçamento',
  list: 'Listar Orçamentos',
  detail: 'Detalhe do Orçamento',
  createdSuccessfully: 'Orçamento criado com sucesso',
  updatedSuccessfully: 'Orçamento atualizado com sucesso',
  deleteConfirm: 'Tem certeza que deseja excluir? Esta ação não pode ser desfeita.',
  deletedSuccessfully: 'Orçamento excluído com sucesso',
  createInfo: 'Você pode criar novos orçamentos aqui.',
  prefix: 'Prefixo',
  timeZone: 'Fuso Horário',
  prospectTargetEntityId: 'Cliente Potencial',
  prospectTargetEntityRef: 'Ref. Cliente Potencial',
  prospectType: 'Tipo de Cliente Potencial',
  prospectName: 'Nome do Cliente Potencial',
  prospectVAT: 'CNPJ/CPF do Cliente Potencial',
  prospectPhoneNumber: 'Telefone do Cliente Potencial',
  prospectCountry: 'País do Cliente Potencial',
  prospectState: 'Estado do Cliente Potencial',
  prospectCity: 'Cidade do Cliente Potencial',
  prospectZIP: 'CEP do Cliente Potencial',
  prospectAddress: 'Endereço do Cliente Potencial',
  prospectContactFirstName: 'Nome do Contato do Cliente Potencial',
  prospectContactLastName: 'Sobrenome do Contato do Cliente Potencial',
  prospectContactEmail: 'Email do Contato do Cliente Potencial',
  language: 'Idioma',
  date: 'Data',
  expireDate: 'Data de Validade',
  sendEmailOnExpiration: 'Enviar Email',
  expirationEmailSentDate: 'Data de Envio do Email de Validade',
  projectOrServiceDesc: 'Descrição do Projeto ou Serviço',
  paymentTerms: 'Condições de Pagamento',
  products: 'Produtos',
  addProducts: 'Adicionar Produtos',
  offers: 'Ofertas',
  salesAgentIds: 'Agentes de Vendas',
  allowComments: 'Permitir Comentários',
  legalNDAId: 'NDA',
  legalTermAndCondId: 'Termos e Condições',
  signerUserId: 'Usuário Assinante',
  listInfo: 'Lista de todos os orçamentos.',
  importedError: 'Erro ao importar',
  importedSuccessfully: 'Importado com sucesso',
  downloadFormError: 'Erro ao baixar formulário',
  downloadFormSuccessfully: 'Formulário baixado com sucesso',
  downloadExampleFormSuccessfully: 'Formulário de exemplo baixado com sucesso',
  downloadExampleFormError: 'Erro ao baixar formulário de exemplo',
  emptyState1: 'Nenhum orçamento encontrado.',
  emptyState2: 'Você pode criar ou importar orçamentos imediatamente.',
  createEstimate: 'Criar Orçamento',
  importEstimate: 'Importar Orçamento',
  importTitle: 'Importar Orçamentos',
  downloadExample: 'Baixar Exemplo',
  importText1: 'Você pode importar orçamentos a partir desta página.',
  importText2: 'Você pode usar o formulário de exemplo abaixo para publicar seus orçamentos.',
  importExport: 'Importar/Exportar',
  exportData: 'Exportar Dados',
  importData: 'Importar Dados',
  b2b: 'B2B',
  b2c: 'B2C',
  viewEstimate: 'Visualizar',
  edit: 'Editar',
  junk: 'Lixeira',
  unJunk: 'Restaurar',
  addNewRole: 'Adicionar Nova Função',
  actions: 'Ações',
  legalAgreements: 'Acordos Legais',
  businessName: 'Nome da Empresa',
  governmentAgency: 'Órgão Governamental',
  fullName: 'Nome Completo',
  VAT: 'CNPJ/CPF',
  phoneNumber: 'Número de Telefone',
  country: 'País',
  state: 'Estado',
  city: 'Cidade',
  ZIP: 'CEP',
  sentDate: 'Data de Envio',
  name: 'Nome',
  description: 'Descrição',
  productUnit: 'Unidade',
  priceType: 'Tipo de Preço',
  service: 'Serviço',
  product: 'Produto',
  services: 'Serviços',
  references: 'Referências',
  add: 'Adicionar',
  salesAgent: 'Agente de Vendas',
  estimateCreateSuccess: 'Orçamento criado com sucesso',

  addEstimate: 'Adicionar Orçamento',
  noEstimateContent1: 'Nenhum orçamento encontrado',
  noEstimate: 'Não há orçamentos',
  generalInfo: 'Informações Gerais',
  sentSuccessfully: 'Orçamento enviado com sucesso',
  send: 'Enviar Orçamento',
  resend: 'Reenviar Orçamento',
  sendSureTitle: 'Enviar Orçamento',
  sendSureDesc: 'Tem certeza que deseja enviar este orçamento?',
  changeStatus: 'Alterar Status',
  statusUpdatedSuccessfully: 'Status atualizado com sucesso',

  // generalInfo
  generalInfoTitle: 'Informações Gerais',
  estimateId: 'Orçamento',
  status: 'Status',
  createdAt: 'Criado em',
  reference: 'Referência',
  createdBy: 'Criado por',
  updatedAt: 'Atualizado em',
  notAvailable: 'Não Disponível',
  updateStatus: 'Atualizar Status',

  // customerInfo
  customerInfoTitle: 'Informações do Cliente',
  contactFirstName: 'Nome',
  contactLastName: 'Sobrenome',
  contactEmail: 'Email',
  contactPhoneNumber: 'Número de Telefone',
  address: 'Endereço',

  //productInfo
  price: 'Preço',
  productInfoTitle: 'Detalhes do Produto',
  productNameUnknown: 'Produto Desconhecido',
  discountedPrice: 'Preço com Desconto',
  quantity: 'Quantidade',
  finalPrice: 'Preço Final',
  tax: 'Taxa de Imposto',
  noProducts: 'Nenhum produto disponível.',

  //legalInfo
  legalInfoTitle: 'Informações Legais',
  ndaTitle: 'Acordo de Confidencialidade (NDA)',
  termsTitle: 'Termos e Condições',
  documentName: 'Nome do Documento',
  documentDescription: 'Descrição',
  documentContent: 'Conteúdo',

  //financialInfo
  financialInfoTitle: 'Informações Financeiras',
  currency: 'Moeda',
  subTotal: 'Subtotal',
  discount: 'Desconto',
  taxTotal: 'Total de Impostos',
  shippingTotal: 'Total de Envio',
  total: 'Preço Total',
  noFinancialData: 'Nenhum dado financeiro disponível.',

  // documentInfo
  documentsTitle: 'Documentos',
  documentNameUnknown: 'Documento Desconhecido',
  mimeType: 'Tipo de Arquivo',
  download: 'Baixar',
  noDocuments: 'Nenhum documento disponível.',

  // salesAgents
  salesAgentsTitle: 'Agentes de Vendas',
  unknownAgent: 'Agente Desconhecido',
  noSalesAgents: 'Nenhum agente de vendas disponível.',

  //signature
  signatureTitle: 'Informações de Assinatura',
  additionalInfoTitle: 'Informações Adicionais',
  signature: 'Assinatura',
  signerUser: 'Usuário Assinante',

  //additionalInfo
  scopeOfWork: 'Escopo do Trabalho',
  assumptionsAndExclusions: 'Premissas e Exclusões',
  useLegalNDA: 'Usar NDA Legal',
  useLegalTermAndCond: 'Usar Termos e Cond.',
  yes: 'Sim',
  no: 'Não',

  //tabs
  generalInformation: 'Informações Gerais*',
  prospect: 'Cliente Potencial*',
  detailedInformation: 'Informações Detalhadas',
  productsAndServices: 'Produtos e Serviços*',
  salesAgents: 'Agentes de Vendas',
  legal: 'Legal',
  signatureTab: 'Assinatura*',
};

export default locale; 
import dashboardLocale from '../domains/01-dashboard/locales/ru';
import productAndServiceLocale from '../domains/08-productAndService/locales/ru';
import organizationalChartLocale from '../domains/02-organizationalChart/locales/ru';
import leadLocale from '../domains/03-lead/locales/ru';
import customerLocale from '../domains/04-customer/locales/ru';
import preSaleLocale from '../domains/05-preSale/locales/ru';
import saleLocale from '../domains/06-sale/locales/ru';
import pricingLocale from '../domains/16-pricing/locales/ru';
import calendarLocale from '../domains/12-calendar/locales/ru';
import contractLocale from '../domains/07-contract/locales/ru';
import supportLocale from '../domains/15-support/locales/ru';
import reportLocale from '../domains/13-report/locales/ru';
import taskLocale from '../domains/11-task/locales/ru';
import projectLocale from '../domains/10-project/locales/ru';
import expenseLocale from '../domains/09-expense/locales/ru';
import noteLocale from '../domains/14-note/locales/ru';

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  productAndService: {
    ...productAndServiceLocale,
  },
  customer: {
    ...customerLocale,
  },
  preSale: {
    ...preSaleLocale,
  },
  sale: {
    ...saleLocale,
  },
  pricing: {
    ...pricingLocale,
  },
  calendar: {
    ...calendarLocale,
  },
  contract: {
    ...contractLocale,
  },
  support: {
    ...supportLocale,
  },
  report: {
    ...reportLocale,
  },
  task: {
    ...taskLocale,
  },
  project: {
    ...projectLocale,
  },
  expense: {
    ...expenseLocale,
  },
  note: {
    ...noteLocale,
  },

  ////////////////////////////////////////////////////////////////
  // common used names
  ////////////////////////////////////////////////////////////////
  preSaleLabel: 'Предпродажа',
  preSales: 'Предпродажи',
  contactDetails: 'Детали',
  localization: 'Локализация',
  info: 'Инфо',
  about: 'О нас',
  contact: 'Контакт',
  record: 'Запись',
  records: 'Записи',
  tracking: 'Отслеживание',
  pricingLabel: 'Цены',
  dashboardLabel: 'Панель',
  marketing: 'Маркетинг',
  campaigns: 'Кампании',
  emails: 'Электронная почта',
  sms: 'СМС',
  socialMedia: 'Социальные сети',
  salesLabel: 'Продажи',
  businessIntelligence: 'Бизнес-аналитика',
  calendarLabel: 'Календарь',
  supportLabel: 'Поддержка',
  accountMenu: 'Меню аккаунта',
  profile: 'Профиль',
  myAccount: 'Мой аккаунт',
  logout: 'Выйти',
  searchPlaceholder: 'Попробуйте поискать "аналитика"',
  searchAnIcon: 'Поиск иконки',

  // --------------------
  // settings related
  // --------------------
  settings: 'Настройки',
  english: 'Английский',
  turkish: 'Турецкий',
  french: 'Французский',
  german: 'Немецкий',
  italian: 'Итальянский',
  portuguese: 'Португальский',
  russian: 'Русский',
  spanish: 'Испанский',
  dutch: 'Голландский',
  darkMode: 'Темный режим',
  lightMode: 'Светлый режим',
  languageSelectLabel: 'Выбрать язык',

  ////////////////////////////////////////////////////////////////
  // common action texts
  ////////////////////////////////////////////////////////////////
  actions: 'Действия',
  yes: 'Да',
  no: 'Нет',
  importExport: 'Импорт/Экспорт',
  view: 'Просмотр',
  list: 'Список',
  create: 'Создать',
  update: 'Обновить',
  delete: 'Удалить',
  edit: 'Редактировать',
  cancel: 'Отмена',
  downloadSuccessful: 'Загрузка успешна',
  export: 'Экспорт',
  import: 'Импорт',
  exportData: 'Экспорт данных',
  importData: 'Импорт данных',
  showMore: 'Показать больше',
  selectColor: 'Выбрать цвет',
  justForMe: 'Только для меня',

  ////////////////////////////////////////////////////////////////
  // common situational texts
  ////////////////////////////////////////////////////////////////
  total: 'Всего',
  weeklyTotal: 'Недельный итог',
  hasCreatedAt: 'создан ',
  createdBy: 'Создано ',
  updatedBy: 'Обновлено ',
  uploadLogo: 'Загрузить логотип',
  selectIconTitle: 'Выбрать иконку',
  searchIcons: 'Поиск иконок',
  uploadPictureText: 'Загрузить изображение',
  searchForAnIcon: 'Поиск иконки',
  selectedIcon: 'Выбранная иконка:',
  signedUser: 'Авторизованный пользователь',

  // --------------------
  // delete dialog
  // --------------------
  deletedEntitySuccessfully: 'Успешно удалено',
  deleteEntityContent: 'Вы уверены, что хотите удалить? Это действие нельзя отменить.',
  deleteEntityTitle: 'Удалить',

  // --------------------
  // organization
  // --------------------
  mustCreateOrganizationTitle: 'Создать организацию',
  mustCreateOrganizationContent: 'Вы должны создать организацию, чтобы использовать панель.',
  mustCreateOrganizationSuccess: 'Организация успешно создана.',

  // --------------------
  // import export
  // --------------------
  importedError: 'Ошибка импорта',
  importedSuccessfully: 'Успешно импортировано',
  downloadExample: 'Скачать пример',
  downloadFormError: 'Ошибка при скачивании формы',
  downloadFormSuccessfully: 'Форма успешно скачана',
  downloadExampleFormSuccessfully: 'Пример формы успешно скачан',
  downloadExampleFormError: 'Ошибка при скачивании примера формы',

  // --------------------
  // estimate
  // --------------------
  addEstimate: 'Добавить смету',
  noEstimate: 'Нет смет',
  noEstimateContent1: 'Сметы не найдены',
  additionalInfo: 'Дополнительная информация',

  // --------------------
  // proposal
  // --------------------
  addProposal: 'Добавить предложение',
  noProposal: 'Нет предложений',
  lastProposal: 'Последнее предложение',
  proposalSend: 'Предложение отправлено',
  noProposalSend: 'Нет отправленных предложений',
  noProposalContent1: 'Предложения не найдены',
  sendEmailOnExpiration: 'Отправить email при истечении срока',
  testimonialCaseStudies: 'Примеры отзывов',
  projectTimeline: 'График проекта',
  executiveSummary: 'Краткое содержание',
  costBreakdown: 'Разбивка затрат',
  conclusion: 'Заключение',
  projectorServiceDesc: 'Подробное описание проекта/услуги',
  noSalentAgents: 'Нет торговых агентов',

  // --------------------
  // document
  // --------------------
  addDocument: 'Добавить документ',
  noDocument: 'Нет документов',
  noDocumentContent1: 'Документы не найдены',

  // --------------------
  // task
  // --------------------
  noTask: 'Нет задач',
  noTaskContent1: 'Задачи не найдены',

  // --------------------
  // activity
  // --------------------
  noActivity: 'Нет активностей',
  addActivity: 'Добавить активность',
  noActivityContent1: 'Активности не найдены',
  noActivityContent2: 'Нет активностей для этой сущности',

  // --------------------
  // note
  // --------------------
  addNote: 'Добавить заметку',
  noNote: 'Нет заметок',
  emptyStateNoteList1: 'Заметки не найдены',
  noteDate: 'Дата заметки',
  noteUsers: 'Пользователи заметки',
  noteTitle: 'Заголовок',

  // --------------------
  // reminder
  // --------------------
  addReminder: 'Добавить напоминание',
  noReminder: 'Нет напоминаний',
  emptyStateReminderList1: 'Напоминания не найдены',
  remindDate: 'Дата напоминания',
  remindUsers: 'Напомнить пользователям',
  remindTitle: 'Заголовок',

  // --------------------
  // appointment
  // --------------------
  noAppointments: 'Нет встреч',
  makeAnAppointment: 'Назначить встречу',

  // --------------------
  // assignee
  // --------------------
  assignees: 'Исполнители',
  noAssignees: 'Нет исполнителей',
  addAssignee: 'Добавить исполнителя',

  // --------------------
  // followers
  // --------------------
  followers: 'Подписчики',
  noFollowers: 'Нет подписчиков',

  // --------------------
  // product and services
  // --------------------
  addProduct: 'Добавить продукт',
  addProductOrService: 'Добавить продукт или услугу',
  editProdutctOrService: 'Редактировать продукт или услугу',
  productType: 'Тип продукта',
  editProduct: 'Редактировать продукт',
  product: 'Продукт',

  // --------------------
  // ticket
  // --------------------
  subject: 'Тема',
  contactName: 'Имя контакта',
  tickets: 'Тикеты',

  ////////////////////////////////////////////////////////////////
  // common used words
  ////////////////////////////////////////////////////////////////
  of: 'из',

  ////////////////////////////////////////////////////////////////
  // common used entity names
  ////////////////////////////////////////////////////////////////
  leadLabel: 'Лид',
  leads: 'Лиды',
  leadForms: 'Формы лидов',
  leadStatuses: 'Статусы лидов',
  leadSources: 'Источники лидов',
  customerLabel: 'Клиент',
  customers: 'Клиенты',
  estimateLabel: 'Смета',
  estimates: 'Сметы',
  proposalLabel: 'Предложение',
  proposals: 'Предложения',
  invoiceLabel: 'Счет',
  invoices: 'Счета',
  productsServices: 'Продукты и услуги',
  activityLabel: 'Активность',
  activities: 'Активности',
  noteLabel: 'Заметка',
  notes: 'Заметки',
  documentLabel: 'Документ',
  documents: 'Документы',
  appointmentLabel: 'Встреча',
  appointments: 'Встречи',
  reminderLabel: 'Напоминание',
  reminders: 'Напоминания',
  departmentLabel: 'Отдел',
  departments: 'Отделы',
  contractLabel: 'Контракт',
  contractTemplate: 'Шаблон контракта',
  contracts: 'Контракты',
  paymentLabel: 'Платеж',
  payments: 'Платежи',
  organizationalChartLabel: 'Организационная структура',
  expenseLabel: 'Расход',
  expenses: 'Расходы',
  productAndServiceLabel: 'Продукты и услуги',
  projectLabel: 'Проект',
  projects: 'Проекты',
  taskLabel: 'Задача',
  tasks: 'Задачи',
  reportLabel: 'Отчет',
  reports: 'Отчеты',
  billingInformation: 'Платежная информация',
  billingInfo: 'Платежная информация',
  otherBillingInformation: 'Другая платежная информация',
  shippingInfo: 'Информация о доставке',
  contactInformation: 'Контактная информация',
  otherContactInformation: 'Другая контактная информация',
  warningBillingInfo: 'Как только одно поле заполнено, все поля должны быть заполнены.',
  addRole: 'Добавить роль',

  ////////////////////////////////////////////////////////////////
  // common fields
  ////////////////////////////////////////////////////////////////
  color: 'Цвет',
  icon: 'Иконка',
  index: 'Индекс',
  title: 'Заголовок',
  fullName: 'Полное имя',
  firstName: 'Имя',
  lastName: 'Фамилия',
  surname: 'Фамилия',
  convert: 'Конвертировать',
  country: 'Страна',
  state: 'Штат',
  city: 'Город',
  industry: 'Отрасль',
  address: 'Адрес',
  website: 'Веб-сайт',
  emailAddress: 'Адрес электронной почты',
  name: 'Имя',
  companyName: 'Название компании',
  companyEmail: 'Email компании',
  companyPhone: 'Телефон компании',
  timezone: 'Часовой пояс',
  companyLogo: 'Логотип компании',
  language: 'Язык',
  department: 'Отдел',
  email: 'Email',
  phoneNumber: 'Номер телефона',
  type: 'Тип',
  createdAt: 'Создано',
  updatedAt: 'Обновлено',
  prefix: 'Префикс',
  tags: 'Теги',
  tagsPlaceholder: 'Теги',
  addTagsPlaceholder: 'Добавить теги',
  addNewOption: 'Добавить новый вариант',
  enterText: 'Введите текст...',
  company: 'Компания',
  sector: 'Сектор',
  phone: 'Телефон',
  content: 'Содержание',
  priority: 'Приоритет',
  position: 'Позиция',
  status: 'Статус',
  source: 'Источник',
  date: 'Дата',
  expireDate: 'Дата истечения',
  totalPrice: 'Общая цена',
  currency: 'Валюта',
  category: 'Категория',
  parentCategory: 'Родительская категория',
  vat: 'НДС',
  zip: 'Почтовый индекс',
  street: 'Улица',
  taxOffice: 'Налоговая инспекция',
  target: 'Цель',
  targetType: 'Тип цели',
  description: 'Описание',
  birthDate: 'Дата рождения',
  gender: 'Пол',
  isPrimary: 'Основной',
  prospectName: 'Имя потенциального клиента',
  prospectAddress: 'Адрес потенциального клиента',
  prospectContactName: 'Имя контакта потенциального клиента',
  prospectType: 'Тип потенциального клиента',
  leadValue: 'Стоимость лида',
  estimateId: 'ID сметы',
  proposalId: 'ID предложения',
  invoiceId: 'ID счета',
  paymentId: 'ID платежа',
  returnId: 'ID возврата',
  expenseId: 'ID расхода',
  customerName: 'Имя клиента',
  customerEmail: 'Email клиента',
  customerType: 'Тип клиента',
  finalPrice: 'Окончательная цена',
  paymentMethod: 'Способ оплаты',
  confirmationNumber: 'Номер подтверждения',
  amoundPaid: 'Оплаченная сумма',
  refundAmount: 'Сумма возврата',
  isOpen: 'Открыт',
  resolveReason: 'Причина решения',
  disapproveReason: 'Причина отклонения',
  sku: 'Артикул',
  priceType: 'Тип цены',
  price: 'Цена',
  discountedPrice: 'Цена со скидкой',
  discount: 'Скидка',
  unit: 'Единица',
  unitPrice: 'Цена за единицу',
  natTaxRate: 'Национальная налоговая ставка',
  intTaxRate: 'Международная налоговая ставка',
  selectRoles: 'Выбрать роли',
  timeZone: 'Часовой пояс',
  prospect: 'Потенциальный клиент',
  prospectTargetEntityRef: 'Ссылка на целевую сущность потенциального клиента',
  prospectTarget: 'Цель потенциального клиента',
  prospectVAT: 'НДС потенциального клиента',
  prospectPhoneNumber: 'Номер телефона потенциального клиента',
  prospectZIP: 'Почтовый индекс потенциального клиента',
  contactFirstName: 'Имя контакта',
  contactLastName: 'Фамилия контакта',
  contactEmail: 'Email контакта',
  expiration: 'Срок действия',
  detailedInformation: 'Подробная информация',
  projectOrServiceDesc: 'Описание проекта/услуги',
  scopeOfWork: 'Объем работ',
  assumptionsAndExclusions: 'Предположения и исключения',
  paymentTerms: 'Условия оплаты',
  products: 'Продукты',
  services: 'Услуги',
  productName: 'Название продукта',
  productDescription: 'Описание продукта',
  productUnit: 'Единица продукта',
  quantity: 'Количество',
  tax: 'Налог',
  extraInformation: 'Дополнительная информация',
  offers: 'Предложения',
  reference: 'Ссылка',
  allowComments: 'Разрешить комментарии',
  totally: 'Всего',
  subTotal: 'Промежуточный итог',
  taxTotal: 'Итого налогов',
  extraDiscount: 'Дополнительная скидка',
  shippingTotal: 'Итого доставка',
  salesAgents: 'Торговые агенты',
  legal: 'Юридический',
  useLegalNDA: 'Использовать юридическое соглашение о неразглашении',
  useLegalTermAndCond: 'Использовать юридические условия',
  signature: 'Подпись',
  signerUser: 'Подписывающий пользователь',
  signedDate: 'Дата подписания',
  generalInfo: 'Общая информация',
  startDate: 'Дата начала',
  dueDate: 'Срок',
  endDate: 'Дата окончания',
  expirationEmailSentDate: 'Дата отправки email об истечении срока',
  allowedPaymentMethods: 'Разрешенные способы оплаты',
  sendEmail: 'Отправить email',
  identityNumber: 'Идентификационный номер',
  recurringCount: 'Количество повторений',
  isRecurring: 'Повторяющийся',
  recurringFrequency: 'Частота повторения',
  recurringDueDate: 'Срок повторения',
  paymentID: 'ID платежа',
  paymentPrefix: 'Префикс платежа',
  returnPrefix: 'Префикс возврата',
  paymentDate: 'Дата платежа',
  amountPaid: 'Оплаченная сумма',
  invoiceID: 'ID счета',
  targetDetail: 'Детали цели',
  productsAndServices: 'Продукты и услуги',
  popularSearch: 'Популярный поиск',
  returnReason: 'Причина возврата',
  partialReturn: 'Частичный возврат',
  amount: 'Сумма',
  relationship: 'Отношение',
  relationshipType: 'Тип отношения',
  relatedEntity: 'Связанная сущность',
  relatedEstimate: 'Связанная смета',
  relatedProposal: 'Связанное предложение',
  relatedTask: 'Связанная задача',
  relatedProject: 'Связанный проект',
  relatedLead: 'Связанный лид',
  relatedCustomer: 'Связанный клиент',
  relatedInvoice: 'Связанный счет',
  relatedPayment: 'Связанный платеж',
  relatedUser: 'Связанный пользователь',
  relatedExpense: 'Связанный расход',
  relatedContract: 'Связанный контракт',
  order: 'Заказ',

  ////////////////////////////////////////////////////////////////
  // SEARCH
  ////////////////////////////////////////////////////////////////
  leadCreate: 'Создать лид',
  leadList: 'Список лидов',
  leadImport: 'Импорт лидов',
  customerCreate: 'Создать клиента',
  customerList: 'Список клиентов',
  customerImport: 'Импорт клиентов',
  estimateCreate: 'Создать смету',
  estimateList: 'Список смет',
  proposalCreate: 'Создать предложение',
  proposalList: 'Список предложений',
  invoiceCreate: 'Создать счет',
  invoiceList: 'Список счетов',
  paymentCreate: 'Создать платеж',
  paymentList: 'Список платежей',
  returnCreate: 'Создать возврат',
  returnList: 'Список возвратов',
  contractCreate: 'Создать контракт',
  contractList: 'Список контрактов',
  productCreate: 'Создать продукт',
  productList: 'Список продуктов',
  serviceList: 'Список услуг',
  serviceCreate: 'Создать услугу',
  expenseCreate: 'Создать расход',
  expenseList: 'Список расходов',
  projectCreate: 'Создать проект',
  projectList: 'Список проектов',
  taskCreate: 'Создать задачу',
  taskList: 'Список задач',
  reminderList: 'Список напоминаний',
  reminderCreate: 'Создать напоминание',
  appointmentList: 'Список встреч',
  appointmentCreate: 'Создать встречу',
  noteCreate: 'Создать заметку',
  noteList: 'Список заметок',
  ticketList: 'Список тикетов',
  ticketCreate: 'Создать тикет',
  noResultsFound: 'Результаты не найдены',
  trySearching: 'Попробуйте поискать',
  hideButton: 'Скрыть кнопку',
};

export default locale; 
import profileLocale from "../domains/profile/locales/tr";

const locale = {
  profile: {
    ...profileLocale,
  },
  profileo: 'Teklif İste',
};

export default locale;

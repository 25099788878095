const locale = {
  // fields
  users: "Lembrar Usuários",
  date: "Data do Lembrete",
  title: "Listar Lembretes",
  detail: "Detalhe do Lembrete",
  sharedList: "Lembretes Compartilhados",
  privateList: "Lembretes Privados",
  ownList: "Meus Lembretes",
  allList: "Todos os Lembretes",
  reminder: "Lembrete",

  // actions
  create: "Criar Lembrete",
  add: "Adicionar Lembrete",
  update: "Atualizar Lembrete",
  delete: "Excluir Lembrete",

  // situational text
  createSuccess: "Lembrete criado com sucesso",
  updateSuccess: "Lembrete atualizado com sucesso",
  deletedSuccessfully: "Lembrete excluído com sucesso",
  deleteContent: "Tem certeza de que deseja excluir este lembrete? Esta ação não pode ser desfeita.",
  emptyState1: "Nenhum lembrete adicionado ainda.",
  emptyState2: "Armazene e gerencie todos os detalhes dos lembretes aqui para agilizar seu faturamento e manter acordos claros com seus clientes.",

  // detail drawer
  changeStatus: "Alterar Status",
  generalInfoTitle: "Informações Gerais",
  remindUsers: "Usuários a Lembrar",
  remindDate: "Data do Lembrete",
  description: "Descrição",
  status: "Status",
  createdBy: "Criado Por",
  schedule: "Agendar",
  cancelSuccess: "Lembrete cancelado com sucesso",
  completeSuccess: "Lembrete concluído com sucesso",
  scheduleSuccess: "Lembrete agendado com sucesso",
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import { IBaseError, IGraphqlVariables, IHasId, PermissionAction } from 'corede-common';
import {
  IProject,
  IProjectDetailResult,
  IProjectListInput,
  PermissionSubject,
  ProgressCalculationType,
  ProjectBillingType,
  ProjectStatus,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import {
  useProjectListCountQuery,
  useProjectListQuery,
  useProjectUpdateMutation,
} from '../../context/project.api';
import { ProjectDeleteDialog } from '../ProjectDeleteDialog';
import ProjectUpdateDrawerWrapper from '../update/ProjectUpdateDrawer.wrapper';
// import { CustomerInfoCellComponent } from '../../../../../../components/profile/ProjectList-grid-customerInfoCell.component';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  useHandleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import {
  representBooleanAsString,
  representDateAsString,
  representHtmlAsString,
} from 'utils/representationHelper';
import ProjectDetailDrawer from '../detailDrawer/ProjectDetailDrawer';
import { usePermissions } from 'permission/PermissionContext';
import { BaseGridColumnDefFactory } from 'components/baseDataGrid/factories/base-grid-column.factory';
import { EntityProfileFactory } from 'apps/crm/components/profile/profile-column-cell.component.helper';

export interface IProjectListGridComponent {
  // project

  setOpenProjectCreateDrawer?: (value: boolean) => void | undefined;
}

const ProjectListGridComponent = memo((props: IProjectListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedProject, setSelectedProject] = useState<TListGridSelectedEntity<IProject>>();
  const [openProjectDetailDrawer, setOpenProjectDetailDrawer] = useState(false);
  const [openProjectDeleteDialog, setOpenProjectDeleteDialog] = useState(false);
  const [openProjectUpdateDrawer, setOpenProjectUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.project,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.project,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.project,
    action: PermissionAction.delete,
  });

  const [projectListFilter, setProjectListFilter] = useState<IGraphqlVariables<IProjectListInput>>({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = projectListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = projectListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: projectListData,
    isLoading: projectListLoading,
    isFetching: projectListFetching,
    error: projectListError,
    refetch,
  } = useProjectListQuery(projectListFilter);

  const { data: projectListCountData } = useProjectListCountQuery({});

  // used for direct update in the list rows
  const [projectUpdate, { isLoading: projectUpdateLoading, error: projectUpdateError }] =
    useProjectUpdateMutation();

  // effects

  useEffect(() => {
    refetch();
  }, [projectListFilter]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(projectListError as IBaseError, currentLanguage);
  }, [projectListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(projectUpdateError as IBaseError, currentLanguage);
  }, [projectUpdateError]);

  const isEmptyState =
    projectListData?.data?.length === 0 &&
    !projectListLoading &&
    !projectListFetching &&
    projectListCountData?.count === 0;

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedProject}
      setSelectedEntity={setSelectedProject}
      listFilter={projectListFilter}
      setListFilter={setProjectListFilter}
      listData={projectListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      {projectListData ? (
        !isEmptyState ? (
          <BaseDataGrid
            rows={
              projectListData?.data?.map((project, index) => ({
                id:
                  ((projectListFilter.input?.pagination?.page || 1) - 1) *
                    (projectListFilter.input?.pagination?.pageSize || 0) +
                  index +
                  1,
                _id: project._id,
                name: project.name || '-',
                customerInfo1: {
                  name: project.customer?.name ?? '',
                  profileImage: {
                    thumbnailPublicUrl: project.customer?.image?.thumbnailPublicUrl,
                  },
                },
                customerInfo: project?.customer
                  ? EntityProfileFactory.CreateEntityProfileFrom({
                      _id: project?.customer?._id,
                      fullName: project?.customer?.name,
                      image: project?.customer?.image?.thumbnailPublicUrl,
                    })
                  : undefined,
                status: project.status || null,
                progressPercentage: project.progressPercentage || '-',
                progressCalculationType: project.progressCalculationType || '-',
                estimatedHours: project.estimatedHours || '-',
                startDate: representDateAsString(project.startDate, currentLanguage),
                deadline: representDateAsString(project.deadline, currentLanguage),
                isBillable: representBooleanAsString({
                  data: project.isBillable,
                  t: t,
                }),
                billingType: project.billingType || '-',
                billingRatePerHour: project.billingRatePerHour || '-',
                // TODO: members array user
                tags: project.tags || '-',
                description: representHtmlAsString({
                  data: project.description,
                  trimLength: 100,
                }),
              })) || []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'id',
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                    align: 'center',
                  },
                  headerConfig: {
                    name: '#',
                    align: 'center',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'name',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.name'),
                  },
                },
              },
              BaseGridColumnDefFactory.EntityProfileColumnDef({
                headerName: `${t('crm.customerLabel')}`,
                field: 'customerInfo',
              }),
              // {
              //   config: {
              //     baseGridColType: BaseGridColType.any,
              //     column: {
              //       field: 'customerInfo',
              //       width: 180,
              //       sortable: false,
              //       editable: false,
              //       filterable: false,
              //     },
              //     headerConfig: {
              //       name: `${t('crm.customerLabel')}`,
              //     },
              //     overrideCell: CustomerInfoCellComponent(),
              //   },
              // },

              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'status',
                    editable: hasUpdatePermission,
                    filterable: true,
                    width: 90,
                  },
                  headerConfig: {
                    name: t('crm.status'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `statuses`,
                    isArrayFilter: true,
                  },
                },
                data: ProjectStatus,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'progressPercentage',
                    editable: hasUpdatePermission,
                    filterable: true,
                  },
                  headerConfig: {
                    name: t('crm.project.project.progressPercentage'),
                  },
                },
              },
              // TODO : progress çubuk olarak göster
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'progressCalculationType',
                    editable: hasUpdatePermission,
                    filterable: true,
                    width: 170,
                  },
                  headerConfig: {
                    name: t('crm.project.project.progressCalculationType'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `progressCalculationTypes`,
                    isArrayFilter: true,
                  },
                },
                data: ProgressCalculationType,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'estimatedHours',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.project.project.estimatedHours'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'startDate',
                    filterable: false,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.project.project.startDate'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'deadline',
                    filterable: false,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.project.project.deadline'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'isBillable',
                    filterable: true,
                    editable: false,
                  },
                  headerConfig: {
                    name: t('crm.project.project.isBillable'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'billingType',
                    editable: false,
                    filterable: true,
                    width: 90,
                  },
                  headerConfig: {
                    name: t('crm.project.project.billingType'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `billingTypes`,
                    isArrayFilter: true,
                  },
                },
                data: ProjectBillingType,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'billingRatePerHour',
                    filterable: true,
                    editable: false,
                    width: 120,
                  },
                  headerConfig: {
                    name: t('crm.project.project.billingRatePerHour'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.textArray,
                  column: {
                    field: 'tags',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.tags'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'description',
                    filterable: true,
                    editable: hasUpdatePermission,
                    width: 200,
                  },
                  headerConfig: {
                    name: t('crm.description'),
                  },
                },
              },
            ]}
            actionColumn={{
              width: 80,
              defaultActions: {
                view: hasDetailPermission
                  ? {
                      clickConfig: {
                        setSelectedRow: setSelectedProject,
                        setOpenAction: setOpenProjectDetailDrawer,
                      },
                    }
                  : undefined,
                edit: hasUpdatePermission
                  ? {
                      clickConfig: {
                        setSelectedRow: setSelectedProject,
                        setOpenAction: setOpenProjectUpdateDrawer,
                      },
                    }
                  : undefined,
                delete: hasDeletePermission
                  ? {
                      clickConfig: {
                        setSelectedRow: setSelectedProject,
                        setOpenAction: setOpenProjectDeleteDialog,
                      },
                    }
                  : undefined,
              },
              customCellItems: [],
              actionHeaderName: t('crm.actions'),
            }}
            loading={projectListLoading || projectUpdateLoading || projectListFetching}
            listFilter={{
              filterInput: projectListFilter,
              setFilterInput: setProjectListFilter,
            }}
            update={{
              updateQuery: projectUpdate,
            }}
            count={projectListData?.count ?? 0}
            config={{
              columnVisibilityModel: {},
              features: [],
            }}
            toolbar={{
              customActions: [],
            }}
          />
        ) : (
          <EmptyState
            content1={t('crm.project.project.emptyState1')}
            content2={t('crm.project.project.emptyState2')}
            button1={{
              title: t('crm.project.project.create'),
              leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              onClick: () => (props?.setOpenProjectCreateDrawer ?? (() => {}))(true),
              permission: {
                subject: PermissionSubject.project,
                action: PermissionAction.create,
              },
            }}
          />
        )
      ) : null}

      <ProjectDetailDrawer
        open={openProjectDetailDrawer}
        setOpen={setOpenProjectDetailDrawer}
        selectedProject={selectedProject as IProjectDetailResult & IHasId<number>}
        setSelectedProject={setSelectedProject}
        sideNavigationProps={{
          count: projectListData?.count ?? 0,
          handleNavigate: useHandleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: projectListData,
            listFilter: projectListFilter,
            setListFilter: setProjectListFilter,
            selectedEntity: selectedProject,
            setSelectedEntity: setSelectedProject,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      />

      <ProjectUpdateDrawerWrapper
        open={openProjectUpdateDrawer}
        setOpen={setOpenProjectUpdateDrawer}
        selectedProject={selectedProject}
      />

      <ProjectDeleteDialog
        open={openProjectDeleteDialog}
        projectId={selectedProject?._id ?? ''}
        setProject={setSelectedProject}
        onClose={() => {
          setSelectedProject(undefined);
          setOpenProjectDeleteDialog(false);
        }}
        key={selectedProject?._id}
      />
    </DefaultListGridComponent>
  );
});

export default ProjectListGridComponent;

const locale = {
  // actions
  create: 'Crear Cliente',
  update: 'Actualizar Cliente',
  detail: 'Detalle del Cliente',
  view: 'Ver Cliente',
  list: 'Listar Clientes',
  addNewRole: 'Añadir Nuevo Rol',
  tagsPlaceholder: 'Añadir etiquetas',
  listInfo: 'Lista de todos los clientes.',
  weeklyReport: 'Informe Semanal',
  copyBillingInfo: 'Copiar Información de Facturación',

  // situational messages
  createdSuccessfully: 'Cliente creado con éxito',
  updatedSuccessfully: 'Cliente actualizado con éxito',
  deleteConfirm: '¿Está seguro de que desea eliminar este cliente? Esta acción no se puede deshacer.',
  deletedSuccessfully: 'Cliente eliminado con éxito',
  createInfo: 'Puede crear nuevos clientes aquí.',
  updateCustomerSuccess: 'Cliente actualizado con éxito',
  createCustomerSuccess: 'Cliente creado con éxito',
  emptyState1: 'No se encontraron clientes',
  emptyState2: 'Por favor, cree un nuevo cliente',

  // import
  importCustomer: 'Importar Cliente',
  import: 'Importar Cliente',
  importTitle: 'Importar Clientes',
  importText1: 'Puede importar clientes desde esta página.',
  importText2: 'Puede utilizar el formulario de muestra a continuación para publicar sus clientes.',

  // fields and names
  title: 'Clientes',
  customer: 'Cliente',
  customers: 'Clientes',
  assigneds: 'Asignados',
  qualification: 'Calificación',
  justOwn: 'Solo Propios',
  isJunk: 'Es Basura',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Basura',
  unJunk: 'No Basura',
  prospectName: 'Nombre del Prospecto',
  businessType: 'Tipo de Negocio',
  customerType: 'Tipo de Cliente',
  customerCount: 'clientes',
  customerValue: 'Valor del Cliente',

  // contact
  addContact: 'Añadir Contacto',
  contactedDate: 'Fecha de Contacto',
  emptyStateContactContent1: 'No se encontraron contactos aquí.',
  contactDetail: 'Detalle de Contacto',
  contactHistory: 'Historial de Contacto',
  lastContacted: 'Último Contacto',
  lastContact: 'Último Contacto',
  contacted: 'Contactado',
  otherContacts: 'Otros Contactos',

  onSiteEstimateWith: 'Estimación en sitio con',

  // new
  customerListTitle: 'Lista de Clientes',
  headerTitle: 'Clientes',
  relatedCustomers: 'Clientes Relacionados',
  b2bB2c: 'B2B / B2C',
  customerNameLabel: 'Nombre del Cliente',
  customerEmailLabel: 'Correo del Cliente',
  customerPhoneLabel: 'Teléfono del Cliente',
  customerTypeLabel: 'Tipo de Cliente',
  customerPictureLabel: 'Imagen del Cliente',
  customerLanguageLabel: 'Idioma del Cliente',

  createSuccess: 'Cliente creado con éxito',

  prospectType: 'Tipo de Prospecto',
  prospectAddress: 'Dirección del Prospecto',
  prospectContactName: 'Nombre de Contacto del Prospecto',

  // accordions
  primaryContact: 'Contacto Principal*',
  primaryBillingInfo: 'Información de Facturación Principal',
  primaryShippingInfo: 'Información de Envío Principal',
  detailInformation: 'Información Detallada',
  address: 'Dirección',

  // labels
  welcome: 'Bienvenido',
  description: 'Tu progreso con los clientes esta semana es increíble. Continuemos.',

  // new keys
  customerDetail: 'Detalle del Cliente',
  viewMüşteri: 'Ver',
  createMüşteri: 'Crear Cliente',
  columnParentCategory: 'Categoría Principal',
  columnActions: 'Acciones',
  createCustomerTitle: 'Crear Cliente',
  uploadPictureText: 'Subir Imagen',
  createCustomerButton: 'Crear Cliente',
  selectIconTitle: 'Seleccionar un Icono',
  searchIcons: 'Buscar Iconos',
  searchForAnIcon: 'Buscar un icono',
};

export default locale; 
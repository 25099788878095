const locale = {
  // fields
  users: "Benutzer erinnern",
  date: "Erinnerungsdatum",
  title: "Erinnerungen auflisten",
  detail: "Erinnerungsdetail",
  sharedList: "Geteilte Erinnerungen",
  privateList: "Private Erinnerungen",
  ownList: "Meine Erinnerungen",
  allList: "Alle Erinnerungen",
  reminder: "Erinnerung",

  // actions
  create: "Erinnerung erstellen",
  add: "Erinnerung hinzufügen",
  update: "Erinnerung aktualisieren",
  delete: "Erinnerung löschen",

  // situational text
  createSuccess: "Erinnerung erfolgreich erstellt",
  updateSuccess: "Erinnerung erfolgreich aktualisiert",
  deletedSuccessfully: "Erinnerung erfolgreich gelöscht",
  deleteContent: "Sind Sie sicher, dass Sie diese Erinnerung löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  emptyState1: "Noch keine Erinnerungen hinzugefügt.",
  emptyState2: "Speichern und verwalten Sie hier alle Erinnerungsdetails, um Ihre Rechnungsstellung zu optimieren und klare Vereinbarungen mit Ihren Kunden zu treffen.",

  // detail drawer
  changeStatus: "Status ändern",
  generalInfoTitle: "Allgemeine Informationen",
  remindUsers: "Benutzer erinnern",
  remindDate: "Erinnerungsdatum",
  description: "Beschreibung",
  status: "Status",
  createdBy: "Erstellt von",
  schedule: "Planen",
  cancelSuccess: "Erinnerung erfolgreich abgebrochen",
  completeSuccess: "Erinnerung erfolgreich abgeschlossen",
  scheduleSuccess: "Erinnerung erfolgreich geplant",
};

export default locale; 
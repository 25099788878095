const locale = {
  demo: "Демо",
  title: "Что такое <highlight>CoCRM</highlight> Lead+?",
  description:
    "В CoCRM мы не только поддерживаем вас в управлении вашими бизнес-процессами и клиентами, но и с радостью помогаем вам приобретать новых клиентов.",
  featuresTitle: "С CoCRM Lead+,",
  features1:
    "Мы рады предоставить вам необходимые данные о потенциальных клиентах на основе",
  features2:
    "Параметров страны, города и/или отрасли, которые вы нацеливаете для маркетинга.",
  features3: "Конкретных областей, которые вы определили для своих целей продаж.",
  features4: "Рынков, где вы хотите повысить узнаваемость бренда.",
  buttonTitle: "Запросить Цену",
  formDescription:
    "Пожалуйста, заполните форму ниже, и мы свяжемся с вами как можно скорее.",
  formTitle: "Запросить Цену",
  formButtonTitle: "Отправить",
  sendSuccess: "Спасибо! Мы свяжемся с вами как можно скорее.",
  fullName: "Полное Имя",
  email: "Электронная Почта",
  phone: "Телефон",
  orgName: "Название Организации",
};

export default locale; 
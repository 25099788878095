const locale = {
  product: 'Produtos',
  title: 'Produtos',
  create: 'Criar Produto',
  update: 'Atualizar Produto',
  delete: 'Excluir Produto',
  deleteConfirm: 'Tem certeza que deseja excluir este status: ',
  deletedSuccessfully: 'Produto excluído com sucesso',
  createProduct: 'Criar Produto',
  createSuccess: 'Produto criado com sucesso',
  updateSuccess: 'Produto atualizado com sucesso',
  name: 'Nome',
  icon: 'Ícone',
  showMore: 'Mostrar Mais',
  detail: 'Detalhe do Produto',
  type: 'Tipo',
  image: 'Imagem',
  sku: 'Código',
  description: 'Descrição',
  currency: 'Moeda',
  price: 'Preço',
  priceType: 'Tipo de Preço',
  discountedPrice: 'Preço com Desconto',
  unit: 'Unidade',
  categoryId: 'Categoria',
  natTaxRate: 'Taxa de Imposto Nacional',
  intTaxRate: 'Taxa de Imposto Internacional',
  unitPrice: 'Preço Unitário',
  categories: 'Categorias',

  emptyState1: 'Nenhum produto registrado ainda.',
  emptyState2:
    'Gerencie e revise quaisquer produtos aqui para manter seus registros precisos e atender às necessidades dos clientes.',
};

export default locale; 
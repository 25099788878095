const locale = {
  readAllSuccess: '¡Todas las notificaciones leídas con éxito!',
  title: 'Notificaciones',
  markAllAsRead: 'Marcar todas como leídas.',
  close: 'Cerrar',
  noNotifications1: "Sin notificaciones",
  noNotifications2: "Aún no has recibido ninguna notificación.",
  refresh: 'Actualizar',
};

export default locale; 
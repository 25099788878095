const locale = {
  permissions: "Autorisations",
  errorFetchingPermissions: "Erreur lors de la récupération des autorisations",
  allPermissions: "Toutes les autorisations",
  all: "Tout",
  ownPermissionsAlert: "Vous ne pouvez pas modifier vos propres autorisations.",
  action: "Action",
  actionScope: "Portée de l'action",
  updateSuccess: "Autorisation mise à jour avec succès",
};

export default locale; 
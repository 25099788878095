const locale = {
  supports: 'Tickets',
  support: 'Ticket',
  detail: 'Détails du Ticket',
  subject: 'Sujet',
  title: 'Tickets',
  create: 'Créer un Ticket',
  createSuccess: 'Ticket créé avec succès.',
  update: 'Mettre à jour le Ticket',
  updateSuccess: 'Ticket mis à jour avec succès.',
  delete: 'Supprimer le Ticket',
  deleteSuccess: 'Ticket supprimé avec succès.',
  deleteContent: 'Êtes-vous sûr de vouloir supprimer le ticket?',
  emptyState1: 'Pas encore de ticket.',
  emptyState2:
    'Vous n\'avez pas créé de tickets, ou ils ont déjà été résolus. Une fois que votre équipe soumettra une demande de support, tous les tickets apparaîtront ici.',

  // detailDrawer
  generalInfoTitle: 'Informations Générales',
  ticketInfoTitle: 'Informations sur le Ticket',
  closedDate: 'Date de Clôture',
  changeStatus: 'Changer le Statut',
  statusChangeSuccess: 'Statut changé avec succès.',
};

export default locale; 
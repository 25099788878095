const locale = {
  demo: "Demo",
  title: "O que é <highlight>CoCRM</highlight> Marketing?",
  description:
    "Na CoCRM, não apenas facilitamos a gestão dos seus relacionamentos com clientes existentes — também ajudamos a otimizar seus processos de marketing para atingir seus objetivos de negócio. Com o CoCRM Marketing, você pode alcançar seu público-alvo de forma mais eficaz e tornar suas campanhas de marketing mais eficientes.",
  featuresTitle: "Com o CoCRM Marketing, você pode:",
  features1:
    "Personalizar suas campanhas de marketing por país, cidade e setor para uma gestão mais estratégica,",
  features2:
    "Criar campanhas personalizadas adaptadas aos mercados específicos onde deseja aumentar o reconhecimento da sua marca,",
  features3:
    "Acompanhar a jornada do cliente de forma mais eficiente para apoiar seus objetivos de vendas e marketing,",
  features4:
    "Desenvolver estratégias orientadas a resultados integrando ferramentas de marketing digital.",
  buttonTitle: "Solicitar orçamento",
  formDescription:
    "Preencha o formulário e entraremos em contato o mais breve possível.",
  sendSuccess: "Obrigado! Entraremos em contato o mais breve possível.",
  formTitle: "Solicitar orçamento",
  formButtonTitle: "Enviar",
  fullName: "Nome completo",
  email: "E-mail",
  phone: "Telefone",
  orgName: "Nome da organização",
};

export default locale; 
const locale = {
  projects: 'Proyectos',
  project: 'Proyecto',
  projectConversions: 'Conversiones de Proyecto',
  title: 'Proyectos',
  create: 'Crear Proyecto',
  update: 'Actualizar Proyecto',
  delete: 'Eliminar Proyecto',
  createSuccess: 'Proyecto creado con éxito.',
  updateSuccess: 'Proyecto actualizado con éxito.',
  deleteSuccess: 'Proyecto eliminado con éxito.',
  deleteConfirm: '¿Está seguro de que desea eliminar este proyecto? Esta acción no se puede deshacer.',
  emptyState1: 'Aún no se ha iniciado ningún proyecto.',
  emptyState2:
    'Cree y gestione sus proyectos aquí para seguir el progreso, asignar tareas y colaborar con su equipo.',
  progressCalculationType: 'Cálculo de Progreso',
  progressPercentage: 'Progreso %',
  isBillable: 'Facturable',
  billingType: 'Tipo de Facturación',
  billingRatePerHour: 'Tarifa por Hora',
  members: 'Miembros',
  startDate: 'Fecha de Inicio',
  deadline: 'Fecha Límite',
  estimatedHours: 'Horas Estimadas',
  // labels
  welcome: 'Bienvenido',
  description: "El progreso de su proyecto esta semana es increíble. Continuemos.",

  // detail
  changeStatus: 'Cambiar Estado',
  generalInfoTitle: 'Información General',
  customerInfoTitle: 'Información del Cliente',
  contactPhoneNumber: 'Número de Teléfono de Contacto',
  membersTitle: 'Miembros',
  milestone: 'Hito',
  timeSheet: 'Hoja de Tiempo',
  projectInfoTitle: 'Información del Proyecto',
  billingInfoTitle: 'Información de Facturación',
  statusChangeSuccess: 'Estado del proyecto cambiado con éxito.',
  detail: 'Detalle del Proyecto',
  noMember: 'Aún no hay miembros.',

  // timeSheet
  noAssigneeToCreateTimeSheet: 'Por favor, asigne usuarios al proyecto para crear hojas de tiempo',
  emptyStateTimeSheetList1: 'Aún no hay hojas de tiempo.',
  addTimeSheet: 'Añadir Hoja de Tiempo',
  timeSheetCreate: 'Crear Hoja de Tiempo',
  timeSheetUpdate: 'Actualizar Hoja de Tiempo',
  member: 'Miembro',
  startTime: 'Hora de Inicio',
  endTime: 'Hora de Fin',
  duration: 'Duración',
  timeSheetCreateSuccess: 'Hoja de tiempo creada con éxito.',
  timeSheetUpdateSuccess: 'Hoja de tiempo actualizada con éxito.',
  timeSheetDeleteSuccess: 'Hoja de tiempo eliminada con éxito.',

  // milestone
  emptyStateMilestoneList1: 'Aún no hay hitos.',
  addMilestone: 'Añadir Hito',
  milestoneCreate: 'Crear Hito',
  milestoneUpdate: 'Actualizar Hito',
  milestoneCreateSuccess: 'Hito creado con éxito.',
  milestoneUpdateSuccess: 'Hito actualizado con éxito.',
  milestoneDeleteSuccess: 'Hito eliminado con éxito.',
  addTask: 'Añadir Tarea',
};

export default locale; 
import React from 'react';
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
  Tooltip,
  IconButton,
  Button,
  Grid,
  Box,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Iconify } from 'components';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type Props = {
  open: boolean;
  setOpen?: (open: boolean) => void;
  onClose?: () => void;
  size?: 'small' | 'medium';
  title: string | JSX.Element;
  children: React.ReactNode;
  handleClick?: () => void;
  handleSubmit?: () => void;
  buttonTitle?: string;
  disabled?: boolean;
  loading?: boolean;
  width?: number;
  height?: number;
  leftIcon?: JSX.Element;
  backgroundColor?: string;
  buttonColor?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  sx?: object;
  buttonCancelTitle?: string;
  date?: string | Date;
  summary?: JSX.Element;
};

export enum OverlayType {
  dialog = 'dialog',
  drawer = 'drawer',
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ActionDialog = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.open}
      onClose={() =>
        props?.buttonCancelTitle !== t('crm.logout')
          ? props.setOpen
            ? props.setOpen(false)
            : props.onClose
              ? props.onClose()
              : null
          : null
      }
      TransitionComponent={Transition}
      sx={{
        '& .MuiDialog-paper': {
          width: props.width ?? 'auto',
          maxWidth: props.width ?? 'auto',
          height: props.height ?? 'auto',
        },
      }}
    >
      <DialogTitle
        bgcolor={'background.paper'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Stack
          pl={0}
          pb={0}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width={'100%'}
        >
          {typeof props.title === 'string' ? (
            <Typography variant="h5" gutterBottom>
              {props.title}
            </Typography>
          ) : (
            <Box width={'100%'}>{props.title}</Box>
          )}
        </Stack>

        <Tooltip title={t('webNotification.close')}>
          <IconButton
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              props.setOpen ? props.setOpen(false) : props.onClose ? props.onClose() : null
            }
          >
            <Iconify icon={'material-symbols:close'} />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ mt: 0, py: 0 }}>
        <Grid container mt={2} gap={2} mb={2}>
          {props.children}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pb: 3,
          pt: 2,
          px: 3,
        }}
      >
        {props.date ? (
          <Typography fontSize={'15px'}>{moment(props.date).format()}</Typography>
        ) : props.summary ? (
          props.summary
        ) : (
          <Typography />
        )}

        <Stack direction={'row'} sx={{ gap: 1 }}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            onClick={() =>
              props.setOpen ? props.setOpen(false) : props.onClose ? props.onClose() : null
            }
            sx={{
              fontSize: 14,
              height: 'auto',
              borderRadius: '24px',
            }}
          >
            {props?.buttonCancelTitle ?? t('cancel')}
          </Button>

          {(props.handleClick || props.handleSubmit) && (
            <Button
              type="submit"
              variant="contained"
              disabled={props.disabled || props.loading}
              onClick={() =>
                props.handleClick
                  ? props.handleClick()
                  : props.handleSubmit
                    ? props.handleSubmit()
                    : null
              }
              sx={{
                fontSize: 14,
                height: 'auto',
                borderRadius: '24px',
                backgroundColor: 'primary.lighter',
                color: 'secondary.main',
              }}
            >
              {props.loading && <Iconify icon={'eos-icons:loading'} width={20} />}
              {props.buttonTitle ?? t('submit')}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;

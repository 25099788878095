const locale = {
  product: 'Produits',
  title: 'Produits',
  create: 'Créer un Produit',
  update: 'Mettre à jour le Produit',
  delete: 'Supprimer le Produit',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce statut : ',
  deletedSuccessfully: 'Produit supprimé avec succès',
  createProduct: 'Créer un Produit',
  createSuccess: 'Produit créé avec succès',
  updateSuccess: 'Produit mis à jour avec succès',
  name: 'Nom',
  icon: 'Icône',
  showMore: 'Afficher Plus',
  detail: 'Détail du Produit',
  type: 'Type',
  image: 'Image',
  sku: 'Référence',
  description: 'Description',
  currency: 'Devise',
  price: 'Prix',
  priceType: 'Type de Prix',
  discountedPrice: 'Prix Réduit',
  unit: 'Unité',
  categoryId: 'Catégorie',
  natTaxRate: 'Taux de Taxe National',
  intTaxRate: 'Taux de Taxe International',
  unitPrice: 'Prix Unitaire',
  categories: 'Catégories',

  emptyState1: 'Aucun produit enregistré pour le moment.',
  emptyState2:
    'Gérez et examinez tous les produits ici pour maintenir vos enregistrements précis et répondre aux besoins des clients.',
};

export default locale; 
import demoLocale from "../subdomains/demo/locales/pt";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  demo: {
    ...demoLocale,
  },
};

export default locale; 
const locale = {
  demo: "Demo",
  title: "O que é a Janela <highlight>CoCRM</highlight>?",
  description:
    "A Janela CoCRM é uma área de desenvolvimento de negócios projetada exclusivamente para empresas usuárias do CoCRM, onde podem fornecer transferências diretamente para membros dentro do ecossistema CoCRM em relação aos seus produtos, serviços ou campanhas, e capturar oportunidades de negócios definindo descontos especiais para indivíduos e instituições que visitam nosso site.",
  featuresTitle: "Com a Janela CoCRM;",
  features1:
    "Você pode fazer ofertas diretamente aos visitantes do nosso site com mais de 1 milhão de tráfego mensal,",
  features2:
    "Você pode fazer vários anúncios de campanhas para empresas dentro do nosso ecossistema,",
  features3:
    "Você pode fornecer informações detalhadas sobre seus produtos e serviços participando das campanhas de blog que realizaremos,",
  features4:
    "Você pode fazer uma contribuição adicional de 36,2% para o reconhecimento da sua marca!",
  buttonTitle: "Solicitar um orçamento!",
  formDescription:
    "Preencha o formulário e entraremos em contato o mais breve possível.",
  sendSuccess: "Obrigado! Entraremos em contato o mais breve possível.",
  formTitle: "Solicitar um orçamento",
  formButtonTitle: "Enviar",
  fullName: "Nome completo",
  email: "Email",
  phone: "Telefone",
  orgName: "Nome da organização",
};

export default locale; 
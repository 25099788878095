const locale = {
  productCategories: 'Catégories',
  title: 'Catégories de Produits/Services',
  create: 'Créer une Catégorie',
  delete: 'Supprimer la Catégorie',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer cette catégorie : ',
  deletedSuccessfully: 'Catégorie supprimée avec succès',
  createCategory: 'Créer une Catégorie',
  categoryCreateSuccess: 'Catégorie créée avec succès',
  name: 'Nom de la Catégorie',
  icon: 'Icône de la Catégorie',
  showMore: 'Afficher Plus',
  update: 'Mettre à jour',
  updateCategory: 'Mettre à jour la Catégorie',
  categoryUpdateSuccess: 'Catégorie mise à jour avec succès',
  en: 'Anglais',
  tr: 'Turc',
  createSuccess: 'Catégorie créée avec succès',
  updateSuccess: 'Catégorie de produit mise à jour avec succès',
  actions: 'Actions',
  createdAt: 'Créé le',
  emptyState1: 'Aucune catégorie trouvée',
  emptyState2: 'Veuillez créer une nouvelle catégorie',
  createProductCategory: 'Créer une Catégorie de Produit',
};

export default locale; 
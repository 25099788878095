/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card, Stack } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import EmptyState from 'components/emptyState/EmptyState';
import {
  IBaseError,
  IGraphqlVariables,
  IHasId,
  isNullOrUndefinedOrEmptyObject,
  PermissionAction,
} from 'corede-common';
import {
  IReminder,
  IReminderDetailResult,
  IReminderListInput,
  PermissionSubject,
  ReminderTargetType,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useReminderListQuery, useReminderUpdateMutation } from '../../context/reminder.api';
import { ReminderDeleteDialog } from '../ReminderDeleteDialog';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  useHandleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import ReminderDetailDrawerWrapper from '../detailDrawer/ReminderDetailDrawer';
import ReminderUpdateDrawerWrapper from '../update/ReminderUpdateOverlay.wrapper';
import { representDateTimeAsString, representHtmlAsString } from 'utils/representationHelper';
import { OverlayType } from 'components/dialog/ActionDialog';
import { reminderListColumnData } from './ReminderList-grid.columnData';
import { DefaultTabForListComponentProps } from 'apps/crm/components/tabs/DefaultTabs.forList.component';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import ReminderCreateOverlayWrapper from '../create/ReminderCreateOverlay.wrapper';
import { useLocation } from 'react-router-dom';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export interface IReminderListGridComponent extends DefaultTabForListComponentProps {
  canCreate: boolean;
  targetType?: ReminderTargetType;
  targetId?: string;
  createdById?: string;
}

const ReminderListGridComponent = memo((props: IReminderListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const location = useLocation();

  // states
  const [selectedReminder, setSelectedReminder] = useState<TListGridSelectedEntity<IReminder>>();
  const [openReminderDetailDrawer, setOpenReminderDetailDrawer] = useState(false);
  const [openReminderDeleteDialog, setOpenReminderDeleteDialog] = useState(false);
  const [openReminderUpdateDrawer, setOpenReminderUpdateDrawer] = useState(false);
  const [openReminderCreateOverlay, setOpenReminderCreateOverlay] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.reminder,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.reminder,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.reminder,
    action: PermissionAction.delete,
  });

  const [customReminderListFilter, setCustomReminderListFilter] = useState<
    IGraphqlVariables<IReminderListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const [reminderListFilter, setReminderListFilter] = useState<
    IGraphqlVariables<IReminderListInput>
  >({
    input: {
      filter: {
        targetTypes: props.targetType ? [props.targetType] : undefined,
        targetIds: props.targetId ? [props.targetId] : undefined,
        createdById: props.createdById,
      },
      pagination: { page: 1, pageSize: 10 },
    },
  });
  const currentPage = reminderListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = reminderListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: reminderListData,
    isLoading: reminderListLoading,
    isFetching: reminderListFetching,
    error: reminderListError,
    refetch,
  } = useReminderListQuery(reminderListFilter);

  // used for direct update in the list rows
  const [reminderUpdate, { isLoading: reminderUpdateLoading, error: reminderUpdateError }] =
    useReminderUpdateMutation();

  // effects

  useEffect(() => {
    setReminderListFilter({
      ...customReminderListFilter,
      input: {
        ...customReminderListFilter.input,
        filter: {
          targetTypes: props.targetType ? [props.targetType] : undefined,
          targetIds: props.targetId ? [props.targetId] : undefined,
          createdById: props.createdById,
          ...customReminderListFilter.input?.filter,
        },
      },
    });
  }, [customReminderListFilter]);

  // useEffect(() => {
  //   props.setReminderListLoading && props.setReminderListLoading(reminderListLoading);
  // }, [reminderListLoading]);
  useEffect(() => {
    refetch();
  }, [reminderListFilter]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(reminderListError as IBaseError, currentLanguage);
  }, [reminderListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(reminderUpdateError as IBaseError, currentLanguage);
  }, [reminderUpdateError]);

  const isEmptyState =
    reminderListData?.data?.length === 0 &&
    !reminderListLoading &&
    !reminderListFetching &&
    isNullOrUndefinedOrEmptyObject(customReminderListFilter?.input?.filter);

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenReminderCreateOverlay(true);
    }
  }, [location]);
  return (
    <DefaultTabContainer
      title={t(props.name ?? 'crm.reminders')}
      rightButton={
        props.canCreate
          ? {
              icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
              title: t(`crm.calendar.reminder.create`),
              onClick: () => {
                setOpenReminderCreateOverlay(true);
              },
              permission: {
                subject: PermissionSubject.reminder,
                action: PermissionAction.create,
              },
            }
          : undefined
      }
    >
      <DefaultListGridComponent
        selectedEntity={selectedReminder}
        setSelectedEntity={setSelectedReminder}
        listFilter={reminderListFilter}
        setListFilter={setReminderListFilter}
        listData={reminderListData}
        pendingNavigation={pendingNavigation}
        setPendingNavigation={setPendingNavigation}
        listFetching
        listLoading
      >
        <Stack
          sx={{ p: '0px', height: 'calc(100dvh - 250px)' }}
        >
          {reminderListData ? (
            !isEmptyState ? (
              <BaseDataGrid
                rows={
                  reminderListData?.data?.map((reminder, index) => ({
                    id:
                      ((reminderListFilter.input?.pagination?.page || 1) - 1) *
                        (reminderListFilter.input?.pagination?.pageSize || 0) +
                      index +
                      1,
                    _id: reminder._id,
                    title: reminder.title || '-',
                    content: representHtmlAsString({ data: reminder.content, trimLength: 50 }),
                    status: reminder.status || '-',
                    tags: reminder.tags || '-',
                    remindDate: reminder.remindDate || '-',
                    remindDateString: representDateTimeAsString(reminder.remindDate) || '-',
                    priority: reminder.priority || '-',
                    target: reminder.target || '-',
                    targetType: reminder.targetType || '-',
                    remindUsers: reminder.remindUsers || '-',
                    createdAt: representDateTimeAsString(reminder.createdAt) || '-',
                  })) || []
                }
                columns={reminderListColumnData({
                  t,
                  containsTargetType: !props.targetType,
                  hasUpdatePermission,
                })}
                actionColumn={{
                  width: 80,
                  defaultActions: {
                    view: hasDetailPermission
                      ? {
                          clickConfig: {
                            setSelectedRow: setSelectedReminder,
                            setOpenAction: setOpenReminderDetailDrawer,
                          },
                        }
                      : undefined,
                    edit: hasUpdatePermission
                      ? {
                          clickConfig: {
                            setSelectedRow: setSelectedReminder,
                            setOpenAction: setOpenReminderUpdateDrawer,
                          },
                        }
                      : undefined,
                    delete: hasDeletePermission
                      ? {
                          clickConfig: {
                            setSelectedRow: setSelectedReminder,
                            setOpenAction: setOpenReminderDeleteDialog,
                          },
                        }
                      : undefined,
                  },
                  customCellItems: [],
                  actionHeaderName: t('crm.actions'),
                }}
                loading={reminderListLoading || reminderUpdateLoading || reminderListFetching}
                listFilter={{
                  filterInput: customReminderListFilter,
                  setFilterInput: setCustomReminderListFilter,
                }}
                update={{
                  updateQuery: hasUpdatePermission ? reminderUpdate : undefined,
                }}
                count={reminderListData?.count ?? 0}
                config={{
                  columnVisibilityModel: {},
                  features: [],
                }}
              />
            ) : (
              <EmptyState
                content1={t('crm.calendar.reminder.emptyState1')}
                content2={t('crm.calendar.reminder.emptyState2')}
              />
            )
          ) : null}
        </Stack>
      </DefaultListGridComponent>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.reminder,
          action: PermissionAction.detail,
        }}
      >
        <ReminderDetailDrawerWrapper
          open={openReminderDetailDrawer}
          setOpen={setOpenReminderDetailDrawer}
          selectedReminder={selectedReminder as IReminderDetailResult & IHasId<number>}
          setSelectedReminder={setSelectedReminder}
          sideNavigationProps={{
            count: reminderListData?.count ?? 0,
            handleNavigate: useHandleSideNavigationNavigate({
              currentPage,
              currentPageSize,
              listData: reminderListData,
              listFilter: reminderListFilter,
              setListFilter: setReminderListFilter,
              selectedEntity: selectedReminder,
              setSelectedEntity: setSelectedReminder,
              setPendingNavigation: setPendingNavigation,
            }),
          }}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.reminder,
          action: PermissionAction.update,
        }}
      >
        <ReminderUpdateDrawerWrapper
          open={openReminderUpdateDrawer}
          setOpen={setOpenReminderUpdateDrawer}
          targetId={props.targetId}
          targetType={props.targetType}
          reminderId={selectedReminder?._id ?? ''}
          overlayType={OverlayType.drawer}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.reminder,
          action: PermissionAction.delete,
        }}
      >
        <ReminderDeleteDialog
          open={openReminderDeleteDialog}
          reminderId={selectedReminder?._id ?? ''}
          setReminder={setSelectedReminder}
          onClose={() => {
            setSelectedReminder(undefined);
            setOpenReminderDeleteDialog(false);
          }}
          key={selectedReminder?._id}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.reminder,
          action: PermissionAction.create,
        }}
      >
        <ReminderCreateOverlayWrapper
          open={openReminderCreateOverlay}
          setOpen={setOpenReminderCreateOverlay}
          targetId={props.targetId}
          targetType={props.targetType}
          overlayType={OverlayType.drawer}
        />
      </PermissionWrapper>
    </DefaultTabContainer>
  );
});

export default ReminderListGridComponent;

const locale = {
  // fields
  users: "Recordar a usuarios",
  date: "Fecha de recordatorio",
  title: "Listar recordatorios",
  detail: "Detalle del recordatorio",
  sharedList: "Recordatorios compartidos",
  privateList: "Recordatorios privados",
  ownList: "Mis recordatorios",
  allList: "Todos los recordatorios",
  reminder: "Recordatorio",

  // actions
  create: "Crear recordatorio",
  add: "Añadir recordatorio",
  update: "Actualizar recordatorio",
  delete: "Eliminar recordatorio",

  // situational text
  createSuccess: "Recordatorio creado con éxito",
  updateSuccess: "Recordatorio actualizado con éxito",
  deletedSuccessfully: "Recordatorio eliminado con éxito",
  deleteContent: "¿Está seguro de que desea eliminar este recordatorio? Esta acción no se puede deshacer.",
  emptyState1: "Aún no se han añadido recordatorios.",
  emptyState2: "Almacene y gestione aquí todos los detalles de los recordatorios para agilizar su facturación y mantener acuerdos claros con sus clientes.",

  // detail drawer
  changeStatus: "Cambiar estado",
  generalInfoTitle: "Información general",
  remindUsers: "Usuarios a recordar",
  remindDate: "Fecha de recordatorio",
  description: "Descripción",
  status: "Estado",
  createdBy: "Creado por",
  schedule: "Programar",
  cancelSuccess: "Recordatorio cancelado con éxito",
  completeSuccess: "Recordatorio completado con éxito",
  scheduleSuccess: "Recordatorio programado con éxito",
};

export default locale; 
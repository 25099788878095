const locale = {
  demo: 'Demo',
  adjustment: 'Ajuste',
  currency: 'Moeda',
  exchangeRates: 'Taxas de câmbio',
  fromCurrency: 'Da moeda',
  toCurrency: 'Para moeda',
  rate: 'Taxa',
  updatedAt: 'Atualizado em',
  language: 'Idioma',
  locale: 'Localização',
  updateSettings: 'Atualizar configurações',
  NDA: 'Acordo de confidencialidade',
  followUpMailTemplate: 'Modelo de e-mail de acompanhamento',
  emailType: 'Tipo de e-mail',
  subject: 'Assunto',
  content: 'Conteúdo',
  variables: 'Variáveis',
  prefix: 'Prefixo',
  paymentPrefix: 'Prefixo de pagamento',
  returnPrefix: 'Prefixo de devolução',
  termsAndConditions: 'Termos e condições',
  paymentMethods: 'Métodos de pagamento',
  companyBackground: 'Histórico da empresa',
  companyReferences: 'Referências da empresa',
  timezone: 'Fuso horário',
  updateSuccess: 'Configurações atualizadas com sucesso',
  name: 'Nome',
  surname: 'Sobrenome',
  email: 'E-mail',
  phone: 'Telefone',
  identityNumber: 'Número de identidade',
  vat: 'Número de IVA',
  country: 'País',
  state: 'Estado/Província',
  city: 'Cidade',
  street: 'Rua',
  address: 'Endereço',
  taxOffice: 'Repartição fiscal',
  zip: 'CEP',
  fullName: 'Nome completo',
  update: 'Atualizar',
  create: 'Criar',
  updateBillingInfo: 'Atualizar informações de faturamento',
  updateBillingInfoSuccess: 'Informações de faturamento atualizadas com sucesso',
  commonSettingsUpdateSuccess: 'Configurações atualizadas com sucesso',
  save: 'Salvar',
  organizationSettings: 'Organização',
  monthly: 'mensal',
  trial: 'Teste',
  free: 'Gratuito',
  trialEnds: 'O teste termina',
  browsePackages: 'Navegar pelos pacotes',
  cancelSubscription: 'Cancelar assinatura',
  updateOrganizationLogoSuccess: 'Logo da organização atualizado com sucesso',
  organizationLogo: 'Logo da organização',
  summary: 'Resumo',
  website: 'Site',
  legalName: 'Nome legal',
  dateFormat: 'Formato de data',
  timeFormat: 'Formato de hora',
  vatNumber: 'Número de IVA',
  taxRate: 'Taxa de imposto',
  exchangeRateMode: 'Modo de taxa de câmbio',
  general: 'Geral',
  localizations: 'Localizações',
  finance: 'Finanças',
  generalInfo: 'Informações gerais',
  socialMedia: 'Redes sociais',
  billingInfo: 'Informações de faturamento',
  cards: 'Cartões',
  currentPlan: 'Plano atual',
  invoices: 'Faturas',
  cardListSecure: 'Seus cartões são armazenados no banco de dados iyzico.',
  noInvoiceContent1: "Você ainda não tem nenhuma fatura.",
  noInvoiceContent2:
    'Acompanhe e gerencie pagamentos para suas faturas aqui para manter suas finanças organizadas e atualizadas.',
  noLanguageContent1: "Você ainda não tem nenhum idioma.",
  noLanguageContent2:
    'Crie e gerencie idiomas aqui para tornar seu site acessível a um público global.',
  noLeadContent1: 'As configurações de leads não estão disponíveis no momento.',
  noLeadContent2:
    'Personalize como os leads são capturados, atribuídos e rastreados para otimizar seu fluxo de trabalho de CRM.',
  noCustomerContent1: 'Nenhuma configuração de cliente configurada ainda.',
  noCustomerContent2:
    'Configure preferências para gerenciar informações e interações de clientes de forma eficaz em seu CRM.',
  noEstimateContent1: 'Nenhuma configuração de fatura configurada ainda.',
  noEstimateContent2:
    'Personalize suas preferências de fatura aqui para agilizar o faturamento e garantir registros precisos.',
  noProposalContent1: 'As configurações de proposta não estão disponíveis no momento.',
  noProposalContent2:
    'Personalize suas preferências de proposta aqui para agilizar a comunicação com o cliente e melhorar as vendas.',
  noInvoiceContent3: 'Nenhuma configuração de fatura configurada ainda.',
  noInvoiceContent4:
    'Personalize suas preferências de fatura aqui para agilizar o faturamento e garantir registros precisos.',
  noProjectContent1: 'As configurações de projeto ainda não foram acessadas.',
  noProjectContent2:
    'Personalize suas preferências de projeto aqui para otimizar o gerenciamento de tarefas e o fluxo de trabalho em seu CRM.',
  noTaskContent1: 'As configurações de tarefa ainda não foram acessadas.',
  noTaskContent2:
    'Configure suas preferências de tarefa aqui para agilizar o gerenciamento de tarefas e melhorar a produtividade da equipe.',
  noTicketContent1: 'As configurações de ticket/suporte ainda não foram acessadas.',
  noTicketContent2:
    'Configure suas preferências aqui para gerenciar solicitações de suporte ao cliente e garantir resoluções oportunas.',
  noFileManagementContent1: 'As configurações de gerenciamento de arquivos ainda não foram acessadas.',
  noFileManagementContent2:
    'Configure suas preferências aqui para organizar e gerenciar documentos de forma eficiente em seu CRM.',
  noCalendarContent1: 'As configurações de calendário ainda não foram acessadas.',
  noCalendarContent2:
    'Configure suas preferências aqui para personalizar seu calendário e otimizar o agendamento em seu CRM.',
  noNotificationContent1: 'As configurações de notificação ainda não foram acessadas.',
  noNotificationContent2:
    'Personalize suas preferências de notificação aqui para se manter informado e atualizado sobre atividades importantes em seu CRM.',
  noLeadPlusContent1: 'Nenhum produto ou plano Lead+ ativo.',
  noLeadPlusContent2:
    'Compre ou autorize um plano para desbloquear acesso completo e personalizar suas configurações de Lead+.',
  noMarketingContent1: 'Nenhum produto ou plano de Marketing ativo.',
  noMarketingContent2:
    'Compre ou autorize um plano para desbloquear acesso completo e personalizar suas configurações de Marketing.',
  noWindowContent1: 'Nenhum produto ou plano Window ativo.',
  noWindowContent2:
    'Compre ou autorize um plano para desbloquear acesso completo e personalizar suas configurações de Window.',
  noCommunityContent1: 'Nenhum produto ou plano Community ativo.',
  noCommunityContent2:
    'Compre ou autorize um plano para desbloquear acesso completo e personalizar suas configurações de Community.',
  noIntegrationsContent1: 'Nenhuma integração configurada ainda.',
  noIntegrationsContent2:
    'Configure integrações aqui para aprimorar as capacidades do CoCRM e agilizar fluxos de trabalho com conexões autorizadas.',
  noMenuContent1: 'As configurações de menu não estão disponíveis no momento.',
  noMenuContent2:
    'Autorize o acesso para personalizar o layout do seu menu e agilizar a navegação em seu CRM.',
  noLegalAgreementsContent1: 'As configurações de acordos legais não estão disponíveis no momento. ',
  noLegalAgreementsContent2:
    'Autorize o acesso para gerenciar e personalizar acordos legais em seu CRM.',
  noCrmContent1: 'Nenhum produto ou plano CRM ativo.',
  noCrmContent2:
    'Compre ou autorize um plano para desbloquear acesso completo e personalizar suas configurações de CRM.',
  noUserListContent1: 'Nenhum produto ou plano de Lista de Usuários ativo.',
  noUserListContent2:
    'Compre ou autorize um plano para desbloquear acesso completo e personalizar suas configurações de Lista de Usuários.',
  noPermissionsContent1: 'Nenhum produto ou plano de Permissões ativo.',
  noPermissionsContent2:
    'Compre ou autorize um plano para desbloquear acesso completo e personalizar suas configurações de Permissões.',
  lead: 'Lead',
  crm: 'CRM',
  menu: 'Menu',
  customer: 'Cliente',
  estimate: 'Orçamento',
  proposal: 'Proposta',
  invoice: 'Fatura',
  project: 'Projeto',
  task: 'Tarefa',
  ticket: 'Ticket',
  fileManagement: 'Gerenciamento de arquivos',
  calendar: 'Calendário',
  notification: 'Notificação',
  leadPlus: 'Lead+',
  marketing: 'Marketing',
  window: 'Window',
  community: 'Community',
  integrations: 'Integrações',
  userList: 'Lista de usuários',
  permissions: 'Permissões',
  userType: 'Tipo de usuário',
  companyName: 'Nome da empresa',
  allowableFileFormats: 'Formatos de arquivo permitidos',

  // currentPlan
  yourCurrentPlan: 'Seu plano atual é ',
  yourCurrentPlanDesc: 'Um início simples para todos',
  activeUntil: 'Ativo até',
  activeUntilDesc: 'Enviaremos uma notificação quando a assinatura expirar',
  paymentDesc: 'O pagamento será cobrado em sua conta',
  paymentForTrialDesc: 'Você precisa adquirir uma assinatura para continuar usando',
  perMonth: 'Por mês',
  prtYear: 'Por ano',
  perMonthDesc: 'Você pode cancelar sua assinatura a qualquer momento',
  upgradePlan: 'Atualizar plano',
  attentionTitle: 'Precisamos da sua atenção!',
  attentionDesc: 'Seu plano requer atualizações',
  days: 'Dias',
  of: 'de',
  remainingDesc: 'dias restantes até que seu plano requeira atualização',
  usageOfStorage: 'Uso de armazenamento',
  users: 'Usuários',

  //legalAgreements
  legalAgreements: 'Acordos legais',
  createLegalAgreement: 'Criar acordo legal',
  updateLegalAgreement: 'Atualizar acordo legal',
  deleteLegalAgreement: 'Excluir acordo legal',
  createLegalAgreementSuccess: 'Acordo legal criado com sucesso',
  updateLegalAgreementSuccess: 'Acordo legal atualizado com sucesso',
  deleteLegalAgreementSuccess: 'Acordo legal excluído com sucesso',
  deleteLegalAgreementDesc: 'Tem certeza de que deseja excluir este acordo legal?',

  // cards
  deleteCard: 'Excluir cartão',
  addCard: 'Adicionar novo cartão',
  createCardSuccess: 'Cartão criado com sucesso',
  addCardSuccess: 'Cartão adicionado com sucesso',
  deleteCardSuccess: 'Cartão excluído com sucesso',
  deleteCardDesc: 'Tem certeza de que deseja excluir este cartão?',
  requiredCard: 'O número do cartão é obrigatório',
  requiredCardHolder: 'O nome do titular do cartão é obrigatório',
  requiredExpireMonth: 'O mês de expiração é obrigatório',
  requiredExpireYear: 'O ano de expiração é obrigatório',
  requiredCvc: 'O CVC é obrigatório',
};

export default locale; 
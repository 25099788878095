const locale = {
  title: "Dipartimenti",
  delete: "Elimina",
  deleteConfirm: "Sei sicuro di voler eliminare questo dipartimento? Questa azione non può essere annullata.",
  deletedSuccessfully: "Dipartimento eliminato con successo",
  create: "Crea Dipartimento",
  departmentNameLabel: "Nome del Dipartimento",
  rolesLabel: "Ruoli",
  addRole: "Aggiungi Ruolo",
  createDepartmentButton: "Crea Dipartimento",
  createSuccess: "Dipartimento creato con successo",
  columnId: "#",
  columnName: "Nome",
  columnRoles: "Ruoli",
  columnActions: "Azioni",
  headerTitle: "Dipartimenti",
  createDepartment: "Crea Dipartimento",
  rolesButton: "Ruoli",
  emptyState1: "Nessun dipartimento trovato",
  emptyState2: "Per favore, crea un nuovo dipartimento",
  addNewRole: "Aggiungi Nuovo Ruolo",
  updateDepartmentTitle: "Aggiorna Dipartimento",
  updateDepartmentButton: "Aggiorna",
  updateSuccess: "Dipartimento aggiornato con successo",
};

export default locale; 
import { commonRoutes } from "routes/route.common";

export const expenseDomainRoutes = {
  base: 'expense',
  subdomains: {
    expense: {
      base: 'expense',
    },
  },
};

export const baseExpenseRoute = (): string => `/${expenseDomainRoutes.base}/`;
export const listExpenseRoute = (): string =>
  `/${expenseDomainRoutes.base}/${expenseDomainRoutes.subdomains.expense.base}`;
export const importExpenseRoute = (): string =>
  `/${expenseDomainRoutes.base}/${expenseDomainRoutes.subdomains.expense.base}/${commonRoutes.import}`;

const locale = {
  somethingWentWrong: 'Algo deu errado!',
  loginSuccess: 'Login realizado com sucesso',
  signinText: 'Faça login.',
  emailAddress: 'Endereço de e-mail',
  password: 'Senha',
  forgotPassword: 'Esqueceu a senha?',
  login: 'Entrar',
  continueWith: 'ou continue com',
  noAccount: 'Não tem uma conta?',
  signUp: 'Cadastre-se',
  logoAlt: 'logo',
  growYourBusiness: 'Tudo que você precisa para fazer crescer seu',
  business: 'Negócio',
  bgPattern: 'padrão de fundo',
  manageYourBusiness:
    'Gerencie seu negócio de forma eficiente, aumente suas vendas, alcance clientes potenciais e fortaleça-se ao se juntar à comunidade.',

  quote1: 'Seus clientes mais insatisfeitos são sua maior fonte de aprendizado.',
  quoteName1: 'Bill Gates',
  quoteCompany1: 'Microsoft',
  quoteImage1: 'Bill-Gates',

  quote2: 'O atendimento ao cliente não deve ser um departamento. Deve ser a empresa inteira.',
  quoteName2: 'Tony Hsieh',
  quoteCompany2: 'Zappos',
  quoteImage2: 'Tony-Hsieh',

  quote3: 'A única razão pela qual estamos no negócio é para tornar a vida menos difícil para nossos clientes.',
  quoteName3: 'Matthew Odgers',
  quoteCompany3: 'Odgers Law Group',
  quoteImage3: 'Matthew-Odgers',

  quote4: 'Se você não cuida do seu cliente, seu concorrente o fará.',
  quoteName4: 'Bob Hooey',
  quoteCompany4: 'Ideaman',
  quoteImage4: 'Bob-Hooey',

  quote5: 'Em vendas, uma indicação é a chave para a porta da resistência.',
  quoteName5: 'Bo Bennett',
  quoteCompany5: 'Archieboy',
  quoteImage5: 'Bo-Bennett',

  quote6: 'Sempre faça o seu melhor. O que você planta agora, colherá mais tarde.',
  quoteName6: 'Og Mandino',
  quoteCompany6: 'Author',
  quoteImage6: 'Og-Mandino',

  quote7: 'Um dos maiores presentes que você pode dar a alguém é o presente da sua atenção.',
  quoteName7: 'Jim Rohn',
  quoteCompany7: 'Author',
  quoteImage7: 'Jim-Rohn',

  quote8: 'Faça o que você faz tão bem que eles vão querer ver novamente e trazer seus amigos.',
  quoteName8: 'Walt Disney',
  quoteCompany8: 'Disney',
  quoteImage8: 'Walt-Disney',

  passwordResetSuccess: 'Sua senha foi redefinida com sucesso!',
  resetPasswordTitle: 'Redefinir Senha',
  enterNewPassword: 'Por favor, digite sua nova senha.',
  passwordAgain: 'Confirmar Senha',
  resetPassword: 'Redefinir Senha',
  alreadyHaveAccount: 'Já tem uma conta?',
  resetPasswordRequestSuccess: 'Solicitação de redefinição de senha enviada com sucesso',
  forgotPasswordTitle: 'Esqueceu a Senha',
  resetPasswordInstructions: 'Você pode redefinir sua senha digitando seu endereço de e-mail.',
  rememberPassword: 'Lembra sua senha?',
  userConfirmedSuccess: 'Usuário confirmado com sucesso!',
  emailConfirmationTitle: 'Confirmação de E-mail',
  TwoFAConfirmationTitle: 'Confirmação 2FA',
  enterOtp: 'Por favor, digite o código OTP enviado para seu e-mail.',
  submit: 'Verificar',
  didNotReceiveCode: 'Não recebeu o código?',
  resendCode: 'Reenviar código',
  resendCodeTitle: 'Reenviar Código de Confirmação',
  enterEmailToResend: 'Por favor, digite seu endereço de e-mail para receber o código de confirmação.',
  email: 'E-mail',
  close: 'Fechar',
  send: 'Enviar',
  signupSuccess: 'Cadastro realizado com sucesso',
  letsRegister: 'Vamos fazer seu cadastro.',
  name: 'Nome',
  surname: 'Sobrenome',
  iAccept: 'Eu aceito os',
  and: 'e',
  with: 'e',
  termsAndConditions: 'Termos e Condições',
  privacyPolicy: 'Política de Privacidade',
  explicitConsent: 'Política de Consentimento Explícito',
  kvkkPolicy: 'Política de Informação KVKK',
  orContinueWith: 'ou continue com',
  createPassword: 'Criar Senha',
  createPasswordTitle: 'Criar Senha',
  signupSuccessfullTitle: 'Cadastro realizado com sucesso!',
  signupSuccessfullDescription: 'Por favor, verifique seu e-mail para confirmar sua conta.',
  backToLogin: 'Voltar para Login',
  resendConfirmationSuccessTitle: 'Código de confirmação reenviado com sucesso',
  resendConfirmationSuccessText: 'Por favor, verifique seu e-mail para confirmar sua conta.',
  resentCodeSuccess: 'Código reenviado com sucesso',
  acceptAllPolicies: 'Por favor, aceite todas as políticas!',
  alreadyRegisteredError: 'Você já está registrado! Por favor, faça login.',
  userNotFoundError: 'Usuário não encontrado!',

  proposal: 'Proposta',
  estimate: 'Orçamento',
  invoice: 'Fatura',
  startDate: 'Data de início',
  expireDate: 'Data de expiração',
  deadline: 'Prazo',
  proposalInformation: 'Informações da proposta',
  estimateInformation: 'Informações do orçamento',
  invoiceInformation: 'Informações da fatura',
  billTo: 'Faturar para',
  bankName: 'Nome do banco',
  country: 'País',
  iban: 'IBAN',
  currency: 'Moeda',
  productDescription: 'Descrição do produto',
  qty: 'QTDE',
  rate: 'Taxa',
  tax: 'Imposto',
  amount: 'Valor',
  projectTimeline: 'Cronograma do projeto',
  timeline: 'Cronograma',
  accept: 'Aceitar',
  decline: 'Recusar',
  download: 'Baixar',
  comments: 'Comentários',
  documents: 'Documentos',
  uploadDocument: 'Enviar documento',
  addDocument: 'Adicionar documento',

  estimateAccepted: 'Orçamento aceito',
  estimateDeclined: 'Orçamento recusado',
  proposalAccepted: 'Proposta aceita',
  proposalDeclined: 'Proposta recusada',
  invoiceAccepted: 'Fatura aceita',
  invoiceDeclined: 'Fatura recusada',
  declineReason: 'Motivo da recusa',
  declineReasonPlaceholder: 'Por favor, digite o motivo da recusa'
};

export default locale;

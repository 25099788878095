import React, { useEffect, useState } from 'react';

interface MemoryInfo {
  usedJSHeapSize: number;
  totalJSHeapSize: number;
  jsHeapSizeLimit: number;
}

interface PerformanceWithMemory extends Performance {
  memory?: MemoryInfo;
}

const MemoryUsage = (props: { setTotalMemory: React.Dispatch<React.SetStateAction<number>> }) => {
  const [memory, setMemory] = useState<number | null>(null);
  const [totalMemory, setTotalMemory] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if ((performance as PerformanceWithMemory).memory) {
        const currentMemory =
          ((performance as PerformanceWithMemory).memory as MemoryInfo).usedJSHeapSize /
          1024 /
          1024; // convert to MB
        setMemory(currentMemory);
        setTotalMemory((prev) => (currentMemory > prev ? currentMemory : prev));
        props.setTotalMemory((prev) => (currentMemory > prev ? currentMemory : prev));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return false ? (
    <div>
      <h3>Memory Usage</h3>
      <p>Current: {memory ? `${memory?.toFixed(2)} MB` : 'Veri yok'}</p>
      <p>Total Accumulated: {totalMemory ? `${totalMemory.toFixed(2)} MB` : 'Veri yok'}</p>
    </div>
  ) : null;
};

export default MemoryUsage;

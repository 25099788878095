/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button, Menu, MenuItem, Typography } from '@mui/material';
import { RefreshRounded } from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppointmentStatus,
  IAppointmentDetailResult,
  PermissionSubject,
} from 'corede-common-cocrm';
import React from 'react';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import {
  useAppointmentUpdateMutation,
  useAppointmentUpdateStatusMutation,
} from '../../context/appointment.api';
import { enqueueSnackbar } from 'notistack';
import { IBaseError, PermissionAction } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export interface IAppointmentDetailDrawerLeftPanelActionsComponentProps {
  appointmentDetailData: IAppointmentDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * contains actions: add appointment, convert appointment to customer
 */
export const AppointmentDetailDrawerLeftPanelActionsComponent = (
  props: IAppointmentDetailDrawerLeftPanelActionsComponentProps,
) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const [
    appointmentUpdateStatus,
    {
      data: appointmentUpdateStatusData,
      isLoading: appointmentUpdateStatusLoading,
      error: appointmentUpdateStatusError,
    },
  ] = useAppointmentUpdateStatusMutation();

  useEffect(() => {
    if (appointmentUpdateStatusData) {
      enqueueSnackbar(t('crm.calendar.appointment.statusChangeSuccess'), {
        variant: 'success',
      });
    }
  }, [appointmentUpdateStatusData]);

  useEffect(() => {
    if (appointmentUpdateStatusError) {
      DefaultErrorHandlerUseEffect(appointmentUpdateStatusError as IBaseError, currentLanguage);
    }
  }, [appointmentUpdateStatusError]);

  const handleChangeStatus = async (status: AppointmentStatus) => {
    try {
      await appointmentUpdateStatus({
        filter: {
          _id: props.appointmentDetailData?._id ?? '',
        },
        input: {
          status: status,
        },
      });
    } catch (error) {
      DefaultErrorHandlerUseEffect(error as IBaseError, currentLanguage);
    }
  };

  const StatusMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <PermissionWrapper
          check={{
            subject: PermissionSubject.appointment,
            action: PermissionAction.update,
          }}
        >
          <Button
            id="appointment-detail-button"
            aria-controls={open ? 'appointment-detail-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            color="primary"
            variant="contained"
            disabled={appointmentUpdateStatusLoading}
            sx={{
              height: 40,
              fontSize: '13px',
              borderRadius: 100,
            }}
          >
            <RefreshRounded sx={{ mr: 1 }} />
            {t('crm.calendar.appointment.changeStatus')}
          </Button>
          <Menu
            id="appointment-detail-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'appointment-detail-button',
            }}
            sx={{
              '& .MuiPaper-root': {
                bgcolor: 'background.secondWithBlur',
                backdropFilter: 'blur(3px)',
                borderRadius: 2,
              },
            }}
          >
            {Object.entries(AppointmentStatus)?.map(([key, value]) => (
              <MenuItem key={key} onClick={() => handleChangeStatus(value)}>
                <Typography fontSize={'small'} fontWeight={'bold'}>
                  {getTranslatedEnumValue(value)}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </PermissionWrapper>
      </div>
    );
  };

  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      {/* Appointment base actions */}
      <StatusMenu />
    </Stack>
  );
};

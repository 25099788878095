const locale = {
  demo: "Demo",
  title: "Was ist das <highlight>CoCRM</highlight> Fenster?",
  description:
    "Das CoCRM-Fenster ist ein Geschäftsentwicklungsbereich, der exklusiv für CoCRM-Benutzerunternehmen entwickelt wurde. Hier können sie Transfers direkt an Mitglieder innerhalb des CoCRM-Ökosystems bezüglich ihrer Produkte, Dienstleistungen oder Kampagnen bereitstellen und Geschäftsmöglichkeiten nutzen, indem sie spezielle Rabatte für Einzelpersonen und Institutionen definieren, die unsere Website besuchen.",
  featuresTitle: "Mit dem CoCRM-Fenster;",
  features1:
    "Können Sie Besuchern unserer Website mit über 1 Million monatlichen Zugriffen direkt Angebote machen,",
  features2:
    "Können Sie verschiedene Kampagnenankündigungen an Unternehmen in unserem Ökosystem machen,",
  features3:
    "Können Sie detaillierte Informationen über Ihre Produkte und Dienstleistungen bereitstellen, indem Sie an den Blog-Kampagnen teilnehmen, die wir durchführen werden,",
  features4:
    "Können Sie einen zusätzlichen Beitrag von 36,2% zu Ihrer Markenbekanntheit leisten!",
  buttonTitle: "Angebot anfordern!",
  formDescription:
    "Füllen Sie das Formular aus und wir werden uns so schnell wie möglich bei Ihnen melden.",
  sendSuccess: "Vielen Dank! Wir werden uns so schnell wie möglich bei Ihnen melden.",
  formTitle: "Angebot anfordern",
  formButtonTitle: "Absenden",
  fullName: "Vollständiger Name",
  email: "E-Mail",
  phone: "Telefon",
  orgName: "Organisationsname",
};

export default locale; 
const locale = {
  title: 'Dashboard',
  description:
    'Active su suscripción Cambie a un plan de suscripción adecuado para utilizar todas las funciones del sistema CRM y hacer crecer su negocio',
  featuresTitle: 'Con suscripción:',
  features1: 'Acceso ilimitado a todos sus datos',
  features2: 'Herramientas de informes personalizados',
  features3: 'Más almacenamiento',
  features4: 'Soporte técnico 24/7',
  buttonTitle: 'Explorar planes de suscripción',
  trialExpired: 'El plan de prueba ha expirado. Por favor, elija otro plan.',
  mainFailed: 'Su plan no está activado. Por favor, controle su información de facturación.',
  mainCompleted: 'Su plan ha finalizado. Por favor, elija otro plan.',
  welcome: 'Bienvenido ',
  platformValue: 'Valor de la plataforma',
  dealsAmount: 'Cantidad de acuerdos',
  deals: 'Acuerdos',
  byRefererCategory: 'por categoría de referencia',
  revenue: 'Ingresos',
  leads: 'Leads',
  wl: 'G/P',
  winLoss: 'Ganancia/Pérdida',
  averageMonthly: 'Promedio mensual',
  monthly: 'Mensual',
  bestDeal: 'Mejor acuerdo',
  sales: 'Ventas',
  topSales: 'Mejores ventas',
  salesStreak: 'Racha de ventas',
  topReview: 'Mejor reseña',
  workWithPlatforms: 'Trabajo con plataformas',
  salesDynamics: 'Dinámica de ventas',
  timeframe: 'Período de tiempo',
  bestDetal: 'Mejor acuerdo',
  winRate: 'Tasa de éxito',
  prevMonth: 'mes anterior',
  vsPrevMonth: 'vs mes anterior',
  details: 'Detalles',
  filters: 'Filtros',
  statistics: 'Estadísticas',

  //LEAD
  totalLead: 'Total de leads',
  totalLeadSubtitle: 'Total de leads con datos históricos diarios',
  today: 'Hoy',
  yesterday: 'Ayer',
  leadConversion: 'Conversión de leads',
  leadConversionSubtitle: 'Conversión de leads con datos históricos diarios',
  weeklyReport: 'Informe semanal',

  //CUSTOMER
  totalCustomer: 'Total de clientes',
  totalCustomerSubtitle: 'Total de clientes con datos históricos diarios',
  customerFilterType: 'Filtro de clientes por tipo',
  customerFilterCategory: 'Filtro de clientes por categoría',

  //ESTIMATE
  totalEstimate: 'Total de presupuestos',
  totalEstimateSubtitle: 'Total de presupuestos con datos históricos diarios',
  estimateTotalPrice: 'Precio total estimado',
  estimateTotalPriceSubtitle: 'Precio total estimado con datos históricos diarios',
  estimatesByStatus: 'Presupuestos por estado',
  estimatesByTypes: 'Presupuestos por tipo',
  estimatesByTarget: 'Presupuestos por objetivo',

  //PROPOSAL
  totalProposal: 'Total de propuestas',
  totalProposalSubtitle: 'Total de propuestas con datos históricos diarios',
  proposalTotalPrice: 'Precio total de propuestas',
  proposalTotalPriceSubtitle: 'Precio total de propuestas con datos históricos diarios',
  proposalsByStatus: 'Propuestas por estado',
  proposalsByTypes: 'Propuestas por tipo',
  proposalsByTarget: 'Propuestas por objetivo',

  //TASK
  totalTask: 'Total de tareas',
  totalTaskSubtitle: 'Total de tareas con datos históricos diarios',
  taskFilterPriority: 'Filtro de tareas por prioridad',
  taskFilterStatus: 'Filtro de tareas por estado',
};

export default locale; 
const locale = {
  tasks: "Aufgaben",
  task: "Aufgabe",
  taskConversions: "Aufgabenkonvertierungen",
  title: "Aufgaben",
  addTask: "Aufgabe hinzufügen",
  emptyState1: "Noch keine Aufgaben zugewiesen.",
  emptyState2: "Erstellen Sie hier Aufgaben, um Ihre Arbeit zu organisieren, Prioritäten zu setzen und Ihre CRM-Aktivitäten im Auge zu behalten.",
  create: "Aufgabe erstellen",
  subject: "Betreff",
  description: "Beschreibung",
  startDate: "Startdatum",
  dueDate: "Fälligkeitsdatum",
  assigneeIds: "Zugewiesene Personen",
  followerIds: "Follower",
  checklist: "Checkliste",
  department: "Abteilung",
  priority: "Priorität",
  tags: "Tags",
  hourlyRate: "Stundensatz",
  hourlyRateCurrency: "Währung des Stundensatzes",
  isBillable: "Abrechenbar",
  isRecurrent: "Wiederkehrend",
  repeatDays: "Wiederholungstage",
  totalCycle: "Gesamtzyklus",
  addChecklistItem: "Checklistenelement hinzufügen",
  noAssigneeToCreateChecklist: "Weisen Sie Benutzer zu, um Checklisten zu erstellen",
  assignee: "Zugewiesene Person",
  createTaskSuccess: "Aufgabe erfolgreich erstellt.",
  deleteTask: "Aufgabe löschen",
  deleteTaskContent: "Sind Sie sicher, dass Sie diese Aufgabe löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  deletedSuccessfully: "Aufgabe erfolgreich gelöscht",
  updateTask: "Aufgabe aktualisieren",
  updateSuccess: "Aufgabe erfolgreich aktualisiert.",

  detail: "Aufgabendetails",
  subtask: "Unteraufgabe",

  addSubtask: "Unteraufgabe hinzufügen",
  updateStatus: "Status aktualisieren",

  createdBy: "Aufgabe erstellt von ",

  // detailDrawer
  detailedInfo: "Detaillierte Informationen",
  recurrentInfo: "Informationen zur Wiederholung",
  billableInfo: "Abrechnungsinformationen",
  documents: "Dokumente",
  editAssignees: "Zugewiesene Personen bearbeiten",
};

export default locale; 
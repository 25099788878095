import settingsLocale from "../domains/settings/locales/de";

const locale = {
  settings: {
    ...settingsLocale,
  },
  demoo: "Angebotsanfrage",
  settingsLabel: "Einstellungen",
  paymentSettings: "Zahlung",
  menuSettings: "Menüeinstellungen",
  crmSettings: "CRM",
  leadPlusSettings: "Lead Plus",
  marketingSettings: "Marketing",
  windowSettings: "Fenster",
  communitySettings: "Gemeinschaft",
  notificationSettings: "Benachrichtigung",
  organizationSettings: "Organisation",
  myOrganization: "Meine Organisation",
  generalSettings: "Allgemein",
  moduleSettings: "Moduleinstellungen",
  userSettings: "Benutzereinstellungen",
  integrations: "Integrationen",
  legalAgreements: "Rechtliche Vereinbarungen",
};

export default locale; 
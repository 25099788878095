import crmLocale from '../../apps/crm/locales/fr';
import authLocale from '../../apps/auth/locales/fr';
import leadPlusLocale from '../../apps/leadPlus/locales/fr';
import communityLocale from '../../apps/community/locales/fr';
import marketingLocale from '../../apps/marketing/locales/fr';
import windowLocale from '../../apps/window/locales/fr';
import settingsLocale from '../../apps/settings/locales/fr';
import accountLocale from '../../apps/account/locales/fr';
import webNotificationLocale from '../../apps/webNotification/locales/fr';
import diskLocale from '../../apps/disk/locales/fr';

const locale = {
  crm: {
    ...crmLocale,
  },
  auth: {
    ...authLocale,
  },
  leadPlus: {
    ...leadPlusLocale,
  },
  community: {
    ...communityLocale,
  },
  marketing: {
    ...marketingLocale,
  },
  window: {
    ...windowLocale,
  },
  settings: {
    ...settingsLocale,
  },
  account: {
    ...accountLocale,
  },
  webNotification: {
    ...webNotificationLocale,
  },
  disk: {
    ...diskLocale,
  },
  enums: {
    '-': '-',
    undefined: '-',
    null: '-',
    active: 'Actif',
    pending: 'En attente',
    passive: 'Passif',
    neutral: 'Neutre',
    cold: 'Froid',
    warm: 'Tiède',
    hot: 'Chaud',
    customer: 'Client',
    unqualified: 'Non qualifié',
    IQL: 'IQL (Information)',
    MQL: 'MQL (Marketing)',
    SQL: 'SQL (Ventes)',
    en: 'Anglais',
    fr: 'Français',
    de: 'Allemand',
    it: 'Italien',
    pt: 'Portugais',
    ru: 'Russe',
    es: 'Espagnol',
    tr: 'Turc',
    individual: 'Individuel',
    business: 'Entreprise',
    government: 'Gouvernement',
    b2b: 'B2B',
    b2c: 'B2C',
    TRY: 'TRY',
    EUR: 'EUR',
    USD: 'USD',
    IRR: 'IRR',
    GBP: 'GBP',
    NOK: 'NOK',
    RUB: 'RUB',
    CHF: 'CHF',
    flat: 'Forfait',
    hourly: 'Horaire',
    daily: 'Quotidien',
    monthly: 'Mensuel',
    NDA: 'NDA',
    termsAndConditions: 'Conditions générales',
    lead: 'Lead',
    unknown: 'Inconnu',
    everyday: 'Tous les jours',
    everyWeek: 'Chaque semaine',
    annual: 'Annuel',
    email: 'Email',
    sms: 'SMS',
    push: 'Push',
    web: 'Web',
    male: 'Masculin',
    female: 'Féminin',
    other: 'Autre',
    All: 'Tous',
    Unread: 'Non lu',
    automatic: 'Automatique',
    manual: 'Manuel',
    high: 'Élevé',
    low: 'Faible',
    medium: 'Moyen',
    urgent: 'Urgent',
    draft: 'Brouillon',
    sent: 'Envoyé',
    opened: 'Ouvert',
    revised: 'Révisé',
    declined: 'Refusé',
    accepted: 'Accepté',
    product: 'Produit',
    service: 'Service',
    iyzico: 'Iyzico',
    stripe: 'Stripe',
    posbasit: 'POS Basit',
    paytr: 'PayTR',
    moneyTransfer: 'Virement bancaire',
    defective: 'Défectueux',
    incorrectItem: 'Article incorrect',
    changeOfMind: 'Changement d\'avis',
    throughTasks: 'Par tâches',
    fixedRate: 'Taux fixe',
    projectHours: 'Heures de projet',
    taskHours: 'Heures de tâche',
    onHold: 'En attente',
    nearToExpire: 'Proche de l\'expiration',
    trash: 'Corbeille',
    A: 'A',
    B: 'B',
    C: 'C',
    yes: 'Oui',
    no: 'Non',
    default: 'Par défaut',
    meeting: 'Réunion',
    call: 'Appel',
    event: 'Événement',
    postponed: 'Reporté',
    canceled: 'Annulé',
    TWENTY_FOUR_HOURS: '24 heures',
    TWELVE_HOURS: '12 heures',
    '24-hours': '24 heures',
    '12-hours': '12 heures',
    requested: 'Demandé',
    inspected: 'Inspecté',
    approved: 'Approuvé',
    disapproved: 'Désapprouvé',
    resolved: 'Résolu',
    refund: 'Remboursement',
    replacement: 'Remplacement',
    repair: 'Réparation',
    exchange: 'Échange',

    // UIElementType
    fieldInput: 'Champ de saisie',
    header: 'En-tête',
    paragraph: 'Paragraphe',

    // LeadFieldEnum
    fullName: 'Nom complet',
    position: 'Poste',
    website: 'Site web',
    phoneNumber: 'Numéro de téléphone',
    company: 'Entreprise',
    sector: 'Secteur',
    country: 'Pays',
    state: 'État',
    city: 'Ville',
    documents: 'Documents',

    // reminder
    schedule: 'Planifier',
    complete: 'Terminer',
    cancel: 'Annuler',
    scheduled: 'Planifié',
    unscheduled: 'Non planifié',
    expired: 'Expiré',
    cancelled: 'Annulé',

    // subscription status
    ongoing: 'ACTIF',
    ongoingWithFailure: 'ACTIF',
    ongoingWithCancelled: 'ACTIF',
    failed: 'ÉCHOUÉ',

    //task status
    notStarted: 'Non commencé',
    inProgress: 'En cours',
    tested: 'Testé',
    awaitingFeedback: 'En attente de retour',
    completed: 'Terminé',

    // target type
    Lead: 'Lead',
    Customer: 'Client',
    Estimate: 'Devis',
    Proposal: 'Proposition',
    Invoice: 'Facture',
    Task: 'Tâche',
    User: 'Utilisateur',
    Organization: 'Organisation',
    Project: 'Projet',
    None: 'Aucun',
    Ticket: 'Ticket',
    Expense: 'Dépense',
    Contract: 'Contrat',

    //ticket categories
    incidentTicket: 'Ticket d\'incident',
    requestForInformation: 'Demande d\'information',
    serviceRequest: 'Demande de service',
    problemTicket: 'Ticket de problème',
    changeRequest: 'Demande de changement',
    featureRequest: 'Demande de fonctionnalité',
    feedback: 'Retour',
    complaint: 'Plainte',
    maintenanceAndOutageReport: 'Rapport de maintenance et de panne',
    billingAndPaymentIssue: 'Problème de facturation et de paiement',
    internalTicket: 'Ticket interne',
    open: 'Ouvert',
    answered: 'Répondu',
    closed: 'Fermé',

    //permissions
    auth: 'Authentification',
    comment: 'Commentaire',
    dms: 'Document',
    file: 'Document',
    note: 'Note',
    notification: 'Notification',
    payment: 'Paiement',
    presale: 'Prévente',
    reminder: 'Rappel',
    sale: 'Vente',
    task: 'Tâche',
    user: 'Utilisateur',
    permission: 'Permission',
    role: 'Rôle',
    leadSource: 'Source de lead',
    leadStatus: 'Statut de lead',
    leadTag: 'Tag de lead',
    productCategory: 'Catégorie de produit',
    webNotification: 'Notification web',
    notificationHistory: 'Historique des notifications',
    order: 'Commande',
    purchase: 'Achat',
    subscription: 'Abonnement',
    proposal: 'Proposition',
    estimate: 'Devis',
    customerCategory: 'Catégorie de client',
    customerTag: 'Tag de client',
    invoice: 'Facture',
    invoicePayment: 'Paiement',
    invoiceReturn: 'Retour',
    organization: 'Organisation',
    organizationSettings: 'Paramètres de l\'organisation',
    organizationChart: 'Organigramme',
    department: 'Département',
    userRole: 'Rôle d\'utilisateur',
    userPermission: 'Permission d\'utilisateur',
    manage: 'Gérer',
    view: 'Voir',
    export: 'Exporter',
    import: 'Importer',
    calendar: 'Calendrier',
    appointment: 'Rendez-vous',
    contract: 'Contrat',
    contractTemplate: 'Modèle de contrat',
    expense: 'Dépense',
    project: 'Projet',
    ticket: 'Ticket',
    dashboard: 'Tableau de bord',

    // file extension
    jpg: 'jpg',
    png: 'png',
    pdf: 'pdf',
    doc: 'doc',
    docx: 'docx',
    xls: 'xls',
    xlsx: 'xlsx',
    ppt: 'ppt',
    pptx: 'pptx',
    txt: 'txt',
    mp4: 'mp4',
    zip: 'zip',

    // payment status
    unPaid: 'Non payé',
    partiallyPaid: 'Partiellement payé',
    totallyPaid: 'Totalement payé',
    
  },
  organization: 'Organisation',
  department: 'Département',
  user: 'Utilisateur',
  aboutUs: 'À propos de nous',
  termsAndConditions: 'Conditions générales',
  uploadDocuments: 'Télécharger des documents',
  privacyPolicy: 'Politique de confidentialité',
  cookiesPolicy: 'Politique de cookies',
  resources: 'Ressources',
  allRightsReserved: 'Tous droits réservés',
  rowsPerPage: 'Lignes par page',
  chartUpdateSuccess: 'Graphique mis à jour avec succès !',
  imageUploadSuccess: 'Image téléchargée avec succès !',
  uploadPicture: 'Télécharger une image',
  expiresIn: 'Expire dans ',
  expiresDays: ' jours',
  subscriptionExpired: 'Votre plan a expiré !',
  noOptionsText: 'Pas d\'options',
  noCategory: 'Pas de catégorie',

  ////////////////////////////////////////////////////////////////
  // Validations
  ////////////////////////////////////////////////////////////////

  isRequired: 'Ce champ est obligatoire',
  minValue0: 'La valeur minimale est 0',
  fullNameIsRequired: 'Le nom complet est obligatoire',
  emailIsRequired: 'L\'email est obligatoire',
  emailIsInvalid: 'L\'email est invalide',
  phoneIsInvalid: 'Le téléphone est invalide',
  nameIsRequired: 'Le nom est obligatoire',
  titleIsRequired: 'Le titre est obligatoire',
  profileImageIsRequired: 'L\'image de profil est obligatoire',
  descriptionIsRequired: 'La description est obligatoire',
  subjectIsRequired: 'Le sujet est obligatoire',
  phoneNumberIsRequired: 'Le numéro de téléphone est obligatoire',
  quotaYupIsRequired: 'Le quota est obligatoire',
  dateYupIsRequired: 'La date est obligatoire',
  statusYupIsRequired: 'Le statut est obligatoire',
  locationYupIsRequired: 'L\'emplacement est obligatoire',
  detailsIsRequired: 'Les détails sont obligatoires',
  surnameIsRequired: 'Le nom de famille est obligatoire',
  birthDateIsRequired: 'La date de naissance est obligatoire',
  genderIsRequired: 'Le genre est obligatoire',
  countryIsRequired: 'Le pays est obligatoire',
  cityIsRequired: 'La ville est obligatoire',
  addressIsRequired: 'L\'adresse est obligatoire',
  contentIsRequired: 'Le contenu est obligatoire',
  readingTimeIsRequired: 'Le temps de lecture est obligatoire',
  tagsIsRequired: 'Les tags sont obligatoires',
  referencesIsRequired: 'Les références sont obligatoires',
  relatedVideoIsRequired: 'La vidéo associée est obligatoire',
  authorIsRequired: 'L\'auteur est obligatoire',
  categoryIsRequired: 'La catégorie est obligatoire',
  targetCategoryIsRequired: 'La catégorie cible est obligatoire',
  languageIsRequired: 'La langue est obligatoire',
  domainIsRequired: 'Le domaine est obligatoire',
  targetIsRequired: 'La cible est obligatoire',
  questionIsRequired: 'La question est obligatoire',
  answerIsRequired: 'La réponse est obligatoire',
  titleIsTooShort25: 'Le titre est trop court, la longueur minimale est de 25',
  titleIsTooLong50: 'Le titre est trop long, la longueur maximale est de 50',
  contentIsTooShort50: 'Le contenu est trop court, la longueur minimale est de 50',
  contentIsTooLong250: 'Le contenu est trop long, la longueur maximale est de 250',
  descriptionIsTooShort400: 'La description est trop courte, la longueur minimale est de 400',
  descriptionIsTooLong4000: 'La description est trop longue, la longueur maximale est de 4000',
  quotaMustBePositive: 'Le quota doit être positif',
  quotaMustBeMultipleOf10: 'Le quota doit être un multiple de 10',
  participantsIsRequired: 'Les participants sont obligatoires',
  startDateIsRequired: 'La date de début est obligatoire',
  durationIsRequired: 'La durée est obligatoire',
  quotaIsRequired: 'Le quota est obligatoire',
  lastApplicationDateIsRequired: 'La date de dernière candidature est obligatoire',
  statusIsRequired: 'Le statut est obligatoire',
  locationIsRequired: 'L\'emplacement est obligatoire',
  typeIsRequired: 'Le type est obligatoire',
  participationLinkIsRequired: 'Le lien de participation est obligatoire',
  durationMustBeMultipleOf5: 'La durée doit être un multiple de 5',
  durationMustBePositive: 'La durée doit être positive',
  nameIsTooShort2: 'Le nom est trop court, la longueur minimale est de 2',
  nameIsTooLong50: 'Le nom est trop long, la longueur maximale est de 50',
  surnameIsTooShort2: 'Le nom de famille est trop court, la longueur minimale est de 2',
  surnameIsTooLong50: 'Le nom de famille est trop long, la longueur maximale est de 50',
  stateIsRequired: 'L\'état est obligatoire',
  positionIsRequired: 'Le poste est obligatoire',
  companyIsRequired: 'L\'entreprise est obligatoire',
  sectorIsRequired: 'Le secteur est obligatoire',
  qualificationIsRequired: 'La qualification est obligatoire',
  prospectNameIsRequired: 'Le nom du prospect est obligatoire',
  prospectPhoneNumberIsRequired: 'Le numéro de téléphone du prospect est obligatoire',
  prospectContactFirstNameIsRequired: 'Le prénom du contact du prospect est obligatoire',
  prospectContactLastNameIsRequired: 'Le nom de famille du contact du prospect est obligatoire',
  prospectContactEmailIsRequired: 'L\'email du contact du prospect est obligatoire',
  websiteIsInvalid: 'Le site web est invalide',
  identityNumberIsRequired: 'Le numéro d\'identité est obligatoire',
  taxOfficeIsRequired: 'Le bureau des impôts est obligatoire',
  streetIsRequired: 'La rue est obligatoire',
  zipIsRequired: 'Le code postal est obligatoire',
  passwordIsRequired: 'Le mot de passe est obligatoire',
  passwordIsInvalid: 'Doit contenir au moins 8 caractères, inclure des majuscules, des minuscules, des chiffres et des symboles.',
  passwordAgainIsInvalid: 'Les mots de passe doivent correspondre',
  cannotBeGreaterThanPrice: 'Ne peut pas être supérieur au prix',
  endTimeMustBeLaterThanStartTime: 'Doit être postérieur à l\'heure de début',
  mustBeNumber: 'Doit être un nombre',

  cardValidation: {
    required: 'Ce champ est obligatoire',
    invalid: 'Invalide',
    expired: 'Expiré',
  },

  ////////////////////////////////////////////////////////////////
  // Buttons
  ////////////////////////////////////////////////////////////////

  cancel: 'Annuler',
  save: 'Enregistrer',
  update: 'Mettre à jour',
  delete: 'Supprimer',
  create: 'Créer',
  add: 'Ajouter',
  close: 'Fermer',
  submit: 'Soumettre',
  addDocument: 'Ajouter un document',
  upload: 'Télécharger',
  view: 'Voir',
  edit: 'Modifier',
  remove: 'Supprimer',
  myPlan: 'Mon plan',
  expandAll: 'Tout développer',
  collapseAll: 'Tout réduire',
  show: 'Afficher',
  download: 'Télécharger',
  yes: 'Oui',
  no: 'Non',
  completed: 'Terminé',
  uploadDocument: 'Télécharger un document',
  newTask: 'Nouvelle tâche',
  loadMore: 'Charger plus',

  ////////////////////////////////////////////////////////////////
  // Tables
  ////////////////////////////////////////////////////////////////

  actions: 'Actions',
  title: 'Titre',
  content: 'Contenu',
  category: 'Catégorie',
  target: 'Cible',
  status: 'Statut',
  date: 'Date',
  location: 'Emplacement',
  details: 'Détails',
  name: 'Nom',
  surname: 'Nom de famille',
  birthDate: 'Date de naissance',
  fullName: 'Nom complet',
  email: 'Email',
  subject: 'Sujet',
  createdAt: 'Créé le',
  userType: 'Type d\'utilisateur',
  page: 'Page',
  news: 'Actualités',
  offer: 'Offre',
  products: 'Produits',
  updatedAt: 'Mis à jour le',
  readingTime: 'Temps de lecture',
  tags: 'Tags',
  references: 'Références',
  version: 'Version',
  domain: 'Domaine',
  type: 'Type',
  language: 'Langue',
  duration: 'Durée',
  startDate: 'Date de début',
  participants: 'Participants',
  quota: 'Quota',
  question: 'Question',
  answer: 'Réponse',
  lastApplicationDate: 'Date de dernière candidature',
  selectAnOption: 'Sélectionnez une option',
  showing: 'Affichage de {{start}} à {{end}} sur {{total}} entrées',

  ////////////////////////////////////////////////////////////////
  // Menus
  ////////////////////////////////////////////////////////////////
  dashboard: 'Tableau de bord',
  blog: 'Blog',
  blogs: 'Blogs',
  categories: 'Catégories',
  authors: 'Auteurs',
  faq: 'FAQ',
  faqs: 'FAQs',
  faqCategory: 'Catégories de FAQ',
  form: 'Formulaire',
  contactForm: 'Formulaires de contact',
  subscriptionForm: 'Formulaires d\'abonnement',
  blogTargetCategories: 'Catégories cibles',
  users: 'Utilisateurs',
  departments: 'Départements',
  roles: 'Rôles',
  permissions: 'Permissions',
  leadStatuses: 'Statuts de lead',
  leadSources: 'Sources de lead',
  leadForms: 'Formulaires de lead',
  customers: 'Clients',
  contacts: 'Contacts',
  customerCategories: 'Catégories de client',
  estimates: 'Devis',
  proposals: 'Propositions',
  invoices: 'Factures',
  payments: 'Paiements',
  contracts: 'Contrats',
  returns: 'Retours',
  services: 'Services',
  reminders: 'Rappels',
  appointments: 'Rendez-vous',
  starred: 'Favoris',
  recent: 'Récents',
  actionMenu: 'Menu d\'action',
  themeMenu: 'Menu de thème',
  notifications: 'Notifications',
  languageMenu: 'Menu de langue',

  ////////////////////////////////////////////////////////////////
  // Permissions
  ////////////////////////////////////////////////////////////////

  organizationLevel: 'Niveau d\'organisation',
  organizationLevelDesc: 'Le niveau de l\'organisation dans lequel l\'utilisateur est situé.',
  departmentLevel: 'Niveau de département',
  departmentLevelDesc: 'Le niveau du département dans lequel l\'utilisateur est situé.',
  userLevel: 'Niveau d\'utilisateur',
  userLevelDesc: 'Le niveau de l\'utilisateur dans lequel l\'utilisateur est situé.',

  ////////////////////////////////////////////////////////////////
  // Abbreviations
  ////////////////////////////////////////////////////////////////
  min: 'min',

  ////////////////////////////////////////////////////////////////
  // Inputs
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // Days
  ////////////////////////////////////////////////////////////////
  sunday: 'Dimanche',
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  saturday: 'Samedi',
  today: 'Aujourd\'hui',
};

export default locale; 
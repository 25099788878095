const locale = {
  title: "Categorías de Clientes",
  headerTitle: "Categorías de Clientes",
  create: "Crear Categoría de Cliente",
  columnId: "ID",
  columnName: "Nombre",
  columnRoles: "Roles",
  columnActions: "Acciones",
  rolesButton: "Editar Roles",
  createCustomerCategoryTitle: "Crear Nueva Categoría de Cliente",
  customerCategoryNameLabel: "Nombre de Categoría de Cliente",
  rolesLabel: "Roles",
  createCustomerCategoryButton: "Crear",
  emptyStateContent1: "No se encontraron categorías de clientes",
  emptyStateContent2:
    "Puede crear nuevas categorías de clientes haciendo clic en el botón de abajo.",
  addNewRole: "Añadir Nuevo Rol",
  deletedSuccessfully: "Categoría de cliente eliminada con éxito",
  deleteConfirm: "¿Está seguro de que desea eliminar esta categoría de cliente?",
  delete: "Eliminar",
  enTranslation: "Traducción al Inglés",
  trTranslation: "Traducción al Turco",
  department: "Departamento",
  parentCategory: "Categoría Principal",
  icon: "Icono",
  name: "Nombre",
  createCustomerCategorySuccess: "Categoría de cliente creada con éxito",
  addDepartment: "Añadir Departamento",
  showMore: "Mostrar Más",
  columnIcon: "Icono",
  columnDepartment: "Departamento",
  columnParentCategory: "Categoría Principal",
  selectIconTitle: "Seleccionar un Icono",
  searchIcons: "Buscar Iconos",
  update: "Actualizar Categoría de Cliente",
  updateCustomerCategorySuccess: "Categoría de cliente actualizada con éxito",
  actions: "Acciones",
  createdAt: "Creado El",
  emptyState1: "No se encontraron categorías de clientes",
  emptyState2: "Puede crear nuevas categorías de clientes haciendo clic en el botón de abajo.",
};

export default locale; 
const locale = {
  permissions: 'Permisos',
  roles: 'Roles',
  createRole: 'Crear Rol',
  rolesInfo: 'Puede crear roles y asignarles permisos.',
  roleName: 'Nombre del Rol',
  roleDetail: 'Detalle del Rol',
  addRole: 'Añadir',
  updateRole: 'Actualizar',
  deleteRole: 'Eliminar',
  roleCreatedSuccess: 'Rol creado con éxito',
  roleUpdatedSuccess: 'Rol actualizado con éxito',
  roleDeletedSuccess: 'Rol eliminado con éxito',
  errorFetchingRoles: 'Error al obtener roles',
  errorCreatingRole: 'Error al crear rol',
  errorUpdatingRole: 'Error al actualizar rol',
  errorDeletingRole: 'Error al eliminar rol',
  capabilities: 'Capacidades',
  advancedOptions: 'Opciones Avanzadas',
  addNewRole: 'Añadir Nuevo Rol',
  rolesAndPermissions: 'Roles y Permisos',
  action: 'Acción',
  actionScope: 'Ámbito de Acción',
  deleteConfirm: '¿Está seguro de que desea eliminar el rol ',
  deletedSuccessfully: "Rol eliminado con éxito",
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  IBaseError,
  IGraphqlVariables,
  IHasId,
  isNullOrUndefinedOrEmptyObject,
  PermissionAction,
} from 'corede-common';
import {
  INote,
  INoteDetailResult,
  INoteListInput,
  NoteTargetType,
  PermissionSubject,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useNoteListQuery, useNoteUpdateMutation } from '../../context/note.api';
import { NoteDeleteDialog } from '../NoteDeleteDialog';
import NoteUpdateDrawerWrapper from '../update/NoteUpdateOverlay.Wrapper';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  useHandleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import NoteDetailDrawerWrapper from '../detailDrawer/NoteDetailDrawer.wrapper';
import { representDateTimeAsString, representHtmlAsString } from 'utils/representationHelper';
import { OverlayType } from 'components/dialog/ActionDialog';
import { DefaultTabForListComponentProps } from 'apps/crm/components/tabs/DefaultTabs.forList.component';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import NoteCreateOverlayWrapper from '../create/NoteCreateOverlay.wrapper';
import { noteListColumnData } from './NoteList-grid.columnData';
import { useLocation } from 'react-router-dom';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export interface INoteListGridComponent extends DefaultTabForListComponentProps {
  canCreate: boolean;
  targetType?: NoteTargetType;
  targetId?: string;
  createdById?: string;
}

const NoteListGridComponent = memo((props: INoteListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const location = useLocation();

  // states
  const [selectedNote, setSelectedNote] = useState<TListGridSelectedEntity<INote>>();
  const [openNoteDetailDrawer, setOpenNoteDetailDrawer] = useState(false);
  const [openNoteDeleteDialog, setOpenNoteDeleteDialog] = useState(false);
  const [openNoteUpdateDrawer, setOpenNoteUpdateDrawer] = useState(false);
  const [openNoteCreateOverlay, setOpenNoteCreateOverlay] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.note,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.note,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.note,
    action: PermissionAction.delete,
  });

  const [customNoteListFilter, setCustomNoteListFilter] = useState<
    IGraphqlVariables<INoteListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const [noteListFilter, setNoteListFilter] = useState<IGraphqlVariables<INoteListInput>>({
    input: {
      filter: {
        targetType: props.targetType,
        targetIds: props.targetId ? [props.targetId] : undefined,
        createdById: props.createdById,
      },
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = noteListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = noteListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: noteListData,
    isLoading: noteListLoading,
    isFetching: noteListFetching,
    error: noteListError,
    refetch,
  } = useNoteListQuery(noteListFilter);

  // used for direct update in the list rows
  const [noteUpdate, { isLoading: noteUpdateLoading, error: noteUpdateError }] =
    useNoteUpdateMutation();

  // effects

  useEffect(() => {
    setNoteListFilter({
      ...customNoteListFilter,
      input: {
        ...customNoteListFilter.input,
        filter: {
          targetType: props.targetType,
          targetIds: props.targetId ? [props.targetId] : undefined,
          createdById: props.createdById,
          ...customNoteListFilter.input?.filter,
        },
      },
    });
  }, [customNoteListFilter]);

  // useEffect(() => {
  //   props.setNoteListLoading && props.setNoteListLoading(noteListLoading);
  // }, [noteListLoading]);
  useEffect(() => {
    refetch();
  }, [noteListFilter]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(noteListError as IBaseError, currentLanguage);
  }, [noteListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(noteUpdateError as IBaseError, currentLanguage);
  }, [noteUpdateError]);

  const isEmptyState =
    noteListData?.data?.length === 0 &&
    !noteListLoading &&
    !noteListFetching &&
    isNullOrUndefinedOrEmptyObject(customNoteListFilter?.input?.filter);

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenNoteCreateOverlay(true);
    }
  }, [location]);

  return (
    <DefaultTabContainer
      title={t(props.name ?? 'crm.notes')}
      rightButton={
        props.canCreate
          ? {
              icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
              title: t(`crm.note.note.create`),
              onClick: () => {
                setOpenNoteCreateOverlay(true);
              },
              permission: {
                subject: PermissionSubject.note,
                action: PermissionAction.create,
              },
            }
          : undefined
      }
    >
      <DefaultListGridComponent
        selectedEntity={selectedNote}
        setSelectedEntity={setSelectedNote}
        listFilter={noteListFilter}
        setListFilter={setNoteListFilter}
        listData={noteListData}
        pendingNavigation={pendingNavigation}
        setPendingNavigation={setPendingNavigation}
        listFetching
        listLoading
      >
        <Card
          sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: 'calc(100dvh - 250px)' }}
        >
          {noteListData ? (
            !isEmptyState ? (
              <BaseDataGrid
                rows={
                  noteListData?.data?.map((note, index) => ({
                    id:
                      ((noteListFilter.input?.pagination?.page || 1) - 1) *
                        (noteListFilter.input?.pagination?.pageSize || 0) +
                      index +
                      1,
                    _id: note._id,
                    target: note.target,
                    title: note.title || '-',
                    content: representHtmlAsString({ data: note.content, trimLength: 50 }),
                    targetType: note.targetType || '-',
                    createdAt: representDateTimeAsString(note.createdAt),
                  })) || []
                }
                columns={noteListColumnData({
                  t,
                  containsTargetType: !props.targetType,
                  hasUpdatePermission,
                })}
                actionColumn={{
                  width: 80,
                  defaultActions: {
                    view: hasDetailPermission
                      ? {
                          clickConfig: {
                            setSelectedRow: setSelectedNote,
                            setOpenAction: setOpenNoteDetailDrawer,
                          },
                        }
                      : undefined,
                    edit: hasUpdatePermission
                      ? {
                          clickConfig: {
                            setSelectedRow: setSelectedNote,
                            setOpenAction: setOpenNoteUpdateDrawer,
                          },
                        }
                      : undefined,
                    delete: hasDeletePermission
                      ? {
                          clickConfig: {
                            setSelectedRow: setSelectedNote,
                            setOpenAction: setOpenNoteDeleteDialog,
                          },
                        }
                      : undefined,
                  },
                  customCellItems: [],
                  actionHeaderName: t('crm.actions'),
                }}
                loading={noteListLoading || noteUpdateLoading || noteListFetching}
                listFilter={{
                  filterInput: customNoteListFilter,
                  setFilterInput: setCustomNoteListFilter,
                }}
                update={{
                  updateQuery: hasUpdatePermission ? noteUpdate : undefined,
                }}
                count={noteListData?.count ?? 0}
                config={{
                  columnVisibilityModel: {},
                  features: [],
                }}
              />
            ) : (
              <EmptyState
                content1={t('crm.note.note.emptyState1')}
                content2={t('crm.note.note.emptyState2')}
              />
            )
          ) : null}
        </Card>
        <Box sx={{ width: '100%', height: '10px' }} />
      </DefaultListGridComponent>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.note,
          action: PermissionAction.detail,
        }}
      >
        <NoteDetailDrawerWrapper
          open={openNoteDetailDrawer}
          setOpen={setOpenNoteDetailDrawer}
          note={selectedNote as INoteDetailResult & IHasId<number>} // TODO:
          sideNavigationProps={{
            count: noteListData?.count ?? 0,
            handleNavigate: useHandleSideNavigationNavigate({
              currentPage,
              currentPageSize,
              listData: noteListData,
              listFilter: noteListFilter,
              setListFilter: setNoteListFilter,
              selectedEntity: selectedNote,
              setSelectedEntity: setSelectedNote,
              setPendingNavigation: setPendingNavigation,
            }),
          }}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.note,
          action: PermissionAction.update,
        }}
      >
        <NoteUpdateDrawerWrapper
          open={openNoteUpdateDrawer}
          setOpen={setOpenNoteUpdateDrawer}
          targetId={props.targetId}
          targetType={props.targetType}
          selectedNoteId={selectedNote?._id ?? ''}
          overlayType={OverlayType.drawer}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.note,
          action: PermissionAction.delete,
        }}
      >
        <NoteDeleteDialog
          open={openNoteDeleteDialog}
          noteId={selectedNote?._id ?? ''}
          setNote={setSelectedNote}
          onClose={() => {
            setSelectedNote(undefined);
            setOpenNoteDeleteDialog(false);
          }}
          key={selectedNote?._id}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.note,
          action: PermissionAction.create,
        }}
      >
        <NoteCreateOverlayWrapper
          open={openNoteCreateOverlay}
          setOpen={setOpenNoteCreateOverlay}
          targetId={props.targetId}
          targetType={props.targetType}
          overlayType={OverlayType.drawer}
        />
      </PermissionWrapper>
    </DefaultTabContainer>
  );
});

export default NoteListGridComponent;

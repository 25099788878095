const locale = {
  invoice: "Rechnung",
  invoices: "Rechnungen",
  create: "Rechnung erstellen",
  update: "Rechnung aktualisieren",
  delete: "Löschen",
  view: "Rechnung anzeigen",
  list: "Rechnungen auflisten",
  createdSuccessfully: "Rechnung erfolgreich erstellt",
  updatedSuccessfully: "Rechnung erfolgreich aktualisiert",
  deleteConfirm:
    "Sind Sie sicher, dass Sie löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  deletedSuccessfully: "Rechnung erfolgreich gelöscht",
  createInfo: "Hier können Sie neue Rechnungen erstellen.",
  prefix: "Präfix",
  invoiceId: "Rechnung",
  timeZone: "Zeitzone",
  customerId: "Kunde",
  customerName: "Kundenname",
  customerType: "Kundentyp",
  customerCity: "Kundenstadt",
  customerCountry: "Kundenland",
  customerContactEmail: "Kunden-E-Mail",
  customerContactLastName: "Kunde Nachname",
  customerContactFirstName: "Kunde Vorname",
  billingInfo: "Rechnungsinformationen",
  ["billingInfo.fullName"]: "Vollständiger Name",
  ["billingInfo.phone"]: "Telefon",
  ["billingInfo.address"]: "Adresse",
  ["billingInfo.country"]: "Land",
  ["billingInfo.state"]: "Bundesland",
  ["billingInfo.city"]: "Stadt",
  ["billingInfo.street"]: "Straße",
  ["billingInfo.zip"]: "PLZ",
  ["billingInfo.vat"]: "USt-IdNr.",
  ["billingInfo.taxOffice"]: "Finanzamt",
  shippingInfo: "Versandinformationen",
  ["shippingInfo.fullName"]: "Vollständiger Name",
  ["shippingInfo.phone"]: "Telefon",
  ["shippingInfo.address"]: "Adresse",
  ["shippingInfo.country"]: "Land",
  ["shippingInfo.state"]: "Bundesland",
  ["shippingInfo.city"]: "Stadt",
  ["shippingInfo.street"]: "Straße",
  ["shippingInfo.zip"]: "PLZ",
  generalInformation: "Allgemeine Informationen*",
  prospect: "Interessent*",
  detailedInformation: "Detaillierte Informationen",
  billingInformation: "Rechnungsinformationen*",
  shippingInformation: "Versandinformationen",
  productsAndServices: "Produkte und Dienstleistungen*",
  salesAgents: "Vertriebsmitarbeiter",
  legal: "Rechtliches",
  signatureTab: "Unterschrift*",
  isRecurring: "Wiederkehrend",
  recurringFrequency: "Wiederholungsfrequenz",
  recurringDueDate: "Wiederkehrendes Fälligkeitsdatum",
  recurringCount: "Anzahl der Wiederholungen",
  language: "Sprache",
  date: "Datum",
  expireDate: "Ablaufdatum",
  sendEmailOnExpiration: "E-Mail bei Ablauf senden",
  expirationEmailSentDate: "Datum der gesendeten Ablauf-E-Mail",
  allowedPaymentMethods: "Erlaubte Zahlungsmethoden",
  paymentTerms: "Zahlungsbedingungen",
  currency: "Währung",
  products: "Produkte",
  addProducts: "Produkte hinzufügen",
  offers: "Angebote",
  reference: "Referenz",
  salesAgentIds: "Vertriebsmitarbeiter",
  allowComments: "Kommentare erlauben",
  useLegalNDA: "Rechtliche Geheimhaltungsvereinbarung verwenden",
  legalNDAId: "Rechtliche Geheimhaltungsvereinbarung",
  useLegalTermAndCond: "Rechtliche Geschäftsbedingungen verwenden",
  legalTermAndCondId: "Rechtliche Geschäftsbedingungen",
  signerUserId: "Unterzeichner",
  listInfo: "Liste aller Rechnungen.",
  importedError: "Importfehler",
  importedSuccessfully: "Erfolgreich importiert",
  downloadFormError: "Fehler beim Herunterladen des Formulars",
  downloadFormSuccessfully: "Formular erfolgreich heruntergeladen",
  downloadExampleFormSuccessfully: "Beispielformular erfolgreich heruntergeladen",
  downloadExampleFormError: "Fehler beim Herunterladen des Beispielformulars",
  title: "Rechnungen",
  emptyState1: "Keine Rechnungen gefunden.",
  emptyState2: "Sie können sofort Rechnungen erstellen oder importieren.",
  createInvoice: "Rechnung erstellen",
  importInvoice: "Rechnung importieren",
  importTitle: "Rechnungen importieren",
  downloadExample: "Beispiel herunterladen",
  importText1: "Sie können Rechnungen von dieser Seite importieren.",
  importText2: "Sie können das Beispielformular unten verwenden, um Ihre Rechnungen zu veröffentlichen.",
  importExport: "Import/Export",
  exportData: "Daten exportieren",
  importData: "Daten importieren",
  b2b: "B2B",
  b2c: "B2C",
  viewInvoice: "Anzeigen",
  edit: "Bearbeiten",
  junk: "Papierkorb",
  unJunk: "Aus Papierkorb entfernen",
  addNewRole: "Neue Rolle hinzufügen",
  actions: "Aktionen",
  allowedPaymentMethodsPlaceholder: "Zahlungsmethode hinzufügen",
  timezone: "Zeitzone",
  followUp: "Nachfassen",
  lastSent: "Zuletzt gesendet",
  dueDate: "Fälligkeitsdatum",
  sendEmail: "E-Mail senden",
  customer: "Kunde",
  country: "Land",
  city: "Stadt",
  contactFirstName: "Vorname des Kontakts",
  contactLastName: "Nachname des Kontakts",
  contactEmail: "Kontakt-E-Mail",
  services: "Dienstleistungen",
  businessName: "Firmenname",
  governmentAgency: "Regierungsbehörde",
  fullName: "Vollständiger Name",
  VAT: "USt-IdNr.",
  phoneNumber: "Telefonnummer",
  state: "Bundesland",
  ZIP: "PLZ",
  address: "Adresse",
  sentDate: "Sendedatum",
  name: "Name",
  description: "Beschreibung",
  productUnit: "Einheit",
  quantity: "Menge",
  price: "Preis",
  priceType: "Preistyp",
  discountedPrice: "Rabattierter Preis",
  finalPrice: "Endpreis",
  tax: "Steuer",
  service: "Dienstleistung",
  subTotal: "Zwischensumme",
  taxTotal: "Steuersumme",
  discount: "Rabatt",
  product: "Produkt",
  references: "Referenzen",
  add: "Hinzufügen",
  salesAgent: "Vertriebsmitarbeiter",
  executiveSummary: "Zusammenfassung",
  companyBackground: "Unternehmenshintergrund",
  projectorServiceDesc: "Detaillierte Projekt-/Dienstleistungsbeschreibung",
  costBreakdown: "Kostenaufschlüsselung",
  projectTimeline: "Projektzeitplan",
  testimonialCaseStudies: "Testimonials und Fallstudien",
  conclusion: "Fazit",

  sentSuccessfully: 'Rechnung erfolgreich gesendet',
  send: 'Rechnung senden',
  resend: 'Rechnung erneut senden',
  sendSureTitle: 'Rechnung senden',
  sendSureDesc: 'Sind Sie sicher, dass Sie diese Rechnung senden möchten?',
  changeStatus: 'Status ändern',
  statusUpdatedSuccessfully: 'Status erfolgreich aktualisiert',

  // generalInfo
  generalInfoTitle: 'Allgemeine Informationen',
  status: 'Status',
  createdAt: 'Erstellt am',
  createdBy: 'Erstellt von',
  updatedAt: 'Aktualisiert am',
  notAvailable: 'Nicht verfügbar',
  updateStatus: 'Status aktualisieren',

  // customerInfo
  customerInfoTitle: 'Kundeninformationen',
  contactPhoneNumber: 'Telefonnummer',

  //productInfo
  productInfoTitle: 'Produktdetails',
  productNameUnknown: 'Unbekanntes Produkt',
  noProducts: 'Keine Produkte verfügbar.',

  //legalInfo
  legalInfoTitle: 'Rechtliche Informationen',
  ndaTitle: 'Geheimhaltungsvereinbarung (NDA)',
  termsTitle: 'Allgemeine Geschäftsbedingungen',
  documentName: 'Dokumentname',
  documentDescription: 'Beschreibung',
  documentContent: 'Inhalt',

  //financialInfo
  financialInfoTitle: 'Finanzinformationen',
  shippingTotal: 'Versandkosten gesamt',
  total: 'Gesamtpreis',
  noFinancialData: 'Keine Finanzdaten verfügbar.',

  // documentInfo
  documentsTitle: 'Dokumente',
  documentNameUnknown: 'Unbekanntes Dokument',
  mimeType: 'Dateityp',
  download: 'Herunterladen',
  noDocuments: 'Keine Dokumente verfügbar.',

  // salesAgents
  salesAgentsTitle: 'Vertriebsmitarbeiter',
  unknownAgent: 'Unbekannter Mitarbeiter',
  noSalesAgents: 'Keine Vertriebsmitarbeiter verfügbar.',

  //signature
  signatureTitle: 'Unterschriftsinformationen',
  additionalInfoTitle: 'Zusätzliche Informationen',
  signature: 'Unterschrift',
  signerUser: 'Unterzeichner',

  //additionalInfo
  yes: 'Ja',
  no: 'Nein',
};

export default locale; 
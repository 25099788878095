/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from 'react';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import {
  IProjectTimeSheet,
  IProjectTimeSheetUpdateFilterInput,
  IProjectTimeSheetUpdateInput,
} from 'corede-common-cocrm';
import { validateUpdateTimeSheetInput } from '../../../validations/update.validation';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import { useProjectTimeSheetUpdateMutation } from '../../../context/project.api';
import { enqueueSnackbar } from 'notistack';
import 'react-quill/dist/quill.snow.css';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpsertTimeSheetComponent } from '../UpsertTimeSheet.component';
import ActionDrawer from 'components/drawer/ActionDrawer';
import ActionDialog, { OverlayType } from 'components/dialog/ActionDialog';

export interface ITimeSheetUpdateOverlay {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTimeSheet?: IProjectTimeSheet | any;
  projectId: string;
  overlayType: OverlayType;
}

const TimeSheetUpdateOverlay = memo((props: ITimeSheetUpdateOverlay) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);

  console.log(props.selectedTimeSheet, 'selectedTimeSheet');
  // queries

  // mutations
  const [
    timeSheetUpdate,
    { data: timeSheetUpdateData, isLoading: timeSheetUpdateLoading, error: timeSheetUpdateError },
  ] = useProjectTimeSheetUpdateMutation();

  // constants
  const [initialValues, setInitialValues] = useState<
    IGraphqlVariables<IProjectTimeSheetUpdateInput, IProjectTimeSheetUpdateFilterInput>
  >({
    filter: {
      projectId: props.projectId ?? '',
      projectTimeSheetId: props.selectedTimeSheet?.timeSheet?._id ?? '',
    },
    input: {
      memberId: props.selectedTimeSheet?.timeSheet?.member?._id ?? '',
      taskId: props.selectedTimeSheet?.timeSheet?.task?._id ?? '',
      startTime: props.selectedTimeSheet?.timeSheet?.startTime ?? undefined,
      endTime: props.selectedTimeSheet?.timeSheet?.endTime ?? undefined,
      note: props.selectedTimeSheet?.timeSheet?.note ?? undefined,
      duration: props.selectedTimeSheet?.timeSheet?.duration ?? undefined,
    },
  });

  // form setup
  const timeSheetUpdateUseForm = useForm<
    IGraphqlVariables<IProjectTimeSheetUpdateInput, IProjectTimeSheetUpdateFilterInput>
  >({
    values: initialValues,
    resolver: yupResolver(validateUpdateTimeSheetInput),
    mode: 'onChange',
  });

  const onSubmit = async (
    values: IGraphqlVariables<IProjectTimeSheetUpdateInput, IProjectTimeSheetUpdateFilterInput>,
  ) => {
    setLoading(true);
    const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
      input: values.input,
    });
    await timeSheetUpdate({
      filter: values.filter,
      input: transformedValues,
    });
    setLoading(false);
  };

  // useEffects.success

  useEffect(() => {
    if (timeSheetUpdateData) {
      enqueueSnackbar(t('crm.project.project.timeSheetUpdateSuccess'), {
        variant: 'success',
      });
      timeSheetUpdateUseForm.reset();
      props.setOpen(false);
    }
  }, [timeSheetUpdateData, timeSheetUpdateUseForm.reset]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(timeSheetUpdateError as IBaseError, currentLanguage);
  }, [timeSheetUpdateError]);

  // useEffects.init

  useEffect(() => {
    if (props.selectedTimeSheet) {
      setInitialValues({
        filter: {
          projectId: props.projectId ?? '',
          projectTimeSheetId: props.selectedTimeSheet?.timeSheet?._id,
        },
        input: {
          memberId: props.selectedTimeSheet?.timeSheet?.member?._id,
          taskId: props.selectedTimeSheet?.timeSheet?.task?._id,
          startTime: props.selectedTimeSheet?.timeSheet?.startTime,
          endTime: props.selectedTimeSheet?.timeSheet?.endTime,
          note: props.selectedTimeSheet?.timeSheet?.note,
          duration: props.selectedTimeSheet?.timeSheet?.duration,
        },
      });
    }
  }, [props.selectedTimeSheet, props.projectId]);

  const commonContent = (
    <UpsertTimeSheetComponent
      open={props.open}
      setOpen={props.setOpen}
      projectId={props.projectId}
      loading={timeSheetUpdateLoading || loading}
      useForm={timeSheetUpdateUseForm}
    />
  );

  switch (props.overlayType) {
    case OverlayType.drawer:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          size="medium"
          title={t('crm.project.project.update')}
          handleSubmit={timeSheetUpdateUseForm.handleSubmit(onSubmit)}
          disabled={timeSheetUpdateLoading || loading || !timeSheetUpdateUseForm.formState.isValid}
          loading={timeSheetUpdateLoading || loading}
          buttonTitle={t('crm.project.project.update')}
        >
          {commonContent}
        </ActionDialog>
      );
    case OverlayType.dialog:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          title={t('crm.project.project.update')}
          handleClick={timeSheetUpdateUseForm.handleSubmit(onSubmit)}
          disabled={timeSheetUpdateLoading || loading || !timeSheetUpdateUseForm.formState.isValid}
          loading={timeSheetUpdateLoading || loading}
          buttonTitle={t('crm.project.project.update')}
          width={500}
        >
          {commonContent}
        </ActionDialog>
      );
    default:
      return null;
  }
});

export default TimeSheetUpdateOverlay;

/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import { IBaseError, IGraphqlVariables, IHasId, PermissionAction } from 'corede-common';
import {
  AppointmentCategory,
  AppointmentStatus,
  AppointmentTargetType,
  IAppointment,
  IAppointmentDetailResult,
  IAppointmentListInput,
  PermissionSubject,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import {
  // useAppointmentListCountQuery,
  useAppointmentListQuery,
  useAppointmentUpdateMutation,
} from '../../context/appointment.api';
import { AppointmentDeleteDialog } from '../AppointmentDeleteDialog';
import AppointmentUpdateDrawerWrapper from '../update/AppointmentUpdateDrawer.wrapper';
import { OrganizerInfoCellComponent } from './AppointmentList-grid-organizerInfoCell.component';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import {
  useHandleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import AppointmentDetailDrawer from '../detailDrawer/AppointmentDetailDrawer';
import { representDateTimeAsString, representHtmlAsString } from 'utils/representationHelper';
import momentTimezone from 'moment-timezone';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import { UserProfileFactory } from 'apps/crm/components/profile/profile-column-cell.component.helper';
import { BaseGridColumnDefFactory } from 'components/baseDataGrid/factories/base-grid-column.factory';

export interface IAppointmentListGridComponent {
  // appointment

  setOpenAppointmentCreateDrawer?: (value: boolean) => void | undefined;
}

const AppointmentListGridComponent = memo((props: IAppointmentListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const timezones = momentTimezone.tz.names();

  // states
  const [selectedAppointment, setSelectedAppointment] =
    useState<TListGridSelectedEntity<IAppointment>>();
  const [openAppointmentDetailDrawer, setOpenAppointmentDetailDrawer] = useState(false);
  const [openAppointmentDeleteDialog, setOpenAppointmentDeleteDialog] = useState(false);
  const [openAppointmentUpdateDrawer, setOpenAppointmentUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.appointment,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.appointment,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.appointment,
    action: PermissionAction.delete,
  });

  const [appointmentListFilter, setAppointmentListFilter] = useState<
    IGraphqlVariables<IAppointmentListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = appointmentListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = appointmentListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: appointmentListData,
    isLoading: appointmentListLoading,
    isFetching: appointmentListFetching,
    error: appointmentListError,
    refetch,
  } = useAppointmentListQuery(appointmentListFilter);

  // const { data: appointmentListCountData } = useAppointmentListCountQuery({});
  const { data: appointmentListCountData } = useAppointmentListQuery({});

  // used for direct update in the list rows
  const [
    appointmentUpdate,
    { isLoading: appointmentUpdateLoading, error: appointmentUpdateError },
  ] = useAppointmentUpdateMutation();

  // effects

  useEffect(() => {
    refetch();
  }, [appointmentListFilter]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(appointmentListError as IBaseError, currentLanguage);
  }, [appointmentListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(appointmentUpdateError as IBaseError, currentLanguage);
  }, [appointmentUpdateError]);

  const isEmptyState =
    appointmentListData?.data?.length === 0 &&
    !appointmentListLoading &&
    !appointmentListFetching &&
    appointmentListCountData?.count === 0;

  // custom views

  console.log(
    timezones?.map((timezone) => ({
      _id: timezone,
      name: timezone,
    })),
    'timezones',
  );

  return (
    <DefaultListGridComponent
      selectedEntity={selectedAppointment}
      setSelectedEntity={setSelectedAppointment}
      listFilter={appointmentListFilter}
      setListFilter={setAppointmentListFilter}
      listData={appointmentListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      {appointmentListData ? (
        !isEmptyState ? (
          <BaseDataGrid
            rows={
              appointmentListData?.data?.map((appointment, index) => ({
                id:
                  ((appointmentListFilter.input?.pagination?.page || 1) - 1) *
                    (appointmentListFilter.input?.pagination?.pageSize || 0) +
                  index +
                  1,
                _id: appointment._id,
                title: appointment.title,
                description: representHtmlAsString({
                  data: appointment.description,
                  trimLength: 100,
                }),
                startTime: representDateTimeAsString(appointment.startTime),
                endTime: representDateTimeAsString(appointment.endTime),
                duration:
                  (appointment.duration ? Number(appointment.duration) / 60 : 0) + ' ' + t('min'),
                status: appointment.status,
                timezone: appointment.timezone,
                location: appointment.location,
                organizer: UserProfileFactory.CreateUserProfileFrom({
                  _id: appointment.organizer?._id,
                  name: appointment.organizer?.name,
                  surname: appointment.organizer?.surname,
                  profileImage: appointment.organizer?.profileImage,
                }),
                targetType: appointment.targetType ?? '-',
                attendees: appointment.attendees,
                category: appointment.category,
                meetingLink: appointment.meetingLink,
              })) || []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'id',
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                    align: 'center',
                  },
                  headerConfig: {
                    name: '#',
                    align: 'center',
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'title',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.title'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'description',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.description'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'startTime',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.calendar.appointment.startTime'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'endTime',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.calendar.appointment.endTime'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'duration',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.calendar.appointment.duration'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'status',
                    filterable: true,
                    editable: false, //TODO hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.status'),
                  },
                  filterConfig: {
                    customFieldName: `appointmentStatus`,
                    isArrayFilter: true,
                  },
                },
                data: AppointmentStatus,
              },
              // {
              //   config: {
              //     baseGridColType: BaseGridColType.object,
              //     column: {
              //       field: 'timezone',
              //       editable: hasUpdatePermission,
              //     },
              //     headerConfig: {
              //       name: t('crm.timezone'),
              //       creatable: false,
              //     },
              //     filterConfig: {
              //       customFieldName: `timezone`,
              //       isArrayFilter: true,
              //     },
              //     valueOptionFields: timezonesOptions,
              //   },
              // },
              //TODO timezone'u object olarak göster
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'timezone',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.calendar.appointment.timezone'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'location',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.calendar.appointment.location'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.any,
                  column: {
                    field: 'organizer',
                    width: 180,
                    sortable: false,
                    editable: false,
                    filterable: false,
                  },
                  headerConfig: {
                    name: `${t('crm.calendar.appointment.organizer')}`,
                  },
                  overrideCell: OrganizerInfoCellComponent(),
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'targetType',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.calendar.appointment.targetType'),
                  },
                  filterConfig: {
                    customFieldName: `targetType`,
                    isArrayFilter: true,
                  },
                },
                data: AppointmentTargetType,
              },
              BaseGridColumnDefFactory.MultiUserColumnDef({
                fieldName: 'attendees',
                fieldLabel: t('crm.calendar.appointment.attendees'),
              }),
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'category',
                    filterable: true,
                    editable: hasUpdatePermission,
                  },
                  headerConfig: {
                    name: t('crm.category'),
                  },
                  filterConfig: {
                    customFieldName: `category`,
                    isArrayFilter: true,
                  },
                },
                data: AppointmentCategory,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'meetingLink',
                    filterable: false,
                    editable: false,
                  },
                  headerConfig: {
                    name: t('crm.calendar.appointment.meetingLink'),
                  },
                },
              },
            ]}
            actionColumn={{
              width: 80,
              defaultActions: {
                view: hasDetailPermission
                  ? {
                      clickConfig: {
                        setSelectedRow: setSelectedAppointment,
                        setOpenAction: setOpenAppointmentDetailDrawer,
                      },
                    }
                  : undefined,
                edit: hasUpdatePermission
                  ? {
                      clickConfig: {
                        setSelectedRow: setSelectedAppointment,
                        setOpenAction: setOpenAppointmentUpdateDrawer,
                      },
                    }
                  : undefined,
                delete: hasDeletePermission
                  ? {
                      clickConfig: {
                        setSelectedRow: setSelectedAppointment,
                        setOpenAction: setOpenAppointmentDeleteDialog,
                      },
                    }
                  : undefined,
              },
              customCellItems: [],
              actionHeaderName: t('crm.actions'),
            }}
            loading={appointmentListLoading || appointmentUpdateLoading || appointmentListFetching}
            listFilter={{
              filterInput: appointmentListFilter,
              setFilterInput: setAppointmentListFilter,
            }}
            update={{
              updateQuery: hasUpdatePermission ? appointmentUpdate : undefined,
            }}
            count={appointmentListData?.count ?? 0}
            config={{
              columnVisibilityModel: {},
              features: [],
            }}
            toolbar={{
              customActions: [],
            }}
          />
        ) : (
          <EmptyState
            content1={t('crm.calendar.appointment.emptyState1')}
            content2={t('crm.calendar.appointment.emptyState2')}
            button1={{
              title: t('crm.calendar.appointment.create'),
              leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              onClick: () => (props?.setOpenAppointmentCreateDrawer ?? (() => {}))(true),
              permission: {
                subject: PermissionSubject.appointment,
                action: PermissionAction.create,
              },
            }}
          />
        )
      ) : null}

      <PermissionWrapper
        check={{
          subject: PermissionSubject.appointment,
          action: PermissionAction.detail,
        }}
      >
        <AppointmentDetailDrawer
          open={openAppointmentDetailDrawer}
          setOpen={setOpenAppointmentDetailDrawer}
          selectedAppointment={selectedAppointment as IAppointmentDetailResult & IHasId<number>}
          setSelectedAppointment={setSelectedAppointment}
          sideNavigationProps={{
            count: appointmentListData?.count ?? 0,
            handleNavigate: useHandleSideNavigationNavigate({
              currentPage,
              currentPageSize,
              listData: appointmentListData,
              listFilter: appointmentListFilter,
              setListFilter: setAppointmentListFilter,
              selectedEntity: selectedAppointment,
              setSelectedEntity: setSelectedAppointment,
              setPendingNavigation: setPendingNavigation,
            }),
          }}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.appointment,
          action: PermissionAction.update,
        }}
      >
        <AppointmentUpdateDrawerWrapper
          open={openAppointmentUpdateDrawer}
          setOpen={setOpenAppointmentUpdateDrawer}
          selectedAppointment={selectedAppointment}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.appointment,
          action: PermissionAction.delete,
        }}
      >
        <AppointmentDeleteDialog
          open={openAppointmentDeleteDialog}
          appointmentId={selectedAppointment?._id ?? ''}
          setAppointment={setSelectedAppointment}
          onClose={() => {
            setSelectedAppointment(undefined);
            setOpenAppointmentDeleteDialog(false);
          }}
          key={selectedAppointment?._id}
        />
      </PermissionWrapper>
    </DefaultListGridComponent>
  );
});

export default AppointmentListGridComponent;

import calendarLocale from "../subdomains/calendar/locales/de";
import appointmentLocale from "../subdomains/appointment/locales/de";
import reminderLocale from "../subdomains/reminder/locales/de";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  reminder: {
    ...reminderLocale,
  },
  appointment: {
    ...appointmentLocale,
  },
  calendar: {
    ...calendarLocale,
  },
};

export default locale; 
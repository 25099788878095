import expenseLocale from "../subdomains/expense/locales/pt";

const locale = {
  // Todo
  // users: {
  //   ...usersLocale,
  // },
  expense: {
    ...expenseLocale,
  },
};

export default locale; 
const locale = {
  myAccount: "Mon compte",
  profilePicture: "Photo de profil",
  profile: "Profil",
  name: "Nom",
  surname: "Prénom",
  birthDate: "Date de naissance",
  gender: "Genre",
  update: "Mettre à jour",
  changeImage: "Changer l'image",
  change: "Modifier",
  updateProfileSuccess: "Profil mis à jour avec succès",
  updateProfilePictureSuccess: "Photo de profil mise à jour avec succès",
  country: "Pays",
  city: "Ville",
  signatureImage: "Image de signature",
  twoFactorAuthEmail: "Email d'authentification à deux facteurs",
};

export default locale; 
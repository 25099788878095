const locale = {
  title: 'Vertragsvorlage',
  emptyState1: 'Noch keine Vertragsvorlagen.',
  emptyState2:
    'Speichern und verwalten Sie hier alle Vertragsdetails, um Ihre Rechnungsstellung zu optimieren und klare Vereinbarungen mit Ihren Kunden zu treffen.',
  create: 'Vertragsvorlage erstellen',
  update: 'Vertragsvorlage aktualisieren',
  delete: 'Vertragsvorlage löschen',
  createSuccess: 'Vertragsvorlage erfolgreich erstellt.',
  updateSuccess: 'Vertragsvorlage erfolgreich aktualisiert.',
  deleteSuccess: 'Vertragsvorlage erfolgreich gelöscht.',
  deleteConfirm: 'Sind Sie sicher, dass Sie diese Vertragsvorlage löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
};

export default locale; 

export const calendarDomainRoutes = {
  base: "calendar",
  subdomains: {
    reminder: {
      base: "reminder",
    },
    appointment: {
      base: "appointment",
    },
    calendar: {
      base: "calendar",
    }
  },
};


export const baseCalendarRoute = (): string => `/${calendarDomainRoutes.base}/`;
export const listReminderRoute = (): string =>
  `/${calendarDomainRoutes.base}/${calendarDomainRoutes.subdomains.reminder.base}/`;
export const listAppointmentRoute = (): string =>
  `/${calendarDomainRoutes.base}/${calendarDomainRoutes.subdomains.appointment.base}`;
export const listCalendarRoute = (): string =>
  `/${calendarDomainRoutes.base}/${calendarDomainRoutes.subdomains.calendar.base}`;
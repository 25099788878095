import pricingLocale from "../subdomains/pricing/locales/ru";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  pricing: {
    ...pricingLocale,
  },
};

export default locale; 
const locale = {
  demo: "Démo",
  title: "Qu'est-ce que <highlight>CoCRM</highlight> Lead+?",
  description:
    "Chez CoCRM, nous ne vous aidons pas seulement à gérer vos processus commerciaux et clients; nous sommes également ravis de vous aider à acquérir de nouveaux clients.",
  featuresTitle: "Avec CoCRM Lead+,",
  features1:
    "Nous sommes heureux de vous fournir les données de clients potentiels dont vous avez besoin en fonction de",
  features2:
    "Les paramètres de pays, de ville et/ou d'industrie que vous ciblez pour le marketing.",
  features3: "Les domaines spécifiques que vous avez définis pour vos objectifs de vente.",
  features4: "Les marchés où vous souhaitez augmenter la notoriété de votre marque.",
  buttonTitle: "Demander un Devis",
  formDescription:
    "Veuillez remplir le formulaire ci-dessous et nous vous contacterons dès que possible.",
  formTitle: "Demander un Devis",
  formButtonTitle: "Soumettre",
  sendSuccess: "Merci! Nous vous répondrons dès que possible.",
  fullName: "Nom Complet",
  email: "Email",
  phone: "Téléphone",
  orgName: "Nom de l'Organisation",
};

export default locale; 
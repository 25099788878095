const locale = {
  organization: 'Organización',
  organizations: 'Organizaciones',
  organizationChart: 'Organigrama',
  update: 'Actualizar',
  delete: 'Eliminar',
  view: 'Ver',
  createdSuccessfully: 'Usuario creado con éxito',
  updatedSuccessfully: 'Usuario actualizado con éxito',
  deleteConfirm: '¿Está seguro de que desea eliminar la cuenta de usuario "',
  deletedSuccessfully: 'Usuario eliminado con éxito',
  info: 'Puede agregar usuarios a su equipo desde esta sección.',
  name: 'Nombre',
  email: 'Correo electrónico',
  surname: 'Apellido',
  viewProfile: 'Ver perfil',
  roles: 'Roles',
  editChart: 'Editar organigrama',
  save: 'Guardar',
  editOrganizationChart: 'Editar organigrama',
  editOrganizationDetail: 'Arrastre y suelte los usuarios en la ubicación deseada.',
  createRole: 'Crear rol',
  userList: 'Lista de usuarios',
  rolesInfo: 'Puede crear roles y asignarles permisos.',
  createUserSuccess: 'Usuario creado con éxito',
  chart: 'Vista de organigrama',
  list: 'Vista de lista',
  users: 'Usuarios',
  user: 'Usuario',
  activeUsers: 'Usuarios activos',
  activeUser: 'Usuario activo',
  create: 'Crear',
  new: 'Nuevo',
  roleName: 'Nombre del rol',
  addRole: 'Añadir',
  updateRole: 'Actualizar',
  deleteRole: 'Eliminar',
  roleCreatedSuccess: 'Rol creado con éxito',
  roleUpdatedSuccess: 'Rol actualizado con éxito',
  roleDeletedSuccess: 'Rol eliminado con éxito',
  errorFetchingPermissions: 'Error al obtener permisos',
  errorFetchingRoles: 'Error al obtener roles',
  errorCreatingRole: 'Error al crear rol',
  errorUpdatingRole: 'Error al actualizar rol',
  errorDeletingRole: 'Error al eliminar rol',
  permissionUpdateSuccess: 'Los permisos del usuario se actualizaron con éxito',
  subject: 'Asunto',
  capabilities: 'Capacidades',
  allPermissions: 'Todos los permisos',
  all: 'Todos',
  advancedOptions: 'Opciones avanzadas',
  id: 'ID',
  emailAddress: 'Dirección de correo electrónico',
  status: 'Estado',
  emailVerified: 'Correo electrónico verificado',
  phoneNumber: 'Número de teléfono',
  country: 'País',
  city: 'Ciudad',
  phone: 'Número de teléfono',
  lastLoginDate: 'Última fecha de inicio de sesión',
  createdAt: 'Creado el',
  actions: 'Acciones',
  addNewRole: 'Añadir nuevo rol',
  yes: 'Sí',
  no: 'No',
  details: 'Detalles',
  jobTitle: 'Puesto de trabajo',
  deleteMessage: '¿Está seguro de que desea eliminar este usuario?',
  cancel: 'Cancelar',
  userDeleteSuccess: 'Usuario eliminado con éxito',
  userDeleteError: 'Error al eliminar usuario',
  updateChartSuccessfully: 'Organigrama actualizado con éxito',
  address: 'Dirección',
  birthDate: 'Fecha de nacimiento',
  gender: 'Género',
  language: 'Idioma',
  description: 'Descripción',
  rolesAndPermissions: 'Roles y permisos',
  ownPermissionsAlert: 'No puede cambiar sus propios permisos.',
  zoom: 'Zoom',
  tree: 'Vista de árbol',
  createSuccess: 'Organigrama creado con éxito',
  updateSuccess: 'Organigrama actualizado con éxito',
  updateError: 'Error al actualizar el organigrama',
  fetchError: 'Error al obtener el organigrama',
  fetchSuccess: 'Organigrama obtenido con éxito',
  undefinedUser: 'Usuario no definido',
  chartUpdateSuccess: '¡Organigrama actualizado con éxito!',
  listView: 'Vista de lista',
  action: 'Acción',
  actionScope: 'Ámbito de acción',
  userDetail: 'Detalle de usuario',
  admin: 'Administrador',
  department: 'Departamento',
  addUser: 'Añadir usuario',
  createDepartment: 'Crear departamento',
  updateUser: 'Actualizar usuario',
  notDeleteOwnConfirm: 'No puede eliminarse a sí mismo.',
  updateUserSuccess: 'Usuario actualizado con éxito',
  removeAccount: 'Eliminar cuenta',
  profile: 'Perfil',
  permissions: 'Permisos',
  associatedLeads: 'Leads asociados',
  associatedCustomers: 'Clientes asociados',
  associatedEstimates: 'Presupuestos asociados',
  associatedProposals: 'Propuestas asociadas',
  associatedInvoices: 'Facturas asociadas',
  associatedTasks: 'Tareas asociadas',
  about: 'Acerca de',
  detail: 'Detalle de usuario',
  users_: 'usuarios',
  resendConfirmation: 'Reenviar confirmación',
  resendSuccess: 'Confirmación enviada con éxito',
};

export default locale; 
import crmLocale from '../../apps/crm/locales/it';
import authLocale from '../../apps/auth/locales/it';
import leadPlusLocale from '../../apps/leadPlus/locales/it';
import communityLocale from '../../apps/community/locales/it';
import marketingLocale from '../../apps/marketing/locales/it';
import windowLocale from '../../apps/window/locales/it';
import settingsLocale from '../../apps/settings/locales/it';
import accountLocale from '../../apps/account/locales/it';
import webNotificationLocale from '../../apps/webNotification/locales/it';
import diskLocale from '../../apps/disk/locales/it';

const locale = {
  crm: {
    ...crmLocale,
  },
  auth: {
    ...authLocale,
  },
  leadPlus: {
    ...leadPlusLocale,
  },
  community: {
    ...communityLocale,
  },
  marketing: {
    ...marketingLocale,
  },
  window: {
    ...windowLocale,
  },
  settings: {
    ...settingsLocale,
  },
  account: {
    ...accountLocale,
  },
  webNotification: {
    ...webNotificationLocale,
  },
  disk: {
    ...diskLocale,
  },
  enums: {
    '-': '-',
    undefined: '-',
    null: '-',
    active: 'Attivo',
    pending: 'In attesa',
    passive: 'Passivo',
    neutral: 'Neutro',
    cold: 'Freddo',
    warm: 'Tiepido',
    hot: 'Caldo',
    customer: 'Cliente',
    unqualified: 'Non qualificato',
    IQL: 'IQL (Informazione)',
    MQL: 'MQL (Marketing)',
    SQL: 'SQL (Vendite)',
    en: 'Inglese',
    fr: 'Francese',
    de: 'Tedesco',
    it: 'Italiano',
    pt: 'Portoghese',
    ru: 'Russo',
    es: 'Spagnolo',
    tr: 'Turco',
    individual: 'Individuale',
    business: 'Azienda',
    government: 'Governo',
    b2b: 'B2B',
    b2c: 'B2C',
    TRY: 'TRY',
    EUR: 'EUR',
    USD: 'USD',
    IRR: 'IRR',
    GBP: 'GBP',
    NOK: 'NOK',
    RUB: 'RUB',
    CHF: 'CHF',
    flat: 'Forfettario',
    hourly: 'Orario',
    daily: 'Giornaliero',
    monthly: 'Mensile',
    NDA: 'NDA',
    termsAndConditions: 'Termini e condizioni',
    lead: 'Lead',
    unknown: 'Sconosciuto',
    everyday: 'Ogni giorno',
    everyWeek: 'Ogni settimana',
    annual: 'Annuale',
    email: 'Email',
    sms: 'SMS',
    push: 'Push',
    web: 'Web',
    male: 'Maschile',
    female: 'Femminile',
    other: 'Altro',
    All: 'Tutti',
    Unread: 'Non letto',
    automatic: 'Automatico',
    manual: 'Manuale',
    high: 'Alto',
    low: 'Basso',
    medium: 'Medio',
    urgent: 'Urgente',
    draft: 'Bozza',
    sent: 'Inviato',
    opened: 'Aperto',
    revised: 'Revisionato',
    declined: 'Rifiutato',
    accepted: 'Accettato',
    product: 'Prodotto',
    service: 'Servizio',
    iyzico: 'Iyzico',
    stripe: 'Stripe',
    posbasit: 'POS Basit',
    paytr: 'PayTR',
    moneyTransfer: 'Bonifico bancario',
    defective: 'Difettoso',
    incorrectItem: 'Articolo errato',
    changeOfMind: 'Cambio di idea',
    throughTasks: 'Tramite attività',
    fixedRate: 'Tariffa fissa',
    projectHours: 'Ore di progetto',
    taskHours: 'Ore di attività',
    onHold: 'In attesa',
    nearToExpire: 'Vicino alla scadenza',
    trash: 'Cestino',
    A: 'A',
    B: 'B',
    C: 'C',
    yes: 'Sì',
    no: 'No',
    default: 'Predefinito',
    meeting: 'Riunione',
    call: 'Chiamata',
    event: 'Evento',
    postponed: 'Rinviato',
    canceled: 'Annullato',
    TWENTY_FOUR_HOURS: '24 ore',
    TWELVE_HOURS: '12 ore',
    '24-hours': '24 ore',
    '12-hours': '12 ore',
    requested: 'Richiesto',
    inspected: 'Ispezionato',
    approved: 'Approvato',
    disapproved: 'Non approvato',
    resolved: 'Risolto',
    refund: 'Rimborso',
    replacement: 'Sostituzione',
    repair: 'Riparazione',
    exchange: 'Scambio',

    // UIElementType
    fieldInput: 'Campo di input',
    header: 'Intestazione',
    paragraph: 'Paragrafo',

    // LeadFieldEnum
    fullName: 'Nome completo',
    position: 'Posizione',
    website: 'Sito web',
    phoneNumber: 'Numero di telefono',
    company: 'Azienda',
    sector: 'Settore',
    country: 'Paese',
    state: 'Stato',
    city: 'Città',
    documents: 'Documenti',

    // reminder
    schedule: 'Programma',
    complete: 'Completa',
    cancel: 'Annulla',
    scheduled: 'Programmato',
    unscheduled: 'Non programmato',
    expired: 'Scaduto',
    cancelled: 'Annullato',

    // subscription status
    ongoing: 'ATTIVO',
    ongoingWithFailure: 'ATTIVO',
    ongoingWithCancelled: 'ATTIVO',
    failed: 'FALLITO',

    //task status
    notStarted: 'Non iniziato',
    inProgress: 'In corso',
    tested: 'Testato',
    awaitingFeedback: 'In attesa di feedback',
    completed: 'Completato',

    // target type
    Lead: 'Lead',
    Customer: 'Cliente',
    Estimate: 'Preventivo',
    Proposal: 'Proposta',
    Invoice: 'Fattura',
    Task: 'Attività',
    User: 'Utente',
    Organization: 'Organizzazione',
    Project: 'Progetto',
    None: 'Nessuno',
    Ticket: 'Ticket',
    Expense: 'Spesa',
    Contract: 'Contratto',

    //ticket categories
    incidentTicket: 'Ticket di incidente',
    requestForInformation: 'Richiesta di informazioni',
    serviceRequest: 'Richiesta di servizio',
    problemTicket: 'Ticket di problema',
    changeRequest: 'Richiesta di modifica',
    featureRequest: 'Richiesta di funzionalità',
    feedback: 'Feedback',
    complaint: 'Reclamo',
    maintenanceAndOutageReport: 'Rapporto di manutenzione e interruzione',
    billingAndPaymentIssue: 'Problema di fatturazione e pagamento',
    internalTicket: 'Ticket interno',
    open: 'Aperto',
    answered: 'Risposto',
    closed: 'Chiuso',

    //permissions
    auth: 'Autenticazione',
    comment: 'Commento',
    dms: 'Documento',
    file: 'Documento',
    note: 'Nota',
    notification: 'Notifica',
    payment: 'Pagamento',
    presale: 'Prevendita',
    reminder: 'Promemoria',
    sale: 'Vendita',
    task: 'Attività',
    user: 'Utente',
    permission: 'Permesso',
    role: 'Ruolo',
    leadSource: 'Fonte del lead',
    leadStatus: 'Stato del lead',
    leadTag: 'Tag del lead',
    productCategory: 'Categoria di prodotto',
    webNotification: 'Notifica web',
    notificationHistory: 'Cronologia delle notifiche',
    order: 'Ordine',
    purchase: 'Acquisto',
    subscription: 'Abbonamento',
    proposal: 'Proposta',
    estimate: 'Preventivo',
    customerCategory: 'Categoria cliente',
    customerTag: 'Tag cliente',
    invoice: 'Fattura',
    invoicePayment: 'Pagamento',
    invoiceReturn: 'Reso',
    organization: 'Organizzazione',
    organizationSettings: 'Impostazioni dell\'organizzazione',
    organizationChart: 'Organigramma',
    department: 'Dipartimento',
    userRole: 'Ruolo utente',
    userPermission: 'Permesso utente',
    manage: 'Gestisci',
    view: 'Visualizza',
    export: 'Esporta',
    import: 'Importa',
    calendar: 'Calendario',
    appointment: 'Appuntamento',
    contract: 'Contratto',
    contractTemplate: 'Modello di contratto',
    expense: 'Spesa',
    project: 'Progetto',
    ticket: 'Ticket',
    dashboard: 'Dashboard',

    // file extension
    jpg: 'jpg',
    png: 'png',
    pdf: 'pdf',
    doc: 'doc',
    docx: 'docx',
    xls: 'xls',
    xlsx: 'xlsx',
    ppt: 'ppt',
    pptx: 'pptx',
    txt: 'txt',
    mp4: 'mp4',
    zip: 'zip',

    // payment status
    unPaid: 'Non pagato',
    partiallyPaid: 'Parzialmente pagato',
    totallyPaid: 'Totalmente pagato',
    
  },
  organization: 'Organizzazione',
  department: 'Dipartimento',
  user: 'Utente',
  aboutUs: 'Chi siamo',
  termsAndConditions: 'Termini e condizioni',
  uploadDocuments: 'Carica documenti',
  privacyPolicy: 'Politica sulla privacy',
  cookiesPolicy: 'Politica sui cookie',
  resources: 'Risorse',
  allRightsReserved: 'Tutti i diritti riservati',
  rowsPerPage: 'Righe per pagina',
  chartUpdateSuccess: 'Grafico aggiornato con successo!',
  imageUploadSuccess: 'Immagine caricata con successo!',
  uploadPicture: 'Carica un\'immagine',
  expiresIn: 'Scade tra ',
  expiresDays: ' giorni',
  subscriptionExpired: 'Il tuo piano è scaduto!',
  noOptionsText: 'Nessuna opzione',
  noCategory: 'Nessuna categoria',

  ////////////////////////////////////////////////////////////////
  // Validations
  ////////////////////////////////////////////////////////////////

  isRequired: 'Questo campo è obbligatorio',
  minValue0: 'Il valore minimo è 0',
  fullNameIsRequired: 'Il nome completo è obbligatorio',
  emailIsRequired: 'L\'email è obbligatoria',
  emailIsInvalid: 'L\'email non è valida',
  phoneIsInvalid: 'Il telefono non è valido',
  nameIsRequired: 'Il nome è obbligatorio',
  titleIsRequired: 'Il titolo è obbligatorio',
  profileImageIsRequired: 'L\'immagine del profilo è obbligatoria',
  descriptionIsRequired: 'La descrizione è obbligatoria',
  subjectIsRequired: 'L\'oggetto è obbligatorio',
  phoneNumberIsRequired: 'Il numero di telefono è obbligatorio',
  quotaYupIsRequired: 'La quota è obbligatoria',
  dateYupIsRequired: 'La data è obbligatoria',
  statusYupIsRequired: 'Lo stato è obbligatorio',
  locationYupIsRequired: 'La posizione è obbligatoria',
  detailsIsRequired: 'I dettagli sono obbligatori',
  surnameIsRequired: 'Il cognome è obbligatorio',
  birthDateIsRequired: 'La data di nascita è obbligatoria',
  genderIsRequired: 'Il genere è obbligatorio',
  countryIsRequired: 'Il paese è obbligatorio',
  cityIsRequired: 'La città è obbligatoria',
  addressIsRequired: 'L\'indirizzo è obbligatorio',
  contentIsRequired: 'Il contenuto è obbligatorio',
  readingTimeIsRequired: 'Il tempo di lettura è obbligatorio',
  tagsIsRequired: 'I tag sono obbligatori',
  referencesIsRequired: 'I riferimenti sono obbligatori',
  relatedVideoIsRequired: 'Il video correlato è obbligatorio',
  authorIsRequired: 'L\'autore è obbligatorio',
  categoryIsRequired: 'La categoria è obbligatoria',
  targetCategoryIsRequired: 'La categoria target è obbligatoria',
  languageIsRequired: 'La lingua è obbligatoria',
  domainIsRequired: 'Il dominio è obbligatorio',
  targetIsRequired: 'Il target è obbligatorio',
  questionIsRequired: 'La domanda è obbligatoria',
  answerIsRequired: 'La risposta è obbligatoria',
  titleIsTooShort25: 'Il titolo è troppo corto, la lunghezza minima è 25',
  titleIsTooLong50: 'Il titolo è troppo lungo, la lunghezza massima è 50',
  contentIsTooShort50: 'Il contenuto è troppo corto, la lunghezza minima è 50',
  contentIsTooLong250: 'Il contenuto è troppo lungo, la lunghezza massima è 250',
  descriptionIsTooShort400: 'La descrizione è troppo corta, la lunghezza minima è 400',
  descriptionIsTooLong4000: 'La descrizione è troppo lunga, la lunghezza massima è 4000',
  quotaMustBePositive: 'La quota deve essere positiva',
  quotaMustBeMultipleOf10: 'La quota deve essere un multiplo di 10',
  participantsIsRequired: 'I partecipanti sono obbligatori',
  startDateIsRequired: 'La data di inizio è obbligatoria',
  durationIsRequired: 'La durata è obbligatoria',
  quotaIsRequired: 'La quota è obbligatoria',
  lastApplicationDateIsRequired: 'La data dell\'ultima applicazione è obbligatoria',
  statusIsRequired: 'Lo stato è obbligatorio',
  locationIsRequired: 'La posizione è obbligatoria',
  typeIsRequired: 'Il tipo è obbligatorio',
  participationLinkIsRequired: 'Il link di partecipazione è obbligatorio',
  durationMustBeMultipleOf5: 'La durata deve essere un multiplo di 5',
  durationMustBePositive: 'La durata deve essere positiva',
  nameIsTooShort2: 'Il nome è troppo corto, la lunghezza minima è 2',
  nameIsTooLong50: 'Il nome è troppo lungo, la lunghezza massima è 50',
  surnameIsTooShort2: 'Il cognome è troppo corto, la lunghezza minima è 2',
  surnameIsTooLong50: 'Il cognome è troppo lungo, la lunghezza massima è 50',
  stateIsRequired: 'Lo stato è obbligatorio',
  positionIsRequired: 'La posizione è obbligatoria',
  companyIsRequired: 'L\'azienda è obbligatoria',
  sectorIsRequired: 'Il settore è obbligatorio',
  qualificationIsRequired: 'La qualifica è obbligatoria',
  prospectNameIsRequired: 'Il nome del prospect è obbligatorio',
  prospectPhoneNumberIsRequired: 'Il numero di telefono del prospect è obbligatorio',
  prospectContactFirstNameIsRequired: 'Il nome del contatto del prospect è obbligatorio',
  prospectContactLastNameIsRequired: 'Il cognome del contatto del prospect è obbligatorio',
  prospectContactEmailIsRequired: 'L\'email del contatto del prospect è obbligatoria',
  websiteIsInvalid: 'Il sito web non è valido',
  identityNumberIsRequired: 'Il numero di identità è obbligatorio',
  taxOfficeIsRequired: 'L\'ufficio delle imposte è obbligatorio',
  streetIsRequired: 'La strada è obbligatoria',
  zipIsRequired: 'Il CAP è obbligatorio',
  passwordIsRequired: 'La password è obbligatoria',
  passwordIsInvalid: 'Deve contenere almeno 8 caratteri, includere maiuscole, minuscole, numeri e simboli.',
  passwordAgainIsInvalid: 'Le password devono corrispondere',
  cannotBeGreaterThanPrice: 'Non può essere maggiore del prezzo',
  endTimeMustBeLaterThanStartTime: 'Deve essere successivo all\'ora di inizio',
  mustBeNumber: 'Deve essere un numero',

  cardValidation: {
    required: 'Questo campo è obbligatorio',
    invalid: 'Non valido',
    expired: 'Scaduto',
  },

  ////////////////////////////////////////////////////////////////
  // Buttons
  ////////////////////////////////////////////////////////////////

  cancel: 'Annulla',
  save: 'Salva',
  update: 'Aggiorna',
  delete: 'Elimina',
  create: 'Crea',
  add: 'Aggiungi',
  close: 'Chiudi',
  submit: 'Invia',
  addDocument: 'Aggiungi documento',
  upload: 'Carica',
  view: 'Visualizza',
  edit: 'Modifica',
  remove: 'Rimuovi',
  myPlan: 'Il mio piano',
  expandAll: 'Espandi tutto',
  collapseAll: 'Comprimi tutto',
  show: 'Mostra',
  download: 'Scarica',
  yes: 'Sì',
  no: 'No',
  completed: 'Completato',
  uploadDocument: 'Carica documento',
  newTask: 'Nuova attività',
  loadMore: 'Carica altro',

  ////////////////////////////////////////////////////////////////
  // Tables
  ////////////////////////////////////////////////////////////////

  actions: 'Azioni',
  title: 'Titolo',
  content: 'Contenuto',
  category: 'Categoria',
  target: 'Target',
  status: 'Stato',
  date: 'Data',
  location: 'Posizione',
  details: 'Dettagli',
  name: 'Nome',
  surname: 'Cognome',
  birthDate: 'Data di nascita',
  fullName: 'Nome completo',
  email: 'Email',
  subject: 'Oggetto',
  createdAt: 'Creato il',
  userType: 'Tipo di utente',
  page: 'Pagina',
  news: 'Notizie',
  offer: 'Offerta',
  products: 'Prodotti',
  updatedAt: 'Aggiornato il',
  readingTime: 'Tempo di lettura',
  tags: 'Tag',
  references: 'Riferimenti',
  version: 'Versione',
  domain: 'Dominio',
  type: 'Tipo',
  language: 'Lingua',
  duration: 'Durata',
  startDate: 'Data di inizio',
  participants: 'Partecipanti',
  quota: 'Quota',
  question: 'Domanda',
  answer: 'Risposta',
  lastApplicationDate: 'Data dell\'ultima applicazione',
  selectAnOption: 'Seleziona un\'opzione',
  showing: 'Visualizzazione da {{start}} a {{end}} di {{total}} voci',

  ////////////////////////////////////////////////////////////////
  // Menus
  ////////////////////////////////////////////////////////////////
  dashboard: 'Dashboard',
  blog: 'Blog',
  blogs: 'Blog',
  categories: 'Categorie',
  authors: 'Autori',
  faq: 'FAQ',
  faqs: 'FAQ',
  faqCategory: 'Categorie FAQ',
  form: 'Modulo',
  contactForm: 'Moduli di contatto',
  subscriptionForm: 'Moduli di abbonamento',
  blogTargetCategories: 'Categorie target',
  users: 'Utenti',
  departments: 'Dipartimenti',
  roles: 'Ruoli',
  permissions: 'Permessi',
  leadStatuses: 'Stati dei lead',
  leadSources: 'Fonti dei lead',
  leadForms: 'Moduli dei lead',
  customers: 'Clienti',
  contacts: 'Contatti',
  customerCategories: 'Categorie clienti',
  estimates: 'Preventivi',
  proposals: 'Proposte',
  invoices: 'Fatture',
  payments: 'Pagamenti',
  contracts: 'Contratti',
  returns: 'Resi',
  services: 'Servizi',
  reminders: 'Promemoria',
  appointments: 'Appuntamenti',
  starred: 'Preferiti',
  recent: 'Recenti',
  actionMenu: 'Menu azioni',
  themeMenu: 'Menu tema',
  notifications: 'Notifiche',
  languageMenu: 'Menu lingua',

  ////////////////////////////////////////////////////////////////
  // Permissions
  ////////////////////////////////////////////////////////////////

  organizationLevel: 'Livello organizzazione',
  organizationLevelDesc: 'Il livello dell\'organizzazione in cui si trova l\'utente.',
  departmentLevel: 'Livello dipartimento',
  departmentLevelDesc: 'Il livello del dipartimento in cui si trova l\'utente.',
  userLevel: 'Livello utente',
  userLevelDesc: 'Il livello dell\'utente in cui si trova l\'utente.',

  ////////////////////////////////////////////////////////////////
  // Abbreviations
  ////////////////////////////////////////////////////////////////
  min: 'min',

  ////////////////////////////////////////////////////////////////
  // Inputs
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // Days
  ////////////////////////////////////////////////////////////////
  sunday: 'Domenica',
  monday: 'Lunedì',
  tuesday: 'Martedì',
  wednesday: 'Mercoledì',
  thursday: 'Giovedì',
  friday: 'Venerdì',
  saturday: 'Sabato',
  today: 'Oggi',
};

export default locale; 
const locale = {
  tasks: "Tâches",
  task: "Tâche",
  taskConversions: "Conversions de tâches",
  title: "Tâches",
  addTask: "Ajouter une tâche",
  emptyState1: "Aucune tâche assignée pour le moment.",
  emptyState2: "Créez des tâches ici pour organiser votre travail, définir des priorités et suivre vos activités CRM.",
  create: "Créer une tâche",
  subject: "Sujet",
  description: "Description",
  startDate: "Date de début",
  dueDate: "Date d'échéance",
  assigneeIds: "Assignés",
  followerIds: "Suiveurs",
  checklist: "Liste de contrôle",
  department: "Département",
  priority: "Priorité",
  tags: "Tags",
  hourlyRate: "Taux horaire",
  hourlyRateCurrency: "Devise du taux horaire",
  isBillable: "Facturable",
  isRecurrent: "Récurrente",
  repeatDays: "Jours de répétition",
  totalCycle: "Cycle total",
  addChecklistItem: "Ajouter un élément à la liste",
  noAssigneeToCreateChecklist: "Assignez des utilisateurs pour créer des listes de contrôle",
  assignee: "Assigné",
  createTaskSuccess: "Tâche créée avec succès.",
  deleteTask: "Supprimer la tâche",
  deleteTaskContent: "Êtes-vous sûr de vouloir supprimer cette tâche ? Cette action ne peut pas être annulée.",
  deletedSuccessfully: "Tâche supprimée avec succès",
  updateTask: "Mettre à jour la tâche",
  updateSuccess: "Tâche mise à jour avec succès.",

  detail: "Détail de la tâche",
  subtask: "Sous-tâche",

  addSubtask: "Ajouter une sous-tâche",
  updateStatus: "Mettre à jour le statut",

  createdBy: "Tâche créée par ",

  // detailDrawer
  detailedInfo: "Informations détaillées",
  recurrentInfo: "Informations de récurrence",
  billableInfo: "Informations de facturation",
  documents: "Documents",
  editAssignees: "Modifier les assignés",
};

export default locale; 
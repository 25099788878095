const locale = {
  leadSources: "Fuentes de Leads",
  title: "Fuentes de Leads",
  create: "Crear Fuente de Lead",
  delete: "Eliminar Fuente de Lead",
  deleteConfirm: "¿Está seguro de que desea eliminar esta fuente: ",
  deletedSuccessfully: "Fuente de lead eliminada con éxito",
  createSuccess: "Fuente de lead creada con éxito",
  updateSuccess: "Fuente de lead actualizada con éxito",
  update: "Actualizar Fuente de Lead",
  updateLeadSourceSuccess: "Fuente de lead actualizada con éxito",
  selectColor: "Seleccionar Color",
  selectIcon: "Seleccionar Icono",
};

export default locale; 
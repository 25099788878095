const locale = {
  readAllSuccess: 'Tutte le notifiche lette con successo!',
  title: 'Notifiche',
  markAllAsRead: 'Segna tutte come lette.',
  close: 'Chiudi',
  noNotifications1: "Nessuna notifica",
  noNotifications2: "Non hai ancora ricevuto alcuna notifica.",
  refresh: 'Aggiorna',
};

export default locale; 
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { getCurrentLanguage } from 'localization';
import { useAppointmentListQuery } from '../../appointment';
import { usePermissions } from 'permission/PermissionContext';
import {
  IAppointmentListItemResult,
  IReminderListItemResult,
  PermissionSubject,
} from 'corede-common-cocrm';
import { PermissionAction } from 'corede-common';
import { useReminderListQuery } from '../../reminder';

const List = () => {
  const currentLanguage = getCurrentLanguage();
  const { hasPermission } = usePermissions();

  const hasAppointmentListPermission = hasPermission({
    subject: PermissionSubject.appointment,
    action: PermissionAction.list,
  });

  const hasReminderListPermission = hasPermission({
    subject: PermissionSubject.reminder,
    action: PermissionAction.list,
  });

  const [selectedRange, setSelectedRange] = useState<{ start: Date; end: Date }>(() => {
    const today = new Date();
    return {
      start: new Date(today.getFullYear(), today.getMonth(), 1),
      end: new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999),
    };
  });

  const { data: appointmentListData } = useAppointmentListQuery(
    {
      input: {
        filter: {
          startTime: {
            from: selectedRange.start,
            to: selectedRange.end,
          },
        },
      },
    },
    {
      skip: !hasAppointmentListPermission,
    },
  );
  const appintmentData: IAppointmentListItemResult[] | undefined = appointmentListData?.data;

  const { data: reminderListData } = useReminderListQuery(
    {
      input: {
        filter: {
          remindDateFilter: {
            from: selectedRange.start,
            to: selectedRange.end,
          },
        },
      },
    },
    {
      skip: !hasReminderListPermission,
    },
  );
  const reminderData: IReminderListItemResult[] | undefined = reminderListData?.data;

  console.log(reminderData);
  // Map appointment events
  const appointmentEvents =
    appintmentData?.map((appointment) => ({
      id: appointment._id,
      title: appointment.title,
      start: appointment.startTime,
      end: appointment.endTime,
    })) || [];
  
  // Map reminder events using remindDate as start time and set red color
  const reminderEvents =
    reminderData?.map((reminder) => ({
      id: reminder._id,
      title: reminder.title,
      start: reminder.remindDate,
      color: 'red', // reminders colored in red
    })) || [];
  
  // Combine both events arrays
  const events = [...appointmentEvents, ...reminderEvents];

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        {appintmentData && (
          <FullCalendar
            key={currentLanguage}
            plugins={[dayGridPlugin]}
            locale={currentLanguage}
            headerToolbar={{
              left: 'prev',
              center: 'title',
              right: 'next',
            }}
            initialView="dayGridMonth"
            dayMaxEvents={true}
            weekends={true}
            events={events} // replaced initialEvents with events prop to update calendar content
            // Update selectedRange when the view changes
            datesSet={(arg) => {
              setSelectedRange({
                start: arg.view.currentStart,
                end: arg.view.currentEnd,
              });
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default List;

const locale = {
  expenses: 'Расходы',
  expense: 'Расход',
  expenseConversions: 'Конвертации Расходов',
  title: 'Расходы',
  create: 'Создать Расход',
  delete: 'Удалить Расход',
  update: 'Обновить Расход',
  deletedSuccessfully: 'Расход успешно удален.',
  createSuccess: 'Расход успешно создан.',
  updateSuccess: 'Расход успешно обновлен.',
  deleteConfirm: 'Вы уверены, что хотите удалить этот расход? Это действие нельзя отменить.',
  emptyState1: 'Расходы еще не добавлены.',
  emptyState2:
    'Храните и управляйте всеми деталями расходов здесь, чтобы упростить выставление счетов и поддерживать четкие соглашения с вашими клиентами.',
  downloadFormSuccessfully: 'Список расходов успешно скачан.',  
  importExpense: 'Импортировать Расходы',
  importTitle: 'Импортировать Расходы',
  importText1: 'Вы можете импортировать свои данные о расходах из файла Excel.',
  importText2: 'Нажмите на кнопку Скачать пример, чтобы скачать шаблон.',
  
  // labels
  id: "ID Расхода",
  totalAmount: "Общая Сумма",
  totalTax: "Общий Налог",
  isBillable: "Подлежит Оплате",
  isInvoiced: "Выставлен Счет",

  // detailDrawer
  changeStatus: 'Изменить Статус',
  generalInfoTitle: 'Общая Информация',
  customerInfoTitle: 'Информация о Клиенте',
  expenseInfoTitle: 'Информация о Расходе',
  projectInfoTitle: 'Информация о Проекте',
  projectName: 'Название Проекта',
  paymentMethod: 'Способ Оплаты',
};

export default locale; 
const locale = {
  expenses: 'Dépenses',
  expense: 'Dépense',
  expenseConversions: 'Conversions de Dépenses',
  title: 'Dépenses',
  create: 'Créer une Dépense',
  delete: 'Supprimer la Dépense',
  update: 'Mettre à jour la Dépense',
  deletedSuccessfully: 'Dépense supprimée avec succès.',
  createSuccess: 'Dépense créée avec succès.',
  updateSuccess: 'Dépense mise à jour avec succès.',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer cette dépense ? Cette action ne peut pas être annulée.',
  emptyState1: 'Aucune dépense ajoutée pour le moment.',
  emptyState2:
    'Stockez et gérez tous les détails des dépenses ici pour simplifier votre facturation et maintenir des accords clairs avec vos clients.',
  downloadFormSuccessfully: 'Liste des dépenses téléchargée avec succès.',
  importExpense: 'Importer les dépenses',
  importTitle: 'Importer les dépenses',
  importText1: 'Vous pouvez importer vos données de dépenses depuis un fichier Excel.',
  importText2: 'Cliquez sur le bouton Télécharger l\'exemple pour télécharger le modèle.',
  // labels
  id: "ID de Dépense",
  totalAmount: "Montant Total",
  totalTax: "Taxe Totale",
  isBillable: "Est Facturable",
  isInvoiced: "Est Facturé",

  // detailDrawer
  changeStatus: 'Changer le Statut',
  generalInfoTitle: 'Informations Générales',
  customerInfoTitle: 'Informations Client',
  expenseInfoTitle: 'Informations sur la Dépense',
  projectInfoTitle: 'Informations sur le Projet',
  projectName: 'Nom du Projet',
  paymentMethod: 'Méthode de Paiement',
};

export default locale; 
import React from 'react';
import { Stack, InputLabel, TextField, Autocomplete } from '@mui/material';
import { FileExtension } from 'corede-common';
import { DocumentFolder, IFileListInput } from 'corede-common-cocrm';
import { t } from 'i18next';
import { generateAutocompleteTranslatedOptions } from 'localization';
import { debounce } from 'lodash';

const FilesFilter = (props: {
  fileListFilter: IFileListInput;
  setFileListFilter: (fileListFilter: IFileListInput) => void;
  hideTarget?: boolean;
}) => {
  return (
    <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={2}>
      <Stack direction={'column'} width={'100%'}>
        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
          {t('disk.document.document.search')}
        </InputLabel>
        <TextField
          size="small"
          placeholder={t('disk.document.document.search')}
          onChange={debounce((e) => {
            props.setFileListFilter({
              filter: {
                ...props.fileListFilter.filter,
                customName: e.target.value,
              },
              pagination: {
                page: 1,
                pageSize: 12,
              },
            });
          }, 300)}
        />
      </Stack>
      {!props.hideTarget && (
        <Stack direction={'column'} width={'100%'}>
          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
            {t('disk.document.document.target')}
          </InputLabel>
          <Autocomplete
            noOptionsText={t('noOptionsText')}
            size="small"
            disablePortal
            id="entityModel"
            options={generateAutocompleteTranslatedOptions(Object.values(DocumentFolder)).map(
              (option) => ({ label: option.name, id: option._id }),
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={t('disk.document.document.target')}
              />
            )}
            onChange={(e, value) => {
              props.setFileListFilter({
                filter: {
                  ...props.fileListFilter.filter,
                  folder: value ? (value.id as DocumentFolder) : undefined,
                },
                pagination: {
                  ...props.fileListFilter.pagination,
                },
              });
            }}
          />
        </Stack>
      )}
      <Stack direction={'column'} width={'100%'}>
        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
          {t('disk.document.document.fileType')}
        </InputLabel>
        <Autocomplete
          noOptionsText={t('noOptionsText')}
          size="small"
          disablePortal
          id="fileType"
          options={generateAutocompleteTranslatedOptions(Object.values(FileExtension)).map(
            (option) => ({ label: option.name, id: option._id }),
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder={t('disk.document.document.fileType')}
            />
          )}
          onChange={(e, value) => {
            props.setFileListFilter({
              filter: {
                ...props.fileListFilter.filter,
                extensions: value ? [value.id as FileExtension] : undefined,
              },
              pagination: {
                ...props.fileListFilter.pagination,
              },
            });
          }}
        />
      </Stack>
      <Stack direction={'column'} width={'100%'}>
        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
          {t('disk.document.document.tags')}
        </InputLabel>
        <TextField
          size="small"
          placeholder={t('disk.document.document.tags')}
          onChange={debounce((e) => {
            props.setFileListFilter({
              filter: {
                ...props.fileListFilter.filter,
                customName: e.target.value,
              },
              pagination: {
                page: 1,
                pageSize: 12,
              },
            });
          }, 300)}
        />
      </Stack>
    </Stack>
  );
};

export default FilesFilter;

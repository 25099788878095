/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import { IBaseError, IGraphqlVariables, PermissionAction } from 'corede-common';
import {
  IContractTemplate,
  IContractTemplateListInput,
  PermissionSubject,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import {
  useContractTemplateListQuery,
  useContractTemplateUpdateMutation,
} from '../../context/contractTemplate.api';
import { ContractTemplateDeleteDialog } from '../ContractTemplateDeleteDialog';
import ContractTemplateUpdateDrawerWrapper from '../update/ContractTemplateUpdateDrawer.wrapper';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import { IPendingNavigation } from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representHtmlAsString } from 'utils/representationHelper';
import ContractTemplateDetailDrawer from '../detailDrawer/ContractTemplateDetailDrawer.wrapper';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export interface IContractTemplateListGridComponent {
  // contractTemplate

  setOpenContractTemplateCreateDrawer?: (value: boolean) => void | undefined;
}

const ContractTemplateListGridComponent = memo((props: IContractTemplateListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedContractTemplate, setSelectedContractTemplate] =
    useState<TListGridSelectedEntity<IContractTemplate>>();
  const [openContractTemplateDetailDrawer, setOpenContractTemplateDetailDrawer] = useState(false);
  const [openContractTemplateDeleteDialog, setOpenContractTemplateDeleteDialog] = useState(false);
  const [openContractTemplateUpdateDrawer, setOpenContractTemplateUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.contractTemplate,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.contractTemplate,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.contractTemplate,
    action: PermissionAction.delete,
  });

  const [contractTemplateListFilter, setContractTemplateListFilter] = useState<
    IGraphqlVariables<IContractTemplateListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = contractTemplateListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = contractTemplateListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: contractTemplateListData,
    isLoading: contractTemplateListLoading,
    isFetching: contractTemplateListFetching,
    error: contractTemplateListError,
    refetch,
  } = useContractTemplateListQuery(contractTemplateListFilter);

  const { data: contractTemplateListCountData } = useContractTemplateListQuery({});

  // used for direct update in the list rows
  const [
    contractTemplateUpdate,
    { isLoading: contractTemplateUpdateLoading, error: contractTemplateUpdateError },
  ] = useContractTemplateUpdateMutation();

  // effects

  useEffect(() => {
    refetch();
  }, [contractTemplateListFilter]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(contractTemplateListError as IBaseError, currentLanguage);
  }, [contractTemplateListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(contractTemplateUpdateError as IBaseError, currentLanguage);
  }, [contractTemplateUpdateError]);

  const isEmptyState =
    contractTemplateListData?.data?.length === 0 &&
    !contractTemplateListLoading &&
    !contractTemplateListFetching &&
    contractTemplateListCountData?.count === 0;

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedContractTemplate}
      setSelectedEntity={setSelectedContractTemplate}
      listFilter={contractTemplateListFilter}
      setListFilter={setContractTemplateListFilter}
      listData={contractTemplateListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
        {contractTemplateListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                contractTemplateListData?.data?.map((contractTemplate, index) => ({
                  id:
                    ((contractTemplateListFilter.input?.pagination?.page || 1) - 1) *
                      (contractTemplateListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: contractTemplate._id,
                  title: contractTemplate.title,
                  description: representHtmlAsString({
                    data: contractTemplate.description,
                    trimLength: 100,
                  }),
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: 'center',
                    },
                    headerConfig: {
                      name: '#',
                      align: 'center',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'title',
                      filterable: true,
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      name: t('crm.title'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'description',
                      filterable: true,
                      editable: hasUpdatePermission,
                      width: 300,
                    },
                    headerConfig: {
                      name: t('crm.description'),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: hasDetailPermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedContractTemplate,
                          setOpenAction: setOpenContractTemplateDetailDrawer,
                        },
                      }
                    : undefined,
                  edit: hasUpdatePermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedContractTemplate,
                          setOpenAction: setOpenContractTemplateUpdateDrawer,
                        },
                      }
                    : undefined,
                  delete: hasDeletePermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedContractTemplate,
                          setOpenAction: setOpenContractTemplateDeleteDialog,
                        },
                      }
                    : undefined,
                },
                customCellItems: [],
                actionHeaderName: t('crm.actions'),
              }}
              loading={
                contractTemplateListLoading ||
                contractTemplateUpdateLoading ||
                contractTemplateListFetching
              }
              listFilter={{
                filterInput: contractTemplateListFilter,
                setFilterInput: setContractTemplateListFilter,
              }}
              update={{
                updateQuery: hasUpdatePermission ? contractTemplateUpdate : undefined,
              }}
              count={contractTemplateListData?.count ?? 0}
              config={{
                columnVisibilityModel: {},
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.contract.contractTemplate.emptyState1')}
              content2={t('crm.contract.contractTemplate.emptyState2')}
              button1={{
                title: t('crm.contract.contractTemplate.create'),
                onClick: () => (props?.setOpenContractTemplateCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
                permission: {
                  subject: PermissionSubject.contractTemplate,
                  action: PermissionAction.create,
                },
              }}
            />
          )
        ) : null}

      <PermissionWrapper
        check={{
          subject: PermissionSubject.contractTemplate,
          action: PermissionAction.detail,
        }}
      >
        <ContractTemplateDetailDrawer
          open={openContractTemplateDetailDrawer}
          contractTemplate={selectedContractTemplate}
          setOpen={setOpenContractTemplateDetailDrawer}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.contractTemplate,
          action: PermissionAction.update,
        }}
      >
        <ContractTemplateUpdateDrawerWrapper
          open={openContractTemplateUpdateDrawer}
          setOpen={setOpenContractTemplateUpdateDrawer}
          selectedContractTemplate={selectedContractTemplate}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.contractTemplate,
          action: PermissionAction.delete,
        }}
      >
        <ContractTemplateDeleteDialog
          open={openContractTemplateDeleteDialog}
          contractTemplateId={selectedContractTemplate?._id ?? ''}
          setContractTemplate={setSelectedContractTemplate}
          onClose={() => {
            setSelectedContractTemplate(undefined);
            setOpenContractTemplateDeleteDialog(false);
          }}
          key={selectedContractTemplate?._id}
        />
      </PermissionWrapper>
    </DefaultListGridComponent>
  );
});

export default ContractTemplateListGridComponent;

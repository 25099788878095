const locale = {
  estimate: 'Presupuesto',
  title: 'Presupuestos',
  create: 'Crear Presupuesto',
  update: 'Actualizar Presupuesto',
  delete: 'Eliminar',
  view: 'Ver Presupuesto',
  list: 'Listar Presupuestos',
  detail: 'Detalle del Presupuesto',
  createdSuccessfully: 'Presupuesto creado con éxito',
  updatedSuccessfully: 'Presupuesto actualizado con éxito',
  deleteConfirm: '¿Está seguro de que desea eliminar? Esta acción no se puede deshacer.',
  deletedSuccessfully: 'Presupuesto eliminado con éxito',
  createInfo: 'Puede crear nuevos presupuestos aquí.',
  prefix: 'Prefijo',
  timeZone: 'Zona Horaria',
  prospectTargetEntityId: 'Cliente Potencial',
  prospectTargetEntityRef: 'Ref. Cliente Potencial',
  prospectType: 'Tipo de Cliente Potencial',
  prospectName: 'Nombre del Cliente Potencial',
  prospectVAT: 'NIF/CIF del Cliente Potencial',
  prospectPhoneNumber: 'Teléfono del Cliente Potencial',
  prospectCountry: 'País del Cliente Potencial',
  prospectState: 'Provincia del Cliente Potencial',
  prospectCity: 'Ciudad del Cliente Potencial',
  prospectZIP: 'Código Postal del Cliente Potencial',
  prospectAddress: 'Dirección del Cliente Potencial',
  prospectContactFirstName: 'Nombre del Contacto del Cliente Potencial',
  prospectContactLastName: 'Apellido del Contacto del Cliente Potencial',
  prospectContactEmail: 'Email del Contacto del Cliente Potencial',
  language: 'Idioma',
  date: 'Fecha',
  expireDate: 'Fecha de Vencimiento',
  sendEmailOnExpiration: 'Enviar Email',
  expirationEmailSentDate: 'Fecha de Envío del Email de Vencimiento',
  projectOrServiceDesc: 'Descripción del Proyecto o Servicio',
  paymentTerms: 'Condiciones de Pago',
  products: 'Productos',
  addProducts: 'Añadir Productos',
  offers: 'Ofertas',
  salesAgentIds: 'Agentes de Ventas',
  allowComments: 'Permitir Comentarios',
  legalNDAId: 'NDA',
  legalTermAndCondId: 'Términos y Condiciones',
  signerUserId: 'Usuario Firmante',
  listInfo: 'Lista de todos los presupuestos.',
  importedError: 'Error al importar',
  importedSuccessfully: 'Importado con éxito',
  downloadFormError: 'Error al descargar el formulario',
  downloadFormSuccessfully: 'Formulario descargado con éxito',
  downloadExampleFormSuccessfully: 'Formulario de ejemplo descargado con éxito',
  downloadExampleFormError: 'Error al descargar el formulario de ejemplo',
  emptyState1: 'No se encontraron presupuestos.',
  emptyState2: 'Puede crear o importar presupuestos inmediatamente.',
  createEstimate: 'Crear Presupuesto',
  importEstimate: 'Importar Presupuesto',
  importTitle: 'Importar Presupuestos',
  downloadExample: 'Descargar Ejemplo',
  importText1: 'Puede importar presupuestos desde esta página.',
  importText2: 'Puede utilizar el formulario de ejemplo a continuación para publicar sus presupuestos.',
  importExport: 'Importar/Exportar',
  exportData: 'Exportar Datos',
  importData: 'Importar Datos',
  b2b: 'B2B',
  b2c: 'B2C',
  viewEstimate: 'Ver',
  edit: 'Editar',
  junk: 'Descartar',
  unJunk: 'Recuperar',
  addNewRole: 'Añadir Nuevo Rol',
  actions: 'Acciones',
  legalAgreements: 'Acuerdos Legales',
  businessName: 'Nombre de la Empresa',
  governmentAgency: 'Organismo Gubernamental',
  fullName: 'Nombre Completo',
  VAT: 'NIF/CIF',
  phoneNumber: 'Número de Teléfono',
  country: 'País',
  state: 'Provincia',
  city: 'Ciudad',
  ZIP: 'Código Postal',
  sentDate: 'Fecha de Envío',
  name: 'Nombre',
  description: 'Descripción',
  productUnit: 'Unidad',
  priceType: 'Tipo de Precio',
  service: 'Servicio',
  product: 'Producto',
  services: 'Servicios',
  references: 'Referencias',
  add: 'Añadir',
  salesAgent: 'Agente de Ventas',
  estimateCreateSuccess: 'Presupuesto creado con éxito',

  addEstimate: 'Añadir Presupuesto',
  noEstimateContent1: 'No se encontraron presupuestos',
  noEstimate: 'No hay presupuestos',
  generalInfo: 'Información General',
  sentSuccessfully: 'Presupuesto enviado con éxito',
  send: 'Enviar Presupuesto',
  resend: 'Reenviar Presupuesto',
  sendSureTitle: 'Enviar Presupuesto',
  sendSureDesc: '¿Está seguro de que desea enviar este presupuesto?',
  changeStatus: 'Cambiar Estado',
  statusUpdatedSuccessfully: 'Estado actualizado con éxito',

  // generalInfo
  generalInfoTitle: 'Información General',
  estimateId: 'Presupuesto',
  status: 'Estado',
  createdAt: 'Creado el',
  reference: 'Referencia',
  createdBy: 'Creado por',
  updatedAt: 'Actualizado el',
  notAvailable: 'No Disponible',
  updateStatus: 'Actualizar Estado',

  // customerInfo
  customerInfoTitle: 'Información del Cliente',
  contactFirstName: 'Nombre',
  contactLastName: 'Apellido',
  contactEmail: 'Email',
  contactPhoneNumber: 'Número de Teléfono',
  address: 'Dirección',

  //productInfo
  price: 'Precio',
  productInfoTitle: 'Detalles del Producto',
  productNameUnknown: 'Producto Desconocido',
  discountedPrice: 'Precio con Descuento',
  quantity: 'Cantidad',
  finalPrice: 'Precio Final',
  tax: 'Tasa de Impuesto',
  noProducts: 'No hay productos disponibles.',

  //legalInfo
  legalInfoTitle: 'Información Legal',
  ndaTitle: 'Acuerdo de Confidencialidad (NDA)',
  termsTitle: 'Términos y Condiciones',
  documentName: 'Nombre del Documento',
  documentDescription: 'Descripción',
  documentContent: 'Contenido',

  //financialInfo
  financialInfoTitle: 'Información Financiera',
  currency: 'Moneda',
  subTotal: 'Subtotal',
  discount: 'Descuento',
  taxTotal: 'Total de Impuestos',
  shippingTotal: 'Total de Envío',
  total: 'Precio Total',
  noFinancialData: 'No hay datos financieros disponibles.',

  // documentInfo
  documentsTitle: 'Documentos',
  documentNameUnknown: 'Documento Desconocido',
  mimeType: 'Tipo de Archivo',
  download: 'Descargar',
  noDocuments: 'No hay documentos disponibles.',

  // salesAgents
  salesAgentsTitle: 'Agentes de Ventas',
  unknownAgent: 'Agente Desconocido',
  noSalesAgents: 'No hay agentes de ventas disponibles.',

  //signature
  signatureTitle: 'Información de Firma',
  additionalInfoTitle: 'Información Adicional',
  signature: 'Firma',
  signerUser: 'Usuario Firmante',

  //additionalInfo
  scopeOfWork: 'Alcance del Trabajo',
  assumptionsAndExclusions: 'Suposiciones y Exclusiones',
  useLegalNDA: 'Usar NDA Legal',
  useLegalTermAndCond: 'Usar Términos y Cond.',
  yes: 'Sí',
  no: 'No',

  //tabs
  generalInformation: 'Información General*',
  prospect: 'Cliente Potencial*',
  detailedInformation: 'Información Detallada',
  productsAndServices: 'Productos y Servicios*',
  salesAgents: 'Agentes de Ventas',
  legal: 'Legal',
  signatureTab: 'Firma*',
};

export default locale; 
import React from 'react';
import { Stack, Typography, Tooltip, IconButton } from '@mui/material';
import { useUserListByRelatedQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import AvatarGroupBase from 'components/avatar/AvatarGroupBase';
import { IBaseError, PermissionAction } from 'corede-common';
import {
  ITaskDetailResult,
  IUser,
  PermissionSubject,
  UserRelatedTargetType,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useTaskUpdateMutation } from '../../context/task.api';
import { Add, Edit } from '@mui/icons-material';
import { AddAssigneeDialog } from './TaskDetailDrawer-rightPanel-infoSection-asignees-add.component';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import { EditAssigneeDialog } from './TaskDetailDrawer-rightPanel-infoSection-asignees-edit.component';

export const DetailDrawerAsigneesComponent = (props: {
  taskDetailData: ITaskDetailResult | undefined;
}) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [openAssigneeAddDialog, setOpenAssigneeAddDialog] = useState(false);
  const [openAssigneeEditDialog, setOpenAssigneeEditDialog] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState<IUser | undefined>(undefined);

  const { data: userListData } = useUserListByRelatedQuery({
    input: {
      filter: {
        relatedTargetType: UserRelatedTargetType.Task,
      },
    },
  });

  const [
    taskUpdate,
    { data: taskUpdateData, isLoading: taskUpdateLoading, error: taskUpdateError },
  ] = useTaskUpdateMutation();

  const filteredUserList = useMemo(() => {
    if (!props.taskDetailData?.assignees) return [];
    return userListData?.data?.filter((user) => {
      return !props.taskDetailData?.assignees?.find(
        (assignedUser) => assignedUser._id === user._id,
      );
    });
  }, [props.taskDetailData?.assignees, userListData?.data]);

  const handleAddAssignee = async () => {
    if (!selectedAssignee) return;
    await taskUpdate({
      input: {
        assigneeIds: [
          ...(props.taskDetailData?.assignees?.map((item) => item._id) ?? []),
          selectedAssignee._id,
        ],
      },
      filter: {
        _id: props.taskDetailData?._id ?? '',
      },
    });
  };

  useEffect(() => {
    if (taskUpdateData) {
      setSelectedAssignee(undefined);
      setOpenAssigneeAddDialog(false);
      enqueueSnackbar(t('crm.task.task.updateSuccess'), { variant: 'success' });
    }
  }, [taskUpdateData]);

  useEffect(() => {
    if (taskUpdateError) {
      DefaultErrorHandlerUseEffect(taskUpdateError as IBaseError, currentLanguage);
    }
  }, [taskUpdateError]);

  return (
    <Stack direction={'column'} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: 'relative' }}>
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        <Typography sx={{ fontWeight: 600, fontSize: '16px', color: 'text.primary' }}>
          {t('crm.assignees')}
        </Typography>
        <IconButton
          sx={{
            border: '2px solid',
            borderRadius: '10px',
            borderColor: 'primary.light',
            width: 25,
            height: 25,
            ml: 1,
            p: 1.5,
            color: 'primary.main',
          }}
          onClick={() => setOpenAssigneeEditDialog(true)}
        >
          <Edit fontSize="small" />
        </IconButton>
      </Stack>
      <Stack direction={'row'} mt={1} justifyContent={'flex-start'}>
        <AvatarGroupBase userList={props.taskDetailData?.assignees as IUser[]} max={10} />
        <PermissionWrapper
          check={{
            subject: PermissionSubject.task,
            action: PermissionAction.update,
          }}
        >
          <Tooltip title={t('crm.addAssignee')}>
            <IconButton
              sx={{
                border: '2px solid',
                borderColor: 'primary.light',
                width: 35,
                height: 35,
                ml: 1,
                color: 'primary.main',
              }}
              onClick={() => setOpenAssigneeAddDialog(true)}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </PermissionWrapper>
      </Stack>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.task,
          action: PermissionAction.update,
        }}
      >
        <AddAssigneeDialog
          openAssigneeAddDialog={openAssigneeAddDialog}
          setOpenAssigneeAddDialog={setOpenAssigneeAddDialog}
          filteredUserList={filteredUserList as IUser[]}
          selectedAssignee={selectedAssignee}
          setSelectedAssignee={setSelectedAssignee}
          taskUpdateLoading={taskUpdateLoading}
          handleAddAssignee={handleAddAssignee}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.task,
          action: PermissionAction.update,
        }}
      >
        <EditAssigneeDialog
          openAssigneeAddDialog={openAssigneeEditDialog}
          setOpenAssigneeAddDialog={setOpenAssigneeEditDialog}
          assignedUserList={props.taskDetailData?.assignees as IUser[]}
          taskUpdateLoading={taskUpdateLoading}
          handleDeleteAssignee={async (user: IUser) => {
            await taskUpdate({
              input: {
                assigneeIds: props.taskDetailData?.assignees
                  ?.filter((item) => item._id !== user._id)
                  ?.map((item) => item._id) as string[],
              },
              filter: {
                _id: props.taskDetailData?._id ?? '',
              },
            });
          }}
        />
      </PermissionWrapper>
    </Stack>
  );
};

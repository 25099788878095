import dashboardLocale from '../domains/01-dashboard/locales/es';
import productAndServiceLocale from '../domains/08-productAndService/locales/es';
import organizationalChartLocale from '../domains/02-organizationalChart/locales/es';
import leadLocale from '../domains/03-lead/locales/es';
import customerLocale from '../domains/04-customer/locales/es';
import preSaleLocale from '../domains/05-preSale/locales/es';
import saleLocale from '../domains/06-sale/locales/es';
import pricingLocale from '../domains/16-pricing/locales/es';
import calendarLocale from '../domains/12-calendar/locales/es';
import contractLocale from '../domains/07-contract/locales/es';
import supportLocale from '../domains/15-support/locales/es';
import reportLocale from '../domains/13-report/locales/es';
import taskLocale from '../domains/11-task/locales/es';
import projectLocale from '../domains/10-project/locales/es';
import expenseLocale from '../domains/09-expense/locales/es';
import noteLocale from '../domains/14-note/locales/es';

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  productAndService: {
    ...productAndServiceLocale,
  },
  customer: {
    ...customerLocale,
  },
  preSale: {
    ...preSaleLocale,
  },
  sale: {
    ...saleLocale,
  },
  pricing: {
    ...pricingLocale,
  },
  calendar: {
    ...calendarLocale,
  },
  contract: {
    ...contractLocale,
  },
  support: {
    ...supportLocale,
  },
  report: {
    ...reportLocale,
  },
  task: {
    ...taskLocale,
  },
  project: {
    ...projectLocale,
  },
  expense: {
    ...expenseLocale,
  },
  note: {
    ...noteLocale,
  },

  ////////////////////////////////////////////////////////////////
  // common used names
  ////////////////////////////////////////////////////////////////
  preSaleLabel: 'Pre-Venta',
  preSales: 'Pre-Ventas',
  contactDetails: 'Detalles',
  localization: 'Localización',
  info: 'Información',
  about: 'Acerca de',
  contact: 'Contacto',
  record: 'Registro',
  records: 'Registros',
  tracking: 'Seguimiento',
  pricingLabel: 'Precios',
  dashboardLabel: 'Panel',
  marketing: 'Marketing',
  campaigns: 'Campañas',
  emails: 'Correos',
  sms: 'SMS',
  socialMedia: 'Redes Sociales',
  salesLabel: 'Ventas',
  businessIntelligence: 'Inteligencia de Negocios',
  calendarLabel: 'Calendario',
  supportLabel: 'Ticket',
  accountMenu: 'Menú de Cuenta',
  profile: 'Perfil',
  myAccount: 'Mi Cuenta',
  logout: 'Cerrar Sesión',
  searchPlaceholder: 'Intenta buscar "insights"',
  searchAnIcon: 'Buscar Icono',

  // --------------------
  // settings related
  // --------------------
  settings: 'Configuración',
  english: 'Inglés',
  turkish: 'Turco',
  french: 'Francés',
  german: 'Alemán',
  italian: 'Italiano',
  portuguese: 'Portugués',
  russian: 'Ruso',
  spanish: 'Español',
  dutch: 'Holandés',
  darkMode: 'Modo Oscuro',
  lightMode: 'Modo Claro',
  languageSelectLabel: 'Seleccionar Idioma',

  ////////////////////////////////////////////////////////////////
  // common action texts
  ////////////////////////////////////////////////////////////////
  actions: 'Acciones',
  yes: 'Sí',
  no: 'No',
  importExport: 'Importar/Exportar',
  view: 'Ver',
  list: 'Lista',
  create: 'Crear',
  update: 'Actualizar',
  delete: 'Eliminar',
  edit: 'Editar',
  cancel: 'Cancelar',
  downloadSuccessful: 'Descargado con éxito',
  export: 'Exportar',
  import: 'Importar',
  exportData: 'Exportar Datos',
  importData: 'Importar Datos',
  showMore: 'Mostrar Más',
  selectColor: 'Seleccionar Color',
  justForMe: 'Solo Para Mí',

  ////////////////////////////////////////////////////////////////
  // common situational texts
  ////////////////////////////////////////////////////////////////
  total: 'Total',
  weeklyTotal: 'Total Semanal',
  hasCreatedAt: 'ha sido creado en ',
  createdBy: 'Creado por ',
  updatedBy: 'Actualizado por ',
  uploadLogo: 'Subir Logo',
  selectIconTitle: 'Seleccionar un Icono',
  searchIcons: 'Buscar Iconos',
  uploadPictureText: 'Subir una imagen',
  searchForAnIcon: 'Buscar un Icono',
  selectedIcon: 'Icono Seleccionado:',
  signedUser: 'Usuario Firmado',

  // --------------------
  // delete dialog
  // --------------------
  deletedEntitySuccessfully: 'Eliminado con éxito',
  deleteEntityContent: '¿Estás seguro de que quieres eliminar? Esta acción no se puede deshacer.',
  deleteEntityTitle: 'Eliminar',

  // --------------------
  // organization
  // --------------------
  mustCreateOrganizationTitle: 'Crear Organización',
  mustCreateOrganizationContent: 'Debes crear una organización para usar el panel.',
  mustCreateOrganizationSuccess: 'Organización creada con éxito.',

  // --------------------
  // import export
  // --------------------
  importedError: 'Error de importación',
  importedSuccessfully: 'Importado con éxito',
  downloadExample: 'Descargar Ejemplo',
  downloadFormError: 'Error al descargar el formulario',
  downloadFormSuccessfully: 'Formulario descargado con éxito',
  downloadExampleFormSuccessfully: 'Formulario de ejemplo descargado con éxito',
  downloadExampleFormError: 'Error al descargar el formulario de ejemplo',

  // --------------------
  // estimate
  // --------------------
  addEstimate: 'Añadir Estimación',
  noEstimate: 'No hay estimaciones',
  noEstimateContent1: 'No se encontraron estimaciones',
  additionalInfo: 'Información Adicional',

  // --------------------
  // proposal
  // --------------------
  addProposal: 'Añadir Propuesta',
  noProposal: 'No hay propuestas',
  lastProposal: 'Última Propuesta',
  proposalSend: 'Propuesta Enviada',
  noProposalSend: 'No hay propuestas enviadas',
  noProposalContent1: 'No se encontraron propuestas',
  sendEmailOnExpiration: 'Enviar correo al vencimiento',
  testimonialCaseStudies: 'Casos de Estudio Testimoniales',
  projectTimeline: 'Cronograma del Proyecto',
  executiveSummary: 'Resumen Ejecutivo',
  costBreakdown: 'Desglose de Costos',
  conclusion: 'Conclusión',
  projectorServiceDesc: 'Descripción Detallada del Proyecto/Servicio',
  noSalentAgents: 'No hay agentes de ventas',

  // --------------------
  // document
  // --------------------
  addDocument: 'Añadir Documento',
  noDocument: 'No hay documentos',
  noDocumentContent1: 'No se encontraron documentos',

  // --------------------
  // task
  // --------------------
  noTask: 'No hay tareas',
  noTaskContent1: 'No se encontraron tareas',

  // --------------------
  // activity
  // --------------------
  noActivity: 'No hay actividades',
  addActivity: 'Añadir Actividad',
  noActivityContent1: 'No se encontraron actividades',
  noActivityContent2: 'No hay actividades para esta entidad',

  // --------------------
  // note
  // --------------------
  addNote: 'Añadir Nota',
  noNote: 'No hay notas',
  emptyStateNoteList1: 'No se encontraron notas',
  noteDate: 'Fecha de Nota',
  noteUsers: 'Usuarios de Nota',
  noteTitle: 'Título',

  // --------------------
  // reminder
  // --------------------
  addReminder: 'Añadir Recordatorio',
  noReminder: 'No hay recordatorios',
  emptyStateReminderList1: 'No se encontraron recordatorios',
  remindDate: 'Fecha de Recordatorio',
  remindUsers: 'Recordar a Usuarios',
  remindTitle: 'Título',

  // --------------------
  // appointment
  // --------------------
  noAppointments: 'No hay citas',
  makeAnAppointment: 'Hacer una Cita',

  // --------------------
  // assignee
  // --------------------
  assignees: 'Asignados',
  noAssignees: 'Sin Asignados',
  addAssignee: 'Añadir Asignado',

  // --------------------
  // followers
  // --------------------
  followers: 'Seguidores',
  noFollowers: 'Sin Seguidores',

  // --------------------
  // product and services
  // --------------------
  addProduct: 'Añadir Producto',
  addProductOrService: 'Añadir Producto o Servicio',
  editProdutctOrService: 'Editar Producto o Servicio',
  productType: 'Tipo de Producto',
  editProduct: 'Editar Producto',
  product: 'Producto',

  // --------------------
  // ticket
  // --------------------
  subject: 'Asunto',
  contactName: 'Nombre de Contacto',
  tickets: 'Tickets',

  ////////////////////////////////////////////////////////////////
  // common used words
  ////////////////////////////////////////////////////////////////
  of: 'de',

  ////////////////////////////////////////////////////////////////
  // common used entity names
  ////////////////////////////////////////////////////////////////
  leadLabel: 'Lead',
  leads: 'Leads',
  leadForms: 'Formularios de Lead',
  leadStatuses: 'Estados de Lead',
  leadSources: 'Fuentes de Lead',
  customerLabel: 'Cliente',
  customers: 'Clientes',
  estimateLabel: 'Estimación',
  estimates: 'Estimaciones',
  proposalLabel: 'Propuesta',
  proposals: 'Propuestas',
  invoiceLabel: 'Factura',
  invoices: 'Facturas',
  productsServices: 'Productos y Servicios',
  activityLabel: 'Actividad',
  activities: 'Actividades',
  noteLabel: 'Nota',
  notes: 'Notas',
  documentLabel: 'Documento',
  documents: 'Documentos',
  appointmentLabel: 'Cita',
  appointments: 'Citas',
  reminderLabel: 'Recordatorios',
  reminders: 'Recordatorios',
  departmentLabel: 'Departamento',
  departments: 'Departamentos',
  contractLabel: 'Contrato',
  contractTemplate: 'Plantilla de Contrato',
  contracts: 'Contratos',
  paymentLabel: 'Pago',
  payments: 'Pagos',
  organizationalChartLabel: 'Organigrama',
  expenseLabel: 'Gasto',
  expenses: 'Gastos',
  productAndServiceLabel: 'Productos y Servicios',
  projectLabel: 'Proyecto',
  projects: 'Proyectos',
  taskLabel: 'Tarea',
  tasks: 'Tareas',
  reportLabel: 'Informe',
  reports: 'Informes',
  billingInformation: 'Información de Facturación',
  billingInfo: 'Info de Facturación',
  otherBillingInformation: 'Otra Información de Facturación',
  shippingInfo: 'Info de Envío',
  contactInformation: 'Información de Contacto',
  otherContactInformation: 'Otra Información de Contacto',
  warningBillingInfo: 'Una vez que se completa un campo, todos los campos deben completarse.',
  addRole: 'Añadir Rol',

  ////////////////////////////////////////////////////////////////
  // common fields
  ////////////////////////////////////////////////////////////////
  color: 'Color',
  icon: 'Icono',
  index: 'Índice',
  title: 'Título',
  fullName: 'Nombre Completo',
  firstName: 'Nombre',
  lastName: 'Apellido',
  surname: 'Apellido',
  convert: 'Convertir',
  country: 'País',
  state: 'Estado',
  city: 'Ciudad',
  industry: 'Industria',
  address: 'Dirección',
  website: 'Sitio Web',
  emailAddress: 'Dirección de Correo',
  name: 'Nombre',
  companyName: 'Nombre de la Empresa',
  companyEmail: 'Correo de la Empresa',
  companyPhone: 'Teléfono de la Empresa',
  timezone: 'Zona Horaria',
  companyLogo: 'Logo de la Empresa',
  language: 'Idioma',
  department: 'Departamento',
  email: 'Correo',
  phoneNumber: 'Número de Teléfono',
  type: 'Tipo',
  createdAt: 'Creado El',
  updatedAt: 'Actualizado El',
  prefix: 'Prefijo',
  tags: 'Etiquetas',
  tagsPlaceholder: 'Etiquetas',
  addTagsPlaceholder: 'Añadir Etiquetas',
  addNewOption: 'Añadir nueva opción',
  enterText: 'Introducir texto...',
  company: 'Empresa',
  sector: 'Sector',
  phone: 'Teléfono',
  content: 'Contenido',
  priority: 'Prioridad',
  position: 'Posición',
  status: 'Estado',
  source: 'Fuente',
  date: 'Fecha',
  expireDate: 'Fecha de Vencimiento',
  totalPrice: 'Precio Total',
  currency: 'Moneda',
  category: 'Categoría',
  parentCategory: 'Categoría Padre',
  vat: 'Número de IVA',
  zip: 'Código Postal',
  street: 'Calle',
  taxOffice: 'Oficina de Impuestos',
  target: 'Objetivo',
  targetType: 'Tipo de Objetivo',
  description: 'Descripción',
  birthDate: 'Fecha de Nacimiento',
  gender: 'Género',
  isPrimary: 'Es Primario',
  prospectName: 'Nombre del Prospecto',
  prospectAddress: 'Dirección del Prospecto',
  prospectContactName: 'Nombre de Contacto del Prospecto',
  prospectType: 'Tipo de Prospecto',
  leadValue: 'Valor del Lead',
  estimateId: 'Id de Estimación',
  proposalId: 'Id de Propuesta',
  invoiceId: 'Id de Factura',
  paymentId: 'Id de Pago',
  returnId: 'Id de Devolución',
  expenseId: 'Id de Gasto',
  customerName: 'Nombre del Cliente',
  customerEmail: 'Correo del Cliente',
  customerType: 'Tipo de Cliente',
  finalPrice: 'Precio Final',
  paymentMethod: 'Método de Pago',
  confirmationNumber: 'Número de Confirmación',
  amoundPaid: 'Cantidad Pagada',
  refundAmount: 'Cantidad de Reembolso',
  isOpen: 'Está Abierto',
  resolveReason: 'Razón de Resolución',
  disapproveReason: 'Razón de Desaprobación',
  sku: 'SKU',
  priceType: 'Tipo de Precio',
  price: 'Precio',
  discountedPrice: 'Precio con Descuento',
  discount: 'Descuento',
  unit: 'Unidad',
  unitPrice: 'Precio Unitario',
  natTaxRate: 'Tasa de Impuesto Nacional',
  intTaxRate: 'Tasa de Impuesto Internacional',
  selectRoles: 'Seleccionar Roles',
  timeZone: 'Zona Horaria',
  prospect: 'Prospecto',
  prospectTargetEntityRef: 'Ref de Entidad Objetivo del Prospecto',
  prospectTarget: 'Objetivo del Prospecto',
  prospectVAT: 'IVA del Prospecto',
  prospectPhoneNumber: 'Número de Teléfono del Prospecto',
  prospectZIP: 'Código Postal del Prospecto',
  contactFirstName: 'Nombre del Contacto',
  contactLastName: 'Apellido del Contacto',
  contactEmail: 'Correo del Contacto',
  expiration: 'Vencimiento',
  detailedInformation: 'Información Detallada',
  projectOrServiceDesc: 'Descripción del Proyecto/Servicio',
  scopeOfWork: 'Alcance del Trabajo',
  assumptionsAndExclusions: 'Suposiciones y Exclusiones',
  paymentTerms: 'Términos de Pago',
  products: 'Productos',
  services: 'Servicios',
  productName: 'Nombre del Producto',
  productDescription: 'Descripción del Producto',
  productUnit: 'Unidad del Producto',
  quantity: 'Cantidad',
  tax: 'Impuesto',
  extraInformation: 'Información Extra',
  offers: 'Ofertas',
  reference: 'Referencia',
  allowComments: 'Permitir Comentarios',
  totally: 'Totalmente',
  subTotal: 'Subtotal',
  taxTotal: 'Total de Impuestos',
  extraDiscount: 'Descuento Extra',
  shippingTotal: 'Costo Total de Envío',
  salesAgents: 'Agentes de Ventas',
  legal: 'Legal',
  useLegalNDA: 'Usar NDA Legal',
  useLegalTermAndCond: 'Usar Términos y Condiciones Legales',
  signature: 'Firma',
  signerUser: 'Usuario Firmante',
  signedDate: 'Fecha de Firma',
  generalInfo: 'Información General',
  startDate: 'Fecha de Inicio',
  dueDate: 'Fecha de Vencimiento',
  endDate: 'Fecha de Finalización',
  expirationEmailSentDate: 'Fecha de Envío de Correo de Vencimiento',
  allowedPaymentMethods: 'Métodos de Pago Permitidos',
  sendEmail: 'Enviar Correo',
  identityNumber: 'Número de Identidad',
  recurringCount: 'Recuento Recurrente',
  isRecurring: 'Es Recurrente',
  recurringFrequency: 'Frecuencia Recurrente',
  recurringDueDate: 'Fecha de Vencimiento Recurrente',
  paymentID: 'ID de Pago',
  paymentPrefix: 'Prefijo de Pago',
  returnPrefix: 'Prefijo de Devolución',
  paymentDate: 'Fecha de Pago',
  amountPaid: 'Cantidad Pagada',
  invoiceID: 'ID de Factura',
  targetDetail: 'Detalle del Objetivo',
  productsAndServices: 'Productos y Servicios',
  popularSearch: 'Búsqueda Popular',
  returnReason: 'Razón de Devolución',
  partialReturn: 'Devolución Parcial',
  amount: 'Cantidad',
  relationship: 'Relación',
  relationshipType: 'Tipo de Relación',
  relatedEntity: 'Entidad Relacionada',
  relatedEstimate: 'Estimación Relacionada',
  relatedProposal: 'Propuesta Relacionada',
  relatedTask: 'Tarea Relacionada',
  relatedProject: 'Proyecto Relacionado',
  relatedLead: 'Lead Relacionado',
  relatedCustomer: 'Cliente Relacionado',
  relatedInvoice: 'Factura Relacionada',
  relatedPayment: 'Pago Relacionado',
  relatedUser: 'Usuario Relacionado',
  relatedExpense: 'Gasto Relacionado',
  relatedContract: 'Contrato Relacionado',
  order: 'Orden',

  ////////////////////////////////////////////////////////////////
  // SEARCH
  ////////////////////////////////////////////////////////////////
  leadCreate: 'Crear Lead',
  leadList: 'Listar Lead',
  leadImport: 'Importar Leads',
  customerCreate: 'Crear Cliente',
  customerList: 'Listar Cliente',
  customerImport: 'Importar Clientes',
  estimateCreate: 'Crear Estimación',
  estimateList: 'Listar Estimación',
  proposalCreate: 'Crear Propuesta',
  proposalList: 'Listar Propuesta',
  invoiceCreate: 'Crear Factura',
  invoiceList: 'Listar Factura',
  paymentCreate: 'Crear Pago',
  paymentList: 'Listar Pago',
  returnCreate: 'Crear Devolución',
  returnList: 'Listar Devolución',
  contractCreate: 'Crear Contrato',
  contractList: 'Listar Contrato',
  productCreate: 'Crear Producto',
  productList: 'Listar Producto',
  serviceList: 'Listar Servicio',
  serviceCreate: 'Crear Servicio',
  expenseCreate: 'Crear Gasto',
  expenseList: 'Listar Gasto',
  projectCreate: 'Crear Proyecto',
  projectList: 'Listar Proyecto',
  taskCreate: 'Crear Tarea',
  taskList: 'Listar Tarea',
  reminderList: 'Listar Recordatorio',
  reminderCreate: 'Crear Recordatorio',
  appointmentList: 'Listar Cita',
  appointmentCreate: 'Crear Cita',
  noteCreate: 'Crear Nota',
  noteList: 'Listar Nota',
  ticketList: 'Listar Ticket',
  ticketCreate: 'Crear Ticket',
  noResultsFound: 'No se encontraron resultados',
  trySearching: 'Intenta buscar',
  hideButton: 'Ocultar Botón',
};

export default locale; 
const locale = {
  invoice: "Счет",
  invoices: "Счета",
  create: "Создать Счет",
  update: "Обновить Счет",
  delete: "Удалить",
  view: "Просмотр Счета",
  list: "Список Счетов",
  createdSuccessfully: "Счет успешно создан",
  updatedSuccessfully: "Счет успешно обновлен",
  deleteConfirm:
    "Вы уверены, что хотите удалить? Это действие нельзя отменить.",
  deletedSuccessfully: "Счет успешно удален",
  createInfo: "Здесь вы можете создавать новые счета.",
  prefix: "Префикс",
  invoiceId: "Счет",
  timeZone: "Часовой Пояс",
  customerId: "Клиент",
  customerName: "Имя Клиента",
  customerType: "Тип Клиента",
  customerCity: "Город Клиента",
  customerCountry: "Страна Клиента",
  customerContactEmail: "Email Клиента",
  customerContactLastName: "Фамилия Клиента",
  customerContactFirstName: "Имя Клиента",
  billingInfo: "Платежная Информация",
  ["billingInfo.fullName"]: "Полное Имя",
  ["billingInfo.phone"]: "Телефон",
  ["billingInfo.address"]: "Адрес",
  ["billingInfo.country"]: "Страна",
  ["billingInfo.state"]: "Область/Регион",
  ["billingInfo.city"]: "Город",
  ["billingInfo.street"]: "Улица",
  ["billingInfo.zip"]: "Почтовый Индекс",
  ["billingInfo.vat"]: "ИНН",
  ["billingInfo.taxOffice"]: "Налоговая Инспекция",
  shippingInfo: "Информация о Доставке",
  ["shippingInfo.fullName"]: "Полное Имя",
  ["shippingInfo.phone"]: "Телефон",
  ["shippingInfo.address"]: "Адрес",
  ["shippingInfo.country"]: "Страна",
  ["shippingInfo.state"]: "Область/Регион",
  ["shippingInfo.city"]: "Город",
  ["shippingInfo.street"]: "Улица",
  ["shippingInfo.zip"]: "Почтовый Индекс",
  generalInformation: "Общая Информация*",
  prospect: "Перспектива*",
  detailedInformation: "Подробная Информация",
  billingInformation: "Платежная Информация*",
  shippingInformation: "Информация о Доставке",
  productsAndServices: "Товары и Услуги*",
  salesAgents: "Торговые Представители",
  legal: "Юридическая Информация",
  signatureTab: "Подпись*",
  isRecurring: "Повторяющийся",
  recurringFrequency: "Частота Повторения",
  recurringDueDate: "Дата Повторяющегося Платежа",
  recurringCount: "Количество Повторений",
  language: "Язык",
  date: "Дата",
  expireDate: "Дата Истечения",
  sendEmailOnExpiration: "Отправить Email при Истечении",
  expirationEmailSentDate: "Дата Отправки Email об Истечении",
  allowedPaymentMethods: "Разрешенные Способы Оплаты",
  paymentTerms: "Условия Оплаты",
  currency: "Валюта",
  products: "Товары",
  addProducts: "Добавить Товары",
  offers: "Предложения",
  reference: "Ссылка",
  salesAgentIds: "Торговые Представители",
  allowComments: "Разрешить Комментарии",
  useLegalNDA: "Использовать Соглашение о Конфиденциальности",
  legalNDAId: "Соглашение о Конфиденциальности",
  useLegalTermAndCond: "Использовать Условия и Положения",
  legalTermAndCondId: "Условия и Положения",
  signerUserId: "Подписывающий Пользователь",
  listInfo: "Список всех счетов.",
  importedError: "Ошибка импорта",
  importedSuccessfully: "Успешно импортировано",
  downloadFormError: "Ошибка загрузки формы",
  downloadFormSuccessfully: "Форма успешно загружена",
  downloadExampleFormSuccessfully: "Пример формы успешно загружен",
  downloadExampleFormError: "Ошибка загрузки примера формы",
  title: "Счета",
  emptyState1: "Счета не найдены.",
  emptyState2: "Вы можете сразу создать или импортировать счета.",
  createInvoice: "Создать Счет",
  importInvoice: "Импортировать Счет",
  importTitle: "Импортировать Счета",
  downloadExample: "Скачать Пример",
  importText1: "Вы можете импортировать счета с этой страницы.",
  importText2: "Вы можете использовать пример формы ниже для публикации ваших счетов.",
  importExport: "Импорт/Экспорт",
  exportData: "Экспорт Данных",
  importData: "Импорт Данных",
  b2b: "B2B",
  b2c: "B2C",
  viewInvoice: "Просмотр",
  edit: "Редактировать",
  junk: "Корзина",
  unJunk: "Восстановить из Корзины",
  addNewRole: "Добавить Новую Роль",
  actions: "Действия",
  allowedPaymentMethodsPlaceholder: "Добавить Способ Оплаты",
  timezone: "Часовой Пояс",
  followUp: "Отслеживание",
  lastSent: "Последняя Отправка",
  dueDate: "Срок Оплаты",
  sendEmail: "Отправить Email",
  customer: "Клиент",
  country: "Страна",
  city: "Город",
  contactFirstName: "Имя Контакта",
  contactLastName: "Фамилия Контакта",
  contactEmail: "Email Контакта",
  services: "Услуги",
  businessName: "Название Компании",
  governmentAgency: "Государственное Учреждение",
  fullName: "Полное Имя",
  VAT: "ИНН",
  phoneNumber: "Номер Телефона",
  state: "Область/Регион",
  ZIP: "Почтовый Индекс",
  address: "Адрес",
  sentDate: "Дата Отправки",
  name: "Имя",
  description: "Описание",
  productUnit: "Единица",
  quantity: "Количество",
  price: "Цена",
  priceType: "Тип Цены",
  discountedPrice: "Цена со Скидкой",
  finalPrice: "Итоговая Цена",
  tax: "Налог",
  service: "Услуга",
  subTotal: "Промежуточный Итог",
  taxTotal: "Итого Налогов",
  discount: "Скидка",
  product: "Товар",
  references: "Ссылки",
  add: "Добавить",
  salesAgent: "Торговый Представитель",
  executiveSummary: "Краткое Содержание",
  companyBackground: "История Компании",
  projectorServiceDesc: "Подробное Описание Проекта/Услуги",
  costBreakdown: "Разбивка Затрат",
  projectTimeline: "График Проекта",
  testimonialCaseStudies: "Отзывы и Примеры",
  conclusion: "Заключение",

  sentSuccessfully: 'Счет успешно отправлен',
  send: 'Отправить Счет',
  resend: 'Отправить Счет Повторно',
  sendSureTitle: 'Отправить Счет',
  sendSureDesc: 'Вы уверены, что хотите отправить этот счет?',
  changeStatus: 'Изменить Статус',
  statusUpdatedSuccessfully: 'Статус успешно обновлен',

  // generalInfo
  generalInfoTitle: 'Общая Информация',
  status: 'Статус',
  createdAt: 'Создано',
  createdBy: 'Создано',
  updatedAt: 'Обновлено',
  notAvailable: 'Недоступно',
  updateStatus: 'Обновить Статус',

  // customerInfo
  customerInfoTitle: 'Информация о Клиенте',
  contactPhoneNumber: 'Номер Телефона',

  //productInfo
  productInfoTitle: 'Детали Товара',
  productNameUnknown: 'Неизвестный Товар',
  noProducts: 'Нет доступных товаров.',

  //legalInfo
  legalInfoTitle: 'Юридическая Информация',
  ndaTitle: 'Соглашение о Конфиденциальности (NDA)',
  termsTitle: 'Условия и Положения',
  documentName: 'Название Документа',
  documentDescription: 'Описание',
  documentContent: 'Содержание',

  //financialInfo
  financialInfoTitle: 'Финансовая Информация',
  shippingTotal: 'Итого за Доставку',
  total: 'Общая Цена',
  noFinancialData: 'Нет доступных финансовых данных.',

  // documentInfo
  documentsTitle: 'Документы',
  documentNameUnknown: 'Неизвестный Документ',
  mimeType: 'Тип Файла',
  download: 'Скачать',
  noDocuments: 'Нет доступных документов.',

  // salesAgents
  salesAgentsTitle: 'Торговые Представители',
  unknownAgent: 'Неизвестный Представитель',
  noSalesAgents: 'Нет доступных торговых представителей.',

  //signature
  signatureTitle: 'Информация о Подписи',
  additionalInfoTitle: 'Дополнительная Информация',
  signature: 'Подпись',
  signerUser: 'Подписывающий Пользователь',

  //additionalInfo
  yes: 'Да',
  no: 'Нет',
};

export default locale; 
const locale = {
  readAllSuccess: 'Alle Benachrichtigungen erfolgreich gelesen!',
  title: 'Benachrichtigungen',
  markAllAsRead: 'Alle als gelesen markieren.',
  close: 'Schließen',
  noNotifications1: "Keine Benachrichtigungen",
  noNotifications2: "Sie haben noch keine Benachrichtigungen erhalten.",
  refresh: 'Aktualisieren',
};

export default locale; 
const locale = {
  returns: 'İadeler',
  return: 'İade',
  returnConversions: 'İade Dönüştürme',
  title: 'İadeler',
  create: 'İade Ekle',
  update: 'İade Güncelle',
  delete: 'İade Sil',
  deleteConfirm: 'Bu iadeyi silmek istediginizden emin misiniz?',
  createSuccess: 'İade basarıyla eklendi.',
  updateSuccess: 'İade basarıyla guncellendi.',
  deleteSuccess: 'İade silindi.',
  emptyState1: 'Henüz herhangi bir iade kaydedilmedi.',
  emptyState2:
    'Kayıtlarınızı doğru tutmak ve müşteri ihtiyaçlarını karşılamak için herhangi bir ürün veya hizmet iadesini burada yönetin ve inceleyin.',

  // detailDrawer
  generalInfoTitle: 'Genel Bilgiler',
  isOpen: 'Açık mı',
  refundAmount: 'İade Tutarı',
  resolveReason: 'Çözüm Nedeni',
  disapproveReason: 'Red Nedeni',
  returnProducts: 'İade Ürünleri',
  amount: 'Miktar',
  reason: 'Neden',
  isPartial: 'Kısmi mi',
  note: 'Not',
  statusChangeSuccess: 'Durum başarıyla değiştirildi',
  changeStatus: 'Durumu Değiştir',
};

export default locale;

/* eslint-disable react-hooks/exhaustive-deps */
import { IInvoiceReturnDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { ReturnDetailDrawerRightPanelHeaderComponent } from './ReturnDetailDrawer-rightPanel-header.component';
import { ReturnDetailDrawerRightPanelTabsComponent } from './ReturnDetailDrawer-rightPanel.tabs.component';

export interface IReturnDetailDrawerRightPanelComponentProps {
  returnDetailData: IInvoiceReturnDetailResult | undefined;
}

export const ReturnDetailDrawerRightPanelComponent = (
  props: IReturnDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <ReturnDetailDrawerRightPanelHeaderComponent returnDetailData={props.returnDetailData} />

      {/* Tabs Section */}
      <ReturnDetailDrawerRightPanelTabsComponent returnDetailData={props.returnDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};

const locale = {
  leadStatuses: "Statuts de Lead",
  title: "Statuts de Lead",
  create: "Créer un Statut de Lead",
  delete: "Supprimer un Statut de Lead",
  deleteConfirm: "Êtes-vous sûr de vouloir supprimer ce statut : ",
  deletedSuccessfully: "Statut de lead supprimé avec succès",
  createSuccess: "Statut de lead créé avec succès",
  update: "Mettre à jour le Statut de Lead",
  updateSuccess: "Statut de lead mis à jour avec succès",
  selectColor: "Sélectionner une Couleur",
  selectIcon: "Sélectionner une Icône",
};

export default locale; 
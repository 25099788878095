/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from 'react';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import {
  IProjectMilestone,
  IProjectMilestoneUpdateFilterInput,
  IProjectMilestoneUpdateInput,
} from 'corede-common-cocrm';
import { validateUpdateMilestoneInput } from '../../../validations/update.validation';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import {
  useProjectMilestoneUpdateMutation,
  useProjectDetailQuery,
} from '../../../context/project.api';
import { enqueueSnackbar } from 'notistack';
import 'react-quill/dist/quill.snow.css';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpsertMilestoneComponent } from '../UpsertMilestone.component';
import ActionDrawer from 'components/drawer/ActionDrawer';
import ActionDialog, { OverlayType } from 'components/dialog/ActionDialog';

export interface IMilestoneUpdateOverlay {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMilestone?: IProjectMilestone;
  projectId?: string;
  overlayType: OverlayType;
}

const MilestoneUpdateOverlay = memo((props: IMilestoneUpdateOverlay) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);

  // queries

  // mutations
  const [
    milestoneUpdate,
    { data: milestoneUpdateData, isLoading: milestoneUpdateLoading, error: milestoneUpdateError },
  ] = useProjectMilestoneUpdateMutation();

  // constants
  const [initialValues, setInitialValues] = useState<
    IGraphqlVariables<IProjectMilestoneUpdateInput, IProjectMilestoneUpdateFilterInput>
  >({
    filter: {
      projectId: props.projectId ?? '',
      projectMilestoneId: props.selectedMilestone?._id ?? '',
    },
    input: {
      name: props.selectedMilestone?.name,
      description: props.selectedMilestone?.description,
      dueDate: props.selectedMilestone?.dueDate,
      startDate: props.selectedMilestone?.startDate,
    },
  });

  // form setup
  const milestoneUpdateUseForm = useForm<
    IGraphqlVariables<IProjectMilestoneUpdateInput, IProjectMilestoneUpdateFilterInput>
  >({
    values: initialValues,
    resolver: yupResolver(validateUpdateMilestoneInput),
    mode: 'onChange',
  });

  const onSubmit = async (
    values: IGraphqlVariables<IProjectMilestoneUpdateInput, IProjectMilestoneUpdateFilterInput>,
  ) => {
    setLoading(true);
    const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
      input: values.input,
    });
    await milestoneUpdate({
      filter: values.filter,
      input: transformedValues,
    });
    setLoading(false);
  };

  // useEffects.success

  useEffect(() => {
    if (milestoneUpdateData) {
      enqueueSnackbar(t('crm.project.project.milestoneUpdateSuccess'), {
        variant: 'success',
      });
      milestoneUpdateUseForm.reset();
      props.setOpen(false);
    }
  }, [milestoneUpdateData, milestoneUpdateUseForm.reset]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(milestoneUpdateError as IBaseError, currentLanguage);
  }, [milestoneUpdateError]);

  // useEffects.init

  useEffect(() => {
    if (props.selectedMilestone) {
      setInitialValues({
        filter: {
          projectId: props.projectId ?? '',
          projectMilestoneId: props.selectedMilestone?._id,
        },
        input: {
          name: props.selectedMilestone?.name,
          description: props.selectedMilestone?.description,
          dueDate: props.selectedMilestone?.dueDate,
          startDate: props.selectedMilestone?.startDate,
        },
      });
    }
  }, [props.selectedMilestone, props.projectId]);

  const commonContent = (
    <UpsertMilestoneComponent
      open={props.open}
      setOpen={props.setOpen}
      loading={milestoneUpdateLoading || loading}
      useForm={milestoneUpdateUseForm}
    />
  );

  switch (props.overlayType) {
    case OverlayType.drawer:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          size="medium"
          title={t('crm.project.project.milestoneUpdate')}
          handleSubmit={milestoneUpdateUseForm.handleSubmit(onSubmit)}
          disabled={milestoneUpdateLoading || loading || !milestoneUpdateUseForm.formState.isValid}
          loading={milestoneUpdateLoading || loading}
          buttonTitle={t('crm.project.project.milestoneUpdate')}
        >
          {commonContent}
        </ActionDialog>
      );
    case OverlayType.dialog:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          title={t('crm.project.project.milestoneUpdate')}
          handleClick={milestoneUpdateUseForm.handleSubmit(onSubmit)}
          disabled={milestoneUpdateLoading || loading || !milestoneUpdateUseForm.formState.isValid}
          loading={milestoneUpdateLoading || loading}
          buttonTitle={t('crm.project.project.milestoneUpdate')}
          width={500}
        >
          {commonContent}
        </ActionDialog>
      );
    default:
      return null;
  }
});

export default MilestoneUpdateOverlay;

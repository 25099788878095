const locale = {
  demo: "Demo",
  title: "Cos'è la Finestra <highlight>CoCRM</highlight>?",
  description:
    "La Finestra CoCRM è un'area di sviluppo aziendale progettata esclusivamente per le aziende utenti di CoCRM, dove possono fornire trasferimenti direttamente ai membri all'interno dell'ecosistema CoCRM riguardo i loro prodotti, servizi o campagne, e catturare opportunità di business definendo sconti speciali per individui e istituzioni che visitano il nostro sito web.",
  featuresTitle: "Con la Finestra CoCRM;",
  features1:
    "Puoi fare offerte direttamente ai visitatori del nostro sito web con oltre 1 milione di traffico mensile,",
  features2:
    "Puoi fare vari annunci di campagne alle aziende all'interno del nostro ecosistema,",
  features3:
    "Puoi fornire informazioni dettagliate sui tuoi prodotti e servizi partecipando alle campagne blog che realizzeremo,",
  features4:
    "Puoi dare un contributo aggiuntivo del 36,2% alla notorietà del tuo marchio!",
  buttonTitle: "Richiedi un preventivo!",
  formDescription:
    "Compila il modulo e ti risponderemo il prima possibile.",
  sendSuccess: "Grazie! Ti risponderemo il prima possibile.",
  formTitle: "Richiedi un preventivo",
  formButtonTitle: "Invia",
  fullName: "Nome completo",
  email: "Email",
  phone: "Telefono",
  orgName: "Nome dell'organizzazione",
};

export default locale; 
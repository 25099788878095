const locale = {
  permissions: 'Permessi',
  roles: 'Ruoli',
  createRole: 'Crea Ruolo',
  rolesInfo: 'Puoi creare ruoli e assegnare loro permessi.',
  roleName: 'Nome del Ruolo',
  roleDetail: 'Dettaglio del Ruolo',
  addRole: 'Aggiungi',
  updateRole: 'Aggiorna',
  deleteRole: 'Elimina',
  roleCreatedSuccess: 'Ruolo creato con successo',
  roleUpdatedSuccess: 'Ruolo aggiornato con successo',
  roleDeletedSuccess: 'Ruolo eliminato con successo',
  errorFetchingRoles: 'Errore nel recupero dei ruoli',
  errorCreatingRole: 'Errore nella creazione del ruolo',
  errorUpdatingRole: 'Errore nell\'aggiornamento del ruolo',
  errorDeletingRole: 'Errore nell\'eliminazione del ruolo',
  capabilities: 'Capacità',
  advancedOptions: 'Opzioni Avanzate',
  addNewRole: 'Aggiungi Nuovo Ruolo',
  rolesAndPermissions: 'Ruoli e Permessi',
  action: 'Azione',
  actionScope: 'Ambito di Azione',
  deleteConfirm: 'Sei sicuro di voler rimuovere il ruolo ',
  deletedSuccessfully: "Ruolo eliminato con successo",
};

export default locale; 
import settingsLocale from "../domains/settings/locales/pt";

const locale = {
  settings: {
    ...settingsLocale,
  },
  demoo: "Pedido de Orçamento",
  settingsLabel: "Configurações",
  paymentSettings: "Pagamento",
  menuSettings: "Configurações de Menu",
  crmSettings: "CRM",
  leadPlusSettings: "Lead Plus",
  marketingSettings: "Marketing",
  windowSettings: "Janela",
  communitySettings: "Comunidade",
  notificationSettings: "Notificação",
  organizationSettings: "Organização",
  myOrganization: "Minha Organização",
  generalSettings: "Geral",
  moduleSettings: "Configurações de Módulo",
  userSettings: "Configurações de Usuário",
  integrations: "Integrações",
  legalAgreements: "Acordos Legais",
};

export default locale; 
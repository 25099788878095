const locale = {
  contracts: 'Contratos',
  contract: 'Contrato',
  contractConversions: 'Conversiones de Contratos',
  title: 'Contratos',
  create: 'Crear Contrato',
  update: 'Actualizar Contrato',
  delete: 'Eliminar Contrato',
  createSuccess: 'Contrato creado con éxito.',
  updateSuccess: 'Contrato actualizado con éxito.',
  deleteSuccess: 'Contrato eliminado con éxito.',
  deleteConfirm: '¿Está seguro de que desea eliminar este contrato? Esta acción no se puede deshacer.',
  emptyState1: 'Aún no se han añadido contratos.',
  emptyState2:
    'Almacene y gestione todos los detalles del contrato aquí para agilizar su facturación y mantener acuerdos claros con sus clientes.',

  // labels
  contractTemplateLabel: 'Plantilla de Contrato',
  contractValue: 'Valor del Contrato',
  contractType: 'Tipo de Contrato',
  signedDate: 'Fecha de Firma',
  signedUser: 'Usuario Firmante',
  signStatus: 'Estado de Firma',

  //detail drawer
  changeStatus: 'Cambiar Estado',
  statusChangeSuccess: 'Estado cambiado con éxito.',
  generalInfoTitle: 'Información General',
  customerInfoTitle: 'Información del Cliente',
  projectInfoTitle: 'Información del Proyecto',
  contractRenew: 'Renovar Contrato',
  renew: 'Renovar Contrato',
  renewSuccess: 'Contrato renovado con éxito.',
  emptyStateRenew: 'No hay contratos para renovar.',
  renewHistory: 'Historial de Renovaciones',
};

export default locale; 
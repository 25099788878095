const locale = {
  leadStatuses: "Lead-Status",
  title: "Lead-Status",
  create: "Lead-Status erstellen",
  delete: "Lead-Status löschen",
  deleteConfirm: "Sind Sie sicher, dass Sie diesen Status löschen möchten: ",
  deletedSuccessfully: "Lead-Status erfolgreich gelöscht",
  createSuccess: "Lead-Status erfolgreich erstellt",
  update: "Lead-Status aktualisieren",
  updateSuccess: "Lead-Status erfolgreich aktualisiert",
  selectColor: "Farbe auswählen",
  selectIcon: "Symbol auswählen",
};

export default locale; 
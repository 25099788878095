const locale = {
  demo: "Demo",
  title: "¿Qué es <highlight>CoCRM</highlight> Lead+?",
  description:
    "En CoCRM, no solo te apoyamos en la gestión de tus procesos de negocio y clientes; también nos complace ayudarte a adquirir nuevos clientes.",
  featuresTitle: "Con CoCRM Lead+,",
  features1:
    "Nos complace proporcionarte los datos de clientes potenciales que necesitas basados en",
  features2:
    "Los parámetros de país, ciudad y/o industria que tienes como objetivo para marketing.",
  features3: "Las áreas específicas que has definido para tus objetivos de ventas.",
  features4: "Los mercados donde deseas aumentar el conocimiento de tu marca.",
  buttonTitle: "Solicitar Presupuesto",
  formDescription:
    "Por favor, completa el formulario a continuación y nos pondremos en contacto contigo lo antes posible.",
  formTitle: "Solicitar Presupuesto",
  formButtonTitle: "Enviar",
  sendSuccess: "¡Gracias! Nos pondremos en contacto contigo lo antes posible.",
  fullName: "Nombre Completo",
  email: "Correo Electrónico",
  phone: "Teléfono",
  orgName: "Nombre de la Organización",
};

export default locale; 
import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';
import { date, ref } from 'yup';

export const validateCreateAppointmentInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    title: ValidationHelper.RequiredString(getTranslatedText('titleIsRequired')),
    startTime: date()
      .typeError(getTranslatedText('invalidDateError'))
      .required(getTranslatedText('isRequired')),
    endTime: date()
      .typeError(getTranslatedText('invalidDateError'))
      .min(ref('startTime'), getTranslatedText('endTimeMustBeLaterThanStartTime'))
      .required(getTranslatedText('isRequired')),
    description: ValidationHelper.RequiredString(getTranslatedText('descriptionIsRequired')),
    category: ValidationHelper.RequiredString(getTranslatedText('categoryIsRequired')),
  }),
});

const locale = {
  payments: 'Pagamentos',
  payment: 'Pagamento',
  paymentConversions: 'Conversões de Pagamento',
  title: 'Pagamentos',
  create: 'Adicionar Pagamento',
  update: 'Atualizar Pagamento',
  delete: 'Excluir Pagamento',
  createSuccess: 'Pagamento adicionado com sucesso.',
  updateSuccess: 'Pagamento atualizado com sucesso.',
  deleteSuccess: 'Pagamento excluído com sucesso.',
  deleteConfirm: 'Tem certeza de que deseja excluir este pagamento? Esta ação não pode ser desfeita.',
  emptyState1: 'Nenhum pagamento registrado ainda.',
  emptyState2:
    'Acompanhe e gerencie os pagamentos de suas faturas aqui para manter suas finanças organizadas e atualizadas.',

  // detailDrawer
  generalInfoTitle: 'Informações Gerais',
  date: 'Data de Pagamento',
  amountPaid: 'Valor Pago',
  confirmationNumber: 'Número de Confirmação',
  currency: 'Moeda',
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, useMediaQuery, Theme, Divider } from '@mui/material';
import { Iconify } from 'components';
import { useTranslation } from 'react-i18next';
import {
  IInvoiceReturnDetailResult,
  IInvoiceReturnProduct,
  PermissionSubject,
} from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import parse from 'html-react-parser';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';
import { Iconify as Icon } from 'components';
import { usePermissions } from 'permission/PermissionContext';
import { useInvoiceDetailQuery } from '../../../invoice';
import { PermissionAction } from 'corede-common';

export interface IReturnDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<IInvoiceReturnDetailResult> {
  returnDetailData: IInvoiceReturnDetailResult | undefined;
  tabKey: 'info';
  name: 'crm.info';
}

export const ReturnDetailDrawerTabInfoComponent = (
  props: IReturnDetailDrawerTabInfoComponentProps,
) => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { hasPermission } = usePermissions();
  const hasInvoicePermission = hasPermission({
    subject: PermissionSubject.invoiceReturn,
    action: PermissionAction.view,
  });

  const { data: invoiceDetailData } = useInvoiceDetailQuery(
    {
      input: {
        _id: props.returnDetailData?.invoice?._id ?? '',
      },
    },
    {
      skip: !(props.returnDetailData?.invoice?._id && hasInvoicePermission),
    },
  );

  const additionalInfoData = [
    {
      title: t('crm.sale.return.isOpen'),
      value: props?.returnDetailData?.isOpen ? t('yes') : t('no'),
      icon: 'mdi:check-circle',
    },
    {
      title: t('crm.sale.return.refundAmount'),
      value: props?.returnDetailData?.refundAmount,
      icon: 'mdi:cash-refund',
    },
    {
      title: t('crm.sale.return.resolveReason'),
      value: props.returnDetailData?.resolveReason
        ? getTranslatedEnumValue(props.returnDetailData.resolveReason)
        : '-',
      icon: 'mdi:comment-text-multiple',
    },
    {
      title: t('crm.sale.return.disapproveReason'),
      value: props.returnDetailData?.disapproveReason || '-',
      icon: 'mdi:receipt-text',
    },
  ];

  return (
    <>
      <Grid container mt={downMd ? 0 : 2} mb={2}>
        <Grid item xs={12}>
          <Stack
            direction={'column'}
            p={2.5}
            mt={downMd ? 2 : 0}
            sx={{ borderRadius: 3, boxShadow: '0px 3px 12px 0px rgba(35, 32, 32, 0.1)' }}
          >
            <Grid container spacing={1}>
              {additionalInfoData.map((item) => (
                <Grid item xs={12}>
                  <Stack direction={'row'} gap={2} alignItems={'center'}>
                    <Box
                      sx={{
                        width: 30,
                        height: 30,
                        bgcolor: 'secondary.extraLight',
                        borderRadius: '6px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                    </Box>
                    <Stack direction={'column'}>
                      <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                        {item.value ?? '-'}
                      </Typography>
                      <Typography variant="caption" color={'text.secondary'}>
                        {item.title}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Grid>

        <Stack direction={'column'} sx={{ py: 1, mb: 2, mt: 1, position: 'relative' }}>
          <Box>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              {t('crm.sale.return.returnProducts')}
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />

            {props.returnDetailData?.invoiceReturnProducts?.length ? (
              <Grid container spacing={2}>
                {props.returnDetailData.invoiceReturnProducts.map(
                  (product: IInvoiceReturnProduct, index: number) => (
                    <Grid key={index} item xs={12}>
                      <Box sx={{ border: '1px solid #ddd', borderRadius: 2, padding: 2 }}>
                        {/* Ürün Adı */}
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{ marginBottom: 2 }}
                        >
                          <Icon icon="mdi:package-variant" width={20} />
                          <Typography
                            variant={'caption'}
                            sx={{ fontWeight: 500, color: 'text.primary' }}
                          >
                            {invoiceDetailData?.products?.find(
                              (p) => p._id === product?.invoiceProduct?._id,
                            )?.name || '-'}
                          </Typography>
                        </Stack>

                        {/* Fiyat */}
                        <Grid container spacing={1.5}>
                          <Grid item xs={6}>
                            <Stack direction="row" alignItems="center" gap={2}>
                              <Box
                                sx={{
                                  width: 30,
                                  height: 30,
                                  bgcolor: 'secondary.extraLight',
                                  borderRadius: '6px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Icon icon="mdi:cash" width={20} sx={{ color: 'secondary.main' }} />
                              </Box>

                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 500 }}
                                  color={'text.primary'}
                                >
                                  {product.amount || '-'}
                                </Typography>
                                <Typography variant="caption">
                                  {t('crm.sale.return.amount')}
                                </Typography>
                              </Box>
                            </Stack>
                          </Grid>

                          <Grid item xs={6}>
                            <Stack direction="row" alignItems="center" gap={2}>
                              <Box
                                sx={{
                                  width: 30,
                                  height: 30,
                                  bgcolor: 'secondary.extraLight',
                                  borderRadius: '6px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Icon
                                  icon="mdi:note-text"
                                  width={20}
                                  sx={{ color: 'secondary.main' }}
                                />
                              </Box>

                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 500 }}
                                  color={'text.primary'}
                                >
                                  {product.note || '-'}
                                </Typography>
                                <Typography variant="caption">
                                  {t('crm.sale.return.note')}
                                </Typography>
                              </Box>
                            </Stack>
                          </Grid>

                          <Grid item xs={6}>
                            <Stack direction="row" alignItems="center" gap={2}>
                              <Box
                                sx={{
                                  width: 30,
                                  height: 30,
                                  bgcolor: 'secondary.extraLight',
                                  borderRadius: '6px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Icon
                                  icon="mdi:comment-text-multiple"
                                  width={20}
                                  sx={{ color: 'secondary.main' }}
                                />
                              </Box>

                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 500 }}
                                  color={'text.primary'}
                                >
                                  {product.reason ? getTranslatedEnumValue(product.reason) : '-'}
                                </Typography>
                                <Typography variant="caption">
                                  {t('crm.sale.return.reason')}
                                </Typography>
                              </Box>
                            </Stack>
                          </Grid>

                          <Grid item xs={6}>
                            <Stack direction="row" alignItems="center" gap={2}>
                              <Box
                                sx={{
                                  width: 30,
                                  height: 30,
                                  bgcolor: 'secondary.extraLight',
                                  borderRadius: '6px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Icon
                                  icon="mdi:check-circle"
                                  width={20}
                                  sx={{ color: 'secondary.main' }}
                                />
                              </Box>

                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 500 }}
                                  color={'text.primary'}
                                >
                                  {product.isPartial ? t('yes') : t('no')}
                                </Typography>
                                <Typography variant="caption">
                                  {t('crm.sale.return.isPartial')}
                                </Typography>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ),
                )}
              </Grid>
            ) : (
              <Typography variant="body2" color="text.secondary">
                {t('crm.sale.return.noProducts')}
              </Typography>
            )}
          </Box>
        </Stack>
      </Grid>
    </>
  );
};

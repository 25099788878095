/* eslint-disable react-hooks/exhaustive-deps */
import { useInvoiceReturnDetailQuery } from '../../context/return.api';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  IInvoiceReturn,
  IInvoiceReturnDetailResult,
  IInvoiceReturnListItemResult,
} from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { ReturnDetailDrawerLeftPanelComponent } from './ReturnDetailDrawer-leftPanel.component';
import { ReturnDetailDrawerRightPanelComponent } from './ReturnDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

export interface IInvoiceReturnDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedReturn: IInvoiceReturnDetailResult & IHasId<number>;
  setSelectedReturn: Dispatch<
    SetStateAction<TListGridSelectedEntity<IInvoiceReturnListItemResult>>
  >;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const ReturnDetailDrawer = memo((props: IInvoiceReturnDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: returnDetailData, error: returnDetailError } = useInvoiceReturnDetailQuery(
    props?.selectedReturn?._id
      ? {
          input: {
            invoiceId: props.selectedReturn.invoice?._id ?? '',
            returnId: props.selectedReturn._id,
          },
        }
      : skipToken,
    {
      skip: !props?.selectedReturn?._id,
    },
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(returnDetailError as IBaseError, currentLanguage);
  }, [returnDetailError]);

  return (
    <DefaultDetailDrawer<IInvoiceReturnDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedReturn as IInvoiceReturnDetailResult & IHasId<number>}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.sale.return.title'),
            }
      }
    >
      <ReturnDetailDrawerLeftPanelComponent
        returnDetailData={returnDetailData}
        setOpen={props.setOpen}
      />
      <ReturnDetailDrawerRightPanelComponent returnDetailData={returnDetailData} />
    </DefaultDetailDrawer>
  );
});

export default ReturnDetailDrawer;

const locale = {
  proposal: 'Предложение',
  title: 'Предложения',
  proposals: 'Предложения',
  create: 'Создать Предложение',
  update: 'Обновить Предложение',
  delete: 'Удалить',
  view: 'Просмотр Предложения',
  list: 'Список Предложений',
  createdSuccessfully: 'Предложение успешно создано',
  updatedSuccessfully: 'Предложение успешно обновлено',
  deleteConfirm: 'Вы уверены, что хотите удалить? Это действие нельзя отменить.',
  deletedSuccessfully: 'Предложение успешно удалено',
  createInfo: 'Здесь вы можете создавать новые предложения.',
  prefix: 'Префикс',
  proposalId: 'Предложение',
  timeZone: 'Часовой Пояс',
  prospectTargetEntityId: 'Целевой Клиент',
  prospectTargetEntityRef: 'Ссылка на Целевого Клиента',
  prospectType: 'Тип Клиента',
  prospectName: 'Имя Клиента',
  prospectVAT: 'ИНН Клиента',
  prospectPhoneNumber: 'Телефон Клиента',
  prospectCountry: 'Страна Клиента',
  prospectState: 'Регион Клиента',
  prospectCity: 'Город Клиента',
  prospectZIP: 'Почтовый Индекс Клиента',
  prospectAddress: 'Адрес Клиента',
  prospectContactFirstName: 'Имя Контактного Лица Клиента',
  prospectContactLastName: 'Фамилия Контактного Лица Клиента',
  prospectContactEmail: 'Email Контактного Лица Клиента',
  language: 'Язык',
  date: 'Дата',
  expireDate: 'Дата Истечения',
  sendEmailOnExpiration: 'Отправить Email при Истечении',
  expirationEmailSentDate: 'Дата Отправки Email об Истечении',
  projectOrServiceDesc: 'Описание Проекта или Услуги',
  scopeOfWork: 'Объем Работ',
  assumptionsAndExclusions: 'Предположения и Исключения',
  paymentTerms: 'Условия Оплаты',
  currency: 'Валюта',
  products: 'Продукты',
  addProducts: 'Добавить Продукты',
  offers: 'Предложения',
  reference: 'Ссылка',
  salesAgentIds: 'Менеджеры по Продажам',
  allowComments: 'Разрешить Комментарии',
  useLegalNDA: 'Использовать NDA',
  legalNDAId: 'Юридический NDA',
  useLegalTermAndCond: 'Использовать Условия',
  legalTermAndCondId: 'Юридические Условия',
  signerUserId: 'Подписывающий Пользователь',
  listInfo: 'Список всех предложений.',
  importedError: 'Ошибка импорта',
  importedSuccessfully: 'Успешно импортировано',
  downloadFormError: 'Ошибка загрузки формы',
  downloadFormSuccessfully: 'Форма успешно загружена',
  downloadExampleFormSuccessfully: 'Пример формы успешно загружен',
  downloadExampleFormError: 'Ошибка загрузки примера формы',
  emptyState1: 'Предложения не найдены.',
  emptyState2: 'Вы можете сразу создать или импортировать предложения.',
  createProposal: 'Создать Предложение',
  importProposal: 'Импортировать Предложение',
  importTitle: 'Импортировать Предложения',
  downloadExample: 'Скачать Пример',
  importText1: 'Вы можете импортировать предложения с этой страницы.',
  importText2: 'Вы можете использовать пример формы ниже для публикации ваших предложений.',
  importExport: 'Импорт/Экспорт',
  exportData: 'Экспорт Данных',
  importData: 'Импорт Данных',
  b2b: 'B2B',
  b2c: 'B2C',
  viewProposal: 'Просмотр',
  edit: 'Редактировать',
  junk: 'Спам',
  unJunk: 'Не Спам',
  addNewRole: 'Добавить Новую Роль',
  actions: 'Действия',
  businessName: 'Название Компании',
  governmentAgency: 'Государственное Учреждение',
  fullName: 'Полное Имя',
  VAT: 'ИНН',
  phoneNumber: 'Номер Телефона',
  country: 'Страна',
  state: 'Регион',
  city: 'Город',
  ZIP: 'Почтовый Индекс',
  address: 'Адрес',
  contactFirstName: 'Имя Контактного Лица',
  contactLastName: 'Фамилия Контактного Лица',
  contactEmail: 'Email Контактного Лица',
  sentDate: 'Дата Отправки',
  name: 'Имя',
  description: 'Описание',
  productUnit: 'Единица',
  quantity: 'Количество',
  price: 'Цена',
  priceType: 'Тип Цены',
  discountedPrice: 'Цена со Скидкой',
  finalPrice: 'Итоговая Цена',
  tax: 'Налог',
  service: 'Услуга',
  subTotal: 'Промежуточный Итог',
  taxTotal: 'Итого Налогов',
  discount: 'Скидка',
  product: 'Продукт',
  services: 'Услуги',
  references: 'Ссылки',
  add: 'Добавить',
  salesAgent: 'Менеджер по Продажам',
  executiveSummary: 'Краткое Содержание',
  companyBackground: 'Информация о Компании',
  projectorServiceDesc: 'Подробное Описание Проекта/Услуги',
  costBreakdown: 'Разбивка Затрат',
  projectTimeline: 'График Проекта',
  testimonialCaseStudies: 'Отзывы и Примеры',
  conclusion: 'Заключение',
  proposalCreateSuccess: 'Предложение успешно создано',
  sentSuccessfully: 'Предложение успешно отправлено',
  send: 'Отправить Предложение',
  resend: 'Повторно Отправить Предложение',
  sendSureTitle: 'Отправить Предложение',
  sendSureDesc: 'Вы уверены, что хотите отправить это предложение?',
  changeStatus: 'Изменить Статус',
  statusUpdatedSuccessfully: 'Статус успешно обновлен',

  // alert
  noProposal: 'Нет предложений',
  noProposalContent1: 'Предложения не найдены',
  noProposalSend: 'Нет отправленных предложений',

  // generalInfo
  generalInfoTitle: 'Общая Информация',
  status: 'Статус',
  createdAt: 'Создано',
  createdBy: 'Создано',
  updatedAt: 'Обновлено',
  notAvailable: 'Недоступно',
  updateStatus: 'Обновить Статус',

  // customerInfo
  customerInfoTitle: 'Информация о Клиенте',
  contactPhoneNumber: 'Номер Телефона',

  //productInfo
  productInfoTitle: 'Детали Продукта',
  productNameUnknown: 'Неизвестный Продукт',
  noProducts: 'Нет доступных продуктов.',

  //legalInfo
  legalInfoTitle: 'Юридическая Информация',
  ndaTitle: 'Соглашение о Конфиденциальности (NDA)',
  termsTitle: 'Условия и Положения',
  documentName: 'Название Документа',
  documentDescription: 'Описание',
  documentContent: 'Содержание',

  //financialInfo
  financialInfoTitle: 'Финансовая Информация',
  shippingTotal: 'Итого Доставка',
  total: 'Общая Цена',
  noFinancialData: 'Нет доступных финансовых данных.',

  // documentInfo
  documentsTitle: 'Документы',
  documentNameUnknown: 'Неизвестный Документ',
  mimeType: 'Тип Файла',
  download: 'Скачать',
  noDocuments: 'Нет доступных документов.',

  // salesAgents
  salesAgentsTitle: 'Менеджеры по Продажам',
  unknownAgent: 'Неизвестный Менеджер',
  noSalesAgents: 'Нет доступных менеджеров по продажам.',

  //signature
  signatureTitle: 'Информация о Подписи',
  additionalInfoTitle: 'Дополнительная Информация',
  signature: 'Подпись',
  signerUser: 'Подписывающий Пользователь',

  //additionalInfo
  yes: 'Да',
  no: 'Нет',
};

export default locale; 
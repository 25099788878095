const locale = {
  productCategories: 'Категории',
  title: 'Категории Продуктов/Услуг',
  create: 'Создать Категорию',
  delete: 'Удалить Категорию',
  deleteConfirm: 'Вы уверены, что хотите удалить эту категорию: ',
  deletedSuccessfully: 'Категория успешно удалена',
  createCategory: 'Создать Категорию',
  categoryCreateSuccess: 'Категория успешно создана',
  name: 'Название Категории',
  icon: 'Иконка Категории',
  showMore: 'Показать Больше',
  update: 'Обновить',
  updateCategory: 'Обновить Категорию',
  categoryUpdateSuccess: 'Категория успешно обновлена',
  en: 'Английский',
  tr: 'Турецкий',
  createSuccess: 'Категория успешно создана',
  updateSuccess: 'Категория продукта успешно обновлена',
  actions: 'Действия',
  createdAt: 'Создано',
  emptyState1: 'Категории не найдены',
  emptyState2: 'Пожалуйста, создайте новую категорию',
  createProductCategory: 'Создать Категорию Продукта',
};

export default locale; 
const locale = {
  permissions: "Permessi",
  errorFetchingPermissions: "Errore durante il recupero dei permessi",
  allPermissions: "Tutti i permessi",
  all: "Tutti",
  ownPermissionsAlert: "Non puoi modificare i tuoi permessi.",
  action: "Azione",
  actionScope: "Ambito di azione",
  updateSuccess: "Permesso aggiornato con successo",
};

export default locale; 
const locale = {
  estimate: 'Смета',
  title: 'Сметы',
  create: 'Создать Смету',
  update: 'Обновить Смету',
  delete: 'Удалить',
  view: 'Просмотр Сметы',
  list: 'Список Смет',
  detail: 'Детали Сметы',
  createdSuccessfully: 'Смета успешно создана',
  updatedSuccessfully: 'Смета успешно обновлена',
  deleteConfirm: 'Вы уверены, что хотите удалить? Это действие нельзя отменить.',
  deletedSuccessfully: 'Смета успешно удалена',
  createInfo: 'Здесь вы можете создавать новые сметы.',
  prefix: 'Префикс',
  timeZone: 'Часовой Пояс',
  prospectTargetEntityId: 'Потенциальный Клиент',
  prospectTargetEntityRef: 'Ссылка на Потенциального Клиента',
  prospectType: 'Тип Потенциального Клиента',
  prospectName: 'Имя Потенциального Клиента',
  prospectVAT: 'ИНН Потенциального Клиента',
  prospectPhoneNumber: 'Телефон Потенциального Клиента',
  prospectCountry: 'Страна Потенциального Клиента',
  prospectState: 'Регион Потенциального Клиента',
  prospectCity: 'Город Потенциального Клиента',
  prospectZIP: 'Индекс Потенциального Клиента',
  prospectAddress: 'Адрес Потенциального Клиента',
  prospectContactFirstName: 'Имя Контакта Потенциального Клиента',
  prospectContactLastName: 'Фамилия Контакта Потенциального Клиента',
  prospectContactEmail: 'Email Контакта Потенциального Клиента',
  language: 'Язык',
  date: 'Дата',
  expireDate: 'Дата Истечения',
  sendEmailOnExpiration: 'Отправить Email',
  expirationEmailSentDate: 'Дата Отправки Email об Истечении',
  projectOrServiceDesc: 'Описание Проекта или Услуги',
  paymentTerms: 'Условия Оплаты',
  products: 'Продукты',
  addProducts: 'Добавить Продукты',
  offers: 'Предложения',
  salesAgentIds: 'Менеджеры по Продажам',
  allowComments: 'Разрешить Комментарии',
  legalNDAId: 'NDA',
  legalTermAndCondId: 'Условия и Положения',
  signerUserId: 'Подписывающий Пользователь',
  listInfo: 'Список всех смет.',
  importedError: 'Ошибка импорта',
  importedSuccessfully: 'Успешно импортировано',
  downloadFormError: 'Ошибка загрузки формы',
  downloadFormSuccessfully: 'Форма успешно загружена',
  downloadExampleFormSuccessfully: 'Пример формы успешно загружен',
  downloadExampleFormError: 'Ошибка загрузки примера формы',
  emptyState1: 'Сметы не найдены.',
  emptyState2: 'Вы можете сразу создать или импортировать сметы.',
  createEstimate: 'Создать Смету',
  importEstimate: 'Импортировать Смету',
  importTitle: 'Импортировать Сметы',
  downloadExample: 'Скачать Пример',
  importText1: 'Вы можете импортировать сметы с этой страницы.',
  importText2: 'Вы можете использовать пример формы ниже для публикации ваших смет.',
  importExport: 'Импорт/Экспорт',
  exportData: 'Экспорт Данных',
  importData: 'Импорт Данных',
  b2b: 'B2B',
  b2c: 'B2C',
  viewEstimate: 'Просмотр',
  edit: 'Редактировать',
  junk: 'В Корзину',
  unJunk: 'Восстановить',
  addNewRole: 'Добавить Новую Роль',
  actions: 'Действия',
  legalAgreements: 'Юридические Соглашения',
  businessName: 'Название Компании',
  governmentAgency: 'Государственное Учреждение',
  fullName: 'Полное Имя',
  VAT: 'ИНН',
  phoneNumber: 'Номер Телефона',
  country: 'Страна',
  state: 'Регион',
  city: 'Город',
  ZIP: 'Индекс',
  sentDate: 'Дата Отправки',
  name: 'Имя',
  description: 'Описание',
  productUnit: 'Единица',
  priceType: 'Тип Цены',
  service: 'Услуга',
  product: 'Продукт',
  services: 'Услуги',
  references: 'Ссылки',
  add: 'Добавить',
  salesAgent: 'Менеджер по Продажам',
  estimateCreateSuccess: 'Смета успешно создана',

  addEstimate: 'Добавить Смету',
  noEstimateContent1: 'Сметы не найдены',
  noEstimate: 'Нет смет',
  generalInfo: 'Общая Информация',
  sentSuccessfully: 'Смета успешно отправлена',
  send: 'Отправить Смету',
  resend: 'Повторно Отправить Смету',
  sendSureTitle: 'Отправить Смету',
  sendSureDesc: 'Вы уверены, что хотите отправить эту смету?',
  changeStatus: 'Изменить Статус',
  statusUpdatedSuccessfully: 'Статус успешно обновлен',

  // generalInfo
  generalInfoTitle: 'Общая Информация',
  estimateId: 'Смета',
  status: 'Статус',
  createdAt: 'Создано',
  reference: 'Ссылка',
  createdBy: 'Создано',
  updatedAt: 'Обновлено',
  notAvailable: 'Недоступно',
  updateStatus: 'Обновить Статус',

  // customerInfo
  customerInfoTitle: 'Информация о Клиенте',
  contactFirstName: 'Имя',
  contactLastName: 'Фамилия',
  contactEmail: 'Email',
  contactPhoneNumber: 'Номер Телефона',
  address: 'Адрес',

  //productInfo
  price: 'Цена',
  productInfoTitle: 'Детали Продукта',
  productNameUnknown: 'Неизвестный Продукт',
  discountedPrice: 'Цена со Скидкой',
  quantity: 'Количество',
  finalPrice: 'Итоговая Цена',
  tax: 'Ставка Налога',
  noProducts: 'Нет доступных продуктов.',

  //legalInfo
  legalInfoTitle: 'Юридическая Информация',
  ndaTitle: 'Соглашение о Неразглашении (NDA)',
  termsTitle: 'Условия и Положения',
  documentName: 'Название Документа',
  documentDescription: 'Описание',
  documentContent: 'Содержание',

  //financialInfo
  financialInfoTitle: 'Финансовая Информация',
  currency: 'Валюта',
  subTotal: 'Промежуточный Итог',
  discount: 'Скидка',
  taxTotal: 'Итого Налог',
  shippingTotal: 'Итого Доставка',
  total: 'Итоговая Цена',
  noFinancialData: 'Нет доступных финансовых данных.',

  // documentInfo
  documentsTitle: 'Документы',
  documentNameUnknown: 'Неизвестный Документ',
  mimeType: 'Тип Файла',
  download: 'Скачать',
  noDocuments: 'Нет доступных документов.',

  // salesAgents
  salesAgentsTitle: 'Менеджеры по Продажам',
  unknownAgent: 'Неизвестный Менеджер',
  noSalesAgents: 'Нет доступных менеджеров по продажам.',

  //signature
  signatureTitle: 'Информация о Подписи',
  additionalInfoTitle: 'Дополнительная Информация',
  signature: 'Подпись',
  signerUser: 'Подписывающий Пользователь',

  //additionalInfo
  scopeOfWork: 'Объем Работ',
  assumptionsAndExclusions: 'Предположения и Исключения',
  useLegalNDA: 'Использовать Юридическое NDA',
  useLegalTermAndCond: 'Использовать Условия и Положения',
  yes: 'Да',
  no: 'Нет',

  //tabs
  generalInformation: 'Общая Информация*',
  prospect: 'Потенциальный Клиент*',
  detailedInformation: 'Подробная Информация',
  productsAndServices: 'Продукты и Услуги*',
  salesAgents: 'Менеджеры по Продажам',
  legal: 'Юридическая Информация',
  signatureTab: 'Подпись*',
};

export default locale; 
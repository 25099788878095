/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import { IBaseError, IGraphqlVariables, Language } from 'corede-common';
import {
  ILeadForm,
  ILeadFormListInput,
  ILeadSourceResult,
  ILeadStatusResult,
  LeadBusinessType,
  LeadQualification,
  LeadType,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import {
  useLazyLeadFormUrlQuery,
  useLeadFormListQuery,
  useLeadFormUpdateMutation,
} from '../../context/leadForm.api';
import { LeadFormDeleteDialog } from '../LeadFormDeleteDialog';
import LeadFormUpdateDrawerWrapper from '../update/LeadFormUpdateDrawer.wrapper';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import { IPendingNavigation } from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representBooleanAsString } from 'utils/representationHelper';
import { useUserListQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import { useLeadSourceListQuery } from '../../../leadSource';
import { useLeadStatusListQuery } from '../../../leadStatus';

export interface ILeadFormListGridComponent {
  // leadForm

  setOpenLeadFormCreateDrawer?: (value: boolean) => void | undefined;
}

const LeadFormListGridComponent = memo((props: ILeadFormListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedLeadForm, setSelectedLeadForm] = useState<TListGridSelectedEntity<ILeadForm>>();
  const [openLeadFormDetailDrawer, setOpenLeadFormDetailDrawer] = useState(false);
  const [openLeadFormDeleteDialog, setOpenLeadFormDeleteDialog] = useState(false);
  const [openLeadFormUpdateDrawer, setOpenLeadFormUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [leadFormListFilter, setLeadFormListFilter] = useState<
    IGraphqlVariables<ILeadFormListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  // queries
  const {
    data: leadFormListData,
    isLoading: leadFormListLoading,
    isFetching: leadFormListFetching,
    error: leadFormListError,
    refetch,
  } = useLeadFormListQuery(leadFormListFilter);

  // const { data: leadFormListCountData } = useLeadFormListCountQuery({});
  const { data: leadFormListCountData } = useLeadFormListQuery({});
  const [leadFormUrl, { data: leadFormUrlData, error: leadFormUrlError }] =
    useLazyLeadFormUrlQuery();

  const { data: leadStatusListDataFromApi } = useLeadStatusListQuery({});
  const leadStatusList: ILeadStatusResult[] | undefined = leadStatusListDataFromApi?.data;

  const { data: leadSourceListDataFromApi } = useLeadSourceListQuery({});
  const leadSourceList: ILeadSourceResult[] | undefined = leadSourceListDataFromApi?.data;

  const { data: userListData } = useUserListQuery({});

  // used for direct update in the list rows
  const [leadFormUpdate, { isLoading: leadFormUpdateLoading, error: leadFormUpdateError }] =
    useLeadFormUpdateMutation();

  const isEmptyState =
    leadFormListData?.data?.length === 0 &&
    !leadFormListLoading &&
    !leadFormListFetching &&
    leadFormListCountData?.count === 0;

  // effects

  useEffect(() => {
    refetch();
  }, [leadFormListFilter]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadFormListError as IBaseError, currentLanguage);
  }, [leadFormListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadFormUpdateError as IBaseError, currentLanguage);
  }, [leadFormUpdateError]);

  // useEffects.init

  useEffect(() => {
    if (openLeadFormDetailDrawer) {
      leadFormUrl({ input: { _id: selectedLeadForm?._id ?? '' } });
      setOpenLeadFormDetailDrawer(false);
    }
  }, [openLeadFormDetailDrawer]);

  useEffect(() => {
    if (leadFormUrlData) {
      window.open(leadFormUrlData.leadFormUrl, '_blank');
    }
  }, [leadFormUrlData]);

  useEffect(() => {
    if (leadFormUrlError) {
      DefaultErrorHandlerUseEffect(leadFormUrlError as IBaseError, currentLanguage);
      setOpenLeadFormDetailDrawer(false);
    }
  }, [leadFormUrlError]);

  return (
    <DefaultListGridComponent
      selectedEntity={selectedLeadForm}
      setSelectedEntity={setSelectedLeadForm}
      listFilter={leadFormListFilter}
      setListFilter={setLeadFormListFilter}
      listData={leadFormListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
        {leadFormListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                leadFormListData?.data?.map((leadForm, index) => ({
                  id:
                    ((leadFormListFilter.input?.pagination?.page || 1) - 1) *
                      (leadFormListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: leadForm._id,
                  formName: leadForm.formName,
                  defaultLanguage: leadForm.defaultLanguage,
                  defaultBusinessType: leadForm.defaultBusinessType,
                  defaultType: leadForm.defaultType,
                  defaultQualification: leadForm.defaultQualification,
                  defaultStatus: leadForm.defaultStatus,
                  defaultSource: leadForm.defaultSource,
                  defaultTags: leadForm.defaultTags,
                  defaultPrefix: leadForm.defaultPrefix,
                  defaultIsPublic: representBooleanAsString({ data: leadForm.defaultIsPublic, t }),
                  defaultAssigned: leadForm.defaultAssigned,
                  followUpMessage: leadForm.followUpMessage,
                  followUpRedirectUrl: leadForm.followUpRedirectUrl,
                  specificUsersToNotify: leadForm.specificUsersToNotify,
                  willNotify: representBooleanAsString({ data: leadForm.willNotify, t }),
                  willNotifyResponsibleUsers: leadForm.willNotifyResponsibleUsers,
                  submitButtonText: leadForm.submitButtonText,
                  submitButtonBackgroundColor: leadForm.submitButtonBackgroundColor,
                  submitButtonTextColor: leadForm.submitButtonTextColor,
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: 'center',
                    },
                    headerConfig: {
                      name: '#',
                      align: 'center',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'formName',
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.formName'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'defaultLanguage',
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.defaultLanguage'),
                    },
                    filterConfig: {
                      customFieldName: `defaultLanguage`,
                      isArrayFilter: true,
                    },
                  },
                  data: Language,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'defaultBusinessType',
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.defaultBusinessType'),
                    },
                    filterConfig: {
                      customFieldName: `defaultBusinessType`,
                      isArrayFilter: true,
                    },
                  },
                  data: LeadBusinessType,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'defaultType',
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.defaultType'),
                    },
                    filterConfig: {
                      customFieldName: `defaultType`,
                      isArrayFilter: true,
                    },
                  },
                  data: LeadType,
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'defaultQualification',
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.defaultQualification'),
                    },
                    filterConfig: {
                      customFieldName: `defaultQualification`,
                      isArrayFilter: true,
                    },
                  },
                  data: LeadQualification,
                },

                {
                  config: {
                    baseGridColType: BaseGridColType.object,
                    column: {
                      field: 'defaultStatus',
                      editable: true,
                      width: 150,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.defaultStatus'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `defaultStatusIds`,
                      isArrayFilter: true,
                    },
                    valueOptionFields: leadStatusList,
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.object,
                    column: {
                      field: 'defaultSource',
                      editable: true,
                      width: 150,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.defaultSource'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `defaultSourceIds`,
                      isArrayFilter: true,
                    },
                    valueOptionFields: leadSourceList,
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.textArray,
                    column: {
                      field: 'defaultTags',
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.defaultTags'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'defaultPrefix',
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.defaultPrefix'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'defaultIsPublic',
                      filterable: true,
                      editable: false,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.defaultIsPublic'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.object,
                    column: {
                      field: 'defaultAssigned',
                      editable: true,
                      width: 150,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.defaultAssigned'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `defaultAssignedIds`,
                      isArrayFilter: true,
                    },
                    valueOptionFields: userListData?.data ?? [],
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'followUpMessage',
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.followUpMessage'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'followUpRedirectUrl',
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.followUpRedirectUrl'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.object,
                    column: {
                      field: 'specificUsersToNotify',
                      editable: true,
                      width: 150,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.specificUsersToNotify'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `specificUserIdsToNotify`,
                      isArrayFilter: true,
                    },
                    valueOptionFields: userListData?.data ?? [],
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'willNotify',
                      filterable: true,
                      editable: false,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.willNotify'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'willNotifyResponsibleUsers',
                      filterable: true,
                      editable: false,
                    },
                    headerConfig: {
                      name: t('crm.lead.leadForm.willNotifyResponsibleUsers'),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedLeadForm,
                      setOpenAction: setOpenLeadFormDetailDrawer,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedLeadForm,
                      setOpenAction: setOpenLeadFormUpdateDrawer,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedLeadForm,
                      setOpenAction: setOpenLeadFormDeleteDialog,
                    },
                  },
                },
                customCellItems: [],
                actionHeaderName: t('crm.actions'),
              }}
              loading={leadFormListLoading || leadFormUpdateLoading || leadFormListFetching}
              listFilter={{
                filterInput: leadFormListFilter,
                setFilterInput: setLeadFormListFilter,
              }}
              update={{
                updateQuery: leadFormUpdate,
              }}
              count={leadFormListData?.count ?? 0}
              config={{
                columnVisibilityModel: {},
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.lead.leadForm.emptyStateDesc1')}
              content2={t('crm.lead.leadForm.emptyStateDesc2')}
              button1={{
                title: t('crm.lead.leadForm.create'),
                onClick: () => (props?.setOpenLeadFormCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
            />
          )
        ) : null}

      {/* <LeadFormDetailDrawer
        open={openLeadFormDetailDrawer}
        setOpen={setOpenLeadFormDetailDrawer}
        selectedLeadForm={selectedLeadForm as ILeadFormDetailResult & IHasId<number>}
        setSelectedLeadForm={setSelectedLeadForm}
        sideNavigationProps={{
          count: leadFormListData?.count ?? 0,
          handleNavigate: useHandleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: leadFormListData,
            listFilter: leadFormListFilter,
            setListFilter: setLeadFormListFilter,
            selectedEntity: selectedLeadForm,
            setSelectedEntity: setSelectedLeadForm,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      /> */}

      <LeadFormUpdateDrawerWrapper
        open={openLeadFormUpdateDrawer}
        setOpen={setOpenLeadFormUpdateDrawer}
        selectedLeadForm={selectedLeadForm}
      />

      <LeadFormDeleteDialog
        open={openLeadFormDeleteDialog}
        leadFormId={selectedLeadForm?._id ?? ''}
        setLeadForm={setSelectedLeadForm}
        onClose={() => {
          setSelectedLeadForm(undefined);
          setOpenLeadFormDeleteDialog(false);
        }}
        key={selectedLeadForm?._id}
      />
    </DefaultListGridComponent>
  );
});

export default LeadFormListGridComponent;

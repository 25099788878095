const locale = {
  // actions
  create: 'Crea Lead',
  update: 'Aggiorna Lead',
  detail: 'Dettaglio Lead',
  view: 'Visualizza Lead',
  list: 'Elenco Lead',
  addNewRole: 'Aggiungi Nuovo Ruolo',
  tagsPlaceholder: 'Aggiungi tag',
  listInfo: 'Elenco di tutti i lead.',
  weeklyReport: 'Report Settimanale',

  // situational messages
  createdSuccessfully: 'Lead creato con successo',
  updatedSuccessfully: 'Lead aggiornato con successo',
  deleteConfirm: 'Sei sicuro di voler eliminare? Questa azione non può essere annullata.',
  deletedSuccessfully: 'Lead eliminato con successo',
  createInfo: 'Puoi creare nuovi lead qui.',
  updateLeadSuccess: 'Lead aggiornato con successo',
  createLeadSuccess: 'Lead creato con successo',
  emptyState1: 'Nessun lead trovato qui.',
  emptyState2: 'Puoi creare o importare lead immediatamente.',

  // import
  importLead: 'Importa Lead',
  importTitle: 'Importa Lead',
  importText1: 'Puoi importare lead da questa pagina.',
  importText2: 'Puoi utilizzare il modulo di esempio qui sotto per pubblicare i tuoi lead.',

  // fields and names
  lead: 'Lead',
  leads: 'Lead',
  title: 'Elenco Lead',
  assigneds: 'Assegnatari',
  qualification: 'Qualificazione',
  justOwn: 'Solo Propri',
  isJunk: 'È Spam',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Spam',
  unJunk: 'Non Spam',
  prospectName: 'Nome Prospetto: ',
  businessType: 'Tipo di Business',
  leadType: 'Tipo di Lead',
  leadCount: 'lead',
  leadValue: 'Valore del Lead',
  tracking: 'Monitoraggio',
  image: 'Immagine Lead',
  
  // contact
  addContact: 'Aggiungi Contatto',
  contactedDate: 'Data di Contatto',
  emptyStateContactContent1: 'Nessun contatto trovato qui.',
  contactDetail: 'Dettaglio Contatto',
  contactHistory: 'Cronologia Contatti',
  lastContacted: 'Ultimo Contatto',
  lastContact: 'Ultimo Contatto',
  contacted: 'Contattato',

  // statistics
  leadConversions: 'Conversioni Lead',

  // lead to customer
  convertToCustomer: 'Converti in Cliente',
  customerType: 'Tipo di Cliente',
  leadConvertedSuccessfully: 'Lead convertito con successo',

  onSiteEstimateWith: 'Preventivo in loco con',

  // labels
  welcome: 'Benvenuto',
  description: 'Il tuo progresso sui lead questa settimana è incredibile. Continuiamo.',
};

export default locale; 
const locale = {
  projects: 'Проекты',
  project: 'Проект',
  projectConversions: 'Конверсии проекта',
  title: 'Проекты',
  create: 'Создать проект',
  update: 'Обновить проект',
  delete: 'Удалить проект',
  createSuccess: 'Проект успешно создан.',
  updateSuccess: 'Проект успешно обновлен.',
  deleteSuccess: 'Проект успешно удален.',
  deleteConfirm: 'Вы уверены, что хотите удалить этот проект? Это действие нельзя отменить.',
  emptyState1: 'Проекты еще не начаты.',
  emptyState2:
    'Создавайте и управляйте своими проектами здесь, чтобы отслеживать прогресс, назначать задачи и сотрудничать с вашей командой.',
  progressCalculationType: 'Расчет прогресса',
  progressPercentage: 'Прогресс %',
  isBillable: 'Оплачиваемый',
  billingType: 'Тип оплаты',
  billingRatePerHour: 'Почасовая ставка',
  members: 'Участники',
  startDate: 'Дата начала',
  deadline: 'Срок',
  estimatedHours: 'Оценочные часы',
  // labels
  welcome: 'Добро пожаловать',
  description: "Прогресс вашего проекта на этой неделе потрясающий. Давайте продолжим.",

  // detail
  changeStatus: 'Изменить статус',
  generalInfoTitle: 'Общая информация',
  customerInfoTitle: 'Информация о клиенте',
  contactPhoneNumber: 'Контактный телефон',
  membersTitle: 'Участники',
  milestone: 'Этап',
  timeSheet: 'Табель учета времени',
  projectInfoTitle: 'Информация о проекте',
  billingInfoTitle: 'Информация об оплате',
  statusChangeSuccess: 'Статус проекта успешно изменен.',
  detail: 'Детали проекта',
  noMember: 'Пока нет участников.',

  // timeSheet
  noAssigneeToCreateTimeSheet: 'Пожалуйста, назначьте пользователей проекту для создания табелей учета времени',
  emptyStateTimeSheetList1: 'Пока нет табелей учета времени.',
  addTimeSheet: 'Добавить табель',
  timeSheetCreate: 'Создать табель',
  timeSheetUpdate: 'Обновить табель',
  member: 'Участник',
  startTime: 'Время начала',
  endTime: 'Время окончания',
  duration: 'Продолжительность',
  timeSheetCreateSuccess: 'Табель учета времени успешно создан.',
  timeSheetUpdateSuccess: 'Табель учета времени успешно обновлен.',
  timeSheetDeleteSuccess: 'Табель учета времени успешно удален.',

  // milestone
  emptyStateMilestoneList1: 'Пока нет этапов.',
  addMilestone: 'Добавить этап',
  milestoneCreate: 'Создать этап',
  milestoneUpdate: 'Обновить этап',
  milestoneCreateSuccess: 'Этап успешно создан.',
  milestoneUpdateSuccess: 'Этап успешно обновлен.',
  milestoneDeleteSuccess: 'Этап успешно удален.',
  addTask: 'Добавить задачу',
};

export default locale; 
const locale = {
  projects: 'Progetti',
  project: 'Progetto',
  projectConversions: 'Conversioni Progetto',
  title: 'Progetti',
  create: 'Crea Progetto',
  update: 'Aggiorna Progetto',
  delete: 'Elimina Progetto',
  createSuccess: 'Progetto creato con successo.',
  updateSuccess: 'Progetto aggiornato con successo.',
  deleteSuccess: 'Progetto eliminato con successo.',
  deleteConfirm: 'Sei sicuro di voler eliminare questo progetto? Questa azione non può essere annullata.',
  emptyState1: 'Nessun progetto avviato ancora.',
  emptyState2:
    'Crea e gestisci i tuoi progetti qui per tracciare i progressi, assegnare compiti e collaborare con il tuo team.',
  progressCalculationType: 'Calcolo Progresso',
  progressPercentage: 'Progresso %',
  isBillable: 'Fatturabile',
  billingType: 'Tipo di Fatturazione',
  billingRatePerHour: 'Tariffa Oraria',
  members: 'Membri',
  startDate: 'Data di Inizio',
  deadline: 'Scadenza',
  estimatedHours: 'Ore Stimate',
  // labels
  welcome: 'Benvenuto',
  description: "Il progresso del tuo progetto questa settimana è incredibile. Continuiamo così.",

  // detail
  changeStatus: 'Cambia Stato',
  generalInfoTitle: 'Informazioni Generali',
  customerInfoTitle: 'Informazioni Cliente',
  contactPhoneNumber: 'Numero di Telefono di Contatto',
  membersTitle: 'Membri',
  milestone: 'Milestone',
  timeSheet: 'Foglio Ore',
  projectInfoTitle: 'Informazioni Progetto',
  billingInfoTitle: 'Informazioni Fatturazione',
  statusChangeSuccess: 'Stato del progetto cambiato con successo.',
  detail: 'Dettaglio Progetto',
  noMember: 'Nessun membro ancora.',

  // timeSheet
  noAssigneeToCreateTimeSheet: 'Assegna utenti al progetto per creare fogli ore',
  emptyStateTimeSheetList1: 'Non ci sono ancora fogli ore.',
  addTimeSheet: 'Aggiungi Foglio Ore',
  timeSheetCreate: 'Crea Foglio Ore',
  timeSheetUpdate: 'Aggiorna Foglio Ore',
  member: 'Membro',
  startTime: 'Ora di Inizio',
  endTime: 'Ora di Fine',
  duration: 'Durata',
  timeSheetCreateSuccess: 'Foglio ore creato con successo.',
  timeSheetUpdateSuccess: 'Foglio ore aggiornato con successo.',
  timeSheetDeleteSuccess: 'Foglio ore eliminato con successo.',

  // milestone
  emptyStateMilestoneList1: 'Non ci sono ancora milestone.',
  addMilestone: 'Aggiungi Milestone',
  milestoneCreate: 'Crea Milestone',
  milestoneUpdate: 'Aggiorna Milestone',
  milestoneCreateSuccess: 'Milestone creata con successo.',
  milestoneUpdateSuccess: 'Milestone aggiornata con successo.',
  milestoneDeleteSuccess: 'Milestone eliminata con successo.',
  addTask: 'Aggiungi Compito',
};

export default locale; 
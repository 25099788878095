const locale = {
  pricing: 'Цены',
  saveUpTo: 'Сэкономьте до',
  monthly: 'ежемесячно',
  monthlyUppercase: 'Ежемесячно',
  annually: 'ежегодно',
  annuallyUppercase: 'Ежегодно',
  organization: 'Организация',
  month: 'Месяц',
  billedMonthly: 'Оплата ежемесячно',
  billedAnnually: 'Оплата ежегодно',
  users: 'Пользователи',
  startTrialForFree: 'Начать бесплатную пробную версию',
  buy: 'КУПИТЬ',
  contactUs: 'Свяжитесь с нами',
  starterPlan: 'Стартовый план',
  enterprisePlan: 'Корпоративный план',
  mostPopular: 'Самый популярный',
  buyPackage: 'Купить пакет',
  buyPackageDescription:
    'Этот пакет под названием {{name}} включает лимит хранилища {{storageLimit}} ГБ и лимит пользователей {{userLimit}}.',
  dontHaveBillingInfo: 'У вас нет платежной информации для использования.',
  dontHaveCard: 'У вас нет карты для использования.',
  addBillingInfo: 'Добавить платежную информацию',
  addCard: 'Добавить карту',
  manageBillingInfo: 'Управление платежной информацией',
  manageCards: 'Управление картами',
  summary: 'Сводка',
  iAcceptThe: 'Я принимаю',
  preliminaryInformation: 'Предварительную информацию',
  distanceSalesAgreement: 'Соглашение о дистанционной продаже',
  close: 'Закрыть',
  cancel: 'Отмена',
  subscribeSuccess1: 'Ваша подписка успешно начата.',
  subscribeSuccess2: 'Добро пожаловать на борт.',
  continue: 'Продолжить',
  letUsCall: 'Позвольте нам позвонить',
  billingInfo: 'Платежная информация',
  selectedCard: 'Выбранная карта',
  currentPlan: 'Текущий план',
  renewAt: 'Продление',
  active: 'Активный',
  usageLimits: 'Лимиты использования',
  storageUsage: 'Использование хранилища',
  activeCard: 'Активная карта',
  trialPackage: 'Пробный пакет',
  trialPackageDescription:
    'Этот пакет предлагает период использования 7 дней, с лимитом хранилища {{storageLimit}} ГБ и лимитом пользователей {{userLimit}}.',
  confirmTrial: 'Вы уверены, что хотите начать пробную версию?',
  beginTrial: 'Начать пробную версию',
  contactFormSuccess: 'Ваш запрос успешно отправлен. Мы свяжемся с вами в ближайшее время.',

  packageDescription:
    'Этот пакет предлагает период использования 7 дней, с лимитом хранилища {{storageLimit}} ГБ и лимитом пользователей {{userLimit}}.',
  beginTrialSuccess: 'Пробная версия успешно начата.',
  purchaseSuccess: 'Успешно приобретено.',
  title: 'Цены',
  unknownError: 'Произошла неизвестная ошибка.',
  contactMessage: 'Телефон: {{phone}}, Название организации: {{orgName}}',
  detailTitle: 'Детали',
  trialPackageTitle: 'Пробный пакет',
  buyPackageTitle: 'Купить пакет',
  contactUsTitle: 'Свяжитесь с нами',
  preliminaryInfoTitle: 'Предварительная информация',
  distanceSalesAgreementTitle: 'Соглашение о дистанционной продаже',
  welcomeTrial1: 'Ваш пробный пакет успешно начат. Добро пожаловать на борт.',
  welcomeTrial2: 'Добро пожаловать на борт.',
  noBillingInfo: 'У вас нет платежной информации для использования.',
  billingInfoLabel: 'Платежная информация*',
  noCardInfo: 'У вас нет карты для использования.',
  selectedCardLabel: 'Выбранная карта*',
  acceptPreliminaryInfo: 'Я принимаю предварительную информацию',
  acceptDistanceSalesAgreement: 'Я принимаю соглашение о дистанционной продаже',
  preliminaryInfoDescription:
    'Этот пакет предлагает период использования 7 дней, с лимитом хранилища и разрешениями пользователей, как указано в соглашении.',
  subscriptionSuccess: 'Ваша подписка успешно начата. Добро пожаловать на борт.',
  selectedPeriodLabel: '/{{selectedPeriod}}',
  distanceSalesAgreementDescription:
    'Этот пакет предлагает период использования 7 дней, с лимитом хранилища и разрешениями пользователей, как указано в соглашении о дистанционной продаже.',
  goCRM: 'Перейти в CRM',
  tryForFree: 'Попробовать бесплатно',
  onePaymentOf: 'Один платеж в размере',

  cancelled: 'Отменено',
  expired: 'Истекло',
  completed: 'Завершено',
  failed: 'Не удалось',
  ongoingWithCancelled: 'В процессе с отменой',
  ongoingWithFailure: 'В процессе с ошибкой',

  payment: 'Оплата',
  upgrade: 'ОБНОВИТЬ',
  paymentSuccess: 'Оплата успешно завершена.',
  paymentFailure: 'Процесс оплаты завершен',
};

export default locale; 
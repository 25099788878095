const locale = {
  demo: "Démo",
  title: "Qu'est-ce que la Fenêtre <highlight>CoCRM</highlight> ?",
  description:
    "La Fenêtre CoCRM est un espace de développement commercial conçu exclusivement pour les entreprises utilisatrices de CoCRM, où elles peuvent effectuer des transferts directement aux membres de l'écosystème CoCRM concernant leurs produits, services ou campagnes, et saisir des opportunités commerciales en définissant des remises spéciales pour les particuliers et les institutions visitant notre site web.",
  featuresTitle: "Avec la Fenêtre CoCRM ;",
  features1:
    "Vous pouvez faire des offres directement aux visiteurs de notre site web avec plus d'1 million de trafic mensuel,",
  features2:
    "Vous pouvez faire diverses annonces de campagnes aux entreprises au sein de notre écosystème,",
  features3:
    "Vous pouvez fournir des informations détaillées sur vos produits et services en participant aux campagnes de blog que nous organiserons,",
  features4:
    "Vous pouvez apporter une contribution supplémentaire de 36,2% à la notoriété de votre marque !",
  buttonTitle: "Demander un devis !",
  formDescription:
    "Remplissez le formulaire et nous vous répondrons dans les plus brefs délais.",
  sendSuccess: "Merci ! Nous vous répondrons dans les plus brefs délais.",
  formTitle: "Demander un devis",
  formButtonTitle: "Soumettre",
  fullName: "Nom complet",
  email: "Email",
  phone: "Téléphone",
  orgName: "Nom de l'organisation",
};

export default locale; 
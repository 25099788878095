import { Language } from 'corede-common';
import en from './locales/en';
import tr from './locales/tr';
import it from './locales/it';
import pt from './locales/pt';
import ru from './locales/ru';
import de from './locales/de';
import es from './locales/es';
import fr from './locales/fr';
// import nl from './locales/nl';

export const resources = {
  [Language.en]: {
    translation: en,
  },
  [Language.tr]: {
    translation: tr,
  },
  [Language.it]: {
    translation: it,
  },
  [Language.pt]: {
    translation: pt,
  },
  [Language.ru]: {
    translation: ru,
  },
  [Language.de]: {
    translation: de,
  },
  [Language.es]: {
    translation: es,
  },
  [Language.fr]: {
    translation: fr,
  },
  // [Language.nl]: {
  //  translation: nl,
  //},
};

const locale = {
  demo: 'Demo',
  adjustment: 'Regolazione',
  currency: 'Valuta',
  exchangeRates: 'Tassi di cambio',
  fromCurrency: 'Da valuta',
  toCurrency: 'A valuta',
  rate: 'Tasso',
  updatedAt: 'Aggiornato il',
  language: 'Lingua',
  locale: 'Localizzazione',
  updateSettings: 'Aggiorna impostazioni',
  NDA: 'Accordo di riservatezza',
  followUpMailTemplate: 'Modello email di follow-up',
  emailType: 'Tipo di email',
  subject: 'Oggetto',
  content: 'Contenuto',
  variables: 'Variabili',
  prefix: 'Prefisso',
  paymentPrefix: 'Prefisso pagamento',
  returnPrefix: 'Prefisso reso',
  termsAndConditions: 'Termini e condizioni',
  paymentMethods: 'Metodi di pagamento',
  companyBackground: 'Background aziendale',
  companyReferences: 'Referenze aziendali',
  timezone: 'Fuso orario',
  updateSuccess: 'Impostazioni aggiornate con successo',
  name: 'Nome',
  surname: 'Cognome',
  email: 'Email',
  phone: 'Telefono',
  identityNumber: 'Numero di identità',
  vat: 'Partita IVA',
  country: 'Paese',
  state: 'Stato/Provincia',
  city: 'Città',
  street: 'Via',
  address: 'Indirizzo',
  taxOffice: 'Ufficio fiscale',
  zip: 'CAP',
  fullName: 'Nome completo',
  update: 'Aggiorna',
  create: 'Crea',
  updateBillingInfo: 'Aggiorna informazioni di fatturazione',
  updateBillingInfoSuccess: 'Informazioni di fatturazione aggiornate con successo',
  commonSettingsUpdateSuccess: 'Impostazioni aggiornate con successo',
  save: 'Salva',
  organizationSettings: 'Organizzazione',
  monthly: 'mensile',
  trial: 'Prova',
  free: 'Gratuito',
  trialEnds: 'La prova termina',
  browsePackages: 'Sfoglia pacchetti',
  cancelSubscription: 'Annulla abbonamento',
  updateOrganizationLogoSuccess: 'Logo dell\'organizzazione aggiornato con successo',
  organizationLogo: 'Logo dell\'organizzazione',
  summary: 'Riepilogo',
  website: 'Sito web',
  legalName: 'Nome legale',
  dateFormat: 'Formato data',
  timeFormat: 'Formato ora',
  vatNumber: 'Partita IVA',
  taxRate: 'Aliquota fiscale',
  exchangeRateMode: 'Modalità tasso di cambio',
  general: 'Generale',
  localizations: 'Localizzazioni',
  finance: 'Finanza',
  generalInfo: 'Informazioni generali',
  socialMedia: 'Social media',
  billingInfo: 'Informazioni di fatturazione',
  cards: 'Carte',
  currentPlan: 'Piano attuale',
  invoices: 'Fatture',
  cardListSecure: 'Le tue carte sono memorizzate nel database iyzico.',
  noInvoiceContent1: "Non hai ancora nessuna fattura.",
  noInvoiceContent2:
    'Tieni traccia e gestisci i pagamenti per le tue fatture qui per mantenere le tue finanze organizzate e aggiornate.',
  noLanguageContent1: "Non hai ancora nessuna lingua.",
  noLanguageContent2:
    'Crea e gestisci le lingue qui per rendere il tuo sito web accessibile a un pubblico globale.',
  noLeadContent1: 'Le impostazioni dei lead non sono attualmente disponibili.',
  noLeadContent2:
    'Personalizza come i lead vengono acquisiti, assegnati e tracciati per ottimizzare il tuo flusso di lavoro CRM.',
  noCustomerContent1: 'Nessuna impostazione cliente configurata ancora.',
  noCustomerContent2:
    'Configura le preferenze per gestire efficacemente le informazioni e le interazioni dei clienti all\'interno del tuo CRM.',
  noEstimateContent1: 'Nessuna impostazione fattura configurata ancora.',
  noEstimateContent2:
    'Personalizza qui le tue preferenze di fatturazione per semplificare la fatturazione e garantire registrazioni accurate.',
  noProposalContent1: 'Le impostazioni delle proposte non sono attualmente disponibili.',
  noProposalContent2:
    'Personalizza qui le tue preferenze di proposta per semplificare la comunicazione con i clienti e migliorare le vendite.',
  noInvoiceContent3: 'Nessuna impostazione fattura configurata ancora.',
  noInvoiceContent4:
    'Personalizza qui le tue preferenze di fatturazione per semplificare la fatturazione e garantire registrazioni accurate.',
  noProjectContent1: 'Le impostazioni del progetto non sono ancora state consultate.',
  noProjectContent2:
    'Personalizza qui le tue preferenze di progetto per ottimizzare la gestione delle attività e il flusso di lavoro nel tuo CRM.',
  noTaskContent1: 'Le impostazioni delle attività non sono ancora state consultate.',
  noTaskContent2:
    'Configura qui le tue preferenze di attività per semplificare la gestione delle attività e migliorare la produttività del team.',
  noTicketContent1: 'Le impostazioni dei ticket/supporto non sono ancora state consultate.',
  noTicketContent2:
    'Configura qui le tue preferenze per gestire le richieste di supporto dei clienti e garantire risoluzioni tempestive.',
  noFileManagementContent1: 'Le impostazioni di gestione dei file non sono ancora state consultate.',
  noFileManagementContent2:
    'Configura qui le tue preferenze per organizzare e gestire i documenti in modo efficiente all\'interno del tuo CRM.',
  noCalendarContent1: 'Le impostazioni del calendario non sono ancora state consultate.',
  noCalendarContent2:
    'Configura qui le tue preferenze per personalizzare il tuo calendario e ottimizzare la pianificazione all\'interno del tuo CRM.',
  noNotificationContent1: 'Le impostazioni delle notifiche non sono ancora state consultate.',
  noNotificationContent2:
    'Personalizza qui le tue preferenze di notifica per rimanere informato e aggiornato sulle attività importanti all\'interno del tuo CRM.',
  noLeadPlusContent1: 'Nessun prodotto o piano Lead+ attivo.',
  noLeadPlusContent2:
    'Acquista o autorizza un piano per sbloccare l\'accesso completo e personalizzare le tue impostazioni Lead+.',
  noMarketingContent1: 'Nessun prodotto o piano Marketing attivo.',
  noMarketingContent2:
    'Acquista o autorizza un piano per sbloccare l\'accesso completo e personalizzare le tue impostazioni Marketing.',
  noWindowContent1: 'Nessun prodotto o piano Window attivo.',
  noWindowContent2:
    'Acquista o autorizza un piano per sbloccare l\'accesso completo e personalizzare le tue impostazioni Window.',
  noCommunityContent1: 'Nessun prodotto o piano Community attivo.',
  noCommunityContent2:
    'Acquista o autorizza un piano per sbloccare l\'accesso completo e personalizzare le tue impostazioni Community.',
  noIntegrationsContent1: 'Nessuna integrazione configurata ancora.',
  noIntegrationsContent2:
    'Configura qui le integrazioni per migliorare le capacità di CoCRM e semplificare i flussi di lavoro con connessioni autorizzate.',
  noMenuContent1: 'Le impostazioni del menu non sono attualmente disponibili.',
  noMenuContent2:
    'Autorizza l\'accesso per personalizzare il layout del tuo menu e semplificare la navigazione nel tuo CRM.',
  noLegalAgreementsContent1: 'Le impostazioni degli accordi legali non sono attualmente disponibili. ',
  noLegalAgreementsContent2:
    'Autorizza l\'accesso per gestire e personalizzare gli accordi legali all\'interno del tuo CRM.',
  noCrmContent1: 'Nessun prodotto o piano CRM attivo.',
  noCrmContent2:
    'Acquista o autorizza un piano per sbloccare l\'accesso completo e personalizzare le tue impostazioni CRM.',
  noUserListContent1: 'Nessun prodotto o piano Lista Utenti attivo.',
  noUserListContent2:
    'Acquista o autorizza un piano per sbloccare l\'accesso completo e personalizzare le tue impostazioni Lista Utenti.',
  noPermissionsContent1: 'Nessun prodotto o piano Permessi attivo.',
  noPermissionsContent2:
    'Acquista o autorizza un piano per sbloccare l\'accesso completo e personalizzare le tue impostazioni Permessi.',
  lead: 'Lead',
  crm: 'CRM',
  menu: 'Menu',
  customer: 'Cliente',
  estimate: 'Preventivo',
  proposal: 'Proposta',
  invoice: 'Fattura',
  project: 'Progetto',
  task: 'Attività',
  ticket: 'Ticket',
  fileManagement: 'Gestione file',
  calendar: 'Calendario',
  notification: 'Notifica',
  leadPlus: 'Lead+',
  marketing: 'Marketing',
  window: 'Window',
  community: 'Community',
  integrations: 'Integrazioni',
  userList: 'Lista utenti',
  permissions: 'Permessi',
  userType: 'Tipo utente',
  companyName: 'Nome azienda',
  allowableFileFormats: 'Formati file consentiti',

  // currentPlan
  yourCurrentPlan: 'Il tuo piano attuale è ',
  yourCurrentPlanDesc: 'Un semplice inizio per tutti',
  activeUntil: 'Attivo fino al',
  activeUntilDesc: 'Ti invieremo una notifica alla scadenza dell\'abbonamento',
  paymentDesc: 'Il pagamento verrà addebitato sul tuo account',
  paymentForTrialDesc: 'Devi acquistare un abbonamento per continuare a utilizzare',
  perMonth: 'Al mese',
  prtYear: 'All\'anno',
  perMonthDesc: 'Puoi annullare il tuo abbonamento in qualsiasi momento',
  upgradePlan: 'Aggiorna piano',
  attentionTitle: 'Abbiamo bisogno della tua attenzione!',
  attentionDesc: 'Il tuo piano richiede aggiornamenti',
  days: 'Giorni',
  of: 'di',
  remainingDesc: 'giorni rimanenti prima che il tuo piano richieda un aggiornamento',
  usageOfStorage: 'Utilizzo dello storage',
  users: 'Utenti',

  //legalAgreements
  legalAgreements: 'Accordi legali',
  createLegalAgreement: 'Crea accordo legale',
  updateLegalAgreement: 'Aggiorna accordo legale',
  deleteLegalAgreement: 'Elimina accordo legale',
  createLegalAgreementSuccess: 'Accordo legale creato con successo',
  updateLegalAgreementSuccess: 'Accordo legale aggiornato con successo',
  deleteLegalAgreementSuccess: 'Accordo legale eliminato con successo',
  deleteLegalAgreementDesc: 'Sei sicuro di voler eliminare questo accordo legale?',

  // cards
  deleteCard: 'Elimina carta',
  addCard: 'Aggiungi nuova carta',
  createCardSuccess: 'Carta creata con successo',
  addCardSuccess: 'Carta aggiunta con successo',
  deleteCardSuccess: 'Carta eliminata con successo',
  deleteCardDesc: 'Sei sicuro di voler eliminare questa carta?',
  requiredCard: 'Il numero della carta è obbligatorio',
  requiredCardHolder: 'Il nome del titolare della carta è obbligatorio',
  requiredExpireMonth: 'Il mese di scadenza è obbligatorio',
  requiredExpireYear: 'L\'anno di scadenza è obbligatorio',
  requiredCvc: 'Il CVC è obbligatorio',
};

export default locale; 
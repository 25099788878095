import { IBaseError, IFilter, Language } from "corede-common";
import { BASE_URL } from "utils";
import { getAccessToken } from "utils/getUserInfo";
import { enqueueSnackbar } from "notistack";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { t } from "i18next";
import axios from "axios";
import moment from "moment";

/**
 *
 * @param params.filePrefix fileName = `<filePrefix>-${moment().format("DD-MM-YYYY")}.xlsx`
 * @param params.downloadUrl url = ``${BASE_URL}/<downloadUrl>``
 */
export const handleDownloadList = (params: {
  filePrefix: string;
  downloadUrl: string;
  filter?: IFilter;
  currentLanguage: Language;
  successMessage?: string;
}) => {
  const data = JSON.stringify({
    filter: params.filter ?? {},
  });

  const url = `${BASE_URL}/${params.downloadUrl}`;

  const config = {
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    data: data,
    responseType: "blob",
  };

  axios
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .request(config as any)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${params.filePrefix}-${moment().format("DD-MM-YYYY")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar(t("crm.downloadSuccessful"), {
        variant: "success",
      });
    })
    .catch((error) => {
      DefaultErrorHandlerUseEffect(error as IBaseError, params.currentLanguage);
    });
};

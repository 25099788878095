const locale = {
  // actions
  create: 'Crea Cliente',
  update: 'Aggiorna Cliente',
  detail: 'Dettaglio Cliente',
  view: 'Visualizza Cliente',
  list: 'Elenco Clienti',
  addNewRole: 'Aggiungi Nuovo Ruolo',
  tagsPlaceholder: 'Aggiungi tag',
  listInfo: 'Elenco di tutti i clienti.',
  weeklyReport: 'Report Settimanale',
  copyBillingInfo: 'Copia Informazioni di Fatturazione',

  // situational messages
  createdSuccessfully: 'Cliente creato con successo',
  updatedSuccessfully: 'Cliente aggiornato con successo',
  deleteConfirm: 'Sei sicuro di voler eliminare questo cliente? Questa azione non può essere annullata.',
  deletedSuccessfully: 'Cliente eliminato con successo',
  createInfo: 'Puoi creare nuovi clienti qui.',
  updateCustomerSuccess: 'Cliente aggiornato con successo',
  createCustomerSuccess: 'Cliente creato con successo',
  emptyState1: 'Nessun cliente trovato',
  emptyState2: 'Per favore crea un nuovo cliente',

  // import
  importCustomer: 'Importa Cliente',
  import: 'Importa Cliente',
  importTitle: 'Importa Clienti',
  importText1: 'Puoi importare clienti da questa pagina.',
  importText2: 'Puoi utilizzare il modulo di esempio qui sotto per pubblicare i tuoi clienti.',

  // fields and names
  title: 'Clienti',
  customer: 'Cliente',
  customers: 'Clienti',
  assigneds: 'Assegnati',
  qualification: 'Qualificazione',
  justOwn: 'Solo Propri',
  isJunk: 'È Spam',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Spam',
  unJunk: 'Non Spam',
  prospectName: 'Nome Prospetto',
  businessType: 'Tipo di Business',
  customerType: 'Tipo di Cliente',
  customerCount: 'clienti',
  customerValue: 'Valore Cliente',

  // contact
  addContact: 'Aggiungi Contatto',
  contactedDate: 'Data Contatto',
  emptyStateContactContent1: 'Nessun contatto trovato qui.',
  contactDetail: 'Dettaglio Contatto',
  contactHistory: 'Cronologia Contatti',
  lastContacted: 'Ultimo Contatto',
  lastContact: 'Ultimo Contatto',
  contacted: 'Contattato',
  otherContacts: 'Altri Contatti',

  onSiteEstimateWith: 'Preventivo in loco con',

  // new
  customerListTitle: 'Elenco Clienti',
  headerTitle: 'Clienti',
  relatedCustomers: 'Clienti Correlati',
  b2bB2c: 'B2B / B2C',
  customerNameLabel: 'Nome Cliente',
  customerEmailLabel: 'Email Cliente',
  customerPhoneLabel: 'Telefono Cliente',
  customerTypeLabel: 'Tipo di Cliente',
  customerPictureLabel: 'Immagine Cliente',
  customerLanguageLabel: 'Lingua Cliente',

  createSuccess: 'Cliente creato con successo',

  prospectType: 'Tipo di Prospetto',
  prospectAddress: 'Indirizzo Prospetto',
  prospectContactName: 'Nome Contatto Prospetto',

  // accordions
  primaryContact: 'Contatto Principale*',
  primaryBillingInfo: 'Informazioni di Fatturazione Principali',
  primaryShippingInfo: 'Informazioni di Spedizione Principali',
  detailInformation: 'Informazioni Dettagliate',
  address: 'Indirizzo',

  // labels
  welcome: 'Benvenuto',
  description: 'Il tuo progresso con i clienti questa settimana è incredibile. Continuiamo così.',

  // new keys
  customerDetail: 'Dettaglio Cliente',
  viewMüşteri: 'Visualizza',
  createMüşteri: 'Crea Cliente',
  columnParentCategory: 'Categoria Principale',
  columnActions: 'Azioni',
  createCustomerTitle: 'Crea Cliente',
  uploadPictureText: 'Carica Immagine',
  createCustomerButton: 'Crea Cliente',
  selectIconTitle: 'Seleziona un\'Icona',
  searchIcons: 'Cerca Icone',
  searchForAnIcon: 'Cerca un\'icona',
};

export default locale; 
/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  IBaseError,
  IGraphqlVariables,
  isNullOrUndefinedOrEmptyObject,
  PermissionAction,
} from 'corede-common';
import {
  ICustomerContactListInput,
  ICustomerContactListItemResult,
  PermissionSubject,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useCustomerContactListQuery } from '../../context/contact.api';
import { useCustomerUpdateMutation } from '../../../customer';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import { IPendingNavigation } from 'apps/crm/util/handleSideNavigationNavigate.action';
import ContactCreateWrapper from '../create/ContactCreateDrawer.wrapper';
import ContactDeleteDialog from '../delete/ContactDeleteDialog';
import ContactUpdateWrapper from '../update/ContactUpdateDrawer.wrapper';
import { CustomerContactDetailDialog } from './CustomerContact-detail.dialog';
import { usePermissions } from 'permission/PermissionContext';
import { representBooleanAsString } from 'utils/representationHelper';
import { BaseGridColumnDefFactory } from 'components/baseDataGrid/factories/base-grid-column.factory';

export interface IContactListGridComponent {
  // contact
  setOpenContactCreateDrawer?: (value: boolean) => void | undefined;
}

const ContactListGridComponent = memo((props: IContactListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  // states
  const [selectedContact, setSelectedContact] =
    useState<TListGridSelectedEntity<ICustomerContactListItemResult>>();
  const [openContactDetailDrawer, setOpenContactDetailDrawer] = useState(false);
  const [openContactDeleteDialog, setOpenContactDeleteDialog] = useState(false);
  const [openContactUpdateDrawer, setOpenContactUpdateDrawer] = useState(false);
  const [contactCreateOpen, setContactCreateOpen] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const { hasPermission } = usePermissions();
  const hasListPermission = hasPermission({
    subject: PermissionSubject.customer,
    action: PermissionAction.list,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.customer,
    action: PermissionAction.update,
  });

  const [contactListFilter, setContactListFilter] = useState<
    IGraphqlVariables<ICustomerContactListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  // queries
  const {
    data: contactListData,
    isLoading: contactListLoading,
    isFetching: contactListFetching,
    error: contactListError,
    refetch,
  } = useCustomerContactListQuery(contactListFilter);

  // used for direct update in the list rows
  const [customerUpdate, { isLoading: customerUpdateLoading, error: customerUpdateError }] =
    useCustomerUpdateMutation();

  // effects
  useEffect(() => {
    refetch();
  }, [contactListFilter]);

  // useEffects.errors
  useEffect(() => {
    DefaultErrorHandlerUseEffect(contactListError as IBaseError, currentLanguage);
  }, [contactListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(customerUpdateError as IBaseError, currentLanguage);
  }, [customerUpdateError]);

  const isEmptyState =
    contactListData?.data?.length === 0 &&
    !contactListLoading &&
    !contactListFetching &&
    isNullOrUndefinedOrEmptyObject(contactListFilter?.input?.filter);

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedContact}
      setSelectedEntity={setSelectedContact}
      listFilter={contactListFilter}
      setListFilter={setContactListFilter}
      listData={contactListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      {contactListData ? (
        !isEmptyState ? (
          <BaseDataGrid
            rows={
              contactListData?.data?.map((contact, index) => ({
                id:
                  ((contactListFilter.input?.pagination?.page || 1) - 1) *
                    (contactListFilter.input?.pagination?.pageSize || 0) +
                  index +
                  1,
                _id: contact._id,
                contactInfo: {
                  fullName: contact.customer.name || '-',
                  image: contact.customer.image?.thumbnailPublicUrl || '',
                },
                customer: contact.customer,
                firstName: contact.firstName,
                lastName: contact.lastName,
                email: contact.email,
                phone: contact.phone,
                isPrimary: representBooleanAsString({
                  data: Boolean(contact.isPrimary),
                  t: t,
                }),
                position: contact.position,
              })) || []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'id',
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                    align: 'center',
                    editable: false,
                  },
                  headerConfig: {
                    name: '#',
                    align: 'center',
                  },
                },
              },
              BaseGridColumnDefFactory.EntityProfileColumnDef({
                field: 'contactInfo',
                headerName: t('crm.customerLabel'),
                headerIcon: 'radix-icons:person',
              }),
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'firstName',
                    filterable: true,
                    editable: false,
                  },
                  headerConfig: {
                    icon: 'radix-icons:text',
                    name: t('crm.firstName'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'lastName',
                    editable: false,
                  },
                  headerConfig: {
                    icon: 'radix-icons:crosshair-2',
                    name: t('crm.lastName'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'email',
                    editable: false,
                  },
                  headerConfig: {
                    icon: 'radix-icons:envelope-closed',
                    name: t('crm.email'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'phone',
                    filterable: false,
                    editable: false,
                  },
                  headerConfig: {
                    icon: 'mi:call',
                    name: t('crm.phone'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'position',
                    width: 140,
                    filterable: false,
                    editable: false,
                  },
                  headerConfig: {
                    icon: 'streamline:web',
                    name: t('crm.position'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'isPrimary',
                    editable: false,
                  },
                  headerConfig: {
                    icon: 'material-symbols:business-center-outline',
                    name: t('crm.isPrimary'),
                  },
                },
              },
            ]}
            actionColumn={{
              width: 80,
              defaultActions: {
                view: hasListPermission
                  ? {
                      clickConfig: {
                        setSelectedRow: setSelectedContact,
                        setOpenAction: setOpenContactDetailDrawer,
                      },
                    }
                  : undefined,
                edit: hasUpdatePermission
                  ? {
                      clickConfig: {
                        setSelectedRow: setSelectedContact,
                        setOpenAction: setOpenContactUpdateDrawer,
                      },
                    }
                  : undefined,
                delete: hasUpdatePermission
                  ? {
                      clickConfig: {
                        setSelectedRow: setSelectedContact,
                        setOpenAction: setOpenContactDeleteDialog,
                      },
                    }
                  : undefined,
              },
              actionHeaderName: t('crm.actions'),
            }}
            loading={contactListLoading || customerUpdateLoading || contactListFetching}
            listFilter={{
              filterInput: contactListFilter,
              setFilterInput: setContactListFilter,
            }}
            update={{
              updateQuery: hasUpdatePermission ? customerUpdate : undefined,
            }}
            count={contactListData?.count ?? 0}
            config={{
              columnVisibilityModel: {
                fullName: false,
                website: false,
                company: false,
                sector: false,
                country: false,
                state: false,
                city: false,
                language: false,
                contactValue: false,
                department: false,
              },
              features: [],
            }}
            toolbar={{
              customActions: [],
            }}
          />
        ) : (
          <EmptyState
            content1={t('crm.customer.contact.emptyState1')}
            content2={t('crm.customer.contact.emptyState2')}
            button1={{
              title: t('crm.customer.contact.createContact'),
              onClick: () => setContactCreateOpen(true),
              leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              permission: {
                subject: PermissionSubject.customer,
                action: PermissionAction.update,
              },
            }}
          />
        )
      ) : null}

      <ContactCreateWrapper open={contactCreateOpen} setOpen={setContactCreateOpen} />

      <ContactUpdateWrapper
        open={openContactUpdateDrawer}
        setOpen={setOpenContactUpdateDrawer}
        selectedContact={selectedContact}
      />

      <ContactDeleteDialog
        open={openContactDeleteDialog}
        onClose={() => {
          setSelectedContact(undefined);
          setOpenContactDeleteDialog(false);
        }}
        item={selectedContact}
      />

      <CustomerContactDetailDialog
        item={selectedContact}
        open={openContactDetailDrawer}
        onClose={() => {
          setSelectedContact(undefined);
          setOpenContactDetailDrawer(false);
        }}
      />
    </DefaultListGridComponent>
  );
});

export default ContactListGridComponent;

/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, useMediaQuery } from '@mui/material';
import { IInvoicePaymentDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { PaymentDetailDrawerLeftPanelHeaderComponent } from './PaymentDetailDrawer-leftPanel-header.component';
import { PaymentDetailDrawerLeftPanelContactDetailComponent } from './PaymentDetailDrawer-leftPanel-contactDetail.component';
import { PaymentDetailDrawerLeftPanelAssignedsComponent } from './PaymentDetailDrawer-leftPanel-assigned.component';

export interface IPaymentDetailDrawerLeftPanelComponentProps {
  paymentDetailData: IInvoicePaymentDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentDetailDrawerLeftPanelComponent = (
  props: IPaymentDetailDrawerLeftPanelComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <DefaultDetailDrawerLeftPanelComponent<IInvoicePaymentDetailResult>
      entityData={props.paymentDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <PaymentDetailDrawerLeftPanelHeaderComponent
        paymentDetailData={props.paymentDetailData}
        setOpen={props.setOpen}
      />

      {/* General Details */}
      <PaymentDetailDrawerLeftPanelContactDetailComponent
        paymentDetailData={props.paymentDetailData}
        setOpen={props.setOpen}
      />

      <Divider />
    </DefaultDetailDrawerLeftPanelComponent>
  );
};

import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';
import { string } from 'yup';

export const validateCreateCardInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    expireMonth: string()
      .required(getTranslatedText('cardValidation.required'))
      .test('is-valid-month', getTranslatedText('cardValidation.invalid'), (value) => {
        const month = parseInt(value, 10);
        return month >= 1 && month <= 12;
      }),
    expireYear: string()
      .required(getTranslatedText('cardValidation.required'))
      .test('is-valid-year', getTranslatedText('cardValidation.expired'), function (value) {
        const year = parseInt(value, 10) + 2000;
        const expireMonth = parseInt(this.parent.expireMonth, 10);
        if (!expireMonth || !year) return true;

        const today = new Date();
        const cardDate = new Date(year, expireMonth - 1);
        return cardDate >= new Date(today.getFullYear(), today.getMonth());
      }),
  }),
});

import {
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridSingleSelectColDef,
} from '@mui/x-data-grid-pro';
import { IGridColConfigInput } from '../infra/IGridColConfigInput';
import { Box, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { enumValues } from 'corede-common';
import { TEnum, TEnumColorType } from '../infra/types';
import { generateBaseSingleSelectColDef } from '../infra/GridColDefFactory';
import { getTranslatedEnumValue } from 'localization';
import { Iconify } from 'components/iconify';
import { Add } from '@mui/icons-material';
import BaseChip from 'components/chip/BaseChip';

export const BaseEnumGridColumnDef = (params: {
  config: IGridColConfigInput;
  enumType: TEnum;
  enumColor?: TEnumColorType;
}): GridSingleSelectColDef => {
  const baseColDef = generateBaseSingleSelectColDef({
    config: params.config,
  });
  
  const valueOptions = enumValues(params.enumType).map((enumValue) => ({
    label: getTranslatedEnumValue(enumValue),
    value: enumValue,
  }));

  baseColDef.valueOptions = valueOptions;

  baseColDef.renderCell = (cellParams: GridRenderCellParams) => {
    const color =
      params.enumColor && params.enumColor[cellParams.value]
        ? params.enumColor[cellParams.value]
        : 'primary';

    return (
      <BaseChip
        label={getTranslatedEnumValue(cellParams.value)}
        color={color}
        borderRadius={'4px'}
        fontWeight={'400'}
        borderColor={'transparent'}
        backgroundColor={'#212b3614'}
      />
    );
  };

  if (params.config.column.editable) {
    baseColDef.renderEditCell = (editCellParams: GridRenderEditCellParams) => {
      // const currentValue = params.value || params.row.language || ""; // TODO: check this one
      const currentValue = editCellParams.value || '';
      return (
        <Select
          value={currentValue}
          label={params.config.editCellConfig?.label ?? ''}
          onChange={(e) => {
            // TODO: refactor
            editCellParams.api.setEditCellValue({
              ...editCellParams,
              value: e.target.value,
            });
          }}
          sx={{ width: '100%' }}
        >
          {valueOptions.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              <Typography fontSize={'13px'} fontWeight={'bold'}>
                {item.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      );
    };
  }

  if (params.config.headerConfig) {
    baseColDef.renderHeader = (headerParams: GridColumnHeaderParams) => (
      <Stack
        direction="row"
        alignItems={'center'}
        justifyContent={params.config.headerConfig.align ?? 'left'}
      >
        <Iconify
          icon={params.config.headerConfig.icon}
          sx={{ width: '15px', mr: 1, color: 'primary.main' }}
        />
        <span style={{ fontWeight: 500 }}>{headerParams.colDef.headerName}</span>
        {params.config.headerConfig.creatable && (
          <IconButton
            size="small"
            onClick={(e: any) => {
              e.stopPropagation();
              if (params.config.headerConfig.createFunc) {
                params.config.headerConfig.createFunc(true);
              }
            }}
          >
            <Add fontSize="small" />
          </IconButton>
        )}
      </Stack>
    );
  }

  return baseColDef;
};

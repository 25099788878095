import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  Stack,
  Divider,
} from '@mui/material';
import { ITableProps } from '../interfaces';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { IEstimateDetailProspectResult } from 'corede-common-cocrm';
import { convertCurrency } from 'utils/convertCurrency';

const TableComponent = (props: { estimateData: IEstimateDetailProspectResult | undefined }) => {
  const { t } = useTranslation();

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 'none', bordeBottom: 'none', borderRadius: 3 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell sx={{ textTransform: 'uppercase' }}>
                {t('auth.productDescription')}
              </TableCell>
              <TableCell sx={{ textTransform: 'uppercase' }} align="center">
                {t('auth.qty')}
              </TableCell>
              <TableCell sx={{ textTransform: 'uppercase' }} align="center">
                {t('auth.rate')}
              </TableCell>
              <TableCell sx={{ textTransform: 'uppercase' }} align="center">
                {t('auth.tax')}
              </TableCell>
              <TableCell sx={{ textTransform: 'uppercase' }} align="center">
                {t('auth.amount')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.estimateData?.products &&
              props.estimateData?.products.length > 0 &&
              props.estimateData?.products.map((row: any, index) => (
                <TableRow key={index + 1}>
                  <TableCell sx={{ p: 2 }}>{index + 1}</TableCell>
                  <TableCell sx={{ p: 2, maxWidth: '284px' }}>
                    <Typography variant="body1" fontWeight="bold">
                      {row.name ?? ''}
                    </Typography>
                    <div>
                      {row.description ? parse(row.description) : ''}
                    </div>
                  </TableCell>
                  <TableCell sx={{ p: 2 }} align="center">
                    {row.quantity ?? ''}
                  </TableCell>
                  <TableCell sx={{ p: 2 }} align="center">
                    {row.productUnit ?? ''}
                  </TableCell>
                  <TableCell sx={{ p: 2 }} align="center">
                    {row.tax ?? ''}
                  </TableCell>
                  <TableCell sx={{ p: 2 }} align="center">
                    {row.finalPrice ?? ''}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={9}
          visibility={
            props.estimateData?.salesAgents && props.estimateData?.salesAgents?.length > 0
              ? 'visible'
              : 'hidden'
          }
        >
          <Typography variant={'body2'} sx={{ fontWeight: 500 }}>
            <strong>{t('crm.preSale.estimate.salesAgentsTitle')}</strong>{' '}
            {props.estimateData?.salesAgents?.map((agent) => agent.name).join(', ')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <>
            <Typography fontSize={14} sx={{ fontWeight: 500 }}>
              {t('crm.totalPrice')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Stack direction="row" justifyContent="space-between">
              <Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
                {t('crm.subTotal')}
              </Typography>

              <Typography variant={'body2'} sx={{ fontWeight: 500 }}>
                {convertCurrency(props.estimateData?.currency)}{' '}
                {props.estimateData?.totalPrice?.subTotal}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
                {t('crm.tax')}
              </Typography>
              <Typography variant={'body2'} sx={{ fontWeight: 500 }}>
                {convertCurrency(props.estimateData?.currency)}{' '}
                {props.estimateData?.totalPrice?.taxTotal}
              </Typography>
            </Stack>
            {props.estimateData?.totalPrice?.discount ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
                  {t('crm.discount')}
                </Typography>
                <Typography variant={'body2'} sx={{ fontWeight: 500 }}>
                  {convertCurrency(props.estimateData?.currency)}{' '}
                  {props.estimateData?.totalPrice?.discount}
                </Typography>
              </Stack>
            ) : null}
            <Stack direction="row" justifyContent="space-between">
              <Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
                {t('crm.total')}
              </Typography>
              <Typography variant={'body2'} sx={{ fontWeight: 500 }}>
                {convertCurrency(props.estimateData?.currency)}{' '}
                {props.estimateData?.totalPrice?.total}
              </Typography>
            </Stack>
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default TableComponent;

const locale = {
  tasks: "Tareas",
  task: "Tarea",
  taskConversions: "Conversiones de Tareas",
  title: "Tareas",
  addTask: "Añadir Tarea",
  emptyState1: "Aún no hay tareas asignadas.",
  emptyState2: "Cree tareas aquí para organizar su trabajo, establecer prioridades y mantenerse al día con sus actividades de CRM.",
  create: "Crear Tarea",
  subject: "Asunto",
  description: "Descripción",
  startDate: "Fecha de inicio",
  dueDate: "Fecha de vencimiento",
  assigneeIds: "Asignados",
  followerIds: "Seguidores",
  checklist: "Lista de verificación",
  department: "Departamento",
  priority: "Prioridad",
  tags: "Etiquetas",
  hourlyRate: "Tarifa por hora",
  hourlyRateCurrency: "Moneda de tarifa por hora",
  isBillable: "Facturable",
  isRecurrent: "Recurrente",
  repeatDays: "Días de repetición",
  totalCycle: "Ciclo total",
  addChecklistItem: "Añadir elemento a la lista",
  noAssigneeToCreateChecklist: "Asigne usuarios para crear listas de verificación",
  assignee: "Asignado",
  createTaskSuccess: "Tarea creada con éxito.",
  deleteTask: "Eliminar Tarea",
  deleteTaskContent: "¿Está seguro de que desea eliminar esta tarea? Esta acción no se puede deshacer.",
  deletedSuccessfully: "Tarea eliminada con éxito",
  updateTask: "Actualizar Tarea",
  updateSuccess: "Tarea actualizada con éxito.",

  detail: "Detalle de la Tarea",
  subtask: "Subtarea",

  addSubtask: "Añadir Subtarea",
  updateStatus: "Actualizar Estado",

  createdBy: "Tarea creada por ",

  // detailDrawer
  detailedInfo: "Información Detallada",
  recurrentInfo: "Información de Recurrencia",
  billableInfo: "Información de Facturación",
  documents: "Documentos",
  editAssignees: "Editar Asignados",
};

export default locale; 
const locale = {
  productCategories: 'Categorie',
  title: 'Categorie di Prodotti/Servizi',
  create: 'Crea Categoria',
  delete: 'Elimina Categoria',
  deleteConfirm: 'Sei sicuro di voler eliminare questa categoria: ',
  deletedSuccessfully: 'Categoria eliminata con successo',
  createCategory: 'Crea Categoria',
  categoryCreateSuccess: 'Categoria creata con successo',
  name: 'Nome Categoria',
  icon: 'Icona Categoria',
  showMore: 'Mostra di più',
  update: 'Aggiorna',
  updateCategory: 'Aggiorna Categoria',
  categoryUpdateSuccess: 'Categoria aggiornata con successo',
  en: 'Inglese',
  tr: 'Turco',
  createSuccess: 'Categoria creata con successo',
  updateSuccess: 'Categoria prodotto aggiornata con successo',
  actions: 'Azioni',
  createdAt: 'Creato il',
  emptyState1: 'Nessuna categoria trovata',
  emptyState2: 'Per favore crea una nuova categoria',
  createProductCategory: 'Crea Categoria Prodotto',
};

export default locale; 
const locale = {
  expenses: 'Despesas',
  expense: 'Despesa',
  expenseConversions: 'Conversões de Despesas',
  title: 'Despesas',
  create: 'Criar Despesa',
  delete: 'Excluir Despesa',
  update: 'Atualizar Despesa',
  deletedSuccessfully: 'Despesa excluída com sucesso.',
  createSuccess: 'Despesa criada com sucesso.',
  updateSuccess: 'Despesa atualizada com sucesso.',
  deleteConfirm: 'Tem certeza de que deseja excluir esta despesa? Esta ação não pode ser desfeita.',
  emptyState1: 'Nenhuma despesa adicionada ainda.',
  emptyState2:
    'Armazene e gerencie todos os detalhes de despesas aqui para agilizar seu faturamento e manter acordos claros com seus clientes.',
  downloadFormSuccessfully: 'Lista de despesas baixada com sucesso.',
  importExpense: 'Importar Despesas',
  importTitle: 'Importar Despesas',
  importText1: 'Você pode importar seus dados de despesas de um arquivo Excel.',
  importText2: 'Clique no botão Baixar Exemplo para baixar o modelo.',
  
  // labels
  id: "ID da Despesa",
  totalAmount: "Valor Total",
  totalTax: "Imposto Total",
  isBillable: "É Faturável",
  isInvoiced: "Foi Faturado",

  // detailDrawer
  changeStatus: 'Alterar Status',
  generalInfoTitle: 'Informações Gerais',
  customerInfoTitle: 'Informações do Cliente',
  expenseInfoTitle: 'Informações da Despesa',
  projectInfoTitle: 'Informações do Projeto',
  projectName: 'Nome do Projeto',
  paymentMethod: 'Método de Pagamento',
};

export default locale; 
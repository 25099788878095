const locale = {
  contracts: 'Contratti',
  contract: 'Contratto',
  contractConversions: 'Conversioni di Contratti',
  title: 'Contratti',
  create: 'Crea Contratto',
  update: 'Aggiorna Contratto',
  delete: 'Elimina Contratto',
  createSuccess: 'Contratto creato con successo.',
  updateSuccess: 'Contratto aggiornato con successo.',
  deleteSuccess: 'Contratto eliminato con successo.',
  deleteConfirm: 'Sei sicuro di voler eliminare questo contratto? Questa azione non può essere annullata.',
  emptyState1: 'Nessun contratto aggiunto finora.',
  emptyState2:
    'Archivia e gestisci qui tutti i dettagli del contratto per semplificare la fatturazione e mantenere accordi chiari con i tuoi clienti.',

  // labels
  contractTemplateLabel: 'Modello di Contratto',
  contractValue: 'Valore del Contratto',
  contractType: 'Tipo di Contratto',
  signedDate: 'Data di Firma',
  signedUser: 'Utente Firmatario',
  signStatus: 'Stato della Firma',

  //detail drawer
  changeStatus: 'Cambia Stato',
  statusChangeSuccess: 'Stato cambiato con successo.',
  generalInfoTitle: 'Informazioni Generali',
  customerInfoTitle: 'Informazioni Cliente',
  projectInfoTitle: 'Informazioni Progetto',
  contractRenew: 'Rinnova Contratto',
  renew: 'Rinnova Contratto',
  renewSuccess: 'Contratto rinnovato con successo.',
  emptyStateRenew: 'Nessun contratto da rinnovare.',
  renewHistory: 'Cronologia Rinnovi',
};

export default locale; 
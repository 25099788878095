import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Button, Typography } from '@mui/material';
import ActionDialog from 'components/dialog/ActionDialog';
import { IEstimateDetailProspectResult } from 'corede-common-cocrm';
import moment from 'moment';
import parse from 'html-react-parser';

const Descriptions = (props: { estimateData: IEstimateDetailProspectResult | undefined }) => {
  const { t } = useTranslation();

  const [openNDA, setOpenNDA] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  const descriptionsData = [
    {
      title: 'PROJECT/SERVICES DESCRIPTION',
      description: props.estimateData?.projectOrServiceDesc,
    },
    {
      title: 'SCOPE OF WORK',
      description: props.estimateData?.scopeOfWork,
    },
    {
      title: 'ASSUMPTIONS AND EXCLUSIONS',
      description: props.estimateData?.assumptionsAndExclusions,
    },
    {
      title: 'PAYMENT TERMS',
      description: props.estimateData?.paymentTerms,
    },
    {
      title: 'OFFERS',
      description: props.estimateData?.offers,
    },
    {
      title: 'REFERENCE',
      description: props.estimateData?.reference,
    },
  ].filter((item) => item.description);

  return (
    <Stack>
      {descriptionsData &&
        descriptionsData.length > 0 &&
        descriptionsData.map((item, index) => (
          <Stack key={index} sx={{ py: 2, borderTop: '1px dashed', borderColor: 'divider' }}>
            <Typography variant="body2" sx={{ fontWeight: 500, textTransform: 'uppercase' }}>
              {item.title}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {item.description}
            </Typography>
          </Stack>
        ))}

      <Stack direction={'row'} alignItems={'center'} gap={3}>
        {props.estimateData?.legalNDA && (
          <Button
            variant="text"
            sx={{ color: 'secondary.main', fontWeight: 500, textDecoration: 'underline' }}
            onClick={() => setOpenNDA(true)}
          >
            {t('crm.preSale.estimate.ndaTitle')}
          </Button>
        )}
        {props.estimateData?.legalTermAndCond && (
          <Button
            variant="text"
            sx={{ color: 'secondary.main', fontWeight: 500, textDecoration: 'underline' }}
            onClick={() => setOpenTerms(true)}
          >
            {t('crm.preSale.estimate.termsTitle')}
          </Button>
        )}
      </Stack>

      <ActionDialog
        open={openNDA}
        onClose={() => setOpenNDA(false)}
        title={t('crm.preSale.estimate.ndaTitle')}
        width={600}
        buttonCancelTitle={t('close')}
      >
        <Grid item xs={12}>
          <Typography variant="h6">{props.estimateData?.legalNDA?.name}</Typography>
          <Typography variant="body1">{props.estimateData?.legalNDA?.content}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {props.estimateData?.legalNDA?.createdAt
              ? moment(props.estimateData?.legalNDA?.createdAt).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        </Grid>
      </ActionDialog>

      <ActionDialog
        open={openTerms}
        onClose={() => setOpenTerms(false)}
        title={t('crm.preSale.estimate.termsTitle')}
        buttonCancelTitle={t('close')}
        width={600}
      >
        <Grid item xs={12}>
          <Typography variant="h6">{props.estimateData?.legalTermAndCond?.name}</Typography>
          <Typography variant="body1">
            {props.estimateData?.legalTermAndCond?.content
              ? parse(props.estimateData?.legalTermAndCond?.content)
              : ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {props.estimateData?.legalTermAndCond?.createdAt
              ? moment(props.estimateData?.legalTermAndCond?.createdAt).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        </Grid>
      </ActionDialog>
    </Stack>
  );
};

export default Descriptions;

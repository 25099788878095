/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button, Menu, MenuItem, Typography, Dialog, DialogContent, TextField, DialogActions } from '@mui/material';
import { RefreshRounded } from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InvoiceReturnStatus,
  IInvoiceReturnDetailResult,
  PermissionSubject,
  InvoiceReturnReason,
  InvoiceReturnResolveReason,
} from 'corede-common-cocrm';
import React from 'react';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import {
  useInvoiceReturnApproveMutation,
  useInvoiceReturnDisapproveMutation,
  useInvoiceReturnInspectMutation,
  useInvoiceReturnResolveMutation,
  useInvoiceReturnUpdateMutation,
} from '../../context/return.api';
import { enqueueSnackbar } from 'notistack';
import { IBaseError, PermissionAction } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import ActionDialog from 'components/dialog/ActionDialog';

export interface IReturnDetailDrawerLeftPanelActionsComponentProps {
  returnDetailData: IInvoiceReturnDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * contains actions: add appointment, convert return to customer
 */

export const ReturnDetailDrawerLeftPanelActionsComponent = (
  props: IReturnDetailDrawerLeftPanelActionsComponentProps,
) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const [returnApprove, { data: returnApproveData, error: returnApproveError }] =
    useInvoiceReturnApproveMutation();

  const [returnDisapprove, { data: returnDisapproveData, error: returnDisapproveError }] =
    useInvoiceReturnDisapproveMutation();

  const [returnInspect, { data: returnInspectData, error: returnInspectError }] =
    useInvoiceReturnInspectMutation();

  const [returnResolve, { data: returnResolveData, error: returnResolveError }] =
    useInvoiceReturnResolveMutation();

  const [showDisapproveDialog, setShowDisapproveDialog] = React.useState(false);
  const [disapproveReason, setDisapproveReason] = React.useState(InvoiceReturnReason.changeOfMind);

  const [showResolveDialog, setShowResolveDialog] = React.useState(false);
  const [refundAmount, setRefundAmount] = React.useState(0);
  const [resolveReason, setResolveReason] = React.useState(InvoiceReturnResolveReason.exchange);

  const handleChangeStatus = (status: InvoiceReturnStatus) => {
    switch (status) {
      case InvoiceReturnStatus.approved:
        returnApprove({
          input: {
            invoiceId: props.returnDetailData?.invoice?._id ?? '',
            returnId: props.returnDetailData?._id ?? '',
          },
        });
        break;
      case InvoiceReturnStatus.inspected:
        returnInspect({
          input: {
            invoiceId: props.returnDetailData?.invoice?._id ?? '',
            returnId: props.returnDetailData?._id ?? '',
          },
        });
        break;
      case InvoiceReturnStatus.disapproved:
        setShowDisapproveDialog(true);
        break;
      case InvoiceReturnStatus.resolved:
        setShowResolveDialog(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (returnApproveData) {
      enqueueSnackbar(t('crm.sale.return.statusChangeSuccess'), {
        variant: 'success',
      });
    }
  }, [returnApproveData]);

  useEffect(() => {
    if (returnDisapproveData) {
      enqueueSnackbar(t('crm.sale.return.statusChangeSuccess'), {
        variant: 'success',
      });
    }
  }, [returnDisapproveData]);

  useEffect(() => {
    if (returnInspectData) {
      enqueueSnackbar(t('crm.sale.return.statusChangeSuccess'), {
        variant: 'success',
      });
    }
  }, [returnInspectData]);

  useEffect(() => {
    if (returnResolveData) {
      enqueueSnackbar(t('crm.sale.return.statusChangeSuccess'), {
        variant: 'success',
      });
    }
  }, [returnResolveData]);

  useEffect(() => {
    if (returnApproveError) {
      DefaultErrorHandlerUseEffect(returnApproveError as IBaseError, currentLanguage);
    }
  }, [returnApproveError]);

  useEffect(() => {
    if (returnDisapproveError) {
      DefaultErrorHandlerUseEffect(returnDisapproveError as IBaseError, currentLanguage);
    }
  }, [returnDisapproveError]);

  useEffect(() => {
    if (returnInspectError) {
      DefaultErrorHandlerUseEffect(returnInspectError as IBaseError, currentLanguage);
    }
  }, [returnInspectError]);

  useEffect(() => {
    if (returnResolveError) {
      DefaultErrorHandlerUseEffect(returnResolveError as IBaseError, currentLanguage);
    }
  }, [returnResolveError]);

  const StatusMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <PermissionWrapper
          check={{
            subject: PermissionSubject.invoiceReturn,
            action: PermissionAction.update,
          }}
        >
          <Button
            id="return-detail-button"
            aria-controls={open ? 'return-detail-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            color="primary"
            variant="contained"
            sx={{
              height: 40,
              fontSize: '13px',
              borderRadius: 100,
            }}
          >
            <RefreshRounded sx={{ mr: 1 }} />
            {t('crm.sale.return.changeStatus')}
          </Button>
          <Menu
            id="return-detail-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'return-detail-button',
            }}
            sx={{
              '& .MuiPaper-root': {
                bgcolor: 'background.secondWithBlur',
                backdropFilter: 'blur(3px)',
                borderRadius: 2,
              },
            }}
          >
            {Object.entries(InvoiceReturnStatus)
              .filter(([_, value]) => value !== InvoiceReturnStatus.requested)
              .map(([key, value]) => (
                <MenuItem key={key} onClick={() => handleChangeStatus(value)}>
                  <Typography fontSize={'small'} fontWeight={'bold'}>
                    {getTranslatedEnumValue(value)}
                  </Typography>
                </MenuItem>
              ))}
          </Menu>
        </PermissionWrapper>
      </div>
    );
  };

  return (
    <>
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        {/* Return base actions */}
        <StatusMenu />
      </Stack>
      {showDisapproveDialog && (
        <ActionDialog
          open={showDisapproveDialog}
          setOpen={setShowDisapproveDialog}
          title={t('crm.sale.return.disapproveReason')}
          buttonTitle={t('submit')}
          width={500}
          handleClick={() => {
            returnDisapprove({
              filter: {
                invoiceId: props.returnDetailData?.invoice?._id || '',
                returnId: props.returnDetailData?._id || '',
              },
              input: {
                reason: disapproveReason,
              },
            });
            setShowDisapproveDialog(false);
          }}
        >
          <TextField
            select
            fullWidth
            label={t('crm.sale.return.disapproveReason')}
            value={disapproveReason}
            onChange={(e) => setDisapproveReason(e.target.value as InvoiceReturnReason)}
          >
            {Object.values(InvoiceReturnReason).map((reason) => (
              <MenuItem key={reason} value={reason}>
                {getTranslatedEnumValue(reason)}
              </MenuItem>
            ))}
          </TextField>
        </ActionDialog>
      )}
      {showResolveDialog && (
        <ActionDialog
          open={showResolveDialog}
          setOpen={setShowResolveDialog}
          title={t('crm.sale.return.resolveReason')}
          buttonTitle={t('submit')}
          width={500}
          handleClick={() => {
            returnResolve({
              filter: {
                invoiceId: props.returnDetailData?.invoice?._id || '',
                returnId: props.returnDetailData?._id || '',
              },
              input: {
                refundAmount,
                resolveReason,
              },
            });
            setShowResolveDialog(false);
          }}
        >
          <TextField
            type="number"
            fullWidth
            label={t('crm.sale.return.refundAmount')}
            value={refundAmount}
            onChange={(e) => setRefundAmount(Number(e.target.value))}
          />
          <TextField
            select
            fullWidth
            label={t('crm.sale.return.resolveReason')}
            value={resolveReason}
            onChange={(e) => setResolveReason(e.target.value as InvoiceReturnResolveReason)}
          >
            {Object.values(InvoiceReturnResolveReason).map((reason) => (
              <MenuItem key={reason} value={reason}>
                {getTranslatedEnumValue(reason)}
              </MenuItem>
            ))}
          </TextField>
        </ActionDialog>
      )}
    </>
  );
};

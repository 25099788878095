const locale = {
  proposal: 'Proposition',
  title: 'Propositions',
  proposals: 'Propositions',
  create: 'Créer une Proposition',
  update: 'Mettre à jour la Proposition',
  delete: 'Supprimer',
  view: 'Voir la Proposition',
  list: 'Liste des Propositions',
  createdSuccessfully: 'Proposition créée avec succès',
  updatedSuccessfully: 'Proposition mise à jour avec succès',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ? Cette action ne peut pas être annulée.',
  deletedSuccessfully: 'Proposition supprimée avec succès',
  createInfo: 'Vous pouvez créer de nouvelles propositions ici.',
  prefix: 'Préfixe',
  proposalId: 'Proposition',
  timeZone: 'Fuseau Horaire',
  prospectTargetEntityId: 'Cible Prospect',
  prospectTargetEntityRef: 'Référence Cible Prospect',
  prospectType: 'Type de Prospect',
  prospectName: 'Nom du Prospect',
  prospectVAT: 'TVA du Prospect',
  prospectPhoneNumber: 'Numéro de Téléphone du Prospect',
  prospectCountry: 'Pays du Prospect',
  prospectState: 'État/Région du Prospect',
  prospectCity: 'Ville du Prospect',
  prospectZIP: 'Code Postal du Prospect',
  prospectAddress: 'Adresse du Prospect',
  prospectContactFirstName: 'Prénom du Contact Prospect',
  prospectContactLastName: 'Nom du Contact Prospect',
  prospectContactEmail: 'Email du Contact Prospect',
  language: 'Langue',
  date: 'Date',
  expireDate: 'Date d\'Expiration',
  sendEmailOnExpiration: 'Envoyer un Email à l\'Expiration',
  expirationEmailSentDate: 'Date d\'Envoi de l\'Email d\'Expiration',
  projectOrServiceDesc: 'Description du Projet ou Service',
  scopeOfWork: 'Portée du Travail',
  assumptionsAndExclusions: 'Hypothèses et Exclusions',
  paymentTerms: 'Conditions de Paiement',
  currency: 'Devise',
  products: 'Produits',
  addProducts: 'Ajouter des Produits',
  offers: 'Offres',
  reference: 'Référence',
  salesAgentIds: 'Agents Commerciaux',
  allowComments: 'Autoriser les Commentaires',
  useLegalNDA: 'Utiliser NDA',
  legalNDAId: 'NDA Légal',
  useLegalTermAndCond: 'Utiliser Termes et Conditions',
  legalTermAndCondId: 'Termes et Conditions Légaux',
  signerUserId: 'Utilisateur Signataire',
  listInfo: 'Liste de toutes les propositions.',
  importedError: 'Erreur importée',
  importedSuccessfully: 'Importé avec succès',
  downloadFormError: 'Erreur de téléchargement du formulaire',
  downloadFormSuccessfully: 'Formulaire téléchargé avec succès',
  downloadExampleFormSuccessfully: 'Formulaire exemple téléchargé avec succès',
  downloadExampleFormError: 'Erreur de téléchargement du formulaire exemple',
  emptyState1: 'Aucune proposition trouvée.',
  emptyState2: 'Vous pouvez créer ou importer des propositions immédiatement.',
  createProposal: 'Créer une Proposition',
  importProposal: 'Importer une Proposition',
  importTitle: 'Importer des Propositions',
  downloadExample: 'Télécharger l\'Exemple',
  importText1: 'Vous pouvez importer des propositions depuis cette page.',
  importText2: 'Vous pouvez utiliser le formulaire exemple ci-dessous pour publier vos propositions.',
  importExport: 'Importer/Exporter',
  exportData: 'Exporter les Données',
  importData: 'Importer les Données',
  b2b: 'B2B',
  b2c: 'B2C',
  viewProposal: 'Voir',
  edit: 'Modifier',
  junk: 'Indésirable',
  unJunk: 'Non Indésirable',
  addNewRole: 'Ajouter un Nouveau Rôle',
  actions: 'Actions',
  businessName: 'Nom de l\'Entreprise',
  governmentAgency: 'Agence Gouvernementale',
  fullName: 'Nom Complet',
  VAT: 'TVA',
  phoneNumber: 'Numéro de Téléphone',
  country: 'Pays',
  state: 'État/Région',
  city: 'Ville',
  ZIP: 'Code Postal',
  address: 'Adresse',
  contactFirstName: 'Prénom du Contact',
  contactLastName: 'Nom du Contact',
  contactEmail: 'Email du Contact',
  sentDate: 'Date d\'Envoi',
  name: 'Nom',
  description: 'Description',
  productUnit: 'Unité',
  quantity: 'Quantité',
  price: 'Prix',
  priceType: 'Type de Prix',
  discountedPrice: 'Prix Réduit',
  finalPrice: 'Prix Final',
  tax: 'Taxe',
  service: 'Service',
  subTotal: 'Sous-Total',
  taxTotal: 'Total des Taxes',
  discount: 'Remise',
  product: 'Produit',
  services: 'Services',
  references: 'Références',
  add: 'Ajouter',
  salesAgent: 'Agent Commercial',
  executiveSummary: 'Résumé Exécutif',
  companyBackground: 'Contexte de l\'Entreprise',
  projectorServiceDesc: 'Description Détaillée du Projet/Service',
  costBreakdown: 'Ventilation des Coûts',
  projectTimeline: 'Calendrier du Projet',
  testimonialCaseStudies: 'Témoignages et Études de Cas',
  conclusion: 'Conclusion',
  proposalCreateSuccess: 'Proposition créée avec succès',
  sentSuccessfully: 'Proposition envoyée avec succès',
  send: 'Envoyer la Proposition',
  resend: 'Renvoyer la Proposition',
  sendSureTitle: 'Envoyer la Proposition',
  sendSureDesc: 'Êtes-vous sûr de vouloir envoyer cette proposition ?',
  changeStatus: 'Changer le Statut',
  statusUpdatedSuccessfully: 'Statut mis à jour avec succès',

  // alert
  noProposal: 'Il n\'y a pas de propositions',
  noProposalContent1: 'Propositions non trouvées',
  noProposalSend: 'Il n\'y a pas de propositions envoyées',

  // generalInfo
  generalInfoTitle: 'Informations Générales',
  status: 'Statut',
  createdAt: 'Créé le',
  createdBy: 'Créé par',
  updatedAt: 'Mis à jour le',
  notAvailable: 'Non Disponible',
  updateStatus: 'Mettre à jour le Statut',

  // customerInfo
  customerInfoTitle: 'Informations Client',
  contactPhoneNumber: 'Numéro de Téléphone',

  //productInfo
  productInfoTitle: 'Détails du Produit',
  productNameUnknown: 'Produit Inconnu',
  noProducts: 'Aucun produit disponible.',

  //legalInfo
  legalInfoTitle: 'Informations Légales',
  ndaTitle: 'Accord de Confidentialité (NDA)',
  termsTitle: 'Termes et Conditions',
  documentName: 'Nom du Document',
  documentDescription: 'Description',
  documentContent: 'Contenu',

  //financialInfo
  financialInfoTitle: 'Informations Financières',
  shippingTotal: 'Total Livraison',
  total: 'Prix Total',
  noFinancialData: 'Aucune donnée financière disponible.',

  // documentInfo
  documentsTitle: 'Documents',
  documentNameUnknown: 'Document Inconnu',
  mimeType: 'Type de Fichier',
  download: 'Télécharger',
  noDocuments: 'Aucun document disponible.',

  // salesAgents
  salesAgentsTitle: 'Agents Commerciaux',
  unknownAgent: 'Agent Inconnu',
  noSalesAgents: 'Aucun agent commercial disponible.',

  //signature
  signatureTitle: 'Informations de Signature',
  additionalInfoTitle: 'Informations Supplémentaires',
  signature: 'Signature',
  signerUser: 'Utilisateur Signataire',

  //additionalInfo
  yes: 'Oui',
  no: 'Non',
};

export default locale; 
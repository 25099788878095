const locale = {
  title: "Catégories de Clients",
  headerTitle: "Catégories de Clients",
  create: "Créer une Catégorie de Client",
  columnId: "ID",
  columnName: "Nom",
  columnRoles: "Rôles",
  columnActions: "Actions",
  rolesButton: "Modifier les Rôles",
  createCustomerCategoryTitle: "Créer une Nouvelle Catégorie de Client",
  customerCategoryNameLabel: "Nom de la Catégorie de Client",
  rolesLabel: "Rôles",
  createCustomerCategoryButton: "Créer",
  emptyStateContent1: "Aucune catégorie de client trouvée",
  emptyStateContent2:
    "Vous pouvez créer de nouvelles catégories de clients en cliquant sur le bouton ci-dessous.",
  addNewRole: "Ajouter un Nouveau Rôle",
  deletedSuccessfully: "Catégorie de client supprimée avec succès",
  deleteConfirm: "Êtes-vous sûr de vouloir supprimer cette catégorie de client?",
  delete: "Supprimer",
  enTranslation: "Traduction Anglaise",
  trTranslation: "Traduction Turque",
  department: "Département",
  parentCategory: "Catégorie Parente",
  icon: "Icône",
  name: "Nom",
  createCustomerCategorySuccess: "Catégorie de client créée avec succès",
  addDepartment: "Ajouter un Département",
  showMore: "Afficher Plus",
  columnIcon: "Icône",
  columnDepartment: "Département",
  columnParentCategory: "Catégorie Parente",
  selectIconTitle: "Sélectionner une Icône",
  searchIcons: "Rechercher des Icônes",
  update: "Mettre à Jour la Catégorie de Client",
  updateCustomerCategorySuccess: "Catégorie de client mise à jour avec succès",
  actions: "Actions",
  createdAt: "Créé Le",
  emptyState1: "Aucune catégorie de client trouvée",
  emptyState2: "Vous pouvez créer de nouvelles catégories de clients en cliquant sur le bouton ci-dessous.",
};

export default locale; 
const locale = {
  // actions
  create: 'Создать Лид',
  update: 'Обновить Лид',
  detail: 'Детали Лида',
  view: 'Просмотр Лида',
  list: 'Список Лидов',
  addNewRole: 'Добавить Новую Роль',
  tagsPlaceholder: 'Добавить теги',
  listInfo: 'Список всех лидов.',
  weeklyReport: 'Еженедельный Отчет',

  // situational messages
  createdSuccessfully: 'Лид успешно создан',
  updatedSuccessfully: 'Лид успешно обновлен',
  deleteConfirm: 'Вы уверены, что хотите удалить? Это действие нельзя отменить.',
  deletedSuccessfully: 'Лид успешно удален',
  createInfo: 'Здесь вы можете создавать новые лиды.',
  updateLeadSuccess: 'Лид успешно обновлен',
  createLeadSuccess: 'Лид успешно создан',
  emptyState1: 'Лиды не найдены.',
  emptyState2: 'Вы можете сразу создать или импортировать лиды.',

  // import
  importLead: 'Импорт Лида',
  importTitle: 'Импорт Лидов',
  importText1: 'Вы можете импортировать лиды с этой страницы.',
  importText2: 'Вы можете использовать пример формы ниже для публикации ваших лидов.',

  // fields and names
  lead: 'Лид',
  leads: 'Лиды',
  title: 'Список Лидов',
  assigneds: 'Назначенные',
  qualification: 'Квалификация',
  justOwn: 'Только Свои',
  isJunk: 'Спам',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Спам',
  unJunk: 'Не Спам',
  prospectName: 'Имя Потенциального Клиента: ',
  businessType: 'Тип Бизнеса',
  leadType: 'Тип Лида',
  leadCount: 'лидов',
  leadValue: 'Ценность Лида',
  tracking: 'Отслеживание',
  image: 'Изображение Лида',
  
  // contact
  addContact: 'Добавить Контакт',
  contactedDate: 'Дата Контакта',
  emptyStateContactContent1: 'Контакты не найдены.',
  contactDetail: 'Детали Контакта',
  contactHistory: 'История Контактов',
  lastContacted: 'Последний Контакт',
  lastContact: 'Последний Контакт',
  contacted: 'Связались',

  // statistics
  leadConversions: 'Конверсии Лидов',

  // lead to customer
  convertToCustomer: 'Конвертировать в Клиента',
  customerType: 'Тип Клиента',
  leadConvertedSuccessfully: 'Лид успешно конвертирован',

  onSiteEstimateWith: 'Оценка на месте с',

  // labels
  welcome: 'Добро пожаловать',
  description: 'Ваш прогресс по лидам на этой неделе потрясающий. Давайте продолжим.',
};

export default locale; 
const locale = {
  permissions: "Permisos",
  errorFetchingPermissions: "Error al obtener permisos",
  allPermissions: "Todos los permisos",
  all: "Todos",
  ownPermissionsAlert: "No puedes cambiar tus propios permisos.",
  action: "Acción",
  actionScope: "Ámbito de acción",
  updateSuccess: "Permiso actualizado con éxito",
};

export default locale; 
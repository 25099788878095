const locale = {
  permissions: 'Berechtigungen',
  roles: 'Rollen',
  createRole: 'Rolle erstellen',
  rolesInfo: 'Sie können Rollen erstellen und ihnen Berechtigungen zuweisen.',
  roleName: 'Rollenname',
  roleDetail: 'Rollendetail',
  addRole: 'Hinzufügen',
  updateRole: 'Aktualisieren',
  deleteRole: 'Löschen',
  roleCreatedSuccess: 'Rolle erfolgreich erstellt',
  roleUpdatedSuccess: 'Rolle erfolgreich aktualisiert',
  roleDeletedSuccess: 'Rolle erfolgreich gelöscht',
  errorFetchingRoles: 'Fehler beim Abrufen der Rollen',
  errorCreatingRole: 'Fehler beim Erstellen der Rolle',
  errorUpdatingRole: 'Fehler beim Aktualisieren der Rolle',
  errorDeletingRole: 'Fehler beim Löschen der Rolle',
  capabilities: 'Fähigkeiten',
  advancedOptions: 'Erweiterte Optionen',
  addNewRole: 'Neue Rolle hinzufügen',
  rolesAndPermissions: 'Rollen & Berechtigungen',
  action: 'Aktion',
  actionScope: 'Aktionsbereich',
  deleteConfirm: 'Sind Sie sicher, dass Sie die Rolle entfernen möchten ',
  deletedSuccessfully: "Rolle erfolgreich gelöscht",
};

export default locale; 
const locale = {
  leadStatuses: "Stati dei Lead",
  title: "Stati dei Lead",
  create: "Crea Stato Lead",
  delete: "Elimina Stato Lead",
  deleteConfirm: "Sei sicuro di voler eliminare questo stato: ",
  deletedSuccessfully: "Stato lead eliminato con successo",
  createSuccess: "Stato lead creato con successo",
  update: "Aggiorna Stato Lead",
  updateSuccess: "Stato lead aggiornato con successo",
  selectColor: "Seleziona Colore",
  selectIcon: "Seleziona Icona",
};

export default locale; 
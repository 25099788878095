/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import { OutlinedButton } from 'components';
import { listCustomerRoute } from '../../../routes/customer.base.route';
import { UploadFileOutlined, ArticleOutlined } from '@mui/icons-material';
import { memo, useEffect, useRef, useState } from 'react';
import { BASE_URL } from 'utils';
import { getAccessToken } from 'utils/getUserInfo';
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';
import { unknownError } from 'corede-common';
import { getCurrentLanguage } from 'localization';

const Import = memo(() => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);

  const handleDownloadExampleForm = () => {
    let data = JSON.stringify({
      filter: {},
    });

    let config = {
      method: 'post',
      url: `${BASE_URL}/customers/import/example`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: data,
      responseType: 'blob',
    };

    axios
      .request(config as any)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `customer-example-import.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        enqueueSnackbar(t('crm.downloadExampleFormSuccessfully'), { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(
          error.response.data?.error?.message[currentLanguage] ??
            unknownError.message[currentLanguage],
          { variant: 'error' },
        );
      });
  };

  const handleDownloadForm = () => {
    let data = JSON.stringify({
      filter: {},
    });

    let config = {
      method: 'post',
      url: `${BASE_URL}/customers/export`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: data,
      responseType: 'blob',
    };

    axios
      .request(config as any)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `customers-${moment().format('DD-MM-YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        enqueueSnackbar(t('crm.customer.customer.downloadFormSuccessfully'), { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(
          error.response.data?.error?.message[currentLanguage] ??
            unknownError.message[currentLanguage],
          { variant: 'error' },
        );
      });
  };

  const handleImportForm = async (file: File) => {
    setLoading(true);
    let formData = new FormData();
    formData.append('file', file);

    let config: AxiosRequestConfig<File> = {
      method: 'post',
      url: `${BASE_URL}/customers/import`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: formData as any,
    };

    try {
      const response = await axios.request(config);
      if (!response) {
        setLoading(false);
        throw new Error('upload document failed');
      } else {
        setLoading(false);
        enqueueSnackbar(t('crm.importedSuccessfully'), { variant: 'success' });
        setTimeout(() => {
          window.location.href = listCustomerRoute();
        }, 1500);
      }
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(
        error.response.data?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  };

  // useEffect(() => {
  //   if (loading) {
  //     enqueueSnackbar(
  //       <Box display="flex" alignItems="center" gap={2}>
  //         <CircularProgress size={20} />
  //         <Typography>{t('crm.customer.customer.importing')}</Typography>
  //       </Box>,
  //       { variant: 'info', autoHideDuration: null },
  //     );
  //   }
  // }, [loading]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase title={t(`crm.customer.customer.importTitle`)} showBackButton={true} />

      <Card
        sx={{
          bgcolor: 'background.secondWithBlur',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={1}
          borderRadius={3}
          width={'100%'}
          height={'100%'}
          sx={{ bgcolor: 'background.secondWithBlur' }}
        >
          <UploadFileOutlined sx={{ opacity: 0.2, fontSize: '100px' }} />
          <Typography variant="body2">{t('crm.customer.customer.importText1')}</Typography>
          <Typography variant="body2">{t('crm.customer.customer.importText2')}</Typography>
          <Stack direction="row" spacing={1} mt={2}>
            <OutlinedButton
              title={t('crm.downloadExample')}
              leftIcon={<ArticleOutlined sx={{ mr: 1 }} fontSize="small" />}
              onClick={handleDownloadExampleForm}
            />
            <OutlinedButton
              title={t('crm.customer.customer.importCustomer')}
              leftIcon={<UploadFileOutlined sx={{ mr: 1 }} fontSize="small" />}
              onClick={() => inputFileRef.current?.click()}
            />
            <input
              type="file"
              hidden
              ref={inputFileRef}
              onChange={(e) => {
                handleImportForm(e.target.files![0]);
              }}
            />
          </Stack>
          {loading && <CircularProgress size={20} />}
        </Stack>
      </Card>
    </Grid>
  );
});

export default Import;

const locale = {
  demo: "Démo",
  title: "Qu'est-ce que <highlight>CoCRM</highlight> Marketing?",
  description:
    "Chez CoCRM, nous ne nous contentons pas de faciliter la gestion de vos relations clients existantes — nous vous aidons également à optimiser vos processus marketing pour atteindre vos objectifs commerciaux. Avec CoCRM Marketing, vous pouvez atteindre votre public cible plus efficacement et rendre vos campagnes marketing plus performantes.",
  featuresTitle: "Avec CoCRM Marketing, vous pouvez:",
  features1:
    "Personnaliser vos campagnes marketing par pays, ville et secteur pour une gestion plus stratégique,",
  features2:
    "Créer des campagnes personnalisées adaptées aux marchés spécifiques où vous souhaitez augmenter la notoriété de votre marque,",
  features3:
    "Suivre le parcours client plus efficacement pour soutenir vos objectifs de vente et de marketing,",
  features4:
    "Développer des stratégies orientées résultats en intégrant des outils de marketing digital.",
  buttonTitle: "Demander un devis",
  formDescription:
    "Remplissez le formulaire et nous vous répondrons dans les plus brefs délais.",
  sendSuccess: "Merci! Nous vous répondrons dans les plus brefs délais.",
  formTitle: "Demander un devis",
  formButtonTitle: "Soumettre",
  fullName: "Nom complet",
  email: "Email",
  phone: "Téléphone",
  orgName: "Nom de l'organisation",
};

export default locale; 
const locale = {
  reports: "Informes",
  report: "Informe",
  reportConversions: "Conversiones de Informes",
  title: "Informes",
  addReport: "Añadir Informe",
  emptyStateDesc1: "Aún no hay informes disponibles.",
  emptyStateDesc2: "Genere y vea aquí información detallada para realizar un seguimiento de sus actividades de CRM y tomar decisiones basadas en datos."
};

export default locale; 